<script setup lang="ts">
import { useSlots, ref, watchEffect, onMounted, reactive, nextTick, computed } from 'vue'

const slots = useSlots() as ReturnType<typeof useSlots>

interface IColumn {
  label: string
  field: string
  class?: string
  isSorted?: boolean
  mode?: string
  width?: string
  accordion?: boolean
  info?: {
    enabled?: boolean
    trigger?: string
    placement?: string
    content?: string
  }
}

type EventType = 'sortBy' | 'selectedRow' | 'expandedRow'

export interface Props {
  columns: Array<IColumn>
  rows: Array<{ [key: string]: any }>
  id?: string
  class?: string
  description?: string
  loading?: boolean
  noResultsMessage?: string
  headerColor?: string
  rowColors?: string[] | undefined
  scrollable?: boolean
  fixedHeader?: boolean
  overflow?: boolean
  sorting?: boolean
  sortingType?: string
  selectAll?: boolean
  hasAccordion?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  columns: () => [],
  rows: () => [],
  id: '',
  class: '',
  description: '',
  loading: false,
  noResultsMessage: 'No results found',
  headerColor: '',
  rowColors: undefined,
  scrollable: true,
  fixedHeader: false,
  overflow: true,
  sorting: false,
  sortingType: 'ui',
  selectAll: false,
  hasAccordion: false
})

const tableCounter = ref(0)
const customColumns = ref(props.columns)
const sortedRows = ref(props.rows)
const isLoading = ref(props.loading)
const noResultsMessage = ref(props.noResultsMessage)
const selectedAll = computed(
  () => sortedRows.value.length > 0 && sortedRows.value.every((row) => row.checked)
)
const expandAll = computed(
  () => sortedRows.value.length > 0 && sortedRows.value.every((row) => row.expanded)
)
const records = reactive({
  id: props.id,
  rows: sortedRows.value
})
const emit = defineEmits(['sortBy', 'selectedRow', 'expandedRow'])
const id = ref(props.id || generateRandomId())

function generateRandomId() {
  return 'section-' + Math.random().toString(36).substr(2, 9)
}

function initializeColumns(columns: Array<IColumn>) {
  return columns.map((column: IColumn) => ({
    ...column,
    isSorted: column.isSorted ?? false,
    mode: column.mode ?? 'desc'
  }))
}

onMounted(async () => {
  if (props.sorting && customColumns.value.length > 0) {
    customColumns.value = initializeColumns(customColumns.value)
    for (const column of customColumns.value) {
      const ascending = column.mode === 'asc'
      await nextTick()
      if (column.isSorted) {
        sortedRows.value = sortRows(props.rows, column.field, ascending)
      }
      if (props.selectAll) {
        sortedRows.value.forEach((row) => {
          row.checked = false
        })
      }
      if (props.hasAccordion) {
        sortedRows.value.forEach((row) => {
          row.expanded = false
          row.accordion = !!row.accordion
        })
      }
    }
  }
  tableCounter.value++
})

function toggleSortMode(column: IColumn) {
  column.mode = column.mode === 'asc' ? 'desc' : 'asc'
}

function sortRows(rows: any, field: string, ascending: boolean) {
  return [...rows].sort((a, b) => {
    if (a[field] === b[field]) return 0
    const comparison = a[field] < b[field] ? -1 : 1
    return ascending ? comparison : -comparison
  })
}

const onSortBy = (column: IColumn) => {
  toggleSortMode(column)
  const field = column.field
  const ascending = column.mode === 'asc'
  if (props.sortingType?.toLocaleLowerCase() === 'api') {
    setTimeout(() => {
      emit('sortBy', { field, mode: column.mode })
    }, 0)
  } else {
    if (!props.sorting) return
    sortedRows.value = sortRows(props.rows, field, ascending)
  }
}

function columnClasses(column: IColumn) {
  const isSorted = column.isSorted ?? false
  const isAsc = isSorted && column.mode === 'asc'
  const isDesc = isSorted && column.mode === 'desc'
  return {
    'icon-arrow-vertical': isSorted && !isAsc && !isDesc,
    'icon-chevron-up': isAsc && !isDesc,
    'icon-chevron-down': isDesc && !isAsc
  }
}

watchEffect(() => {
  if (props.rows.length > 0) {
    isLoading.value = false
  } else {
    isLoading.value = true
    noResultsMessage.value = 'Loading...'
    setTimeout(() => {
      isLoading.value = false
      noResultsMessage.value = props.noResultsMessage
    }, 2500)
  }
})

function updateRecords(eventType: EventType, filterCondition: (row: any) => boolean) {
  records.id = id.value
  records.rows = sortedRows.value.filter(filterCondition)
  emit(eventType, records)
}

function toggleCheckedAll() {
  if (sortedRows.value.length === 0) return
  const selectState = !selectedAll.value
  sortedRows.value.forEach((row) => {
    row.checked = selectState
  })
  updateRecords('selectedRow', (row) => row.checked)
}

function onSelectRow(row: any) {
  row.checked = !row.checked
  updateRecords('selectedRow', (row) => row.checked)
}

function onToggleRow(row: any) {
  row.expanded = !row.expanded
  updateRecords('expandedRow', (row) => row.expanded)
}

function onExpandAll() {
  if (sortedRows.value.length === 0) return
  const expandState = !expandAll.value
  sortedRows.value.forEach((row) => {
    row.expanded = expandState
  })
  updateRecords('expandedRow', (row) => row.expanded)
}
</script>

<template>
  <section
    :id="id"
    :key="tableCounter"
    class="chi-table"
    :class="props.fixedHeader && sortedRows.length > 0 ? '-fixed--header' : ''"
    :style="props.fixedHeader && sortedRows.length > 0 ? 'height: 480px;' : ''"
  >
    <div
      :class="[
        props.scrollable && !fixedHeader ? '-mh--480' : '',
        props.overflow ? '-overflow--auto' : ''
      ]"
    >
      <table :class="props.class" class="chi-table" :aria-label="props.description">
        <thead>
          <tr :style="{ 'background-color': props.headerColor }">
            <th
              v-for="(column, cIndex) in customColumns"
              :key="cIndex"
              :style="[
                column.width ? { width: column.width } : '',
                column.field === 'expandAll' ? { 'border-left': '4px solid transparent' } : '',
                props.sorting && column.isSorted ? { cursor: 'pointer' } : ''
              ]"
              @click.stop="
                props.sorting && column.isSorted && sortedRows.length > 0 ? onSortBy(column) : ''
              "
            >
              <div v-if="props.hasAccordion && column.field === 'expandAll'">
                <button type="button" class="chi-button -icon -flat -p--0" @click="onExpandAll">
                  <div class="chi-button__content">
                    <i
                      :class="`chi-icon -m--0 icon-squares-${expandAll ? 'minus' : 'plus'}-outline`"
                      aria-expanded="true"
                    ></i>
                  </div>
                </button>
              </div>
              <div
                v-else-if="
                  props.selectAll &&
                  column.label &&
                  (column.field?.toLocaleLowerCase() === 'all' ||
                    column.field?.toLocaleLowerCase() === 'selectAll')
                "
                class="chi-form__item"
              >
                <div class="chi-checkbox">
                  <input
                    :id="`select-${column.field?.toLocaleLowerCase() + '-' + id}`"
                    v-model="selectedAll"
                    type="checkbox"
                    class="chi-checkbox__input"
                    @click="toggleCheckedAll"
                  />
                  <label
                    class="chi-checkbox__label -text--semi-bold"
                    :for="`select-${column.field?.toLocaleLowerCase() + '-' + id}`"
                    >{{ column?.label || '' }}</label
                  >
                </div>
              </div>

              <div v-else>
                {{ column?.label || '' }}

                <eis-popover
                  v-if="column.info?.enabled"
                  :placement="column.info?.placement || 'top'"
                  :content="column.info?.content"
                  :trigger="column.info?.trigger || 'hover'"
                >
                  <template #trigger>
                    <i
                      class="chi-icon icon-circle-info-outline -xs -ml--1"
                      aria-hidden="true"
                      style="cursor: pointer"
                      @click.stop
                    ></i>
                  </template>
                </eis-popover>
                <i
                  v-if="props.sorting && column.isSorted"
                  :class="columnClasses(column)"
                  class="chi-icon -m--0 -xs -ml--1"
                  aria-hidden="true"
                ></i>
              </div>
            </th>
          </tr>
        </thead>

        <tbody v-if="props.rows.length > 0">
          <template v-for="(row, rIndex) in sortedRows" :key="rIndex">
            <tr
              :style="[
                {
                  'background-color': props?.rowColors
                    ? props?.rowColors?.[rIndex % props?.rowColors?.length]
                    : ''
                },
                row.expanded && row.accordion ? { 'background-color': '#e6f0ff' } : ''
              ]"
            >
              <td
                v-for="(column, cIndex) in customColumns"
                :key="cIndex"
                :class="[column.class, row.expanded && row.accordion ? '-text--semi-bold' : '']"
                :style="[
                  column.width ? { width: column.width } : '',
                  row.expanded && row.accordion && column.field === 'expandAll'
                    ? { 'background-color': '#e6f0ff', 'box-shadow': 'inset .25rem 0 0 #0075c9' }
                    : ''
                ]"
              >
                <div v-if="props.hasAccordion && column.field === 'expandAll' && row.accordion">
                  <button
                    type="button"
                    class="chi-button -icon -flat -p--0"
                    @click="onToggleRow(row)"
                  >
                    <div class="chi-button__content">
                      <i
                        :class="`chi-icon -m--0 icon-${row.expanded ? 'minus' : 'plus'}`"
                        aria-expanded="true"
                      ></i>
                    </div>
                  </button>
                </div>

                <div
                  v-if="
                    props.selectAll &&
                    (column.field?.toLocaleLowerCase() === 'all' ||
                      column.field?.toLocaleLowerCase() === 'selectAll')
                  "
                >
                  <div class="chi-form__item">
                    <div class="chi-checkbox">
                      <input
                        :id="`checkbox-${id}-${rIndex}`"
                        v-model="row.checked"
                        :disabled="row.disabled"
                        type="checkbox"
                        class="chi-checkbox__input"
                        @click="onSelectRow(row)"
                      />
                      <label :for="`checkbox-${id}-${rIndex}`" class="chi-checkbox__label"
                        >&nbsp;</label
                      >
                    </div>
                  </div>
                </div>

                <div v-if="slots?.[column?.field]" :class="row[column?.field]">
                  <slot :name="column?.field" :content="row"></slot>
                </div>
                <div v-else>{{ row?.[column.field] }}</div>
              </td>
            </tr>
            <tr v-if="row.expanded && row.accordion">
              <td
                :colspan="props.columns.length"
                class=""
                :style="{
                  'background-color': '#e6f0ff',
                  'box-shadow': 'inset 0.25rem 0 0 #0075c9'
                }"
              >
                <div class="accordian-body">
                  <slot name="accordian-body" :content="row"></slot>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="customColumns.length">
              <div class="-text -text--center -py--7">
                <chi-spinner
                  v-if="isLoading"
                  class="-m--auto"
                  color="primary"
                  size="md"
                ></chi-spinner>
                <p>
                  <i v-if="!isLoading" class="chi-icon icon-search -mr--1" aria-hidden="true"></i
                  >{{ noResultsMessage }}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
