<script lang="ts" setup>
import { defineProps, defineEmits, ref } from 'vue'
import Accordion from '../common/Accordion.vue'
interface IProps {
  taskOrders: any[]
}
const props = defineProps<IProps>()
const selected = ref()
const $emit = defineEmits<{
  (e: 'taskOrderSelect', value: string): void
}>()

function onTaskOrderSelect($event: any) {
  selected.value = $event.taskOrderId
  $emit('taskOrderSelect', $event)
}
</script>
<template>
  <Accordion title="Tos Associated to Selected Agency" :default-open="true">
    <div class="chi-grid">
      <div class="chi-col -w--3" v-for="to in props.taskOrders" :key="to.value">
        <div class="chi-form__item -inline">
          <div class="chi-radio" @click="onTaskOrderSelect(to)">
            <input class="chi-radio__input" type="radio" :checked="selected == to.value" />
            <label class="chi-radio__label">{{ to.label }}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="chi-card -widget -mt--3">
      <div class="chi-card__header">
        <div class="chi-card__title">Tos Associated to Selected Agency</div>
      </div>
      <div clas="chi-card__content" style="padding: 1rem">
        <div class="chi-grid">
          <div class="chi-col -w--3" v-for="to in props.taskOrders" :key="to.value">
            <div class="chi-form__item -inline">
              <div class="chi-radio" @click="onTaskOrderSelect(to)">
                <input
                  class="chi-radio__input"
                  type="radio"
                  :checked="props.selectedTo == to.value"
                />
                <label class="chi-radio__label">{{ to.label }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </Accordion>
</template>
