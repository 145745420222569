<script lang="ts" setup>
import { ref, computed, watch, onMounted, toRaw } from 'vue'
import { storeToRefs } from 'pinia'
import { useTaskOrder } from './taskOrderStore'
import { Input, SearchCell, SingleCell, toBeOptional } from '@centurylinkfederal/eis-vue'
import EmptyData from '../common/EmptyData.vue'

export interface Props {
  taskOrderNumber?: string
}
const props = withDefaults(defineProps<Props>(), {
  taskOrderNumber: undefined
})

const useTaskOrderStore = useTaskOrder()
const { agencyEntries, taskOrderEntries, taskOrderMap, taskOrderSearchEntries, agencyNameMap } =
  storeToRefs(useTaskOrderStore)
const { updateTaskOrderEntries, onInit } = useTaskOrderStore

const agencyInput = ref(new Input({ value: '', tests: [toBeOptional] }))
const taskOrderSearchInput = ref(new Input({ value: '', tests: [toBeOptional] }))
const taskOrderSingle = ref(new Input({ value: '', tests: [toBeOptional] }))
const selectedAgency = ref('')

const emit = defineEmits(['onSelect'])

const agencyCell = computed(() =>
  Object({
    field: 'agencyIdentifier',
    label: 'Agency',
    input: {
      options: [['', '- Select -'], ...agencyEntries.value]
    }
  })
)
const taskOrderSearchCell = computed(() =>
  Object({
    field: 'taskOrderString',
    label: 'Task Order Number',
    input: {
      options: [...(taskOrderSearchEntries?.value ?? [])],
      placeholder: 'Search'
    }
  })
)
const taskOrderSingleCell = computed(() =>
  Object({
    field: 'taskOrderOption',
    label: 'Associated Task Orders',
    input: {
      mode: 'radio',
      options: [...taskOrderEntries.value],
      classWrapper: '-w--30 -pl--2 -pt--1 -text--truncate'
    }
  })
)
watch(
  () => props.taskOrderNumber,
  async (newValue) => {
    if (taskOrderMap.value.size === 0) {
      await onInit(true)
    }
    taskOrderSingle.value.set(newValue)
    taskOrderSearchInput.value.set(newValue)
  }
)
function onSelect(emittedValue: any): void {
  emit('onSelect', emittedValue)
}
function resetForm(): void {}

async function onAgencyChange(newValue: any): Promise<void> {
  await updateTaskOrderEntries(newValue)
  selectedAgency.value = newValue
  taskOrderSearchInput.value.set(null)
  emit('onSelect', {})
}

function onTaskOrderSingle(newValue: any): void {
  const taskOrderObj = toRaw(taskOrderMap.value.get(newValue))
  const agencyObj = toRaw(agencyNameMap.value.get(taskOrderObj?.agencyIdentifier))
  onSelect({ newValue, taskOrderObj, agencyObj })
}
function onTaskOrderSearchChange(newValue: any): void {
  setTimeout(() => {
    agencyInput.value.set('')
    selectedAgency.value = ''
    onTaskOrderSingle(newValue)
  })
}
onMounted(() => {
  resetForm()
})
</script>

<template>
  <div class="-d--flex -flex--column -flex-md--row">
    <div class="-w-xl--25 -w-lg--30 -w-md--40 -w--50">
      <SearchCell
        :cell="taskOrderSearchCell"
        :input="taskOrderSearchInput"
        @onChange="onTaskOrderSearchChange"
      />
    </div>
    <div class="chi-divider -label -align-self--start -align-self-md--end">or</div>
    <div class="-w-xl--25 -w-lg--30 -w-md--40 -w--50">
      <SelectCell :cell="agencyCell" :input="agencyInput" @onChange="onAgencyChange" />
    </div>
  </div>
  <div class="-d--flex -mt--3" v-if="selectedAgency">
    <div class="-w--60" v-if="taskOrderEntries.length > 0">
      <SingleCell
        :cell="taskOrderSingleCell"
        :input="taskOrderSingle"
        @onChange="onTaskOrderSingle"
      />
    </div>
    <div class="-w--60 -p--5" v-if="taskOrderEntries.length === 0">
      <EmptyData
        text="There are no Task Orders associated with this Agency."
        icon="file-error-outline"
      />
    </div>
  </div>
</template>
