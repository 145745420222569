<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import ViewKPI from './ViewKPI.vue'
import { useTOAdminStore } from '../../../store/to-admin'
export interface Props {
  selectedOnChange: any
  createKPI: boolean
  taskOrderNumber: any
  taskOrderId: any
  billingEditedList: any
  createCustomKPIList: any
}
const props = withDefaults(defineProps<Props>(), {
  selectedOnChange: undefined,
  createKPI: undefined,
  taskOrderNumber: undefined,
  taskOrderId: undefined,
  billingEditedList: undefined,
  createCustomKPIList: undefined
})
const emit = defineEmits(['addCustomKpiProv'])
const kpiGrp = 'PROV - Provisioning SLA'
const toAdminStore = useTOAdminStore()
const { getTaskOrderKPIStatus } = storeToRefs(toAdminStore)
function addCustomKPI(updatekpi: any) {
  emit('addCustomKpiProv', updatekpi, kpiGrp)
  console.log(props.createCustomKPIList)
}
</script>

<template>
  <div>
    <!-- <div v-if="getTaskOrderKPIStatus && getTaskOrderKPIStatus === 'Active'">
      <div class="chi-backdrop -center -inverse">
        <div class="chi-backdrop__wrapper">
          <svg class="chi-spinner -icon--primary" viewBox="0 0 66 66">
            <title>Loading</title>
            <circle class="path" cx="33" cy="33" r="30" fill="none" stroke-width="6"></circle>
          </svg>
        </div>
      </div>
    </div> -->
    <ViewKPI
      :billingEditedList="billingEditedList"
      :selectedOnChange="selectedOnChange"
      :taskOrderNumber="taskOrderNumber"
      :taskOrderId="taskOrderId"
      :createKPI="createKPI"
      :kpiGrp="kpiGrp"
      :createCustomKPIList="createCustomKPIList"
      v-on:addCustomKpi="addCustomKPI"
      :createKPIList="''"
      :perfStdThreshold="''"
      :tabName="'Provisioning'"
    />
  </div>
</template>
