<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { ref, onMounted } from 'vue'
import States from './../constants/us-state'
import { useAlertsStore } from '../store/alert'
import { useApi } from '@centurylinkfederal/eis-vue'
import { Agency, Item } from '../models/createAccount'
import { ErrorMessages, createAccountError } from '../constants/create-account'

const states = States
const alertStore = useAlertsStore()
const isSubmitted = ref<boolean>(false)
const agencyList = ref<Array<Agency>>([])
const nonBillableRBO = ref<Array<Item>>([])
const nonBillableGreen = ref<Array<Item>>([])
const taskOrderList = ref<Array<Item>>([])
const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)
const formErrors = ref<createAccountError>({} as createAccountError)
const accountDetails = ref<any>({ level3Ban: '', agencyId: '', nonBanType: '' })
const isTaskOrderAdded = ref<{ firstCol: boolean; secondCol: boolean }>({
  firstCol: false,
  secondCol: false
})

onMounted(async () => {
  try {
    const response = await api.get<Agency[]>('/agencies')
    agencyList.value = response
  } catch (e) {
    agencyList.value = []
  }
})

function checkErrors(e: any, formName: keyof createAccountError): void {
  if (!isSubmitted.value) return
  const value = typeof e !== 'string' ? e.target.value : e
  formErrors.value[formName] = ''
  if (!value) {
    formErrors.value[formName] = ErrorMessages[formName]
  }
}

function displayNonBillableError(): boolean {
  if (!nonBillableRBO.value.length && !nonBillableGreen.value.length) {
    formErrors.value['nonBillable'] = ErrorMessages['nonBillable']
    return true
  }

  return false
}

function addNonBillableBan(): void {
  displayNonBillableError()
  if (!accountDetails.value.nonBanType || !accountDetails.value.nonBillableText?.trim()) {
    return
  }
  const selectedText = accountDetails.value.nonBillableText.trim()
  formErrors.value.nonBillable = ''
  if (
    !nonBillableRBO.value.some((item: Item) => item.label === selectedText) &&
    !nonBillableGreen.value.some((item: Item) => item.label === selectedText)
  ) {
    if (accountDetails.value.nonBanType === 'RBO') {
      nonBillableRBO.value.push({ label: selectedText, isSelected: false })
    }
    if (accountDetails.value.nonBanType === 'GREEN') {
      nonBillableGreen.value.push({ label: selectedText, isSelected: false })
    }

    accountDetails.value.nonBillableText = ''
  }
}

function removeSelectedNonBillableBans(): void {
  if (nonBillableRBO.value?.length) {
    nonBillableRBO.value = nonBillableRBO.value.filter((item: Item) => !item.isSelected)
  }
  if (nonBillableGreen.value?.length) {
    nonBillableGreen.value = nonBillableGreen.value.filter((item: Item) => !item.isSelected)
  }
}

function addTaskOrder(): void {
  if (!accountDetails.value.taskOrderText?.trim()) return
  if (
    !taskOrderList.value.some((item: Item) => item.label === accountDetails.value.taskOrderText)
  ) {
    taskOrderList.value.push({ label: accountDetails.value.taskOrderText, isSelected: false })
    accountDetails.value.taskOrderText = ''
    formErrors.value['taskOrder'] = ''
  }
}

function removeTaskOrder(): void {
  if (!taskOrderList.value.length) {
    return
  }
  taskOrderList.value = taskOrderList.value.filter((item: Item) => !item.isSelected)
}

function createAccount(): void {
  isSubmitted.value = true
  const accountKey = ['level3Ban', 'agencyId']
  let hasError = false
  for (let prop of accountKey) {
    if (!accountDetails.value[prop]) {
      checkErrors(accountDetails.value[prop], prop as keyof createAccountError)
      hasError = true
    }
  }

  const hasNonBillableError = displayNonBillableError()
  if (hasNonBillableError || hasError) {
    window.scroll({ top: 0, behavior: 'smooth' })
    return
  }
  const newTaskOrders = taskOrderList.value.map((item) => item.label)
  if (!(newTaskOrders?.length > 0)) {
    formErrors.value['taskOrder'] = ErrorMessages['taskOrder']
    hasError = true
    window.scroll({ top: 0, behavior: 'smooth' })
    return
  }

  const createAccountRequest = {
    ...accountDetails.value,
    billingTelephone: accountDetails.value.billingTelephone?.replace(/\D/g, ''),
    billingContactName: `${accountDetails.value.billingFirstName} ${accountDetails.value.billingLastName}`,
    accountId: 1,
    rboNonBillableBanList: nonBillableRBO.value.map((e: Item) => e.label),
    greenNonBillableBanList: nonBillableGreen.value.map((e: Item) => e.label),
    taskOrders: [...newTaskOrders]
  }

  api
    .post('/account', createAccountRequest)
    .then((res: any) => {
      alertStore.alerts = []
      alertStore.addAlert('success', res)
      resetForm()
      window.scroll({ top: 0, behavior: 'smooth' })
    })
    .catch((err: any) => {
      alertStore.alerts = []
      alertStore.addAlert(
        'danger',
        err?.response?.data?.message || `Error occured while creating the account.`
      )
      window.scroll({ top: 0, behavior: 'smooth' })
    })
}

function resetForm(): void {
  for (let key of Object.keys(accountDetails.value)) {
    accountDetails.value[key] = ''
  }
  nonBillableRBO.value = []
  nonBillableGreen.value = []
  taskOrderList.value = []
}

function onPhoneNumberChange(e: any) {
  const phoneRegex = /^(\d{0,3})(\d{0,3})(\d{0,4})$/
  const value = e.target.value.replace(/\D/g, '')
  const matches = value.match(phoneRegex)
  if (!matches?.length) {
    return
  }
  e.target.value = !matches[2]
    ? value.length == 3 && e.inputType !== 'deleteContentBackward'
      ? `(${matches[1]})`
      : matches[1]
    : `(${matches[1]})-${matches[2]}${matches[3] ? '-' + matches[3] : ''}`
}

const onFieldValueChange = (e: any, maxLength: number, key: string): void => {
  const target = e.target as HTMLInputElement
  if (target.value?.length > maxLength) {
    target.value = target.value?.substring(0, maxLength)
    accountDetails.value[key] = target.value
  }
}

const onNumberFieldKeyDown = (e: any): void => {
  if (['e', 'E'].includes(e.key)) {
    e.preventDefault()
  }
}
</script>

<template>
  <div class="container -p--2">
    <div class="chi-form__item">
      <label class="chi-label required" for="level3Ban">Lumen Ban</label>
      <input
        type="text"
        class="chi-input -w--25"
        id="level3Ban"
        maxlength="50"
        @input="checkErrors($event, 'level3Ban')"
        v-model="accountDetails.level3Ban"
      />
      <span class="-text--danger" v-if="formErrors.level3Ban">{{ formErrors.level3Ban }}</span>
    </div>

    <div class="chi-form__item -mt--2">
      <label class="chi-label required" for="agency">Agency</label>
      <select
        class="chi-select -w--25"
        id="agency"
        v-model="accountDetails.agencyId"
        @change="checkErrors($event, 'agencyId')"
      >
        <option value="">- Select Agency -</option>
        <option v-for="agency in agencyList" :value="agency.agencyId" :key="agency.agencyId">
          {{ agency.agencyDisplayName }}
        </option>
      </select>

      <span class="-text--danger" v-if="formErrors.agencyId">{{ formErrors.agencyId }}</span>
    </div>

    <div class="-mt--2 -d--flex -flex-gap-sm">
      <div class="-w--25">
        <label class="chi-label" for="nonBillable"
          ><span class="required">Non-Billable BAN</span> <small>(minimum 1 required)</small>
        </label>
        <input
          type="text"
          class="chi-input"
          id="nonBillable"
          maxlength="50"
          v-model="accountDetails.nonBillableText"
        />
        <span class="-text--danger" v-if="formErrors.nonBillable">{{
          formErrors.nonBillable
        }}</span>
      </div>
      <div class="-w--25 -d--flex -justify-content--between">
        <div>
          <label class="chi-label">&nbsp; </label>
          <div class="chi-radio">
            <input
              class="chi-radio__input"
              type="radio"
              name="nonBillableOption"
              id="rbo"
              v-model="accountDetails.nonBanType"
              value="RBO"
            />
            <label class="chi-radio__label" for="rbo">RBO</label>
          </div>
          <div class="chi-radio">
            <input
              class="chi-radio__input"
              type="radio"
              name="nonBillableOption"
              id="green"
              v-model="accountDetails.nonBanType"
              value="GREEN"
            />
            <label class="chi-radio__label" for="green">Green</label>
          </div>
        </div>
        <div>
          <label class="chi-label">&nbsp; </label>
          <div class="-d--flex -flex--column">
            <button class="chi-button -xs -mb--1" @click="addNonBillableBan">
              <div class="chi-button__content">
                <span>Add</span>
                <i class="chi-icon icon-chevron-right" aria-hidden="true"></i>
              </div>
            </button>
            <button class="chi-button -xs" @click="removeSelectedNonBillableBans">
              <div class="chi-button__content">
                <span>Remove</span>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div class="-w--45">
        <div class="chi-grid">
          <label class="chi-label">&nbsp; </label>
        </div>
        <div class="chi-grid non-billable__bans -h--4">
          <div class="chi-col">
            <label for="rbo">RBO</label>
            <div v-for="(item, i) in nonBillableRBO" :key="i">
              <div class="chi-form__item">
                <div class="chi-checkbox">
                  <input
                    type="checkbox"
                    class="chi-checkbox__input"
                    :id="item.label + 'RBO'"
                    v-model="item.isSelected"
                  />
                  <label class="chi-checkbox__label" :for="item.label + 'RBO'"
                    >{{ item.label }} - RBO</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="chi-col">
            <label for="green">GREEN</label>
            <div v-for="(el, j) in nonBillableGreen" :key="j">
              <div class="chi-form__item">
                <div class="chi-checkbox">
                  <input
                    type="checkbox"
                    class="chi-checkbox__input"
                    :id="el.label + 'GREEN'"
                    v-model="el.isSelected"
                  />
                  <label class="chi-checkbox__label" :for="el.label + 'GREEN'"
                    >{{ el.label }} - GREEN</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TASK ORDER  -->
    <div class="-mt--2 -d--flex">
      <div class="-w--25 -mr--4">
        <label class="chi-label" for="non-billable"
          ><span>Task Order</span>
          <abbr class="chi-label__required" aria-label="Required field">*</abbr>
        </label>
        <input
          type="text"
          class="chi-input"
          id="non-billable"
          maxlength="75"
          v-model="accountDetails.taskOrderText"
        />
        <span class="-text--danger" v-if="formErrors.taskOrder">
          {{ formErrors.taskOrder }}
        </span>
      </div>
      <div class="-w--25 -d--flex -justify-content--between -mr--4">
        <div>
          <label class="chi-label">&nbsp; </label>
          <div class="chi-radio">
            <label class="chi-radio__label" for="rbo"></label>
          </div>
          <div class="chi-radio">
            <label class="chi-radio__label" for="green"></label>
          </div>
        </div>
        <div class="-d--flex -flex--column">
          <label class="chi-label">&nbsp; </label>
          <button class="chi-button -xs -mb--1" @click="addTaskOrder">
            <div class="chi-button__content">
              <span>Add</span>
              <i class="chi-icon icon-chevron-right" aria-hidden="true"></i>
            </div>
          </button>

          <button class="chi-button -xs" @click="removeTaskOrder">
            <div class="chi-button__content">
              <span>Remove</span>
            </div>
          </button>
        </div>
      </div>

      <div class="-w--45">
        <label class="chi-label">&nbsp; </label>
        <div class="chi-grid non-billable__bans -h--4">
          <div class="chi-col -w--6" v-for="(el, i) in taskOrderList" :key="i">
            <div class="chi-form__item">
              <div class="chi-checkbox">
                <input
                  type="checkbox"
                  class="chi-checkbox__input"
                  :id="el.label"
                  v-model="el.isSelected"
                />
                <label class="chi-checkbox__label" :for="el.label">{{ el.label }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h4 class="-mt--4">Billing Contact</h4>

    <div class="chi-grid -mb--2">
      <div class="chi-form__item chi-col -w--3 -pr--0 -mr--4">
        <label class="chi-label" for="billingFirstName">First Name</label>
        <input
          type="text"
          class="chi-input"
          maxlength="50"
          id="billingFirstName"
          v-model="accountDetails.billingFirstName"
        />
      </div>

      <div class="chi-form__item chi-col -w--3 -pr--0 -mr--4">
        <label class="chi-label" for="billingLastName">Last Name</label>
        <input
          type="text"
          class="chi-input"
          maxlength="50"
          id="billingLastName"
          v-model="accountDetails.billingLastName"
        />
      </div>
    </div>

    <div class="chi-grid -mb--2">
      <div class="chi-form__item chi-col -w--3 -pr--0 -mr--4">
        <label class="chi-label" for="billingEmail">Email</label>
        <input
          type="text"
          class="chi-input"
          maxlength="200"
          id="billingEmail"
          v-model="accountDetails.billingEmail"
        />
      </div>

      <div class="chi-form__item chi-col -w--3 -pr--0 -mr--4 -flex--column">
        <chi-label for="billingTelephone">Phone Number</chi-label>
        <input
          type="text"
          class="chi-input"
          id="billingTelephone"
          v-model="accountDetails.billingTelephone"
          @input="onPhoneNumberChange($event)"
          maxlength="14"
        />
      </div>
    </div>

    <div class="-mb--2">
      <div class="chi-form__item" style="width: 52%">
        <label class="chi-label" for="billingAddress1">Address 1</label>
        <input
          type="text"
          class="chi-input"
          maxlength="200"
          id="billingAddress1"
          v-model="accountDetails.billingAddress1"
        />
      </div>
    </div>

    <div class="-mb--2">
      <div class="chi-form__item" style="width: 52%">
        <label class="chi-label" for="billingAddress2">Address 2</label>
        <input
          type="text"
          class="chi-input"
          maxlength="200"
          id="billingAddress2"
          v-model="accountDetails.billingAddress2"
        />
      </div>
    </div>

    <div class="chi-grid -mb--2">
      <div class="chi-form__item chi-col -w--3 -pr--0 -mr--4">
        <label class="chi-label" for="billingCity">City</label>
        <input
          type="text"
          class="chi-input"
          maxlength="100"
          id="billingCity"
          v-model="accountDetails.billingCity"
        />
      </div>

      <div class="chi-form__item chi-col -w--3 -pr--0 -mr--4">
        <div class="chi-form__item">
          <label class="chi-label" for="billingState">State</label>
          <select class="chi-select" id="billingState" v-model="accountDetails.billingState">
            <option value="">- Select -</option>
            <option v-for="state in states" :key="state.abbreviation" :value="state.abbreviation">
              {{ state.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="chi-grid -mb--2">
      <div class="chi-form__item chi-col -w--3 -pr--0 -mr--4">
        <label class="chi-label" for="billingZip">Zipcode</label>
        <input
          type="number"
          class="chi-input"
          maxlength="5"
          id="billingZip"
          @keypress="onNumberFieldKeyDown($event)"
          @input="onFieldValueChange($event, 5, 'billingZip')"
          v-model="accountDetails.billingZip"
        />
      </div>

      <div class="chi-form__item chi-col -w--3 -pr--0 -mr--4">
        <label class="chi-label" for="billingZipPlus">Zipcode Plus</label>
        <input
          type="number"
          class="chi-input"
          maxlength="4"
          id="billingZipPlus"
          @keypress="onNumberFieldKeyDown($event)"
          @input="onFieldValueChange($event, 4, 'billingZipPlus')"
          v-model="accountDetails.billingZipPlus"
        />
      </div>
    </div>

    <div class="-mb--2">
      <div class="chi-form__item" style="width: 52%">
        <label class="chi-label" for="billingNotes">Notes</label>
        <textarea
          class="chi-input"
          maxlength="150"
          id="notes"
          v-model="accountDetails.notes"
        ></textarea>
      </div>
    </div>

    <hr class="-mt--4 --mb--4" style="border-top: #dadee2" />

    <div class="-d--flex -justify-content--center">
      <button class="chi-button -primary -mr--4" @click="createAccount">Create Account</button>
      <button class="chi-button" type="button" @click="resetForm">Reset</button>
    </div>
  </div>
</template>

<style scoped>
.required::after {
  content: '*';
  color: red;
}

.-flex-gap-sm {
  gap: 2em;
}

.-w--45 {
  width: 45%;
}

.non-billable__bans {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background: #f8f9f9;
}

.-h--4 {
  min-height: 4em;
}
</style>
