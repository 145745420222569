import { ref } from 'vue'
import { defineStore } from 'pinia'
import { Row } from '@centurylinkfederal/eis-vue'
import { useForm } from '../../composables/form'

export const useStore = defineStore('createTaskOrderModsStore', () => {
  const resetSeq = ref(0)
  const { form, formState, formChanges, isFormEnabled } = useForm()
  const rows = ref<Row[]>([])
  function flush(): void {
    form.value = {}
    formState.value = undefined
  }

  return {
    resetSeq,
    form,
    formState,
    formChanges,
    isFormEnabled,
    flush,
    rows
  }
})
