<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useUserSearchStore } from './store'

const userSearchStore = useUserSearchStore()
const {
  searchInputValue,
  performSearch,
  currentResponse,
  currentPage,
  sortBy,
  searchResult,
  searchUserStatus
} = storeToRefs(userSearchStore)

const userStatus = computed(() => [
  { label: 'All', value: 'ALL' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'DISABLED' }
])

onMounted(() => {
  searchInputValue.value.trim() && searchUser()
})

function onSearchInput(e: any) {
  searchInputValue.value = e.target.value?.trim()
  currentPage.value = 1
}

function searchUser() {
  performSearch.value = Date.now()
}

function resetSearch(): void {
  currentResponse.value = []
  searchResult.value = []
  currentPage.value = 1
  sortBy.value = undefined
  searchInputValue.value = ''
  searchUserStatus.value = 'ALL'
}

function userSearchStatusChange(statusBy: string) {
  searchUserStatus.value = statusBy
  currentPage.value = 1
}
</script>

<template>
  <div class="chi-grid">
    <div class="chi-form__item chi-col -w-lg--3">
      <chi-search-input
        id="userSearch"
        mode="autocomplete"
        placeholder="Search"
        :value="searchInputValue"
        @chiInput="onSearchInput"
        @chiSearch="searchUser"
        @chiBlur="searchUser"
        @chiClean="resetSearch"
        @keypress.enter="searchUser"
      ></chi-search-input>
    </div>
    <div class="chi-divider -vertical -h-md--auto -h-sm--0" style="padding-left: 0.25rem"></div>

    <div class="chi-form__item chi-col -w--sm--12 -w--lg--6 -p--0 -pl--2">
      <fieldset>
        <div class="chi-picker-group">
          <div class="chi-picker-group__content">
            <template v-for="status of userStatus" :key="status.value">
              <input
                :id="status.label"
                v-model="searchUserStatus"
                :value="status.value"
                class="chi-picker__input"
                type="radio"
                name="userTypes"
                @change="userSearchStatusChange(status.value)"
              />
              <label :for="status.label" style="min-width: 6rem">{{ status.label }}</label>
            </template>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<style scoped>
.fg-group > .chi-form__item {
  flex: 1;
}
</style>
