<script lang="ts" setup>
import { ref, computed, onMounted, Ref, onUnmounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useTOAdminStore } from '../../store/to-admin'
import CreateMod from '../../components/to-admin/CreateMod.vue'
import AgencyAssociatedTO from '../../components/to-admin/AgencyAssociatedTO.vue'
import TOModIterations from '../../components/to-admin/TOModIterations.vue'
import TODetails from '../../components/to-admin/TODetails.vue'
import TOFinancials from '../../components/to-admin/TOFinancials.vue'
import moment from 'moment'
import { useToAdminAPI } from '../../composables/to-admin/api'
import ImportFile from '../../components/ImportFile.vue'
import Accordion from '../../components/common/Accordion.vue'
import SearchAutoComplete from '../../components/common/SearchAutoComplete.vue'
// import CreateToFinancials from '../../components/to-admin/create/CreateToFinancials.vue'
import TOServices from '../../components/to-admin/TOServices.vue'
import TaskOrderActivities from '../../components/to-admin/TaskOrderActivities.vue'
import Alerts from '../../components/Alerts.vue'
import KPI from '../../components/to-admin/KPI/KPIs.vue'
import { DatePicker } from '@centurylinkfederal/eis-vue'

const toAdminStore = useTOAdminStore()
const api = useToAdminAPI()
const {
  agencies,
  taskOrderDropdownOptions,
  toModIterations,
  agencyTOMap,
  selectedTO,
  agencyTaskOrderIdOfficials
} = storeToRefs(toAdminStore)
const taskOrderSearchValue = ref<string>('')
const agencySearchValue = ref<string>('')
const taskOrderSearchRef = ref()
const taskOrderOptionsByAgency = ref<any[]>([])
const showToOfficials = ref(false)
const kpiList = ref<any[]>([])
const accordionKey = ref(Date.now())
const currentTORecord = ref()

onMounted(async () => {
  await toAdminStore.fetchTaskOrders()
  await toAdminStore.fetchAgencies()
})

onUnmounted(() => {
  resetSearchValues()
})
const mode = ref<string>('view')
const toOfficialsByTaskOrderId = ref<any>()
let editOfficials = ref<number>(-1)
const errorMsg = ref<string>('')
const showImportModal: Ref<boolean> = ref<boolean>(false)
const fileImported = ref(false)
const editIndex = ref(-1)
const validationError = ref(false)
const originalToOfficialsByTaskOrderId = ref<any>()
const isFormEnabled = ref(false)
const selectedTaskOrder = ref<any>()
const loading = ref<boolean>(false)
const serviceDescList = ref<string[]>([])
const isInteractiveMode = ref<boolean>(false)
const officialImportFields = [
  'firstName',
  'lastName',
  'workOfficePhone',
  'workMobilePhone',
  'workEmail',
  'role',
  'startDate',
  'stopDate'
]
const officialImportHeaders = [
  'First Name',
  'Last Name',
  'Office Phone',
  'Mobile Phone',
  'Email',
  'Role',
  'Start Date',
  'End Date'
]
const errors = ref({
  firstName: '',
  lastName: '',
  workEmail: '',
  workMobilePhone: '',
  workOfficePhone: '',
  role: '',
  startDate: '',
  endDate: ''
})
const modIterationForm = ref<any>({
  description: '',
  modApprovedDate: '',
  modNumber: '',
  pramataId: '',
  startDateOfIteration: ''
})

function resetSearchValues(): void {
  taskOrderSearchValue.value = ''
  agencySearchValue.value = ''
  taskOrderOptionsByAgency.value = []
  toOfficialsByTaskOrderId.value = []
  selectedTO.value = ''
  taskOrderSearchRef.value?.clearContent?.()
}

async function searchTO(): Promise<void> {
  if (agencySearchValue.value) {
    let options = await toAdminStore.fetchTaskOrdersForAgency(agencySearchValue.value)
    options = options && options.filter((item: any) => item.taskOrderId !== 0)
    if (options) {
      taskOrderOptionsByAgency.value = options
    }
  } else {
    const record = taskOrderDropdownOptions.value.find(
      (item) => item.id === taskOrderSearchValue.value
    )
    if (!record) {
      return
    }
    currentTORecord.value = record
    await fetchAccordionData(record)
  }
  showToOfficials.value = false
}

async function onTaskOrderSelect(item: any): Promise<void> {
  selectedTaskOrder.value = item
  const filterAgency = taskOrderDropdownOptions.value.filter(
    (it: any) => it.taskOrderNumber === item.taskOrderNumber
  )
  if (filterAgency && filterAgency.length > 0) {
    item.agencyIdentifier = filterAgency[0].agencyIdentifier
  }
  await fetchAccordionData(item)

  const options = await toAdminStore.fetchTaskOrdersByIdForToOfficials(item.taskOrderId)

  toOfficialsByTaskOrderId.value = options
  toOfficialsByTaskOrderId.value.forEach((item: any) => {
    if (item.startDate) {
      item.startDate = moment(item.startDate).format('MM/DD/YYYY')
    }
    if (item.endDate) {
      item.endDate = moment(item.endDate).format('MM/DD/YYYY')
    }
  })
}

async function fetchAccordionData(record: any): Promise<void> {
  try {
    loading.value = true
    await toAdminStore
      .fetchTaskOrderToEdit(record.agencyIdentifier, record.taskOrderId)
      .catch((err) => err)
    await toAdminStore.fetchModIterationsForTO(record.taskOrderNumber).catch((err) => err)
    await toAdminStore.fetchTaskOrderFinancials(record.taskOrderId).catch((err) => err)
    await toAdminStore.getTaskOrderSvcDetailsDTO(record.taskOrderId).catch((err) => err)
    await toAdminStore.getServicesByTONumber(record.taskOrderId).catch((err) => err)
    await toAdminStore.getAllServices().catch((err) => err)
    serviceDescList.value = toAdminStore.allServices
    loading.value = false
  } catch (e) {
    console.log(e)
    loading.value = false
  }
}

function taskOrderSearchValueChanged($event: any): void {
  taskOrderSearchValue.value = $event
}

function editToOfficials(): void {
  mode.value = 'edit'
  isInteractiveMode.value = true
  originalToOfficialsByTaskOrderId.value = JSON.parse(
    JSON.stringify(toOfficialsByTaskOrderId.value)
  )
}

function removedOfficial(officialData: any, index: number): void {
  const item = officialData
  if (!item.isNew) item.deleted = true
  if (item.deleted && toOfficialsByTaskOrderId.value.length === 1) {
    return
  } else if (item.isNew) {
    toOfficialsByTaskOrderId.value = toOfficialsByTaskOrderId.value.filter(
      (record: any) => record.taskOrderOfficialId !== item.taskOrderOfficialId
    )
  }

  isFormEnabled.value = true
}

function cancelToOfficials() {
  mode.value = 'view'
  isInteractiveMode.value = false
  editOfficials.value = -1
  if (
    errors.value.firstName ||
    errors.value.lastName ||
    errors.value.endDate ||
    errors.value.role ||
    errors.value.startDate ||
    errors.value.workEmail ||
    errors.value.workMobilePhone ||
    errors.value.workOfficePhone
  ) {
    toOfficialsByTaskOrderId.value = originalToOfficialsByTaskOrderId.value
  }
  const values = toOfficialsByTaskOrderId.value.filter(
    (item: any) =>
      item.firstName !== '' &&
      item.lastName !== '' &&
      item.role !== '' &&
      item.workEmail !== '' &&
      item.startDate !== '' &&
      item.workOfficePhone !== ''
  )
  toOfficialsByTaskOrderId.value = values.map((item: { deleted: boolean }) => {
    item.deleted = false
    return item
  })
  toOfficialsByTaskOrderId.value = values.filter((item: { isNew: any }) => !item.isNew)
  toOfficialsByTaskOrderId.value.forEach((item: any) => {
    if (item.startDate) {
      item.startDate = moment(item.startDate).format('MM/DD/YYYY')
    }
    if (item.endDate) {
      item.endDate = moment(item.endDate).format('MM/DD/YYYY')
    }
  })

  validationError.value = false
  errorMsg.value = ''
  errors.value = {
    firstName: '',
    lastName: '',
    workEmail: '',
    workMobilePhone: '',
    workOfficePhone: '',
    role: '',
    startDate: '',
    endDate: ''
  }
  isFormEnabled.value = false
}

async function saveToOfficials(): Promise<void> {
  editOfficials.value = -1
  errorMsg.value = ''
  validationError.value = false
  let selectedTaskOrderId = null
  if (toOfficialsByTaskOrderId === null || toOfficialsByTaskOrderId.value.length === 0) {
    errorMsg.value = 'One Official is required.'
    validationError.value = true
    return
  }
  let count = 0
  let index: number = 0
  let indexNumber: number = 0
  let requiredFieldsError = ''
  let dateErrors = ''
  toOfficialsByTaskOrderId.value.forEach((o: any) => {
    if (o.deleted) {
      count += 1
    } else {
      if (
        o.firstName == null ||
        o.firstName.trim().length == 0 ||
        o.lastName == null ||
        o.lastName.trim().length == 0 ||
        o.workOfficePhone == null ||
        o.workOfficePhone.trim().length == 0 ||
        o.workEmail == null ||
        o.workEmail.trim().length == 0 ||
        o.role == null ||
        o.role.trim().length == 0 ||
        o.startDate == null ||
        o.startDate.trim().length == 0
      ) {
        if (!requiredFieldsError) {
          requiredFieldsError = 'Please fill in all required fields.'
        }
      }
      const errors = validateDates(o)
      if (errors.startDate || errors.endDate) {
        if (!dateErrors) {
          dateErrors = (errors.startDate || errors.endDate) as string
        }
      }
    }
    index = index + 1
  })

  if (count === toOfficialsByTaskOrderId.value.length) {
    validationError.value = true
    errorMsg.value = 'One Official is required.'
    return
  }

  if (requiredFieldsError) {
    validationError.value = true
    errorMsg.value = requiredFieldsError
    return
  }

  if (dateErrors) {
    validationError.value = true
    errorMsg.value = dateErrors
    return
  }

  toOfficialsByTaskOrderId.value.forEach((o: any) => {
    if (o.deleted) {
      o.firstName = 'deleted'
    }
  })
  toOfficialsByTaskOrderId.value.forEach((o: any) => {
    if (o.startDate && moment(o.startDate, 'MM/DD/YYYY', true).isValid()) {
      o.startDate = moment(o.startDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    }
    if (o.endDate && moment(o.endDate, 'MM/DD/YYYY', true).isValid()) {
      o.stopDate = moment(o.endDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    }
  })
  await toAdminStore.updateTaskOrdersByIdForToOfficials(toOfficialsByTaskOrderId.value)
  const taskOrderId = selectedTO.value.taskOrderDto.taskOrderId
  agencyTaskOrderIdOfficials.value[taskOrderId] = null
  const toOfficals = await toAdminStore.fetchTaskOrdersByIdForToOfficials(taskOrderId)

  toOfficialsByTaskOrderId.value = toOfficals
  toOfficialsByTaskOrderId.value.forEach((item: any) => {
    if (item.startDate) {
      item.startDate = moment(item.startDate).format('MM/DD/YYYY')
    }
    if (item.endDate) {
      item.endDate = moment(item.endDate).format('MM/DD/YYYY')
    }
  })

  cancelToOfficials()
}

function isValidDate(value: any): boolean {
  return moment(value, 'MM/DD/YYYY', true).isValid()
}

function validateDates(o: { startDate: moment.MomentInput; endDate: moment.MomentInput }) {
  let startError: String | Boolean = false
  let stopDateError: String | Boolean = false
  if (o.startDate && !isValidDate(o.startDate)) {
    startError = 'Invalid Date'
  }
  if (o.endDate && !isValidDate(o.endDate)) {
    stopDateError = 'Invalid Date'
  }
  if (
    !startError &&
    !stopDateError &&
    moment(o.startDate, 'MM/DD/YYYY', true).isSameOrAfter(moment(o.endDate, 'MM/DD/YYYY', true))
  ) {
    stopDateError = 'End date must be greater than start date'
  }

  return {
    startDate: startError,
    endDate: stopDateError
  }
}

function addNewoOfficials(): void {
  const officials = generateOfficial()
  toOfficialsByTaskOrderId.value = [...toOfficialsByTaskOrderId.value, officials]
  mode.value = 'create'
}

function generateOfficial() {
  return {
    isNew: true,
    firstName: '',
    lastName: '',
    workOfficePhone: '',
    workMobilePhone: '',
    workEmail: '',
    role: '',
    startDate: '',
    deleted: false,
    id: uuidv4()
  }
}
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const disableSearchButtons = computed(() => !taskOrderSearchValue.value && !agencySearchValue.value)
const disableSearchButtonsOfficial = computed(() => isFormEnabled.value)
const showRestOfTheForm = computed(() => !!selectedTO.value)

function clearMod() {
  modIterationForm.value = {
    description: '',
    modApprovedDate: '',
    modNumber: '',
    pramataId: '',
    startDateOfIteration: ''
  }
}

function importedFileInfo(details: any = {}): void {
  const { isValid, contents = [] } = details
  contents.forEach((content: any) => {
    console.log(content)
    ;(content.startDate =
      (content.startDate && moment(content.startDate).format('MM/DD/YYYY')) || ''),
      (content.stopDate =
        (content.stopDate && moment(content.stopDate).format('MM/DD/YYYY')) || ''),
      (content.id = uuidv4())
    if (mode.value === 'edit') {
      content.officialEdited = true
    } else {
      content.isNew = true
      content.deleted = false
    }
  })
  console.log(contents)
  toOfficialsByTaskOrderId.value = [...toOfficialsByTaskOrderId.value, ...(contents || [])]
  showImportModal.value = false
  fileImported.value = true
}

function validateStartDate(startDate: any, stopDate: any): void {
  let error = ''
  let stopDateError = ''
  if (startDate) {
    if (!isValidDate(startDate)) {
      error = 'Invalid Date'
    } else if (stopDate && !isValidDate(stopDate)) {
      stopDateError = 'Invalid Date'
    } else if (
      startDate &&
      stopDate &&
      isValidDate(stopDate) &&
      moment(startDate, 'MM/DD/YYYY', true).isSameOrAfter(moment(stopDate, 'MM/DD/YYYY', true))
    ) {
      stopDateError = 'End date must be greater than start date'
    }
  } else {
    error = ''
  }
  errors.value = {
    ...errors.value,
    startDate: error,
    endDate: stopDateError
  }
}

function validateInput(field: keyof typeof errors.value, input: any, index: number): void {
  errors.value[field] = ''
  if (field === 'startDate') {
    validateStartDate(
      toOfficialsByTaskOrderId.value[index][field],
      toOfficialsByTaskOrderId.value[index]['endDate']
    )
    errorMsg.value = errors.value.startDate
  }
  if (field === 'lastName') {
    if (toOfficialsByTaskOrderId.value[index][field] === '') {
      errors.value.lastName = 'true'
    }
  }
  if (field === 'firstName') {
    if (toOfficialsByTaskOrderId.value[index][field] === '') {
      errors.value.firstName = 'true'
    }
  }
  if (field === 'workEmail') {
    if (toOfficialsByTaskOrderId.value[index][field] === '') {
      errors.value.workEmail = 'true'
    }
  }
  if (field === 'workOfficePhone') {
    if (toOfficialsByTaskOrderId.value[index][field] === '') {
      errors.value.workOfficePhone = 'true'
    }
  }
  if (field === 'endDate') {
    validateStartDate(
      toOfficialsByTaskOrderId.value[index]['startDate'],
      toOfficialsByTaskOrderId.value[index][field]
    )
    errorMsg.value = errors.value.startDate
    errorMsg.value = errors.value.endDate
  }
  if (field === 'workEmail') {
    const val = toOfficialsByTaskOrderId.value[index][field]
    if (
      val &&
      !/(?:[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        val
      )
    ) {
      errors.value['workEmail'] = 'Invalid'
    }
  }
}

function enableSaveButton(field: any, value: any, index: number): void {
  editIndex.value = index
  if (
    originalToOfficialsByTaskOrderId.value.length === toOfficialsByTaskOrderId.value.length &&
    originalToOfficialsByTaskOrderId.value[index][field] !==
      toOfficialsByTaskOrderId.value[index][field]
  ) {
    isFormEnabled.value = true
  }
  if (toOfficialsByTaskOrderId.value[index].isNew) {
    isFormEnabled.value = true
  }
}

function onDateChange(field: string, $event: any): void {
  modIterationForm.value[field] = $event.detail
}

function onDateChangeOfficial(field: string, $event: any, item: any, index: number): void {
  item[field] = $event
  editIndex.value = index
  isFormEnabled.value = true
}

async function fetchIterations(taskOrderNumber: any): Promise<void> {
  await fetchAccordionData(selectedTaskOrder.value)
}
function addtoCustomKPIList(updatekpi: any) {
  updatekpi.forEach((upkpi: any) => {
    let hasPrevious = false
    kpiList.value.forEach((kpi: any, i: any) => {
      if (kpi.kpiId === upkpi.kpiId) {
        kpiList.value[i] = upkpi
        hasPrevious = true
      }
    })
    if (!hasPrevious) {
      kpiList.value.push(upkpi)
    }
  })
}
watch(
  () => [toAdminStore.allServices],
  () => {
    serviceDescList.value = toAdminStore.allServices
  }
)
watch(selectedTaskOrder, async (newVal: any, oldVal: any) => {
  if (newVal !== oldVal) {
    accordionKey.value = Date.now()
  }
})

function allowNumberOnly(fieldName: string, item: any): void {
  item[fieldName] = item[fieldName].replace(/[^\d]+/g, '')
}
</script>
<template>
  <div>
    <Alerts />
    <div class="search-container -mt--2">
      <div class="-d--flex -justify-content--center -align-items--center">
        <div class="chi-form__item -row -flex--fill">
          <label class="chi-label" style="min-width: 200px">Task Order (TO) Number</label>
          <div style="position: relative; width: 100%">
            <SearchAutoComplete
              ref="taskOrderSearchRef"
              :options="taskOrderDropdownOptions"
              @valueChanged="taskOrderSearchValueChanged"
              :disable="!!agencySearchValue"
            />
          </div>
        </div>
        <div class="-mx--3">OR</div>
        <div class="chi-form__item -row -flex--fill">
          <label class="chi-label">Agency</label>
          <select
            class="chi-select -w--75"
            v-model="agencySearchValue"
            :disabled="!!taskOrderSearchValue"
          >
            <option v-for="agency of agencies" :key="agency.value" :value="agency.value">
              {{ agency.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="-d--flex -justify-content--center -my--3" style="gap: 16px">
        <button class="chi-button -sm" :disabled="disableSearchButtons" @click="searchTO">
          Search
        </button>
        <button class="chi-button -sm" :disabled="disableSearchButtons" @click="resetSearchValues">
          Reset
        </button>
      </div>
    </div>
    <AgencyAssociatedTO
      v-if="taskOrderOptionsByAgency.length > 0"
      :taskOrders="taskOrderOptionsByAgency"
      @taskOrderSelect="onTaskOrderSelect"
    />
    <div v-if="showRestOfTheForm">
      <CreateMod
        :taskOrderNumber="selectedTO.toNumber"
        v-if="selectedTO"
        @afterSave="fetchIterations($event)"
      />
      <TOModIterations :items="toModIterations" />
      <Accordion title="TODetails">
        <TODetails action="edit" :items="selectedTO" />
      </Accordion>
      <TOFinancials action="edit" />
    </div>
    <div v-if="showRestOfTheForm">
      <Accordion title="TO Officials">
        <div class="chi-alert -danger -text--center" v-if="validationError">
          {{ errorMsg }}
        </div>
        <div class="showToOffcialsContentDetails">
          <div
            class="grid-toOfficials"
            v-for="(item, index) in toOfficialsByTaskOrderId"
            :key="index"
          >
            <div class="chi-grid">
              <div class="a-icon">
                <div class="m-form__item__icon">
                  <div class="icon" v-if="item.isNew">
                    <chi-icon icon="check" class="check" v-if="item.isNew"> </chi-icon>
                  </div>
                  <div
                    class="icon"
                    v-else-if="isFormEnabled && !item.isNew && !item.deleted && editIndex === index"
                  >
                    <chi-icon icon="edit" class="edit" v-if="isFormEnabled && !item.isNew">
                    </chi-icon>
                  </div>
                  <div class="icon" v-else-if="item.deleted">
                    <chi-icon
                      icon="circle-x-outline"
                      class="circle-x-outline"
                      color="red"
                      v-if="item.deleted"
                    >
                    </chi-icon>
                  </div>
                </div>
              </div>
              <div class="chi-col" v-if="mode === 'view'">
                <div class="m-form__item">
                  <chi-label>Name</chi-label>
                  <p class="a-labelValue">{{ item.firstName }} {{ item.lastName }}</p>
                </div>
              </div>
              <div class="chi-col" v-else-if="mode !== 'view'">
                <div class="m-form__item">
                  <chi-label required>First Name</chi-label>
                  <input
                    v-if="!item.deleted"
                    :class="errors.firstName ? 'chi-input -danger' : 'chi-input'"
                    v-model="item.firstName"
                    @input="enableSaveButton('firstName', $event, index)"
                    @blur="validateInput('firstName', $event, index)"
                  />
                  <p class="strikethrough" v-else>{{ item.firstName }}</p>
                </div>
              </div>
              <div class="chi-col" v-if="mode !== 'view'">
                <div class="m-form__item">
                  <chi-label required>Last Name</chi-label>
                  <input
                    v-if="!item.deleted"
                    :class="errors.lastName ? 'chi-input -danger' : 'chi-input'"
                    v-model="item.lastName"
                    @input="enableSaveButton('lastName', $event, index)"
                    @blur="validateInput('lastName', $event, index)"
                  />
                  <p class="strikethrough" v-else>{{ item.lastName }}</p>
                </div>
              </div>
              <div class="chi-col">
                <div class="m-form__item">
                  <chi-label required>Work Phone</chi-label>
                  <p v-if="mode === 'view'" class="a-labelValue">{{ item.workOfficePhone }}</p>
                  <input
                    v-else-if="!item.deleted"
                    :class="errors.workOfficePhone ? 'chi-input -danger' : 'chi-input'"
                    maxlength="10"
                    v-model="item.workOfficePhone"
                    @input="
                      (allowNumberOnly('workOfficePhone', item),
                      enableSaveButton('workOfficePhone', $event, index))
                    "
                    @blur="validateInput('workOfficePhone', $event, index)"
                  />
                  <p class="strikethrough" v-else>{{ item.workOfficePhone }}</p>
                </div>
              </div>
              <div class="chi-col">
                <div class="m-form__item">
                  <chi-label>Work Mobile</chi-label>
                  <p v-if="mode === 'view'" class="a-labelValue">{{ item.workMobilePhone }}</p>
                  <input
                    v-else-if="!item.deleted"
                    :class="errors.workMobilePhone ? 'chi-input -danger' : 'chi-input'"
                    maxlength="10"
                    v-model="item.workMobilePhone"
                    @input="allowNumberOnly('workMobilePhone', item)"
                  />
                  <p class="strikethrough" v-else>{{ item.workMobilePhone }}</p>
                </div>
              </div>
              <div class="chi-col">
                <div class="m-form__item">
                  <chi-label required>Work Email</chi-label>
                  <p v-if="mode === 'view'" class="a-labelValue">{{ item.workEmail }}</p>
                  <input
                    v-else-if="!item.deleted"
                    :class="errors.workEmail ? 'chi-input -danger' : 'chi-input'"
                    v-model="item.workEmail"
                    @input="enableSaveButton('workEmail', $event, index)"
                    @blur="validateInput('workEmail', $event, index)"
                  />
                  <p class="strikethrough" v-else>{{ item.workEmail }}</p>
                </div>
              </div>
              <div class="chi-col">
                <div class="m-form__item">
                  <chi-label required>Role</chi-label>
                  <p v-if="mode === 'view'">{{ item.role }}</p>
                  <select
                    v-else-if="!item.deleted"
                    class="chi-input"
                    v-model="item.role"
                    @input="enableSaveButton('role', $event, index)"
                    @blur="validateInput('role', $event, index)"
                  >
                    <option value="">- Select -</option>
                    <option vaue="COR">COR</option>
                    <option vaue="OCO">OCO</option>
                  </select>
                  <p class="strikethrough" v-else>{{ item.role }}</p>
                </div>
              </div>
              <div class="chi-col">
                <div class="m-form__item">
                  <chi-label required v-if="!isInteractiveMode">Start Date</chi-label>
                  <p v-if="mode === 'view'" class="a-labelValue">{{ item.startDate }}</p>
                  <DatePicker
                    label="Start Date"
                    v-else-if="!item.deleted"
                    :value="item.startDate"
                    :id="`start_date-${index}`"
                    format="MM/DD/YYYY"
                    :required="true"
                    @blur="validateInput('startDate', $event, index)"
                    @change="onDateChangeOfficial('startDate', $event, item, index)"
                    :helperMessage="''"
                    :state="errors.startDate ? 'danger' : ''"
                  />

                  <p class="strikethrough" v-else>{{ item.startDate }}</p>
                </div>
              </div>
              <div class="chi-col">
                <div class="m-form__item">
                  <chi-label v-if="!isInteractiveMode">End Date</chi-label>
                  <p v-if="mode === 'view'" class="a-labelValue">{{ item.endDate }}</p>
                  <DatePicker
                    label="End Date"
                    v-else-if="!item.deleted"
                    :value="item.endDate"
                    :id="`end_date-${index}`"
                    format="MM/DD/YYYY"
                    :required="true"
                    @blur="validateInput('endDate', $event, index)"
                    @change="onDateChangeOfficial('endDate', $event, item, index)"
                    :helperMessage="''"
                    :state="errors.endDate ? 'danger' : ''"
                  />
                  <p class="strikethrough" v-else>{{ item.endDate }}</p>
                </div>
              </div>
              <div class="a-icon" v-if="isInteractiveMode">
                <div class="m-form__item__icon">
                  <chi-button
                    variant="flat"
                    type="icon"
                    style="width: 50px"
                    @click="removedOfficial(item, index)"
                  >
                    <chi-icon icon="delete" />
                  </chi-button>
                </div>
              </div>
            </div>
            <div class="a-divider" v-if="index < toOfficialsByTaskOrderId.length - 1"></div>
          </div>

          <div
            class="-mt--2"
            style="display: flex; flex-direction: row; justify-content: center; gap: 16px"
          >
            <button class="chi-button" @click="editToOfficials()" v-if="!isInteractiveMode">
              Edit
            </button>
            <template v-else>
              <button
                class="chi-button"
                :disabled="!disableSearchButtonsOfficial"
                @click="saveToOfficials()"
              >
                save
              </button>
              <button class="chi-button" @click="addNewoOfficials()">Add New</button>
              <button class="chi-button" @click="showImportModal = true">import</button>
              <button class="chi-button" @click="cancelToOfficials()">cancel</button>
            </template>
          </div>
          <div v-if="showImportModal">
            <ImportFile
              :headerDetails="officialImportHeaders"
              :dataFields="officialImportFields"
              title="Task Order financial for Import"
              @closeModal="showImportModal = false"
              @importContent="importedFileInfo($event)"
            />
          </div>
        </div>
      </Accordion>
    </div>
    <div v-if="showRestOfTheForm">
      <Accordion title="TO Services and CLINs Awarded">
        <TOServices
          :taskOrderNumber="selectedTO.toNumber"
          :taskOrderId="selectedTaskOrder?.taskOrderId"
          :loading="loading"
        />
      </Accordion>
      <TaskOrderActivities />
    </div>
    <div v-if="showRestOfTheForm">
      <Accordion :key="accordionKey" title="KPIs">
        <KPI
          :selectedServices="serviceDescList"
          :taskOrderNumber="selectedTO.toNumber"
          :taskOrderId="selectedTaskOrder?.taskOrderId"
          :createKPIList="kpiList"
          :createKPI="false"
          v-on:addCustomKpiMain="addtoCustomKPIList"
          :perfStdThreshold="''"
          :billingUpdatedList="''"
        />
      </Accordion>
    </div>
  </div>
</template>
<style scoped>
.circle-x-outline.chi-icon {
  color: red;
}

.search-input-dropdown .chi-dropdown__menu {
  width: 100%;
}

.strikethrough {
  text-decoration: line-through;
}

.a-icon {
  width: 30px;

  display: inline-flex;

  align-self: center;
  padding-left: 10px;
}

.a-divider {
  border: 0;
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
}

.m-form__item {
  display: flex;

  -ms-flex-direction: column;
  flex-direction: column;
}

.showToOffcialsContentDetails {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 1rem;
  border-radius: 5px;
  word-wrap: break-word;
}
</style>
