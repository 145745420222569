<script setup lang="ts">
import { useStore } from './store'
import { storeToRefs } from 'pinia'
import Cells from '../CreateUser/Cells.vue'
import { useColumnsStore } from './ColumnStore'
import { onMounted, Ref, ref, watch } from 'vue'
import { Action, dispatch } from '../CreateUser/actions'
import { Row, setInput, StateType, toBeAPassword } from '@centurylinkfederal/eis-vue'

const classColDefault = ref('-w-xl-2h--3 -w-xl--3 -w-lg--4 -w-md--6 -w-sm--12')

const settings = {
  key: 'changePassword'
}
const row = ref<Row>({
  changePassword: -1
})
const store = useStore()
const {
  formChanges,
  form,
  formState,
  userDetails,
  resetSeq,
  lastTenPasswordValid,
  isNewPasswordValid,
  alertMap
} = storeToRefs(store)

const columnsStore = useColumnsStore()
const { currentPassword, newPassword, confirmPassword } = columnsStore
const quirksMap: Ref<Map<string, any>> = ref(new Map())
const formChangesOnceMap = ref<Map<string, Function>>(new Map())

watch(
  () => [userDetails.value, resetSeq.value],
  () => {
    createForm()
  }
)

onMounted(async () => {
  alertMap.value = new Map()
  userDetails.value = await dispatch(Action.GET_USER_DETAILS)
  lastTenPasswordValid.value = false
})

function createForm(): void {
  form.value = {}
  const rowKey = row.value.changePassword
  const inputs = form.value[rowKey] || {}

  const columns = [...currentPassword.value, ...newPassword.value, ...confirmPassword.value]

  for (const column of columns) {
    const { field, input } = column
    if (!field) {
      continue
    }

    inputs[field] = setInput(input.value, column)
  }

  form.value = { ...form.value, [rowKey]: inputs }
}

watch(
  () => formChanges.value,
  async (newValue) => {
    if (typeof newValue !== 'object') {
      return
    }
    let inDanger = false
    let inSuccess = false
    let inNoChange = false
    for (const key in newValue) {
      const rowEntries = newValue[key]
      const hasNoChange = rowEntries.some((entry: any) => entry?.[1]?.state === 'unchanged')
      const hasSuccess = rowEntries.some((entry: any) => entry?.[1]?.state === 'success')
      const hasDanger = rowEntries.some((entry: any) => entry?.[1]?.state === 'danger')
      let state = undefined
      state = hasNoChange ? 'unchanged' : state
      state = hasSuccess ? 'success' : state
      state = hasDanger ? 'danger' : state
      const patch = {
        state
      }
      const quirk = quirksMap.value.get(key)
      quirksMap.value.set(key, { ...quirk, ...patch })
      inNoChange = inNoChange || hasNoChange
      inSuccess = inSuccess || hasSuccess
      inDanger = inDanger || hasDanger
    }
    let state: StateType = undefined
    state = inNoChange ? 'unchanged' : state
    state = inSuccess ? 'success' : state
    state = inDanger ? 'danger' : state

    let testFailed = false
    const rowKeys = Object.keys(form.value)
    for (const rowKey of rowKeys) {
      const row = form.value[rowKey]
      for (const field in row) {
        const input = row[field]
        let testState, testErr

        if (!input?.tests?.length) {
          continue
        }
        ;[testState, testErr] = input.test()
        if (testState === 'danger' || testErr) {
          testFailed = true
          break
        }
      }
    }

    formState.value = testFailed ? 'danger' : state
    const patch = {
      state
    }
    const quirk = quirksMap.value.get('_all')
    quirksMap.value.set('_all', { ...quirk, ...patch })
    formChangesOnceMap.value.forEach((fn, key) => {
      try {
        if (typeof fn === 'function') {
          fn()
        }
      } catch (err) {
        console.error(err)
      }
      formChangesOnceMap.value.delete(key)
    })
  }
)

function onInput(newValue: any, cell: any, field?: string): void {
  const input = form.value[cell?.key]?.[cell?.field]
  if (!input) {
    return
  }
  if (cell?.field === 'newPassword') {
    isNewPasswordValid.value = toBeAPassword({ value: newValue })?.[0] === 'success'
  }
  input.set(newValue)
  input.validate()
}

function onError(isValid: boolean) {
  lastTenPasswordValid.value = isValid
}
</script>

<template>
  <div>
    <div class="chi-card -no-border -mt--3">
      <div class="chi-card__header">
        <div class="chi-card__title">
          <div class="-d--flex -flex--column">
            <a class="chi-link">
              <div class="chi-link__content">
                <router-link class="chi-link -no-hover-underline" to="/user-admin/user-profile">
                  <i class="chi-icon icon-chevron-left -xs -mr--1"></i
                  ><span class="-text--md -text--normal">My Profile</span>
                </router-link>
              </div>
            </a>
            <div class="-d--flex -align-items--center -mb--4">
              <div class="-text--h3 -text--boldest -text--navy -m--0 -text--900">
                Change Password
              </div>

              <div
                class="chi-divider -vertical chi-divider -vertical -m--0 -ml--2 -mr--2"
                style="height: 30px"
              ></div>
              <label>{{ userDetails?.userName }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="chi-card__content -bg--info-light">
        <Cells
          :classColDefault="classColDefault"
          :columns="currentPassword"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        >
        </Cells>

        <Cells
          :classColDefault="classColDefault"
          :columns="newPassword"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
          @onError="onError"
        />
        <Cells
          :classColDefault="classColDefault"
          :columns="confirmPassword"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
