import { ref, computed, onMounted } from 'vue'
import { toBeOptional, toNotBeEmpty } from '@centurylinkfederal/eis-vue'
import { defineStore } from 'pinia'
import { useToAdminAPI } from '../../composables/to-admin/api'
import { toMap } from '../../utils/helpers'

export const useColumns = defineStore('kpisColumnsStore', () => {
  const api = useToAdminAPI()
  const codesOptions = ref<any>({})
  const selectOption = ref(['', '- Select -'])
  const groups = ref([
    { title: 'Performance', group: 'PERF', kpiGrpType: 'PERF - Product Specific Performance' },
    { title: 'Outage', group: 'OUT', kpiGrpType: 'OUT - Service Outage' },
    { title: 'Provisioning', group: 'PROV', kpiGrpType: 'PROV - Provisioning SLA' },
    { title: 'Billing', group: 'BILL', kpiGrpType: 'BILL - Billing Accuracy' },
    { title: 'Submission', kpiGrpType: '' }
  ])
  const titleMap = computed(() => toMap(groups.value, 'title'))
  const columns = computed(() => [
    {
      field: 'expand',
      isExpandable: true
    },
    {
      field: 'kpiucUnitCode',
      label: 'KPI',
      class: '-size--md-2 -align-self--start',
      required: true,
      input: {
        type: 'select',
        options: [['', '- Select KPI -'], ...(codesOptions.value?.KPIUC ?? [])],
        value: '',
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'clin',
      label: 'CLIN',
      class: '-size--md -align-self--start',
      input: {
        type: 'text',
        placeholder: 'CLIN',
        required: false,
        tests: [toBeOptional]
      }
    },
    {
      field: 'icbNumber',
      label: 'ICB Number',
      class: '-size--md -align-self--start',
      input: {
        type: 'text',
        placeholder: 'ICB Number',
        required: false,
        tests: [toBeOptional]
      }
    },
    {
      field: 'kpilqSvcLvlQCode',
      label: 'Service Level',
      class: '-size--sm -d-expand--show -w-lg--3 -w-xl--2',
      input: {
        type: 'select',
        options: [selectOption.value, ...(codesOptions.value?.KPISLQ ?? [])],
        value: '',
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'perfStdThreshold',
      label: 'Performance Std',
      class: '-size--sm -d-expand--show -w-lg--3 -w-xl--2',
      input: {
        type: 'text',
        placeholder: 'Threshold',
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'kpiaoAqlQOprCode',
      label: 'AQL Operator',
      class: '-size--sm -d-expand--show',
      input: {
        type: 'select',
        options: [selectOption.value, ...(codesOptions.value?.KPIAO ?? [])],
        value: '',
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'kpimuMeasUnitCode',
      label: 'Measurement Unit',
      class: '-size--sm -d-expand--show -w-lg--3 -w-xl--2',
      input: {
        type: 'select',
        options: [selectOption.value, ...(codesOptions.value?.KPIMU ?? [])],
        value: '',
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'kpilqLocQCode',
      label: 'Location Qualifier',
      class: '-size--sm -d-expand--show -w-lg--3 -w-xl--2',
      input: {
        type: 'select',
        options: [selectOption.value, ...(codesOptions.value?.KPILQ ?? [])],
        value: '',
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'effectiveStartDate',
      label: 'Effective Start Date',
      class: '-size--md-1 -d-xl--show -align-self--start -w-md--3 -w-lg--3 -w-xl--2',
      input: {
        type: 'date',
        required: false,
        tests: [toBeOptional]
      }
    },
    {
      field: 'effectiveEndDate',
      label: 'Effective End Date',
      class: '-size--md-1 -d-xl--show -align-self--start -w-md--3 -w-lg--3 -w-xl--2',
      input: {
        type: 'date',
        required: false,
        tests: [toBeOptional]
      }
    },
    {
      field: 'waive',
      label: '',
      class: '-flex--grow0 -align-self--start -mt--0-5 -px--0 -mx--0',
      input: {
        type: 'toggle',
        mode: 'button',
        label: 'Waive',
        enabled: true,
        class: '-sm -px--2'
      }
    },
    {
      field: '_deleted',
      label: '',
      class: '-flex--grow0 -align-self--start -mt--0-5 -px--0 -mx--0',
      input: {
        type: 'toggle',
        mode: 'button',
        label: '',
        icon: 'delete',
        iconSize: 'sm',
        class: '-sm -danger -flat  -px--2',
        tooltip: 'Delete',
        disabled: true
      }
    },
    {
      field: 'additionalInfo',
      hidden: true
    },
    {
      field: 'taskOrderMetaData',
      hidden: true
    },
    {
      field: 'stdCustomIndicator',
      hidden: true,
      input: {}
    },
    {
      field: 'kpiType',
      hidden: true,
      input: {}
    }
  ])

  onMounted(async () => {
    const codesRes = await api.getCodes({
      transactionCode: ['KPIUC', 'KPIAO', 'KPIMU', 'KPILQ', 'KPISLQ']
    })
    if (typeof codesRes === 'object') {
      const codesEntries = Object.keys(codesRes).map((codeKey) => [
        codeKey,
        Object.entries(codesRes[codeKey])
      ])
      codesOptions.value = Object.fromEntries(codesEntries)
    }
  })
  return {
    columns,
    groups,
    titleMap,
    codesOptions
  }
})
