import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createRouter, createWebHistory } from 'vue-router'
import eis, { ADDITIONAL_UNPROTECTED_ROUTE_PATHS, eisRouteGuard } from '@centurylinkfederal/eis-vue'
import { configureEis } from './config'
import { routes } from './routes'
import App from './App.vue'
import '@centurylinkfederal/eis-vue/css/eis.css'
import '@centurylinkfederal/eis-vue/dist/eis.css'
import '@centurylinkfederal/eis-vue/css/dataTableExpanded.css'
import '@centurylinkfederal/eis-vue/css/editCell.css'
import '@centurylinkfederal/eis-vue/css/floating-vue.css'
import '../css/admin.css'
configureEis()

const pinia = createPinia()
const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_DIR),
  routes
})
ADDITIONAL_UNPROTECTED_ROUTE_PATHS.push('/admin-ui/user-admin/change-password')
ADDITIONAL_UNPROTECTED_ROUTE_PATHS.push('/admin-ui/reset')
ADDITIONAL_UNPROTECTED_ROUTE_PATHS.push('/admin-ui/password-reset')
router.beforeEach((to, from, next) => {
  eisRouteGuard(to, from, next)
})

createApp(App).use(pinia).use(router).use(eis).mount('#app')
