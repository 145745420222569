import { computed } from 'vue'
import { toNotBeEmpty, toBeAMonthDayYear, toBeOptional } from '@centurylinkfederal/eis-vue'
import { toMMDDYYYY } from '../../utils/date'
import { defineStore } from 'pinia'
import moment from 'moment'

export const useColumns = defineStore('modColumnsStore', () => {
  const columnsOriginal = computed(() =>
    columns.value.map((e: any) => {
      if (!['iterationEndDate', 'pramataId'].includes(e.field)) {
        return { ...e, hidden: true, input: undefined }
      }
      return { ...e, input: undefined }
    })
  )
  const columnsNew = computed(() =>
    columns.value.map((e: any) => {
      if (
        ![
          'modNumber',
          'modApprovedDate',
          'iterationStartDate',
          'pramataId',
          'modDescription'
        ].includes(e.field)
      ) {
        return { ...e, hidden: true, input: undefined }
      }
      return { ...e }
    })
  )
  const columnsEdit = computed(() =>
    columns.value.map((e: any) => {
      if (!['modNumber', 'modApprovedDate', 'modDescription'].includes(e.field)) {
        return {
          ...e,
          input: {
            type: 'text',
            disabled: true
          }
        }
      }
      return { ...e }
    })
  )
  const columnsReadOnly = computed(() =>
    columns.value.map((e: any) => Object({ ...e, input: undefined }))
  )
  const columns = computed(() => [
    {
      field: 'modNumber',
      label: 'MOD Number',
      input: {
        type: 'text',
        required: true,
        maxLength: 25,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'pramataId',
      label: 'CLM ID',
      input: {
        type: 'text',
        required: false,
        maxLength: 25,
        tests: [toBeOptional]
      }
    },
    {
      field: 'iterationStartDate',
      label: 'Start Date',
      input: {
        type: 'date',
        format: 'MM/DD/YYYY',
        required: true,
        max: moment().format('MM/DD/YYYY'),
        tests: [toNotBeEmpty, toBeAMonthDayYear]
      }
    },
    {
      field: 'iterationEndDate',
      label: 'End Date'
    },
    {
      field: 'modApprovedDate',
      label: 'Approved Date',
      input: {
        type: 'date',
        format: 'MM/DD/YYYY',
        required: true,
        tests: [toNotBeEmpty, toBeAMonthDayYear]
      }
    },
    {
      field: 'createdOn',
      label: 'Created On'
    },
    {
      field: 'updatedOn',
      label: 'Updated On',
      formatFn: (e: string | undefined) => (e ? toMMDDYYYY(e) : e)
    },
    {
      field: 'modDescription',
      label: 'Description',
      input: {
        type: 'textarea',
        required: false,
        maxLength: 1000,
        tests: [toBeOptional]
      }
    }
  ])

  return {
    columns,
    columnsOriginal,
    columnsNew,
    columnsEdit,
    columnsReadOnly
  }
})
