<script lang="ts" setup>
import { onMounted, ref, computed, watch, toRaw } from 'vue'
import { storeToRefs } from 'pinia'
import { useScroll } from '@vueuse/core'
import { useStore } from './store'
import Epanel from '../common/Epanel.vue'
import TaskOrder from './TaskOrder.vue'
import Details from '../viewTaskOrder/Details.vue'
import SetsToExport from './SetsToExport.vue'
import Submit from './Submit.vue'
import { doScrollToElement } from '../../utils/ui'
import { encodeAgencyIdentifier } from '../../utils/to-admin.utils'
import { Alert } from '@centurylinkfederal/eis-vue'

const store = useStore()
const {
  activePanel,
  disabledPanels,
  billingNotifications,
  alertMap,
  alertEntries,
  modalMap,
  modalEntries,
  signal,
  summary,
  form,
  formChanges,
  panelStates,
  selectedTaskOrderObj,
  selectedTaskOrder,
  setsToExport
} = storeToRefs(store)
const documentRef = ref<Document | null>(null)
const { arrivedState: bottomArrivedState } = useScroll(documentRef, {
  throttle: 500,
  offset: { bottom: 150 }
})
const epanelSettings = ref({ classFooter: '-pr--7', adHoc: true, noFooter: true })
const panels = ['search', 'picks']
const detailsArgs = computed(() => {
  if (!selectedTaskOrderObj.value) {
    return {}
  }
  const { taskOrderId, agencyIdentifier } = selectedTaskOrderObj.value
  const agencyIdentifierEncoded = encodeAgencyIdentifier(agencyIdentifier)
  return {
    taskOrderId,
    agencyIdentifierEncoded
  }
})
watch(
  () => formChanges.value,
  (newChanges) => {
    panelStates.value = { ...panelStates.value, details: makePanelState(newChanges, form.value) }
  }
)
watch(
  () => selectedTaskOrder.value,
  (newValue) => {
    if (!newValue) {
      disabledPanels.value.add('picks')
      panelStates.value.search = {
        state: 'none',
        error: null
      }
    } else {
      disabledPanels.value.delete('picks')
      panelStates.value.search = {
        state: 'primary',
        error: null
      }
      onAction('next', 'search')
    }
  }
)
watch(
  () => setsToExport.value,
  (newValue) => {
    if (!newValue || !newValue.length) {
      panelStates.value.picks = {
        state: 'unchanged',
        error: null
      }
    } else {
      panelStates.value.picks = {
        state: 'success',
        error: null
      }
    }
  }
)
function makePanelState(changes: any, formValue: any): any {
  let formState = 'unchanged'
  let inputError
  for (const key in changes) {
    const inputs = formValue[key]
    for (const [field, change] of changes[key]) {
      formState = change.state && change.state !== 'unchanged' ? change.state : formState
      if (formState === 'danger') {
        inputError = inputs?.[field]?.error
        break
      }
    }
    if (formState === 'danger') {
      break
    }
  }
  return {
    state: formState,
    error: inputError
  }
}
function getNextPanel(currentPanel: string, sign = 1, panic = 0): string | undefined {
  if (panic > 10) {
    return
  }
  const index = panels.indexOf(currentPanel)
  if (index === -1) {
    return
  }
  const nextIndex = index + sign
  if (nextIndex > panels.length - 1 || nextIndex < 0) {
    return
  }
  const nextPanel = panels[nextIndex]
  if (disabledPanels.value.has(nextPanel)) {
    return getNextPanel(nextPanel, sign, panic + 1)
  }
  return nextPanel
}
function onAction(action: string, panel: string) {
  switch (action) {
    case 'next':
    case 'previous': {
      const sign = action === 'next' ? 1 : -1
      const nextPanel = getNextPanel(panel, sign)
      if (!nextPanel) {
        return
      }
      activePanel.value = nextPanel
      doScrollToElement('#epanel-' + nextPanel, { wait: 50, left: 0, offsetTop: -40 })
      break
    }
    case 'active': {
      if (disabledPanels.value.has(panel)) {
        return
      }
      activePanel.value = panel
      doScrollToElement('#epanel-' + panel, { wait: 50, left: 0, offsetTop: -40 })
      break
    }
  }
}
function onSelectTaskOrder({ taskOrderObj }: any): void {
  selectedTaskOrderObj.value = toRaw(taskOrderObj)
}
function onExportSetsPick(key: string, value: boolean): void {
  if (value) {
    setsToExport.value = [...setsToExport.value, key]
  } else {
    setsToExport.value = setsToExport.value.filter((item) => item !== key)
  }
}
onMounted(() => {
  documentRef.value = window.document
  selectedTaskOrderObj.value = null
  activePanel.value = 'search'
  setsToExport.value = []
  disabledPanels.value.add('picks')
})
</script>

<template>
  <div class="alerts" :class="{ '-mt--2': alertEntries?.length }">
    <Alert
      v-for="[alertKey, alert] in alertEntries"
      :key="alertKey"
      v-bind="alert"
      @onDismissAlert="alertMap.delete(alertKey)"
    />
  </div>

  <Epanel
    id="epanel-search"
    number="1."
    title="Task Order"
    :state="panelStates.search?.state"
    :active="activePanel === 'search'"
    :settings="{
      ...epanelSettings,
      noPrevious: true,
      noNext: disabledPanels.has('picks') ? true : false
    }"
    @onAction="(action) => onAction(action, 'search')"
  >
    <template #done>
      <div
        v-if="['primary', 'success', 'muted'].includes(panelStates.search?.state)"
        :class="['chi-breadcrumb', '-text--' + panelStates.search?.state]"
      >
        {{ selectedTaskOrder }}
      </div>
      <div class="-text--danger" else-if="panelStates.search?.state === 'danger'">
        {{ panelStates.search?.error?.message }}
      </div>
    </template>
    <TaskOrder @onSelect="onSelectTaskOrder" />
  </Epanel>
  <Epanel
    id="epanel-picks"
    number="2."
    title="Detail Sets"
    :state="panelStates.picks?.state"
    :active="activePanel === 'picks'"
    :disabled="disabledPanels.has('picks')"
    :settings="{ ...epanelSettings, noNext: true }"
    @onAction="(action) => onAction(action, 'picks')"
  >
    <template #disabled>
      <div class="-text--md -text--muted">
        A <b>Task Order</b> must be selected to activate this panel.
      </div></template
    >
    <template #done>
      <div
        v-if="['primary', 'success', 'muted'].includes(panelStates.picks?.state)"
        :class="['chi-breadcrumb', '-text--' + panelStates.picks?.state]"
      >
        <div>
          {{ setsToExport?.join(', ') }}
        </div>
      </div>
      <div class="-text--danger" else-if="panelStates['billing-contacts']?.state === 'danger'">
        {{ panelStates.picks?.error?.message }}
      </div>
    </template>
    <Details v-bind="detailsArgs" />
    <div class="chi-divider"></div>
    <SetsToExport :taskOrderId="detailsArgs.taskOrderId" @onSelect="onExportSetsPick" />
  </Epanel>
  <footer
    class="-d--flex -justify-content--end -pb--2 -pt--2 -pl--2 -z--20"
    :class="{
      '-position--fixed-bottom -bg--white-translucent -pr--4': !bottomArrivedState.bottom
    }"
  >
    <div class="chi-button-group -flex--row chi-divider -vertical">
      <button
        class="chi-button -flat -primary"
        data-chi-epanel-action="previous"
        :disabled="!getNextPanel(activePanel, -1)"
        @click="onAction('previous', String(activePanel))"
      >
        <div class="chi-button__content">
          <i class="chi-icon icon-chevron-left -xs" aria-hidden="true"></i>
          <span>Previous</span>
        </div>
      </button>
      <button
        class="chi-button -flat -primary"
        data-chi-epanel-action="next"
        :disabled="!getNextPanel(activePanel)"
        @click="onAction('next', String(activePanel))"
      >
        <div class="chi-button__content">
          <span>Next</span>
          <i class="chi-icon icon-chevron-right -xs" aria-hidden="true"></i>
        </div>
      </button>
    </div>
    <div class="-d--flex -pl--4">
      <Submit />
    </div>
  </footer>
</template>
<style>
.-cursor--default {
  cursor: default;
}
.-cursor--progress {
  cursor: progress;
}
.-user-select--none {
  user-select: none;
}
</style>
<style scoped>
a:hover.-cursor--default {
  cursor: default;
}
a:hover.-text--no-decoration {
  text-decoration: none;
}
.-bg--white-translucent {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>
