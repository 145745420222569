<!-- eslint-disable no-console -->
<script lang="ts" setup>
import TODetails from '../../components/to-admin/TODetails.vue'
import TOFinancials from '../../components/to-admin/create/CreateToFinancials.vue'
import { useTOAdminStore } from '../../store/to-admin'
import { storeToRefs } from 'pinia'
import { watch, ref, onUnmounted, defineEmits, onMounted } from 'vue'
import CreateToOfficials from '../../components/to-admin/create/CreateToOfficials.vue'
import Card from '../../components/to-admin/create/Card.vue'
import TOServiceClinsAward from '../../../src/components/to-admin/create/ToServiceClinsAward.vue'
import moment from 'moment'
import Accordion from '../../components/common/Accordion.vue'
import KPI from '../../components/to-admin/KPI/KPIs.vue'
const emits = defineEmits(['reset'])
const selectedServices = ref<any[]>([])
const isServiceDataValid = ref<any>([])
const errorFlag = ref(false)
const isStateResettingNow = ref(false)
const showAllComponent = ref(false)
const createSuccess = ref(false)
const createFailure = ref(false)
const isValid = ref(false)
const detail = ref<any>({
  taskOrderNumber: '',
  agency: '',
  contractNumber: '',
  strStartDate: '',
  strEndDate: '',
  isCurrent: '',
  enWorkAuto: '',
  enhancedEmailNotification: '',
  fullyLoaded: '',
  dashboardDisplay: '',
  originalPramataId: '',
  toProgramName: '',
  toInterval: {
    numberOfDays: 0,
    typeOfDays: ''
  },
  invoiceOptions: {
    ioCustom: '',
    etems: '',
    ipp: '',
    ioDoi: ''
  },
  invoiceNumber: '',
  statusType: ''
})

interface CustomKpiDTO {
  thresholdUpdatedKpis: any[]
}
const taskOrderDTO = ref<any>()
const officialsList = ref<any[]>([])
const contactList = ref<any[]>([])
const financials = ref<any>({})
const selectedCountryAndJurisdictions = ref<any[]>([])
const taskOrderNSCList = ref<any>()
const customKpiDTO = ref<CustomKpiDTO>({ thresholdUpdatedKpis: [] })
const kpiList = ref<any[]>([])
const billingKpisList = ref<any[]>([])
const billingContacts = ref<any[]>([])
const comments = ref<string>('')
const invoiceNumber = ref<string>('')
const TODetailsRef = ref()
const TOFinancialsRef = ref()
const createToOfficialsRef = ref()
const toKPI = ref<any>({})
const refKPIs = ref([])
const originalKpis = ref([])
const serviceIds = ref<any>([])
const selectedTO = ref<any>()

const errors = ref<any>({
  firstName: false,
  lastName: false,
  workEmail: false,
  workOfficePhone: false
})
const errorMessage = ref<string>('')

const toAdminStore = useTOAdminStore()
const { isTODetailsFormValid, deleteKpiFromModal, billingUpdateListForCreate } =
  storeToRefs(toAdminStore)

function getSelectedServicesFromToServicesAndClins(this: any, selectedServicesList: any): void {
  selectedServices.value = selectedServicesList.value
  isServiceDataValid.value = validateLocationsAndCountyJurisdictions(selectedServices)
}
function validateLocationsAndCountyJurisdictions(selectedServicesList: any) {
  let servicesErrorList = ref<any>([])
  selectedServicesList.value.forEach(
    (service: {
      cbsaBased: string
      taskOrderNSCList: any
      jurisdictionCodeContry: any
      serviceID: any
    }) => {
      if (service.cbsaBased !== 'No') {
        if (
          checkJurisNscs(service.taskOrderNSCList) &&
          checkJurisNscs(service.jurisdictionCodeContry)
        ) {
          servicesErrorList.value.push(service.serviceID)
        } else if (
          !checkJurisNscs(service.taskOrderNSCList) &&
          !checkJurisNscs(service.jurisdictionCodeContry)
        ) {
          servicesErrorList.value.push(service.serviceID)
        }
      }
    }
  )
  return servicesErrorList
}
function checkJurisNscs(inList: any[]) {
  let valid = true

  if (inList === undefined || inList.length === 0) {
    valid = false
  } else if (!inList) {
    valid = false
  } else {
    console.log('checkJurisNscs222---' + JSON.stringify(inList))
  }
  return valid
}

async function create() {
  let listOfTaskOrderServicesWithClinsData = ref<any[]>([])
  errorFlag.value = false

  selectedServices.value &&
    selectedServices.value.forEach((service: any) => {
      listOfTaskOrderServicesWithClinsData.value.push(
        generateUpdateClinsAwardedObject(service, detail.value.taskOrderNumber)
      )
    })
  const flag = validate()
  if (flag) {
    errorFlag.value = true
    const taskOrder = document.getElementById('task-order-admin-top')
    if (taskOrder) {
      taskOrder.scrollIntoView(true)
    }
    return
  }
  prepareSave()
  taskOrderDTO.value.taskOrderServices = JSON.stringify(listOfTaskOrderServicesWithClinsData.value)
  try {
    const resp = await toAdminStore.createTaskOrders(taskOrderDTO.value)
    if (resp) {
      prepareCustomServiceKpis(resp.results[0].taskOrderId, resp.results[0].taskOrderNumber)
      createSuccess.value = true
      createFailure.value = false
      resetToIntialState()
    } else {
      createSuccess.value = false
      createFailure.value = true
      resetToIntialState()
    }
  } catch (err: any) {
    createSuccess.value = false
    createFailure.value = true

    errorMessage.value = err.response && err.response.data && err.response.data.message
    resetToIntialState()
  }
}

function resetToIntialState(this: any) {
  isStateResettingNow.value = true
  detail.value = {}
  showAllComponent.value = false
  isTODetailsFormValid.value = false
  selectedServices.value = []
  selectedCountryAndJurisdictions.value = []
  isServiceDataValid.value = []
  const childCOmpoent = TODetailsRef.value
  childCOmpoent.reset()

  /// emits('reset')
  //$store.dispatch("taskOrderAdmin/reset")
  isValid.value = false
}
function prepareSave() {
  taskOrderDTO.value = {}
  const details = TODetailsRef.value
  const offcialsRef = createToOfficialsRef.value
  const financialsRef = TOFinancialsRef.value

  detail.value = details.toDetailsForm()

  taskOrderDTO.value.taskOrderId = 0
  taskOrderDTO.value.taskOrderNumber = detail.value.taskOrderNumber
  taskOrderDTO.value.agencyIdentifier = detail.value.agencyIdentifier
  taskOrderDTO.value.selectedAgency = detail.value.agencyIdentifier
  taskOrderDTO.value.contractNumber = detail.value.contractNumber
  taskOrderDTO.value.strStartDate = detail.value.strStartDate
  taskOrderDTO.value.strEndDate = detail.value.strEndDate
  taskOrderDTO.value.isCurrent = 'Y'
  taskOrderDTO.value.enWorkAuto = detail.value.enWorkAuto
  taskOrderDTO.value.enhancedEmailNotification = detail.value.enhancedEmailNotification
  taskOrderDTO.value.fullyLoaded = detail.value.fullyLoaded
  taskOrderDTO.value.dashboardDisplay = detail.value.dashboardDisplay
  taskOrderDTO.value.originalPramataId = detail.value.originalPramataId
  taskOrderDTO.value.toProgramName = detail.value.toProgramName
  taskOrderDTO.value.comments = comments.value
  taskOrderDTO.value.tooDTOList = prepareOfficialsList(offcialsRef.toOfficialsForm())
  taskOrderDTO.value.taskOrderFinancialDTOList = prepareFinancials(financialsRef.toFinancialsForm())
  taskOrderDTO.value.contractorInvoiceLvlAcctNo = detail.value.invoiceNumber
  taskOrderDTO.value.ioCustom = detail.value.invoiceOptions.ioCustom === 'Y' ? 'Y' : 'N'
  taskOrderDTO.value.etems = detail.value.invoiceOptions.etems === 'Y' ? 'Y' : 'N'
  taskOrderDTO.value.ioIppSummary = detail.value.invoiceOptions.ipp === 'Y' ? 'Y' : 'N'
  taskOrderDTO.value.ioIppDetail = detail.value.invoiceOptions.ipp === 'Y' ? 'Y' : 'N'
  taskOrderDTO.value.ioDoi = detail.value.invoiceOptions.ioDoi === 'Y' ? 'Y' : 'N'
  taskOrderDTO.value.svcDeliveryDaysType = detail.value.toInterval.typeOfDays
  taskOrderDTO.value.svcDeliveryNumDays = Number(detail.value.toInterval.numberOfDays)
  if (detail.value.statusManaged) {
    taskOrderDTO.value.statusType = 'MANAGED'
  } else {
    taskOrderDTO.value.statusType = 'UNMANAGED'
  }

  taskOrderDTO.value.billingContacts = billingContacts.value
}
function prepareOfficialsList(officials: any) {
  officials.forEach((o: any) => {
    if (o.startDate) {
      o.startDate = moment(o.startDate, 'MM/DD/YYYY', true).format('YYYY-MM-DD')
      o.stopDate = o.stopDate ? moment(o.stopDate, 'MM/DD/YYYY', true).format('YYYY-MM-DD') : ''
    }
  })
  return officials
}

function prepareFinancials(financials: any) {
  financials.forEach((o: any) => {
    if (o.performanceBeginDate) {
      o.performanceBeginDate = moment(o.performanceBeginDate, 'MM/DD/YYYY', true).format(
        'YYYY-MM-DD'
      )
      o.performanceEndDate = o.performanceEndDate
        ? moment(o.performanceEndDate, 'MM/DD/YYYY', true).format('YYYY-MM-DD')
        : ''
    }
  })
  return financials
}
function generateUpdateClinsAwardedObject(service: any, toNumber: string): any {
  let clinIds = service.clins.map((clin: any) => clin.split('-')[0].trim())
  let selectedClinIds = service.selectedClins.map((clin: any) => clin.split('-')[0].trim())

  let taskOrderSvcDTO = {
    individualClins: clinIds.length === selectedClinIds.length ? 'N' : 'Y',
    allClins: clinIds.length === selectedClinIds.length ? 'Y' : 'N',
    clin: selectedClinIds.join(','),
    serviceCode: service.serviceID,
    taskOrderNSCList: service.taskOrderNSCList,
    jurisdictionCodeContry: service.jurisdictionCodeContry,
    vendorPo: service.serviceID === 'MWS' && service.vendorPo ? service.vendorPo : null
  }

  return taskOrderSvcDTO
}
function isValidDate(dateStr: string) {
  return moment(dateStr, 'MM/DD/YYYY', true).isValid()
}
function isStartDateAfter(date1: string, date2: string) {
  return moment(date1, 'MM/DD/YYYY', true).isAfter(moment(date2, 'MM/DD/YYYY', true))
}
function validateEndDate(date1: string, date2: string) {
  if (!!date2) {
    if (!isValidDate(date2)) {
      return 'Invalid Date'
    }
    if (isStartDateAfter(date1, date2)) {
      return 'End date must be greater than Start date'
    }
    return false
  }
  return false
}
function validate() {
  createFailure.value = false
  createSuccess.value = false
  const servicesErrored = validateLocationsAndCountyJurisdictions(selectedServices)
  isServiceDataValid.value = servicesErrored.value.length > 0 ? servicesErrored.value : []
  const hasErrors = checkErrors() || servicesErrored.value.length > 0
  isValid.value = errorFlag.value = hasErrors
  return hasErrors
}
function cancelCreate() {
  resetToIntialState()
}
function hasErrorsInOfficials(officials: any) {
  for (const official of officials) {
    const flag =
      !!!official.firstName ||
      !!!official.lastName ||
      !!!official.workOfficePhone ||
      !!!official.workEmail ||
      !!!official.role ||
      !!!official.startDate
    if (flag) {
      return true
    }
    if (
      official.workEmail &&
      !/(?:[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        official.workEmail
      )
    ) {
      return true
    }
    if (official.startDate && !isValidDate(official.startDate)) {
      return true
    }
    if (official.stopDate && !isValidDate(official.stopDate)) {
      return true
    }
    if (
      official.stopDate &&
      moment(official.startDate, 'MM/DD/YYYY', true).isSameOrAfter(
        moment(official.stopDate, 'MM/DD/YYYY', true)
      )
    ) {
      return true
    }
  }
  return false
}
function checkErrors() {
  const checkToDetails = TODetailsRef.value.checkEmptyFields()
  const checkToFinancials = TOFinancialsRef.value.checkFieldsFinancials()
  const checkToOfficials = createToOfficialsRef.value.checkFieldsOfficials()

  if (checkToDetails && checkToFinancials && checkToOfficials) {
    return false
  }
  return true
}
watch(isTODetailsFormValid, (newValue) => {
  console.log(newValue, 'TO FORM')
})

/* watch(deleteKpiFromModal, (newValue: any) => {
  deleteKPIs(newValue)
}) */

watch(billingUpdateListForCreate, (newValue: any) => {
  billingKpisList.value = newValue
})
function addtoCustomKPIList(updatekpi: any) {
  updatekpi.forEach((upkpi: any) => {
    let hasPrevious = false
    kpiList.value.forEach((kpi: any, i: number) => {
      if (upkpi.kpiId !== null && kpi.kpiId === upkpi.kpiId) {
        kpiList.value[i] = upkpi
        hasPrevious = true
      }
    })
    if (!hasPrevious) {
      kpiList.value.push(upkpi)
    }
  })
}
async function prepareCustomServiceKpis(taskOrderId: any, taskOrderNumber: any) {
  serviceIds.value = selectedServices.value.map((service: any) => service.serviceID)
  const billingKpiGrp = 'BILL - Billing Accuracy '
  const resp = await toAdminStore.getRefKpis()
  if (resp) {
    refKPIs.value = resp
    if (billingKpisList.value && billingKpisList.value.length > 0) {
      let index = kpiList.value.length
      billingKpisList.value.forEach((billingKPI: any) => {
        kpiList.value[index] = billingKPI
        index++
      })
    } else {
      let count = 0
      refKPIs.value.forEach((billingKPI: any) => {
        if (
          billingKPI.kpiGrpType === billingKpiGrp &&
          billingKPI.svcId === serviceIds.value[0] &&
          count < 2
        ) {
          kpiList.value.push(billingKPI)
          count++
        }
      })
    }
    originalKpis.value = refKPIs.value.filter((refKpi: any) =>
      serviceIds.value.includes(refKpi.svcId)
    )

    kpiList.value = kpiList.value.map((kpi: any) => {
      if (kpi.effectiveStartDate == null) {
        kpi.effectiveStartDate = formatDateAndTime(new Date().toISOString())
      }
      if (kpi.effectiveStartDate && moment(kpi.effectiveStartDate, 'MM/DD/YYYY', true).isValid()) {
        kpi.effectiveStartDate = `${moment(kpi.effectiveStartDate, 'MM/DD/YYYY', true).format(
          'YYYY-MM-DD'
        )}T00:00:00`
      }
      if (kpi.effectiveEndDate && moment(kpi.effectiveEndDate, 'MM/DD/YYYY', true).isValid()) {
        kpi.effectiveEndDate = `${moment(kpi.effectiveEndDate, 'MM/DD/YYYY', true).format(
          'YYYY-MM-DD'
        )}T00:00:00`
      }
      if (kpi.kpiType === 'New Custom KPI') {
        kpi.kpiId = null
      }
      kpi.taskOrderId = taskOrderId ?? null
      kpi.taskOrderNumber = taskOrderNumber ?? null
      return kpi
    })
    originalKpis.value.forEach((originalKpi: any) => {
      const foundRecord = kpiList.value.find((kpi: any) => kpi.kpiId === originalKpi.kpiId)
      if (!foundRecord) {
        originalKpi.taskOrderId = taskOrderId ?? null
        originalKpi.taskOrderNumber = taskOrderNumber ?? null
        kpiList.value.push(JSON.parse(JSON.stringify(originalKpi)))
      }
    })

    customKpiDTO.value = { thresholdUpdatedKpis: kpiList.value }
    await toAdminStore
      .createServiceKpis(customKpiDTO.value)
      .then((response: any) => {
        kpiList.value = []
      })
      .catch(() => {
        console.log('create kpi fail')
      })
  }
}
function formatDateAndTime(dateString: string): string {
  return dateString ? moment(String(dateString)).format('MM/DD/YYYY') : ''
}
// we have to get emitted delete-kpi from commonTaskOrderKpiPopup.vue page
// this function need to get called from onMounted
function deleteKPIs(kpisToDelete: any) {
  const matchedIndex = kpiList.value.findIndex((kpi: any) => kpi.kpiId === kpisToDelete?.kpiId)
  if (matchedIndex >= 0) {
    kpiList.value.splice(matchedIndex, 1)
  }
}

onUnmounted(() => (isTODetailsFormValid.value = false))
</script>
<template>
  <div class="-mt--1">
    <div id="create_task_order" class="-text--center">
      <div v-if="createSuccess" class="-mt--5 -text--center">
        <div class="chi-alert -success -text--center" id="alert-success">
          Task Order {{ taskOrderDTO.taskOrderNumber }} Successfully Created
        </div>
      </div>
      <div v-if="createFailure" class="-mt--5 -text--center">
        <div class="chi-alert -danger -text--center" id="alert-fail">
          <p>Error while creating Task Order. {{ errorMessage }}</p>
        </div>
      </div>
      <div v-if="isValid" class="-mt--5 -text--center">
        <div class="chi-alert -danger -text--center" id="alert-fail">
          <p>* Please fill in all required fields</p>
        </div>
      </div>
    </div>
    <TODetails ref="TODetailsRef" action="create" :items="selectedTO" />
    <div v-if="isTODetailsFormValid">
      <TOFinancials ref="TOFinancialsRef" />
      <CreateToOfficials ref="createToOfficialsRef" />
      <TOServiceClinsAward
        :valid="isServiceDataValid"
        ref="toServices"
        v-on:selectedServicesList="getSelectedServicesFromToServicesAndClins($event)"
      />
      <div v-if="selectedServices.length > 0">
        <Accordion title="KPIs">
          <KPI
            ref="toKPI"
            :selectedServices="selectedServices"
            :createKPI="true"
            :createKPIList="kpiList"
            :billingUpdatedList="billingKpisList"
            v-on:addCustomKpiMain="addtoCustomKPIList"
            :perfStdThreshold="''"
            :taskOrderNumber="''"
            :taskOrderId="''"
          />
        </Accordion>
      </div>
      <div>
        <div class="chi-col -mt--5 -text--center">
          <button class="chi-button -sm -mr--2 -primary" id="create" @click="create">Create</button>
          <button class="chi-button -sm" @click="cancelCreate">Cancel</button>
        </div>
        <div v-if="errorFlag" class="chi-alert -danger -mt--5 -text--center">
          Error(s) have occurred. Scroll up to display errors.
        </div>
      </div>
    </div>
  </div>
</template>
