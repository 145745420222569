import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { Tab } from '../types/common'

export const useViewStore = defineStore('taskOrderAdminViewStore', () => {
  const loading = ref(false)
  const tabs = ref<Tab[]>([
    {
      name: 'CreateTaskOrder',
      label: 'Create',
      to: '/taskOrderAdmin/create',
      breadcrumbs: [{ label: 'Task Order Admin' }, { label: 'Create' }]
    },
    {
      name: 'EditTaskOrder',
      label: 'Edit',
      to: '/taskOrderAdmin/edit',
      breadcrumbs: [{ label: 'Task Order Admin' }, { label: 'Edit' }]
    },
    {
      name: 'ExportDetailsSets',
      label: 'Export Details Sets',
      to: '/taskOrderAdmin/export',
      breadcrumbs: [{ label: 'Task Order Admin' }, { label: 'Export' }]
    }
  ])
  const activeTab = ref('')
  const tabsMap = computed(() => new Map(tabs.value.map((e) => [e.name, e])))
  return {
    loading,
    tabs,
    tabsMap,
    activeTab
  }
})
