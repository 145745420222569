<script setup lang="ts">
import { ref, defineEmits, watch, onMounted } from 'vue'
import { useColumnsStore } from './ColumnStore'
import { useStore } from './store'
import { storeToRefs } from 'pinia'

const store = useStore()
const { roleInfo, userDetail, isFormSubmitted, adminEditingAdimUser, lumenAdminEditingLumenAdmin } =
  storeToRefs(store)
const columnsStore = useColumnsStore()
const { roleInformationTypes } = columnsStore
const buttons = ref(roleInformationTypes)
const selectedRole = ref<any>(null)
const emit = defineEmits<{
  (event: 'role-selected', role: string): void
}>()

watch(
  () => userDetail.value,
  (newValue) => {
    if (newValue) {
      const userRole = roleInformationTypes.value.find(
        (type: any) => type.value === userDetail.value.userType
      )
      selectedRole.value = userRole
    }
  }
)
watch(selectedRole, (newValue) => {
  if (newValue !== null) {
    emit('role-selected', newValue)
  }
  roleInfo.value = newValue
})

watch(
  () => roleInfo.value,
  (newValue: any) => {
    if (!newValue) {
      selectedRole.value = null
    }
  }
)

watch(
  () => adminEditingAdimUser.value,
  (newValue) => {
    if (newValue && !lumenAdminEditingLumenAdmin.value) {
      const superAdminOption = { label: 'Super Admin', value: 'SUPERADMIN' }
      buttons.value.push(superAdminOption)
      selectedRole.value = superAdminOption
    }
  }
)
</script>

<template>
  <fieldset class="-mx--2">
    <legend class="chi-label">
      Role Information
      <abbr class="chi-label__required" aria-label="Required field">*</abbr>
    </legend>
    <div class="chi-picker-group -fluid">
      <div class="chi-picker-group__content">
        <div v-for="button of buttons" :key="button.value">
          <input
            class="chi-picker__input"
            type="radio"
            name="userTypes"
            :id="button.value"
            :value="button"
            v-model="selectedRole"
            :disabled="adminEditingAdimUser"
          />
          <label :for="button.value">{{ button.label }}</label>
        </div>
      </div>
    </div>
    <div class="chi-label -status -danger" v-if="isFormSubmitted && !selectedRole">
      <i class="chi-icon icon-circle-warning" aria-hidden="true"></i>
      Please select an option
    </div>
  </fieldset>
</template>

<style></style>
