<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { debounce } from 'perfect-debounce'
import { sliceNonEmpty, randomId, delay, Tooltip } from '@centurylinkfederal/eis-vue'
import type { Column, InputType, SettingsType, Option } from '@centurylinkfederal/eis-vue'

const defaultSettings: any = {
  debounce: 200,
  actions: []
}
export interface Props {
  options?: Option[] | undefined
  valueSet?: Set<string | number> | undefined
  label?: string
  chiLabel?: SettingsType
  chiSearch?: SettingsType
  settings?: SettingsType
  focusSeq?: number | string | undefined
}
const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  settings: () => {
    return {}
  },
  chiLabel: () => {
    return {}
  },
  chiSearch: () => {
    return {}
  },
  valueSet: () => new Set(),
  focusSeq: undefined,
  inEditMode: undefined
})
const emit = defineEmits(['onChange'])
const cid = ref('PickerBox-' + randomId())
const options = ref(props.options)
const settings = ref({ ...defaultSettings, ...props?.settings })
const valueSet = ref(new Set(props.valueSet))
const inputText = ref('')
const chiInputRef = ref()
const selectAllActive = ref(false)
const unselectAllActive = ref(false)
const searchOptions = computed(() =>
  (options.value ?? []).map((e: Option) => (Array.isArray(e) ? e : [e, e]))
)
const searchOptionsFiltered = computed(() =>
  searchOptions.value.filter((f: any[]) => new RegExp(inputText.value, 'gi').test(f[1]))
)
watch(
  () => props.options,
  (newValue) => {
    options.value = newValue
  },
  { deep: false }
)
watch(
  () => props.valueSet,
  (newValue) => {
    valueSet.value = newValue
  },
  { deep: false }
)
watch(() => props.focusSeq, onChiFocus)

const onChiInput = async (event: any): Promise<void> => {
  if (!event) {
    return
  }
  let newText = String(event?.detail ?? '')
  inputText.value = newText
}

function chiInputTweaks(): void {
  if (!chiInputRef.value) {
    return
  }
  const inputEls = chiInputRef.value.getElementsByClassName('chi-input')
  const inputEl = inputEls?.[0]
  if (!inputEl) {
    return
  }
  inputEl.ariaLabel = chiInputRef.value.ariaLabel
  inputEl.value = inputText.value
}
function onClick(value: string | number): void {
  let action
  if (valueSet.value.has(value)) {
    valueSet.value.delete(value)
    action = 'delete'
    unselectAllActive.value = false
    selectAllActive.value = false
  } else {
    valueSet.value.add(value)
    action = 'add'
    unselectAllActive.value = false
  }
  emit('onChange', { newSet: valueSet.value, value, action })
}
function onChiClear(): void {
  inputText.value = ''
  const inputEl = chiInputRef.value?.childNodes?.[0]?.firstChild
  if (!inputEl) {
    return
  }
  inputEl.value = inputText.value
  inputEl.focus()
}
function onChiFocus(): void {
  setTimeout(() => {
    const inputEl = chiInputRef.value?.childNodes?.[0]?.firstChild
    if (!inputEl) {
      return
    }
    inputEl.focus()
  })
}
function onSelectAll(): void {
  let action
  if (selectAllActive.value) {
    onUnselectAll()
    action = 'delete'
  } else {
    unselectAllActive.value = false
    selectAllActive.value = true
    searchOptionsFiltered.value.forEach(([value]) => {
      valueSet.value.add(value)
    })
    action = 'add'
  }
  emit('onChange', { newSet: valueSet.value, action })
}
function onUnselectAll(): void {
  unselectAllActive.value = true
  selectAllActive.value = false
  valueSet.value.clear()
}
onMounted(() => {
  chiInputRef.value?.addEventListener?.('chiInput', onChiInput)
  chiInputRef.value?.addEventListener?.('chiClean', onChiClear)
  if (props.focusSeq) {
    onChiFocus()
  }
})
onUnmounted(() => {
  chiInputRef.value?.removeEventListener?.('chiInput', onChiInput)
  chiInputRef.value?.removeEventListener?.('chiClean', onChiClear)
})
</script>
<template>
  <div class="-d--flex -justify-content--between" v-show="searchOptions.length > 0">
    <div class="-flex--grow1">
      <chi-label v-if="!settings.noLabel && label" :for="cid" v-bind="chiLabel">{{
        label
      }}</chi-label>
      <chi-search-input
        ref="chiInputRef"
        :id="cid"
        v-bind="chiSearch"
        :disabled="searchOptions.length === 0"
        @keydown.esc="onChiClear"
      />
      <div class="chi-label -status" v-if="chiSearch.helperMessage">
        {{ chiSearch.helperMessage }}
      </div>
    </div>
    <div class="chi-divider -vertical" v-if="settings.actions.length > 0" />
    <div class="" v-if="settings.actions.length > 0">
      <Tooltip :content="selectAllActive ? 'Unselect All' : 'Select All'" placement="bottom">
        <button
          v-if="settings.actions.includes('selectAll')"
          :aria-label="selectAllActive ? 'Unselect All' : 'Select All'"
          class="chi-button -secondary -flat -md -icon -pt--0"
          @click="onSelectAll()"
        >
          <div class="chi-button__content">
            <i
              class="chi-icon -md"
              :class="{
                'icon-checklist': selectAllActive,
                'icon-checklist-outline': !selectAllActive
              }"
              aria-hidden="true"
            ></i>
          </div>
        </button>
      </Tooltip>
    </div>
  </div>
  <div
    v-if="searchOptions.length > 0"
    ref="chiMenuRef"
    class="chi-dropdown__menu -b--1 -mt--2 -s--0 -active -overflow--auto"
    v-bind:class="settings.class"
    v-bind:style="settings.style"
  >
    <a
      v-for="([value, text], index) in searchOptionsFiltered"
      :key="index"
      class="chi-dropdown__menu-item -user-select--none"
      :class="{ '-active': valueSet.has(value) }"
      slot="menu"
      @click="onClick(value)"
      @keydown.esc="onChiClear"
      >{{ text }}</a
    >
    <div
      v-if="searchOptionsFiltered.length === 0"
      class="chi-data-table__row-empty"
      :style="{ marginTop: '18%' }"
    >
      <div>
        <i
          class="chi-icon icon-filter-alt-outline -sm--2 -vertical-align--bottom"
          aria-hidden="true"
        ></i>
        <span class="-ml--1">There are no matching options.</span>
      </div>
    </div>
  </div>
  <div
    class="chi-data-table__row-empty -bg--info-lighter -mt--1"
    :style="{ marginTop: '18%' }"
    v-if="searchOptions.length === 0"
  >
    <div>
      <i
        class="chi-icon icon-file-error-outline -sm--2 -vertical-align--bottom"
        aria-hidden="true"
      ></i>
      <span class="-ml--1">There are no options to choose from.</span>
    </div>
  </div>
</template>
<style scoped>
.cursor--pointer {
  cursor: pointer;
}
.-user-select--none {
  user-select: none;
}
</style>
