export const USER_SESSION_STORAGE_KEYS = {
  NAV_ITEMS: 'eis.portal.nav_items',
  USER_INFO: 'eis.portal.userinfo'
}
export function parseNavPaths(nav: any[]): string[] {
  const paths: string[] = []

  nav.forEach((item: any) => {
    if (item.path) {
      paths.push(item.path)
    }
    if (Array.isArray(item.subItems)) {
      const subPaths = parseNavPaths(item.subItems)
      paths.push(...subPaths)
    }
  })
  return paths
}
export function getUserPaths(): string[] {
  const nav = JSON.parse(sessionStorage.getItem(USER_SESSION_STORAGE_KEYS.NAV_ITEMS) || '[]') ?? []
  const paths = parseNavPaths(nav)
  return paths
}
