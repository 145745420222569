<script lang="ts" setup>
import { defineProps, withDefaults, ref, onMounted } from 'vue'
import { useTOAdminStore } from '../../../store/to-admin'
import { storeToRefs } from 'pinia'
import moment from 'moment'
import ViewKPI from './ViewKPI.vue'
export interface Props {
  selectedOnChange: any
  createKPI: boolean
  taskOrderNumber: any
  taskOrderId: any
  tabName: string
  billingEditedList: any
  billingUpdatedList: any
  createCustomKPIList: any
}
const props = withDefaults(defineProps<Props>(), {
  selectedOnChange: undefined,
  createKPI: undefined,
  taskOrderNumber: undefined,
  taskOrderId: undefined,
  tabName: undefined,
  billingEditedList: undefined,
  billingUpdatedList: undefined,
  createCustomKPIList: undefined
})
const emit = defineEmits(['Billing-KPIS', 'addCustomKPI'])
const toAdminStore = useTOAdminStore()
const { billingUpdateListForCreate } = storeToRefs(toAdminStore)
const isLoading = ref(false)
const refKPIs = ref<any[]>([])
const kpiGrp = ref('BILL - Billing Accuracy ')
const aQLOperator = ref(['<', '>', '<=', '>='])
const aQLOperatorDescription = ref([
  '< Less Than',
  '> Greater Than',
  '<= Less Than or Equal To',
  '>= Greater Than or Equal To'
])
const measurementUnit = ref([
  'PERCET',
  'dB',
  'Hours',
  'Minute',
  'MOS',
  'ms',
  'Second',
  'Day',
  'DAYBUS'
])
let effectiveStartDateKey = ref<string>('')
let effectiveEndDateKey = ref<string>('')
const billingKPIs = ref<any[]>([])
const popUpColumn = ref([
  { name: 'kpi', value: 'KPI' },
  {
    name: 'perfStdThreshold',
    value: 'Performance Standard (Threshold)'
  },
  { name: 'aqlOperator', value: 'AQL Operator' },
  { name: 'measurementUnit', value: 'Measurement Unit' },
  { name: 'effectiveStartDate', value: 'Effective Start Date' },
  { name: 'effectiveEndDate', value: 'Effective End Date' }
])

onMounted(() => {
  isLoading.value = true
  if (!props.createKPI) {
    toAdminStore.getAllKPIs(props.taskOrderId).then((response: any) => {
      refKPIs.value = response
      prepareBillingData()
    })
  } else {
    toAdminStore
      .getRefKpis()
      .then((response: any) => {
        refKPIs.value = response
        prepareBillingData()
        if (props.billingUpdatedList && props.billingUpdatedList.length > 0) {
          billingKPIs.value.forEach((kpi: any, index) => {
            props.billingUpdatedList.forEach((billing: any) => {
              if (kpi.kpiId === billing.kpiId) {
                billingKPIs.value[index] = billing
                console.log(billingKPIs.value)
              }
            })
          })
        }
      })
      .catch(() => {
        isLoading.value = false
      })
  }
})
function formatDateAndTime(dateString: string): string {
  return dateString ? moment(String(dateString)).format('MM/DD/YYYY') : ''
}
function prepareBillingData() {
  let count = 0
  billingKPIs.value = refKPIs.value.filter((kpi) => {
    if (
      kpi.kpiGrpType === kpiGrp.value &&
      kpi.svcId === props.selectedOnChange[0].serviceID &&
      count < 2
    ) {
      count++
      return kpi
    }
  })

  billingKPIs.value.forEach((kpi, index) => {
    kpi.effectiveStartDate = formatDateAndTime(kpi.effectiveStartDate)
    kpi.effectiveEndDate = formatDateAndTime(kpi.effectiveEndDate)
    if (
      kpi.customPrefStdThresHold === '' ||
      kpi.customPrefStdThresHold === undefined ||
      kpi.customPrefStdThresHold === null
    ) {
      kpi.customPrefStdThresHold = kpi.perfStdThreshold
    }
  })

  isLoading.value = false
}

async function captureValue(type: any, value: any, index: any) {
  switch (type) {
    case 'kpiaoAqlQOprDesc':
      const descIndex = aQLOperator.value.findIndex((op) => op.includes(value))
      billingKPIs.value[index].kpiaoAqlQOprCode = descIndex + 1
      billingKPIs.value[index].kpiaoAqlQOprDesc = aQLOperatorDescription.value[descIndex]
      break
    case 'kpimuMeasUnitCode':
      billingKPIs.value[index].kpimuMeasUnitDesc = value
      billingKPIs.value[index].kpimuMeasUnitCode = value
      break
    case 'effectiveStartDate':
      billingKPIs.value[index].effectiveStartDate = value
      formatDateAndTime(billingKPIs.value[index].effectiveStartDate)
      break
    case 'effectiveEndDate':
      billingKPIs.value[index].effectiveEndDate = value
      formatDateAndTime(billingKPIs.value[index].effectiveEndDate)
      break
  }

  billingUpdateListForCreate.value = billingKPIs.value
}
function mapOptionToValue(op: any) {
  switch (op) {
    case '>= Greater Than or Equal To':
      return '>='
    case '<= Less Than or Equal To':
      return '<='
    case '> Greater Than':
      return '>'
    default:
      return op
  }
}
const decimalRegex = (evt: any) => {
  const regex = /^\d*\.?\d{2}$/
  const val = evt.key
  const value = `${evt.target.value}${val}`
  const arr = value.split('.')
  let length = arr.length > 1 ? arr[1].length : 1
  let isDecimal = length > 3
  var charCode = evt.which ? evt.which : evt.keyCode
  if ((charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) || isDecimal) {
    evt.preventDefault()
  }
}
const addCustomKpi = (updatekpi: any) => {
  updatekpi.effectiveStartDate = `${moment.utc().format('YYYY-MM-DD')}T00:00:00`
  updatekpi.stdCustomIndicator = 'Custom KPI'
  emit('addCustomKPI', updatekpi)
}
</script>

<template>
  <div class="chi-card -portal -bg--white -bg-md--grey-15">
    <div class="chi-card__content -p--0">
      <div class="chi-data-table -portal -compact">
        <div class="table-container">
          <div class="chi-data-table__head">
            <div class="chi-data-table__row">
              <div
                v-for="header in popUpColumn"
                :key="header.name"
                class="chi-data-table__cell -cell-wrap"
              >
                {{ header.value }}
              </div>
            </div>
          </div>
          <div v-if="isLoading" class="chi-data-table__body">
            <div class="loading-container">
              <svg class="chi-spinner -icon--primary -sm" viewBox="0 0 66 66">
                <title>Loading</title>
                <circle class="path" cx="33" cy="33" r="30" fill="none" stroke-width="6"></circle>
              </svg>
            </div>
          </div>
          <div v-else class="chi-data-table__body">
            <div
              v-for="(kpirec, index) in billingKPIs"
              :key="kpirec.kpiId"
              class="chi-data-table__row"
              :class="{
                '-striped': index % 2 === 1
              }"
            >
              <div class="chi-data-table__cell">
                <div>
                  {{ kpirec.kpiucUnitCodeDesc }}
                </div>
              </div>
              <div class="chi-data-table__cell">
                <input
                  :id="`threshold-${index}`"
                  v-model="kpirec.customPrefStdThresHold"
                  required
                  class="chi-input"
                  style="width: 150px"
                  type="text"
                  @keypress="decimalRegex"
                  @change="captureValue('customPrefStdThresHold', null, index)"
                />
              </div>
              <div class="chi-data-table__cell">
                <div class="chi-dropdown -fluid">
                  <select
                    class="chi-input"
                    @change="
                      captureValue(
                        'kpiaoAqlQOprDesc',
                        ($event.target as HTMLInputElement).value,
                        index
                      )
                    "
                  >
                    <option disabled value="">- Select One -</option>
                    <option
                      v-for="(op, index) in aQLOperator"
                      :key="index"
                      :value="mapOptionToValue(op)"
                      :selected="op === mapOptionToValue(kpirec.kpiaoAqlQOprDesc)"
                    >
                      {{ op }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="chi-data-table__cell">
                <div class="chi-dropdown -fluid">
                  <select
                    v-model="kpirec.kpimuMeasUnitCode"
                    class="chi-input"
                    @change="
                      captureValue(
                        'kpimuMeasUnitCode',
                        ($event.target as HTMLInputElement).value,
                        index
                      )
                    "
                  >
                    <option v-for="mu in measurementUnit" :key="mu">
                      {{ mu }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="chi-data-table__cell">
                <DatePicker
                  :id="`start-date-${index}`"
                  :key="effectiveStartDateKey"
                  :value="formatDateAndTime(kpirec.effectiveStartDate)"
                  label=""
                  format="MM/DD/YYYY"
                  @change="captureValue('effectiveStartDate', $event, index)"
                ></DatePicker>
              </div>
              <div class="chi-data-table__cell">
                <DatePicker
                  :id="`end-date-${index}`"
                  :key="effectiveEndDateKey"
                  :value="formatDateAndTime(kpirec.effectiveEndDate)"
                  label=""
                  format="MM/DD/YYYY"
                  @change="captureValue('effectiveEndDate', $event, index)"
                ></DatePicker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ViewKPI
    :billingEditedList="billingKPIs"
    :selectedOnChange="selectedOnChange"
    tabName="billing"
    :taskOrderNumber="taskOrderNumber"
    :taskOrderId="taskOrderId"
    :kpiGrp="kpiGrp"
    :createKPI="createKPI"
    :createCustomKPIList="createCustomKPIList"
    v-on:addCustomKpi="addCustomKpi"
    :createKPIList="''"
    :perfStdThreshold="''"
  />
</template>
<style scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust this value based on your needs */
  width: 100%;
}
</style>
