<script lang="ts" setup>
import moment from 'moment'
import ImportFile from '../../ImportFile.vue'
import { ref, defineProps, onMounted, Ref } from 'vue'
import { populateOfficialRow } from '../../../utils/to-admin.utils'
import {
  generateOfficial,
  officialImportFields,
  officialImportHeaders
} from '../../../utils/to-admin.utils'

const officials: any = ref([])
const errorMsg = ref('')
const showOfficialImportPopup = ref(false)
const validationError = ref(false)
const taskOrderDetails: any = ref([])
const errors = ref({
  firstName: false,
  lastName: false,
  workEmail: false,
  workOfficePhone: false
})
const fields = officialImportFields
const headers = officialImportHeaders
const showImportModal = ref(false)
const isExpanded: Ref<boolean> = ref<boolean>(true)

onMounted(() => {
  officials.value = [populateOfficialRow()]
})

function alertDismissed() {
  errorMsg.value = ''
}

function deleteOfficial(official: any) {
  officials.value = officials.value.filter((result: any) => result.id.value !== official.id.value)
}

function addOfficial() {
  const official = populateOfficialRow()
  officials.value.push(official)
}

function onDateChange(e: any, field: any, field2?: any, type?: string) {
  field.value = e
  onFieldValueChange(field, field2, type)
}

function onFieldValueChange(field: any, field2?: any, type?: string) {
  field.error = null
  if (field2) {
    field2.error = null
  }
  for (let validator of field.validators) {
    const params =
      type === 'startDate'
        ? [field.value, field2.value]
        : type === 'endDate'
          ? [field2.value, field.value]
          : []
    const result = field2?.value ? validator(...params) : validator(field.value)
    if (result) {
      const [key, value]: any = Object.entries(result)?.[0]
      field.error = {}
      field.error[key] = value
      break
    }
  }
}

function openDialog() {
  showOfficialImportPopup.value = true
}

function importedFileInfo(details: any = {}) {
  const { contents = [] } = details
  const importedOfficals = contents.map((content: any) => {
    const startDate = (content.startDate && moment(content.startDate).format('MM/DD/YYYY')) || ''
    const stopDate = (content.stopDate && moment(content.stopDate).format('MM/DD/YYYY')) || ''
    const officialData = populateOfficialRow()
    officialData.startDate.value = startDate
    officialData.endDate.value = stopDate
    officialData.firstName.value = content.firstName
    officialData.lastName.value = content.lastName
    officialData.workOfficePhone.value = content.workOfficePhone
    officialData.workMobilePhone.value = content.workMobilePhone
    officialData.workEmail.value = content.workEmail
    officialData.role.value = content.role

    return officialData
  })
  officials.value = [...officials.value, ...(importedOfficals || [])]
  showOfficialImportPopup.value = false
}

function toggleAccordian() {
  isExpanded.value = !isExpanded.value
}

function checkFieldsOfficials() {
  let checkField = false
  officials.value.forEach((official: any) => {
    if (
      official.firstName.value === '' ||
      official.lastName.value === '' ||
      official.role.value === '' ||
      official.workEmail.value === '' ||
      official.workOfficePhone.value === '' ||
      official.startDate.value === '' ||
      official.endDate.value === ''
    ) {
      checkField = true
    }
  })
  if (checkField) {
    return false
  }
  return true
}

function toOfficialsForm() {
  const officialDetials = officials.value.map((official: any) => ({
    firstName: official.firstName.value,
    lastName: official.lastName.value,
    role: official.role.value,
    id: official.id.value,
    startDate: official.startDate.value,
    stopDate: official.endDate.value,
    workEmail: official.workEmail.value,
    workOfficePhone: official.workOfficePhone.value,
    workMobilePhone: official.workMobilePhone.value,
    isNew: true
  }))
  return officialDetials
}

defineExpose({
  checkFieldsOfficials,
  toOfficialsForm
})

function allowNumbersOnly(e: any) {
  const numRegex = /[^0-9]+/g
  e.target.value = e.target.value.replace(numRegex, '')
}
</script>

<template>
  <div class="chi-accordion -md" id="example-size-md">
    <div class="chi-accordion__item" :class="isExpanded ? '-expanded' : ''">
      <button class="chi-accordion__trigger" @click="toggleAccordian()">
        <i class="chi-icon icon-chevron-down" aria-hidden="true"></i>
        <div class="chi-accordion__title">To officials</div>
      </button>
      <div class="chi-accordion__content" style="overflow: visible">
        <div v-for="(official, index) in officials" :key="index">
          <div class="chi-grid -mb--4" style="position: relative">
            <div class="chi-col">
              <div class="m-form__item">
                <chi-label required>First Name</chi-label>
                <input
                  type="text"
                  :class="[official.firstName.error?.required ? 'chi-input -danger' : 'chi-input']"
                  v-model="official.firstName.value"
                  @input="onFieldValueChange(official.firstName)"
                  @blur="onFieldValueChange(official.firstName)"
                />
              </div>
            </div>
            <div class="chi-col">
              <div class="m-form__item">
                <div class="m-form__item">
                  <chi-label required>Last Name</chi-label>
                  <input
                    type="text"
                    class="chi-input"
                    :class="[official.lastName.error?.required ? 'chi-input -danger' : 'chi-input']"
                    v-model="official.lastName.value"
                    @input="onFieldValueChange(official.lastName)"
                    @blur="onFieldValueChange(official.lastName)"
                  />
                </div>
              </div>
            </div>
            <div class="chi-col">
              <div class="m-form__item">
                <chi-label required>Work Phone</chi-label>
                <input
                  type="text"
                  class="chi-input"
                  :class="[
                    official.workOfficePhone.error?.required ? 'chi-input -danger' : 'chi-input'
                  ]"
                  v-model="official.workOfficePhone.value"
                  @input="allowNumbersOnly($event)"
                  @blur="onFieldValueChange(official.workOfficePhone)"
                  maxlength="10"
                />
              </div>
            </div>
            <div class="chi-col">
              <div class="m-form__item">
                <div class="m-form__item">
                  <chi-label>Work Mobile</chi-label>
                  <input
                    type="text"
                    class="chi-input"
                    v-model="official.workMobilePhone.value"
                    maxlength="10"
                    @input="allowNumbersOnly($event)"
                  />
                </div>
              </div>
            </div>
            <div class="chi-col">
              <div class="m-form__item">
                <div class="m-form__item">
                  <chi-label required>Work Email</chi-label>
                  <input
                    type="text"
                    class="chi-input"
                    :class="[
                      official.workEmail.error?.required || official.workEmail.error?.invalidEmail
                        ? 'chi-input -danger'
                        : 'chi-input'
                    ]"
                    v-model="official.workEmail.value"
                    @input="onFieldValueChange(official.workEmail)"
                    @blur="onFieldValueChange(official.workEmail)"
                  />
                </div>
                <div
                  class="chi-label -status -danger"
                  v-if="official.workEmail.error?.invalidEmail"
                >
                  <chi-icon icon="circle-warning"></chi-icon>
                  Not a valid email
                </div>
              </div>
            </div>
            <div class="chi-col">
              <div class="m-form__item">
                <div class="m-form__item">
                  <chi-label required>Role</chi-label>
                  <select
                    class="chi-input"
                    v-model="official.role.value"
                    @change="onFieldValueChange(official.role)"
                    @blur="onFieldValueChange(official.role)"
                    :class="[official.role.error?.required ? 'chi-input -danger' : 'chi-input']"
                  >
                    <option value="">- Select -</option>
                    <option vaue="COR">COR</option>
                    <option vaue="OCO">OCO</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="chi-col -w2">
              <div class="m-form__item">
                <DatePicker
                  label="Start Date"
                  :id="`offStartDate_${index}`"
                  :required="true"
                  class="z--70"
                  :value="official.startDate.value"
                  :state="
                    official.startDate.error?.required || official.startDate.error?.dateAfter
                      ? 'danger'
                      : ''
                  "
                  :helperMessage="
                    official.startDate.error?.dateAfter
                      ? ' Start date shouldn\'t be greater than end date.'
                      : ''
                  "
                  @change="onDateChange($event, official.startDate, official.endDate, 'startDate')"
                  @focusout="onFieldValueChange(official.startDate, official.endDate, 'startDate')"
                >
                </DatePicker>
              </div>
            </div>
            <div class="chi-col -w2">
              <div class="m-form__item">
                <DatePicker
                  label="End Date"
                  :id="`offEndDate_${index}`"
                  :required="true"
                  class="z--70"
                  :value="official.endDate.value"
                  :state="
                    official.endDate.error?.required || official.endDate.error?.dateAfter
                      ? 'danger'
                      : ''
                  "
                  :helperMessage="
                    official.endDate.error?.dateAfter
                      ? 'End date should be greater than start date.'
                      : ''
                  "
                  @change="onDateChange($event, official.endDate, official.startDate, 'endDate')"
                  @focusout="onFieldValueChange(official.endDate, official.startDate, 'endDate')"
                >
                </DatePicker>
              </div>
            </div>

            <div class="icon remove-icon">
              <i
                style="cursor: pointer"
                class="chi-icon icon-delete -icon--primary"
                @click="deleteOfficial(official)"
                aria-hidden="true"
                v-if="index !== 0"
              ></i>
            </div>
          </div>
        </div>
        <div class="-mt--5 -text--center">
          <button class="chi-button -sm -mr--2 -primary" @click="addOfficial">Add Official</button>
          <button class="chi-button -sm -mr--2 -outline" @click="showImportModal = true">
            Import
          </button>
        </div>
        <div v-if="showImportModal">
          <ImportFile
            :headerDetails="headers"
            :dataFields="fields"
            title="Official"
            @closeModal="showImportModal = false"
            @importContent="importedFileInfo($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.remove-icon {
  position: absolute;
  right: 0;
  bottom: 7px;
  margin-right: -9px;
}
</style>
