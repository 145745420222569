<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useDbasStore } from './dbasStore'
import { useApi } from '@centurylinkfederal/eis-vue'
const dbasStore = useDbasStore()
const {
  isModalClosed,
  isSpinnerVisible,
  dbasAssociationIdOfSelectedTaskOrder,
  requestXML,
  responseXML,
  selectedTaskOrderNumber,
  selectedTaxExempt,
  displayTOError,
  displayTaxExemptError,
  successAlerts,
  errorAlerts,
  accountId,
  dbasFormData
} = storeToRefs(dbasStore)
const api = useApi(import.meta.env.VITE_EIS_API_BASE)

const openModal = async () => {
  errorAlerts.value = []
  successAlerts.value = []
  if (!selectedTaskOrderNumber.value) {
    displayTOError.value = true
    return
  }
  if (!dbasAssociationIdOfSelectedTaskOrder.value) {
    errorAlerts.value.push('DBAS Association not found for the selected Task Order.')
    return
  }
  isSpinnerVisible.value = true
  try {
    const res: any = await api.get(
      `/dbas/xml-details/${dbasAssociationIdOfSelectedTaskOrder.value}`
    )
    requestXML.value = res.xmlRequest
    responseXML.value = res.xmlResponse
  } catch (err) {
    errorAlerts.value.push('Error while fetching XML details. Please try again later.')
    console.error(err)
    isSpinnerVisible.value = false
    return
  }
  isModalClosed.value = false
  isSpinnerVisible.value = false
}

const closeModal = () => {
  isModalClosed.value = true
}

const sendDbas = async () => {
  errorAlerts.value = []
  successAlerts.value = []
  if (!selectedTaskOrderNumber.value) {
    displayTOError.value = true
  }
  if (!selectedTaxExempt.value) {
    displayTaxExemptError.value = true
  }
  if (displayTOError.value || displayTaxExemptError.value) {
    return
  }
  isSpinnerVisible.value = true

  try {
    const res: any = await api.post('/dbas/send', {
      accountId: accountId.value,
      contractNumber: dbasFormData.value.contractnumber,
      agencyIdentifier: dbasFormData.value.agencyIdentifier,
      taskOrderNumber: selectedTaskOrderNumber.value,
      taxExempt: selectedTaxExempt.value
    })
    dbasAssociationIdOfSelectedTaskOrder.value = res.dbasAssociationId
  } catch (err: any) {
    if (err.response.status === 409) {
      errorAlerts.value.push('Cannot send duplicate DBAS.')
    } else {
      errorAlerts.value.push('Error while sending DBAS. Please try again later.')
    }
    console.error(err)
    isSpinnerVisible.value = false
    return
  }

  successAlerts.value.push('DBAS sent successfully')

  isSpinnerVisible.value = false
}
</script>
<template>
  <div class="chi-grid">
    <div class="chi-col -w--6 -w-md--10">
      <div class="-d--flex -justify-content--end">
        <button class="chi-button" @click="openModal">View</button>
      </div>
    </div>
    <div class="chi-col -w--1 -w-md--2">
      <div class="-d--flex -justify-content--start">
        <button class="chi-button -primary" @click="sendDbas">Send</button>
      </div>
    </div>
  </div>

  <div class="chi-backdrop -center" :class="{ '-closed': isModalClosed }">
    <div class="chi-backdrop__wrapper">
      <section
        id="modal-2"
        class="chi-modal"
        role="dialog"
        aria-label="Modal description"
        aria-modal="true"
      >
        <header class="chi-modal__header">
          <h2 class="chi-modal__title">Conexus Information</h2>
          <button
            class="chi-button -icon -close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <div class="chi-button__content">
              <i class="chi-icon icon-x" aria-hidden="true"></i>
            </div>
          </button>
        </header>
        <div class="chi-modal__content">
          <div class="chi-form__item -mb--3">
            <label class="chi-label">Request</label>
            <textarea v-model="requestXML" class="chi-input" readonly></textarea>
          </div>
          <div class="chi-form__item">
            <label class="chi-label">Response</label>
            <textarea v-model="responseXML" class="chi-input" readonly></textarea>
          </div>
        </div>
        <footer class="chi-modal__footer">
          <button class="chi-button" data-dismiss="modal" @click="closeModal">OK</button>
        </footer>
      </section>
    </div>
  </div>
  <div v-show="isSpinnerVisible" class="chi-backdrop -center">
    <div class="chi-backdrop__wrapper">
      <svg class="chi-spinner -icon--light" viewBox="0 0 66 66">
        <title>Processing</title>
        <circle class="path" cx="33" cy="33" r="30" fill="none" stroke-width="6"></circle>
      </svg>
    </div>
  </div>
</template>
