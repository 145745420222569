<script lang="ts" setup>
import { onMounted, ref, watchEffect, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { DataTable } from '@centurylinkfederal/eis-vue'
import { useTOAdminStore } from '../../store/to-admin'
import Accordion from '../common/Accordion.vue'
import moment from 'moment'
import TOActivityLogDownloadModal from './TOActivityLogDownloadModal.vue'
import { useToAdminAPI } from '../../composables/to-admin/api'

const toAdminStore = useTOAdminStore()
const api = useToAdminAPI()
const { activityLogResponse, selectedTO, kpiActivityLogResponse } = storeToRefs(toAdminStore)
const pagination = ref<any>({ currentPage: 1, totalResults: 0, rowsPerPage: 20 })
const sortParams = ref<any>([])
const showSelectedModDownload = ref<boolean>(false)
const showAllModDownload = ref<boolean>(false)
const showKPIModDownload = ref<boolean>(false)

const ActicityLogErrorCodes = {
  NO_DATA: 'acitivity_log.no_data',
  FETCHING_ERROR: 'activity_log.error_fetching',
  DOWNLOAD_ERROR: 'activity_log.error_downloading_psv'
}

const KPILogErrorCodes = {
  NO_DATA: 'kpi_log.no_data',
  FETCHING_ERROR: 'kpi_log.error_fetching',
  DOWNLOAD_ERROR: 'kpi_log.error_downloading_psv'
}

const errorMessages: Record<string, string> = {
  [ActicityLogErrorCodes.NO_DATA]: 'No Data',
  [ActicityLogErrorCodes.FETCHING_ERROR]: 'Error Fetching Data',
  [ActicityLogErrorCodes.DOWNLOAD_ERROR]: 'Erro Downloading File',
  [KPILogErrorCodes.NO_DATA]: 'No Data',
  [KPILogErrorCodes.FETCHING_ERROR]: 'Error Fetching Data',
  [KPILogErrorCodes.DOWNLOAD_ERROR]: 'Erro Downloading File'
}

const errors = ref<string[]>([])

onMounted(async () => {
  const response: any = await toAdminStore.fetchTaskOrderActivities(serverParams.value)
  if (response.totalRecords < 1) {
    addAlert(ActicityLogErrorCodes.NO_DATA)
  }
  await toAdminStore.getKPIActivityLog(serverParamsKPI.value)
})

watchEffect(() => {
  pagination.value.totalResults = activityLogResponse.value.totalRecords
})

const serverParams = computed(() => {
  const params: any = {
    offset:
      pagination.value.rowsPerPage === '_all'
        ? 0
        : pagination.value.rowsPerPage * (pagination.value.currentPage - 1),
    limit:
      pagination.value.rowsPerPage === '_all'
        ? pagination.value.totalResults
        : pagination.value.rowsPerPage
  }
  if (sortParams.value.length) {
    params.sortField = sortParams.value[0]
    params.sortDirection = sortParams.value[1]
  }
  return params
})

const serverParamsKPI = computed(() => {
  const params: any = {
    offset:
      pagination.value.rowsPerPage === '_all'
        ? 0
        : pagination.value.rowsPerPage * (pagination.value.currentPage - 1),
    limit:
      pagination.value.rowsPerPage === '_all'
        ? pagination.value.totalResults
        : pagination.value.rowsPerPage
  }
  params.sortBy = 'sectionName'
  params.sortDirection = 'desc'
  return params
})

watchEffect(async () => {
  try {
    await toAdminStore.fetchTaskOrderActivities(serverParams.value)
  } catch (e) {
    addAlert(ActicityLogErrorCodes.FETCHING_ERROR)
  }
  try {
    await toAdminStore.getKPIActivityLog(serverParamsKPI.value)
  } catch (e) {
    addAlert(KPILogErrorCodes.FETCHING_ERROR)
  }
})

const columns = [
  {
    label: 'Date & Time',
    field: 'createdOn',
    isSortable: true
  },
  {
    label: 'Action Type',
    field: 'changeType',
    isSortable: true
  },
  {
    label: 'Modified By',
    field: 'createdBy',
    isSortable: true
  },
  {
    label: 'Section Name Updated DB Table',
    field: 'taskOrderEditSection',
    isSortable: true
  },
  {
    label: 'Field Name / Data Element Name',
    field: 'field',
    isSortable: true
  },
  {
    label: 'Previous Value',
    field: 'oldValue'
  },
  {
    label: 'Latest Value',
    field: 'newValue'
  }
]

const Kpicolumns = [
  {
    label: 'Date & Time',
    field: 'createdOn',
    isSortable: true
  },
  {
    label: 'Action Type',
    field: 'actionType',
    isSortable: true
  },
  {
    label: 'Modified By',
    field: 'createdBy',
    isSortable: true
  },
  {
    label: 'Section Name Updated DB Table',
    field: 'sectionName',
    isSortable: true
  },
  {
    label: 'Field Name / Data Element Name',
    field: 'fieldName',
    isSortable: true
  },
  {
    label: 'Previous Value',
    field: 'previousValue'
  },
  {
    label: 'Latest Value',
    field: 'latestValue'
  }
]

const settings: any = {
  key: 'taskOrderEditLogId',
  rowsPerPageOptions: [20, 40, 60, 80, '_all'],
  rowsPerPageDefaultValue: 20
}

function onPageChange($event: any): void {
  pagination.value = {
    ...pagination.value,
    currentPage: $event
  }
}

function onPageSizeChange($event: any): void {
  pagination.value = {
    ...pagination.value,
    rowsPerPage: $event
  }
}

function onSortChange($event: any): void {
  sortParams.value = $event
}

async function downloadSelectedModFile($event: any): Promise<void> {
  const taskOrderId = selectedTO.value.taskOrderDto.taskOrderId
  const params = { taskOrderId, ...$event }
  try {
    await api.downloadActivityLog(params)
  } catch {
    addAlert(ActicityLogErrorCodes.DOWNLOAD_ERROR)
  }
  showSelectedModDownload.value = false
}

async function downloadAllModFile($event: any): Promise<void> {
  const taskOrderId = 'all'
  const params = { taskOrderId, ...$event }
  try {
    await api.downloadActivityLog(params)
  } catch {
    addAlert(ActicityLogErrorCodes.DOWNLOAD_ERROR)
  }
  showAllModDownload.value = false
}

async function downloadKPIModFile($event: any): Promise<void> {
  const taskOrderId = selectedTO.value.taskOrderDto.taskOrderId
  const from = $event.from
  const to = $event.to
  const fromDate = moment(from, 'YYYY/MM/DD').format('YYYY-MM-DD HH:mm:ss.SSS')
  const toDate = moment(to, 'YYYY/MM/DD').format('YYYY-MM-DD HH:mm:ss.SSS')
  const params = {
    fromDate: fromDate,
    toDate: toDate
  }
  try {
    await api.downloadKPIActivityLog(params)
  } catch {
    addAlert(KPILogErrorCodes.DOWNLOAD_ERROR)
  }
  showKPIModDownload.value = false
}

function removeAlert(code: string) {
  errors.value = errors.value.filter((e) => e !== code)
}

function addAlert(code: string) {
  errors.value.push(code)
  const t = setTimeout(() => {
    removeAlert(code)
  }, 5000)
}
</script>
<template>
  <Accordion title="Activity Log">
    <chi-alert
      v-for="error in errors"
      :key="error"
      color="danger"
      icon="circle-x"
      type="banner"
      class="-mb--2"
      closable
      @dismissAlert="() => removeAlert(error)"
    >
      {{ errorMessages[error] }}
    </chi-alert>
    <TOActivityLogDownloadModal
      title="Select Date Range"
      :key="'selected_mod'"
      v-if="showSelectedModDownload"
      @download="downloadSelectedModFile"
      @close="() => (showSelectedModDownload = false)"
    />
    <TOActivityLogDownloadModal
      title="Select Date Range"
      :key="'all_mods'"
      v-if="showAllModDownload"
      @download="downloadAllModFile"
      @close="() => (showAllModDownload = false)"
    />
    <div class="-d--flex -mb--2 -justify-content--end" style="gap: 16px">
      <button class="chi-button -outline -sm" @click="showSelectedModDownload = true">
        Download Activity Log for Selected MOD
      </button>
      <button class="chi-button -outline -sm" @click="showAllModDownload = true">
        Download Activity Log for all MODs
      </button>
    </div>
    <DataTable
      title="Task Order Activity Log"
      @onPageChange="onPageChange"
      @onPageSize="onPageSizeChange"
      @onSortedBy="onSortChange"
      :columns="columns"
      :rows="activityLogResponse.data"
      :settings="settings"
      :pagination="pagination"
    />
    <TOActivityLogDownloadModal
      title="Select Date Range"
      :key="'kpi_mods'"
      v-if="showKPIModDownload"
      @download="downloadKPIModFile"
      @close="() => (showKPIModDownload = false)"
    />
    <div class="-d--flex -mb--2 -justify-content--end" style="gap: 16px">
      <button class="chi-button -outline -sm" @click="showKPIModDownload = true">
        Download Activity Log
      </button>
    </div>
    <DataTable
      title="KPI Activity Log"
      @onPageChange="onPageChange"
      @onPageSize="onPageSizeChange"
      @onSortedBy="onSortChange"
      :columns="Kpicolumns"
      :rows="kpiActivityLogResponse.data"
      :settings="settings"
      :pagination="pagination"
    />
  </Accordion>
</template>
