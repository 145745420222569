<script lang="ts" setup>
import { ref, watch, toRaw, readonly } from 'vue'
import { storeToRefs } from 'pinia'
import { useStore } from './financialsStore'
import { useColumns } from './financialColumns'
import DataCard from '../common/DataCard.vue'
import ActionButton from '../common/ActionButton.vue'
import { Row, Input, randomId, slice, Signal } from '@centurylinkfederal/eis-vue'
import { doScrollToElement } from '../../utils/ui'
import { triggerForm } from '../../utils/form'
import { fromMMDDYYYY, toMMDDYYYY, padMMDDYYYY } from '../../utils/date'
import ImportModal from '../common/ImportModal.vue'
import { useToAdminAPI } from '../../composables/to-admin/api'
import { getStore } from './store'

const store = useStore()
const { resetSeq, form, formChanges, deleted } = storeToRefs(store)
export interface Props {
  pageMode: 'create' | 'edit'
}
const props = withDefaults(defineProps<Props>(), {
  pageMode: 'create'
})
const pageMode = ref(props.pageMode)
const pageStore = getStore(pageMode.value)()
const { selectedTaskOrderObj, resetSeq: pageResetSeq, signal } = storeToRefs(pageStore)
const columnsStore = useColumns()
const { columns } = storeToRefs(columnsStore)
const settings = {
  key: 'taskOrderFinancialId',
  footerLabelClass: '-d--none -d-md--inline'
}
const rows = ref<Row[]>([])
const emptyDataId = ref('emptyData-' + randomId())
const showImportModal = ref(false)
const scrollOpts = { wait: 100, left: 0, useOffsetHight: true }
const api = useToAdminAPI()

watch(() => signal.value, onSignal, { deep: true })

async function onSignal([signalKey, payload]: Signal): Promise<void> {
  switch (signalKey) {
    case 'previous':
    case 'next':
    case 'active': {
      if (payload?.panel === 'financials') {
        if (rows.value.length === 0) {
          onInsert()
        }
      }
      break
    }
  }
}
function resetForm(
  keyValue: string | number,
  item: undefined | Record<string, any> = undefined
): void {
  const inputs = form.value[keyValue] ?? {}
  for (const cell of columns.value) {
    const { label, field, input } = cell
    const value = item?.[field] ?? inputs?.[field]?.value ?? input?.value
    let disabled = input?.disabled
    if (field === 'prorationType') {
      if (!String(keyValue).includes('_new-')) {
        disabled = true
      }
    }
    inputs[field] = new Input({ ...input, label, field, value, disabled })
  }
  form.value = { ...form.value, [keyValue]: inputs }
}
async function onInput(newValue: any, cell: any): Promise<void> {
  if (!cell) {
    return
  }
  const { key, field } = cell
  const inputs = form.value[key]
  const input = inputs?.[field]
  if (!input) {
    return
  }
  input.set(newValue)
  input.validate()
  switch (field) {
    case 'taskOrderCeiling': {
      const taskOrderCeiling = parseInt(inputs.taskOrderCeiling.value)
      const performancePeriodFunding = parseInt(inputs.performancePeriodFunding.value)
      if (taskOrderCeiling < performancePeriodFunding) {
        inputs.taskOrderCeiling.state = 'danger'
        inputs.taskOrderCeiling.error = {
          message: `Must be equal or higher than Performance Period Funding.`
        }
      } else {
        if (inputs.performancePeriodFunding.value) {
          inputs.performancePeriodFunding.set(inputs.performancePeriodFunding.value)
          inputs.performancePeriodFunding.validate()
        }
      }
      break
    }
    case 'performancePeriodFunding': {
      const taskOrderCeiling = parseInt(inputs.taskOrderCeiling.value)
      const performancePeriodFunding = parseInt(inputs.performancePeriodFunding.value)
      if (performancePeriodFunding > taskOrderCeiling) {
        inputs.performancePeriodFunding.state = 'danger'
        inputs.performancePeriodFunding.error = {
          message: `Must not exceed Ceiling Amount.`
        }
      } else {
        if (inputs.performancePeriodFunding.value) {
          inputs.performancePeriodFunding.set(inputs.performancePeriodFunding.value)
          inputs.performancePeriodFunding.validate()
        }
      }
      break
    }
    case 'performanceBeginDate': {
      const startDate = fromMMDDYYYY(inputs.performanceBeginDate.value)
      const endDate = fromMMDDYYYY(inputs.performanceEndDate.value)
      if (startDate >= endDate) {
        inputs.performanceBeginDate.state = 'danger'
        inputs.performanceBeginDate.error = {
          message: `Begin Date should be before End Date.`
        }
      } else {
        inputs.performanceEndDate.set(inputs.performanceEndDate.value)
        inputs.performanceEndDate.validate()
      }
      break
    }
    case 'performanceEndDate': {
      const startDate = fromMMDDYYYY(inputs.performanceBeginDate.value)
      const endDate = fromMMDDYYYY(inputs.performanceEndDate.value)
      if (startDate >= endDate) {
        inputs.performanceEndDate.state = 'danger'
        inputs.performanceEndDate.error = {
          message: `End Date should be after Begin Date.`
        }
      } else {
        inputs.performanceBeginDate.set(inputs.performanceBeginDate.value)
        inputs.performanceBeginDate.validate()
      }
      break
    }
  }
}
function onInsert(_index = 0): void {
  const keyValue = '_new-' + randomId()
  const nonDeletedRows = rows.value.filter((f) => !f._deleted)
  const lastRow: any = {}
  if (nonDeletedRows.length > 0) {
    const { taskOrderFinancialId } = nonDeletedRows[nonDeletedRows.length - 1] ?? {}
    if (taskOrderFinancialId) {
      const lastInputs: any = form.value[taskOrderFinancialId] ?? {}
      for (const field in lastInputs) {
        if (
          ['taskOrderCeiling', 'taskOrderAgfRate', 'taskOrderCeiling', 'prorationType'].includes(
            field
          )
        ) {
          lastRow[field] = lastInputs[field]?.value
        }
      }
    }
  }
  const newRow = {
    ...lastRow,
    [settings.key]: keyValue
  }
  rows.value.push(newRow)
  resetForm(keyValue, newRow)
  doScrollToElement('#dataCard-' + keyValue, scrollOpts)
  setTimeout(() => {
    if (!rows.value.length) {
      return
    }
    const inputs = document.getElementById('dataCard-' + keyValue)?.getElementsByTagName('input')
    if (rows.value.length === 1) {
      inputs?.[0]?.focus()
    } else {
      inputs?.[2]?.focus()
    }
  }, 100)
}
function onDelete(index: number): void {
  const row = rows.value[index]
  if (!row) return
  const keyValue = row[settings.key]
  const dto: any = form.value[keyValue]
  if (!dto) return
  onInput(true, { field: '_deleted', key: keyValue })
  dto.deleted = true
  deleted.value.push({ ...dto })
  rows.value.splice(index, 1)
  const currentRow = rows.value[index - 1]
  const selector = currentRow ? `#dataCard-${currentRow[settings.key]}` : `#${emptyDataId.value}`
  doScrollToElement(selector, scrollOpts)
}
function onReset(index: number | undefined = undefined): void {
  if (typeof index === 'undefined') {
    return
  }
  const currentRow = rows.value[index]
  const keyValue = currentRow?.[settings.key]
  if (!keyValue) {
    return
  }
  resetForm(keyValue)
}
function handleFinancialImport({ contents }: { contents: any[] }) {
  contents = contents.map(parseFinancial)
  contents.forEach((item) => {
    const keyValue = item[settings.key] ?? `_new-${randomId()}`
    if (!form.value[keyValue]) {
      resetForm(keyValue, item)
      rows.value.push({ [settings.key]: keyValue })
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Duplicate key detected: ${keyValue}`)
    }
  })
  closeModal()
}

function validateFinancialImport(headers: string[], data: any) {
  const requiredHeaders = [
    'Ceiling Amount',
    'AGF Rate',
    'Performance Period Funding',
    'Proration Type',
    'Begin Date',
    'End Date'
  ]
  if (!requiredHeaders.every((h) => headers.includes(h))) {
    return { isValid: false, errorMsg: 'Invalid headers for Financial Import' }
  }
  return { isValid: true, errorMsg: '' }
}

function toggleImportModal(): void {
  showImportModal.value = !showImportModal.value
}

function closeModal() {
  showImportModal.value = false
}

function parseFinancial(financial: any): any {
  if (financial.performanceBeginDate) {
    financial.performanceBeginDate = toMMDDYYYY(financial.performanceBeginDate)
    financial.performanceBeginDate = padMMDDYYYY(financial.performanceBeginDate)
  }
  if (financial.performanceEndDate) {
    financial.performanceEndDate = toMMDDYYYY(financial.performanceEndDate)
    financial.performanceEndDate = padMMDDYYYY(financial.performanceEndDate)
  }
  return { ...financial, errors: {} }
}

async function loadData(): Promise<void> {
  if (pageMode.value === 'edit' && selectedTaskOrderObj.value?.taskOrderId) {
    try {
      const response: any = await api.fetchTaskOrderFinancial(
        selectedTaskOrderObj.value.taskOrderId
      )
      if (response?.results?.[0]?.length) {
        const parsedData = response.results[0]
        handleFinancialImport({ contents: parsedData })
      } else {
        // eslint-disable-next-line no-console
        console.warn('No financial data returned for the selected task order.')
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch financial data:', error)
    }
  }
}
watch(
  () => [selectedTaskOrderObj.value?.taskOrderId, pageResetSeq.value].join(':'),
  async () => {
    form.value = {}
    rows.value = []
    await loadData()
    resetSeq.value++
  }
)
</script>

<template>
  <div :key="resetSeq">
    <DataCard
      v-for="(row, index) in rows"
      :id="'dataCard-' + row?.[settings.key]"
      :key="row?.[settings.key]"
      :columns="columns"
      :settings="settings"
      :inputs="form?.[row?.[settings.key]]"
      :row="row"
      :count="{ current: index + 1, total: rows.length }"
      class="-mb--3"
      classColDefault="-w-xl--3 -w-lg--4 -w-md--6 -w--12"
      @onInput="onInput"
      @onInsert="onInsert(index)"
      @onDelete="onDelete(index)"
      @onReset="onReset(index)"
    />
    <div class="-d--flex -justify-content--center -m--1" :class="{ '-my--6': rows.length === 0 }">
      <div class="chi-divider -vertical">
        <ActionButton icon="circle-plus" text="Add new record" @onClick="onInsert" />
      </div>
      <div>
        <ActionButton icon="file-export" text="Import" @onClick="toggleImportModal" />
      </div>
    </div>

    <ImportModal
      v-if="showImportModal"
      title="Financial Import"
      :dataFields="columns.map((col) => col.field)"
      :headerDetails="columns.map((col) => col.label)"
      :customValidation="validateFinancialImport"
      @importContent="
        (e) => {
          handleFinancialImport(e)
          triggerForm(form, columns, undefined, true)
        }
      "
      @closeModal="closeModal"
    />
  </div>
</template>
