export function toMMDDYYYY(date: string): string {
  if (String(date).indexOf('-') === -1) {
    return date
  }
  const parts = date.split('-')
  return [parts[1], parts[2], parts[0]].join('/')
}
export function fromMMDDYYYY(date: string): string {
  if (String(date).indexOf('/') === -1) {
    return date
  }
  const parts = date.split('/')
  return [parts[2], parts[0], parts[1]].join('-')
}
export function padMMDDYYYY(date: string): string {
  if (String(date).indexOf('/') === -1) {
    return date
  }
  const parts = date.split('/')
  parts[0] = String(parts[0]).padStart(2, '0')
  parts[1] = String(parts[1]).padStart(2, '0')
  parts[2] = String(parts[2]).padStart(4, '0')
  return parts.join('/')
}
