export function doScrollTo(top = 0, left = 0, msWait: number = 0): void {
  setTimeout(
    () =>
      window.scrollTo({
        top,
        left,
        behavior: 'smooth'
      }),
    msWait
  )
}
export function doScrollToElement(selector: string, opts: any = {}): void {
  const defaultOpts = {
    wait: 0,
    offsetTop: 0,
    offsetLeft: 0
  }
  opts = { ...defaultOpts, ...opts }
  setTimeout(() => {
    const el = document.body.querySelector(selector)
    if (!el) {
      return
    }
    const { offsetHeight, offsetTop, offsetLeft } = el as any
    const top = offsetTop + opts.offsetTop + (opts.useOffsetHight ? offsetHeight : 0)
    const left = opts.left ?? offsetLeft + opts.offsetLeft
    window.scrollTo({
      top,
      left,
      behavior: 'smooth'
    })
  }, opts.wait)
}
