import { Entries } from '@centurylinkfederal/eis-vue'

export function triggerForm(
  form: any,
  cells: any,
  rowKey: string | undefined = undefined,
  anyState: boolean = false
): any {
  let state, error, errorKey
  if (!form || !cells) {
    return {}
  }
  let formKeys = Object.keys(form)
  if (rowKey) {
    formKeys = formKeys.filter((f) => f === rowKey)
  }
  for (const formKey of formKeys) {
    const inputs = form[formKey] ?? {}
    const deletedInput = inputs._deleted
    if (deletedInput?.value === true) {
      continue
    }
    for (const cell of cells) {
      const { field, input: _input } = cell
      const input = inputs[field]
      if (!input) {
        continue
      }
      if (_input?.required) {
        if (!anyState) {
          input.copy = input.copy ?? input.value
        }
        const [_state, _error] = input.validate()
        if (_state === 'danger' || anyState) {
          state = state ?? _state
          error = error ?? _error
          errorKey = errorKey ?? formKey
        }
      }
    }
  }
  return { state, error, errorKey }
}
export function getFormChanges(form: any, cells: any, rowKey: string | undefined = undefined): any {
  const changes: any = {}
  if (!form || !cells) {
    return changes
  }
  let formKeys = Object.keys(form)
  if (rowKey) {
    formKeys = formKeys.filter((f) => f === rowKey)
  }
  for (const formKey of formKeys) {
    const inputs = form[formKey] ?? {}
    const deletedInput = inputs._deleted
    if (deletedInput?.value === true) {
      const { value, copy, state } = deletedInput
      if (!changes[formKey]) {
        changes[formKey] = []
      }
      changes[formKey].push(['_deleted', { value, copy, state }])
      continue
    }
    for (const cell of cells) {
      const { field, input: _input } = cell
      const input = inputs[field]
      if (!input) {
        continue
      }
      const { value, copy, state } = input
      if (!state) {
        continue
      }
      if (!changes[formKey]) {
        changes[formKey] = []
      }
      changes[formKey].push([field, { value, copy, state }])
    }
  }
  return changes
}
export function makeFormQuirksMap(changes: any): Map<string, any> {
  const quirksMap = new Map()
  if (typeof changes !== 'object') {
    return quirksMap
  }
  let inDanger = false
  let inSuccess = false
  let inNoChange = false
  for (const key in changes) {
    const rowEntries = changes[key]
    const hasNoChange = rowEntries.some((entry: any) => entry?.[1]?.state === 'unchanged')
    const hasSuccess = rowEntries.some((entry: any) => entry?.[1]?.state === 'success')
    const hasDanger = rowEntries.some((entry: any) => entry?.[1]?.state === 'danger')
    let state = undefined
    state = hasNoChange ? 'unchanged' : state
    state = hasSuccess ? 'success' : state
    state = hasDanger ? 'danger' : state
    const patch = {
      state
    }
    const quirk = quirksMap.get(key)
    quirksMap.set(key, { ...quirk, ...patch })
    inNoChange = inNoChange || hasNoChange
    inSuccess = inSuccess || hasSuccess
    inDanger = inDanger || hasDanger
  }
  let state = undefined
  state = inNoChange ? 'unchanged' : state
  state = inSuccess ? 'success' : state
  state = inDanger ? 'danger' : state
  const patch = {
    state
  }
  const quirk = quirksMap.get('_all')
  quirksMap.set('_all', { ...quirk, ...patch })
  return quirksMap
}
export function getRowChanges(rowEntries: Entries): Entries {
  if (!rowEntries) {
    return []
  }
  return rowEntries.filter(([_i, e]) => e?.state !== 'unchanged')
}
