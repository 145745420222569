import { defineStore } from 'pinia'
import { ref } from 'vue'

interface Alert {
  type: 'success' | 'danger' | 'error'
  message: string
  id: string
}

export const useAlertsStore = defineStore('alerts', () => {
  const alerts = ref<Alert[]>([])

  function addAlert(type: 'success' | 'danger' | 'error', message: string) {
    const alert = { id: crypto.randomUUID(), type, message }
    alerts.value = [...alerts.value, alert]
    const removeAlertTimer = setTimeout(() => {
      removeAlert(alert.id)
      clearTimeout(removeAlertTimer)
    }, 10000)
  }

  function removeAlert(id: String) {
    alerts.value = alerts.value.filter((a) => a.id !== id)
  }

  return { alerts, addAlert, removeAlert }
})
