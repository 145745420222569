import {
  getUserProfileDetails,
  getUserTOPermissions,
  updateProfile,
  updateTOPermission,
  updateUserPassword,
  validateForExistingPassword
} from './Actions'

export enum Action {
  'PREVIOUS_PASSWORD' = 'PREVIOUS_PASSWORD',
  'UPDATE_TO_PREMISSION' = 'UPDATE_TO_PREMISSION',
  'GET_USER_DETAILS' = 'GET_USER_DETAILS',
  'GET_USER_TOS' = 'GET_USER_TOS',
  'UPDATE_USER_PROFILE' = 'UPDATE_USER_PROFILE',
  'UPDATE_USER_PASSWORD' = 'UPDATE_USER_PASSWORD'
}

export function dispatch(type: keyof typeof Action, payload?: any) {
  const actions = aggregateActions()
  const performAction = actions.get(type)
  if (performAction) {
    return performAction(payload)
  }
}

function aggregateActions(): Map<keyof typeof Action, (...args: any) => any> {
  const actionList: Map<keyof typeof Action, (...args: any) => any> = new Map()
  actionList.set(Action.PREVIOUS_PASSWORD, validateForExistingPassword)
  actionList.set(Action.UPDATE_TO_PREMISSION, updateTOPermission)
  actionList.set(Action.GET_USER_DETAILS, getUserProfileDetails)
  actionList.set(Action.GET_USER_TOS, getUserTOPermissions)
  actionList.set(Action.UPDATE_USER_PROFILE, updateProfile)
  actionList.set(Action.UPDATE_USER_PASSWORD, updateUserPassword)

  return actionList
}
