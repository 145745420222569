<script setup lang="ts">
import { useStore } from './store'
import { storeToRefs } from 'pinia'
import { ref, computed, watch } from 'vue'
import { Action, dispatch } from '../CreateUser/actions'
import { Column, DataTable } from '@centurylinkfederal/eis-vue'

const userProfileStore = useStore()
const { currentPage, rowsPerPage, userTOPermissionDetails, userProfileDetails } =
  storeToRefs(userProfileStore)

const columns: Column[] = [
  {
    field: 'agencyLongName',
    label: 'Agency Name',
    class: '-text--wrap -size--lg-2'
  },
  {
    field: 'taskOrderNumber',
    label: 'Task Order',
    class: '-text--wrap -size--lg-2'
  }
]
const settings = {
  striped: true,
  key: 'accountTaskOrderId',
  rowsPerPageOptions: [10, 20, 40, 60, 80, 100],
  rowsPerPageDefaultValue: 10
}
const quirks = ref<any>([])
const pagination = computed(() => {
  const result = {
    totalResults: userTOPermissionDetails.value?.totalRecords || 0,
    currentPage: currentPage.value,
    rowsPerPage: rowsPerPage.value
  }

  return result
})

watch(
  () => [userProfileDetails.value, currentPage.value, rowsPerPage.value],
  async () => updateAgencyToList()
)

async function updateAgencyToList() {
  userTOPermissionDetails.value = await dispatch(Action.GET_USER_TOS, {
    pageNumber: currentPage.value,
    pageSize: rowsPerPage.value
  })
}

function onPageChange(newValue: number) {
  currentPage.value = newValue
}
function onPageSize(newValue: number) {
  currentPage.value = 1
  rowsPerPage.value = newValue
}
</script>

<template>
  <DataTable
    :columns="columns"
    :settings="settings"
    :quirks="quirks"
    :rows="userTOPermissionDetails?.userProfileAgencies || []"
    :pagination="pagination"
    @onPageChange="onPageChange"
    @onPageSize="onPageSize"
  >
    <template #empty>
      <div class="chi-data-table__row-empty" style="min-height: 300px">
        <div>
          <i
            class="chi-icon icon-search -sm--2 -vertical-align--bottom -icon--grey"
            aria-hidden="true"
          ></i>
          <span class="-ml--1">No Task Order Assigned</span>
        </div>
      </div>
    </template>
  </DataTable>
</template>

<style scoped></style>
