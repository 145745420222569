<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useStore } from './store'
import { Alert } from '@centurylinkfederal/eis-vue'

const store = useStore()
const { alertMap, alertEntries } = storeToRefs(store)
</script>
<template>
  <div class="alerts -mb--2 -mt--1">
    <Alert
      v-for="[alertKey, alert] in alertEntries"
      :key="alertKey"
      v-bind="alert"
      @onDismissAlert="alertMap.delete(alertKey)"
    />
  </div>
</template>
