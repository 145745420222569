import { parseErr, useApi } from '@centurylinkfederal/eis-vue'

export interface PasswordExpirationDetails {
  passwordExpired: boolean
  passwordExpiringWithin15days: boolean
  numberOfDaysUntilExpiration: number
}

export function usePasswordExpirationInfoApis() {
  const api = useApi(import.meta.env.VITE_EIS_API_BASE)

  async function getPasswordExpirationDetails(): Promise<PasswordExpirationDetails> {
    let passwordExpirationData: PasswordExpirationDetails
    try {
      passwordExpirationData = await api.get<PasswordExpirationDetails>(
        '/user-password-expiration-info'
      )
    } catch (err: any) {
      throw new Error(parseErr(err))
    }
    return passwordExpirationData
  }

  return {
    getPasswordExpirationDetails
  }
}
