<script lang="ts" setup>
import { ref, computed, onMounted, watch, toRaw } from 'vue'
import { storeToRefs } from 'pinia'
import { debounce } from 'perfect-debounce'
import { getStore } from './store'
import { useStore as useServicesStore } from './servicesStore'
import { useViewStore } from '../../store/taskOrderAdminViewStore'
import { useColumns } from './servicesColumns'
import { useColumns as useNscsColumns } from './nscsColumns'
import { useFormsStore } from '../../composables/forms'
import { useToAdminAPI } from '../../composables/to-admin/api'
import { affixFormat } from '../../utils/helpers'
import {
  Input,
  randomId,
  Tooltip,
  ButtonType,
  StateType,
  Signal,
  parseErr,
  Row,
  InputCell,
  toBeOptional,
  toBeANumber,
  Pair
} from '@centurylinkfederal/eis-vue'
import { toMap } from '../../utils/helpers'

import PickerBox from '../common/PickerBox.vue'
import EmptyData from '../common/EmptyData.vue'
import ActionButton from '../common/ActionButton.vue'

import ImportModal from '../common/ImportModal.vue'

export interface Props {
  pageMode: 'create' | 'edit'
}
const props = withDefaults(defineProps<Props>(), {
  pageMode: 'create'
})
const pageMode = ref(props.pageMode)
const pageStore = getStore(pageMode.value)()
const store = useServicesStore()
const { form, picks, quirks, formState, resetSeq } = storeToRefs(store)
const {
  modalMap,
  signal,
  summary,
  selectedTaskOrderObj,
  resetSeq: pageResetSeq
} = storeToRefs(pageStore)
const { loading } = storeToRefs(useViewStore())
const columnsStore = useColumns()
const { columnMap, serviceMap, serviceOptions, jurisdictionMap, jurisdictionOptions } =
  storeToRefs(columnsStore)
const nscsColumnsStore = useNscsColumns()
const nscsColumnsStoreRefs = storeToRefs(nscsColumnsStore)
const { useForms, clearForms } = useFormsStore()
const adminApi = useToAdminAPI()
const section = 'services'
const showImportModal = ref(false)
const selectedServiceDesc = ref('')
const importedData = ref([])
const dataFields = ref(['Service ID'])
const headerDetails = ref(['Service ID'])
const currentImportType = ref<string | null>(null)
const currentImportKey = ref<undefined | string>()
const nscMap = ref(new Map())
const vendorPoCell = ref({
  label: 'Vendor PO',
  field: 'vendorPo',
  classCell: '-row',
  input: {
    maxLength: 8
  }
})
const vendorPoInput = ref(
  new Input({
    ...vendorPoCell.value,
    tests: [toBeOptional, toBeANumber],
    dangerMessage: 'Vendor PO must be a number.'
  })
)
const nscOptions = computed(() =>
  Array.from(nscMap.value.entries()).map(([key, obj]) => [key, key + ' - ' + obj?.location])
)
const nscsColumnsMap = computed(() => toMap(nscsColumnsStoreRefs.columns.value ?? [], 'field'))
const api = useToAdminAPI()
watch(() => signal.value, onSignal)
watch(
  () => picks.value,
  (newSet) => {
    const servicesCount = newSet.size
    summary.value = { ...summary.value, servicesCount }
    const inputs = form.value.summary ?? {}
    const { field, input } = columnMap.value.get('picks') ?? {}
    inputs.picks = inputs.picks ?? new Input({ field, ...input })
    inputs.picks.set(Array.from(newSet))
    inputs.picks.validate()
    form.value = { ...form.value, summary: inputs }
  },
  { deep: true }
)
const resetDebounced = debounce(async () => {
  clearForms()
  vendorPoInput.value = new Input({
    ...vendorPoCell.value,
    tests: [toBeOptional, toBeANumber],
    dangerMessage: 'Vendor PO must be a number.'
  })
  picks.value.clear()
  quirks.value.clear()
  await resetServices()
  resetSeq.value++
}, 25)

watch(
  () => pageResetSeq.value,
  async () => await resetDebounced()
)
onMounted(async () => {
  await fetchJurisdictions()
})
function nscsSize(nscMap: Map<string | number, any> | undefined) {
  const nscs = Array.from((nscMap ?? new Map()).values()).filter((e: any) => !e._deleted)
  return nscs.length
}
async function fetchJurisdictions() {
  loading.value = true
  const res: any = await adminApi.getJurisdictions({ useCache: true })
  loading.value = false
  const entries = (res?.results ?? []).map((row: any) => [row.jurisdictionCode, row])
  jurisdictionMap.value = new Map(entries)
}
function onRemove(value: number | string) {
  picks.value.delete(value)
  onPick({ newSet: picks.value, value, action: 'delete' })
}
function onPick({ newSet, value, action }: any): void {
  const quirk = quirks.value.get(value) ?? {}
  quirk.action = action
  quirks.value.set(value, quirk)
  picks.value = new Set([...picks.value, ...newSet])
  formState.value = 'success' // @todo get all Sets differencess, using copy variable, to determine unchanged or success state.
  if (action === 'add' && value) {
    // scrollTo row
  }
  if (value === 'MWS') {
    const serviceObj = serviceMap.value.get('MWS')
    if (action === 'delete') {
      const vendorPoValue = vendorPoInput.value.value
      onVendorPo('')
      vendorPoInput.value.copy = pageMode.value === 'edit' ? serviceObj.vendorPo : vendorPoValue
    }
    if (action === 'add') {
      const vendorPoState = pageMode.value === 'edit' ? 'unchanged' : undefined
      const vendorPoValue =
        pageMode.value === 'edit' ? serviceObj.vendorPo : vendorPoInput.value.copy
      onVendorPo(vendorPoValue, vendorPoState)
    }
  }
}
async function onChangeClick(key: number | string, what: string) {
  let title, pickerBox, dataCards, dataTable, modalStyle, backdropStyle, actionMap
  const buttonMap: Map<string | number, ButtonType> = new Map([
    ['Cancel', { text: 'Cancel', uppercase: true, dismissModal: true }],
    ['Undo', { text: 'Undo', uppercase: true, dismissModal: false, class: '-d--none' }],
    ['Import', { text: 'Import', uppercase: true, dismissModal: true }],
    [
      'Apply',
      {
        text: 'Apply',
        uppercase: true,
        dismissModal: true,
        color: 'primary'
      }
    ]
  ])

  const quirk = quirks.value.get(key) ?? {}
  switch (what) {
    case 'clins': {
      title = 'CLINs'
      currentImportType.value = 'clins'
      const options = (serviceMap.value.get(key)?.clins ?? []).map((clinStr: string) => {
        const clinPair = clinStr.split(' - ')
        const clinKey = clinPair.shift()
        const clinValue = clinPair.join(' - ')
        return [clinKey, clinKey + ' - ' + clinValue]
      })
      quirk.clinsCopy = new Set(quirk.clins)
      const valueSet = new Set(quirk.clins)
      pickerBox = {
        options,
        valueSet,
        settings: { style: { height: '360px' }, actions: ['selectAll'] },
        label: '',
        chiSearch: { helperMessage: '', placeHolder: 'Filter' },
        focusSeq: randomId()
      }
      break
    }
    case 'jurisdictions': {
      currentImportType.value = 'jurisdictions'
      if (jurisdictionMap.value.size === 0) {
        await fetchJurisdictions()
      }
      title = 'Countries / Jurisdictions'
      const options = jurisdictionOptions.value
      quirk.jurisdictionsCopy = quirk.jurisdictions ?? new Set()
      const valueSet = quirk.jurisdictions ?? new Set()
      pickerBox = {
        options,
        valueSet,
        settings: { style: { height: '360px' }, actions: ['selectAll'] },
        label: '',
        chiSearch: { helperMessage: '', placeHolder: 'Filter' },
        focusSeq: randomId()
      }
      break
    }
    case 'nscs': {
      title = 'NSCs'
      currentImportType.value = 'nscs'
      backdropStyle = { maxWidth: '740px' }
      modalStyle = { ...backdropStyle }

      actionMap = new Map([
        ['New', { text: 'Add new record', icon: 'circle-plus', dismissModal: false }]
      ])
      quirk.nscsCopy = new Map(quirk.nscs)
      const valueMap = new Map(quirk.nscs)

      const nscsForm = useForms([what, key].join(':'))
      dataTable = {
        id: [what, key].join('-'),
        columns: nscsColumnsStoreRefs.columns,
        rows: nscsForm.rows.value,
        form: nscsForm.form,
        valueMap,
        settings: {
          key: 'nscId',
          tableBodyStyle: {
            height: '320px',
            'overflow-y': 'auto'
          },
          hideFooter: true
        }
      }
      break
    }
  }
  quirks.value.set(key, quirk)
  const buttons: ButtonType[] = Array.from(buttonMap.values())
  const actions: ButtonType[] = Array.from((actionMap ?? new Map()).values())
  modalMap.value.set('modal-' + key, {
    key,
    what,
    section,
    title,
    closed: false,
    center: true,
    pickerBox,
    dataCards,
    dataTable,
    actions,
    buttons,
    modalStyle,
    backdropStyle
  })
}
async function onSignal([signalKey, payload]: Signal): Promise<void> {
  if (!payload) {
    return
  }
  const { index, value, opts, modal } = toRaw(payload)
  if (!modal) {
    return
  }
  const { key, what, section } = modal
  if (section !== 'services') {
    return
  }
  const modalObj = modalMap.value.get('modal-' + key) || {}
  if (modalObj.pickerBox) {
    switch (signalKey) {
      case 'onModalDismiss': {
        const modalObj = modalMap.value.get('modal-' + key) || {}
        modalObj.closed = true
        modalMap.value.set('modal-' + key, modalObj)
        break
      }
      case 'onModalPick': {
        const newSet = toRaw(value?.newSet)
        const quirk = quirks.value.get(key) ?? {}
        quirk[what + 'Draft'] = newSet
        quirks.value.set(key, quirk)
        break
      }
      case 'onModalButtonClick': {
        const quirk = quirks.value.get(key) ?? {}
        const [cancelIndex, undoIndex, importIndex, applyIndex] = [0, 1, 2, 3]
        switch (index) {
          case cancelIndex: {
            delete quirk[what + 'Draft']
            break
          }
          case undoIndex: {
            quirk[what] = new Set(quirk[what + 'Copy'])
            const modalObj = modalMap.value.get('modal-' + key) || {}
            modalObj.pickerBox.valueSet = quirk[what]
            modalMap.value.set('modal-' + key, modalObj)
            break
          }
          case importIndex: {
            openImportModal({ what, key })
            break
          }
          case applyIndex: {
            quirk[what] = quirk[what + 'Draft']
            formState.value = 'changed'
            break
          }
        }
        quirks.value.set(key, quirk)
        break
      }
    }
  }
  if (modalObj.dataTable) {
    switch (signalKey) {
      case 'onModalDismiss': {
        const modalObj = modalMap.value.get('modal-' + key) || {}
        modalObj.closed = true
        modalMap.value.set('modal-' + key, modalObj)
        break
      }
      case 'onModalPick': {
        break
      }
      case 'onModalButtonClick': {
        const quirk = quirks.value.get(key) ?? {}
        const [cancelIndex, undoIndex, importIndex, applyIndex] = [0, 1, 2, 3]
        switch (index) {
          case cancelIndex:
          case undoIndex: {
            const modalForm = useForms([what, key].join(':'))
            const formKey = modalObj.dataTable.settings.key
            const rowMap = toMap(modalForm.rows.value, formKey)
            for (const rowKey in modalForm.form.value) {
              const inputs = modalForm.form.value[rowKey] ?? {}
              for (const field in inputs) {
                const input = inputs[field]
                if (!input?.state) {
                  continue
                }
                input.value = input.copy
                input.state = 'unchanged'
              }
              modalForm.form.value[rowKey] = inputs
              if (index === cancelIndex) {
                if (
                  inputs._deleted?.value === true ||
                  (!inputs.networkSiteCode.value && inputs.networkSiteCode.state === 'unchanged')
                ) {
                  delete modalForm.form.value[rowKey]
                  rowMap.delete(rowKey)
                  quirk[what + 'Draft']?.delete(rowKey)
                }
              }
            }
            modalForm.rows.value = Array.from(rowMap.values())
            break
          }
          case importIndex: {
            openImportModal({ what, key })
            break
          }
          case applyIndex: {
            const modalForm = useForms([what, key].join(':'))
            const formKey = modalObj.dataTable.settings.key
            const rowMap = toMap(modalForm.rows.value, formKey)
            for (const rowKey in modalForm.form.value) {
              const inputs = modalForm.form.value[rowKey] ?? {}
              for (const field in inputs) {
                const input = inputs[field]
                if (!input) {
                  continue
                }
                input.copy = input.value
                input.state = 'unchanged'
              }
              modalForm.form.value[rowKey] = inputs
              if (
                inputs._deleted?.value === true ||
                (!inputs.networkSiteCode.value && !inputs.location.value)
              ) {
                delete modalForm.form.value[rowKey]
                rowMap.delete(rowKey)
                quirk[what]?.delete(rowKey)
                quirk[what + 'Draft']?.delete(rowKey)
              }
            }
            modalForm.rows.value = Array.from(rowMap.values())
            if (quirk[what + 'Draft']) {
              const draftEntries: Pair[] = Array.from(quirk[what + 'Draft'].entries()) as Pair[]
              if (!quirk[what]) {
                quirk[what] = new Map()
              }
              draftEntries.forEach(([key, value]) => {
                quirk[what].set(key, value)
              })
            }
            quirk[what + 'Copy'] = new Map(quirk[what])
            formState.value = 'changed'
            break
          }
        }
        quirks.value.set(key, quirk)
        break
      }
      case 'onModalActionClick': {
        const [newRecordIndex] = [0]
        switch (index) {
          case newRecordIndex: {
            const modalForm = useForms([what, key].join(':'))
            const formKey = modalObj.dataTable.settings.key
            const formKeyValue = '_new-' + randomId()
            const newRecord = {
              [formKey]: formKeyValue
            }
            let inputs: any = {}
            for (const cell of modalObj.dataTable.columns) {
              const { label, field, input } = cell
              inputs[field] = new Input({ label, field, ...input })
            }
            modalForm.rows.value.push(newRecord)
            modalForm.form.value = { ...modalForm.form.value, [formKeyValue]: inputs }

            setTimeout(() => {
              const dataRows =
                document
                  .getElementById('data-table-' + [what, key].join('-'))
                  ?.querySelector('.data-body')
                  ?.querySelectorAll('.chi-data-table__row') ?? []
              if (dataRows.length > 0) {
                const lastRow = dataRows[dataRows.length - 1]
                lastRow.querySelector('input')?.focus()
              }
            }, 100)
            break
          }
        }
        break
      }
      case 'onModalInput': {
        const modalForm = useForms([what, key].join(':'))
        if (!opts) {
          return
        }
        const inputs = modalForm.form.value[opts.key]
        const input = inputs?.[opts.field]
        if (!input) {
          return
        }
        input.set(value)
        input.validate()
        const row = inputsToRow(inputs)
        let quirk = quirks.value.get(key) ?? {}
        if (!quirk[what + 'Draft']) {
          quirk[what + 'Draft'] = new Map()
        }
        quirk[what + 'Draft'].set(opts.key, row)
        quirks.value.set(key, quirk)
        break
      }
    }
  }
}
function inputsToRow(inputs: Row[]): Row {
  const entries = Array.from(Object.entries(inputs)).map(([field, e]): [string, Input] => [
    field,
    e.value
  ])
  return Object.fromEntries(entries)
}
function openImportModal({ what, key }: any): void {
  const whatToImport: any = {
    services: {
      title: 'Services',
      headers: ['Service ID', 'Service Description']
    },
    clins: {
      title: 'CLINs',
      headers: ['Service ID', 'CLIN']
    },
    jurisdictions: {
      title: 'Countries / Jurisdiction IDs',
      headers: ['Service ID', 'Jurisdiction']
    },
    nscs: {
      title: 'NSCs',
      headers: ['Service ID', 'NSC', 'Location']
    }
  }
  currentImportType.value = what
  currentImportKey.value = key
  selectedServiceDesc.value = `Import ${whatToImport[what]?.title}`
  headerDetails.value = [...(whatToImport[what]?.headers || [])]
  dataFields.value = [...(whatToImport[what]?.headers || [])]
  showImportModal.value = true
}

function closeImportModal(): void {
  showImportModal.value = false
}

function handleImportContent({ isValid, contents }: any): void {
  if (isValid) {
    importedData.value = contents
    if (currentImportType.value) {
      mapImportedData(contents, currentImportType.value, currentImportKey.value)
      closeImportModal()
      currentImportType.value = null
    }
  }
}
function importServices(data: any, _key: undefined | string = undefined): void {
  for (const row of data) {
    const serviceID = String(row['Service ID']).trim()
    if (!serviceMap.value.get(serviceID)) {
      continue
    }
    picks.value.add(serviceID)
    let quirk = quirks.value.get(serviceID) ?? {}

    quirk.services = quirk.services || new Set()
    quirk.services.add(serviceID)
    quirk.servicesDraft = new Set(quirk.services)
    quirk.servicesCopy = new Set(quirk.services)
    quirks.value.set(serviceID, { ...quirk })

    if (form.value.summary?.services) {
      const servicesInput = form.value.summary.services
      servicesInput.set(Array.from(quirk.services))
      servicesInput.validate()
    }
  }
}
function importClins(data: any, _key: undefined | string = undefined): void {
  const importedCLINsSet = new Set()

  for (const row of data) {
    const serviceID = String(row['Service ID']).trim()
    if (_key && serviceID && _key !== serviceID) {
      continue
    }
    if (!serviceMap.value.get(serviceID)) {
      continue
    }
    picks.value.add(serviceID)
    let quirk = quirks.value.get(serviceID) ?? {}

    const options = (serviceMap.value.get(serviceID)?.clins ?? []).map((clinStr: any) => {
      const clinPair = clinStr.split(' - ')
      const clinKey = clinPair.shift()
      const clinValue = clinPair.join(' - ')
      return [clinKey, clinValue]
    })

    const clin = row.CLIN
    const matchedClin = options.find((option: any) => option[0] === clin)
    if (!matchedClin) {
      continue
    }

    quirk.clins = quirk.clins ? new Set([...quirk.clins, clin]) : importedCLINsSet
  }
}
function importJurisdictions(data: any, _key: undefined | string = undefined): void {
  for (const row of data) {
    const serviceID = String(row['Service ID']).trim()
    if (!serviceID) {
      continue
    }
    if (_key && serviceID && _key !== serviceID) {
      continue
    }
    if (!serviceMap.value.get(serviceID)) {
      continue
    }
    if (serviceMap.value.get(serviceID)?.cbsaBased === 'No') {
      continue
    }
    const jurisdiction = parseInt(String(row.Jurisdiction))
    if (!jurisdiction || isNaN(jurisdiction)) {
      continue
    }
    let quirk = quirks.value.get(serviceID) ?? {}
    if (!quirk.jurisdictions) {
      quirk.jurisdictions = new Set()
    }
    quirk.jurisdictions.add(jurisdiction)
    quirk['jurisdictionsDraft'] = quirk.jurisdictions
    quirks.value.set(serviceID, quirk)
  }
}
function importNscs(data: any, _key: undefined | string = undefined): void {
  const fieldTable: any = {
    networkSiteCode: 'NSC',
    location: 'Location'
  }
  for (const row of data) {
    const serviceID = String(row['Service ID']).trim()
    if (_key && serviceID && _key !== serviceID) {
      continue
    }
    if (!serviceMap.value.get(serviceID)) {
      continue
    }
    if (serviceMap.value.get(serviceID)?.cbsaBased === 'No') {
      continue
    }
    const nscsForm = useForms(['nscs', serviceID].join(':'))
    const formKey = 'nscId'
    const formKeyValue = '_new-' + randomId()
    const newRecord: any = {
      [formKey]: formKeyValue
    }
    let inputs: any = {}
    let quirk = quirks.value.get(serviceID) ?? {}
    if (!quirk.nscs) {
      quirk.nscs = new Map()
    }
    for (const cell of nscsColumnsStoreRefs.columns.value) {
      const { label, field, input } = cell
      const externalField = fieldTable[field]
      const value = row[externalField]
      const copy = value
      const state = 'unchanged'
      inputs[field] = new Input({ label, field, ...input, value, copy, state })
      inputs[field].validate()
      newRecord[field] = value
    }
    quirk.nscs.set(formKeyValue, newRecord)
    quirks.value.set(serviceID, quirk)
    nscsForm.rows.value.push(newRecord)
    nscsForm.form.value = { ...nscsForm.form.value, [formKeyValue]: inputs }
  }
}
function mapImportedData(data: any, what: any, key: undefined | string = undefined): void {
  const whatToImport: any = {
    services: importServices,
    clins: importClins,
    jurisdictions: importJurisdictions,
    nscs: importNscs
  }
  const importFn = whatToImport[what]
  if (typeof importFn !== 'function') {
    return
  }
  try {
    importFn(data, key)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Error while importing ${what} data.`, parseErr(err))
  }
}

function handleImportError(errorMsg: string): void {
  // eslint-disable-next-line no-console
  console.error('Import Error:', errorMsg)
}
function onImportServicesClick(): void {
  openImportModal({ what: 'services' })
  return
}
function onVendorPo(newValue: any, _state: StateType = undefined): void {
  const quirk = quirks.value.get('MWS') ?? {}
  quirk.vendorPo = newValue
  quirks.value.set('MWS', quirk)
  vendorPoInput.value.set(newValue)
  if (_state) {
    vendorPoInput.value.state = _state
  } else {
    vendorPoInput.value.validate()
  }
  form.value = { ...form.value, MWS: { vendorPo: vendorPoInput.value } }
}
function processEditTaskOrderData(taskOrder: any): void {
  const serviceCode = String(taskOrder.serviceCode).trim()
  if (!serviceMap.value.get(serviceCode)) {
    return
  }
  let quirk = quirks.value.get(serviceCode) ?? {}
  quirk.serviceDto = { ...taskOrder }
  quirks.value.set(serviceCode, quirk)
  loadEditServiceDetails(serviceMap.value.get(serviceCode))
  loadEditClinsDetails(taskOrder, serviceCode)
  loadEditVendorPo(taskOrder, serviceCode)
  if (taskOrder.jurisdictions.length > 0) {
    loadEditJurisdictionsDetails(taskOrder, serviceCode)
  }
  if (taskOrder.taskOrderNSCList.length > 0) {
    loadEditNscDetails(taskOrder, serviceCode)
  }
}

function loadEditServiceDetails(service: any): void {
  const serviceID = service.serviceID
  picks.value.add(serviceID)
  let quirk = quirks.value.get(serviceID) ?? {}

  quirk.services = quirk.services || new Set()
  quirk.services.add(serviceID)
  quirk.servicesDraft = new Set(quirk.services)
  quirk.servicesCopy = new Set(quirk.services)
  quirks.value.set(serviceID, { ...quirk })
  formState.value = 'unchanged'
  if (form.value.summary?.services) {
    const servicesInput = form.value.summary.services
    servicesInput.set(Array.from(quirk.services))
    servicesInput.validate()
  }
}
function loadEditVendorPo(taskOrder: any, serviceCode: any): void {
  if (serviceCode !== 'MWS') {
    return
  }
  onVendorPo(taskOrder.vendorPo, 'unchanged')
}
function loadEditClinsDetails(taskOrder: any, serviceCode: any): void {
  let quirk = quirks.value.get(serviceMap.value.get(serviceCode).serviceID) ?? {}

  if (taskOrder.clins) {
    const clins = taskOrder.clins.map((clin: any) => clin.trim())
    quirk.clins = quirk.clins ? new Set([...quirk.clins, ...clins]) : new Set(clins)
  }
  quirks.value.set(serviceMap.value.get(serviceCode).serviceID, quirk)
}

function loadEditJurisdictionsDetails(taskOrder: any, serviceCode: any): void {
  let quirk = quirks.value.get(serviceMap.value.get(serviceCode).serviceID) ?? {}

  if (!quirk.jurisdictions) {
    quirk.jurisdictions = new Set()
  }

  taskOrder.jurisdictions.forEach((jurisdiction: any) => {
    quirk.jurisdictions.add(jurisdiction.jurisdId)
  })

  quirks.value.set(serviceMap.value.get(serviceCode).serviceID, quirk)
}
function loadEditNscDetails(taskOrder: any, serviceCode: any): void {
  let quirk = quirks.value.get(serviceMap.value.get(serviceCode).serviceID) ?? {}

  if (!quirk.nscs) {
    quirk.nscs = new Map()
  }

  taskOrder.taskOrderNSCList.forEach((nsc: any) => {
    const formKeyValue = nsc.taskOrderNscId
    const nscRecord = {
      nscId: formKeyValue,
      networkSiteCode: nsc.networkSiteCode,
      location: nsc.location,
      _deleted: false
    }
    quirk.nscs.set(formKeyValue, nscRecord)
  })

  quirks.value.set(serviceMap.value.get(serviceCode).serviceID, quirk)

  const nscForm = useForms(['nscs', serviceCode].join(':'))

  if (nscForm) {
    taskOrder.taskOrderNSCList.forEach((nsc: any) => {
      const formKeyValue = nsc.taskOrderNscId
      const inputs: any = {
        networkSiteCode: new Input({
          label: 'NSC',
          field: 'networkSiteCode',
          value: nsc.networkSiteCode,
          state: 'unchanged'
        }),
        location: new Input({
          label: 'Location',
          field: 'location',
          value: nsc.location,
          state: 'unchanged'
        }),
        _deleted: new Input({ ...nscsColumnsMap.value.get('_deleted')?.input })
      }
      inputs.networkSiteCode?.validate()
      inputs.location?.validate()
      nscForm.rows.value.push({
        nscId: formKeyValue,
        ...nsc,
        inputs
      })
      nscForm.form.value = { ...nscForm.form.value, [formKeyValue]: inputs }
    })
  }
}
async function resetServices(): Promise<void> {
  if (pageMode.value === 'edit' && selectedTaskOrderObj.value?.taskOrderId) {
    const response: any = await api
      .getTaskOrderSvcDetailsDTO(selectedTaskOrderObj.value?.taskOrderId)
      .catch((err: any) => err)
    response.results.map((taskOrder: any) => {
      const processedTaskOrder = {
        taskOrderId: taskOrder.taskOrderId,
        toServiceId: taskOrder.toServiceId,
        vendorPo: taskOrder.vendorPo,
        serviceCode: taskOrder.serviceCode,
        clins: taskOrder.clins,
        allClins: taskOrder.allClins,
        jurisdictions: taskOrder.jurisdictions.map((jurisdiction: any) => ({
          jurisdId: jurisdiction.jurisdId,
          country: jurisdiction.country
        })),
        taskOrderNSCList: taskOrder.taskOrderNSCList.map((e: any) => ({
          taskOrderNscId: e.taskOrderNscId,
          networkSiteCode: e.networkSiteCode,
          location: e.location
        }))
      }
      processEditTaskOrderData(processedTaskOrder)
      return processedTaskOrder
    })
  }
  formState.value = 'unchanged'
}
</script>

<template>
  <div class="chi-grid">
    <div class="chi-col -w-xl--3 -w--8">
      <PickerBox
        :options="serviceOptions"
        :valueSet="picks"
        :settings="{ style: { height: '360px' } }"
        :label="''"
        :chiSearch="{ helperMessage: '', placeHolder: 'Filter' }"
        @onChange="onPick"
      />

      <div class="chi-card__footer -align--center -s--0 -px--0">
        <ActionButton icon="file-export" text="Import" @onClick="onImportServicesClick" />
      </div>
    </div>
    <ImportModal
      v-if="showImportModal"
      :title="selectedServiceDesc"
      :dataFields="dataFields"
      :headerDetails="headerDetails"
      @importContent="handleImportContent"
      @closeModal="closeImportModal"
      @error="handleImportError"
    />

    <div class="chi-col -w-xl--9 -w--12">
      <section
        v-if="picks.size > 0"
        class="chi-table -fixed--header -striped"
        :style="{ height: '460px' }"
      >
        <div>
          <table>
            <thead>
              <tr>
                <th>
                  <div>Service</div>
                </th>
                <th class="-w--20">
                  <div>CLINs</div>
                </th>
                <th class="-w--20">
                  <div class="-w--20 -text--truncate">Country <span>/ Jurisdiction IDs</span></div>
                </th>
                <th class="-w--20">
                  <div>NSCs</div>
                </th>
                <th class="-w--10"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="serviceKey in picks" :key="serviceKey">
                <td>
                  <div>
                    <abbr
                      v-if="serviceMap.get(serviceKey)?.cbsaBased?.includes('Yes')"
                      class="chi-label__required -text--danger -text--no-decoration"
                      aria-label="Required field"
                      >*</abbr
                    >
                    {{ serviceKey + ' - ' + serviceMap.get(serviceKey)?.serviceDesc }}
                  </div>
                  <div v-if="serviceKey === 'MWS'" :class="{ '-mt--2': serviceKey === 'MWS' }">
                    <InputCell :cell="vendorPoCell" :input="vendorPoInput" @onChange="onVendorPo" />
                  </div>
                </td>

                <td :class="{ '-pt--6': serviceKey === 'MWS' }">
                  <Tooltip content="Change"
                    ><button
                      class="chi-button -sm -fluid"
                      aria-label="Change Button"
                      @click="onChangeClick(serviceKey, 'clins')"
                    >
                      <div v-if="quirks.get(serviceKey)?.clins?.size" class="chi-button__content">
                        <span>Some</span>
                        <span>{{
                          affixFormat(quirks.get(serviceKey)?.clins?.size, '(', ')')
                        }}</span>
                      </div>
                      <div v-else class="chi-button__content">
                        <span>All</span>
                        <span>{{
                          affixFormat(serviceMap.get(serviceKey)?.clins?.length, '(', ')')
                        }}</span>
                      </div>
                    </button></Tooltip
                  >
                </td>
                <td :class="{ '-pt--6': serviceKey === 'MWS' }">
                  <Tooltip content="Change"
                    ><button
                      class="chi-button -sm -fluid -outline"
                      :class="{
                        '-disabled':
                          serviceMap.get(serviceKey)?.cbsaBased === 'No' ||
                          nscsSize(quirks.get(serviceKey)?.nscs),
                        '-danger':
                          serviceMap.get(serviceKey)?.cbsaBased?.includes('Yes') &&
                          !nscsSize(quirks.get(serviceKey)?.nscs) &&
                          !quirks.get(serviceKey)?.jurisdictions?.size
                      }"
                      aria-label="Change Button"
                      @click="onChangeClick(serviceKey, 'jurisdictions')"
                    >
                      <div
                        v-if="quirks.get(serviceKey)?.jurisdictions?.size"
                        class="chi-button__content"
                      >
                        <span>Some</span>
                        <span>{{
                          affixFormat(quirks.get(serviceKey)?.jurisdictions?.size, '(', ')')
                        }}</span>
                      </div>
                      <div v-else class="chi-button__content">
                        <span>None</span>
                        <span>{{
                          affixFormat(serviceMap.get(serviceKey)?.jurisdictions?.length, '(', ')')
                        }}</span>
                      </div>
                    </button></Tooltip
                  >
                </td>
                <td :class="{ '-pt--6': serviceKey === 'MWS' }">
                  <Tooltip content="Change"
                    ><button
                      class="chi-button -sm -fluid -outline"
                      :class="{
                        '-disabled':
                          serviceMap.get(serviceKey)?.cbsaBased === 'No' ||
                          quirks.get(serviceKey)?.jurisdictions?.size,
                        '-danger':
                          serviceMap.get(serviceKey)?.cbsaBased?.includes('Yes') &&
                          !nscsSize(quirks.get(serviceKey)?.nscs) &&
                          !quirks.get(serviceKey)?.jurisdictions?.size
                      }"
                      aria-label="Change Button"
                      @click="onChangeClick(serviceKey, 'nscs')"
                    >
                      <div
                        v-if="nscsSize(quirks.get(serviceKey)?.nscs)"
                        class="chi-button__content"
                      >
                        <span>Some</span>
                        <span>{{
                          affixFormat(nscsSize(quirks.get(serviceKey)?.nscs), '(', ')')
                        }}</span>
                      </div>
                      <div v-else class="chi-button__content">
                        <span>None</span>
                        <span>{{
                          affixFormat(serviceMap.get(serviceKey)?.nscs?.length, '(', ')')
                        }}</span>
                      </div>
                    </button></Tooltip
                  >
                </td>
                <td :class="{ '-pt--6': serviceKey === 'MWS' }">
                  <Tooltip content="Remove">
                    <button
                      class="chi-button -sm -danger -flat"
                      aria-label="Delete Button"
                      @click="onRemove(serviceKey)"
                    >
                      <div class="chi-button__content">
                        <i class="chi-icon icon-delete" aria-hidden="true"></i>
                      </div>
                    </button>
                  </Tooltip>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <div v-if="picks.size === 0" class="chi-data-table__row-empty" style="min-height: 480px">
        <EmptyData message="No services selected" icon="cart-outline" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.-text--wrap {
  flex-wrap: wrap;
}
.-text--no-decoration {
  text-decoration: none;
}
</style>
