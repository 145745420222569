<script setup lang="ts">
import { watch, ref } from 'vue'
import { useStore } from './store'
import { storeToRefs } from 'pinia'
import { useColumnsStore } from './ColumnStore.ts'

type SelectAllOption = {
  orderPermission: string
  billingPermission: string
  inventoryPermission: string
  troublePermission: string
  networkPermission: string
}

const store = useStore()
const { selectedAgencyTos, resetSeq, toPermissionList, adminEditingAdimUser, isEditMode } =
  storeToRefs(store)
const columnsStore = useColumnsStore()
const {
  fullPermissionOptions,
  viewOrNonePermission,
  viewOrWritePermission,
  troublePermissionOptions,
  viewOrNonePermissionInventory
} = columnsStore

const selectAll = ref<boolean>(false)
const resetHeaders = ref<boolean>(false)
const selectAllOptionsValue = ref<SelectAllOption>({
  orderPermission: 'NONE',
  billingPermission: 'NONE',
  inventoryPermission: 'NONE',
  troublePermission: 'NONE',
  networkPermission: 'NONE'
})
const troubleWriteAccessOption = ref<any>(troublePermissionOptions.value)
const setEnhancedWorkOrder = ref<boolean>(false)

watch(
  () => selectedAgencyTos.value,
  () => {
    resetTOPermission()
  }
)

watch(
  () => selectAll.value,
  () => {
    if (!selectAll.value) return
    for (let [key, value] of Object.entries(selectAllOptionsValue.value)) {
      for (let to of selectedAgencyTos.value) {
        to[key] = value
      }
    }
    for (let to of selectedAgencyTos.value) {
      for (let access of troubleWriteAccessOption.value) {
        to[access.name] = access.value
        to.showEnhancedWO = to.enhancedWorkOrder === 'Y' ? setEnhancedWorkOrder.value : false
      }
      updateTaskOrderMap(to.agencyId, to)
    }
  }
)

watch(
  () => setEnhancedWorkOrder.value,
  () => {
    if (resetHeaders.value) return
    for (let to of selectedAgencyTos.value) {
      to.showEnhancedWO =
        setEnhancedWorkOrder.value && selectAll.value && to.enhancedWorkOrder === 'Y'
          ? true
          : selectAll.value && !setEnhancedWorkOrder.value
            ? false
            : false
      updateTaskOrderMap(to.agencyId, to)
    }
  }
)

watch(
  () => resetSeq.value,
  () => {
    resetTOPermission()
  }
)

function resetTOPermission(): void {
  resetHeaders.value = true
  selectAll.value = false
  setEnhancedWorkOrder.value = false
  troubleWriteAccessOption.value = troubleWriteAccessOption.value.map((access: any) => ({
    ...access,
    value: false
  }))
  selectAllOptionsValue.value = {
    orderPermission: 'NONE',
    billingPermission: 'NONE',
    inventoryPermission: 'NONE',
    troublePermission: 'NONE',
    networkPermission: 'NONE'
  }
}

function applySelectAllValue(event: any, type: keyof SelectAllOption) {
  resetHeaders.value = false
  selectAllOptionsValue.value[type] = event.target.value
  if (!selectAll.value) return
  for (let to of selectedAgencyTos.value) {
    to[type] = event.target.value
    updateTaskOrderMap(to.agencyId, to)
  }
}

function assignTaskOrderVal(event: any, taskOrder: any, type: keyof SelectAllOption) {
  resetHeaders.value = false
  taskOrder[type] = event.target.value
  updateTaskOrderMap(taskOrder.agencyId, taskOrder)
}

function checkSelectedAccess(event: any, taskOrder: any, access: any): void {
  resetHeaders.value = false
  const key = access.name
  taskOrder[key] = event.target.checked
  updateTaskOrderMap(taskOrder.agencyId, taskOrder)
}
function applyWriteAccess(event: any, access: any) {
  resetHeaders.value = false
  if (!selectAll.value) return
  const type = access.name
  const checked = event.target.checked
  for (let to of selectedAgencyTos.value) {
    to[type] = checked
    updateTaskOrderMap(to.agencyId, to)
  }
}

function assignEnhancedWO(event: any, taskOrder: any): void {
  resetHeaders.value = false
  taskOrder.showEnhancedWO = event.target.checked
  taskOrder.eowaPrivilegeViewEdit = event.target.checked
  taskOrder.eowaPrivilegeViewOnly = !event.target.checked
  updateTaskOrderMap(taskOrder.agencyId, taskOrder)
}

function updateTaskOrderMap(agencyId: string | number, toDetails: any): void {
  toDetails.modified = true
  const existingToDetails: Array<any> = toPermissionList.value.get(agencyId) || []
  if (existingToDetails?.length) {
    const updatedTo = existingToDetails.find(
      (to: any) => to.accountTaskOrderId === toDetails.accountTaskOrderId
    )
    if (updatedTo) {
      const idx = existingToDetails.findIndex(
        (to: any) => to.accountTaskOrderId === toDetails.accountTaskOrderId
      )
      existingToDetails.splice(idx, 1, toDetails)
      toPermissionList.value.set(agencyId, existingToDetails)
    } else {
      toPermissionList.value.set(agencyId, [...existingToDetails, toDetails])
    }
  } else {
    toPermissionList.value.set(agencyId, [toDetails])
  }
}
</script>

<template>
  <fieldset class="-mx--2" :disabled="adminEditingAdimUser">
    <div class="-grid--row -text--bold">
      <div class="-grid-col--2">
        <div>
          <p>Agency Name</p>
          <div class="-text--normal -show">Set All Permissions</div>
        </div>
        <div>
          <p>Task Order</p>
          <div class="-text--normal -hide">Set All Permissions</div>
          <div>
            <div class="chi-checkbox">
              <input
                class="chi-checkbox__input"
                type="checkbox"
                v-model="selectAll"
                id="selectAll"
              />
              <label class="chi-checkbox__label" for="selectAll">
                <div class="-sr--only">Select All</div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="-grid-col--5 -align-item">
        <div>
          <p>Ordering</p>
          <div>
            <div class="chi-form__item">
              <select
                class="chi-select"
                @change="applySelectAllValue($event, 'orderPermission')"
                v-model="selectAllOptionsValue.orderPermission"
              >
                <option
                  v-for="option in fullPermissionOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>

          <div class="-hide">
            <div class="-mt--2" v-if="selectAllOptionsValue.orderPermission === 'SUBMIT'">
              <div class="chi-checkbox">
                <input
                  class="chi-checkbox__input"
                  type="checkbox"
                  v-model="setEnhancedWorkOrder"
                  id="enhancedWO"
                />
                <label class="chi-checkbox__label" for="enhancedWO">
                  <div class="-sr--only">EOWA Approver</div>
                  EOWA Approver
                </label>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p>Billing</p>
          <div class="chi-form__item">
            <select
              class="chi-select"
              @change="applySelectAllValue($event, 'billingPermission')"
              v-model="selectAllOptionsValue.billingPermission"
            >
              <option
                v-for="option in viewOrNonePermission"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <p>Inventory</p>

          <div class="chi-form__item">
            <select
              class="chi-select"
              @change="applySelectAllValue($event, 'inventoryPermission')"
              v-model="selectAllOptionsValue.inventoryPermission"
            >
              <option
                v-for="option in viewOrNonePermissionInventory"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <p>Service Management</p>
          <div class="chi-form__item">
            <select
              class="chi-select"
              @change="applySelectAllValue($event, 'troublePermission')"
              v-model="selectAllOptionsValue.troublePermission"
            >
              <option
                v-for="option in viewOrWritePermission"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>

          <div class="-hide" v-if="selectAllOptionsValue.troublePermission === 'VIEW/WRITE'">
            <div class="-mt--2">
              <div
                class="chi-checkbox"
                v-for="(access, index) in troubleWriteAccessOption"
                :key="index"
              >
                <input
                  class="chi-checkbox__input"
                  type="checkbox"
                  v-model="access.value"
                  :value="access.value"
                  :id="access.label"
                  @change="applyWriteAccess($event, access)"
                />
                <label class="chi-checkbox__label" :for="access.label">
                  <div class="-sr--only">{{ access.label }} ticket</div>
                  {{ access.label }} ticket
                </label>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p>Network Monitoring</p>

          <div class="chi-form__item">
            <select
              class="chi-select"
              @change="applySelectAllValue($event, 'networkPermission')"
              v-model="selectAllOptionsValue.networkPermission"
            >
              <option
                v-for="option in viewOrNonePermission"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="-grid--row">
      <div class="-grid-col--2">
        <div></div>
        <div></div>
      </div>
      <div class="-grid-col--5">
        <div>
          <div class="-show" v-if="selectAllOptionsValue.orderPermission === 'SUBMIT'">
            <div class="chi-checkbox">
              <input
                class="chi-checkbox__input"
                type="checkbox"
                v-model="setEnhancedWorkOrder"
                id="enhancedWO"
              />
              <label class="chi-checkbox__label" for="enhancedWO">
                <div class="-sr--only">EOWA Approver</div>
                EOWA Approver
              </label>
            </div>
          </div>
        </div>
        <div></div>
        <div></div>
        <div class="-show" v-if="selectAllOptionsValue.troublePermission === 'VIEW/WRITE'">
          <div
            class="chi-checkbox"
            v-for="(access, index) in troubleWriteAccessOption"
            :key="index"
          >
            <input
              class="chi-checkbox__input"
              type="checkbox"
              v-model="access.value"
              :value="access.value"
              :id="access.label"
              @change="applyWriteAccess($event, access)"
            />
            <label class="chi-checkbox__label" :for="access.label">
              <div class="-sr--only">{{ access.label }} ticket</div>
              {{ access.label }} ticket
            </label>
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <div class="chi-divider -mt--2 -mb--2"></div>
    <div style="max-height: 500px; overflow: auto">
      <template v-for="(taskOrder, index) in selectedAgencyTos">
        <div class="-grid--row">
          <div class="-grid-col--2">
            <p class="-m--0">
              {{ taskOrder.agencyIdentifier }}
            </p>
            <p class="-m--0" :class="{ '-text--warning': isEditMode && taskOrder.modified }">
              {{ taskOrder.taskOrderNumber }}
            </p>
          </div>
          <div class="-grid-col--5">
            <div>
              <div class="chi-form__item">
                <select
                  class="chi-select"
                  @change="assignTaskOrderVal($event, taskOrder, 'orderPermission')"
                  v-model="taskOrder.orderPermission"
                >
                  <option
                    v-for="option in fullPermissionOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
              <div
                class="-mt--2"
                v-if="taskOrder.enhancedWorkOrder === 'Y' && taskOrder.orderPermission === 'SUBMIT'"
              >
                <div class="chi-checkbox">
                  <input
                    class="chi-checkbox__input"
                    type="checkbox"
                    @change="assignEnhancedWO($event, taskOrder)"
                    :id="`enhancedWO_${index}`"
                    v-model="taskOrder.showEnhancedWO"
                  />
                  <label class="chi-checkbox__label" :for="`enhancedWO_${index}`">
                    <div class="-sr--only">EOWA Approver</div>
                    EOWA Approver
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div class="chi-form__item">
                <select
                  class="chi-select"
                  @change="assignTaskOrderVal($event, taskOrder, 'billingPermission')"
                  v-model="taskOrder.billingPermission"
                >
                  <option
                    v-for="option in viewOrNonePermission"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>
            <div>
              <div class="chi-form__item">
                <select
                  class="chi-select"
                  @change="assignTaskOrderVal($event, taskOrder, 'inventoryPermission')"
                  v-model="taskOrder.inventoryPermission"
                >
                  <option
                    v-for="option in viewOrNonePermissionInventory"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>
            <div>
              <div class="chi-form__item">
                <select
                  class="chi-select"
                  @change="assignTaskOrderVal($event, taskOrder, 'troublePermission')"
                  v-model="taskOrder.troublePermission"
                >
                  <option
                    v-for="option in viewOrWritePermission"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
              <div
                class="-mt--2"
                v-if="
                  taskOrder.troublePermission === 'VIEW/WRITE' ||
                  (selectAll && selectAllOptionsValue.troublePermission === 'VIEW/WRITE')
                "
              >
                <div class="chi-checkbox" v-for="(access, i) in troubleWriteAccessOption" :key="i">
                  <input
                    class="chi-checkbox__input"
                    type="checkbox"
                    v-model="taskOrder[access.name]"
                    :id="`${access.name}_${index}`"
                    @change="checkSelectedAccess($event, taskOrder, access)"
                  />
                  <label class="chi-checkbox__label" :for="`${access.name}_${index}`">
                    <div class="-sr--only">{{ access.label }} ticket</div>
                    {{ access.label }} ticket
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div class="chi-form__item">
                <select
                  class="chi-select"
                  @change="assignTaskOrderVal($event, taskOrder, 'networkPermission')"
                  v-model="taskOrder.networkPermission"
                >
                  <option
                    v-for="option in viewOrNonePermission"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div
            class="chi-divider"
            style="grid-column: 1/-1"
            v-if="index !== selectedAgencyTos.length - 1"
          ></div>
        </div>
      </template>
    </div>
  </fieldset>
</template>

<style scoped>
.-grid--row {
  display: grid;
  margin-bottom: 1em;
  grid-template-columns: 2fr 4fr;
}

.-grid-col--2 {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}

.-grid-col--5 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 1em;
}

.bg--white {
  background: #fff;
  padding: 2em;
}

.-show {
  display: initial;
}

.-hide {
  display: none;
}

.-align-item {
  align-items: end;
}

@media screen and (max-width: 1100px) {
  .-grid-col--2 {
    grid-template-columns: 1fr;
  }

  .-show {
    display: none;
  }

  .-hide {
    display: initial;
  }
  .-align-item {
    align-items: baseline;
  }

  .-grid-col--5 {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@media screen and (max-width: 727px) {
  .-grid--row {
    grid-template-columns: 1fr;
  }

  .-grid-col--2 {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
