/* eslint-disable no-console */
import { ref, reactive } from 'vue'
import { defineStore } from 'pinia'
import { NewsControllerService } from '../../openapi/clients/admin-ui-service-api/services/NewsControllerService'
import { NewsEventDTO } from '../../openapi/clients/admin-ui-service-api/models/NewsEventDTO'

type Modify<T, R> = Omit<T, keyof R> & R

type NewsEvent = Modify<
  NewsEventDTO,
  {
    isActive: string
  }
>
export const useNewsDashboard = defineStore('news-dashboard', () => {
  const alert = reactive({
    show: false,
    title: '',
    message: '',
    color: '' as any,
    closable: false
  })
  const news = ref<NewsEventDTO[]>([])
  const newsID = ref<number>(0)
  const totalNews = ref<number>(0)
  const newsPayload = ref<NewsEvent>({
    newsEventId: 0,
    newsTitle: '',
    newsBody: '',
    isActive: 'Y',
    createdOn: '',
    updatedOn: '',
    createdByUserName: '',
    updatedByUserName: ''
  })

  const showAlert = (title: string, message: string, color: string, closable: boolean = true) => {
    alert.show = true
    alert.title = title
    alert.message = message
    alert.color = color
    alert.closable = closable
  }
  const createNewsFailure = ref<boolean>(false)
  const updateNewsFailure = ref<boolean>(false)

  const fetchNews = async (page: number, pageSize: number): Promise<void> => {
    try {
      const response = await NewsControllerService.getNewsEvents(page, pageSize)
      news.value = response.results as NewsEventDTO[]
      totalNews.value = response.totalSize ?? 0
    } catch (error) {
      console.error('Error fetching news:', error)
    }
  }

  const setNewsPayload = (bodyPayload: NewsEvent): NewsEvent => {
    return { ...newsPayload.value, ...bodyPayload }
  }

  const deleteNews = async (newsId: number): Promise<void> => {
    await NewsControllerService.deleteNewsEvents(newsId)
  }

  const createNews = async (token: string, body: NewsEvent): Promise<void> => {
    const bodyPayload = setNewsPayload(body)
    try {
      const response = await NewsControllerService.createNewsEvents(
        token,
        bodyPayload as NewsEventDTO
      )
      newsID.value = response?.newsEventId as number
      createNewsFailure.value = false
    } catch (error) {
      createNewsFailure.value = true
      console.error('Error creating news:', error)
    }
  }
  const updateNews = async (token: string, body: any): Promise<void> => {
    const bodyPayload = setNewsPayload(body)
    try {
      const response = await NewsControllerService.updateNewsEvents(
        token,
        bodyPayload as NewsEventDTO
      )
      newsID.value = response?.newsEventId as number
      updateNewsFailure.value = false
    } catch (error) {
      updateNewsFailure.value = true
      console.error('Error in Update News API', error)
    }
  }
  return {
    news,
    totalNews,
    newsID,
    alert,
    createNewsFailure,
    updateNewsFailure,
    fetchNews,
    deleteNews,
    createNews,
    updateNews,
    showAlert
  }
})
