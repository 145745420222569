<script lang="ts" setup>
import { defineProps, ref, watchEffect, computed } from 'vue'
import Accordion from '../common/Accordion.vue'
import { useTOAdminStore } from '../../store/to-admin'
import moment from 'moment'

interface IProps {
  items: any[]
}
const props = defineProps<IProps>()
const fields = [
  { label: 'TO Iteration', id: 'iterationNumber' },
  { label: 'TO MOD Number', id: 'modNumber' },
  { label: 'Start Date of Iteration', id: 'iterationStartDate' },
  { label: 'End Date of Iteration', id: 'iterationEndDate' },
  { label: 'Date Added', id: 'createdOn' },
  { label: 'Added By', id: 'createdBy' },
  { label: 'Date Last Updated', id: 'updatedOn' },
  { label: 'Status', id: 'status' },
  { label: 'Pramata ID', id: 'pramataId' },
  { label: 'MOD Approved Date', id: 'modApprovedDate' },
  { label: 'Description', id: 'modDescription' }
]

const selectedIteration = ref<string>('')
const form = ref<any>({})
const canEdit = ref<boolean>(false)

const store = useTOAdminStore()

watchEffect(() => {
  if (props.items.length) {
    selectedIteration.value = props.items[0].generatedId
  }
})

function fieldValue(iteration: any, field: string): any {
  return iteration[field]
}

function needDatePicker(field: string): boolean {
  return ['iterationStartDate', 'iterationEndDate', 'modApprovedDate'].includes(field)
}

function isReadOnlyField(field: string): boolean {
  return ['createdOn', 'createdBy', 'updatedOn', 'status'].includes(field)
}

function addClasses(field: string) {
  if (field === 'modDescription') {
    return '-w--4'
  }
  return ''
}

function readOnlyItem(id: string, field: string): boolean {
  if (selectedIteration.value !== id) {
    return true
  }
  if (selectedIteration.value === id && !canEdit.value) {
    return true
  }
  if (!['modDescription', 'modApprovedDate', 'modNumber'].includes(field)) {
    return true
  }
  return false
}

function isRequiredField(id: string, field: string): boolean {
  if (readOnlyItem(id, field)) {
    return false
  }
  return ['iterationStartDate', 'pramataId', 'modApprovedDate', 'modNumber'].includes(field)
}

function onSelectedIteration(iteration: any): void {
  selectedIteration.value = iteration.generatedId
  canEdit.value = false
}

function onEdit(iteration: any): void {
  form.value = { ...iteration }
  canEdit.value = true
}

async function onSave(): Promise<void> {
  const request = {
    ...form.value,
    iterationStartDate:
      form.value.iterationStartDate && moment(form.value.iterationStartDate).format('YYYY-MM-DD'),
    iterationEndDate:
      form.value.iterationEndDate && moment(form.value.iterationEndDate).format('YYYY-MM-DD'),
    modApprovedDate:
      form.value.modApprovedDate && moment(form.value.modApprovedDate).format('YYYY-MM-DD'),
    createdOn: form.value.createdOn && moment(form.value.createdOn).format('YYYY-MM-DD')
  }
  await store.editMod(request)
  onCancel()
}

function onCancel(): void {
  form.value = {}
  canEdit.value = false
}

function editForm(field: string, $event: any): void {
  const value = {
    ...form.value
  }
  if (needDatePicker(field)) {
    value[field] = $event.detail
  } else {
    value[field] = $event.target.value
  }
  form.value = value
}

const canEnableSave = computed(() => {
  const iteration = props.items.find((item) => item.iterationNumber === selectedIteration.value)
  if (iteration && JSON.stringify(iteration) !== JSON.stringify(form.value)) {
    return true
  }
  return false
})
</script>
<template>
  <Accordion title="TO Iteration" :defaultOpen="true">
    <div
      class="-d--flex -align-items--top -mb--2"
      v-for="iteration in props.items"
      :key="iteration.generatedId"
    >
      <div class="chi-form__item -mr--2" style="width: 40px">
        <div class="chi-radio" @click="onSelectedIteration(iteration)">
          <input
            class="chi-radio__input"
            type="radio"
            :checked="iteration.generatedId === selectedIteration"
          />
          <label class="chi-radio__label">{{ ' ' }} </label>
        </div>
      </div>
      <div class="-flex-grow1 -mr--2">
        <div class="chi-grid iteration-wrapper -pb--2">
          <div
            class="chi-col -w--2 -mb--2"
            v-for="item in fields"
            :key="item.id"
            :class="addClasses(item.id)"
          >
            <div class="chi-form__item">
              <chi-label :required="isRequiredField(iteration.generatedId, item.id)">{{
                item.label
              }}</chi-label>
              <div v-if="readOnlyItem(iteration.generatedId, item.id)">
                {{ fieldValue(iteration, item.id) }}
              </div>
              <template v-else>
                <chi-date-picker
                  format="MM/DD/YYYY"
                  :value="fieldValue(form, item.id)"
                  v-if="needDatePicker(item.id)"
                  @chiDateChange="editForm(item.id, $event)"
                />
                <div v-else-if="isReadOnlyField(item.id)">{{ fieldValue(form, item.id) }}</div>
                <textarea
                  class="chi-input"
                  :value="fieldValue(form, item.id)"
                  @input="editForm('modDescription', $event)"
                  v-else-if="item.id === 'modDescription'"
                />
                <input
                  v-else
                  type="input"
                  class="chi-input"
                  :value="fieldValue(form, item.id)"
                  @input="editForm(item.id, $event)"
                />
              </template>
            </div>
          </div>
          <div
            class="chi-col -w--12 -d--flex -justify-content--center"
            style="gap: 16px"
            v-if="selectedIteration === iteration.generatedId"
          >
            <button
              class="chi-button -sm -primary"
              @click="onEdit(iteration)"
              v-if="!canEdit"
              color="primary"
            >
              Edit
            </button>
            <template v-else>
              <button
                class="chi-button -sm -primary"
                @click="onSave"
                color="primary"
                :disabled="!canEnableSave"
              >
                Save
              </button>
              <button class="chi-button -sm" @click="onCancel" variant="flat">Cancel</button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Accordion>
</template>
<style lang="css" scoped>
.iteration-wrapper {
  border-bottom: 1px solid #ccc;
  font-size: 14px;
}
</style>
