import moment from 'moment'
export const createFinancialFields = [
  'taskOrderCeiling',
  'taskOrderAgfRate',
  'performancePeriodFunding',
  'prorationType',
  'performanceBeginDate',
  'performanceEndDate'
]

export const createFinancialHeaders = [
  'TO Ceiling Amount',
  'TO AGF Rate (%)',
  'Performance Period Funding',
  'Proration Type',
  'Performance Begin Date',
  'Performance End Date'
]

export const officialImportFields = [
  'firstName',
  'lastName',
  'workOfficePhone',
  'workMobilePhone',
  'workEmail',
  'role',
  'startDate',
  'stopDate'
]
export const officialImportHeaders = [
  'First Name',
  'Last Name',
  'Office Phone',
  'Mobile Phone',
  'Email',
  'Role',
  'Start Date',
  'End Date'
]

export function generateFinancial() {
  return {
    id: crypto.randomUUID(),
    isNew: true,
    taskOrderCeiling: '',
    taskOrderAgfRate: '4.75',
    performancePeriodFunding: '',
    prorationType: '',
    performanceBeginDate: '',
    performanceEndDate: '',
    errors: {}
  }
}

export function generateOfficial() {
  return {
    firstName: '',
    lastName: '',
    workOfficePhone: '',
    workMobilePhone: '',
    workEmail: '',
    role: '',
    startDate: '',
    id: crypto.randomUUID()
  }
}

export function populateOfficialRow() {
  return {
    firstName: { value: '', validators: [requiredValidator], error: null },
    lastName: { value: '', validators: [requiredValidator], error: null },
    workOfficePhone: { value: '', validators: [requiredValidator], error: null },
    workMobilePhone: { value: '', validators: [requiredValidator], error: null },
    workEmail: { value: '', validators: [requiredValidator], error: null },
    role: { value: '', validators: [requiredValidator], error: null },
    startDate: { value: '', validators: [requiredValidator, validDates, isAfter], error: null },
    endDate: { value: '', validators: [requiredValidator, validDates, isAfter], error: null },
    id: { value: crypto.randomUUID(), validators: [], error: null }
  }
}

export function requiredValidator(value: string) {
  if (!value) {
    return {
      required: true
    }
  }
  return null
}

export function emailValidator(value: string) {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (!emailRegex.test(value)) {
    return {
      invalidEmail: true
    }
  }
  return null
}

function isValidDate(value: string): boolean {
  if (!value) return false
  return moment(value, 'MM/DD/YYYY', true).isValid()
}

export function validDates(dateValue: string) {
  if (!isValidDate(dateValue)) {
    return {
      invalidDate: true
    }
  }
  return null
}

export function isAfter(startDate: string, endDate: string) {
  const isStartDateAfter =
    isValidDate(startDate) &&
    isValidDate(endDate) &&
    moment(startDate, 'MM/DD/YYYY', true).isSameOrAfter(moment(endDate, 'MM/DD/YYYY', true))

  if (isStartDateAfter) {
    return {
      dateAfter: true
    }
  }
  return null
}

export function encodeAgencyIdentifier(agencyIdentifier: string): string {
  return btoa(agencyIdentifier)
}
