<script lang="ts" setup>
import { computed, onMounted, ref, Ref } from 'vue'
import ImportFile from './../ImportFile.vue'
import { Modal } from '@centurylinkfederal/eis-vue'

const props = defineProps(['serviceId', 'nscDataInbound', 'mode', 'selectedNCS'])
const emit = defineEmits(['close', 'saveNSC'])

const nscActionButtons =
  props.mode === 'VIEW'
    ? [
        {
          text: 'Cancel',
          dismissModal: true
        }
      ]
    : [
        { text: 'Apply' },
        { text: 'Add More' },
        { text: 'Import' },
        {
          text: 'Cancel',
          dismissModal: true
        }
      ]

const title = computed(() =>
  props.mode === 'VIEW' ? `Selected NSC for ${props.serviceId}` : `Add NSC ${props.serviceId}`
)

const selectedLocations: Ref<Array<any>> = ref<any[]>([])
const showLocationsModal: Ref<boolean> = ref<boolean>(false)

const headers = ['NSC', 'Location']
const fields = ['networkSiteCode', 'location']

function close(): void {
  emit('close')
}

onMounted(() => {
  if (props.mode === 'VIEW') {
    selectedLocations.value = props.selectedNCS
    return
  }
  selectedLocations.value = []
  props.nscDataInbound.forEach((nscObj: any) => {
    let instanceObj = {
      taskOrderNscId: nscObj.taskOrderNscId,
      networkSiteCode: nscObj.networkSiteCode,
      location: nscObj.location
    }
    selectedLocations.value.push(instanceObj)
  })
  if (selectedLocations.value.length == 0) {
    addLocation()
  }
})

function addLocation(): void {
  selectedLocations.value.push({ networkSiteCode: '', location: '' })
}
function removeLocation(index: number): void {
  selectedLocations.value = selectedLocations.value.filter((_: any, i: number) => i !== index)
}
function saveData(): void {
  const selectedLocationsData = selectedLocations.value.filter(
    (elem: any) => !!elem.location || !!elem.networkSiteCode
  )
  emit('saveNSC', selectedLocationsData)
}

function importedFileInfo(details: any = {}): void {
  const { isValid, contents } = details
  contents.forEach((content: any) => {
    content.id = crypto.randomUUID()
    content.errors = {}
  })
  selectedLocations.value = selectedLocations.value.filter(
    (item: any) => item.networkSiteCode && item.location
  )

  selectedLocations.value = [...selectedLocations.value, ...(contents || [])]
  showLocationsModal.value = false
}

function onNSCActionBtnClicked(index: number): void {
  if (props.mode === 'VIEW') {
    close()
  } else {
    if (index == 0) {
      saveData()
    }
    if (index === 1) {
      addLocation()
    }
    if (index === 2) {
      showLocationsModal.value = true
    }
    if (index === 3) {
      close()
    }
  }
}
</script>
<template>
  <div>
    <Modal
      :center="true"
      :title="title"
      details=" "
      :buttons="nscActionButtons"
      @onButtonClick="onNSCActionBtnClicked"
      @onDismissModal="close"
    >
      <div class="chi-card">
        <div class="chi-card__content">
          <div class="table">
            <div class="table-heading">
              <div class="row-item">
                <div class="table-cell">
                  <div class="label-align"><strong>NSC</strong></div>
                </div>
                <div class="table-cell">
                  <div class="label-align"><strong>Location</strong></div>
                </div>
                <div class="table-cell"></div>
              </div>
            </div>
            <div style="max-height: 400px; overflow-y: auto">
              <div
                class="row-item"
                v-for="(location, index) in selectedLocations"
                :key="`nsc_options_${index}`"
              >
                <div class="table-cell">
                  <span v-if="props.mode === 'VIEW'">{{ location.networkSiteCode }}</span>
                  <input v-else class="chi-input" type="text" v-model="location.networkSiteCode" />
                </div>
                <div class="table-cell">
                  <span v-if="props.mode === 'VIEW'">{{ location.location }}</span>

                  <input v-else class="chi-input" type="text" v-model="location.location" />
                </div>
                <div class="table-cell">
                  <div class="label-align">
                    <div class="icon" style="margin: auto">
                      <i
                        class="chi-icon icon-delete"
                        v-if="index !== 0"
                        style="cursor: pointer"
                        @click="removeLocation(index)"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <!-- Import Modal -->
    <div v-if="showLocationsModal">
      <ImportFile
        title="Locations"
        :dataFields="fields"
        :headerDetails="headers"
        @closeModal="showLocationsModal = false"
        @importContent="importedFileInfo($event)"
      />
    </div>
  </div>
</template>
<style scoped>
.table {
  margin-bottom: 0px;
}

.table-row {
  display: flex;
}

.table-heading > .row-item {
  background-color: rgb(56 198 244);
  height: 45px;
  color: black;
}

.row-item {
  display: flex;
  flex-wrap: nowrap;
  height: 45px;
}

.row-item > .table-cell {
  padding: 0.25rem;
  border-right: 0.0625rem solid #d0d4d9;
  border-bottom: 0.0625rem solid #d0d4d9;
}

.row-item > .table-cell:first-child {
  border-left: 0.0625rem solid #d0d4d9;
  flex: 6;
}

.row-item > .table-cell:nth-child(2) {
  flex: 6;
}

.row-item > .table-cell:nth-child(3) {
  flex: 2;
}

.row-item > .table-cell .label-align {
  margin: auto !important;
  padding: 10px 0;
  width: 60%;
  text-align: center;
}
</style>
