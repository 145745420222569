import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useForm } from '../../composables/form'

export const useStore = defineStore('createTaskOrderOfficialsStore', () => {
  const resetSeq = ref(0)
  const deleted = ref<any[]>([])
  const { form, formState, formChanges, isFormEnabled } = useForm()

  function flush(): void {
    form.value = {}
    formState.value = undefined
    deleted.value = []
  }

  return {
    resetSeq,
    form,
    formState,
    formChanges,
    isFormEnabled,
    deleted,
    flush
  }
})
