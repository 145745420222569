<script lang="ts" setup>
import ImportFile from './../ImportFile.vue'
import { Modal } from '@centurylinkfederal/eis-vue'
import { ref, Ref, onMounted, defineEmits, computed } from 'vue'

const props = defineProps(['serviceCode', 'selectedClinCodesInput', 'allClins', 'mode'])
const emit = defineEmits(['close', 'selectedClins'])

const isAllClinsSelected: Ref<boolean> = ref<boolean>(false)
const showLocationsModal: Ref<boolean> = ref<boolean>(false)
const allClinsDto: Ref<Array<any>> = ref<Array<any>>([])
const selectedClinCodes: Ref<Array<any>> = ref<Array<any>>([])
const title = computed(() =>
  props.mode === 'VIEW'
    ? `Individual Selected Clins for ${props.serviceCode}`
    : `Select TO CLINs Awarded by Service ${props.serviceCode}`
)

const headers = ['Clins']
const fields = ['clin']

const clinsActionButtons =
  props.mode === 'VIEW'
    ? [
        {
          text: 'Cancel',
          dismissModal: true
        }
      ]
    : [
        { text: 'Apply' },
        { text: 'Import' },
        {
          text: 'Cancel',
          dismissModal: true
        }
      ]

onMounted(() => {
  if (props.mode === 'VIEW') {
    allClinsDto.value = props.selectedClinCodesInput
    return
  }
  onAllClinsChanged()
  processSelectedClins()
})

function onAllClinsChanged(): void {
  let allClinsDuplicate = props.allClins.slice()
  allClinsDto.value = []
  allClinsDuplicate.forEach((clinString: any) => {
    let clinDto = {
      clinId: clinString.substring(0, 7),
      clinName: clinString.substring(10)
    }
    allClinsDto.value.push(clinDto)
  })
}
function processSelectedClins(): void {
  selectedClinCodes.value = []
  if (props.selectedClinCodesInput.length == 0) {
    allClinsDto.value.forEach((clinDto) => {
      selectedClinCodes.value.push(clinDto.clinId)
      isAllClinsSelected.value = true
    })
  } else {
    selectedClinCodes.value = props.selectedClinCodesInput.slice()
    isAllClinsSelected.value = false
  }
}

function openDialog(): void {
  showLocationsModal.value = true
}
function importedFileInfo(details: any = {}): void {
  const { isValid, contents } = details
  contents.forEach((content: any) => {
    content.id = crypto.randomUUID()
    content.errors = {}
  })
  selectedClinCodes.value = []
  contents.forEach((data: any) => {
    selectedClinCodes.value.push(data.clin)
  })

  showLocationsModal.value = false
}

function toggleAllClinsSelected(event: any): void {
  isAllClinsSelected.value = !!event.target.checked
  selectedClinCodes.value = []
  if (isAllClinsSelected.value) {
    allClinsDto.value.forEach((clinDto) => {
      selectedClinCodes.value.push(clinDto.clinId)
    })
  }
}
function applyClins(): void {
  emit('selectedClins', selectedClinCodes.value)
}
function closeClins(): void {
  emit('close')
}

function onClinsActionBtnClicked(index: number): void {
  if (props.mode === 'VIEW') {
    closeClins()
  } else {
    if (index === 0) {
      // apply
      applyClins()
    }
    if (index === 1) {
      //import
      openDialog()
    }
    if (index == 2) {
      closeClins()
    }
  }
}
</script>

<template>
  <div>
    <Modal
      :center="true"
      :title="title"
      details=" "
      :buttons="clinsActionButtons"
      @onButtonClick="onClinsActionBtnClicked"
      @onDismissModal="closeClins"
    >
      <div style="max-height: 400px; overflow-y: auto">
        <table class="chi-table -bordered -striped">
          <thead class="header">
            <tr>
              <td class="table-cell" v-if="props.mode !== 'VIEW'">
                <input
                  :checked="isAllClinsSelected"
                  @click="toggleAllClinsSelected"
                  class="chi-checkbox__input"
                  id="toggleAllClinsId"
                  type="checkbox"
                  value="all"
                />
                <label class="chi-checkbox__label" for="toggleAllClinsId">
                  <div class="-sr--only">Label</div>
                </label>
              </td>
              <td class="table-cell">CLIN</td>
            </tr>
          </thead>
          <tbody>
            <tr :key="`clin-${idx}`" v-for="(clinObj, idx) in allClinsDto">
              <td v-if="props.mode !== 'VIEW'">
                <input
                  :id="`clin-${clinObj.clinId}`"
                  :value="clinObj.clinId"
                  class="chi-checkbox__input"
                  type="checkbox"
                  v-model="selectedClinCodes"
                />
                <label :for="`clin-${clinObj.clinId}`" class="chi-checkbox__label">
                  <div class="-sr--only">Label</div>
                </label>
              </td>
              <td class="smallLeftFont">
                {{ clinObj.clinId.trim() + ' - ' + clinObj.clinName.trim() }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
    <div v-if="showLocationsModal">
      <ImportFile
        title="CLINS"
        :dataFields="fields"
        :headerDetails="headers"
        @closeModal="showLocationsModal = false"
        @importContent="importedFileInfo($event)"
      />
    </div>
  </div>
</template>
()()
