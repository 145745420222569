<script setup lang="ts">
import { useEisUser, EisDrawer } from '@centurylinkfederal/eis-vue'
const eisUser = useEisUser()
</script>
<template>
  <div class="-d--flex -h--100">
    <aside>
      <EisDrawer class="chi-sidenav__drawers" />
    </aside>
    <div class="-flex--grow1 -h--100">
      <eis-header />
      <div class="-mt--2 main-container">
        <template v-if="eisUser.user">
          <router-view />
        </template>
      </div>
      <eis-footer />
    </div>
  </div>
</template>
<style>
.chi-header__navbar {
  max-height: 41px;
}
</style>
