<script lang="ts" setup>
import { ref, Ref, watch, unref, nextTick } from 'vue'
import { useToAdminAPI } from '../../composables/to-admin/api'
import { useTOAdminStore } from '../../store/to-admin'
import EditNSCModal from './EditNSCModal.vue'
import ViewEditClins from './ViewEditClins.vue'
import ViewEditCountryJurisdiction from './ViewEditServiceCountryJuridiction.vue'

const api = useToAdminAPI()

const props = defineProps(['taskOrderNumber', 'taskOrderId', 'edit', 'loading'])
const toAdminStore = useTOAdminStore()

const selectedService = ref()
const selectedJuridiction = ref()
const selectedNCS: Ref<any> = ref<any>(null)
const showClinModal = ref(false)
const showJurisdictions = ref(false)
const taskOrderNSCs: Ref<Array<any>> = ref([])
const selectedToServicesDTO: Ref<Array<any>> = ref([])
const allViewServices: Ref<Array<any>> = ref([])
const selectedClinCodesInput: Ref<Array<any>> = ref([])
const clins: Ref<Array<any>> = ref([])
const currentServiceId: Ref<string> = ref('')
const showNSCPopup: Ref<boolean> = ref<boolean>(false)
const selectedClins: Ref<any> = ref<any>(null)

watch(
  () => [toAdminStore.selectedToServicesDTO, toAdminStore.allServices],
  () => {
    selectedToServicesDTO.value = unref(toAdminStore.selectedToServicesDTO)
    allViewServices.value = toAdminStore.allServices
  },
  { deep: true, immediate: true }
)

function viewClins(selectedService: any): void {
  selectedClins.value = selectedService
  const serviceClins = allViewServices.value?.find(
    (service: any) => service?.serviceID === selectedService.serviceCode
  )
  selectedClinCodesInput.value = serviceClins?.clins?.map((element: any) => ({
    clinId: element.substring(0, 7),
    clinName: element.substring(10)
  }))
  showClinModal.value = true
}

function closeClinsModal(): void {
  showClinModal.value = false
}

function checkCbsaBasedService(svcId: string): boolean {
  const svc: any = allViewServices.value?.find((service: any) => service?.serviceID === svcId)
  return svc?.cbsaBased !== 'No'
}

function viewJurisdictions(selectedService: any): void {
  selectedJuridiction.value = selectedService?.jurisdictions
  currentServiceId.value = selectedService.serviceCode
  showJurisdictions.value = true
}

function showNSCpopup(selectedService: any): void {
  selectedNCS.value = selectedService
  showNSCPopup.value = true
}

function closeNSC(): void {
  showNSCPopup.value = false
}
function closeJurisdictionModal(): void {
  // selectedService.value = ''
  showJurisdictions.value = false
}
function findDescription(code: any): any {
  let allcodes = allViewServices.value
  let result: any = allcodes?.find((e: any) => e?.serviceID === code)
  return result?.serviceDesc
}
</script>

<template>
  <div>
    <div v-if="props.loading" class="-text--center">
      <svg class="chi-spinner -text--primary -sm--3" viewBox="0 0 66 66">
        <title>Loading</title>
        <circle class="path" cx="33" cy="33" r="30" fill="none" stroke-width="6"></circle>
      </svg>
    </div>
    <div v-else :key="index" v-for="(service, index) in selectedToServicesDTO">
      <div class="service-grid" :key="index">
        <div>
          <p>{{ service.serviceCode }} - {{ findDescription(service.serviceCode) }}</p>
        </div>
        <div>
          <p class="-ml--2">{{ service.allClins ? 'All Clins' : 'Individual CLINs' }}</p>
        </div>

        <div v-if="!service.allClins">
          <button
            @click="viewClins(service)"
            class="chi-button --primary -sm"
            style="width: max-content"
          >
            View CLINs
          </button>
        </div>
        <div
          v-if="
            checkCbsaBasedService(service.serviceCode) &&
            service.jurisdictions &&
            service.jurisdictions.length > 0
          "
        >
          <button
            @click="viewJurisdictions(service)"
            class="chi-button --primary -sm"
            style="width: max-content"
          >
            View Country/Jurisdiction ID's
          </button>
        </div>
        <div
          v-if="
            checkCbsaBasedService(service.serviceCode) &&
            service.taskOrderNSCList &&
            service.taskOrderNSCList.length > 0
          "
        >
          <button
            @click="showNSCpopup(service)"
            class="chi-button --primary -sm"
            style="width: max-content"
          >
            View NSCs
          </button>
        </div>
        <div v-if="service.vendorPo">
          <p>Vendor PO : {{ service.vendorPo }}</p>
        </div>
      </div>
      <div class="chi-divider" v-if="index !== selectedToServicesDTO.length - 1"></div>
    </div>
    <!-- Country Juridiction -->
    <div v-if="showJurisdictions">
      <ViewEditCountryJurisdiction
        mode="VIEW"
        :selectedService="selectedJuridiction"
        :serviceId="currentServiceId"
        @close="closeJurisdictionModal"
      >
      </ViewEditCountryJurisdiction>
    </div>
    <div v-if="showNSCPopup">
      <EditNSCModal
        @close="closeNSC()"
        :serviceId="selectedNCS?.serviceCode"
        mode="VIEW"
        :selectedNCS="selectedNCS?.taskOrderNSCList"
      />
    </div>

    <!-- Individual Clins Pop up -->
    <div v-if="showClinModal">
      <ViewEditClins
        :allClins="clins"
        :serviceCode="selectedClins?.serviceCode"
        :selectedClinCodesInput="selectedClinCodesInput"
        @close="closeClinsModal()"
        mode="VIEW"
      />
    </div>
  </div>
</template>
<style scoped>
.table-cell-v1 {
  text-align: left !important;
  width: 10%;
}

.table-cell-v2 {
  text-align: left !important;
}

thead > tr > td.table-cell {
  background-color: rgb(146, 146, 146);
}

.modal-backdrop {
  z-index: -1;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(6, minmax(100px, 1fr));
  gap: 1em;
  align-items: center;
}
</style>
