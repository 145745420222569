<script lang="ts" setup>
import { ref, onBeforeMount, computed, unref } from 'vue'
import { useApi } from '@centurylinkfederal/eis-vue'
import Alert from './Alerts.vue'
import { useAlertsStore } from '../store/alert'
const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)
const agency = ref('')
const maskedName = ref('')
const bizOrgId = ref('')
const notes = ref('')
const agencies = ref<{ abcodeLookUpId: string; agencyDisplayName: string }[]>([])
const alertsStore = useAlertsStore()

const isValid = computed(() => {
  return agency.value && maskedName.value && bizOrgId.value
})

const formValues = computed(() => {
  return {
    agency: agency.value,
    maskedName: maskedName.value,
    bizOrgId: bizOrgId.value
  }
})

const errorMessage = {
  agency: 'Agency is required',
  maskedName: 'Masked Name is required',
  bizOrgId: 'BizOrgId is required'
}
const formErrors = ref({ agency: '', maskedName: '', bizOrgId: '' })
function reset() {
  agency.value = ''
  maskedName.value = ''
  bizOrgId.value = ''
  notes.value = ''
}

function checkFormValidity(): any {
  formErrors.value = { agency: '', maskedName: '', bizOrgId: '' }
  const value = unref(formValues)
  let hasError = false

  for (let key of Object.keys(value)) {
    const formVal = value[key as 'agency' | 'maskedName' | 'bizOrgId']
    if (!formVal) {
      formErrors.value[key as 'agency' | 'maskedName' | 'bizOrgId'] =
        errorMessage[key as 'agency' | 'maskedName' | 'bizOrgId']
      hasError = true
    }
  }

  return hasError
}

async function submit() {
  if (checkFormValidity()) {
    window.scroll({ top: 0, behavior: 'smooth' })
    return
  }

  const selectedAgency = agencies.value.find((a: any) => a.abcodeLookUpId == agency.value)
  try {
    await api.post('/agency', {
      abcodeLookUpId: agency.value,
      bizOrgId: bizOrgId.value,
      maskedName: maskedName.value,
      notes: notes.value,
      agencyDisplayName: selectedAgency?.agencyDisplayName
    })
    alertsStore.addAlert('success', `A new record was created successfully`)
    getAllAgencies()
    reset()
  } catch (e: any) {
    let message = e?.response?.data?.message || 'Unable to process request'
    if (message === '[Record already exist]')
      message = `Your request couldn't be completed. Agency with "${selectedAgency?.agencyDisplayName}" already exists in the system.`
    alertsStore.addAlert('danger', message)
  }
  window.scroll({ top: 0, behavior: 'smooth' })
}

function validateForm(type: 'agency' | 'maskedName' | 'bizOrgId'): void {
  const value = formValues.value
  formErrors.value[type] = ''
  if (!value[type]) {
    formErrors.value[type] = errorMessage[type]
  }
}

async function getAllAgencies() {
  try {
    const data = await api.get('/configurable-agencies')
    agencies.value = data as { abcodeLookUpId: string; agencyDisplayName: string }[]
  } catch {}
}

onBeforeMount(() => {
  getAllAgencies()
})
</script>
<template>
  <div class="chi-grid">
    <div class="chi-col -w--6 -m--2">
      <div class="chi-form__item -mb--2">
        <label class="chi-label" for="agency">
          Agency
          <abbr class="chi-label__required" title="Required field">*</abbr>
        </label>
        <select
          class="chi-select -md"
          id="agency"
          v-model="agency"
          @change="validateForm('agency')"
        >
          <option value="">Select</option>
          <option
            v-for="agency in agencies"
            :key="agency.abcodeLookUpId"
            :value="agency.abcodeLookUpId"
          >
            {{ agency.agencyDisplayName }}
          </option>
        </select>
        <span class="-text--danger" v-if="formErrors.agency">{{ formErrors.agency }}</span>
      </div>
      <div class="chi-form__item -mb--2">
        <label class="chi-label" for="maskedName">
          Masked Name
          <abbr class="chi-label__required" title="Required field">*</abbr>
        </label>
        <input
          class="chi-input -md"
          id="maskedName"
          v-model="maskedName"
          maxlength="50"
          @input="validateForm('maskedName')"
        />

        <span class="-text--danger" v-if="formErrors.maskedName">{{ formErrors.maskedName }}</span>
      </div>
      <div class="chi-form__item -mb--2">
        <label class="chi-label" for="bizOrgId">
          BizOrgId
          <abbr class="chi-label__required" title="Required field">*</abbr>
        </label>
        <input
          class="chi-input -md"
          id="bizOrgId"
          v-model="bizOrgId"
          maxlength="50"
          @input="validateForm('bizOrgId')"
        />
        <span class="-text--danger" v-if="formErrors.bizOrgId">{{ formErrors.bizOrgId }}</span>
      </div>
      <div class="chi-form__item -mb--2">
        <label class="chi-label" for="notes"> Notes </label>
        <textarea class="chi-input -md" id="notes" v-model="notes" maxlength="150" />
      </div>
    </div>
    <div class="chi-col -w--8 -m--2">
      <div class="-d--flex -flex--row-reverse">
        <button class="chi-button -md -primary" @click="reset">Reset</button>
        <button class="chi-button -md -primary -mr--2" @click="submit">Create Agency</button>
      </div>
    </div>
  </div>
</template>
