import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useDbasStore = defineStore('dbasStore', () => {
  const dbasFormData = ref<any>({})
  const selectedTaskOrderNumber = ref<string>('')
  const selectedTaxExempt = ref<string>('')
  const requestXML = ref<string>('')
  const responseXML = ref<string>('')
  const isModalClosed = ref<boolean>(true)
  const isSpinnerVisible = ref<boolean>(false)
  const successAlerts = ref<any>([])
  const errorAlerts = ref<any>([])
  const dbasAssociationIdOfSelectedTaskOrder = ref<string>('')
  const displayTOError = ref<boolean>(false)
  const displayTaxExemptError = ref<boolean>(false)
  const accountId = ref<string>('')
  const taskOrderId = ref<string>('')

  return {
    dbasFormData,
    selectedTaskOrderNumber,
    selectedTaxExempt,
    requestXML,
    responseXML,
    isModalClosed,
    successAlerts,
    errorAlerts,
    isSpinnerVisible,
    dbasAssociationIdOfSelectedTaskOrder,
    displayTOError,
    displayTaxExemptError,
    accountId,
    taskOrderId
  }
})
