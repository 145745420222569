<script setup lang="ts">
import { useStore } from './store'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import Cells from '../CreateUser/Cells.vue'
import { useColumnsStore } from './ColumnStore'
import { onMounted, Ref, ref, watch } from 'vue'
import UserTOPermissions from './UserTOPermissions.vue'
import UserProfileSubmit from './UserProfileSubmit.vue'
import { Action, dispatch } from '../CreateUser/actions'
import { validatePhone } from '../CreateUser/ColumnStore'
import { Row, setInput, StateType, toMatchAPattern, InputType } from '@centurylinkfederal/eis-vue'

const classColDefault = ref('-w-xl-2h--3 -w-xl--3 -w-lg--4 -w-md--6 -w-sm--12')

const settings = {
  key: 'userProfile'
}
const row = ref<Row>({
  userProfile: -1
})
const store = useStore()
const { form, formChanges, formState, passwordExpiresOn, userProfileDetails } = storeToRefs(store)

const columnsStore = useColumnsStore()
const {
  userDetailsCols,
  userAddressLineInfo,
  userCityStateDetails,
  userContactDetails,
  userRemainderDetailsCols,
  notesColumn,
  ruleColumn
} = columnsStore
const quirksMap: Ref<Map<string, any>> = ref(new Map())
const formChangesOnceMap = ref<Map<string, Function>>(new Map())

const router = useRouter()
const passwordExpiryDate = ref()

onMounted(async () => {
  userProfileDetails.value = await dispatch(Action.GET_USER_DETAILS)
})

watch(
  () => userProfileDetails.value,
  () => populateFormValue()
)
function populateFormValue() {
  const rowKey = -1
  const inputs: any = {}
  const columns = [
    ...userDetailsCols.value,
    ...userAddressLineInfo.value,
    ...userCityStateDetails.value,
    ...userContactDetails.value,
    ...userRemainderDetailsCols.value,
    ...notesColumn.value,
    ...ruleColumn.value
  ]

  for (const column of columns) {
    const { field } = column
    if (!field) {
      continue
    }
    const value = userProfileDetails.value[field]
    inputs[field] = setInput(value, column)
    if (field === 'userName') {
      inputs[field].disabled = true
    }
  }
  calculatePasswordExpiryDays()
  passwordExpiryDate.value = Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }).format(new Date(userProfileDetails.value.passwordExpiresOn.replaceAll('-', '/')))
  form.value = { ...form.value, [rowKey]: inputs }
}

function calculatePasswordExpiryDays(): void {
  const MILL_TO_DAYS = 1000 * 60 * 60 * 24
  const expiryDate = userProfileDetails.value.passwordExpiresOn
  const todaysDate = new Date()
  const currMonth = todaysDate.getMonth() + 1
  const currDay = todaysDate.getDate()
  const currenYear = todaysDate.getFullYear()
  const currentDate = `${currenYear}-${currMonth}-${currDay}`

  passwordExpiresOn.value = Math.round(
    (new Date(expiryDate).getTime() - new Date(currentDate).getTime()) / MILL_TO_DAYS
  )
}

watch(
  () => formChanges.value,
  async (newValue) => {
    if (typeof newValue !== 'object') {
      return
    }
    let inDanger = false
    let inSuccess = false
    let inNoChange = false
    for (const key in newValue) {
      const rowEntries = newValue[key]
      const hasNoChange = rowEntries.some((entry: any) => entry?.[1]?.state === 'unchanged')
      const hasSuccess = rowEntries.some((entry: any) => entry?.[1]?.state === 'success')
      const hasDanger = rowEntries.some((entry: any) => entry?.[1]?.state === 'danger')
      let state = undefined
      state = hasNoChange ? 'unchanged' : state
      state = hasSuccess ? 'success' : state
      state = hasDanger ? 'danger' : state
      const patch = {
        state
      }
      const quirk = quirksMap.value.get(key)
      quirksMap.value.set(key, { ...quirk, ...patch })
      inNoChange = inNoChange || hasNoChange
      inSuccess = inSuccess || hasSuccess
      inDanger = inDanger || hasDanger
    }
    let state: StateType = undefined
    state = inNoChange ? 'unchanged' : state
    state = inSuccess ? 'success' : state
    state = inDanger ? 'danger' : state

    let testFailed = false
    const rowKeys = Object.keys(form.value)
    for (const rowKey of rowKeys) {
      const row = form.value[rowKey]
      for (const field in row) {
        const input = row[field]
        let testState, testErr

        if (!input?.tests?.length) {
          continue
        }
        ;[testState, testErr] = input.test()
        if (testState === 'danger' || testErr) {
          testFailed = true
          break
        }
      }
    }

    formState.value = testFailed ? 'danger' : state
    const patch = {
      state
    }
    const quirk = quirksMap.value.get('_all')
    quirksMap.value.set('_all', { ...quirk, ...patch })
    formChangesOnceMap.value.forEach((fn, key) => {
      try {
        if (typeof fn === 'function') {
          fn()
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
      formChangesOnceMap.value.delete(key)
    })
  }
)

function onInput(newValue: any, cell: any): void {
  const input = form.value[cell?.key]?.[cell?.field]
  if (!input) {
    return
  }
  if (cell.field === 'mobilePhone' || cell.field === 'telephone') {
    input.tests = []
    if (newValue && !newValue.includes('+1')) {
      input.tests = [validatePhone]
    } else {
      input.tests = [
        (input: InputType) => toMatchAPattern(input, /^[^0|1]/, 'phone'),
        validatePhone
      ]
    }
  }
  input.set(newValue)
  input.validate()
}

function nagivateToChangePassword() {
  router.push('/user-admin/change-password')
}
</script>

<template>
  <div>
    <div class="chi-card -no-border -mt--3">
      <div class="chi-card__header">
        <div
          class="-d--flex -align-items--baseline -mb--4 -justify-content--between -flex--fill -flex--wrap"
        >
          <div class="-text--h3 -text--boldest -text--navy -m--0 -pr--2 -text--900">My Profile</div>
          <div class="-d--flex -align-items--center">
            <i
              class="chi-icon icon-calendar-circle-clock -icon--primary -mr--2 -md"
              aria-hidden="true"
            ></i>
            <div
              class="-text--md -text -lh--3 -text--info -text--left -text--normal -mr--2 -d--flex -flex--column"
            >
              <span>Password Expires in: {{ passwordExpiresOn }} Days</span>
              <span>on {{ passwordExpiryDate }}</span>
            </div>
            <button class="chi-button --primary" @click="nagivateToChangePassword">
              CHANGE PASSWORD
            </button>
          </div>
        </div>
      </div>
      <div class="chi-card__content">
        <Cells
          :classColDefault="classColDefault"
          :columns="userDetailsCols"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        />

        <Cells
          :classColDefault="classColDefault"
          :columns="userAddressLineInfo"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        />
        <Cells
          :classColDefault="classColDefault"
          :columns="userCityStateDetails"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        />
        <Cells
          :classColDefault="classColDefault"
          :columns="userContactDetails"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        />

        <Cells
          :classColDefault="classColDefault"
          :columns="userRemainderDetailsCols"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        />
        <Cells
          :classColDefault="classColDefault"
          :columns="ruleColumn"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        />
        <Cells
          :classColDefault="classColDefault"
          :columns="notesColumn"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        />
      </div>

      <UserProfileSubmit />

      <div class="chi-card__content -mt--0 -pt--0">
        <div class="-text--h3">Task Orders</div>
        <UserTOPermissions />
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped></style>
