<script setup lang="ts">
import { Ref, computed } from 'vue'
import { onMounted, ref, watch, nextTick, defineEmits, onBeforeMount } from 'vue'
import { useTOAdminStore } from '../../../store/to-admin'
import moment from 'moment'
import { kpi } from '../../../models/to-admin/kpi'
import { useToAdminAPI } from '../../../composables/to-admin/api'
import { DatePicker } from '@centurylinkfederal/eis-vue'
import { toRaw } from 'vue'
export interface Props {
  show: boolean
  popupName?: string
  customKPIList?: any[]
  createCustomKPIList?: any[]
  serviceId?: string
  kpiGrp?: string
  serviceDesc?: string
  taskOrderId?: number
}

const props = withDefaults(defineProps<Props>(), {
  show: false,
  popupName: '',
  customKPIList: undefined,
  createCustomKPIList: () => [] as any[],
  serviceId: '',
  kpiGrp: '',
  serviceDesc: '',
  taskOrderId: 0
})
const popUpColumn = ref([
  { name: 'kpi', value: 'KPI', width: '15em' },
  { name: 'clin', value: 'CLIN', width: '12em' },
  { name: 'icbNumber', value: 'ICB Number', width: '12em' },
  { name: 'serviceLevel', value: 'Service Level', width: '12em' },
  {
    name: 'performanceStandardThreshold',
    value: 'Performance Standard (Threshold)',
    width: '12em'
  },
  { name: 'aqlOperator', value: 'AQL Operator', width: '12em' },
  { name: 'measurementUnit', value: 'Measurement Unit', width: '12em' },
  { name: 'locationQualifier', value: 'Location Qualifier', width: '12em' }
  // { name: 'effectiveStartDate', value: 'Effective Start Date', width: '12em' },
  // { name: 'effectiveEndDate', value: 'Effective End Date', width: '12em' }
])
const api = useToAdminAPI()
const IsCustomKpiValidData = ref(false)
const showData = ref(false)
const showModel = ref(false)
const isDataChanged = ref(true)
const measurementUnit: Ref<string[]> = ref([])
const kpiDesc: Ref<string[]> = ref([])
const aQLOperator: Ref<string[]> = ref([])
const aQLOperatorDescription: Ref<string[]> = ref([])
const locationQualifier: Ref<string[]> = ref([])
const locationQualifierDesc: Ref<string[]> = ref([])
const serviceLevelDesc: Ref<string[]> = ref([])
// let effectiveStartDateKey = ref<string>('')
// let effectiveEndDateKey = ref<string>('')
let kpis = ref<any[]>([])
const updatedKpis = ref<any[]>([])
const toAdminStore = useTOAdminStore()
const refKPIs = ref<any[]>([])
const allKPIsList = ref<any[]>([])
const isLoading = ref(false)
const customKpi = ref<kpi | null>(null)
const emit = defineEmits([
  'cancel-task-order-options',
  'addCustomKpisList',
  'save-task-order-options'
])
const isDuplicateKPI = ref(false)

// Watcher for `show`
watch(
  () => props.show,
  async (newValue: any) => {
    isDataChanged.value = true
    showData.value = true
    showModel.value = newValue
    IsCustomKpiValidData.value = false
    kpis.value = []
    if (props.popupName === 'taskOrderKPIPopup') {
      //getImportKPIData1()
    }
    if (newValue && (!kpis.value || kpis.value.length === 0)) {
      isLoading.value = true
      await toAdminStore
        .getRefKpis()
        .then(async (response: any) => {
          refKPIs.value = response
          if (props.popupName === 'editTaskOrderKPIPopup') {
            await toAdminStore
              .getAllKPIs(props.taskOrderId)
              .then((response: any) => {
                refKPIs.value = response
                if (props.createCustomKPIList.length > 0) {
                  prepareAllRequiredData()
                } else {
                  hasNewServiceIdThenGetRefKpis()
                }
                isLoading.value = false
              })
              .catch(() => {
                isLoading.value = false
              })
          } else {
            prepareAllRequiredData()
          }
        })
        .catch(() => {
          showData.value = false
        })
    } else if (newValue) {
      showData.value = true
      toAdminStore
        .getRefKpis()
        .then((response: any) => {
          refKPIs.value = response
          showData.value = false
        })
        .catch(() => {
          showData.value = false
        })
    }
  },
  { immediate: true }
)

function prepareAllRequiredData() {
  isLoading.value = true
  kpiDataMapping()
  customKPIListData()
  if (props.popupName === 'taskOrderKPIPopup') {
    //getImportKPIData2();
  }
  showData.value = false
  isLoading.value = false
}
async function hasNewServiceIdThenGetRefKpis() {
  if (!refKPIs.value || refKPIs.value.length != 0) {
    const newSvcIdFound = refKPIs.value.find((kpi) => kpi.svcId === props.serviceId)
    if (!newSvcIdFound) {
      await toAdminStore
        .getRefKpis()
        .then((response: any) => {
          refKPIs.value = response.data
          prepareAllRequiredData()
        })
        .catch(() => {
          showData.value = false
        })
    } else {
      prepareAllRequiredData()
    }
  } else {
    showData.value = false
  }
}

function kpiDataMapping() {
  const refKpisTemp = refKPIs.value.filter(
    (refkpi) =>
      refkpi.svcId !== undefined &&
      props.serviceId !== undefined &&
      refkpi.svcId.trim() === props.serviceId.trim() &&
      refkpi.kpiGrpType !== undefined &&
      props.kpiGrp !== undefined &&
      refkpi.kpiGrpType.trim() === props.kpiGrp.trim()
  )
  kpis.value.map((kpi: any) => {
    kpi.kpiMeas = kpi.kpiMeas.trim()
    kpi.effectiveStartDate = formatDateAndTime(kpi.effectiveStartDate)
    kpi.effectiveEndDate = formatDateAndTime(kpi.effectiveEndDate)
    if (kpi.clin === null) {
      kpi.clin = ''
    }
    // if (kpi.customPrefStdThresHold === "" || kpi.customPrefStdThresHold === undefined || kpi.customPrefStdThresHold === null) {
    //   kpi.customPrefStdThresHold = kpi.perfStdThreshold;
    // }
  })
  kpis.value = JSON.parse(JSON.stringify(refKpisTemp))
  sortKpisBasedOnCriteria()
}

function customKPIListData() {
  if (props.createCustomKPIList.length > 0) {
    kpis.value.forEach((kpi: any, index: any) => {
      const customKpi = props.createCustomKPIList.find(
        (customKpi: any) =>
          customKpi.kpiId === kpi.kpiId &&
          customKpi.svcId === props.serviceId &&
          customKpi.kpiGrpType === props.kpiGrp
      )
      if (customKpi) {
        customKpi.effectiveStartDate = formatDateAndTime(customKpi.effectiveStartDate)
        customKpi.effectiveEndDate = formatDateAndTime(customKpi.effectiveEndDate)
        kpis.value[index] = JSON.parse(JSON.stringify(customKpi))
      }
    })
    props.createCustomKPIList.forEach((customKPI: any) => {
      if (
        customKPI.kpiType === 'New Custom KPI' &&
        customKPI.svcId === props.serviceId &&
        customKPI.kpiGrpType === props.kpiGrp
      ) {
        customKPI.effectiveStartDate = formatDateAndTime(customKPI.effectiveStartDate)
        customKPI.effectiveEndDate = formatDateAndTime(customKPI.effectiveEndDate)
        kpis.value.push(customKPI)
      }
    })
    sortKpisBasedOnCriteria()
  }
}
onBeforeMount(async () => {
  const codes = { transactionCode: ['KPIAO', 'KPIMU', 'KPILQ', 'KPISLQ'] }
  api.getCodes(codes).then((response: any) => {
    measurementUnit.value = Object.keys(response.KPIMU)
    aQLOperator.value = Object.values(response.KPIAO)
    aQLOperatorDescription.value = Object.keys(response.KPIAO)
    locationQualifier.value = Object.values(response.KPILQ)
    locationQualifierDesc.value = Object.keys(response.KPILQ)
    serviceLevelDesc.value = Object.values(response.KPISLQ)
  })
  api.getKpiUcCodes(props.kpiGrp).then((response: any) => {
    kpiDesc.value = response.results
  })
})

onMounted(async () => {
  ;(window as any).chi.modal(document.getElementById('modal-trigger-3'))
  setTimeout(() => {
    ;((document.querySelectorAll('#modal-trigger-3')[0] as any) || { click: () => {} }).click()
  }, 100)
})

const isValidData = (data: any, kpi: any) => {
  const hasValid = IsNewCustomKpi(kpi) && hasValidForm(data)
  if (hasValid && hasValidForm(kpi.customPrefStdThresHold)) {
    IsCustomKpiValidData.value = true
  } else if (!hasValid && IsNewCustomKpi(kpi)) {
    IsCustomKpiValidData.value = false
  }
  return hasValid
}
const hasValidForm = (data: any) => {
  return data === null || data === ''
}
async function captureValue(type: string, value: any, kpi: any) {
  isDataChanged.value = false
  duplicateKpi()
  switch (type) {
    case 'kpiMeas':
      if (!findUpdateKpisData(kpi)) {
        const index = findMatchedUpdatedKpi(kpi)
        updatedKpis.value[index].kpiMeas = kpi.kpiMeas
      } else {
        updatedKpis.value.push(kpi)
      }
      break
    case 'clin':
      if (!findUpdateKpisData(kpi)) {
        const index = findMatchedUpdatedKpi(kpi)
        updatedKpis.value[index].clin = kpi.clin
      } else {
        updatedKpis.value.push(kpi)
        duplicateKpi()
      }
      break
    case 'icbNumber':
      if (!findUpdateKpisData(kpi)) {
        const index = findMatchedUpdatedKpi(kpi)
        updatedKpis.value[index].icbNumber = kpi.icbNumber
      } else {
        updatedKpis.value.push(kpi)
        duplicateKpi()
      }
      break
    case 'kpilqSvcLvlQDesc':
      if (!findUpdateKpisData(kpi)) {
        const index = findMatchedUpdatedKpi(kpi)
        updatedKpis.value[index].kpilqSvcLvlQDesc = kpi.kpilqSvcLvlQDesc
        updatedKpis.value[index].kpilqSvcLvlQCode = kpi.kpilqSvcLvlQDesc
      } else {
        updatedKpis.value.push(kpi)
      }
      break
    case 'customPrefStdThresHold':
      IsCustomKpiValidData.value = false
      isValidData(kpi.customPrefStdThresHold, kpi)
      if (!findUpdateKpisData(kpi)) {
        const index = findMatchedUpdatedKpi(kpi)
        updatedKpis.value[index].customPrefStdThresHold = kpi.customPrefStdThresHold
      } else {
        updatedKpis.value.push(kpi)
      }
      break
    case 'perfStdThreshold':
      IsCustomKpiValidData.value = false
      isValidData(kpi.perfStdThreshold, kpi)
      if (!findUpdateKpisData(kpi)) {
        const index = findMatchedUpdatedKpi(kpi)
        updatedKpis.value[index].perfStdThreshold = kpi.perfStdThreshold
      } else {
        updatedKpis.value.push(kpi)
      }
      break
    case 'kpiaoAqlQOprDesc': {
      const descIndex = aQLOperator.value.findIndex((op: string) => op.includes(value))
      if (!findUpdateKpisData(kpi)) {
        const index = findMatchedUpdatedKpi(kpi)
        updatedKpis.value[index].kpiaoAqlQOprCode = descIndex + 1
        updatedKpis.value[index].kpiaoAqlQOprDesc = aQLOperator.value[descIndex]
        duplicateKpi()
      } else {
        kpi.kpiaoAqlQOprDesc = aQLOperator.value[descIndex]
        kpi.kpiaoAqlQOprCode = aQLOperator.value.findIndex((op: string) => op.includes(value)) + 1
        updatedKpis.value.push(kpi)
        duplicateKpi()
      }
      break
    }
    case 'kpimuMeasUnitCode':
      if (!findUpdateKpisData(kpi)) {
        const index = findMatchedUpdatedKpi(kpi)
        updatedKpis.value[index].kpimuMeasUnitDesc = kpi.kpimuMeasUnitCode
        updatedKpis.value[index].kpimuMeasUnitCode = kpi.kpimuMeasUnitCode
      } else {
        kpi.kpimuMeasUnitDesc = kpi.kpimuMeasUnitCode
        updatedKpis.value.push(kpi)
      }
      break
    case 'kpilqLocQDesc': {
      const locQDescIndex = locationQualifier.value.findIndex((op: string) => op.includes(value))
      if (!findUpdateKpisData(kpi)) {
        const index = findMatchedUpdatedKpi(kpi)
        updatedKpis.value[index].kpilqLocQDesc = kpi.kpilqLocQDesc
        updatedKpis.value[index].kpilqLocQCode = locationQualifierDesc.value[locQDescIndex]
      } else {
        kpi.kpilqLocQCode = locationQualifierDesc.value[locQDescIndex]
        updatedKpis.value.push(kpi)
      }
      break
    }
    // case 'effectiveStartDate':
    //   kpi.effectiveStartDate = value
    //   if (!findUpdateKpisData(kpi)) {
    //     const index = findMatchedUpdatedKpi(kpi)
    //     const formattedDate = formatDateAndTime(kpi.effectiveStartDate)
    //     updatedKpis.value[index].effectiveStartDate = formattedDate
    //     kpi.effectiveStartDate = formattedDate
    //     await nextTick()
    //     forceUpdateElement(type, value)
    //   } else {
    //     updatedKpis.value.push(kpi)
    //   }
    //   break
    case 'effectiveEndDate':
      kpi.effectiveEndDate = value
      if (!findUpdateKpisData(kpi)) {
        const index = findMatchedUpdatedKpi(kpi)
        const formattedDate = formatDateAndTime(kpi.effectiveEndDate)
        updatedKpis.value[index].effectiveEndDate = formattedDate
        kpi.effectiveEndDate = formattedDate
      } else {
        updatedKpis.value.push(kpi)
      }
      break
  }
}
// function forceUpdateElement(type: any, value: any) {
//   if (type === 'effectiveStartDate') {
//     effectiveStartDateKey.value = `${type}-${value}-${Date.now()}`
//   } else if (type === 'effectiveEndDate') {
//     effectiveEndDateKey.value = `${type}-${value}-${Date.now()}`
//   }
// }
function findUpdateKpisData(kpi: any): boolean {
  if (updatedKpis.value.length > 0) {
    const matchedKpi = updatedKpis.value.find((updatekpi) => updatekpi.kpiId === kpi.kpiId)
    return !matchedKpi
  }
  return true
}
function findMatchedUpdatedKpi(kpi: any): number {
  let findIndex = -1
  updatedKpis.value.forEach((updatekpi, index) => {
    if (updatekpi.kpiId === kpi.kpiId) {
      findIndex = index
    }
  })
  return findIndex
}
function cancelOptions() {
  checkSaveButtonEventBeforeCancel()
  showModel.value = false
  emit('cancel-task-order-options')
}
function checkSaveButtonEventBeforeCancel() {
  const customKPIList = props.customKPIList || []
  if (kpis.value && customKPIList.length > 0) {
    kpis.value.forEach((element, index) => {
      const notSavedKpi = props.customKPIList?.find(
        (customKpi) => customKpi.kpiId === element.kpiId
      )
      if (notSavedKpi) {
        kpis.value[index] = JSON.parse(JSON.stringify(notSavedKpi))
      } else {
        if (IsNewCustomKpi(element)) {
          kpis.value.splice(index, 1)
        }
      }
    })
  }
}
function formatDateAndTime(dateString: string): string {
  return dateString ? moment(String(dateString)).format('MM/DD/YYYY') : ''
}
function deleteKpi(kpi: any, index: number) {
  if (kpi.kpiType === 'New Custom KPI') {
    kpis.value.splice(index, 1)
    duplicateKpi()
    let removeKpiIndex = -1
    updatedKpis.value.find((updatekpi: any, index: any) => {
      if (updatekpi.kpiId === kpi.kpiId) {
        removeKpiIndex = index
      }
    })
    if (removeKpiIndex >= 0) {
      updatedKpis.value.splice(removeKpiIndex, 1)
      duplicateKpi()
    }
    toAdminStore.deleteKpiFromModal = kpi
  } else {
    kpis.value[index].effectiveEndDate = formatDateAndTime(new Date().toISOString())
    captureValue('effectiveEndDate', kpis.value[index].effectiveEndDate, kpi)
  }
}
function IsNewCustomKpi(kpi: any): boolean {
  return kpi.kpiType === 'New Custom KPI'
}
const refactorKpiGrpString = computed(() => {
  // Check if props.kpiGrp is a string and not undefined
  if (typeof props.kpiGrp === 'string') {
    return props.kpiGrp.replace(/\s/g, '-').toLowerCase()
  }
  // Return a default value or handle the undefined case as needed
  return ''
})

const constructKpiGrpType = computed(() => {
  let result = ''
  switch (props.kpiGrp) {
    case 'PERF - Product Specific Performance':
      result = 'Performance'
      break
    case 'OUT - Service Outage':
      result = 'Outage'
      break
    case 'PROV - Provisioning SLA':
      result = 'Provisioning'
      break
  }

  return result
})
function addCustomKPI() {
  customKpi.value = {
    kpiId: `${refactorKpiGrpString.value}_tempId_${kpis.value.length}`,
    kpiType: 'New Custom KPI',
    kpiGrpType: props.kpiGrp,
    svcId: props.serviceId,
    svcDesc: props.serviceDesc,
    icbNumber: '',
    kpiucUnitCode: '',
    kpiucUnitCodeDesc: '',
    kpiMeas: kpiDesc.value[0],
    kpilqLocQCode: locationQualifierDesc.value[0],
    kpilqLocQDesc: locationQualifier.value[0],
    kpilqSvcLvlQCode: serviceLevelDesc.value[0],
    kpilqSvcLvlQDesc: serviceLevelDesc.value[0],
    kpimuMeasUnitCode: measurementUnit.value[0],
    kpimuMeasUnitDesc: measurementUnit.value[0],
    perfStdThreshold: '',
    kpiaoAqlQOprCode: aQLOperator.value[0],
    kpiaoAqlQOprDesc: aQLOperatorDescription.value[0],
    stdCustomIndicator: 'Custom KPI',
    additionalInfo: '',
    effectiveStartDate: formatDateAndTime(new Date().toISOString()),
    effectiveEndDate: '',
    taskOrderMetaData: '',
    taskOrderNumber: '',
    svcClinPrefix: '',
    clin: '',
    taskOrderSvcId: '',
    customPrefStdThresHold: '',
    customKpiaoAqlQOprCode: '',
    customKpimuMeasUnitCode: '',
    customKpilqLocQCode: '',
    customEffectiveStartDate: '',
    customEffectiveEndDate: '',
    tempKpiId: ''
  }
  IsCustomKpiValidData.value = true
  kpis.value.push(customKpi.value)
  duplicateKpi()
  isDataChanged.value = true
}
function sortKpisBasedOnCriteria() {
  if (kpis.value && kpis.value.length > 0) {
    kpis.value = JSON.parse(
      JSON.stringify(
        kpis.value
          .filter((kpi) => kpi.stdCustomIndicator === 'Standard KPI')
          .sort((a: any, b: any) => a.kpiMeas.localeCompare(b.kpiMeas))
          .concat(
            kpis.value
              .filter((kpi) => kpi.stdCustomIndicator === 'Custom KPI')
              .sort((a: any, b: any) => a.clin.localeCompare(b.clin))
              .sort((a: any, b: any) => a.kpiMeas.localeCompare(b.kpiMeas))
          )
      )
    )
  }
}
function save() {
  if (!IsCustomKpiValidData.value) {
    updatedKpis.value.map((updatedkpi: any) => {
      if (updatedkpi.kpiId !== null) {
        kpis.value.forEach((kpi, index) => {
          if (updatedkpi.kpiId === kpi.kpiId) {
            kpis.value[index].stdCustomIndicator = 'Custom KPI'
          }
        })
      }
    })
    if (props.popupName === 'taskOrderKPIPopup') {
      updatedKpis.value = []
    }
    emit('cancel-task-order-options')
    emit('addCustomKpisList', JSON.parse(JSON.stringify(kpis)))
    if (props.popupName === 'taskOrderKPIPopup') {
    } else {
      emit('save-task-order-options', JSON.parse(JSON.stringify(updatedKpis)))
    }
  }
}
const decimalRegex = (evt: any) => {
  const regex = /^\d*\.?\d{2}$/
  const val = evt.key
  const value = `${evt.target.value}${val}`
  const arr = value.split('.')
  let length = arr.length > 1 ? arr[1].length : 1
  let isDecimal = length > 3
  var charCode = evt.which ? evt.which : evt.keyCode
  if ((charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) || isDecimal) {
    evt.preventDefault()
  }
}

function duplicateKpi() {
  isDataChanged.value = false
  const unique: any[] = []
  for (let o of kpis.value) {
    if (
      unique.find(
        (i) =>
          i.kpiMeas === o.kpiMeas &&
          i.kpilqSvcLvlQDesc === o.kpilqSvcLvlQDesc &&
          i.customPrefStdThresHold === o.customPrefStdThresHold &&
          i.kpiaoAqlQOprDesc === o.kpiaoAqlQOprDesc &&
          i.kpimuMeasUnitCode === o.kpimuMeasUnitCode &&
          i.kpilqLocQDesc === o.kpilqLocQDesc &&
          (i.clin === o.clin ||
            (i.clin === '' && o.clin === null) ||
            (i.clin === null && o.clin === '')) &&
          (i.icbNumber === o.icbNumber ||
            (i.icbNumber === '' && o.icbNumber === null) ||
            (i.icbNumber === null && o.icbNumber === '')) &&
          i.effectiveStartDate === o.effectiveStartDate
      )
    ) {
      isDuplicateKPI.value = true
      break
    }
    isDuplicateKPI.value = false
    unique.push(o)
  }
}
</script>
<template>
  <button id="modal-trigger-3" class="chi-button chi-modal__trigger" data-target="#modal-3">
    Custom
  </button>
  <div v-if="show">
    <div class="chi-backdrop -center -closed">
      <div class="chi-backdrop__wrapper">
        <section
          id="modal-3"
          class="chi-modal custom-modal-width"
          role="dialog"
          aria-label="Modal description"
          aria-modal="true"
        >
          <header class="chi-modal__header">
            <h2 class="chi-modal__title">
              Task Order Custom KPIs for {{ serviceId }} - {{ constructKpiGrpType }}
            </h2>
            <button
              class="chi-button -icon -close"
              data-dismiss="modal"
              aria-label="Close"
              @click="cancelOptions()"
            >
              <div class="chi-button__content">
                <i class="chi-icon icon-x" aria-hidden="true"></i>
              </div>
            </button>
          </header>
          <div class="chi-modal__content">
            <div class="chi-container-fluid -mt--2 -mb--2 -px--0">
              <div class="chi-data-table -portal -compact" style="display: block !important">
                <div class="table-container">
                  <div class="chi-data-table__head">
                    <div class="chi-data-table__row">
                      <div
                        v-for="header in popUpColumn"
                        :key="header.name"
                        class="chi-data-table__cell -cell-wrap -text--sm"
                        :style="{ 'min-width': header.width }"
                      >
                        {{ header.value }}
                      </div>
                    </div>
                  </div>

                  <div class="chi-data-table__body -d--inline">
                    <chi-spinner v-if="isLoading" color="primary" backdrop="inverse"></chi-spinner>
                    <div
                      v-for="(torder, index) in kpis"
                      :key="index"
                      class="chi-data-table__row"
                      :class="{
                        '-striped': index % 2 === 1
                      }"
                    >
                      <div
                        class="chi-data-table__cell -text--sm"
                        style="min-width: 15em !important"
                      >
                        <span
                          v-if="torder.stdCustomIndicator === 'Custom KPI' || torder.kpiId == null"
                          class="custom-threshold-info"
                          style="padding-right: 2px"
                          >*</span
                        >
                        <span
                          v-if="
                            !IsNewCustomKpi(torder) && !(torder.stdCustomIndicator === 'Custom KPI')
                          "
                          >{{ torder.kpiMeas }}
                        </span>
                        <div
                          v-if="
                            IsNewCustomKpi(torder) ||
                            (torder && torder.stdCustomIndicator === 'Custom KPI')
                          "
                          class="chi-dropdown -fluid"
                        >
                          <select
                            class="chi-input"
                            v-model="torder.kpiMeas"
                            @change="
                              captureValue(
                                'kpiMeas',
                                ($event.target as HTMLInputElement).value,
                                torder
                              )
                            "
                          >
                            <option disabled value="">- Select One -</option>
                            <option
                              v-for="(kd, index) in kpiDesc"
                              :key="index"
                              :selected="kd === torder.kpiMeas"
                            >
                              {{ kd }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div
                        class="chi-data-table__cell -text--sm"
                        style="min-width: 12em !important"
                      >
                        <span v-if="!IsNewCustomKpi(torder)">{{ torder.clin }}</span>
                        <input
                          v-if="IsNewCustomKpi(torder)"
                          :id="`clin-${index}`"
                          v-model="torder.clin"
                          class="chi-input"
                          type="text"
                          @change="captureValue('clin', null, torder)"
                          @keyup="duplicateKpi()"
                        />
                      </div>
                      <div
                        class="chi-data-table__cell -text--sm"
                        style="min-width: 12em !important"
                      >
                        <span v-if="!IsNewCustomKpi(torder)">{{ torder.icbNumber }}</span>
                        <input
                          v-if="IsNewCustomKpi(torder)"
                          :id="`icbNumber-${index}`"
                          v-model="torder.icbNumber"
                          class="chi-input"
                          type="text"
                          @change="captureValue('icbNumber', null, torder)"
                          @keyup="duplicateKpi()"
                        />
                      </div>
                      <div
                        class="chi-data-table__cell -text--sm"
                        style="min-width: 12em !important"
                      >
                        <span v-if="!IsNewCustomKpi(torder)">{{ torder.kpilqSvcLvlQDesc }}</span>
                        <div v-if="IsNewCustomKpi(torder)" class="chi-dropdown -fluid">
                          <select
                            v-model="torder.kpilqSvcLvlQDesc"
                            class="chi-input"
                            @change="
                              captureValue(
                                'kpiMeas',
                                ($event.target as HTMLInputElement).value,
                                torder
                              )
                            "
                          >
                            <option disabled value="">- Select One -</option>
                            <option
                              v-for="(sl, index) in serviceLevelDesc"
                              :key="index"
                              :selected="sl === torder.kpilqSvcLvlQDesc"
                            >
                              {{ sl }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div
                        class="chi-data-table__cell -text--sm"
                        style="min-width: 12em !important"
                      >
                        <input
                          :id="`threshold-${index}`"
                          v-model="torder.perfStdThreshold"
                          required
                          class="chi-input"
                          :class="[
                            'a-input',
                            IsNewCustomKpi(torder) && isValidData(torder.perfStdThreshold, torder)
                              ? '-danger'
                              : '',
                            'taskOrder-field-h'
                          ]"
                          style="width: 65px"
                          type="text"
                          @keypress="decimalRegex"
                          @keyup="duplicateKpi()"
                          @change="
                            captureValue(
                              'perfStdThreshold',
                              ($event.target as HTMLInputElement).value,
                              torder
                            )
                          "
                        />
                      </div>
                      <div
                        class="chi-data-table__cell -text--sm"
                        style="min-width: 12em !important"
                      >
                        <div class="chi-dropdown -fluid">
                          <select
                            class="chi-input"
                            @change="
                              captureValue(
                                'kpiaoAqlQOprDesc',
                                ($event.target as HTMLInputElement).value,
                                torder
                              )
                            "
                          >
                            <option
                              v-for="(op, index) in aQLOperator"
                              :key="index"
                              :value="op"
                              :selected="op === torder.kpiaoAqlQOprDesc"
                            >
                              {{ op }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div
                        class="chi-data-table__cell -text--sm"
                        style="min-width: 12em !important"
                      >
                        <div class="chi-dropdown -fluid">
                          <select
                            v-model="torder.kpimuMeasUnitCode"
                            class="chi-input"
                            @change="
                              captureValue(
                                'kpimuMeasUnitCode',
                                ($event.target as HTMLInputElement).value,
                                torder
                              )
                            "
                          >
                            <option
                              v-for="mu in measurementUnit"
                              :key="mu"
                              :selected="mu === torder.kpimuMeasUnitCode"
                            >
                              {{ mu }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div
                        class="chi-data-table__cell -text--sm"
                        style="min-width: 12em !important"
                      >
                        <div class="chi-dropdown -fluid">
                          <select
                            v-model="torder.kpilqLocQDesc"
                            class="chi-input"
                            @change="
                              captureValue(
                                'kpilqLocQDesc',
                                ($event.target as HTMLInputElement).value,
                                torder
                              )
                            "
                          >
                            <option
                              v-for="lq in locationQualifier"
                              :key="lq"
                              :selected="lq === torder.kpilqLocQDesc"
                            >
                              {{ lq }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!-- <div
                        class="chi-data-table__cell -text--sm"
                        style="overflow: visible !important; min-width: 12em !important"
                      >
                        <DatePicker
                          :id="`start-date-${index}`"
                          :key="effectiveStartDateKey"
                          :value="formatDateAndTime(torder.effectiveStartDate)"
                          label=""
                          style="display: inline-block; position: relative"
                          format="MM/DD/YYYY"
                          @change="captureValue('effectiveStartDate', $event, torder)"
                        ></DatePicker>
                      </div> -->
                      <!-- <div
                        class="chi-data-table__cell -text--sm"
                        style="overflow: visible !important; min-width: 12em !important"
                      >
                        <DatePicker
                          :id="`end-date-${index}`"
                          :key="effectiveEndDateKey"
                          :value="formatDateAndTime(torder.effectiveEndDate)"
                          label=""
                          format="MM/DD/YYYY"
                          style="display: inline-block; position: relative"
                          @change="captureValue('effectiveEndDate', $event, torder)"
                        ></DatePicker>
                      </div> -->
                      <div class="chi-data-table__cell -text--sm">
                        <button
                          class="chi-button -primary"
                          :disabled="
                            !IsNewCustomKpi(torder) &&
                            torder.effectiveEndDate !== null &&
                            torder.effectiveEndDate !== ''
                          "
                          @click="deleteKpi(torder, index)"
                        >
                          {{ IsNewCustomKpi(torder) ? 'Delete' : 'Waive' }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-show="!showData && kpis && kpis.length === 0" class="-text--center">
                    <div class="-text--bold">No KPIs found!!</div>
                  </div>
                </div>
              </div>
              <div style="font-size: 12.7px; margin-top: 10px">
                <span class="custom-threshold-info">*</span> Custom KPI
              </div>
              <div v-if="isDuplicateKPI" class="err-msg">
                <span class="custom-threshold-info"
                  >Duplicate KPI's are not allowed. At least one field should be unique!</span
                >
              </div>
            </div>
          </div>
          <footer class="chi-modal__footer -justify-content--center">
            <button
              class="chi-button -primary"
              data-dismiss="modal"
              :disabled="isDuplicateKPI || isDataChanged"
              @click="save()"
            >
              Apply
            </button>
            <button class="chi-button -primary" @click="addCustomKPI">Add Custom KPI</button>
            <button class="chi-button" data-dismiss="modal" @click="cancelOptions()">Cancel</button>
          </footer>
        </section>
      </div>
    </div>
  </div>
</template>
<style scoped>
.chi-data-table__cell {
  white-space: normal !important;
  word-wrap: break-word !important;
  word-break: break-all;
  overflow-wrap: break-word;
}

.chi-data-table__cell,
.chi-data-table__row {
  width: fit-content !important;
}

.-cell-wrap {
  word-wrap: break-word;
}

.table-container {
  overflow-x: auto;
}

.custom-modal-width {
  min-width: 65em !important;
}

#modal-trigger-3 {
  visibility: hidden;
}

.custom-threshold-info {
  color: #ff0000;
}
</style>
