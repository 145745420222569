<script lang="ts" setup>
import { onMounted, computed, ref, watch, toRaw } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useStore } from './store'
import { useColumns as useDetailsColumnsReadOnly } from './detailsColumnsReadOnly'
import Details from './Details.vue'
import EmptyData from '../common/EmptyData.vue'
import { doScrollToElement } from '../../utils/ui'
import { Alert, slice, ifParse } from '@centurylinkfederal/eis-vue'
import { alertTable } from '../../composables/alerts'

const route = useRoute()
const store = useStore()
const { alertMap, alertEntries, modalMap, modalEntries, signal } = storeToRefs(store)

const taskOrderId = computed(() => String(route.query.taskOrderId ?? '') || undefined)
const agencyIdentifierEncoded = computed(
  () => String(route.query.agencyIdentifierEncoded ?? '') || undefined
)
function doAlert(newAlert: any): void {
  const { title, message, state } = ifParse(newAlert)
  if (!title && !message && !state) {
    return
  }
  const alertBubble = alertTable[state] ?? alertTable.muted
  alertMap.value.set('alert', {
    ...alertBubble,
    title,
    message
  })
}

onMounted(() => {
  if (route.query.alert) {
    doAlert(ifParse(route.query.alert))
  }
})
</script>

<template>
  <div v-if="alertEntries.length" class="alerts -my--2">
    <Alert
      v-for="[alertKey, alert] in alertEntries"
      :key="alertKey"
      v-bind="alert"
      @onDismissAlert="alertMap.delete(alertKey)"
    />
  </div>
  <div class="chi-card">
    <div class="chi-card__content -pb--1">
      <Details v-bind="route.query">
        <template v-slot:empty>
          <div class="chi-data-table__row-empty" style="min-height: 160px">
            <EmptyData message="No records found" icon="file-error-outline" />
          </div>
        </template>
      </Details>
    </div>
  </div>
</template>
<style>
.-cursor--default {
  cursor: default;
}
.-cursor--progress {
  cursor: progress;
}
.-user-select--none {
  user-select: none;
}
</style>
<style scoped>
a:hover.-cursor--default {
  cursor: default;
}
a:hover.-text--no-decoration {
  text-decoration: none;
}
</style>
