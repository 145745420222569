<script lang="ts" setup>
import { ref, computed, watch, onMounted, toRaw } from 'vue'
import { storeToRefs } from 'pinia'
import { getStore } from './store'
import { useColumns } from './detailsColumns'
import { useStore as useModsStore } from './modsStore'
import { useStore as useContactsStore } from './contactsStore'
import { useToAdminAPI } from '../../composables/to-admin/api'
import Cells from '../common/Cells.vue'
import {
  Row,
  Input,
  TextAreaCell,
  fromMMDDYYYY,
  toMMDDYYYY,
  isEqual
} from '@centurylinkfederal/eis-vue'
import { toEntries } from '../../utils/helpers'
export interface Props {
  pageMode: 'create' | 'edit'
  query?: any
}
const props = withDefaults(defineProps<Props>(), {
  pageMode: 'create',
  query: {}
})
const pageMode = ref(props.pageMode)
const pageStore = getStore(pageMode.value)()
const adminApi = useToAdminAPI()
const {
  resetSeq,
  loadSeq,
  form,
  formState,
  formChanges,
  billingNotifications,
  taskOrderDetailsRes
} = storeToRefs(pageStore)
const modsStore = useModsStore()
const { rows: modsRows } = storeToRefs(modsStore)
const contactsStore = useContactsStore()
const { form: contactsForm } = storeToRefs(contactsStore)
const columnsStore = useColumns()
const { updateTaskOrderEntries } = columnsStore
const {
  columns,
  columnsEdit,
  taskOrderEntries,
  taskOrderFirstOption,
  taskOrderMap,
  svcDeliveryDefault
} = storeToRefs(columnsStore)
const settings = {
  key: '_panel'
}
const row = ref<Row>({
  _panel: 'details'
})
const svcDeliveryTouched = ref(false)

const cells = computed(() => Object.fromEntries(toEntries(columns.value, 'field')))
const columnsByMode = computed(() =>
  Object({
    create: columns.value,
    edit: columnsEdit.value
  })
)
const computedColumns = computed(() => columnsByMode.value[pageMode.value])
const modsByIterationId = computed(() =>
  Object.fromEntries(modsRows.value.map((e) => [e.taskOrderModIterationId, e]))
)

watch(
  () => modsByIterationId.value,
  (newValues) => {
    const inputs = form.value[row.value._panel] ?? {}
    const modNumberInput = inputs.inputs
    if (!modNumberInput) {
      return
    }
    const modObj = newValues[row.value.taskOrderModIterationId]
    modNumberInput.set(modObj?.modNumber)
  }
)
watch(
  () => resetSeq.value,
  async () => await resetForm()
)
watch(
  () => props.query,
  async () => await resetForm(),
  { deep: true }
)

function parseTaskOrderRes(res: any): any {
  const { taskOrderDto } = res
  const taskOrderObj = taskOrderMap.value.get(taskOrderDto.taskOrderNumber)
  const level3ban = taskOrderObj?.level3ban
  const detailsRow = {
    ...taskOrderDto,
    _panel: 'details',
    agencyId: res.agencyId,
    level3ban,
    statusManaged: String(taskOrderDto.statusType).split(','),
    invoiceOptions: [
      ['ioCustom', taskOrderDto.ioCustom],
      ['ioDoi', taskOrderDto.ioDoi],
      ['etems', taskOrderDto.etems],
      ['ioIppDetail', taskOrderDto.ioIppDetail],
      ['ioIppSummary', taskOrderDto.ioIppSummary]
    ]
      .filter(([_key, value]) => value === 'Y')
      .map(([key, _value]) => key),
    billingNotifications: taskOrderDto.billingContacts?.length ? 'Y' : 'N',
    strStartDate: taskOrderDto.startDate,
    strEndDate: taskOrderDto.stopDate,
    svcDelivery: {
      svcDeliveryNumDays: taskOrderDto.svcDeliveryNumDays
        ? String(parseInt(taskOrderDto.svcDeliveryNumDays))
        : undefined,
      svcDeliveryDaysType: taskOrderDto.svcDeliveryDaysType
    }
  }
  if (
    String(detailsRow.svcDelivery?.svcDeliveryNumDays) !==
    String(svcDeliveryDefault.value.svcDeliveryNumDays)
  ) {
    svcDeliveryTouched.value = true
  }
  return detailsRow
}
async function resetForm(): Promise<void> {
  const key = row.value._panel
  svcDeliveryTouched.value = false
  if (pageMode.value === 'edit') {
    if (props.query.agencyIdentifierEncoded && props.query.taskOrderId) {
      const res = await adminApi.fetchTaskOrderDetails(
        props.query.agencyIdentifierEncoded,
        props.query.taskOrderId
      )
      taskOrderDetailsRes.value = res
      await updateTaskOrderEntries(res.agencyId, pageMode.value)
      loadSeq.value++
      row.value = parseTaskOrderRes(res)
    }
  } else {
    row.value = {
      _panel: 'details'
    }
    form.value[key] = {}
  }
  let inputs = form.value[key] ?? {}
  for (const cell of columns.value) {
    const { label, field, input } = cell
    let value = row.value[field] ?? input?.value
    let disabled = input?.disabled
    let required = input?.required
    let tests = input?.tests
    if (['agencyId', 'taskOrderNumber'].includes(field) && pageMode.value === 'edit') {
      disabled = true
    }
    if (['modNumber'].includes(field)) {
      value = modsByIterationId.value[row.value.taskOrderModIterationId]?.modNumber
    }
    inputs[field] = new Input({ ...input, label, field, value, disabled, required, tests })
    if (Array.isArray(cell.group)) {
      cell.group.forEach((subCell: any) => {
        inputs[subCell.field] = new Input({ ...subCell.input })
      })
    }
  }
  billingNotifications.value = inputs.billingNotifications?.value
  form.value = { ...form.value, [key]: inputs }
}
async function onInput(newValue: any, payload: any): Promise<void> {
  if (!payload) {
    return
  }
  const { key, field, group, defaultValue } = payload
  const inputs = form.value[key]
  const input = inputs?.[field]
  if (!input) {
    return
  }
  if (group && typeof newValue === 'object' && typeof input.value === 'object') {
    newValue = { ...input.value, ...newValue }
  }
  input.set(newValue)
  input.validate()

  switch (field) {
    case 'agencyId': {
      await updateTaskOrderEntries(newValue)
      if (!taskOrderEntries.value?.length) {
        taskOrderFirstOption.value = ['', '- None Available -']
      }
      inputs.taskOrderNumber.set('')
      inputs.taskOrderNumber.validate('')

      break
    }
    case 'taskOrderNumber': {
      const taskOrderObj = taskOrderMap.value.get(newValue)
      inputs.level3ban.set(taskOrderObj?.level3ban)
      break
    }
    case 'invoiceOptions': {
      if ((newValue ?? []).includes('ioIppSummary')) {
        inputs.invoiceOptions.disabledOptions = ['ioIppDetail']
      }
      if ((newValue ?? []).includes('ioIppDetail')) {
        inputs.invoiceOptions.disabledOptions = ['ioIppSummary']
      }
      if (!(newValue ?? []).includes('ioIppSummary') && !(newValue ?? []).includes('ioIppDetail')) {
        inputs.invoiceOptions.disabledOptions = []
      }
      break
    }
    case 'billingNotifications': {
      billingNotifications.value = newValue
      if (newValue === 'N') {
        if (Object.keys(contactsForm.value).length > 0) {
          inputs.billingNotifications.state = 'warning'
        }
      } else {
        if (pageMode.value === 'edit') {
          loadSeq.value++
        }
        inputs.billingNotifications.set(newValue)
        inputs.billingNotifications.validate()
      }
      break
    }
    case 'strStartDate': {
      const startDate = fromMMDDYYYY(inputs.strStartDate.value)
      const endDate = fromMMDDYYYY(inputs.strEndDate.value)
      if (startDate >= endDate) {
        inputs.strStartDate.state = 'danger'
        inputs.strStartDate.error = {
          message: `Start Date should be before End Date.`
        }
      } else {
        const endDateMMDDYYYY = toMMDDYYYY(endDate)
        inputs.strEndDate.set(endDateMMDDYYYY)
        inputs.strEndDate.validate()
      }
      break
    }
    case 'strEndDate': {
      const startDate = fromMMDDYYYY(inputs.strStartDate.value)
      const endDate = fromMMDDYYYY(inputs.strEndDate.value)
      if (startDate >= endDate) {
        inputs.strEndDate.state = 'danger'
        inputs.strEndDate.error = {
          message: `End Date should be after Start Date.`
        }
      } else {
        const startDateMMDDYYYY = toMMDDYYYY(startDate)
        inputs.strStartDate.set(startDateMMDDYYYY)
        inputs.strStartDate.validate()
      }
      break
    }
    case 'svcDelivery': {
      if (!group?.length || typeof newValue !== 'object') {
        break
      }
      const isDefault = isEqual(toRaw(defaultValue), toRaw(newValue))
      if (isDefault) {
        inputs.svcDelivery.required = false
        svcDeliveryTouched.value = false
      }
      if (!isDefault) {
        inputs.svcDelivery.required = true
        if (
          String(defaultValue?.svcDeliveryDaysType) === String(newValue?.svcDeliveryDaysType) &&
          !svcDeliveryTouched.value
        ) {
          svcDeliveryTouched.value = true
          newValue.svcDeliveryDaysType = ''
          inputs.svcDeliveryDaysType.set(newValue.svcDeliveryDaysType)
          inputs.svcDelivery.set(newValue)
          inputs.svcDelivery.validate()
        }
      }

      break
    }
  }
}
onMounted(async () => {
  await resetForm()
})
</script>

<template>
  <Cells
    :columns="computedColumns"
    :settings="settings"
    :form="form"
    :row="row"
    classColDefault="-w-xl--2 -w-lg--3 -w-md--4 -w--12"
    @onInput="onInput"
  />
  <div class="-mx--3" style="padding-left: 0.25rem; padding-right: 0.25rem">
    <TextAreaCell
      class="-flex-basis--20 -flex-basis-lg--10"
      :class="cells.comments?.classCell"
      :cell="cells.comments"
      :input="form?.[row?.[settings.key]]?.comments"
      @onChange="
        (newValue: any) =>
          onInput(newValue, {
            key: row?.[settings.key],
            field: cells.comments.field,
            opts: cells.comments.input
          })
      "
    />
  </div>
</template>
<style>
.-word--wrap {
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
  white-space: -moz-pre-wrap;
  white-space: -hp-pre-wrap;
  white-space: -o-pre-wrap;
  white-space: -pre-wrap;
  white-space: pre-wrap;
  white-space: pre-line;
}
</style>
