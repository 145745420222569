<script lang="ts" setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useStore } from './store'
import { Tooltip } from '@centurylinkfederal/eis-vue'
import { useExportUtils } from './export-utils'

const store = useStore()
const { downloadSets } = useExportUtils()
const {
  activePanel,
  disabledPanels,
  alertMap,
  alertEntries,
  modalMap,
  modalEntries,
  signal,
  summary,
  form,
  formChanges,
  panelStates,
  selectedTaskOrderObj,
  setsToExport
} = storeToRefs(store)
const submitButton = computed(() => {
  let button: any = {
    label: 'Export',
    icon: undefined,
    tooltip: undefined,
    state: undefined,
    class: undefined
  }
  let state = 'unchanged'
  for (const panelKey in panelStates.value) {
    const { state: panelState, error } = panelStates.value[panelKey]
    if (panelState && panelState !== 'unchanged') {
      state = panelState
    }
    button = {
      ...button,
      icon: state === 'danger' ? 'warning' : undefined,
      tooltip: error?.message,
      state,
      class: state === 'success' ? '-primary' : state === 'danger' ? '-danger -outline' : undefined
    }
    if (state === 'danger') {
      break
    }
  }
  return button
})

async function downloadCsv(): Promise<void> {}

async function onSubmit(): Promise<void> {
  await downloadSets()
}
</script>

<template>
  <Tooltip :content="submitButton.tooltip" placement="left">
    <button
      class="chi-button"
      :class="[submitButton.class, { '-disabled': submitButton.state === 'unchanged' }]"
      aria-label="Submit Form"
      @click="onSubmit()"
    >
      <div class="chi-button__content">
        <i
          :class="['chi-icon icon-' + submitButton.icon, submitButton.class]"
          aria-hidden="true"
          v-if="submitButton.icon"
        ></i>
        <span>{{ submitButton.label }}</span>
      </div>
    </button>
  </Tooltip>
</template>
