<script setup lang="ts">
import { useStore } from './store'
import { storeToRefs } from 'pinia'
import { computed, watch } from 'vue'

const store = useStore()
const {
  adminEditingAdimUser,
  agenciesFilter,
  searchTOByAgencies,
  agencies,
  selectedTOOptionType,
  searchTosInputText,
  selectedAgencyTos,
  displayedAgencyTOs
} = storeToRefs(store)

const buttons = computed(() => {
  return [
    {
      label: 'All',
      value: 'ALL',
      action: () => {
        agenciesFilter.value = agencies.value
        selectedTOOptionType.value = 'ALL'
        searchTOByAgencies.value++
      }
    },
    {
      label: 'Show Only Assigned',
      value: 'ASSIGNED',
      action: () => {
        selectedTOOptionType.value = 'ASSIGNED'
        searchTOByAgencies.value++
      }
    }
  ]
})

watch(
  () => agenciesFilter.value,
  () => {
    selectedTOOptionType.value =
      agenciesFilter.value.length &&
      agencies.value.length &&
      agenciesFilter.value.length === agencies.value.length &&
      selectedTOOptionType.value !== 'ASSIGNED'
        ? 'ALL'
        : selectedTOOptionType.value
  }
)

function onSearchInput(e: any) {
  searchTosInputText.value = e.target.value
}

function searchTOByTaskOrder() {
  if (!agenciesFilter.value.length) return
  selectedAgencyTos.value = [...displayedAgencyTOs.value]

  if (searchTosInputText.value) {
    selectedAgencyTos.value = displayedAgencyTOs.value.filter((to) =>
      to.taskOrderNumber?.toLowerCase().includes(searchTosInputText.value?.toLowerCase())
    )
  }
}

watch(() => [displayedAgencyTOs.value, searchTosInputText.value], searchTOByTaskOrder)

function resetSearch() {
  searchTosInputText.value = ''
  if (!agenciesFilter.value.length) return

  selectedAgencyTos.value = [...displayedAgencyTOs.value]
}
</script>
<template>
  <div class="-d--flex -align-items--end">
    <div class="-mr--3">
      <label for="searchTOs" class="chi-label">Task Order</label>
      <chi-search-input
        id="searchTOs"
        mode="autocomplete"
        placeholder="Search"
        :value="searchTosInputText"
        size="md"
        :disabled="!agenciesFilter.length"
        @chiInput="onSearchInput"
        @chiSearch="searchTOByTaskOrder"
        @chiBlur="searchTOByTaskOrder"
        @chiClean="resetSearch"
      ></chi-search-input>
    </div>
    <div class="chi-picker-group -fluid -sm">
      <div class="chi-picker-group__content">
        <div class="-d--flex" v-for="button of buttons" :key="button.value">
          <input
            class="chi-picker__input"
            type="radio"
            name="toOptions"
            :id="button.value"
            :value="button.value"
            v-model="selectedTOOptionType"
            @click="button.action()"
            :disabled="adminEditingAdimUser"
          />
          <label :for="button.value">{{ button.label }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
