<script setup lang="ts">
import { defineEmits, withDefaults, computed, watch, onMounted, ref, nextTick } from 'vue'

export interface Props {
  areaLabel?: string
  compact?: boolean
  currentPage: number
  firstLast?: boolean
  pageJumper?: boolean
  pageSize: number
  isPageSize?: boolean
  results: any[]
  totalResults?: number
  size?: string
  class?: string
}

const props = withDefaults(defineProps<Props>(), {
  areaLabel: 'Pagination',
  compact: true,
  currentPage: 1,
  firstLast: true,
  pageJumper: true,
  pageSize: 10,
  isPageSize: true,
  results: () => [],
  totalResults: 0,
  size: 'xs',
  class: '-my--2'
})

const pageJumperValue = ref(props.currentPage)
const pageSizeArr: readonly number[] = [10, 20, 40, 60, 80]
const emit = defineEmits(['pageSize', 'currentPage', 'displayedResults'])

const paginationPages = computed(() => {
  const pageSize = Number(props.pageSize)
  const totalRecords = Number(props.totalResults)
  return totalRecords === 0
    ? Math.ceil(props.results.length / pageSize)
    : Math.ceil(totalRecords / pageSize)
})

const displayedItems = computed(() => {
  const start = (props.currentPage - 1) * props.pageSize
  const end = start + props.pageSize
  return props.results.slice(start, end)
})

onMounted(() => {
  nextTick(() => {
    emit('displayedResults', displayedItems.value)
  })
})

watch(
  () => props.pageSize,
  () => {
    if (props.currentPage > paginationPages.value) {
      emit('currentPage', 1)
    }
  }
)

watch(
  () => props.currentPage,
  () => {
    pageJumperValue.value = props.currentPage
  }
)

const getPageSize = (event: Event): void => {
  const { value } = event.target as HTMLSelectElement
  const pageSize = Number(value)
  emit('pageSize', pageSize)
}

const onFirstPage = (): void => {
  if (props.currentPage > 1) {
    emit('currentPage', 1)
  }
}

const onNextPage = (): void => {
  if (props.currentPage < paginationPages.value) {
    emit('currentPage', props.currentPage + 1)
  }
}

const onPreviousPage = (): void => {
  if (props.currentPage > 1) {
    emit('currentPage', props.currentPage - 1)
  }
}

const onLastPage = (): void => {
  if (props.currentPage < paginationPages.value) {
    emit('currentPage', paginationPages.value)
  }
}

const onPageInput = (event: Event): void => {
  const { value } = event.target as HTMLInputElement
  const pageNumber = Number(value)
  if (pageNumber > 0 && pageNumber <= paginationPages.value) {
    emit('currentPage', pageNumber)
  } else {
    pageJumperValue.value = props.currentPage
  }
}
</script>

<template>
  <nav
    v-if="props.results.length > 0"
    class="chi-pagination"
    :class="{
      '-compact': props.compact,
      [`-${props.size}`]: props.size,
      [props.class]: props.class
    }"
    role="navigation"
    :aria-label="props.areaLabel"
  >
    <div class="chi-pagination__content">
      <div class="chi-pagination__start">
        <div class="chi-pagination__results">
          <span class="chi-pagination__label"
            >{{ props.totalResults || props.results.length }} results</span
          >
        </div>
        <div v-if="props.totalResults > 10 && isPageSize" class="chi-pagination__page-size">
          <select
            class="chi-input"
            :class="props.size === 'xs' ? '-sm' : `-${props.size}`"
            aria-label="Number of result items per page"
            :disabled="props.results.length === 0"
            @change="getPageSize"
          >
            <option
              v-for="pSize in pageSizeArr"
              :key="pSize"
              :value="pSize"
              :selected="pSize === props.pageSize"
              :disabled="pSize === props.pageSize"
            >
              {{ pSize }}
            </option>
          </select>
          <span class="chi-pagination__label">per page</span>
        </div>
      </div>
      <div class="chi-pagination__center -px--1">
        <div class="chi-pagination__button-group chi-button-group">
          <button
            v-if="props.firstLast"
            id="firstPageBtn"
            class="chi-button -icon -flat -px--0"
            :class="`-${props.size}`"
            aria-label="First page"
            type="button"
            :disabled="props.currentPage === 1"
            @click="onFirstPage"
          >
            <div class="chi-button__content">
              <i class="chi-icon icon-page-first" aria-hidden="true"></i>
            </div>
          </button>
          <button
            id="leftPageBtn"
            class="chi-button -icon -flat"
            :class="`-${props.size}`"
            aria-label="Previous page"
            type="button"
            :disabled="props.currentPage === 1"
            @click="onPreviousPage"
          >
            <div class="chi-button__content">
              <i class="chi-icon icon-chevron-left" aria-hidden="true"></i>
            </div>
          </button>
        </div>
        <div class="chi-pagination__jumper -mx--0">
          <input
            v-if="props.pageJumper"
            id="currentPage"
            v-model="pageJumperValue"
            type="text"
            class="chi-input"
            :class="props.size === 'xs' ? '-sm' : `-${props.size}`"
            :disabled="paginationPages === 1"
            @change="onPageInput"
          />
          <div class="chi-pagination__label">
            <strong v-if="!props.pageJumper">{{ props.currentPage }}</strong>
            <span>of</span>
            <strong>{{ paginationPages }}</strong>
          </div>
        </div>
        <div class="chi-pagination__button-group chi-button-group -ml--1">
          <button
            id="rightPageBtn"
            class="chi-button -icon -flat"
            :class="`-${props.size}`"
            aria-label="Next page"
            type="button"
            :disabled="props.currentPage === paginationPages"
            @click="onNextPage"
          >
            <div class="chi-button__content">
              <i class="chi-icon icon-chevron-right" aria-hidden="true"></i>
            </div>
          </button>
          <button
            v-if="props.firstLast"
            id="lastPageBtn"
            class="chi-button -icon -flat -px--0"
            :class="`-${props.size}`"
            aria-label="Last page"
            type="button"
            :disabled="props.currentPage === paginationPages"
            @click="onLastPage"
          >
            <div class="chi-button__content">
              <i class="chi-icon icon-page-last" aria-hidden="true"></i>
            </div>
          </button>
        </div>
      </div>
      <div class="chi-pagination__end"></div>
    </div>
  </nav>
</template>
