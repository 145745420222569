import { computed } from 'vue'
import {
  toBeOptional,
  toNotBeEmpty,
  toBeAMonthDayYear,
  toBeALocalPhone,
  toLocalPhone,
  toBeAnEmail
} from '@centurylinkfederal/eis-vue'
import { defineStore } from 'pinia'

export const useColumns = defineStore('officialColumnsStore', () => {
  const columns = computed(() => [
    {
      field: 'firstName',
      label: 'First Name',
      input: {
        type: 'text',
        maxLength: 50,
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'lastName',
      label: 'Last Name',
      input: {
        type: 'text',
        maxLength: 50,
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'workOfficePhone',
      label: 'Work Phone',
      input: {
        type: 'text',
        mode: 'tel',
        size: 'md',
        placeholder: 'e.g. (212) 897-1964',
        required: true,
        maxLength: 15,
        tests: [toNotBeEmpty, toBeALocalPhone],
        formatFn: toLocalPhone,
        dangerMessage: 'Invalid Work Phone'
      }
    },
    {
      field: 'workMobilePhone',
      label: 'Work Mobile Phone',
      input: {
        type: 'text',
        mode: 'tel',
        size: 'md',
        placeholder: 'e.g. (212) 897-1964',
        maxLength: 15,
        tests: [toBeOptional, toBeALocalPhone],
        formatFn: toLocalPhone,
        dangerMessage: 'Invalid Work Mobile Phone'
      }
    },
    {
      field: 'workEmail',
      label: 'Work E-mail',
      input: {
        type: 'text',
        size: 'md',
        maxLength: 50,
        required: true,
        tests: [toNotBeEmpty, toBeAnEmail],
        dangerMessage: 'Invalid Work E-mail'
      }
    },
    {
      field: 'role',
      label: 'Role',
      input: {
        type: 'select',
        options: [['', '- Select -'], 'COR', 'OCO'],
        required: true,
        value: '',
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'startDate',
      label: 'Start Date',
      input: {
        type: 'date',
        format: 'MM/DD/YYYY',
        required: true,
        tests: [toNotBeEmpty, toBeAMonthDayYear]
      }
    },
    {
      field: 'stopDate',
      label: 'End Date',
      input: {
        type: 'date',
        format: 'MM/DD/YYYY',
        tests: [toBeOptional, toBeAMonthDayYear]
      }
    },
    {
      field: 'createdOn'
    },
    {
      field: 'createdBy'
    },
    {
      field: 'updatedBy'
    },
    {
      field: '_deleted',
      input: {
        hidden: true,
        value: false
      }
    }
  ])

  return {
    columns
  }
})
