<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useApi } from '@centurylinkfederal/eis-vue'
import { useAlertsStore } from '../store/alert'
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()

const agencyParam = route.params.id

const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)
const agency = ref('')
const maskedName = ref('')
const bizOrgId = ref('')
const notes = ref('')
const alertsStore = useAlertsStore()
const isReadOnly = ref(false)
const hasEditPermission = ref(false)

function reset() {
  alertsStore.alerts = []
  router.push('/agencyAhcAccountAdminFlow/lookup-agency')
}
onMounted(async () => {
  await getAgencyData()
})

async function getAgencyData(): Promise<void> {
  try {
    const res: any = await api.get(`/agency/${agencyParam}`)
    agency.value = res?.agencyName
    maskedName.value = res?.maskedName
    bizOrgId.value = res?.bizOrgId
    notes.value = res?.notes
    isReadOnly.value = res?.readOnly
    hasEditPermission.value = res?.permissions.includes('edit')
    if (res?.errorMessage) {
      alertsStore.alerts = []
      alertsStore.addAlert('danger', res?.errorMessage)
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

const updatedNotes = (event: Event) => {
  if (event.target) {
    notes.value = (event.target as HTMLInputElement).value
  }
}
const updateMaskedName = (event: Event) => {
  if (event.target) {
    maskedName.value = (event.target as HTMLInputElement).value
  }
}
const updatedBizOrgId = (event: Event) => {
  if (event.target) {
    bizOrgId.value = (event.target as HTMLInputElement).value
  }
}

async function submit() {
  try {
    await api
      .put(`/agencies/${agencyParam}`, {
        maskedName: maskedName.value,
        bizOrgId: bizOrgId.value,
        editNotesOnly: isReadOnly.value,
        notes: notes.value
      })
      .then(() => {
        alertsStore.alerts = []
        alertsStore.addAlert('success', `Your request was processed successfully`)
        window.scroll({ top: 0, behavior: 'smooth' })
      })
  } catch (e: any) {
    let message = e.response?.data?.message
    if (!message) {
      message = 'Unable to process request'
    }
    alertsStore.alerts = []
    alertsStore.addAlert('danger', message)
  }
}
</script>
<template>
  <div class="chi-grid">
    <div class="chi-col -w--6 -m--2">
      <div class="chi-form__item -mb--2">
        <label class="chi-label" for="agency">
          Agency
          <abbr class="chi-label__required" title="Required field">*</abbr>
        </label>
        <select class="chi-select -md" id="agency" disabled>
          <option>
            {{ agency }}
          </option>
        </select>
      </div>
      <div class="chi-form__item -mb--2">
        <label class="chi-label" for="maskedName">
          Masked Name
          <abbr class="chi-label__required" title="Required field">*</abbr>
        </label>
        <input
          class="chi-input -md"
          id="maskedName"
          v-model="maskedName"
          :disabled="isReadOnly || !hasEditPermission"
          @change="updateMaskedName($event)"
          maxlength="50"
        />
      </div>
      <div class="chi-form__item -mb--2">
        <label class="chi-label" for="bizOrgId">
          BizOrgId
          <abbr class="chi-label__required" title="Required field">*</abbr>
        </label>
        <input
          class="chi-input -md"
          id="bizOrgId"
          v-model="bizOrgId"
          :disabled="isReadOnly || !hasEditPermission"
          @change="updatedBizOrgId($event)"
          maxlength="50"
        />
      </div>
      <div class="chi-form__item -mb--2">
        <label class="chi-label" for="notes"> Notes </label>
        <textarea
          class="chi-input -md"
          id="notes"
          v-model="notes"
          @change="updatedNotes($event)"
          maxlength="150"
          :disabled="!hasEditPermission"
        />
      </div>
    </div>
    <div class="chi-col -w--8 -m--2">
      <div class="-d--flex -flex--row-reverse">
        <button class="chi-button -md -primary" @click="reset">Back To Lookup</button>
        <button
          class="chi-button -md -primary -mr--2"
          @click="submit"
          :disabled="!hasEditPermission"
        >
          <span v-if="isReadOnly">UPDATE NOTES</span>
          <span v-else>UPDATE AGENCY</span>
        </button>
      </div>
    </div>
  </div>
</template>
