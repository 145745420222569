import { defineStore } from 'pinia'
import { ref } from 'vue'

export interface LookupSelection {
  agencyName: string
  maskedName: string
  taskOrder: string
  billingAccount: string
}

export const useLookupSelectionStore = defineStore('lookup', () => {
  const lookupSelection = ref<LookupSelection>({
    agencyName: '-1',
    maskedName: '-1',
    taskOrder: '-1',
    billingAccount: '-1'
  })

  function updateLookupSelection(value: string, key: string): void {
    const existingSelection = lookupSelection.value
    lookupSelection.value = {
      ...existingSelection,
      [key]: value
    }
  }

  function clearSelection(key: string): void {
    const existingSelection = lookupSelection.value
    lookupSelection.value = {
      ...existingSelection,
      [key]: '-1'
    }
  }

  return { lookupSelection, updateLookupSelection, clearSelection }
})
