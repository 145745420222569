<script lang="ts" setup>
import ImportFile from './../ImportFile.vue'
import { Modal } from '@centurylinkfederal/eis-vue'
import { defineProps, ref, Ref, onMounted, computed } from 'vue'

const props = defineProps([
  'jurisdictonsLookupData',
  'jurisdictonsLookupCodes',
  'selectedJurisdictionsData',
  'serviceId',
  'selectedService',
  'mode'
])

const emit = defineEmits(['close', 'saveSelectedJuridictionCodes'])

const isAllJurisdictionsSlectedForAService: Ref<boolean> = ref<boolean>(false)
const selectedJurisdictionCodes: Ref<Array<any>> = ref<Array<any>>([])
const selectedJurisdictionsDataDuplicate: Ref<Array<any>> = ref<Array<any>>([])
const newlyAddedJuridiction: Ref<Array<any>> = ref<Array<any>>([])
const existingJuridiction: Ref<Array<any>> = ref<Array<any>>([])
const juridictionList: Ref<Array<any>> = ref<Array<any>>([])
const showImportDialog: Ref<boolean> = ref<boolean>(false)

const fields: string[] = ['acronym', 'jurisdId']
const headers: string[] = ['Service Id', 'Jurisdiction Id']
const actionButtons =
  props.mode === 'VIEW'
    ? [
        {
          text: 'Cancel',
          dismissModal: true
        }
      ]
    : [
        { text: 'Apply' },
        { text: 'Import' },
        {
          text: 'Cancel',
          dismissModal: true
        }
      ]

const title = computed(() =>
  props.mode === 'VIEW'
    ? `Selected Jurisdiction for ${props.serviceId}`
    : `Select TO Country/Jurisdiction Awarded by Service ${props.serviceId}`
)

function openImportDialog(): void {
  showImportDialog.value = true
}

function closeImportDialog(): void {
  showImportDialog.value = false
}

function importedFileInfo(file: any): void {
  const { contents } = file
  const importedCountries = contents.map((c: any) =>
    c.jurisdId.replace(/(?:\\[rn]|[\r\n]+)+/g, '').toLowerCase()
  )
  const selectedJurisdictions = props.jurisdictonsLookupData.filter((j: any) =>
    importedCountries.includes(String(j.jurisdictionCode))
  )
  console.log(selectedJurisdictions)
  selectedJurisdictions.forEach((j: any) => {
    newSelectedJuridiction(j, { target: { checked: true } })
    if (!selectedJurisdictionCodes.value.includes(j.jurisdictionCode)) {
      selectedJurisdictionCodes.value.push(j.jurisdictionCode)
    }
  })
}

onMounted(() => {
  if (props.mode === 'VIEW') {
    juridictionList.value = props.selectedService

    return
  }
  juridictionList.value = props.jurisdictonsLookupData
  existingJuridiction.value = []
  selectedJurisdictionCodes.value = []
  props.selectedJurisdictionsData.forEach((jurisdictionObj: any) => {
    selectedJurisdictionCodes.value.push(jurisdictionObj.jurisdId)
  })
  existingJuridiction.value = [...selectedJurisdictionCodes.value]
  isAllJurisdictionsSlectedForAService.value =
    selectedJurisdictionCodes.value.length === props.jurisdictonsLookupCodes.length
  if (isAllJurisdictionsSlectedForAService.value) {
    selectedJurisdictionCodes.value = props.jurisdictonsLookupCodes.slice()
    existingJuridiction.value = selectedJurisdictionCodes.value
  }
})

function closePopup(): void {
  emit('close')
}
function toggleSelectAll(event: any): void {
  isAllJurisdictionsSlectedForAService.value = !!event.target.checked
  selectedJurisdictionCodes.value = []
  if (isAllJurisdictionsSlectedForAService.value) {
    selectedJurisdictionCodes.value = props.jurisdictonsLookupCodes.slice()
  }
}
function saveJuridictionData(): void {
  selectedJurisdictionsDataDuplicate.value = []
  selectedJurisdictionCodes.value.forEach((code) => {
    let obj = {
      taskOrderJurisdictionId: '',
      jurisdId: code,
      country: ''
    }
    selectedJurisdictionsDataDuplicate.value.push(obj)
  })
  emitSavedJuridictionData()
}
function emitSavedJuridictionData(): void {
  emit('saveSelectedJuridictionCodes', selectedJurisdictionsDataDuplicate.value)
}
function newSelectedJuridiction(selectedJuridiction: any, event: any): void {
  if (event.target.checked) {
    const idx = existingJuridiction.value.findIndex(
      (ex) => ex == selectedJuridiction.jurisdictionCode
    )
    if (idx == -1) {
      console.log('adding..')
      newlyAddedJuridiction.value.push({
        taskOrderJurisdictionId: '',
        jurisdId: selectedJuridiction.jurisdictionCode,
        country: selectedJuridiction.countryDesc
      })
    }
  } else {
    const newIdx = newlyAddedJuridiction.value.findIndex(
      (ex) => ex.jurisdId == selectedJuridiction.jurisdictionCode
    )
    if (newIdx > -1) {
      newlyAddedJuridiction.value.splice(newIdx, 1)
    }
  }
}

function actionBtnClicked(index: number): void {
  if (props.mode === 'VIEW') {
    return closePopup()
  }
  if (index === 0) {
    // apply
    saveJuridictionData()
  }
  if (index === 1) {
    //import
    openImportDialog()
  }
  if (index == 2) {
    closePopup()
  }
}
</script>

<template>
  <div>
    <Modal
      :center="true"
      :title="title"
      :buttons="actionButtons"
      @onButtonClick="actionBtnClicked"
      @onDismissModal="closePopup"
    >
      <div style="max-height: 400px; overflow-y: auto">
        <table class="chi-table -bordered -striped">
          <thead class="header">
            <tr>
              <td class="table-cell" v-if="props.mode !== 'VIEW'">
                <input
                  class="chi-checkbox__input"
                  type="checkbox"
                  id="all_jurisdictions"
                  value="all"
                  @click="toggleSelectAll($event)"
                  :checked="isAllJurisdictionsSlectedForAService"
                />
                <label class="chi-checkbox__label" for="all_jurisdictions">
                  <div class="-sr--only">Label</div>
                </label>
              </td>
              <td class="table-cell">Jurisdiction ID</td>
              <td class="table-cell">Country</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in juridictionList" :key="index">
              <td class="table-cell" v-if="props.mode !== 'VIEW'">
                <input
                  @click="newSelectedJuridiction(item, $event)"
                  class="chi-checkbox__input"
                  type="checkbox"
                  :value="item.jurisdictionCode"
                  :id="`jurisdiction_option_${item.jurisdictionCode}`"
                  v-model="selectedJurisdictionCodes"
                />
                <label
                  class="chi-checkbox__label"
                  :for="`jurisdiction_option_${item.jurisdictionCode}`"
                >
                  <div class="-sr--only">Label</div>
                </label>
              </td>
              <td class="table-cell">
                {{ props.mode === 'VIEW' ? item.jurisdId : item.jurisdictionCode }}
              </td>
              <td class="table-cell">
                {{ props.mode === 'VIEW' ? item.country : item.countryDesc }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <template v-slot:modal-footer>
        <div class="chi-card__footer -noBorder -alignCenter">
          <button class="chi-btn -mr--2" v-on:click="saveJuridictionData()">Apply</button>
          <button @click="openImportDialog" class="chi-btn -mr--4">Import</button>
          <button class="chi-btn" v-on:click="closePopup()">Close</button>
        </div>
      </template>
    </Modal>

    <div v-if="showImportDialog">
      <ImportFile
        title="Country/Jurisdiction"
        :dataFields="fields"
        :headerDetails="headers"
        @closeModal="closeImportDialog"
        @importContent="importedFileInfo($event)"
      />
    </div>
  </div>
</template>

<style scoped lang="css">
.country-jurisdiction-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.country-jurisdiction-item {
  flex: 1 0 auto;
  min-width: 50%;
}

.country-jurisdiction-important {
  color: red;
}

.country-jurisdiction-heading {
  font-weight: bold;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.padding-8 {
  padding: 8px;
}

.padding-left-8 {
  padding-left: 8px;
}

.table-cell {
  padding: 0.25rem;
  text-align: left !important;
}

thead > tr > td.table-cell {
  background-color: #38c6f4;
}
</style>
