<script lang="ts" setup>
import { defineProps, ref, Ref } from 'vue'
import { useAlertsStore } from '../store/alert'
import { Modal } from '@centurylinkfederal/eis-vue'
interface IProps {
  title: string
  dataFields: Array<any>
  headerDetails: Array<any>
}
const props = defineProps<IProps>()
const emits = defineEmits(['importContent', 'closeModal'])
const actionButtons = [
  { text: 'Import' },
  {
    text: 'Cancel',
    dismissModal: true
  }
]
const alertStore = useAlertsStore()
const fileName = ref<string>()
const errorMsg = ref<string>()
const fileContent = ref<string>()
function chooseFiles() {
  const uploadElement = document.getElementById('fileUploader')
  uploadElement?.click()
}
function handleFile(e: any) {
  const files = e.target?.files as FileList
  errorMsg.value = ''
  const file = files.item(0)
  if (file) {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = (e) => {
      fileName.value = file.name
      fileContent.value = e.target?.result as string
    }
  }
}
function importCSV() {
  errorMsg.value = ''
  if (!fileName.value) {
    errorMsg.value = `Please select ${props.title} file to import`
    return
  }

  if (!fileContent.value) return
  const headers = fileContent.value
    ?.split('\n')
    .slice(0, 1)?.[0]
    .split(',')
    ?.filter((val) => !!val)
  if (!headers.some((header) => props.headerDetails.includes(header))) {
    errorMsg.value = `Not a valid ${props.title} file for import`
    return
  }
  const importedData = fileContent.value
    ?.split('\n')
    .slice(1)
    .filter((data: any) => !!data)
    .map((item: any) =>
      item.split(',').reduce((all: any, item: any, i: number) => {
        const key = props.dataFields[i]
        if (key) all[key] = item
        return all
      }, {})
    )
  emits('importContent', {
    isValid: true,
    contents: importedData
  })
  fileName.value = ''
  emits('closeModal', true)
}
function closeModal() {
  emits('closeModal', true)
}
function cancelUpload() {
  errorMsg.value = ''
  fileName.value = ''
  emits('closeModal', true)
}
function onButtonClick(index: number) {
  if (index === 0) {
    importCSV()
  } else {
    errorMsg.value = ''
    fileName.value = ''
    emits('closeModal', true)
  }
}
</script>

<template>
  <div>
    <Modal
      :center="true"
      :title="`Task Order ${props.title} for import`"
      details=" "
      :buttons="actionButtons"
      @onButtonClick="onButtonClick"
      @onDismissModal="closeModal"
    >
      <div class="-d--flex">
        <span for="import file" class="-text--bold -text -pl--1">Locate file for import</span>
        <input
          id="fileUploader"
          type="file"
          class="-primary -outline -mr--2"
          hidden
          @change="handleFile($event)"
          accept=".csv"
        />
        <button
          class="chi-button -sm -primary -outline -ml--3 -mr--2"
          @click="chooseFiles()"
          style="border-radius: 4px"
        >
          Browse
        </button>
        <span>{{ fileName }}</span>
      </div>
      <div class="-text--danger">
        {{ errorMsg }}
      </div>
    </Modal>
  </div>
</template>

<style scoped></style>
