<script lang="ts" setup>
import { defineProps, defineEmits, ref, onBeforeMount } from 'vue'

interface IProps {
  invoiceItems: any
  sendBillingEmail: string
  billingContacts: any[]
  readOnly?: boolean
}

const props = defineProps<IProps>()

const $emit = defineEmits<{
  (e: 'close'): void
  (
    e: 'submit',
    value: { invoiceItems: any; sendBillingEmail: string; billingContacts: any[] }
  ): void
}>()

const invoiceOptionList: any[] = [
  { id: 'ioCustom', label: 'Custom Tax, Fees & Surcharges' },
  { id: 'ioDoi', label: 'DOI' },
  { id: 'etems', label: 'Etems' },
  { id: 'ipp', label: 'IPP' }
]

const tabs: any[] = [
  { id: 'invoice-options', label: 'Invoice Options' },
  { id: 'billing-notifications', label: 'Billing Notifications' }
]

const invoiceItems = ref<any>(
  props.invoiceItems || {
    ioCustom: 'N',
    ioDoi: 'N',
    etems: 'N',
    ipp: 'N'
  }
)
const sendBillingEmail = ref<string>(props.sendBillingEmail || 'Y')
const billingContacts = ref<any[]>(
  Array.isArray(props.billingContacts) && props.billingContacts.length > 0
    ? [...props.billingContacts]
    : [{ contactName: '', email: '', id: crypto.randomUUID() }]
)
const activeTab = ref<string>('invoice-options')
const sendBillingEmailAck = ref<boolean>(false)

onBeforeMount(() => {
  if (sendBillingEmail.value === 'N') {
    sendBillingEmailAck.value = true
  }
})

function onTabChange($event: any) {
  activeTab.value = $event
}

function onChangeInvoiceOption(id: string) {
  if (invoiceItems.value[id] === 'N') {
    invoiceItems.value[id] = 'Y'
  } else {
    invoiceItems.value[id] = 'N'
  }
  invoiceItems.value = { ...invoiceItems.value }
}

function onSendBillingEmailChange(val: string) {
  if (props.readOnly) {
    return
  }
  sendBillingEmail.value = val
  if (val === 'Y') {
    sendBillingEmailAck.value = false
  }
}

function addNewContact() {
  if (props.readOnly) {
    return
  }
  billingContacts.value.push({ contactName: '', email: '', id: crypto.randomUUID() })
}

function removeBillingContact(id: string) {
  if (props.readOnly) {
    return
  }
  billingContacts.value = billingContacts.value.filter((bc: any) => bc.id !== id)
}

function applyBillingOptions() {
  $emit('submit', {
    billingContacts: billingContacts.value,
    sendBillingEmail: sendBillingEmail.value,
    invoiceItems: invoiceItems.value
  })
}

function onSendBillingEmailAckConfirm() {
  sendBillingEmailAck.value = true
}

function onSendBillingEmailAckCancel() {
  sendBillingEmail.value = 'Y'
  sendBillingEmailAck.value = false
}
</script>

<template>
  <div class="chi-backdrop -center">
    <div class="chi-backdrop__wrapper">
      <section class="chi-modal" role="dialog" aria-modal="true">
        <header class="chi-modal__header">
          <h2 class="chi-modal__title">Billing Options</h2>
          <button class="chi-button -icon -close" @click="$emit('close')">
            <div class="chi-button__content">
              <i class="chi-icon icon-x" aria-hidden="true"></i>
            </div>
          </button>
        </header>
        <div class="chi-modal__content modal-content">
          <ul class="chi-tabs" role="tablist">
            <li :class="activeTab === tab.id ? '-active' : ''" v-for="tab in tabs" :key="tab.id">
              <a
                @click="onTabChange(tab.id)"
                role="tab"
                aria-selected="true"
                aria-controls="horizontal-bordered-1"
              >
                {{ tab.label }}
              </a>
            </li>
          </ul>
          <!-- <chi-tabs :active-tab="activeTab" :tabs="tabs" @chiTabChange="onTabChange" /> -->
          <div class="tab-body">
            <div v-if="activeTab === 'invoice-options'">
              <div class="chi-form__item -mb--1" v-for="item in invoiceOptionList" :key="item.id">
                <div class="chi-checkbox" @click="onChangeInvoiceOption(item.id)">
                  <input
                    :disabled="props.readOnly"
                    type="checkbox"
                    class="chi-checkbox__input"
                    :checked="invoiceItems[item.id] === 'Y'"
                  />
                  <label class="chi-checkbox__label">{{ item.label }}</label>
                </div>
              </div>
            </div>
            <div v-if="activeTab === 'billing-notifications'">
              <div>
                <label>Does the Agency want the SSCN Billing Notification Email?</label>
              </div>
              <div class="chi-picker-group">
                <div class="chi-picker-group__content">
                  <input
                    class="chi-picker__input"
                    type="radio"
                    :checked="sendBillingEmail === 'Y'"
                    :disabled="props.readOnly"
                  />
                  <label @click="onSendBillingEmailChange('Y')">Yes</label>
                  <input
                    class="chi-picker__input"
                    type="radio"
                    :disabled="props.readOnly"
                    :checked="sendBillingEmail === 'N'"
                  />
                  <label @click="onSendBillingEmailChange('N')">No</label>
                </div>
              </div>
              <div class="-my--1">
                <div class="-d--flex -justify-content--between">
                  <label>Agency Billing Contact(s)</label>
                  <button class="chi-button -xs" @click="addNewContact" :disabled="props.readOnly">
                    Add New Contact
                  </button>
                </div>
              </div>
              <div>
                <div
                  class="-d--flex -mb--1"
                  style="gap: 1rem"
                  v-for="(bc, index) in billingContacts"
                  :key="bc.id"
                >
                  <div class="chi-form__item -flex--fill">
                    <input
                      class="chi-input"
                      v-model="bc.contactName"
                      placeholder="Contact Name"
                      :disabled="props.readOnly"
                    />
                  </div>
                  <div class="chi-form__item -flex--fill">
                    <input
                      class="chi-input"
                      v-model="bc.email"
                      placeholder="Email"
                      :disabled="props.readOnly"
                    />
                  </div>
                  <chi-button
                    variant="flat"
                    type="icon"
                    style="width: 50px"
                    @click="removeBillingContact(bc.id)"
                  >
                    <chi-icon icon="delete" v-if="index > 0" />
                  </chi-button>
                </div>
              </div>
              <div v-if="sendBillingEmail === 'N' && !sendBillingEmailAck">
                <div class="billing-confirm-backdrop">
                  <div class="chi-alert -warning -lg mt-2" role="alert">
                    <i class="chi-alert__icon chi-icon icon-warning" aria-hidden="true"></i>
                    <div class="chi-alert__content">
                      <p class="chi-alert__title">
                        Are you sure you would like to proceed with this action?
                      </p>
                      <p class="chi-alert__text">
                        Selecting "No" will clear all existing SSCN Billing Notification Contacts
                        and discontinue all SSCN Email Notifications.
                      </p>
                      <div class="chi-alert__actions d-flex flex-row-reverse">
                        <button
                          class="chi-button -sm -primary"
                          @click="onSendBillingEmailAckConfirm()"
                        >
                          Proceed
                        </button>
                        <button class="chi-button -sm mr-3" @click="onSendBillingEmailAckCancel()">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="chi-modal__footer">
          <button class="chi-button" @click="$emit('close')" :disabled="props.readOnly">
            Cancel
          </button>
          <button
            class="chi-button -primary"
            @click="applyBillingOptions"
            :disabled="props.readOnly"
          >
            Apply
          </button>
        </footer>
      </section>
    </div>
  </div>
</template>
<style lang="css" scoped>
.billing-confirm-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9;
  position: absolute;
  top: 150px;
  width: 100%;
  left: 0px;
  padding: 30px;
  height: calc(100% - 44px);
}
.modal-content {
  padding: 1rem;
}
.tab-body {
  margin-top: 16px;
}

.billing-contacts > .header {
  display: flex;
  justify-content: space-between;
}
</style>
