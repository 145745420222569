import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useToAdminAPI } from '../../composables/to-admin/api'
import { useStore } from './store'

export function useExportUtils() {
  const store = useStore()
  const { selectedTaskOrderObj, setsToExport } = storeToRefs(store)
  const taskOrderId = computed(() => selectedTaskOrderObj.value?.taskOrderId)
  const originalTaskOrderId = computed(() => selectedTaskOrderObj.value?.originalTaskOrderId)
  const adminApi = useToAdminAPI()
  const meta: any = computed(() => ({
    toFinancials: {
      name: 'TO Financials',
      action: async () => {
        const response: any = await adminApi.fetchTaskOrderFinancial(taskOrderId.value)
        const rec: any = response?.results?.[0] ?? {}
        const rows = Object.values(rec).flat()
        return rows
      },
      headers: [
        { key: 'taskOrderCeiling', header: 'TO Ceiling Amount' },
        { key: 'taskOrderAgfRate', header: 'TO AGF Rate (%)' },
        { key: 'performancePeriodFunding', header: 'Performance Period Funding' },
        { key: 'prorationType', header: 'Proration Type' },
        { key: 'performanceBeginDate', header: 'Performance Begin Date' },
        { key: 'performanceEndDate', header: 'Performance End Date' }
      ]
    },
    toOfficial: {
      name: 'TO Officials',
      action: async () => await adminApi.fetchToOfficialsByTaskOrderId(taskOrderId.value),
      headers: [
        { key: 'firstName', header: 'First Name' },
        { key: 'lastName', header: 'Last Name' },
        { key: 'workOfficePhone', header: 'Office Phone' },
        { key: 'workMobilePhone', header: 'Mobile Phone' },
        { key: 'workEmail', header: 'Email' },
        { key: 'role', header: 'Role' },
        { key: 'startDate', header: 'Start Date' },
        { key: 'stopDate', header: 'End Date' }
      ]
    },
    toLocations: {
      name: 'TO Locations',
      action: async () => {
        const response: any = await adminApi.getTaskOrderNSCsByTaskOrderID(taskOrderId.value)
        const rec: any = response?.results?.[0] ?? {}
        const recs = Object.entries(rec).map(([i, e]: any) =>
          (e ?? []).map((f: any) =>
            Object({
              toServiceId: extractKey(i),
              networkSiteCode: f.networkSiteCode,
              location: f.location
            })
          )
        )
        const rows = recs.flat()
        return rows
      },
      headers: [
        { key: 'toServiceId', header: 'Service ID' },
        { key: 'networkSiteCode', header: 'NSC' },
        { key: 'location', header: 'Location' }
      ]
    },
    toCountriesJurisdictions: {
      name: 'Countries And Jurisdictions',
      action: async () => {
        const response: any = await adminApi.getTaskOrderJurisdictionsByTaskOrderID(
          taskOrderId.value
        )
        const rec: any = response?.results?.[0] ?? {}
        const recs = Object.entries(rec).map(([i, e]: any) =>
          (e ?? []).map((f: any) => Object({ toServiceId: extractKey(i), jurisdId: f.jurisdId }))
        )
        const rows = recs.flat()
        return rows
      },
      headers: [
        { key: 'toServiceId', header: 'Service ID' },
        { key: 'jurisdId', header: 'Jurisdiction ID' }
      ]
    },
    toServicesAndClins: {
      name: 'Services and CLINs',
      action: async () => await adminApi.getServicesByTONumber(taskOrderId.value),
      headers: [
        { key: 'serviceID', header: 'Service ID' },
        { key: 'identifier', header: 'All_Clins' }
      ]
    },
    toClinsAwarded: {
      name: 'CLINs',
      action: async () => {
        const response: any = await adminApi.getTaskOrderSvcDetailsDTO(taskOrderId.value)
        const results: any[] = response?.results ?? []
        const recs = results.map((e) =>
          e.clins?.length
            ? e.clins.map((f: any) => Object({ serviceCode: e.serviceCode, clin: f }))
            : undefined
        )
        const rows = recs.filter((f) => f).flat()
        return rows
      },
      headers: [
        { key: 'serviceCode', header: 'Service ID' },
        { key: 'clin', header: 'CLIN' }
      ]
    },
    toKpi: {
      name: 'KPI',
      action: async () => await adminApi.getAllKPIs(originalTaskOrderId.value ?? taskOrderId.value),
      headers: [
        { key: 'svcId', header: 'service_id' },
        { key: 'clin', header: 'clins' },
        { key: 'icbNumber', header: 'case_number' },
        { key: 'kpiucUnitCode', header: 'unit_code' },
        { key: 'kpilqLocQCode', header: 'loc_qualifier' },
        { key: 'kpimuMeasUnitCode', header: 'measurement_unit' },
        { key: 'kpilqSvcLvlQCode', header: 'service_level_qualifier' },
        { key: 'perfStdThreshold', header: 'performance_standard' },
        { key: 'kpiaoAqlQOprCode', header: 'aql_qualifier' }
      ]
    }
  }))
  function extractKey(key: string): string {
    return String(key).replace('{', '').split('=')[0]
  }
  function getExportData(data: any, heading: Array<any>): string {
    const props = heading.map((data) => data.key)
    const lines = []
    const headers = []
    for (const col of heading) {
      headers.push(col.header)
    }
    lines.push(headers)
    for (const row of data) {
      const cols = []
      for (const key of props) {
        cols.push(row[key])
      }
      lines.push(cols)
    }
    const csv = lines.map((cols) => cols.map((value) => value).join(',')).join('\n')
    return csv
  }

  function createExportReport(value: any, filename: string): void {
    const blob = new Blob([value], { type: 'text/cvs' })
    const val = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = val
    a.download = `${filename}.csv`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  async function downloadSets() {
    const downloadedData: Record<string, any> = {}
    for await (const key of setsToExport.value) {
      downloadedData[key] = await meta.value[key].action()
    }
    for (const key of Object.keys(downloadedData)) {
      const data = getExportData(
        Array.isArray(downloadedData[key]) ? downloadedData[key] : downloadedData[key].results,
        meta.value[key].headers
      )
      createExportReport(data, meta.value[key].name)
    }
  }

  return {
    downloadSets
  }
}
