import { AlertType } from '@centurylinkfederal/eis-vue'
import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

export const useStore = defineStore('ResetPasswordStore', () => {
  const alertMap = ref<Map<string, AlertType>>(new Map())
  const alertEntries = ref<[string, AlertType][]>([])

  watch(
    () => alertMap,
    (newMap) => {
      alertEntries.value = Array.from(newMap.value.entries())
    },
    { deep: true }
  )

  return {
    alertMap,
    alertEntries
  }
})
