<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { useApi } from '@centurylinkfederal/eis-vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useDbasStore } from './dbasStore'
const route = useRoute()

const dbasStore = useDbasStore()
const {
  dbasFormData,
  selectedTaskOrderNumber,
  selectedTaxExempt,
  displayTOError,
  displayTaxExemptError,
  accountId,
  taskOrderId,
  errorAlerts,
  successAlerts,
  dbasAssociationIdOfSelectedTaskOrder
} = storeToRefs(dbasStore)

accountId.value = route.params.accountId.toString()
taskOrderId.value = route.params.taskOrderId.toString()
const api = useApi(import.meta.env.VITE_EIS_API_BASE)
onMounted(async () => {
  await getDbasFormData()
})
async function getDbasFormData(): Promise<void> {
  try {
    const res: any = await api.get(`/dbas/${accountId.value}`)
    dbasFormData.value = res
    if (res.dbasToDetails) {
      selectedTaskOrderNumber.value = res.dbasToDetails[0].taskOrderNumber
    }
  } catch (err) {
    errorAlerts.value.push('Error while loading this page. Please try again later.')
    console.error(err)
  }
}

watch(selectedTaskOrderNumber, (newValue: string) => {
  successAlerts.value = []
  errorAlerts.value = []
  dbasAssociationIdOfSelectedTaskOrder.value = ''
  displayTaxExemptError.value = false
  if (newValue) {
    displayTOError.value = false
  }
  const selectedTODetails = dbasFormData.value?.dbasToDetails?.filter(
    (dbasToDetail: any) => dbasToDetail.taskOrderNumber === newValue
  )
  let isDbasSentForSelectedTaskOrderAtleastOnce = false
  if (selectedTODetails?.length > 0) {
    isDbasSentForSelectedTaskOrderAtleastOnce = selectedTODetails[0].dbasAssociationId
      ? true
      : false
  }
  if (isDbasSentForSelectedTaskOrderAtleastOnce) {
    dbasAssociationIdOfSelectedTaskOrder.value = selectedTODetails[0].dbasAssociationId
    selectedTaxExempt.value = selectedTODetails[0].taxExemptIndicator
  } else {
    selectedTaxExempt.value = ''
  }
})

watch(selectedTaxExempt, (newValue: string) => {
  if (newValue) {
    displayTaxExemptError.value = false
  }
})
</script>
<template>
  <div class="chi-card -no-border -mt--1">
    <div class="chi-card__content">
      <div class="-mb--3">
        <div class="chi-grid">
          <div class="chi-col"><label class="chi-label -md">Agency</label></div>
          <div class="chi-col"><label class="chi-label -md">Contract Number</label></div>
        </div>
        <div class="chi-grid">
          <div class="chi-col">
            {{
              dbasFormData.agencyName +
              '-' +
              dbasFormData.bureauName +
              (dbasFormData.subBureauName ? '-' + dbasFormData.subBureauName : '') +
              '-' +
              dbasFormData.agencyIdentifier
            }}
          </div>
          <div class="chi-col">{{ dbasFormData.contractnumber }}</div>
        </div>
      </div>
      <div class="-mb--3">
        <div class="chi-grid">
          <div class="chi-col"><label class="chi-label -md">BAN</label></div>
          <div class="chi-col"><label class="chi-label -md">Agency Identifier Code</label></div>
        </div>
        <div class="chi-grid">
          <div class="chi-col">{{ dbasFormData.ban }}</div>
          <div class="chi-col">{{ dbasFormData.agencyIdentifier }}</div>
        </div>
      </div>
      <div class="-mb--3">
        <div class="chi-grid">
          <div class="chi-col">
            <label class="chi-label -md"
              >Task Order Number
              <abbr class="chi-label__required" aria-label="Required field">*</abbr></label
            >
          </div>
          <div class="chi-col">
            <label class="chi-label -md"
              >Tax Exempt
              <abbr class="chi-label__required" aria-label="Required field">*</abbr></label
            >
          </div>
        </div>
        <div class="chi-grid">
          <div class="chi-col">
            <div class="-w-md--75">
              <select
                v-model="selectedTaskOrderNumber"
                class="chi-select -sm"
                :class="{ '-danger': displayTOError }"
                required
              >
                <option
                  v-if="dbasFormData.dbasToDetails && dbasFormData.dbasToDetails.length > 1"
                  value=""
                >
                  - Select -
                </option>
                <option v-for="option in dbasFormData.dbasToDetails" :key="option.taskOrderNumber">
                  {{ option.taskOrderNumber }}
                </option>
              </select>
            </div>
            <div v-show="displayTOError" class="chi-label -status -danger">
              <i class="chi-icon icon-circle-warning" aria-hidden="true"></i>
              Please select an option
            </div>
          </div>
          <div class="chi-col">
            <fieldset>
              <div class="chi-picker-group">
                <div class="chi-picker-group__content">
                  <input
                    id="taxExemptYes"
                    v-model="selectedTaxExempt"
                    value="Y"
                    class="chi-picker__input"
                    type="radio"
                  />
                  <label for="taxExemptYes">Yes</label>
                  <input
                    id="taxExemptNo"
                    v-model="selectedTaxExempt"
                    value="N"
                    class="chi-picker__input"
                    type="radio"
                  />
                  <label for="taxExemptNo">No</label>
                </div>
              </div>
            </fieldset>
            <div v-show="displayTaxExemptError" class="chi-label -status -danger">
              <i class="chi-icon icon-circle-warning" aria-hidden="true"></i>
              Please select an option
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chi-divider"></div>
</template>
