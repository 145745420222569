<script setup lang="ts">
import { ref, computed } from 'vue'
import {
  Row,
  ReadOnlyCell,
  Column,
  SettingsType,
  InputType,
  EditCell,
  Tooltip,
  randomId
} from '@centurylinkfederal/eis-vue'

export type Inputs = {
  [key: string]: InputType
}
export type Count = {
  current: number
  total: number
}
export interface Props {
  id?: string
  class?: string
  columns?: Column[]
  settings?: SettingsType
  inputs?: Inputs
  row?: Row
  classColDefault?: string
  count?: Count
  badges?: any[]
  checked?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  id: undefined,
  class: undefined,
  columns: () => {
    return []
  },
  settings: () => {
    return {}
  },
  inputs: () => {
    return {}
  },
  row: () => {
    return {}
  },
  badges: () => [],
  count: undefined,
  classColDefault: '-w-xl-2h--3 -w-lg--3 -w-md--4 -w--6',
  checked: undefined
})
const emit = defineEmits(['onInput', 'onDelete', 'onRadio'])
const className = ref(props.class)
const cid = ref(randomId())
const columnsFiltered = computed(() => props.columns.filter((e) => !e.hidden && !e.input?.hidden))
function onInput(newValue: any, payload: any): void {
  emit('onInput', newValue, payload)
}
function onDelete(): void {
  emit('onDelete')
}
function onRadio(): void {
  emit('onRadio')
}
</script>
<template>
  <div :id="id" class="chi-card" :class="className">
    <div
      class="-xs -d--flex -justify-content--between -position--absolute"
      style="top: calc(50% - 1rem)"
    >
      <div v-if="settings.radio" class="chi-radio -ml--2">
        <input
          :id="'radio-' + cid"
          :name="'radio-' + cid"
          :checked="checked"
          class="chi-radio__input"
          type="radio"
          @click="onRadio()"
        />
        <label class="chi-radio__label" :for="'radio-' + cid"></label>
      </div>
    </div>
    <div class="chi-card__content -p--0 -px--2 -pt--3 -pb--2">
      <div class="grid-wrapper">
        <div class="chi-grid -mx--2">
          <div
            :class="[
              cell.class,
              {
                [' -mb--3 chi-col ' + (cell.classCol ?? classColDefault)]:
                  !cell.divider && !cell.groupTitle,
                'chi-col -w--12': cell.divider || cell.groupTitle
              }
            ]"
            v-for="(cell, index) in columnsFiltered"
            :key="index"
          >
            <div
              :class="['chi-divider', cell.class, { '-mb--2': !cell.dividerClass }]"
              v-if="cell.divider"
            />
            <div
              :class="[cell.class, { '-text--h4 -mt--0 -mb--3': !cell.groupTitleClass }]"
              v-if="cell.groupTitle"
              v-html="cell.groupTitle"
            />
            <ReadOnlyCell
              v-else-if="!cell.input"
              :cell="cell"
              :row="row"
              :keyValue="String(row?.[settings.key] ?? '')"
              :onAction="() => null"
            />
            <EditCell
              v-else-if="cell.input && !cell.input.hidden"
              :cell="cell"
              :row="row"
              :input="inputs?.[String(cell.field)]"
              @onInput="
                (newValue: any) =>
                  onInput(newValue, {
                    key: row?.[settings.key],
                    field: cell.field,
                    opts: cell.input
                  })
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="-xs -d--flex -justify-content--between -position--absolute -w--100"
      style="bottom: 0.25rem; min-height: 33.2px; min-width: 4rem"
    >
      <button class="chi-button -flat -disabled -sm -px--1 -mx--1">
        <span v-if="count">{{ count?.current }} / {{ count?.total }}</span>
      </button>
      <div class="-pr--2" style="min-width: 6rem">
        <div
          v-for="(badge, index) in badges"
          :key="index"
          :class="['chi-badge -sm -ml--1 -mt--1', '-' + badge.color]"
        >
          <span>{{ badge.text }}</span>
        </div>
      </div>
      <div style="min-width: 2.5rem">
        <Tooltip v-if="!settings?.noDelete" content="Delete" placement="left">
          <button class="chi-button -danger -flat -sm -px--1" @click="onDelete()">
            <div class="chi-button__content">
              <i class="chi-icon icon-delete" aria-hidden="true"></i>
            </div>
          </button>
        </Tooltip>
      </div>
    </div>
  </div>
</template>
<style scoped>
.chi-col.-w--0 {
  min-width: 0px;
}
</style>
