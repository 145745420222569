import { ref, Ref } from 'vue'
import { defineStore } from 'pinia'
import { useForm } from '../../composables/form'

export const useStore = defineStore('createTaskOrderKpisStore', () => {
  const resetSeq = ref(0)
  const picks: Ref<Set<string | number>> = ref(new Set())
  const quirks: Ref<Map<string | number, any>> = ref(new Map())
  const kpiStandardMap: Ref<Map<string | number, any>> = ref(new Map())
  const kpisStandardInServiceMap: Ref<Map<string | number, any>> = ref(new Map())
  const kpisInServiceMap = ref(new Map())
  const { form, formState, formChanges, isFormEnabled } = useForm()

  function flush(): void {
    picks.value = new Set()
    quirks.value = new Map()
    form.value = {}
    formState.value = undefined
    kpiStandardMap.value = new Map()
    kpisStandardInServiceMap.value = new Map()
    kpisInServiceMap.value = new Map()
  }

  return {
    resetSeq,
    form,
    formState,
    formChanges,
    isFormEnabled,
    picks,
    quirks,
    kpiStandardMap,
    kpisStandardInServiceMap,
    kpisInServiceMap,
    flush
  }
})
