<script lang="ts" setup>
import { defineProps, defineEmits, ref } from 'vue'

interface IProps {
  title: string
  initialState: {
    numberOfDays: string
    typeOfDays: string
  }
}

const props = defineProps<IProps>()

const $emit = defineEmits<{
  (e: 'close'): void
  (e: 'save', value: { numberOfDays: string; typeOfDays: string }): void
}>()

const numberOfDays = ref(props.initialState.numberOfDays || '')
const typeOfDays = ref(props.initialState.typeOfDays || 'calendar')

const isFormValid = ref(
  Number(numberOfDays.value) > 0 &&
    (typeOfDays.value === 'calendar' || typeOfDays.value === 'business')
)

function actionButtonClicked($event: number): void {
  if ($event === 0 && isFormValid.value) {
    $emit('save', {
      typeOfDays: typeOfDays.value,
      numberOfDays: numberOfDays.value
    })
  }
}

function closeModal(): void {
  $emit('close')
}

function validateNumber(event: Event) {
  const input = event.target as HTMLInputElement
  input.value = input.value.replace(/\D/g, '')
  numberOfDays.value = input.value
  isFormValid.value =
    Number(numberOfDays.value) > 0 &&
    (typeOfDays.value === 'calendar' || typeOfDays.value === 'business')
}

function onTypeOfDaysChange(value: string) {
  typeOfDays.value = value
  isFormValid.value =
    Number(numberOfDays.value) > 0 &&
    (typeOfDays.value === 'calendar' || typeOfDays.value === 'business')
}
</script>

<template>
  <div class="chi-backdrop -center">
    <div class="chi-backdrop__wrapper">
      <section class="chi-modal" role="dialog" aria-modal="true">
        <header class="chi-modal__header">
          <h2 class="chi-modal__title">{{ props.title }}</h2>
          <button class="chi-button -icon -close" @click="closeModal">
            <div class="chi-button__content">
              <i class="chi-icon icon-x" aria-hidden="true"></i>
            </div>
          </button>
        </header>
        <div class="chi-modal__content modal-content">
          <div class="-d--flex -flex--column">
            <p>Enter TO-specific Test and Acceptance Period in Days</p>
            <div class="chi-form__item">
              <label class="chi-label">
                Type of Days<abbr class="chi-label__required" title="Required field">*</abbr>
              </label>
              <div class="chi-button-group ipp-group">
                <button
                  class="chi-button ipp-option-button"
                  :class="{ 'select-ipp-option': typeOfDays === 'calendar' }"
                  style="text-transform: capitalize"
                  @click="onTypeOfDaysChange('calendar')"
                >
                  Calendar
                </button>
                <button
                  class="chi-button ipp-option-button"
                  :class="{ 'select-ipp-option': typeOfDays === 'business' }"
                  style="text-transform: capitalize"
                  @click="onTypeOfDaysChange('business')"
                >
                  Business
                </button>
              </div>
            </div>
            <div class="chi-form__item">
              <label class="chi-label" for="number-of-days">
                Number of Days <abbr class="chi-label__required" title="Required field">*</abbr>
              </label>
              <input
                id="number-of-days"
                v-model="numberOfDays"
                class="chi-input"
                type="text"
                maxlength="2"
                @input="validateNumber"
              />
            </div>
          </div>
        </div>
        <footer class="chi-modal__footer">
          <button class="chi-button" @click="closeModal">Cancel</button>
          <button
            class="chi-button -primary"
            @click="actionButtonClicked(0)"
            :disabled="!isFormValid"
          >
            Save
          </button>
        </footer>
      </section>
    </div>
  </div>
</template>

<style scoped>
.chi-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}
.chi-modal {
  max-width: 500px;
  margin: 20px;
}
.modal-content {
  padding: 1rem;
}
.ipp-group {
  height: 32px;
  margin-left: 0;
}
.ipp-option-button {
  border: 0.0625rem solid #d0d4d9;
  color: #212529;
  text-transform: capitalize;
}
.select-ipp-option {
  border: 0.15rem solid #0075c9 !important;
  color: #0075c9 !important;
}
</style>
