<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useApi } from '@centurylinkfederal/eis-vue'
import { useRouter } from 'vue-router'
import { useLookupSelectionStore, LookupSelection } from '../store/lookup'

const lookupSelectionStore = useLookupSelectionStore()

const router = useRouter()
const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)
const showAccountLookup = ref(true)
const selectedBan = ref('-1')
const selectedTaskOrder = ref('-1')
const banList = ref([])
const taskOrderNumberMap = ref([])

const accountDTO = ref([])
const banResponse: any = ref(null)
const accountResponse: any = ref(null)
const hasEditPermission = ref(false)

const accountId = ref('')
const taskOrderId = ref('')

onMounted(async () => {
  await fetchData()
})

const fetchData = async () => {
  try {
    const response: any = await api.get('/accounts')
    banList.value = response.accounts
    taskOrderNumberMap.value = response.taskOrders
    const existingSelection: LookupSelection = lookupSelectionStore.lookupSelection

    if (existingSelection?.taskOrder !== '-1') {
      selectedTaskOrder.value = existingSelection?.taskOrder
      submitLookupAccountForm('taskOrder')
    }

    if (existingSelection?.billingAccount !== '-1') {
      selectedBan.value = existingSelection?.billingAccount
      submitLookupAccountForm('ban')
    }
  } catch (error) {
    console.error('Error fetching initial data', error)
  }
}

async function submitLookupAccountForm(type: string) {
  if (
    (type === 'ban' && selectedBan.value === '-1') ||
    (type === 'taskOrder' && selectedTaskOrder.value === '-1')
  ) {
    accountDTO.value = []
    banResponse.value = null
    accountResponse.value = null
    return
  }
  try {
    if (type === 'ban') {
      const response: any = await api.get(`/accounts-by-ban/${selectedBan.value}`)
      accountDTO.value = response
      banResponse.value = response
      selectedTaskOrder.value = '-1'
      accountResponse.value = null
      hasEditPermission.value = Array.isArray(response)
        ? response?.[0].permissions.includes('edit')
        : response?.permissions.includes('edit')
      lookupSelectionStore.updateLookupSelection(selectedBan.value, 'billingAccount')
      lookupSelectionStore.clearSelection('taskOrder')
    }
    if (type === 'taskOrder') {
      const response: any = await api.get(`/accounts-by-task-order/${selectedTaskOrder.value}`)
      accountDTO.value = response
      accountResponse.value = Array.isArray(response) ? response : [response]
      banResponse.value = null
      selectedBan.value = '-1'
      hasEditPermission.value = Array.isArray(response)
        ? response?.[0].permissions.includes('edit')
        : response?.permissions.includes('edit')
      lookupSelectionStore.updateLookupSelection(selectedTaskOrder.value, 'taskOrder')
      lookupSelectionStore.clearSelection('billingAccount')
    }
    if (Array.isArray(banResponse.value)) {
      accountId.value = banResponse.value[0]?.accountId
    } else if (banResponse.value) {
      accountId.value = banResponse.value?.accountId
    }
    if (Array.isArray(accountResponse.value)) {
      accountId.value = accountResponse.value[0]?.accountId
    } else if (accountResponse.value) {
      accountId.value = accountResponse.value?.accountId
    }
    taskOrderId.value = selectedTaskOrder.value
  } catch (error) {
    console.error('Error submitting account lookup form', error)
  }
}

const navigateToaccountEdit = (accountResponse: any) => {
  if (Array.isArray(accountResponse)) {
    router.push(`/agencyAhcAccountAdminFlow/edit-account/${accountResponse[0]?.accountId}`)
  } else if (accountResponse) {
    router.push(`/agencyAhcAccountAdminFlow/edit-account/${accountResponse?.accountId}`)
  }
}
const navigateToBanEdit = (banResponse: any) => {
  if (Array.isArray(banResponse)) {
    router.push(`/agencyAhcAccountAdminFlow/edit-account/${banResponse[0].accountId}`)
  } else if (banResponse) {
    router.push(`/agencyAhcAccountAdminFlow/edit-account/${banResponse.accountId}`)
  }
}
</script>
<template>
  <div class="container -pt--2 -pd--4">
    <!-- Account Lookup Section -->
    <div class="chi-grid -pl--2">
      <div class="chi-col -w--6">
        <label>By Billing Account</label>
        <select class="chi-select" v-model="selectedBan" @change="submitLookupAccountForm('ban')">
          <option value="-1">-- Select One --</option>
          <option v-for="ban in banList" :key="ban" :value="ban">{{ ban }}</option>
        </select>
      </div>
      <div class="chi-col -w--6 -pl--4 -pr--4">
        <label>By Task Order</label>
        <select
          class="chi-select"
          v-model="selectedTaskOrder"
          @change="submitLookupAccountForm('taskOrder')"
        >
          <option value="-1">-- Select One --</option>
          <option v-for="taskOrder in taskOrderNumberMap" :key="taskOrder" :value="taskOrder">
            {{ taskOrder }}
          </option>
        </select>
      </div>
    </div>
    <div class="chi-grid -p--2">
      <div class="chi-col -w--12" v-if="accountDTO && banResponse != null">
        <table class="chi-table -striped -p--1">
          <thead class="">
            <tr>
              <th>Billing Account</th>
              <th>Task Order</th>
              <th>Billing Contact</th>
              <th>Billing Address</th>
              <th>Billing Email</th>
              <th>Created Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-if="banResponse != null" id="searchResultsBody">
            <tr>
              <td>
                {{
                  banResponse &&
                  (Array.isArray(banResponse) && banResponse.length > 0
                    ? banResponse[0]?.billingAccount
                    : banResponse?.billingAccount)
                }}
              </td>
              <td class="text-break">
                {{
                  banResponse &&
                  (Array.isArray(banResponse)
                    ? banResponse.map((item) => item.taskOrder).join(', ')
                    : banResponse.taskOrder)
                }}
              </td>
              <td>
                {{
                  banResponse &&
                  (Array.isArray(banResponse)
                    ? banResponse[0]?.billingContact
                    : banResponse?.billingContact)
                }}
              </td>
              <td>
                {{
                  banResponse &&
                  (Array.isArray(banResponse)
                    ? banResponse[0]?.billingAddress
                    : banResponse?.billingAddress)
                }}
              </td>
              <td>
                {{
                  banResponse &&
                  (Array.isArray(banResponse)
                    ? banResponse[0]?.billingEmail
                    : banResponse?.billingEmail)
                }}
              </td>
              <td>
                {{
                  banResponse &&
                  (Array.isArray(banResponse) ? banResponse[0]?.createdOn : banResponse?.createdOn)
                }}
              </td>
              <td>
                <a style="white-space: pre" @click="navigateToBanEdit(banResponse)">
                  {{ hasEditPermission ? 'View/Edit Account' : 'View Account' }}
                </a>
                <br />
                <router-link :to="{ name: 'ViewSendDBAS', params: { accountId, taskOrderId } }"
                  >View/Send DBAS</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="chi-grid -p--2">
      <div class="chi-col -w--12" v-if="accountDTO && accountResponse != null">
        <table class="chi-table -striped -p--1">
          <thead class="">
            <tr>
              <th>Billing Account</th>
              <th>Task Order</th>
              <th>Billing Contact</th>
              <th>Billing Address</th>
              <th>Billing Email</th>
              <th>Created Date</th>
              <th>View/Edit</th>
            </tr>
          </thead>
          <tbody v-if="accountResponse != null" id="searchResultsBody">
            <tr>
              <td>
                {{
                  accountResponse &&
                  (Array.isArray(accountResponse) && accountResponse.length > 0
                    ? accountResponse[0]?.billingAccount
                    : accountResponse?.billingAccount)
                }}
              </td>
              <td class="text-break">
                {{
                  accountResponse &&
                  (Array.isArray(accountResponse)
                    ? accountResponse.map((item) => item.taskOrder).join(', ')
                    : accountResponse.taskOrder)
                }}
              </td>
              <td>
                {{
                  accountResponse &&
                  (Array.isArray(accountResponse)
                    ? accountResponse[0]?.billingContact
                    : accountResponse?.billingContact)
                }}
              </td>
              <td>
                {{
                  accountResponse &&
                  (Array.isArray(accountResponse)
                    ? accountResponse[0]?.billingAddress
                    : accountResponse?.billingAddress)
                }}
              </td>
              <td>
                {{
                  accountResponse &&
                  (Array.isArray(accountResponse)
                    ? accountResponse[0]?.billingEmail
                    : accountResponse?.billingEmail)
                }}
              </td>
              <td>
                {{
                  accountResponse &&
                  (Array.isArray(accountResponse)
                    ? accountResponse[0]?.createdOn
                    : accountResponse?.createdOn)
                }}
              </td>
              <td>
                <a @click="navigateToaccountEdit(accountResponse)">{{
                  hasEditPermission ? 'View/Edit Account' : 'View'
                }}</a>
                <br />
                <router-link :to="{ name: 'ViewSendDBAS', params: { accountId, taskOrderId } }"
                  >View/Send DBAS</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
td {
  border: 0.0625rem solid #dadee2 !important;
}

th {
  border: 0.0625rem solid #dadee2 !important;
}

span:hover {
  text-decoration: underline !important;
  cursor: pointer;
}
</style>
