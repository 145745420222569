<!-- eslint-disable no-console -->
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useEisUser } from '@centurylinkfederal/eis-vue'
import { useNewsDashboard } from '../../store/news/newsDashboardStore'
import { OpenAPI } from '../../openapi/clients/admin-ui-service-api'
import Cookies from 'js-cookie'

const props = defineProps<{
  create: boolean
  data?: any
}>()
const emit = defineEmits(['close', 'newsCreated'])
const eisUser = useEisUser()
const newsDashboardStore = useNewsDashboard()
const { createNews, updateNews } = newsDashboardStore
const user = eisUser.user
const newsTitle = ref('')
const newsDesc = ref('')
const newsTitleError = ref(false)
const newsDescError = ref(false)
const createToggle = ref(true)
const editToggle = ref(false)
const maxCommentLimit = ref(1000)
const commentLimit = ref(1000)
const formattedModifiedDate = ref('')

function cancel() {
  emit('close')
}

function fieldValidations() {
  newsTitleError.value = newsTitle.value.length === 0
  newsDescError.value = newsDesc.value.length === 0
}
function titleCheck() {
  newsTitleError.value = newsTitle.value.length === 0
}
function descriptionCheck() {
  newsDescError.value = newsDesc.value.length === 0
  commentLimit.value = maxCommentLimit.value - newsDesc.value.length
}
const getAuthToken = (): string => {
  OpenAPI.TOKEN = Cookies.get('eis.portal.token')
  return OpenAPI.TOKEN as string
}
async function onCreateNews() {
  const authToken = await getAuthToken()
  const currentDate = new Date()
  const formattedDate = currentDate.toISOString()
  if (!props.create) {
    const modifiedCurrentDate = new Date(props.data.modifiedOn)
    formattedModifiedDate.value = modifiedCurrentDate.toISOString()
  }

  if (newsTitle.value !== '' && newsDesc.value !== '') {
    const createNewsBody = {
      newsEventId: props.create ? 0 : props.data.id,
      newsTitle: newsTitle.value,
      newsBody: newsDesc.value,
      isActive: props.create ? (createToggle.value ? 'Y' : 'N') : editToggle.value ? 'Y' : 'N',
      createdOn: props.create ? formattedDate : formattedModifiedDate.value,
      updatedOn: formattedDate,
      createdByUserName: user?.value?.userName,
      updatedByUserName: user?.value?.userName
    }
    if (props.create) {
      await createNews(authToken, createNewsBody)
    } else {
      await updateNews(authToken, createNewsBody)
    }

    emit('newsCreated')
  } else {
    fieldValidations()
  }
}
function changeCreateToggle() {
  createToggle.value = !createToggle.value
}
function changeEditToggle() {
  editToggle.value = !editToggle.value
}
function removeSpace() {
  newsTitle.value = newsTitle.value.trim()
  newsDesc.value = newsDesc.value.trim()
}
onMounted(() => {
  if (!props.create) {
    newsTitle.value = props.data.title
    newsDesc.value = props.data.description
    editToggle.value = props.data.isActive === 'Y'
    commentLimit.value = maxCommentLimit.value - newsDesc.value.length
  }
})
</script>

<template>
  <div>
    <div class="chi-grid">
      <div class="chi-col -w--12 -mb--4">
        <div class="chi-form__item">
          <label class="chi-label" for="title__input"
            >Title <abbr class="chi-label__required" aria-label="Required field">*</abbr></label
          >
          <input
            type="text"
            class="chi-input"
            :class="{
              '-danger': newsTitleError
            }"
            id="title__input"
            v-model="newsTitle"
            required
            @input="titleCheck()"
            @blur="removeSpace()"
            maxlength="100"
          />
          <div v-if="newsTitleError" class="chi-label -status -danger">
            <i class="chi-icon icon-circle-warning" aria-hidden="true"></i>
            Please enter Title
          </div>
        </div>
      </div>
      <div class="chi-col -w--12 -mb--4">
        <div class="chi-form__item">
          <div class="chi-grid -d--flex">
            <label class="chi-label chi-col -w--6" for="description__input"
              >Description
              <abbr class="chi-label__required" aria-label="Required field">*</abbr></label
            ><span class="chi-col -w--6 -text--right">max. char {{ maxCommentLimit }}</span>
          </div>

          <textarea
            class="chi-input descriptionTextArea"
            :class="{
              '-danger': newsDescError
            }"
            id="description__input"
            required
            v-model="newsDesc"
            @input="descriptionCheck()"
            @blur="removeSpace()"
            :maxlength="maxCommentLimit"
          ></textarea>
          <div
            class="-text--italic"
            :class="commentLimit !== 0 ? '-text--grey' : '-text--danger'"
            for="description__input"
          >
            {{ commentLimit }} charcters left
          </div>
          <div v-if="newsDescError" class="chi-label -status -danger">
            <i class="chi-icon icon-circle-warning" aria-hidden="true"></i>
            Please enter Description
          </div>
        </div>
      </div>
      <div class="chi-col -w--12" v-if="create">
        <div class="chi-form__item">
          <label class="chi-switch" for="toggle-ch1">
            <input
              type="checkbox"
              class="chi-switch__input"
              id="toggle-ch1"
              :checked="createToggle"
              @click="changeCreateToggle()"
            />
            <span class="chi-switch__content">
              <span class="chi-switch__thumb"></span>
            </span>
            <span class="chi-switch__label">Publish</span>
          </label>
        </div>
      </div>
      <div class="chi-col -w--12" v-else>
        <div class="chi-form__item">
          <label class="chi-switch" for="toggle-ch2">
            <input
              type="checkbox"
              class="chi-switch__input"
              id="toggle-ch2"
              :checked="editToggle"
              @click="changeEditToggle()"
            />
            <span class="chi-switch__content">
              <span class="chi-switch__thumb"></span>
            </span>
            <span class="chi-switch__label">Publish</span>
          </label>
        </div>
      </div>
      <div class="chi-col -w--12 -position--fixed-bottom -mb--2">
        <div class="-d--flex -justify-content--end">
          <button id="cancelButton" class="chi-button -mr--1" @click="cancel" title="Cancel">
            Cancel
          </button>
          <button
            id="createButton"
            class="chi-button -primary"
            @click="(onCreateNews(), fieldValidations())"
          >
            <span v-if="create">Create</span><span v-else>Update</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.descriptionTextArea {
  height: 150px;
}
</style>
