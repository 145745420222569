<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import SearchAutoComplete from '../../components/common/SearchAutoComplete.vue'
import { useTOAdminStore } from '../../store/to-admin'
import { useToAdminAPI } from '../../composables/to-admin/api'
import TODetails from '../../components/to-admin/TODetails.vue'
import AgencyAssociatedTO from '../../components/to-admin/AgencyAssociatedTO.vue'
import ExportDetailsSet from '../../components/to-admin/ExportDetailSet.vue'

import { storeToRefs } from 'pinia'
const toAdminStore = useTOAdminStore()
const api = useToAdminAPI()
const taskOrderOptionsByAgency = ref<any[]>([])
const loading = ref<boolean>(false)
const { agencies, taskOrderDropdownOptions, selectedTO } = storeToRefs(toAdminStore)
const taskOrderSearchValue = ref<string>('')
const agencySearchValue = ref<string>('')
const taskOrderSearchRef = ref()
const exportDateData = ref<any>({})

onMounted(async () => {
  await toAdminStore.fetchTaskOrders()
})

function resetSearchValues() {
  taskOrderSearchValue.value = ''
  agencySearchValue.value = ''
  taskOrderSearchRef.value.clearContent()
  taskOrderOptionsByAgency.value = []
  selectedTO.value = ''
}

async function onTaskOrderSelect(item: any): Promise<void> {
  selectedTO.value = item
  const filterAgency = taskOrderDropdownOptions.value.filter(
    (it: any) => it.taskOrderNumber === item.taskOrderNumber
  )
  if (filterAgency) {
    item.agencyIdentifier = filterAgency[0].agencyIdentifier
  }
  await fetchAccordionData(item)
}

async function fetchAccordionData(record: any) {
  try {
    loading.value = true
    await toAdminStore
      .fetchTaskOrderToEdit(record.agencyIdentifier, record.taskOrderId)
      .catch((err) => err)
    const exportDateDetails: any = await toAdminStore
      .getExportUpdatedDates(record.taskOrderId)
      .catch((err) => err)
    selectedTO.value = toAdminStore.selectedTO
    exportDateData.value = exportDateDetails?.results?.[0]
    loading.value = false
  } catch (e) {
    loading.value = false
  }
}

async function searchTO() {
  if (agencySearchValue.value) {
    const options = await toAdminStore.fetchTaskOrdersForAgency(agencySearchValue.value)
    taskOrderOptionsByAgency.value = options
  } else {
    const record = taskOrderDropdownOptions.value.find(
      (item) => item.id === taskOrderSearchValue.value
    )
    if (!record) {
      return
    }
    await fetchAccordionData(record)
  }
}

function taskOrderSearchValueChanged($event: any) {
  taskOrderSearchValue.value = $event
}

const disableSearchButtons = computed(() => !taskOrderSearchValue.value && !agencySearchValue.value)
const showRestOfTheForm = computed(() => !!selectedTO.value)
</script>
<template>
  <div>
    <div class="search-container -mt--2">
      <div class="-d--flex -justify-content--center -align-items--center">
        <div class="chi-form__item -row -flex--fill">
          <label class="chi-label" style="min-width: 200px">Task Order (TO) Number</label>
          <div style="position: relative; width: 100%">
            <SearchAutoComplete
              ref="taskOrderSearchRef"
              :options="taskOrderDropdownOptions"
              @valueChanged="taskOrderSearchValueChanged"
              :disable="!!agencySearchValue"
            />
          </div>
        </div>
        <div class="-mx--3">OR</div>
        <div class="chi-form__item -row -flex--fill">
          <label class="chi-label">Agency</label>
          <select class="chi-select" v-model="agencySearchValue" :disabled="!!taskOrderSearchValue">
            <option v-for="agency of agencies" :key="agency.value" :value="agency.value">
              {{ agency.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="-d--flex -justify-content--center -my--3" style="gap: 16px">
        <button class="chi-button" :disabled="disableSearchButtons" @click="searchTO">
          Search
        </button>
        <button class="chi-button" :disabled="disableSearchButtons" @click="resetSearchValues">
          Reset
        </button>
      </div>
    </div>
    <AgencyAssociatedTO
      v-if="taskOrderOptionsByAgency.length > 0"
      :taskOrders="taskOrderOptionsByAgency"
      @taskOrderSelect="onTaskOrderSelect"
    />
    <template v-if="showRestOfTheForm">
      <TODetails action="view" :items="selectedTO" />
      <ExportDetailsSet :exportDateData="exportDateData" :selectedTOID="selectedTO" />
    </template>
  </div>
</template>
