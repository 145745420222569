import { parseErr, useApi } from '@centurylinkfederal/eis-vue'
import { useRoute } from 'vue-router'

export function useApiActions() {
  const api = useApi(import.meta.env.VITE_EIS_API_BASE)
  const authApi = useApi(import.meta.env.VITE_EIS_AUTH_API)
  const route = useRoute()

  async function validateLastTenPassword(resetPassword: {
    userName: string
    password: string
  }): Promise<boolean> {
    let isInValid = false
    try {
      await api.post('/users/validate/reset-password', resetPassword, {
        Authorization: `Bearer ${route.query.token}`
      })
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      isInValid = true
    }
    return isInValid
  }
  async function resetPassword(resetPasswordRequest: {
    userName: string
    password: string
  }): Promise<boolean> {
    let isInValid = false
    try {
      await api.put('/users/reset-password', resetPasswordRequest, {
        Authorization: `Bearer ${route.query.token}`
      })
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      isInValid = true
    }
    return isInValid
  }

  async function getRuleAckDate(): Promise<any> {
    let isAcknowledgementNeeded = false
    try {
      const userData = await api.get<any>('/user-rules-accept-requirement')
      isAcknowledgementNeeded = userData.requireRulesAcceptance
    } catch (err: any) {
      throw new Error(parseErr(err))
    }
    return isAcknowledgementNeeded
  }

  async function saveUserAcknowledgement(): Promise<any> {
    let isSaved = false
    try {
      const result: boolean = await api.post('/rules-ack-logs', {})
      isSaved = result
    } catch (err: any) {
      isSaved = false
    }

    return isSaved
  }
  async function validateEmailToken(userName: string, token: string): Promise<boolean> {
    let isTokenValid = false
    try {
      await authApi.get<any>(`/users/${userName}/password-reset-token/validate`, {
        Authorization: `Bearer ${token}`
      })
      isTokenValid = true
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }

    return isTokenValid
  }

  return {
    validateLastTenPassword,
    resetPassword,
    getRuleAckDate,
    saveUserAcknowledgement,
    validateEmailToken
  }
}
