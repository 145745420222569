<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useApiActions } from '../composables/ApiActions'
import { useEisUser } from '@centurylinkfederal/eis-vue'

const show = ref(false)
const displayUnexpectedError = ref(false)
const isAgreedToTerms = ref(false)
const { getRuleAckDate } = useApiActions()

onMounted(async () => {
  show.value = await getRuleAckDate()
})

const eisUser = useEisUser()

const { saveUserAcknowledgement } = useApiActions()

async function acceptTerms(): Promise<void> {
  const saved = await saveUserAcknowledgement()
  if (saved) {
    show.value = false
  } else {
    displayUnexpectedError.value = true
  }
}

function declineTerms(): void {
  eisUser.logout()
}

function onAgreementCheckboxClick(): void {
  isAgreedToTerms.value = !isAgreedToTerms.value
}
</script>
<template>
  <!-- Modal -->
  <div class="chi-backdrop" :class="{ '-closed': !show }">
    <div class="chi-backdrop__wrapper">
      <section
        id="modal-3"
        class="chi-modal"
        role="dialog"
        aria-label="Modal description"
        aria-modal="true"
      >
        <header class="chi-modal__header">
          <h2 class="chi-modal__title">Rules of Behavior for Users</h2>
        </header>
        <div class="chi-modal__content -mh--400">
          Scope
          <p>
            This agreement applies to all users who have access to systems & portals located within
            Lumen managed secure FISMA boundaries which support the MTIPS, OSS (Networx), WITS3,
            EIS, and/or other government contracts.
          </p>
          <p>General User Agreement</p>
          <p>
            1. I understand that I have been given user access to government systems and that I have
            and will maintain the necessary clearance, suitability and/or authorizations for the
            general user access to which I am being authorized. I will comply with the high
            standards of conduct required of persons accessing these systems.
          </p>
          <p></p>
          <p>
            2. I have received the Information System Security Briefing and have had the opportunity
            to address my questions with my program Information System Security Officer (ISSO).
          </p>
          <p></p>
          <p>
            3. I will access only such data, control information, software, hardware and/or firmware
            for which I am authorized to have access and for which I have a need-to-know.
          </p>
          <p>4. I will use the information systems only for authorized purposes.</p>
          <p></p>
          <p>5. I will ensure my authenticator((key fob) is not using default settings</p>
          <p></p>
          <p>6. I will maintain possession of my authenticators (key fob) at all times</p>
          <p></p>
          <p>7. I will not loan or share my authenticator (key fob) with others</p>
          <p>
            8. I will protect my authenticators (key fob/passwords) and report any compromise or
            suspected compromise of my authenticators to my program ISSO.
          </p>
          <p>
            9. I will NOT share my password or log into any information system to provide access for
            another user.
          </p>
          <p>
            10. I will ensure all media and hard copy output is properly classified, marked,
            controlled, stored, transported and destroyed per Company or Sponsor guidelines.
          </p>
          <p>
            11. I will NOT introduce any malicious code or physically damage any Company or
            government furnished information system.
          </p>
          <p>
            12. I will NOT bypass, strain or test any security mechanisms of any Company or
            government information system. If security mechanisms must be bypassed for any reason, I
            shall coordinate the procedure with the ISSO and receive written permission prior to the
            occurrence.
          </p>
          <p>
            13. I am responsible for all activity that occurs on my individual account once my
            password has been used to log on. If I am a member of a group account, I am responsible
            for all activity when I am logged on a system with that account.
          </p>
          <p>
            14. I will utilize the password protected screen saver when I leave the line-of-sight of
            my system while logged in.
          </p>
          <p>
            15. I will immediately report any indication of computer network intrusion, unexplained
            degradation or interruption of system or network services, illegal or improper
            possession of content or files, or the actual or possible compromise of data, files,
            access controls, or systems to the program ISSO or designated representative.
          </p>
          <p>
            16. I will NOT install, modify, or remove any hardware or software (e.g.,
            freeware/shareware, security tools, etc.) without permission and approval from the
            program ISSO or designated representative on systems within the protected boundary.
          </p>
          <p>
            17. I am prohibited from obtaining, installing, copying, pasting, modifying,
            transferring, or using software or other materials obtained in violation of the
            appropriate vendor patent, copyright, trade-secret, or license agreements.
          </p>
          <p>
            18. I will NOT relocate or change any information system within the protected boundary
            to include network connectivity without proper security authorization.
          </p>
          <p>
            19. I will NOT introduce or use unauthorized software, firmware or hardware on any
            information systems within the protected boundary.
          </p>
          <p>
            20. I know that my actions as a user can greatly affect the security of the system and
            that my signature on this agreement indicates that I understand my responsibility as a
            user requires that I adhere to regulatory guidance.
          </p>
          <p>
            21. I am prohibited from storing, accessing, processing, sharing, removing, or
            distributing Proprietary, Sensitive, Privacy Act, and other protected or privileged
            information that violates established security and information release policies.
          </p>
          <p>
            22. I will contact the program ISSO or designated representative if I am in doubt as to
            any of my roles, responsibilities, or authorities.
          </p>

          <p>
            23. I understand all information processed on the information system is subject to
            monitoring at any time and there is neither the right nor expectation of privacy.
          </p>
          <p>
            24. I will comply with all information system security controls and immediately report
            control deficiencies to my management and program ISSO.
          </p>
          <p>
            25. I will immediately report all suspected security related information system problems
            or concerns to my program ISSO.
          </p>
          <p>
            26. I will inform my program ISSO and Government security officer when I no longer
            require access to any information system (e.g., upon completion of a project, transfer,
            retirement, resignation, etc.)
          </p>
          <p>
            27. I know that it is a violation of policy for any computer user to try to mask or hide
            his or her identity, or to try to assume the identity of someone else.
          </p>
          <p>
            28. I will not connect any personal IT equipment (for example, smartphones, personal
            computers, and digitally enabled devices) to the information system within the protected
            boundary.
          </p>
          <p>
            29. I am prohibited from accessing CTL managed governments systems while traveling
            outside of the United States. This includes accessing any CTL managed government web
            portals.
          </p>
          <p>
            30. I am prohibited from sharing or posting CTL, system, or customer information on
            social media/networking sites or other public websites.
          </p>

          <p>Non-compliance</p>
          <p>
            I understand failure to comply with the above requirements is a violation of the trust
            extended to me for system access and may result in any of the following actions:
          </p>
          <p>a. Revocation of information system user privileges</p>
          <p>b. Counseling and/or remedial training</p>
          <p>c. Adverse employment actions and/or criminal prosecution</p>
          <p>d. Discharge or loss of employment</p>
          <p>e. Revocation of Security Clearance and/or Public Trust Suitability</p>
          <p>Reporting</p>
          <p>
            All users of MTIPS, OSS (Networx), WITS3, EIS, and/or other government contracts and
            portals shall report any detected or possible violations with the terms of this document
            to CTL Government ISSO & Security immediately.
          </p>
          <p>Email: <a mail:to="Federal-IA@Lumen.com">Federal-IA@Lumen.com</a></p>
        </div>
        <footer class="chi-modal__footer">
          <div class="chi-form__item -mr--10">
            <div class="chi-checkbox">
              <input v-model="isAgreedToTerms" type="checkbox" class="chi-checkbox__input" />
              <label
                class="chi-checkbox__label"
                for="example__base"
                @click="onAgreementCheckboxClick"
                >Agree to Terms & Conditions</label
              >
            </div>
            <div v-if="displayUnexpectedError" class="chi-label -status -danger">
              There was an unexpected error. Please try again later.
            </div>
          </div>
          <button class="chi-button -mt--4" data-dismiss="modal" @click="declineTerms">
            DECLINE
          </button>
          <button
            class="chi-button -primary -mt--4"
            :class="{ '-disabled': !isAgreedToTerms }"
            @click="acceptTerms"
          >
            ACCEPT
          </button>
        </footer>
      </section>
    </div>
  </div>
</template>
