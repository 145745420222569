<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useStore as usePageStore } from './store'
import { useColumns } from './detailsColumnsReadOnly'
import { useToAdminAPI } from '../../composables/to-admin/api'
import Cells from '../common/Cells.vue'
import {
  Row,
  Input,
  InputCell,
  SingleCell,
  TextAreaCell,
  StateType
} from '@centurylinkfederal/eis-vue'
import { toEntries } from '../../utils/helpers'
import { alertTable } from '../../composables/alerts'

export interface Props {
  taskOrderId?: string | number
  agencyIdentifierEncoded?: string
  message?: string
  title?: string
  state?: StateType
}
const props = withDefaults(defineProps<Props>(), {
  taskOrderId: undefined,
  agencyIdentifierEncoded: undefined,
  message: undefined,
  title: undefined,
  state: undefined
})
const adminApi = useToAdminAPI()
const pageStore = usePageStore()
const { resetSeq, form, formState, formChanges, billingNotifications, alertMap } =
  storeToRefs(pageStore)
const columnsStore = useColumns()

const { columns } = storeToRefs(columnsStore)
const settings = {
  key: 'taskOrderId'
}
const row = ref<Row>({})
const cells = computed(() => Object.fromEntries(toEntries(columns.value, 'field')))
const isMounted = ref(false)

watch(
  () => [props.taskOrderId, props.agencyIdentifierEncoded],
  async () => {
    await setRow()
    setAlert()
  }
)
async function setRow(): Promise<void> {
  if (!props.agencyIdentifierEncoded || !props.taskOrderId) {
    // eslint-disable-next-line no-console
    console.warn('Missing taskOrderId or agencyIdentifierEncoded')
    return
  }
  const res = await adminApi.fetchTaskOrderDetails(props.agencyIdentifierEncoded, props.taskOrderId)
  row.value = res?.taskOrderDto ?? {}
}
function setAlert(): void {
  const { title, message, state } = props
  if (!message && !title) {
    return
  }
  const alertObj = alertTable[String(state)] ?? alertTable.muted
  alertMap.value.set('alert', {
    ...alertObj,
    title,
    message
  })
}
onMounted(async () => {
  await setRow()
  setAlert()
  isMounted.value = true
})
</script>

<template>
  <Cells
    v-if="row?.taskOrderId"
    :columns="columns"
    :settings="settings"
    :row="row"
    classColDefault="-w-xl--3 -w-lg--4 -w-md--6 -w--12"
  />
  <slot name="empty" v-if="!row?.taskOrderId && isMounted" />
</template>
