<script lang="ts" setup>
import {
  defineEmits,
  ref,
  defineProps,
  computed,
  withDefaults,
  onMounted,
  onBeforeUnmount,
  defineExpose
} from 'vue'

export interface IProps {
  options?: { title: string; id: string }[]
  disable?: boolean
}

const props = withDefaults(defineProps<IProps>(), { options: [] as any })

const $emit = defineEmits<{
  (e: 'valueChanged', value?: string): void
}>()

defineExpose({ clearContent })

const searchInput = ref<string>('')
const showOptions = ref<boolean>(false)
const dropdownContainerRef = ref<any>()
const inputRef = ref<any>()

onMounted(() => {
  document.addEventListener('click', onClickDetector)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', onClickDetector)
})

function onClickDetector(e: any) {
  if (
    dropdownContainerRef.value &&
    !dropdownContainerRef.value.contains(e.target) &&
    !inputRef.value.contains(e.target)
  ) {
    showOptions.value = false
  }
}

function onInput(e: any) {
  searchInput.value = e.target.value
  $emit('valueChanged', searchInput.value)
}

function onFocus() {
  showOptions.value = true
}

function clearContent() {
  searchInput.value = ''
  $emit('valueChanged', '')
}

function onOptionSelect($event: any) {
  const option = props.options?.find((item: any) => item.id === $event.id)
  searchInput.value = option?.title || ''
  $emit('valueChanged', option?.id)
  showOptions.value = false
}

const filteredOptions = computed(() => {
  return (
    props.options?.filter((item) =>
      item.title.toLowerCase().includes(searchInput.value.toLocaleLowerCase())
    ) || []
  )
})
</script>
<template>
  <div class="chi-dropdown -w--100">
    <div class="chi-form__item">
      <div class="chi-input__wrapper -icon--right">
        <input
          class="chi-input chi-search__input -md"
          type="search"
          ref="inputRef"
          :value="searchInput"
          :disabled="props.disable"
          @input="onInput"
          @focus="onFocus"
          aria-label="search input"
          style="padding-right: 4.75rem"
        />
        <button class="chi-button -icon -flat -md -bg--none" aria-label="Search">
          <div class="chi-button__content">
            <i class="chi-icon icon-search" aria-hidden="true"></i>
          </div>
        </button>
        <button
          class="chi-button -icon -close -sm"
          aria-label="Close"
          v-if="searchInput"
          style="top: 0.25rem"
          @click="clearContent"
        >
          <div class="chi-button__content">
            <i class="chi-icon icon-x"></i>
          </div>
        </button>
      </div>
    </div>
    <div
      class="chi-dropdown__menu"
      ref="dropdownContainerRef"
      :style="showOptions ? 'display:block' : ''"
      v-if="showOptions && searchInput && filteredOptions.length > 0"
    >
      <a
        class="chi-dropdown__menu-item"
        v-for="option in filteredOptions"
        :key="option.id"
        @click="onOptionSelect(option)"
        >{{ option.title }}</a
      >
    </div>
  </div>
</template>
