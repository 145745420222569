<script lang="ts" setup>
import { ToggleCell } from '@centurylinkfederal/eis-vue'
import { ref, watch, computed } from 'vue'
import { useToAdminAPI } from '../../composables/to-admin/api'
import { parse, format } from '@formkit/tempo'

interface IProps {
  taskOrderId?: string | number
}

const props = withDefaults(defineProps<IProps>(), {
  taskOrderId: undefined
})

const emit = defineEmits(['onSelect'])
const adminApi = useToAdminAPI()
const row = ref<any>({
  formId: 'setsToExportForm'
})
const settings = ref<any>({
  key: 'setsToExports'
})
const form = ref<any>({})
const exportItems = ref<any[]>([
  { id: 'toFinancialLastUpdated', label: 'Financials', field: 'toFinancials' },
  { id: 'toOfficialLastUpdated', label: 'Officials', field: 'toOfficial' },
  {
    id: 'toServicesAndClinsLastUpdated',
    label: 'Services and CLINs',
    field: 'toServicesAndClins'
  },
  { id: 'toClinsAwardedLastUpdated', label: 'CLINs Awarded', field: 'toClinsAwarded' },
  {
    id: 'toCountriesJuridisctionLastUpdated',
    label: 'Countries/Jurisdictions',
    field: 'toCountriesJurisdictions'
  },
  { id: 'toLocationsLastUpdated', label: 'NSCs', field: 'toLocations' },
  { id: 'toKpiLastUpdated', label: 'Key Performance Indicators', field: 'toKpi' }
])
const selectedSet = ref(new Set())
const cellsMap: any = computed(() => {
  return exportItems.value.reduce((result, item) => {
    result[item.id] = {
      field: item.field,
      input: {
        mode: 'checkbox',
        label: item.label,
        size: 'lg'
      }
    }
    return result
  }, {})
})
const exportDateDetails = ref<any>({})

watch(
  () => props.taskOrderId,
  async (taskOrderId) => {
    await fetchExportDates(taskOrderId)
  }
)

async function fetchExportDates(taskOrderId: string | number | undefined): Promise<void> {
  selectedSet.value.clear()
  if (!taskOrderId) {
    return
  }

  const response: any = await adminApi.getExportUpdatedDates(taskOrderId)
  exportDateDetails.value = response?.results?.[0] ?? {}
  for (const key in exportDateDetails.value) {
    const datePair = String(exportDateDetails.value[key]).split('+')
    let parsedDate
    try {
      parsedDate = parse(datePair[0])
    } catch (_err) {
      continue
    }
    if (!parsedDate) {
      continue
    }
    exportDateDetails.value[key] = format(parsedDate, 'MM/DD/YYYY h:mm A')
  }
}

function onExportSetChange(field: any, value: any) {
  if (value) {
    selectedSet.value.add(field)
  } else {
    selectedSet.value.delete(field)
  }
  emit('onSelect', field, value)
}
</script>
<template>
  <div>
    <p class="-text--h4">Export Options</p>
    <div class="-d--flex -flex--wrap">
      <div
        v-for="id in Object.keys(cellsMap)"
        :key="id"
        :class="[
          '-mr--3 -mb--3 -w-lg--25 -w-xl--20 chi-card',
          { '-active': selectedSet.has(cellsMap[id].field) }
        ]"
      >
        <div class="chi-card__content">
          <ToggleCell
            :cell="cellsMap[id]"
            :row="row"
            :input="form?.[row?.[settings.key]]?.[String(cellsMap[id].field)]"
            @onChange="($event) => onExportSetChange(cellsMap[id].field, $event)"
          />
          <div class="chi-divider -mt--3 -pt--2">
            <label class="chi-label">Last Updated</label>
            <div>
              {{ exportDateDetails[id] ?? 'N/A' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
