export type NewsEventDTO = {
  newsEventId?: number
  newsTitle: string
  newsBody: string
  isActive?: NewsEventDTO.isActive
  createdOn?: string
  updatedOn?: string
  createdByUserName?: string
  updatedByUserName?: string
}
export namespace NewsEventDTO {
  export enum isActive {
    Y = 'Y',
    N = 'N'
  }
}
