<script lang="ts" setup>
import { ref, watch, computed, toRaw, readonly } from 'vue'
import { storeToRefs } from 'pinia'
import { useStore } from './modsStore'
import { useColumns } from './modColumns'
import DataCard from '../common/DataCard.vue'
import ActionButton from '../common/ActionButton.vue'
import { Input, randomId, fromMMDDYYYY, slice } from '@centurylinkfederal/eis-vue'
import { doScrollToElement } from '../../utils/ui'
import { useToAdminAPI } from '../../composables/to-admin/api'
import { toMMDDYYYY } from '../../utils/date'
import { getStore } from './store'
import moment from 'moment'

const store = useStore()
const { form, rows } = storeToRefs(store)
export interface Props {
  pageMode: 'create' | 'edit'
}
const props = withDefaults(defineProps<Props>(), {
  pageMode: 'create'
})
const pageMode = ref(props.pageMode)
const pageStore = getStore(pageMode.value)()
const { selectedTaskOrderObj, taskOrderDetailsRes, resetSeq, signal } = storeToRefs(pageStore)
const columnsStore = useColumns()
const { columns, columnsOriginal, columnsNew, columnsEdit, columnsReadOnly } =
  storeToRefs(columnsStore)
const settings = {
  key: 'taskOrderId',
  footerLabelClass: '-d--none -d-md--inline'
}

const emptyDataId = ref('emptyData-' + randomId())
const checked = ref<string | number | undefined>()
const activeRow = ref<any>({})
const scrollOpts = { wait: 100, left: 0, useOffsetHight: true }
const adminApi = useToAdminAPI()
const columnsByMode = computed(() =>
  Object({
    original: columnsOriginal.value,
    new: columnsNew.value,
    edit: columnsEdit.value,
    readonly: columnsReadOnly.value
  })
)
const rowColumns = computed(() => {
  const entries = rows.value.map((row) => {
    const rowMode = getRowMode(row, taskOrderDetailsRes.value?.taskOrderDto)
    return [row[settings.key], columnsByMode.value[rowMode]]
  })
  return Object.fromEntries(entries)
})
const newRows = computed(() =>
  rows.value.filter((row) => String(row?.[settings.key]).includes('_new-'))
)
watch(
  () => resetSeq.value,
  async () => await resetForm()
)
function getRowMode(row: any, dto: any = {}): 'original' | 'new' | 'edit' | 'readonly' {
  let mode: 'original' | 'new' | 'edit' | 'readonly' = 'readonly'
  if (row.taskOrderId === dto.taskOrderId && dto.isCurrent === 'Y') {
    mode = 'edit'
  }
  if (!row.taskOrderModIterationId && !String(row[settings.key]).includes('_new-')) {
    mode = 'original'
  }
  if (String(row[settings.key]).includes('_new-')) {
    mode = 'new'
  }
  return mode
}
function makeRowBadges(row: any, dto: any = {}): any[] {
  const mode = getRowMode(row, dto)
  const badges = []
  if (
    (row.taskOrderId === dto.taskOrderId && dto.isCurrent === 'Y') ||
    row.taskOrderId === activeRow.value?.taskOrderId
  ) {
    badges.push({ text: 'Active', color: 'accent-2' })
  } else {
    badges.push({ text: 'Inactive', color: 'muted' })
  }
  if (mode === 'original') {
    badges.push({ text: 'Original', color: 'original' })
  }
  if (mode === 'new') {
    badges.push({ text: 'Draft', color: 'accent-5' })
  }
  return badges
}
async function resetForm(keyValue: string | number | undefined = undefined): Promise<void> {
  if (pageMode.value === 'edit') {
    if (!keyValue && selectedTaskOrderObj.value?.taskOrderNumber) {
      const res = await adminApi.getTOIterations(selectedTaskOrderObj.value.taskOrderNumber)

      activeRow.value = res.results?.[0]
      // Find out the start date of 1st iteration. It will have iterationNumber as 1.
      const startDateOf1stIteration: string = res.results?.filter(
        (e: any) => e.iterationNumber === 1
      )[0]?.iterationStartDate

      // Derive end date of original iteration as 1 day prior to start date of 1st iteration
      const endDateOfOriginalIteration: string =
        startDateOf1stIteration &&
        moment(startDateOf1stIteration).subtract(1, 'days').format('YYYY-MM-DD')

      // Enrich the response with derived value. In the response iterationNumber will be null for original iteration.
      // Only for original iteration, iterationEndDate will be null. So, we can use this logic to enrich the response.
      if (endDateOfOriginalIteration) {
        const originalIteration = res.results.filter((e: any) => !e.iterationNumber)[0]
        if (originalIteration) {
          originalIteration.iterationEndDate = endDateOfOriginalIteration
        }
      }
      rows.value =
        res.results
          ?.map((e: any, _index: number, array: Array<any>) => {
            e.modApprovedDate = toMMDDYYYY(e.modApprovedDate)
            e.iterationStartDate = toMMDDYYYY(e.iterationStartDate)
            e.iterationEndDate = toMMDDYYYY(e.iterationEndDate)
            e.createdOn = toMMDDYYYY(e.createdOn)
            e.updatedOn = e.updatedOn ? toMMDDYYYY(e.updatedOn) : e.updatedOn
            // endpoint bug: it is not returning isCurrent === 'Y' when it supposed to.
            return e
          })
          .reverse() ?? []
    }
  }
  for (const row of rows.value) {
    if (keyValue && row[settings.key] !== keyValue) {
      continue
    }
    const rowKey = row[settings.key]
    let inputs = form.value[rowKey] ?? {}
    for (const field in row) {
      const value = row[field]
      inputs[field] = new Input({ field, value })
    }
    for (const cell of columns.value) {
      const { label, field, input } = cell
      const value = row[field]
      inputs[field] = new Input({ ...input, label, field, value })
    }
    form.value = { ...form.value, [rowKey]: inputs }

    if (String(row.taskOrderId) === String(selectedTaskOrderObj.value?.taskOrderId)) {
      checked.value = String(row.taskOrderId)
    }
  }
}
async function onInput(newValue: any, cell: any): Promise<void> {
  if (!cell) {
    return
  }
  const { key, field } = cell
  const inputs = form.value[key]
  const input = inputs?.[field]
  if (!input) {
    return
  }
  input.set(newValue)
  input.validate()

  const mode = getRowMode(activeRow.value, taskOrderDetailsRes.value?.taskOrderDto)
  const isActive = activeRow.value[settings.key] === key

  if (mode === 'edit' && isActive) {
    inputs.updatedOn.set(moment().format('MM/DD/YYYY'))
  }
  switch (field) {
    case 'iterationStartDate': {
      const startDate = fromMMDDYYYY(inputs.iterationStartDate.value)
      const endDate = fromMMDDYYYY(inputs.iterationEndDate.value)
      if (startDate >= endDate) {
        inputs.iterationStartDate.state = 'danger'
        inputs.iterationStartDate.error = {
          message: `Start Date should be before End Date.`
        }
      } else {
        inputs.iterationEndDate.set(inputs.iterationEndDate.value)
        inputs.iterationEndDate.validate()
      }
      break
    }
    case 'iterationEndDate': {
      const startDate = fromMMDDYYYY(inputs.iterationStartDate.value)
      const endDate = fromMMDDYYYY(inputs.iterationEndDate.value)
      if (startDate >= endDate) {
        inputs.iterationEndDate.state = 'danger'
        inputs.iterationEndDate.error = {
          message: `End Date should be after Start Date.`
        }
      } else {
        inputs.iterationStartDate.set(inputs.iterationStartDate.value)
        inputs.iterationStartDate.validate()
      }
      break
    }
  }
}
async function onInsert(_index = 0): Promise<void> {
  const keyValue = '_new-' + randomId()
  const newRow = {
    [settings.key]: keyValue
  }
  rows.value.push(newRow)
  await resetForm(keyValue)
  doScrollToElement('#dataCard-' + keyValue, scrollOpts)
  setTimeout(() => {
    document
      .getElementById('dataCard-' + keyValue)
      ?.querySelector('input')
      ?.focus()
  }, 100)
}
function onDelete(index: number): void {
  const row = rows.value[index]
  delete form.value[row?.[settings.key]]
  rows.value.splice(index, 1)
  const currentRow = rows.value[index - 1]
  const keyValue = currentRow?.[settings.key]
  const selector = keyValue ? '#dataCard-' + keyValue : '#' + emptyDataId.value
  doScrollToElement(selector, scrollOpts)
}
async function onReset(index: number | undefined = undefined): Promise<void> {
  if (typeof index === 'undefined') {
    return
  }
  const currentRow = rows.value[index]
  const keyValue = currentRow?.[settings.key]
  if (!keyValue) {
    return
  }
  await resetForm(keyValue)
}
function onRadio(newValue: string | number | undefined) {
  checked.value = String(newValue)
  const payload = {
    taskOrderObj: {
      ...slice(selectedTaskOrderObj.value, [
        'originalTaskOrderId',
        'taskOrderNumber',
        'agencyIdentifierEncoded'
      ]),
      taskOrderId: String(newValue)
    }
  }
  signal.value = ['selectTaskOrder', payload]
}
</script>

<template>
  <div class="-d--flex -justify-content--center -m--1" :class="{ '-my--6': rows.length === 0 }">
    <ActionButton
      icon="circle-plus"
      text="Add new Iteration"
      :disabled="newRows?.length"
      @onClick="onInsert"
    />
  </div>
  <DataCard
    v-for="(row, index) in [...rows].reverse()"
    :id="'dataCard-' + row?.[settings.key]"
    :key="row?.[settings.key]"
    class="-mb--3"
    classColDefault="-w-xl--3 -w-lg--4 -w-md--6 -w--12"
    :columns="rowColumns?.[row?.[settings.key]]"
    :settings="{
      ...settings,
      noDelete: !String(row?.[settings.key]).includes('_new-'),
      radio: !String(row?.[settings.key]).includes('_new-')
    }"
    :inputs="form?.[row?.[settings.key]]"
    :row="row"
    :count="
      getRowMode(row, taskOrderDetailsRes?.taskOrderDto) !== 'original'
        ? { current: rows.length - 1 - index, total: rows.length - 1 }
        : undefined
    "
    :badges="makeRowBadges(row, taskOrderDetailsRes?.taskOrderDto)"
    :checked="String(checked) === String(row?.[settings.key] && row?.[settings.key])"
    @onInput="onInput"
    @onInsert="onInsert(rows.length - 1 - index)"
    @onDelete="onDelete(rows.length - 1 - index)"
    @onReset="onReset(rows.length - 1 - index)"
    @onRadio="onRadio(row?.[settings.key])"
  />
</template>
