<script lang="ts" setup>
import { ref, watch, computed, defineProps, onUnmounted, onMounted, watchEffect } from 'vue'
import { storeToRefs } from 'pinia'
import { useTOAdminStore } from '../../store/to-admin'
import BillingOptionsModal from './BillingOptionsModal.vue'
import TOIntervalPopup from './TOIntervalPopup.vue'
import { DatePicker } from '@centurylinkfederal/eis-vue'
import moment from 'moment'

export interface IProps {
  action: 'edit' | 'create' | 'view'
  items: any[]
}
const props = defineProps<IProps>()
function initialForm() {
  return {
    agencyIdentifier: '',
    taskOrderNumber: '',
    toProgramName: '',
    fullyLoaded: 'N',
    dashboardDisplay: '',
    originalPramataId: '',
    strStartDate: '',
    strEndDate: '',
    enhancedEmailNotification: '',
    enhancedWorkOrder: '',
    statusManaged: false,
    statusUnManaged: false,
    comments: '',
    invoiceAccountNumber: '',
    toInterval: {
      numberOfDays: 0,
      typeOfDays: ''
    },
    invoiceOptions: {
      sendBillingEmail: 'Y',
      billingContacts: [{ contactName: '', email: '' }],
      invoiceItems: {
        ioCustom: 'N',
        ioDoi: 'N',
        etems: 'N',
        ipp: 'N',
        ioIppSummary: 'N',
        ioIppDetail: 'N'
      }
    }
  }
}

const toAdminStore = useTOAdminStore()
const { agencies, toProgramNames, agencyTOMap, isTODetailsFormValid, standardIntervalResponse } =
  storeToRefs(toAdminStore)
const form = ref<any>({
  contractNumber: '',
  agencyIdentifier: '',
  taskOrderNumber: '',
  toProgramName: '',
  modNumber: '',
  fullyLoaded: 'N',
  dashboardDisplay: '',
  originalPramataId: '',
  strStartDate: '',
  strEndDate: '',
  enhancedEmailNotification: '',
  enhancedWorkOrder: '',
  statusManaged: false,
  statusUnManaged: false,
  comments: '',
  invoiceAccountNumber: '',
  toInterval: {
    numberOfDays: standardIntervalResponse.value[0]?.svcDeliveryNumDays || 0,
    typeOfDays: standardIntervalResponse.value[0]?.svcDeliveryDaysType || ''
  },
  invoiceOptions: {
    sendBillingEmail: 'Y',
    billingContacts: [{ contactName: '', email: '' }],
    invoiceItems: {
      ioCustom: 'N',
      ioDoi: 'N',
      etems: 'N',
      ipp: 'N',
      ioIppSummary: 'N',
      ioIppDetail: 'N'
    }
  }
})
const showBillingOptionsModal = ref<boolean>(false)
const formErrors = ref<any>({})
const isInteractiveMode = ref<boolean>(false)

const isEditAction = props.action === 'edit'
const isViewAction = props.action === 'view'
const showError = ref<boolean>(false)
const errorMessage = ref<string>('')
const selectedTO = ref<any>()
const showTOIntervalPopup = ref<boolean>(false)
const isStandardIntervalAvailable = ref<boolean>(false)

onMounted(async () => {
  await toAdminStore.fetchStandardInterval()
  if (standardIntervalResponse.value && standardIntervalResponse.value.length > 0) {
    const defaultInterval = standardIntervalResponse.value[0]
    form.value.toInterval.numberOfDays = defaultInterval.svcDeliveryNumDays
    form.value.toInterval.typeOfDays = defaultInterval.svcDeliveryDaysType.toLowerCase()
    isStandardIntervalAvailable.value = true
  }
})

watch(
  () => form,
  () => {
    formErrors.value.strEndDate = ''
    const isStartDateGreater: boolean = Boolean(
      form.value.strEndDate &&
        form.value.strStartDate &&
        new Date(form.value.strEndDate) <= new Date(form.value.strStartDate)
    )
    if (isStartDateGreater) {
      formErrors.value.strEndDate = 'End date should be greater than start date'
    } else {
      formErrors.value.strEndDate = ''
    }
    const requiredFields = [
      'agencyIdentifier',
      'taskOrderNumber',
      'toProgramName',
      'originalPramataId',
      'strStartDate',
      'strEndDate'
    ]

    isTODetailsFormValid.value =
      requiredFields.every((key) => form.value[key] !== undefined && form.value[key] !== null) &&
      !isStartDateGreater &&
      (form.value.statusManaged || form.value.statusUnManaged)
  },
  { deep: true }
)

function initialFormWithInterval() {
  return {
    ...initialForm(),
    toInterval: {
      numberOfDays: standardIntervalResponse.value[0]?.svcDeliveryNumDays || 0,
      typeOfDays: standardIntervalResponse.value[0]?.svcDeliveryDaysType || ''
    }
  }
}

function reset() {
  if (standardIntervalResponse.value) {
    form.value.toInterval.numberOfDays = standardIntervalResponse.value[0].svcDeliveryNumDays
    form.value.toInterval.typeOfDays =
      standardIntervalResponse.value[0].svcDeliveryDaysType.toLowerCase()
  }
  form.value = initialFormWithInterval()
}
function toggleTOIntervalPopup() {
  showTOIntervalPopup.value = !showTOIntervalPopup.value
}
function handleSave(data: { numberOfDays: string; typeOfDays: string }) {
  if (data.numberOfDays && data.typeOfDays) {
    form.value.toInterval.numberOfDays = data.numberOfDays
    form.value.toInterval.typeOfDays = data.typeOfDays
  }
  showTOIntervalPopup.value = false
  if (!standardIntervalResponse.value || standardIntervalResponse.value.length === 0) {
    isStandardIntervalAvailable.value = false
  } else {
    isStandardIntervalAvailable.value = true
  }
}
const intervalText = computed(() => {
  const { numberOfDays, typeOfDays } = form.value.toInterval
  let defaultDays = 3
  let defaultType = 'calendar'
  if (standardIntervalResponse.value.length > 0) {
    defaultDays = standardIntervalResponse.value?.[0].svcDeliveryNumDays
    defaultType = standardIntervalResponse.value?.[0].svcDeliveryDaysType.toLowerCase()
  }
  if (isStandardIntervalAvailable.value) {
    if (Number(numberOfDays) === defaultDays && typeOfDays === defaultType) {
      return `Contract Standard (${numberOfDays} ${typeOfDays} days)`
    } else {
      return `TO-specific - ${numberOfDays} ${typeOfDays} days`
    }
  } else {
    if (Number(numberOfDays) === 3 && typeOfDays === 'calendar') {
      return `Contract Standard (${numberOfDays} ${typeOfDays} days)`
    } else {
      return `TO-specific - ${numberOfDays} ${typeOfDays} days`
    }
  }
})
watchEffect(() => {
  if (props.items) {
    selectedTO.value = props.items
  }
})
function checkEmptyFields() {
  if (
    form.value.agencyIdentifier &&
    form.value.taskOrderNumber !== undefined &&
    form.value.taskOrderNumber !== null &&
    form.value.toProgramName &&
    form.value.originalPramataId &&
    form.value.strEndDate &&
    form.value.strStartDate
  ) {
    return true
  }

  return false
}
function toDetailsForm() {
  const selectElement = document.getElementById('ToNumber') as HTMLSelectElement
  let value = 0
  if (selectElement) {
    value = selectElement && selectElement.selectedIndex
  }

  if (value) {
    const content = selectElement && selectElement.options[value]
    if (content) {
      const contentValue = content.textContent
      form.value.taskOrderNumber = contentValue
      for (let i = 0; i < agencyTOMap.value[form.value.agencyIdentifier].length; i++) {
        if (agencyTOMap.value[form.value.agencyIdentifier][i].taskOrderNumber === contentValue) {
          form.value.invoiceNumber = agencyTOMap.value[form.value.agencyIdentifier][i].level3ban
          break
        }
      }
    }
  }

  return form.value
}

defineExpose({
  reset,
  checkEmptyFields,
  toDetailsForm
})

const dataToEdit = computed(() => {
  const data = selectedTO.value?.taskOrderDto || {}
  if (props.action === 'create') {
    return {}
  }
  return {
    contractNumber: data.contractNumber,
    agencyIdentifier: data.agencyIdentifier,
    taskOrderNumber: data.taskOrderNumber,
    modNumber: data?.taskOrderModIteration?.modNumber || '',
    toProgramName: data.toProgramName,
    originalPramataId: data.originalPramataId,
    strStartDate: (data.startDate && moment(data.startDate).format('MM/DD/YYYY')) || '',
    strEndDate: (data.stopDate && moment(data.stopDate).format('MM/DD/YYYY')) || '',
    enhancedEmailNotification: data.enhancedEmailNotification,
    enhancedWorkOrder: data.enWorkAuto,
    statusType: data.statusType,
    dashboardDisplay: data.dashboardDisplay,
    invoiceAccountNumber: data.contractorInvoiceLvlAcctNo,
    fullyLoaded: data.fullyLoaded,
    comments: data.comments,
    statusManaged: data.statusType === 'MANAGED' || data.statusType === 'BOTH',
    statusUnManaged: data.statusType === 'UNMANAGED' || data.statusType === 'BOTH',
    toInterval: {
      numberOfDays: data.svcDeliveryNumDays || 0,
      typeOfDays: data.svcDeliveryDaysType || ''
    },
    invoiceOptions: {
      billingContacts:
        data.billingContacts?.length > 0 ? data.billingContacts : [{ contactName: '', email: '' }],
      sendBillingEmail: 'Y',
      invoiceItems: {
        ioCustom: data.ioCustom || 'N',
        ioDoi: data.ioDoi || 'N',
        etems: data.etems || 'N',
        ipp: data.ioIppDetail || 'N',
        ioIppSummary: data.ioIppSummary || 'N',
        ioIppDetail: data.ioIppDetail || 'N'
      }
    }
  }
})

const taskOrderOptions = computed(() => {
  const agencyIdentifier = form.value.agencyIdentifier
  if (!agencyIdentifier) {
    return []
  }
  return agencyTOMap.value[agencyIdentifier] || []
})

function onDateChange(field: string, $event: any) {
  form.value[field] = $event
}

function handleRadioInputChange(field: string, $event: any) {
  form.value[field] = $event
}

function toggleBillingOptionsModal() {
  showBillingOptionsModal.value = !showBillingOptionsModal.value
}

function applyBillingOptions($event: any) {
  showBillingOptionsModal.value = false
  if (props.action === 'edit') {
    form.value = {
      ...form.value,
      invoiceOptions: $event
    }
  } else if (props.action === 'create') {
    form.value = {
      ...form.value,
      invoiceOptions: {
        ...form.value.invoiceOptions,
        ...$event
      }
    }
  }
}

async function onAgencyIdentifierChange($event: any) {
  await toAdminStore.fetchTaskOrdersForAgency($event.target.value)
}

function onEdit() {
  isInteractiveMode.value = true
  form.value = { ...dataToEdit.value }
}

async function onSave() {
  const invoiceOptions = form.value.invoiceOptions
  form.value.taskOrderId = selectedTO.value?.taskOrderDto?.taskOrderId
  await toAdminStore.updateTaskOrderDetails({
    ...form.value,
    billingContacts: invoiceOptions.billingContacts.every(
      (item: any) => item.conatctName && item.email
    )
      ? invoiceOptions.billingContacts
      : [],
    sendBillingEmail: invoiceOptions.sendBillingEmail,
    ioCustom: invoiceOptions.invoiceItems.ioCustom,
    ioDoi: invoiceOptions.invoiceItems.ioDoi,
    etems: invoiceOptions.invoiceItems.etems,
    ipp: invoiceOptions.invoiceItems.ipp,
    ioIppSummary: invoiceOptions.invoiceItems.ioIppSummary,
    ioIppDetail: invoiceOptions.invoiceItems.ioIppDetail,
    svcDeliveryDaysType: form.value.toInterval.typeOfDays,
    svcDeliveryNumDays: Number(form.value.toInterval.numberOfDays),
    statusType:
      form.value.statusManaged && form.value.statusUnManaged
        ? 'BOTH'
        : form.value.statusManaged
          ? 'MANAGED'
          : form.value.statusUnManaged
            ? 'UNMANAGED'
            : ''
  })
  isInteractiveMode.value = false
  form.value = initialFormWithInterval()
  formErrors.value = {}
}

function onCancel() {
  isInteractiveMode.value = false
  form.value = initialFormWithInterval()
  formErrors.value = {}
}

function canEnableSave() {
  return true
}

onUnmounted(() => onCancel())
</script>
<template>
  <div :class="!isEditAction ? 'chi-card -widget -mt--3' : ''">
    <div class="-mb--2" v-if="showError">
      <div class="chi-alert -danger -close" role="alert">
        <i class="chi-alert__icon chi-icon icon-circle-x" aria-hidden="true"></i>
        <div class="chi-alert__content">
          <p class="chi-alert__text">
            {{ errorMessage }}
          </p>
        </div>
        <button
          class="chi-alert__close-button chi-button -icon -close"
          aria-label="Close"
          @click="showError = false"
        >
          <div class="chi-button__content">
            <i class="chi-icon icon-x" aria-hidden="true"></i>
          </div>
        </button>
      </div>
    </div>
    <BillingOptionsModal
      :read-only="isViewAction || (isEditAction && !isInteractiveMode)"
      v-if="showBillingOptionsModal"
      :invoice-items="
        isViewAction || (isEditAction && !isInteractiveMode)
          ? dataToEdit.invoiceOptions!.invoiceItems
          : form.invoiceOptions.invoiceItems
      "
      :billing-contacts="
        isViewAction || (isEditAction && !isInteractiveMode)
          ? dataToEdit.invoiceOptions!.billingContacts
          : form.invoiceOptions.billingContacts
      "
      :send-billing-email="
        isViewAction || (isEditAction && !isInteractiveMode)
          ? dataToEdit.invoiceOptions!.sendBillingEmail
          : form.invoiceOptions.sendBillingEmail
      "
      @close="toggleBillingOptionsModal()"
      @submit="applyBillingOptions($event)"
    />
    <div class="chi-card__header" v-if="!isEditAction">
      <div class="chi-card__title">TO Details</div>
    </div>
    <div :class="!isEditAction ? 'chi-card__content' : ''">
      <div class="chi-grid -align--top toDetails-wrapper">
        <div class="chi-col -w--2 -mb--2">
          <div class="chi-form__item font-size: 14px;">
            <chi-label :required="!isEditAction">Agency</chi-label>
            <select
              v-if="!isEditAction && !isViewAction"
              class="chi-select"
              v-model="form.agencyIdentifier"
              @change="onAgencyIdentifierChange"
            >
              <option v-for="agency of agencies" :key="agency.value" :value="agency.value">
                {{ agency.label }}
              </option>
            </select>
            <div v-else>{{ dataToEdit.agencyIdentifier }}</div>
          </div>
        </div>
        <div class="chi-col -w--2 -mb--2">
          <div class="chi-form__item font-size: 14px;">
            <chi-label>Contract Number</chi-label>
            <div>{{ dataToEdit.contractNumber || 'GS00Q17NSD3006' }}</div>
          </div>
        </div>
        <div class="chi-col -w--2 -mb--2">
          <div class="chi-form__item">
            <chi-label :required="!isEditAction">TO Number</chi-label>
            <select
              class="chi-select"
              id="ToNumber"
              v-model="form.taskOrderNumber"
              v-if="!isEditAction && !isViewAction"
            >
              <option value="">- Select -</option>
              <option
                v-for="to of taskOrderOptions"
                :key="to.value"
                :value="to.value"
                :disabled="to.value !== 0"
              >
                {{ to.label }}
              </option>
            </select>
            <div v-else>{{ dataToEdit.taskOrderNumber }}</div>
          </div>
        </div>
        <div class="chi-col -w--2 -mb--2" v-if="isEditAction">
          <div class="chi-form__item">
            <chi-label :required="!isEditAction">TO MOD Number</chi-label>
            <input
              class="chi-input"
              v-model="form.modNumber"
              v-if="!isEditAction && !isViewAction"
            />
            <div v-else>{{ dataToEdit.modNumber }}</div>
          </div>
        </div>
        <div class="chi-col -w--2 -mb--2">
          <div class="chi-form__item">
            <chi-label required>TO Program Name</chi-label>
            <input
              class="chi-input"
              v-model="form.toProgramName"
              v-if="(!isEditAction && !isViewAction) || isInteractiveMode"
            />
            <div v-else>{{ dataToEdit.toProgramName }}</div>
          </div>
        </div>
        <div class="chi-col -w--2 -mb--2">
          <div class="chi-form__item">
            <chi-label>Invoice Account Number</chi-label>
            <div>{{ dataToEdit.invoiceAccountNumber }}</div>
          </div>
        </div>
        <div class="chi-col -w--2 -mb--2">
          <div class="chi-form__item">
            <chi-label>Invoice Options</chi-label>
            <button
              class="chi-button -md"
              style="width: 150px"
              @click="toggleBillingOptionsModal()"
            >
              {{ action === 'create' ? 'Select' : isInteractiveMode ? 'Edit/Cancel' : 'View' }}
            </button>
          </div>
        </div>
        <div class="chi-col -w--2 -mb--2">
          <div class="chi-form__item">
            <chi-label>Fully Loaded</chi-label>
            <fieldset v-if="(!isEditAction && !isViewAction) || isInteractiveMode">
              <div class="chi-form__item -inline">
                <div class="chi-radio" @click="handleRadioInputChange('fullyLoaded', 'Y')">
                  <input
                    class="chi-radio__input"
                    type="radio"
                    value="Y"
                    id="fullyLoaded_Y"
                    v-model="form.fullyLoaded"
                  />
                  <label class="chi-radio__label">Yes</label>
                </div>
              </div>
              <div class="chi-form__item -inline">
                <div class="chi-radio" @click="handleRadioInputChange('fullyLoaded', 'N')">
                  <input
                    class="chi-radio__input"
                    type="radio"
                    value="N"
                    id="fullyLoaded_N"
                    v-model="form.fullyLoaded"
                  />
                  <label class="chi-radio__label">No</label>
                </div>
              </div>
            </fieldset>
            <div v-else>{{ dataToEdit.fullyLoaded }}</div>
          </div>
        </div>
        <div class="chi-col -w--2 -mb--2">
          <div class="chi-form__item">
            <chi-label>Enhanced Work Order Automation</chi-label>
            <fieldset v-if="(!isEditAction && !isViewAction) || isInteractiveMode">
              <div class="chi-form__item -inline">
                <div class="chi-radio" @click="handleRadioInputChange('enhancedWorkOrder', 'Y')">
                  <input
                    class="chi-radio__input"
                    type="radio"
                    value="Y"
                    :checked="form.enhancedWorkOrder === 'Y'"
                  />
                  <label class="chi-radio__label">Yes</label>
                </div>
              </div>
              <div class="chi-form__item -inline">
                <div class="chi-radio" @click="handleRadioInputChange('enhancedWorkOrder', 'N')">
                  <input
                    class="chi-radio__input"
                    type="radio"
                    value="N"
                    :checked="form.enhancedWorkOrder === 'N'"
                  />
                  <label class="chi-radio__label">No</label>
                </div>
              </div>
            </fieldset>
            <div v-else>{{ dataToEdit.enhancedWorkOrder }}</div>
          </div>
        </div>
        <div class="chi-col -w--2">
          <div class="chi-form__item">
            <chi-label>Dashboard Display</chi-label>
            <fieldset v-if="(!isEditAction && !isViewAction) || isInteractiveMode">
              <div class="chi-form__item -inline">
                <div class="chi-radio" @click="handleRadioInputChange('dashboardDisplay', 'Y')">
                  <input
                    class="chi-radio__input"
                    type="radio"
                    value="Y"
                    :checked="form.dashboardDisplay === 'Y'"
                  />
                  <label class="chi-radio__label">Yes</label>
                </div>
              </div>
              <div class="chi-form__item -inline">
                <div class="chi-radio" @click="handleRadioInputChange('dashboardDisplay', 'N')">
                  <input
                    class="chi-radio__input"
                    type="radio"
                    value="N"
                    :checked="form.dashboardDisplay === 'N'"
                  />
                  <label class="chi-radio__label">No</label>
                </div>
              </div>
            </fieldset>
            <div v-else>{{ dataToEdit.dashboardDisplay }}</div>
          </div>
        </div>
        <div class="chi-col -w--2 -mb--2">
          <div class="chi-form__item">
            <chi-label :required="!isEditAction || isInteractiveMode"
              >Original Pramata ID</chi-label
            >
            <input
              v-if="(!isEditAction && !isViewAction) || isInteractiveMode"
              class="chi-input"
              v-model="form.originalPramataId"
            />
            <div v-else>{{ dataToEdit.originalPramataId }}</div>
          </div>
        </div>
        <div class="chi-col -w--2 -mb--2">
          <div class="chi-form__item">
            <DatePicker
              label="Start Date"
              id="startDate"
              :required="!isEditAction || isInteractiveMode"
              v-if="(!isEditAction && !isViewAction) || isInteractiveMode"
              :state="formErrors.strStartDate ? 'danger' : ''"
              :value="form.strStartDate"
              :helperMessage="formErrors.strStartDate ? 'Please enter a date' : ''"
              @change="onDateChange('strStartDate', $event)"
              format="MM/DD/YYYY"
            ></DatePicker>
            <template v-else>
              <chi-label>Start Date</chi-label>
              <div>{{ dataToEdit.strStartDate }}</div>
            </template>
          </div>
        </div>
        <div class="chi-col -w--2 -mb--2">
          <div class="chi-form__item">
            <DatePicker
              id="endDate"
              :required="!isEditAction || isInteractiveMode"
              label="End Date"
              v-if="(!isEditAction && !isViewAction) || isInteractiveMode"
              :state="formErrors.strEndDate ? 'danger' : ''"
              :helperMessage="
                formErrors.strEndDate ? ' End date should be greater than start date.' : ''
              "
              :value="form.strEndDate"
              @change="onDateChange('strEndDate', $event)"
              format="MM/DD/YYYY"
            >
            </DatePicker>
            <template v-else>
              <chi-label>End Date</chi-label>
              <div>{{ dataToEdit.strEndDate }}</div>
            </template>
          </div>
        </div>
        <div class="chi-col -w--2 -mb--2">
          <div class="chi-form__item">
            <chi-label :required="!isEditAction || isInteractiveMode">Managed/Unmanaged</chi-label>
            <fieldset v-if="(!isEditAction && !isViewAction) || isInteractiveMode">
              <div class="chi-form__item -mb--1">
                <div
                  class="chi-checkbox"
                  @click="handleRadioInputChange('statusManaged', !form.statusManaged)"
                >
                  <input
                    type="checkbox"
                    class="chi-checkbox__input"
                    :checked="form.statusManaged"
                  />
                  <label class="chi-checkbox__label">Managed</label>
                </div>
              </div>
              <div class="chi-form__item">
                <div
                  class="chi-checkbox"
                  @click="handleRadioInputChange('statusUnManaged', !form.statusUnManaged)"
                >
                  <input
                    type="checkbox"
                    class="chi-checkbox__input"
                    :checked="form.statusUnManaged"
                  />
                  <label class="chi-checkbox__label">Unmanaged</label>
                </div>
              </div>
            </fieldset>
            <div v-else>{{ dataToEdit.statusType }}</div>
          </div>
        </div>
        <div class="chi-col -w--2 -mb--2">
          <div class="chi-form__item">
            <chi-label>Enhanced Email Notifications</chi-label>
            <div v-if="isEditAction || isViewAction">
              {{ dataToEdit.enhancedEmailNotification }}
            </div>
            <fieldset v-else>
              <div class="chi-form__item -inline">
                <div
                  class="chi-radio"
                  @click="handleRadioInputChange('enhancedEmailNotification', 'Y')"
                >
                  <input
                    class="chi-radio__input"
                    type="radio"
                    value="Y"
                    :checked="form.enhancedEmailNotification === 'Y'"
                  />
                  <label class="chi-radio__label">Yes</label>
                </div>
              </div>
              <div class="chi-form__item -inline">
                <div
                  class="chi-radio"
                  @click="handleRadioInputChange('enhancedEmailNotification', 'N')"
                >
                  <input
                    class="chi-radio__input"
                    type="radio"
                    value="N"
                    :checked="form.enhancedEmailNotification === 'N'"
                  />
                  <label class="chi-radio__label">No</label>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="chi-col -w--6 -w-xl--2">
          <div style="width: max-content">
            <label
              class="chi-label"
              style="
                display: inline-block;
                font-family: 'Inter';
                font-weight: 600;
                font-size: 0.875rem;
                margin-bottom: 8px;
                width: max-content;
              "
            >
              Service Delivery Test and Acceptance Interval
            </label>
            <fieldset
              style="border: none; padding: 0; width: 100%"
              v-if="(!isEditAction && !isViewAction) || isInteractiveMode"
            >
              <div
                class="m-form__item -inline"
                style="display: flex; align-items: center; width: 100%"
              >
                <span style="margin-right: 90px; font-size: 0.875rem">
                  {{ intervalText }}
                  <button
                    class="chi-button select-ipp-option"
                    style="
                      background-color: white;
                      border: 2px solid #007bff;
                      color: #007bff;
                      padding: 6px 12px;
                      cursor: pointer;
                      border-radius: 4px;
                      font-size: 0.875rem;
                      margin-left: 8px;
                      text-transform: capitalize;
                    "
                    @click="toggleTOIntervalPopup"
                  >
                    {{
                      action === 'edit'
                        ? 'Edit TO-Specific Interval'
                        : 'Create TO-Specific Interval'
                    }}
                  </button>
                </span>
              </div>
            </fieldset>
            <div v-else>
              {{ dataToEdit.toInterval?.numberOfDays }} {{ dataToEdit.toInterval?.typeOfDays }} days
            </div>
          </div>
        </div>
      </div>

      <TOIntervalPopup
        v-if="showTOIntervalPopup"
        :title="action === 'edit' ? 'Edit TO-Specific Interval' : 'Create TO-Specific Interval'"
        :initialState="form.toInterval"
        @close="toggleTOIntervalPopup"
        @save="handleSave"
      />
      <div class="chi-col -w--12 -mb--2">
        <div class="chi-form__item">
          <chi-label>Comments</chi-label>
          <div v-if="!isInteractiveMode">{{ dataToEdit.comments }}</div>
          <textarea v-else class="chi-input" v-model="form.comments"></textarea>
        </div>
      </div>

      <div
        class="chi-col -w--12 -d--flex -justify-content--center"
        style="gap: 16px"
        v-if="action === 'edit'"
      >
        <button class="chi-button -primary -md" @click="onEdit()" v-if="!isInteractiveMode">
          Edit
        </button>
        <template v-else>
          <button
            class="chi-button -primary -md"
            @click="onSave()"
            color="primary"
            :disabled="!canEnableSave"
          >
            Save
          </button>
          <button class="chi-button -sm" @click="onCancel()" variant="flat">Cancel</button>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.toDetails-wrapper {
  font-size: 14px;
}
</style>
