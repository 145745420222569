import { ref, computed, onMounted, ComputedRef } from 'vue'
import { toBeOptional, Option } from '@centurylinkfederal/eis-vue'
import { defineStore } from 'pinia'
import { useToAdminAPI } from '../../composables/to-admin/api'
import { Entries } from '../../types/common'
import { toMap } from '../../utils/helpers'

let adminApi: any
export const useColumns = defineStore('serviceColumnsStore', () => {
  adminApi = useToAdminAPI()
  const serviceMap = ref(new Map())
  const jurisdictionMap = ref(new Map())
  const columnMap = computed(() => toMap(columns.value, 'field'))
  const columns = computed(() => [
    {
      field: 'picks',
      input: {
        type: 'hidden',
        value: [],
        tests: [toBeOptional]
      }
    }
  ])
  const serviceOptions: ComputedRef<Option[]> = computed(() => {
    if (!serviceMap.value) {
      return []
    }
    return Array.from(serviceMap.value).map(([key, value]: any) => [
      key,
      key + ' - ' + value.serviceDesc
    ])
  })
  const jurisdictionOptions = computed(() =>
    Array.from(jurisdictionMap.value.entries()).map(([key, obj]) => [
      key,
      key + ' - ' + obj?.countryDesc
    ])
  )
  async function initColumns(): Promise<void> {
    const allServicesRes = await adminApi.getAllServices({ useCache: true })
    serviceMap.value = new Map(makeServiceEntries(allServicesRes))
  }
  onMounted(async () => {
    await initColumns()
  })
  return {
    columns,
    columnMap,
    serviceMap,
    serviceOptions,
    jurisdictionMap,
    jurisdictionOptions
  }
})
function makeServiceEntries(response: any): Entries {
  if (!Array.isArray(response?.results)) {
    return []
  }
  const entries: Entries = response.results.map((e: any) => [e.serviceID, e]) ?? []
  return entries
}
