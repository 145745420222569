<script lang="ts" setup>
import moment from 'moment'
import { ref, Ref, defineProps, computed, unref } from 'vue'
import Accordion from '../../components/common/Accordion.vue'
import { useTOAdminStore } from '../../store/to-admin'
import { storeToRefs } from 'pinia'

const toAdminStore = useTOAdminStore()
const {
  toFinancials,
  allServices,
  agencyTaskOrderIdOfficials,
  exportTaskOrderNSCData,
  exportTaskOrderJuridictionData,
  selectedToServicesDTO,
  selectedTO,
  kpiExportData
} = storeToRefs(toAdminStore)
const props = defineProps(['selectedTOID', 'exportDateData'])
const selectedExports: Ref<Array<any>> = ref<Array<any>>([])

const taskOrderId = computed(() => selectedTO.value?.taskOrderDto?.taskOrderId)

const exportSets = computed(() => {
  return [
    {
      title: 'TO Financial',
      lastUpdated:
        props.exportDateData['toFinancialLastUpdated'] &&
        moment(props.exportDateData['toFinancialLastUpdated']).format('MM/DD/YYYY hh:mm:ss a'),
      action: () => toAdminStore.fetchTaskOrderFinancials(taskOrderId!.value),
      heading: [
        { key: 'taskOrderCeiling', header: 'TO Ceiling Amount' },
        { key: 'taskOrderAgfRate', header: 'TO AGF Rate (%)' },
        { key: 'performancePeriodFunding', header: 'Performance Period Funding' },
        { key: 'prorationType', header: 'Proration Type' },
        { key: 'performanceBeginDate', header: 'Performance Begin Date' },
        { key: 'performanceEndDate', header: 'Performance End Date' }
      ]
    },
    {
      title: 'TO Official',
      lastUpdated:
        props.exportDateData['toOfficialLastUpdated'] &&
        moment(props.exportDateData['toOfficialLastUpdated']).format('MM/DD/YYYY hh:mm:ss a'),
      action: () => toAdminStore.fetchTaskOrdersByIdForToOfficials(taskOrderId!.value),
      heading: [
        { key: 'firstName', header: 'First Name' },
        { key: 'lastName', header: 'Last Name' },
        { key: 'workOfficePhone', header: 'Office Phone' },
        { key: 'workMobilePhone', header: 'Mobile Phone' },
        { key: 'workEmail', header: 'Email' },
        { key: 'role', header: 'Role' },
        { key: 'startDate', header: 'Start Date' },
        { key: 'stopDate', header: 'End Date' }
      ]
    },
    {
      title: 'TO Locations',
      lastUpdated:
        props.exportDateData['toLocationsLastUpdated'] &&
        moment(props.exportDateData['toLocationsLastUpdated']).format('MM/DD/YYYY hh:mm:ss a'),
      action: () => toAdminStore.getTaskOrderNSCsByTaskOrderID(taskOrderId!.value),
      heading: [
        { key: 'label', header: 'NSC' },
        { key: 'value', header: 'Location' }
      ]
    },

    {
      title: 'TO Countries/Jurisdictions',
      lastUpdated:
        props.exportDateData['toCountriesJuridisctionLastUpdated'] &&
        moment(props.exportDateData['toCountriesJuridisctionLastUpdated']).format(
          'MM/DD/YYYY hh:mm:ss a'
        ),
      action: () => toAdminStore.getTaskOrderJurisdictionsByTaskOrderID(taskOrderId!.value),
      heading: [
        { key: 'label', header: 'Service Id' },
        { key: 'value', header: 'Jurisdiction Id' }
      ]
    },

    {
      title: 'TO Services and CLINS',
      lastUpdated:
        props.exportDateData['toServicesAndClinsLastUpdated'] &&
        moment(props.exportDateData['toServicesAndClinsLastUpdated']).format(
          'MM/DD/YYYY hh:mm:ss a'
        ),
      action: () => toAdminStore.getServicesByTONumber(taskOrderId!.value),

      heading: [
        { key: 'serviceID', header: 'Service Id' },
        { key: 'identifier', header: 'All_Clins' }
      ]
    },
    {
      title: 'TO Clins Awarded',
      lastUpdated:
        props.exportDateData['toClinsAwardedLastUpdated'] &&
        moment(props.exportDateData['toClinsAwardedLastUpdated']).format('MM/DD/YYYY hh:mm:ss a'),
      action: () => toAdminStore.getTaskOrderSvcDetailsDTO(taskOrderId!.value),
      heading: [{ key: 'clins', header: 'Clins' }]
    },
    {
      title: 'Key Performance Indicators',
      lastUpdated:
        props.exportDateData['toKpiLastUpdated'] &&
        moment(props.exportDateData['toKpiLastUpdated']).format('MM/DD/YYYY hh:mm:ss a'),
      action: () => toAdminStore.getAllKPIs(taskOrderId!.value),
      heading: [
        { key: 'svcId', header: 'service_id' },
        { key: 'clin', header: 'Clins' },
        { key: 'icbNumber', header: 'case_number' },
        { key: 'kpiucUnitCode', header: 'unit_code' },
        { key: 'kpilqLocQCode', header: 'loc_qualifier' },
        { key: 'kpimuMeasUnitCode', header: 'measurement_unit' },
        { key: 'kpilqSvcLvlQCode', header: 'service_level_qualifier' },
        { key: 'perfStdThreshold', header: 'performance_standard' },
        { key: 'kpiaoAqlQOprCode', header: 'aql_qualifier' }
      ]
    }
  ]
})

async function exportToCSV(): Promise<void> {
  if (!selectedExports.value.length) return
  const selectedValue = selectedExports.value.map((val: any) => val.action)
  for (let action of selectedValue) {
    await action()
  }

  const finacialConfig = selectedExports.value.find((ex: any) => ex.title === 'TO Financial')
  const officailConfig = selectedExports.value.find((ex: any) => ex.title === 'TO Official')
  const juridictionConfig = selectedExports.value.find(
    (ex: any) => ex.title === 'TO Countries/Jurisdictions'
  )
  const locationConfig = selectedExports.value.find((ex: any) => ex.title === 'TO Locations')
  const serviceAwardedData = selectedExports.value.find(
    (ex: any) => ex.title === 'TO Services and CLINS'
  )
  const clindsAwardedConfig = selectedExports.value.find(
    (ex: any) => ex.title === 'TO Clins Awarded'
  )
  const kpiConfig = selectedExports.value.find(
    (ex: any) => ex.title === 'Key Performance Indicators'
  )

  if (toFinancials.value?.length && finacialConfig) {
    const financial = getExportData(toFinancials.value, finacialConfig.heading)
    createExportReport(financial, 'Financial')
  }

  if (agencyTaskOrderIdOfficials.value?.[taskOrderId!.value]?.length && officailConfig) {
    let officialData: any = agencyTaskOrderIdOfficials.value?.[taskOrderId!.value]
    officialData = officialData.map((data: any) => ({
      ...data,
      startDate: (data.startDate && moment(data.startDate).format('MM/DD/YYYY')) || '',
      stopDate: (data.stopDate && moment(data.stopDate).format('MM/DD/YYYY')) || '',
      workOfficePhone:
        (data.workOfficePhone &&
          data.workOfficePhone.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')) ||
        '',
      workMobilePhone:
        (data.workMobilePhone &&
          data.workMobilePhone.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')) ||
        ''
    }))
    const official = getExportData(officialData, officailConfig.heading)
    createExportReport(official, 'Official')
  }

  if (exportTaskOrderJuridictionData.value?.length && juridictionConfig) {
    const data = exportTaskOrderJuridictionData.value.reduce((all: Array<any>, element: any) => {
      const value = element.jurisdictions.map((item: any) => ({
        label: element.acronym,
        value: item.jurisdId
      }))
      all.push(...value)
      return all
    }, [])
    const juridiction = getExportData(data, juridictionConfig.heading)
    createExportReport(juridiction, 'Country and Jurisdiction')
  }

  if (allServices.value?.length && serviceAwardedData) {
    const data = allServices.value.map((elem: any) => ({
      serviceID: elem.serviceID,
      identifier: elem.identifier === 'All CLINs' ? 'Y' : 'N'
    }))
    const awarded = getExportData(data, serviceAwardedData.heading)
    createExportReport(awarded, 'TO Services and CLINs')
  }
  if (exportTaskOrderNSCData.value?.length && locationConfig) {
    const data = exportTaskOrderNSCData.value.reduce((all: Array<any>, element: any) => {
      if (element?.locations?.length) {
        const value = element.locations.map((item: any) => ({
          label: element.acronym,
          value: item.location
        }))
        all.push(...value)
      }
      return all
    }, [])
    const location = getExportData(data, locationConfig.heading)
    createExportReport(location, 'Locations')
  }

  if (selectedToServicesDTO.value?.length && clindsAwardedConfig) {
    const data = selectedToServicesDTO.value
      .filter((data: any) => !!data?.clins?.length)
      .flatMap((result: any) => result.clins)
      .map((value: any) => ({
        clins: value
      }))

    const clins = getExportData(data, clindsAwardedConfig.heading)
    createExportReport(clins, 'Clins Awarded')
  }

  if (kpiExportData.value?.length && kpiConfig) {
    const kpi = getExportData(kpiExportData.value, kpiConfig.heading)
    createExportReport(kpi, 'Key Performance Indicators')
  }
}
function getExportData(data: any, heading: Array<any>): string {
  let csv = ''
  const prop = heading.map((data) => data.key)
  for (let row of heading) {
    csv += `${row.header},`
  }
  csv += '\n'
  for (let value of data) {
    for (let key of prop) {
      csv += `${value[key] || ''},`
    }

    csv += '\n'
  }

  return csv
}
function createExportReport(value: any, filename: string): void {
  const blob = new Blob([value], { type: 'text/cvs' })
  const val = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = val
  a.download = `${filename}.csv`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

function cancelExport() {
  selectedExports.value = []
}
</script>

<template>
  <Accordion title="Detail Sets TO Export" :defaultOpen="true">
    <div>
      <div v-for="(exportSet, index) in exportSets" :key="index">
        <div class="export">
          <div class="chi-col -w--6">
            <div class="m-form__item">
              <fieldset>
                <div class="m-form__item -inline">
                  <div
                    class="chi-checkbox checkbox-alignment"
                    :style="{ width: exportSet.lastUpdated ? '30em' : '23.5em' }"
                  >
                    <input
                      class="chi-checkbox__input"
                      type="checkbox"
                      name="export_set"
                      :id="`${exportSet.title}_${index}`"
                      :value="exportSet"
                      v-model="selectedExports"
                    />
                    <label class="chi-checkbox__label bold" :for="`${exportSet.title}_${index}`">{{
                      exportSet.title
                    }}</label>

                    <div>
                      <p class="-m--0 bold">Last Updated</p>
                      <span class="chi-checkbox__label" :for="`${exportSet.title}_${index}`">{{
                        exportSet.lastUpdated
                      }}</span>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div class="chi-card__footer -noBorder -alignCenter export" style="justify-content: center">
        <button class="chi-button -sm -mr--2" id="create" @click="cancelExport">Cancel</button>
        <button class="chi-button -sm" @click="exportToCSV">Export</button>
      </div>
    </div>
  </Accordion>
</template>

<style scoped>
.export {
  padding: 1.5em 0;
  border-bottom: 1px solid #ddd;
}

.export-detail {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.checkbox-alignment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bold {
  font-weight: 600 !important;
}
</style>
