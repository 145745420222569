<script setup lang="ts">
import { useApi } from '@centurylinkfederal/eis-vue'
import { ref, onMounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import states from '../constants/us-state'
import { useAlertsStore } from '../store/alert'
import { useLookupSelectionStore, LookupSelection } from '../store/lookup'

const lookupSelectionStore = useLookupSelectionStore()

const router = useRouter()
const route = useRoute()
const accountParam = route.params.id
const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)
const userApi = useApi(import.meta.env.VITE_EIS_AUTH_API)
const level3Ban = ref('')
const agencyId = ref('')
const agencyName = ref('')
const billingNotes = ref('')
const accountId = ref('')
const taskOrderToBeUpdated = ref([])
const tobizOrg = ref('')
const nonEditableTaskOrders: any = ref<{ name: string; checked: boolean; isNew: boolean }[]>([])
const errorMessage = ref('')
const greenNonBillableBanListData = ref<
  { name: string; checked: boolean; type: string; isNew: boolean }[]
>([])
const rboNonBillableBanListData = ref<
  { name: string; checked: boolean; type: string; isNew: boolean }[]
>([])
const removeBandData: any = ref([])
const addBandData: any = ref({})
const nonBillableBAN = ref('')
const editableTaskOrdersListData: any = ref<{ name: string; checked: boolean }[]>([])
const taskorder = ref('')
const editableTaskOrder: any = ref([])
const textInput = ref(false)
const textInputTaskOrder: any = ref(false)
const createdOn = ref('')
const isSubmitAccountPressed = ref(false)
const formErrors = ref(false)
const permissions = ref<string[]>([])
const removedTaskOrders = ref<any[]>([])
const removedRboBans = ref<any[]>([])
const removedGreenBans = ref<any[]>([])
const updatedTaskOrders = ref<{ NEW_TO_VALUE: string; OLD_TO_VALUE: string }[]>([])
const banValidationFlag = ref(false)
const removedBans: any = ref([])
const alertStore = useAlertsStore()
const isBizOrgIdChanged = ref(false)
const isSingleTaskOrder = ref(false)
const updateButtonLabel = ref('Update Account')
const taskOrderValidationFlag = ref(false)
const nonBillableBanError = ref(false)
const showTaskOrderEmptyFiles = ref(false)
const emptyFilesList: any = ref([])
const taskOrderEmptyFiles: any = ref({})

const billingFields: any = [
  { name: 'billingFirstName', label: 'FirstName', value: '', maxlength: 50, type: 'text' },
  { name: 'billingLastName', label: 'LastName', value: '', maxlength: 50, type: 'text' },
  { name: 'billingTelephone', label: 'Phone', value: '', maxlength: 10 },
  { name: 'billingEmail', label: 'Email', value: '', maxlength: 200, type: 'text' },
  { name: 'billingAddress1', label: 'Address1', value: '', maxlength: 200, type: 'text' },
  { name: 'data', label: 'data', value: '', maxlength: 200 },
  { name: 'billingAddress2', label: 'Address2', value: '', maxlength: 200, type: 'text' },
  { name: 'data1', label: 'data1', value: '', maxlength: 200 },
  { name: 'billingCity', label: 'City', value: '', maxlength: 100, type: 'text' },
  { name: 'billingState', label: 'State', value: '' },
  { name: 'billingZip', label: 'Zipcode', value: '', type: 'number', maxlength: 5 },
  { name: 'billingZipPlus', label: 'ZipcodePlus', type: 'number', value: '', maxlength: 4 }
]
type BillingField = {
  name: string
  label: string
  value: string
  maxlength: number
  type: string
}

const groupedBillingFields: BillingField[][] = billingFields
  .filter((field: BillingField) => field.name !== 'data' && field.name !== 'data1')
  .reduce(
    (
      accumulator: BillingField[][],
      currentValue: BillingField,
      currentIndex: number,
      array: BillingField[]
    ) => {
      if (currentIndex % 2 === 0) {
        accumulator.push(array.slice(currentIndex, currentIndex + 2))
      }
      return accumulator
    },
    []
  )

const hasEditPermission = computed(() => {
  return permissions.value.includes('PRIVILEGE:EDIT_AGENCY')
})

const hasViewPermissions = computed(() => {
  return (
    permissions.value.includes('PRIVILEGE:EDIT_AGENCY') ||
    permissions.value.includes('PRIVILEGE:VIEW_AGENCY')
  )
})

onMounted(async () => {
  await getUserPermissions()
  await getAgencyData()
})

async function getUserPermissions(): Promise<void> {
  try {
    const res: any = await userApi.get('/user-profile/permissions')
    permissions.value = res as string[]
  } catch {}
}

async function getAgencyData(): Promise<void> {
  try {
    const res: any = await api.get(`/account-details/${accountParam}`)
    if (res.readOnly) {
      updateButtonLabel.value = res.nonBillableBansEnabled
        ? 'Update Non Billable BANs / Task Order / Billing Contact'
        : 'Update Task Order / Billing Contact'
    }
    res.greenNonBillableBanList &&
      res.greenNonBillableBanList.length > 0 &&
      res.greenNonBillableBanList.map((item: any) => {
        let data: any = {}
        data['name'] = item
        data['checked'] = false
        data['isNew'] = false
        greenNonBillableBanListData.value.push(data)
      })
    res.rboNonBillableBanList &&
      res.rboNonBillableBanList.length > 0 &&
      res.rboNonBillableBanList.map((item: any) => {
        let data: any = {}
        data['name'] = item
        data['checked'] = false
        data['isNew'] = false
        rboNonBillableBanListData.value.push(data)
      })
    res.editableTaskOrders &&
      res.editableTaskOrders.length > 0 &&
      res.editableTaskOrders.map((item: any) => {
        let data: any = {}
        data['name'] = item
        data['checked'] = false
        data['edit'] = false
        data['isNew'] = false
        editableTaskOrdersListData.value.push(data)
      })
    nonEditableTaskOrders.value = res.nonEditableTaskOrders
    let totalTo =
      editableTaskOrdersListData.value?.length + (nonEditableTaskOrders.value?.length || 0)
    if (totalTo <= 1) {
      isSingleTaskOrder.value = true
    }
    agencyId.value = res.accountDetails?.agencyId
    agencyName.value = res.accountDetails?.agencyName
    billingFields.map((item: any) => {
      item.value = res.accountDetails[item.name]
    })
    billingNotes.value = res.accountDetails.notes
    ;(accountId.value = res.accountId),
      (level3Ban.value = res.accountDetails.level3Ban),
      (taskOrderToBeUpdated.value = res.taskOrderToBeUpdated),
      (tobizOrg.value = res.tobizOrg),
      (errorMessage.value = res.errorMessage),
      (createdOn.value = res.createdOn)

    if (res.greenNonBillableBanList?.length > 0 || res.rboNonBillableBanList?.length > 0) {
      banValidationFlag.value = true
    }
    taskOrderEmptyFiles.value = res.fileNames
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

function toBizorgInputChange(event: Event) {
  const target = event.target as HTMLInputElement
  if (target.value?.length <= 25) {
    tobizOrg.value = target.value
  }
}
const shouldShowUpdateCheckbox = computed(() => {
  return !!errorMessage.value && errorMessage.value.length > 0
})
const getInputValue = (event: Event, key: string) => {
  const target = event.target as HTMLInputElement
  billingFields.map((item: any) => {
    if (item.name === key) {
      item.value = target.value
    }
  })

  switch (key) {
    case 'billingNotes':
      billingNotes.value = target.value
      break
    case 'accountId':
      accountId.value = target.value
      break
    case 'tobizOrg':
      tobizOrg.value = target.value
      isBizOrgIdChanged.value = true
      break
    case 'errorMessage':
      errorMessage.value = target.value
      break
  }
}
const getBandGroupValue = (event: Event) => {
  const target = event.target as HTMLInputElement
  let data: any = {}
  data['name'] = target.value
  data['checked'] = false
  data['type'] = addBandData.value.type
  addBandData.value = data
}
const getTaskOrder = (event: Event) => {
  const target = event.target as HTMLInputElement
  taskorder.value = target.value
}

const checkRadioChange = (string: string) => {
  addBandData.value['type'] = string
  nonBillableBanError.value = false
}
const changerboNonBillable = (item: any) => {
  //removeBandData.value = item
  const index = rboNonBillableBanListData.value.findIndex((i: any) => i.name === item.name)
  rboNonBillableBanListData.value[index].checked = !rboNonBillableBanListData.value[index].checked
  removeBandData.value.push(rboNonBillableBanListData.value[index])
}
const changeGreenNonBillable = (item: any) => {
  //removeBandData.value = item
  const index = greenNonBillableBanListData.value.findIndex((i: any) => i.name === item.name)
  greenNonBillableBanListData.value[index].checked =
    !greenNonBillableBanListData.value[index].checked
  removeBandData.value.push(greenNonBillableBanListData.value[index])
}
const changeNonEditableOrder = (item: any) => {
  const index = editableTaskOrdersListData.value.findIndex((i: any) => i.name === item.name)
  editableTaskOrdersListData.value[index].checked = !editableTaskOrdersListData.value[index].checked
}
const addTaskOrderValue = () => {
  const value = taskorder.value.trim()
  if (!value) {
    return
  }
  let data: any = {}
  data['name'] = value
  data['checked'] = false
  data['isNew'] = true
  const isDuplicate = (list: any, value: any) =>
    list?.some((v: any) => (v.name || v).toLowerCase() === value.toLowerCase())
  if (
    isDuplicate(nonEditableTaskOrders.value, value) ||
    isDuplicate(editableTaskOrdersListData.value, value)
  ) {
    return
  }
  editableTaskOrder.value = data
  editableTaskOrdersListData.value.push(editableTaskOrder.value)
  if (editableTaskOrdersListData.value?.length + (nonEditableTaskOrders.value?.length || 0) > 1) {
    isSingleTaskOrder.value = false
  } else {
    isSingleTaskOrder.value = true
  }
  taskorder.value = ''
}
const removeTaskOrderValue = (item: any) => {
  const index = editableTaskOrdersListData.value.findIndex((i: any) => i.name === item.name)
  const t = editableTaskOrdersListData.value[index]
  if (t.checked && !t.isNew) {
    removedTaskOrders.value.push(t)
  }
  editableTaskOrdersListData.value.splice(index, 1)
  if (editableTaskOrdersListData.value?.length + (nonEditableTaskOrders.value?.length || 0) > 1) {
    isSingleTaskOrder.value = false
  } else {
    isSingleTaskOrder.value = true
  }

  if (
    (editableTaskOrdersListData.value?.length ?? 0) + (nonEditableTaskOrders.value?.length ?? 0) >
    0
  ) {
    taskOrderValidationFlag.value = false
  } else {
    taskOrderValidationFlag.value = true
  }
}
const goBackToPage = () => {
  router.back()
}
const addBandValue = () => {
  const isNew = true

  if (!addBandData.value.type) {
    nonBillableBanError.value = true
    return
  }
  const name = addBandData.value.name
  const stripSuffix = (name: any) => name.replace(/ - RBO| - GREEN$/, '')
  const strippedName = stripSuffix(name)
  const isDuplicate = (list: any) => list?.some((v: any) => stripSuffix(v.name) === strippedName)
  if (
    isDuplicate(greenNonBillableBanListData.value) ||
    isDuplicate(rboNonBillableBanListData.value)
  ) {
    return
  }
  if (addBandData.value.type === 'GREEN') {
    greenNonBillableBanListData.value.push({ ...addBandData.value, name, isNew })
  } else {
    rboNonBillableBanListData.value.push({ ...addBandData.value, isNew })
  }
  if (
    removedBans.value.length > 0 &&
    greenNonBillableBanListData.value.length < 1 &&
    rboNonBillableBanListData.value.length < 1
  ) {
    banValidationFlag.value = false
  } else {
    banValidationFlag.value = true
  }
  nonBillableBAN.value = '' // Reset the nonBillableBAN value to an empty string
}

const removeBandValue = (data: any) => {
  data.forEach((ban: any) => {
    let listData
    let i = ban.name
    if (i.includes('- GREEN') || addBandData.value.type?.includes('GREEN')) {
      listData = greenNonBillableBanListData.value
      !ban.isNew && removedGreenBans.value.push(ban)
    } else if (i.includes('- RBO') || addBandData.value.type?.includes('RBO')) {
      listData = rboNonBillableBanListData.value
      !ban.isNew && removedRboBans.value.push(ban)
    }

    // Filter the list and collect removed bans
    const updatedList =
      listData?.filter((item: any) => {
        if (item.checked) {
          if (!item.isNew) {
            removedBans.value.push(item)
          }
          return false
        }
        return true
      }) ?? []

    // Update the respective list based on name
    if (i.includes('- GREEN') || addBandData.value.type?.includes('GREEN')) {
      greenNonBillableBanListData.value = [...updatedList]
    } else if (i.includes('- RBO') || addBandData.value.type?.includes('RBO')) {
      rboNonBillableBanListData.value = [...updatedList]
    }
  })
}

function onPhoneNumberChange(e: any) {
  const phoneRegex = /^(\d{0,3})(\d{0,3})(\d{0,4})$/
  const value = e.target.value.replace(/\D/g, '')
  const matches = value.match(phoneRegex)
  if (!matches?.length) {
    return
  }
  e.target.value = !matches[2]
    ? value.length == 3 && e.inputType !== 'deleteContentBackward'
      ? `(${matches[1]})`
      : matches[1]
    : `(${matches[1]})-${matches[2]}${matches[3] ? '-' + matches[3] : ''}`

  billingFields.billingTelephone.value = e.target.value
}
function hasErrors() {
  if (
    removedBans.value.length > 0 &&
    greenNonBillableBanListData.value.length < 1 &&
    rboNonBillableBanListData.value.length < 1
  ) {
    banValidationFlag.value = false
  }
  return (
    !level3Ban.value ||
    !agencyId.value ||
    // !taskorder.value ||
    !tobizOrg.value ||
    !banValidationFlag.value
  )
}

function submitAccount(isEmptyFilesShow?: string) {
  if (hasErrors()) {
    formErrors.value = true
    window.scroll({ top: 0, behavior: 'smooth' })
  } else {
    emptyFilesList.value = []

    if (updatedTaskOrders.value?.length > 0 && !isEmptyFilesShow) {
      updatedTaskOrders.value.forEach((to) => {
        const files: string[] = taskOrderEmptyFiles.value[to.OLD_TO_VALUE] as string[]
        emptyFilesList.value = files
          ? [...emptyFilesList.value, ...files]
          : [...emptyFilesList.value]
      })

      if (emptyFilesList.value.length > 0) {
        showTaskOrderEmptyFiles.value = true
        return
      }
    }

    showTaskOrderEmptyFiles.value = false

    if (isSingleTaskOrder?.value === true) {
      submitAccountConfirm()
    }
    formErrors.value = false
    isSubmitAccountPressed.value = true
  }
}

function submitAccountCancel() {
  isSubmitAccountPressed.value = false
  showTaskOrderEmptyFiles.value = false
}

async function submitAccountConfirm(): Promise<void> {
  const removedTaskOrdersFinal: any[] = []
  const removedRboBansFinal: any[] = []
  const removedGreenBansFinal: any[] = []
  const newTaskOrders: any[] = []
  const newRboBans: any[] = []
  const newGreenBans: any[] = []
  isSubmitAccountPressed.value = false

  rboNonBillableBanListData.value.forEach((item: any) => {
    if (item.isNew) {
      newRboBans.push(item.name)
    }
  })
  greenNonBillableBanListData.value.forEach((item: any) => {
    if (item.isNew) {
      newGreenBans.push(item.name)
    }
  })
  editableTaskOrdersListData.value.forEach((item: any) => {
    if (item.isNew) {
      newTaskOrders.push(item.name)
    }
  })
  removedGreenBans.value.forEach((item) => {
    let nameWithoutSuffix = item.name.replace(/- GREEN(?!.*- GREEN)/i, '').trim()
    removedGreenBansFinal.push(nameWithoutSuffix)
    //removedGreenBansFinal.push(item.name)
  })
  removedRboBans.value.forEach((item) => {
    let nameWithoutSuffix = item.name.replace(/- RBO(?!.*- RBO)/g, '').trim()
    removedRboBansFinal.push(nameWithoutSuffix)
    //removedRboBansFinal.push(item.name)
  })
  removedTaskOrders.value.forEach((item: any) => {
    removedTaskOrdersFinal.push(item.name)
  })

  if (
    newTaskOrders.length > 0 ||
    editableTaskOrdersListData.value?.length > 0 ||
    nonEditableTaskOrders.value?.length > 0
  ) {
    taskOrderValidationFlag.value = false
  } else {
    taskOrderValidationFlag.value = true
    return
  }

  const requestData: any = {
    accountId: accountId.value,
    level3Ban: level3Ban.value,
    billingFirstName: billingFields[0].value,
    billingLastName: billingFields[1].value,
    billingAddress1: billingFields[4].value,
    billingAddress2: billingFields[6].value,
    billingCity: billingFields[8].value,
    billingState: billingFields[9].value,
    billingZip: billingFields[10].value,
    billingZipPlus: billingFields[11].value,
    billingTelephone: billingFields[2].value,
    billingEmail: billingFields[3].value,
    notes: billingNotes.value,
    agencyId: agencyId.value,
    bizOrgId: tobizOrg.value,
    isBizOrgIdChanged: isBizOrgIdChanged.value,
    newTaskOrders,
    updatedTaskOrders: updatedTaskOrders.value,
    removedTaskOrders: removedTaskOrdersFinal,
    newRboBans,
    removedRboBans: removedRboBansFinal,
    newGreenBans,
    removedGreenBans: removedGreenBansFinal
  }
  api
    .put(`/update-account/${accountParam}`, requestData)
    .then((res: any) => {
      alertStore.alerts = []
      isBizOrgIdChanged.value = false
      alertStore.addAlert('success', 'The billing contact/Task Order was updated successfully')
      window.scroll({ top: 0, behavior: 'smooth' })
      const existingSelection: LookupSelection = lookupSelectionStore.lookupSelection
      const taskOrderSelection = updatedTaskOrders.value?.find(
        (item) => item.OLD_TO_VALUE === existingSelection.taskOrder
      )
      if (taskOrderSelection) {
        lookupSelectionStore.updateLookupSelection(taskOrderSelection.NEW_TO_VALUE, 'taskOrder')
      }
    })
    .catch((err: any) => {
      alertStore.alerts = []
      alertStore.addAlert(
        'danger',
        err?.response?.data?.message || `Error occured while creating the account.`
      )
      window.scroll({ top: 0, behavior: 'smooth' })
    })
    .finally(() => {
      isSubmitAccountPressed.value = false
    })
}

const editIconChange = (data: any) => {
  textInput.value = !textInput.value
  data.edit = !data.edit
}
const geteditTaskOrder = (event: Event, item: any): void => {
  if (!item) {
    return
  }
  const target = event.target as HTMLInputElement
  textInputTaskOrder.value = target.value
}

const changeTaskOrder = (item: any) => {
  const newName = textInputTaskOrder.value.trim()
  const isDuplicate = (list: any, value: any) =>
    list?.some((i: any) => (i.name || i).toLowerCase() === value.toLowerCase())
  if (
    isDuplicate(editableTaskOrdersListData.value, newName) ||
    isDuplicate(nonEditableTaskOrders.value, newName)
  ) {
    return
  }
  const index = editableTaskOrdersListData.value.findIndex((i: any) => i.name === item.name)
  if (index === -1) {
    return
  }
  updatedTaskOrders.value.push({
    NEW_TO_VALUE: newName,
    OLD_TO_VALUE: editableTaskOrdersListData.value[index].name
  })

  editableTaskOrdersListData.value[index].name = newName
  textInput.value = !textInput.value
  item.edit = !item.edit
}

const onFieldValueChange = (e: any, field: BillingField): void => {
  const target = e.target as HTMLInputElement
  if (field.type === 'number' && (target.value?.includes('e') || target.value?.includes('E'))) {
    target.value = target.value.replace('e', '')
  }
  if (field.type === 'number' && target.value?.length > field.maxlength) {
    target.value = target.value?.substring(0, field.maxlength)
  }
}

const closeTaskOrder = (item: any) => {
  textInput.value = !textInput.value
  item.edit = !item.edit
}

const onNumberFieldKeyDown = (e: any, field: BillingField): void => {
  if (field.type === 'number' && ['e', 'E'].includes(e.key)) {
    e.preventDefault()
  }
}

const onTaskOrderNumberChange = (event: any): void => {
  const regex = /[^A-Za-z0-9_-\s]+/
  if (!!regex.test(event.key)) {
    event.preventDefault()
  }
}
</script>
<template>
  <div class="chi-alert -danger" role="alert" v-show="shouldShowUpdateCheckbox" id="error_alert">
    <i class="chi-alert__icon chi-icon icon-circle-x" aria-hidden="true"></i>
    <div class="chi-alert__content">
      <p class="chi-alert__text">{{ errorMessage }}</p>
    </div>
  </div>
  <div class="container -p--2">
    <h2>Edit Account</h2>
    <div class="chi-form__item">
      <label class="chi-label" for="level3Ban">
        Lumen Ban
        <abbr class="chi-label__required" aria-label="Required field">*</abbr>
      </label>
      <input
        type="text"
        disabled
        class="chi-input -w--25"
        :class="formErrors && !level3Ban ? '-danger' : ''"
        id="level3Ban"
        v-model="level3Ban"
      />
      <div class="chi-label -status -danger" v-if="formErrors && !level3Ban">Ban is required</div>
    </div>
    <div class="chi-form__item -mt--2">
      <label class="chi-label" for="agency">
        Agency
        <abbr class="chi-label__required" aria-label="Required field">*</abbr>
      </label>
      <input
        type="text"
        disabled
        class="chi-input -w--25"
        id="agency"
        v-model="agencyName"
        :class="formErrors && !agencyId ? '-danger' : ''"
      />
      <div class="chi-label -status -danger" v-if="formErrors && !agencyId">Agency is required</div>
    </div>
    <div class="-mt--2 -d--flex -flex-gap-sm -mr--2" style="gap: 1rem">
      <div class="-w--25">
        <div class="chi-form__item">
          <label class="chi-label" for="nonBillable">
            Non-Billable BAN
            <abbr class="chi-label__required" aria-label="Required field">*</abbr>
            <span class="-text--muted"> (minimum 1 required)</span>
          </label>
          <input
            v-model="nonBillableBAN"
            type="text"
            class="chi-input"
            :disabled="!hasEditPermission"
            :maxLength="50"
            id="nonBillable"
            @change="getBandGroupValue($event)"
            :class="formErrors && !banValidationFlag ? '-danger' : ''"
          />
          <div class="chi-label -status -danger" v-if="formErrors && !banValidationFlag">
            Non-Billable BAN is required
          </div>
          <div class="chi-label -status -danger" v-if="nonBillableBanError">
            Atleast one non-billable ban is required(RBO/GREEN)
          </div>
        </div>
      </div>
      <div class="-w--25 -d--flex -justify-content--between">
        <div>
          <label class="chi-label">&nbsp; </label>
          <div class="chi-radio">
            <input
              class="chi-radio__input"
              type="radio"
              :disabled="!hasEditPermission"
              name="radios"
              id="radio-ba1"
              @change="checkRadioChange('RBO')"
            />
            <label class="chi-radio__label" for="radio-ba1">RBO</label>
          </div>
          <div class="chi-radio">
            <input
              class="chi-radio__input"
              type="radio"
              name="radios"
              id="radio-ba2"
              :disabled="!hasEditPermission"
              @change="checkRadioChange('GREEN')"
            />
            <label class="chi-radio__label" for="radio-ba2">GREEN</label>
          </div>
        </div>
        <div>
          <label class="chi-label">&nbsp;</label>
          <div class="-d--flex -flex--column">
            <button
              class="chi-button -xs -mb--1"
              @click="addBandValue()"
              :disabled="!hasEditPermission"
            >
              <div class="chi-button__content">
                <span>Add</span>
                <chi-icon icon="chevron-right" size="xs"></chi-icon>
              </div>
            </button>
          </div>
          <div>
            <!-- Remove button -->
            <button
              class="chi-button -xs"
              @click="removeBandValue(removeBandData)"
              :disabled="!hasEditPermission || !removeBandData"
            >
              <div class="chi-button__content">
                <span style="width: 52px">Remove</span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="-w--45">
        <div class="chi-grid">
          <label class="chi-label">&nbsp;</label>
        </div>
        <div class="chi-grid -h--4 -b--1 -b--base-dark" style="min-height: 80px; margin-left: 2px">
          <div class="chi-col" style="margin-top: 5px; margin-left: 5px">
            <label for="rbo">RBO</label>
            <template v-if="rboNonBillableBanListData.length">
              <div v-for="(obj, index) in rboNonBillableBanListData" :key="index">
                <div class="chi-form__item -d--inline-flex" style="margin-top: 5px">
                  <div class="chi-checkbox">
                    <input
                      :id="'rbo_' + index"
                      :disabled="!hasEditPermission"
                      type="checkbox"
                      class="chi-checkbox__input"
                      @click="changerboNonBillable(obj)"
                    />
                    <label :for="'rbo_' + index" class="chi-checkbox__label">{{ obj.name }}</label>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="chi-col" style="margin-top: 5px; margin-left: 15px">
            <label for="green">GREEN</label>
            <template v-if="greenNonBillableBanListData.length">
              <div v-for="(item, index) in greenNonBillableBanListData" :key="index">
                <div class="chi-form__item -d--inline-flex" style="margin-top: 5px">
                  <div class="chi-checkbox">
                    <input
                      :id="'green_' + index"
                      :disabled="!hasEditPermission"
                      type="checkbox"
                      class="chi-checkbox__input"
                      @click="changeGreenNonBillable(item)"
                    />
                    <label :for="'green_' + index" class="chi-checkbox__label">{{
                      item.name
                    }}</label>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="-mt--2 -d--flex -flex-gap-sm -mr--2" style="gap: 1rem">
      <div class="-w--25">
        <div class="chi-form__item">
          <label class="chi-label" for="taskOrder">
            Task Order <abbr class="chi-label__required" aria-label="Required field">*</abbr>
          </label>
          <input
            type="text"
            class="chi-input"
            id="taskOrder"
            v-model="taskorder"
            :disabled="!hasEditPermission"
            :maxLength="75"
            @keypress="onTaskOrderNumberChange($event)"
            @change="getTaskOrder($event)"
          />
          <div class="chi-label -status -danger" v-if="taskOrderValidationFlag">
            Atleast one taskorder is required
          </div>
        </div>
      </div>
      <div class="-w--25 -d--flex" style="justify-content: flex-end">
        <div>
          <div>
            <button
              class="chi-button -xs -mb--1"
              @click="addTaskOrderValue()"
              :disabled="!hasEditPermission"
            >
              <div class="chi-button__content">
                <span>Add</span>
                <chi-icon icon="chevron-right" size="xs"></chi-icon>
              </div>
            </button>
          </div>
          <div>
            <button
              class="chi-button -xs"
              :disabled="!hasEditPermission || !editableTaskOrdersListData.length"
              @click="
                editableTaskOrdersListData
                  .filter((order: any) => order.checked)
                  .forEach((order: any) => removeTaskOrderValue(order))
              "
            >
              <div class="chi-button__content">
                <span style="width: 52px">Remove</span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="-w--45">
        <div class="chi-grid -h--4 -b--1 -b--base-dark" style="margin-left: 2px">
          <div class="chi-form__item">
            <ul>
              <li>
                Options grayed out are because Service Orders exist already for this task order,
                therefore edits to Task Order Number cannot be made.
              </li>
            </ul>
            <template v-if="nonEditableTaskOrders && nonEditableTaskOrders.length">
              <div v-for="(obj, index) in nonEditableTaskOrders" :key="index" class="chi-grid__row">
                <div class="chi-col -d--inline-flex">
                  <div class="chi-checkbox">
                    <input
                      type="checkbox"
                      class="chi-checkbox__input"
                      :id="'nonEditable_' + index"
                      v-model="obj.isSelected"
                      disabled
                    />
                    <label
                      class="chi-checkbox__label profile-pic -ml--1 -text--muted"
                      :for="'nonEditable_' + index"
                      style="font-family: 'Inter'; font-size: 16px"
                    >
                      {{ obj }}
                    </label>
                  </div>
                </div>
              </div>
            </template>
            <div
              class="chi-grid non-billable__bans -h--4"
              style="margin-top: 4px; margin-left: 1px"
            >
              <div
                class="chi-col -w--6"
                v-for="(data, index) in editableTaskOrdersListData"
                :key="index"
              >
                <div class="chi-form__item">
                  <div class="chi-checkbox" style="margin-left: 5px; margin-top: 5px">
                    <input
                      type="checkbox"
                      class="chi-checkbox__input"
                      :id="'editable_' + index"
                      v-model="data.isSelected"
                      :disabled="!hasEditPermission"
                      @change="changeNonEditableOrder(data)"
                    />
                    <label
                      class="chi-checkbox__label"
                      :for="'editable_' + index"
                      style="font-family: 'Inter'; font-size: 16px"
                    >
                      {{ data.name }}
                      <chi-icon
                        icon="edit"
                        class="edit-icon"
                        color="navy"
                        :disabled="!hasEditPermission"
                        @click="editIconChange(data)"
                      ></chi-icon>
                    </label>
                  </div>
                </div>
                <div class="chi-grid editIcon" v-if="textInput && data.edit">
                  <div class="chi-col -w--4">
                    <chi-text-input
                      :id="'text__' + index"
                      type="text"
                      :disabled="!hasEditPermission"
                      @change="geteditTaskOrder($event, data)"
                    ></chi-text-input>
                  </div>
                  <div class="chi-col -ml--1">
                    <chi-icon
                      icon="check-alt"
                      size="xs"
                      color="success"
                      class="updateIcon -mr--1"
                      :disabled="!hasEditPermission"
                      @click="changeTaskOrder(data)"
                    ></chi-icon>
                    <chi-icon
                      icon="x-alt"
                      size="xs"
                      color="danger"
                      class="updateIcon"
                      :disabled="!hasEditPermission"
                      @click="closeTaskOrder(data)"
                    ></chi-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chi-form__item -mt--2">
      <label class="chi-label" for="toBizorg">
        TO Bizorg
        <abbr class="chi-label__required" aria-label="Required field">*</abbr>
      </label>
      <input
        type="text"
        class="chi-input -w--25"
        id="toBizorg"
        v-model="tobizOrg"
        maxlength="25"
        :disabled="!hasEditPermission"
        @change="getInputValue($event, 'tobizOrg')"
        :class="formErrors && !tobizOrg ? '-danger' : ''"
      />
      <div class="chi-label -status -danger" v-if="formErrors && !tobizOrg">
        Biz Org is required
      </div>
    </div>
    <div class="chi-label -mt--2">Billing Contact</div>
    <div>
      <template v-for="(group, groupIndex) in groupedBillingFields" :key="groupIndex">
        <div class="chi-grid -mt--2">
          <div class="chi-col -w--3" v-for="field in group" :key="field.name">
            <div class="chi-form__item">
              <chi-label>{{ field.label }}</chi-label>
              <input
                v-if="field.name === 'billingTelephone'"
                v-model="field.value"
                :maxlength="14"
                class="chi-input"
                :type="field.type"
                :disabled="!hasEditPermission"
                @input="onPhoneNumberChange($event)"
              />
              <input
                v-else-if="field.name !== 'billingState'"
                :maxlength="field.maxlength"
                class="chi-input"
                :type="field.type"
                :disabled="!hasEditPermission"
                v-model="field.value"
                @keypress="onNumberFieldKeyDown($event, field)"
                @input="onFieldValueChange($event, field)"
                @change="getInputValue($event, field.name)"
              />
              <select
                v-else
                class="chi-select"
                :disabled="!hasEditPermission"
                v-model="field.value"
                @change="getInputValue($event, field.name)"
              >
                <option value="">Select</option>
                <option
                  v-for="state in states"
                  :key="state.abbreviation"
                  :value="state.abbreviation"
                >
                  {{ state.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="-mb--2">
      <div class="chi-form__item" style="width: 49.3%">
        <label class="chi-label" for="billingNotes">Notes</label>
        <textarea
          id="billingNotes"
          v-model="billingNotes"
          maxlength="150"
          class="chi-input"
          :disabled="!hasEditPermission"
          @change="getInputValue($event, 'billingNotes')"
        >
        </textarea>
      </div>
    </div>
    <div class="chi-grid -d--flex -justify-content--center">
      <button
        class="chi-button -primary -m--1"
        @click="submitAccount()"
        :disabled="!hasEditPermission"
      >
        {{ updateButtonLabel }}
      </button>
      <button class="chi-button -m--1" @click="goBackToPage()">Back To Lookup</button>
    </div>
    <div class="chi-backdrop -center" v-if="isSubmitAccountPressed && !isSingleTaskOrder">
      <div class="chi-backdrop__wrapper">
        <section class="chi-modal" role="dialog" aria-label="Modal description" aria-modal="true">
          <header class="chi-modal__header">
            <h2 class="chi-modal__title">Warning</h2>
            <button
              class="chi-button -icon -close"
              aria-label="Close"
              @click="submitAccountCancel()"
            >
              <div class="chi-button__content">
                <i class="chi-icon icon-x" aria-hidden="true"></i>
              </div>
            </button>
          </header>
          <div class="chi-modal__content">
            <p class="-text -m--0">
              There are more than one task order numbers associated with this account. This is ok
              for non-production environments only, do you wish to proceed
            </p>
          </div>
          <footer class="chi-modal__footer">
            <button class="chi-button" @click="submitAccountCancel()">Cancel</button>
            <button class="chi-button -primary" @click="submitAccountConfirm()">Save</button>
          </footer>
        </section>
      </div>
    </div>
    <div class="chi-backdrop -center" v-if="showTaskOrderEmptyFiles">
      <div class="chi-backdrop__wrapper">
        <section class="chi-modal" role="dialog" aria-label="Modal description" aria-modal="true">
          <header class="chi-modal__header">
            <h2 class="chi-modal__title">Warning</h2>
            <button
              class="chi-button -icon -close"
              aria-label="Close"
              @click="submitAccountCancel()"
            >
              <div class="chi-button__content">
                <i class="chi-icon icon-x" aria-hidden="true"></i>
              </div>
            </button>
          </header>
          <div class="chi-modal__content">
            <p class="-text -m--0">
              Empty Files have already been transmitted for this TO, confirm with items in Queries >
              Transmitted Files > View Empty Files
            </p>
            <br />
            <span v-for="(fileName, index) in emptyFilesList" :key="index">
              {{ fileName }}
              <br />
            </span>
          </div>
          <footer class="chi-modal__footer">
            <button class="chi-button" @click="submitAccountCancel()">Cancel</button>
            <button class="chi-button -primary" @click="submitAccount('true')">OK</button>
          </footer>
        </section>
      </div>
    </div>
  </div>
</template>
<style scoped>
.profile-pic:hover .edit {
  display: inline-block;
}

.edit {
  display: none;
}

.updateIcon {
  background-color: lightgrey;
  padding: 5px;
}
.required::after {
  content: '*';
  color: red;
}

.-flex-gap-sm {
  gap: 2em;
}

.-w--45 {
  width: 45%;
}

.non-billable__bans {
  padding: 2px;
}

.-h--4 {
  min-height: 4em;
}
.edit-icon {
  visibility: hidden;
  margin-left: 8px; /* Adjust as needed for spacing */
}

.chi-checkbox__label:hover .edit-icon {
  visibility: visible;
}

.updateIcon {
  cursor: pointer;
}
.chi-checkbox__label {
  font-family: 'Inter';
  font-size: '16px';
}

.chi-button {
  text-transform: none;
}
</style>
