<script lang="ts" setup>
import { defineProps, withDefaults, ref, onMounted, defineEmits, watch } from 'vue'
import { useTOAdminStore } from '../../../store/to-admin'
import CommonTaskOrderKpisPopup from './CommonTaskOrderKpisPopup.vue'
import moment from 'moment'
import { storeToRefs } from 'pinia'

export interface Props {
  selectedOnChange: any
  createKPI: boolean
  taskOrderNumber: any
  taskOrderId: any
  tabName: any
  createKPIList: any
  createCustomKPIList: any
  billingEditedList: any
  kpiGrp: string
  perfStdThreshold: any
}
const props = withDefaults(defineProps<Props>(), {
  selectedOnChange: '',
  createKPI: false,
  taskOrderNumber: '',
  taskOrderId: '',
  tabName: '',
  createKPIList: '',
  createCustomKPIList: '',
  billingEditedList: '',
  kpiGrp: undefined,
  perfStdThreshold: ''
})
const emit = defineEmits(['addCustomKpi'])
const servicesDesc = ref('')

const serviceId = ref('')
const updatedKpis = ref<any[]>([])

const incrementer = ref(0)
const customIncrementer = ref(0)
const refKPIs = ref<any[]>([])
const allKPIsList = ref<any[]>([])
const allKpis = ref<any[]>([])
const standardCustomEdit_list = ref<boolean[]>([])
const standardCustom_list = ref<boolean[]>([])
const showTaskOrderKpis = ref(false)
const openPopUp = ref(false)
const showEditTaskOrderKpis = ref(false)
const selectedOptions = ref<'standard'>
const viewKPIButtonVisible = ref<Record<string, boolean>>({})
const isLoading = ref(false)
const isSaveEnable = ref<boolean>(false)
const toAdminStore = useTOAdminStore()
toAdminStore.performanceStorage.values = <any>[]
toAdminStore.outageStorage.values = <any>[]
toAdminStore.provisioningStorage.values = <any>[]
const customKPIList = ref<any[]>([])
const billingKpisList = ref<any[]>([])
const originalKpis = ref<any[]>([])
const forceUpdateKey = ref(0)
const customIdentification = ref(false)

const { deleteKpiFromModal } = storeToRefs(toAdminStore)

interface CustomKpiDTO {
  thresholdUpdatedKpis: any[]
}
watch(deleteKpiFromModal, (newValue: any) => {
  deleteKPIs(newValue)
  customKPIList.value = Array.isArray(newValue) ? newValue : [newValue]
  customKPIList.value.forEach((customkpi: any) => {
    if (customkpi.stdCustomIndicator === 'Custom KPI') {
      var index = customkpi.svcId + '#' + customkpi.kpiGrpType
      viewKPIButtonVisible.value[index] = false
    }
  })
  customIncrementer.value++
})
function deleteKPIs(kpisToDelete: any) {
  const kpisArray = Array.isArray(kpisToDelete) ? kpisToDelete : [kpisToDelete]
  const kpiList = props.createCustomKPIList
  let svcId = ''
  kpisArray.forEach((kpiToDelete: any, index: number) => {
    const matchedIndex = kpiList.findIndex((kpi: any) => kpi.kpiId === kpiToDelete?.kpiId)
    if (matchedIndex >= 0) {
      svcId = kpiList[matchedIndex].svcId
      kpiList.splice(matchedIndex, 1)
      kpisArray.splice(index, 1) // Remove the element from kpisArray
    }
  })

  if (kpisArray.length === 0 || kpisArray == null) {
    if (props.kpiGrp == 'PERF - Product Specific Performance') {
      const indexPerf = toAdminStore.performanceStorage.findIndex((kpi: any) => kpi === svcId)
      if (indexPerf >= 0) {
        toAdminStore.performanceStorage.splice(indexPerf, 1)
      }
    } else if (props.kpiGrp == 'OUT - Service Outage') {
      const indexOutg = toAdminStore.outageStorage.findIndex((kpi: any) => kpi === svcId)
      if (indexOutg >= 0) {
        toAdminStore.outageStorage.splice(indexOutg, 1)
      }
    } else {
      const indexProv = toAdminStore.provisioningStorage.findIndex((kpi: any) => kpi === svcId)
      if (indexProv >= 0) {
        toAdminStore.provisioningStorage.splice(indexProv, 1)
      }
    }
  }
}
const customKpiDTO = ref<CustomKpiDTO>({ thresholdUpdatedKpis: [] })
onMounted(() => {
  isLoading.value = true
  toAdminStore
    .getRefKpis()
    .then((response: any) => {
      refKPIs.value = response
      isLoading.value = false
    })
    .catch(() => {
      isLoading.value = false
    })
  if (!props.createKPI) {
    if (!props.createCustomKPIList) {
      emit('addCustomKpi', allKPIsList.value)
    }

    isLoading.value = true
    toAdminStore
      .getAllKPIs(props.taskOrderId)
      .then((response: any) => {
        allKPIsList.value = response
        isLoading.value = false
      })
      .catch(() => {
        isLoading.value = false
      })
  }
  // Assuming selectedOnChange is a reactive reference to an array of indices
  props.selectedOnChange.forEach((index: any) => {
    standardCustom_list.value[index] = false
    standardCustomEdit_list.value[index] = false
  })
})

function hasStandardKpi(serviceID: any) {
  const standardKPI: any = refKPIs?.value.find(
    (refkpi: any) => refkpi.svcId === serviceID && refkpi.kpiGrpType === props.kpiGrp
  )
  return standardKPI?.stdCustomIndicator === 'Standard KPI'
}

const hasStandardEditKpi = (serviceID: any) => {
  const standardKPI: any = allKPIsList.value?.find(
    (kpi: any) => kpi.svcId === serviceID && kpi.kpiGrpType === props.kpiGrp
  )
  return standardKPI?.stdCustomIndicator === 'Standard KPI'
}

const hasCustomCreateKpi = (serviceID: any) => {
  const customKPI: any = props.createCustomKPIList.find(
    (customkpi: any) => customkpi.svcId === serviceID && customkpi.kpiGrpType === props.kpiGrp
  )
  return customKPI?.stdCustomIndicator === 'Custom KPI'
}

const hasCustomEditKpi = (serviceID: any) => {
  const combinedKpisList: any[] = ([] as any[]).concat(allKPIsList.value, updatedKpis.value)
  let hasCustomKpi = false
  combinedKpisList.forEach((customkpi) => {
    if (
      customkpi.svcId === serviceID &&
      customkpi.kpiGrpType === props.kpiGrp &&
      customkpi.stdCustomIndicator === 'Custom KPI'
    ) {
      hasCustomKpi = true
    }
  })
  return hasCustomKpi
}
const updateRadioButtonSelect = (
  type: any,
  inValue: boolean,
  event: any,
  indexs: any,
  serviceID: string,
  serviceDesc: string
) => {
  standardCustomEdit_list.value[indexs] = inValue
  standardCustom_list.value[indexs] = inValue
  if (props.createKPI) {
    openPopUp.value = true
    showTaskOrderKpis.value = true
  } else {
    showEditTaskOrderKpis.value = true
  }
  if (type === 'custom') {
    servicesDesc.value = serviceDesc
    serviceId.value = serviceID
  }
}
const viewCreateKPIHandler = (serviceID: any) => {
  serviceId.value = serviceID
  incrementer.value++
  if (props.createKPI) {
    showTaskOrderKpis.value = true
    openPopUp.value = true
  } else {
    showEditTaskOrderKpis.value = true
    openPopUp.value = true
  }
}
function editKPISave() {
  isLoading.value = true
  isSaveEnable.value = true
  prepareCustomServiceKpis()
}
function addCustomKpisList(updatekpi: any): void {
  customKPIList.value = Array.isArray(updatekpi._rawValue)
    ? updatekpi._rawValue
    : [updatekpi._rawValue]
  customKPIList.value.forEach((customkpi: any) => {
    if (customkpi.stdCustomIndicator === 'Custom KPI') {
      var index = customkpi.svcId + '#' + customkpi.kpiGrpType
      viewKPIButtonVisible.value[index] = true
      if (customkpi.kpiGrpType == 'PERF - Product Specific Performance') {
        if (!toAdminStore.performanceStorage.includes(customkpi.svcId)) {
          toAdminStore.performanceStorage.push(customkpi.svcId)
        }
      } else if (customkpi.kpiGrpType == 'OUT - Service Outage') {
        if (!toAdminStore.outageStorage.includes(customkpi.svcId)) {
          toAdminStore.outageStorage.push(customkpi.svcId)
        }
      } else {
        if (!toAdminStore.provisioningStorage.includes(customkpi.svcId)) {
          toAdminStore.provisioningStorage.push(customkpi.svcId)
        }
      }
    }
  })
  emit('addCustomKpi', updatekpi._rawValue)
  customIncrementer.value++
}
function cancelTaskOrderKpis() {
  openPopUp.value = false
  showEditTaskOrderKpis.value = false
  showTaskOrderKpis.value = false
  props.selectedOnChange.forEach((service: any, index: any) => {
    forceUpdateKey.value++
    standardCustom_list.value[index] = false
    standardCustomEdit_list.value[index] = false
    const customKPI = hasCustomCreateKpi(service.serviceId)
    if (!customKPI) {
      updateRadioButtonSelect(
        'custom',
        false,
        undefined,
        index,
        service.serviceId,
        service.serviceDesc
      )
      showTaskOrderKpis.value = false
      showEditTaskOrderKpis.value = false
    }
  })
}
function saveEditTaskOrderKpis(kpis: any) {
  kpis._rawValue.forEach((upkpi: any) => {
    let hasPrevious = false
    updatedKpis.value.forEach((kpi: any, i: any) => {
      if (kpi.kpiId === upkpi.kpiId) {
        updatedKpis.value[i] = upkpi
        hasPrevious = true
      }
    })
    if (!hasPrevious) {
      updatedKpis.value.push(upkpi)
    }
  })
}
async function prepareCustomServiceKpis() {
  const serviceIds = props.selectedOnChange.map((service: any) => {
    return service.serviceID
  })
  if (props.createKPI && billingKpisList.value.length > 0) {
    billingKpisList.value.forEach((billingKPI: any) => {
      updatedKpis.value.push(billingKPI)
    })
  }
  if (!props.createKPI && props.billingEditedList && props.billingEditedList.length > 0) {
    props.billingEditedList.forEach((billingKPI: any) => {
      updatedKpis.value.push(JSON.parse(JSON.stringify(billingKPI)))
    })
  }
  updatedKpis.value = updatedKpis.value.map((kpi: any) => {
    if (kpi.effectiveStartDate && moment(kpi.effectiveStartDate, 'MM/DD/YYYY', true).isValid()) {
      kpi.effectiveStartDate = `${moment(kpi.effectiveStartDate, 'MM/DD/YYYY').format(
        'YYYY-MM-DD'
      )}T00:00:00`
    }
    if (kpi.effectiveEndDate && moment(kpi.effectiveEndDate, 'MM/DD/YYYY', true).isValid()) {
      kpi.effectiveEndDate = `${moment(kpi.effectiveEndDate, 'MM/DD/YYYY').format(
        'YYYY-MM-DD'
      )}T00:00:00`
    }
    if (kpi.kpiType === 'New Custom KPI') {
      kpi.kpiId = null
    }
    kpi.taskOrderId = props.taskOrderId ?? null
    kpi.taskOrderNumber = props.taskOrderNumber ?? null

    return kpi
  })
  const response = await toAdminStore.getAllKPIs(props.taskOrderId)
  try {
    if (Array.isArray(response)) {
      allKpis.value = response
      isLoading.value = false
      originalKpis.value = allKpis.value.filter((kpi: any) => serviceIds.includes(kpi.svcId))
      for (let o of serviceIds) {
        if (!allKpis.value.find((i: any) => i.svcId == o)) {
          let filterKpis = refKPIs.value.filter((refkpi: any) => refkpi.svcId === o)
          filterKpis.map((kpi: any) => {
            kpi.effectiveStartDate = formatDateAndTime(kpi.effectiveStartDate)
            kpi.effectiveEndDate = formatDateAndTime(kpi.effectiveEndDate)
            kpi.taskOrderId = props.taskOrderId ?? null
            kpi.taskOrderNumber = props.taskOrderNumber ?? null
            kpi.perfStdThreshold = props.perfStdThreshold ?? null
          })
          originalKpis.value.push(...filterKpis)
        }
      }
      originalKpis.value.forEach(async (originalKpi: any) => {
        const foundRecord = updatedKpis.value.find((kpi: any) => kpi.kpiId === originalKpi.kpiId)
        if (!foundRecord) {
          if (
            originalKpi.effectiveStartDate &&
            moment(originalKpi.effectiveStartDate, 'MM/DD/YYYY', true).isValid()
          ) {
            originalKpi.effectiveStartDate = `${moment(
              originalKpi.effectiveStartDate,
              'MM/DD/YYYY'
            ).format('YYYY-MM-DD')}T00:00:00`
          }
          if (
            originalKpi.effectiveEndDate &&
            moment(originalKpi.effectiveEndDate, 'MM/DD/YYYY', true).isValid()
          ) {
            originalKpi.effectiveEndDate = `${moment(
              originalKpi.effectiveEndDate,
              'MM/DD/YYYY'
            ).format('YYYY-MM-DD')}T00:00:00`
          }
          updatedKpis.value.push(JSON.parse(JSON.stringify(originalKpi)))
        }
      })
      customKpiDTO.value = { thresholdUpdatedKpis: JSON.parse(JSON.stringify(updatedKpis.value)) }
      await toAdminStore
        .createServiceKpis(customKpiDTO.value)
        .then(async (response: any) => {
          updatedKpis.value = []
          await toAdminStore.getAllKPIs(props.taskOrderId)
          isSaveEnable.value = false
          isLoading.value = false
        })
        .catch((error: any) => {
          console.log('create kpi fail', error)
        })
    }
  } catch (error) {
    isLoading.value = false
  }
}
function formatDateAndTime(dateString: string) {
  return dateString ? moment(String(dateString)).format('MM/DD/YYYY') : ''
}

function checkForButtonLabel(value: string): boolean {
  if (props.kpiGrp == 'PERF - Product Specific Performance') {
    if (toAdminStore.performanceStorage.includes(value)) {
      return true
    } else {
      return false
    }
  } else if (props.kpiGrp == 'OUT - Service Outage') {
    if (toAdminStore.outageStorage.includes(value)) {
      return true
    } else {
      return false
    }
  } else {
    if (toAdminStore.provisioningStorage.includes(value)) {
      return true
    } else {
      return false
    }
  }
}
</script>

<template>
  <div style="margin-top: 20px">
    <div v-if="kpiGrp !== 'BILL - Billing Accuracy '">
      <label class="chi-label -lg">Service</label>
      <div
        v-for="(s, index) in selectedOnChange"
        :key="`${s}-${index}`"
        class="chi-container-fluid -text--normal -text--md"
      >
        <div class="chi-grid">
          <div class="chi-col -w--3 -mb--2">
            {{ s.serviceDesc }}
          </div>
          <div v-if="isLoading" class="chi-col">
            <svg class="chi-spinner -icon--primary -xs" viewBox="0 0 66 66">
              <title>Loading</title>
              <circle class="path" cx="33" cy="33" r="30" fill="none" stroke-width="6"></circle>
            </svg>
          </div>
          <div v-else class="chi-col -w--1">
            <div class="chi-form__item -inline">
              <div :key="customIncrementer">
                <div>
                  <div
                    v-if="
                      (!hasStandardKpi(s.serviceID) && !hasStandardEditKpi(s.serviceID)) ||
                      hasCustomEditKpi(s.serviceID) ||
                      viewKPIButtonVisible[`${s.serviceID}` + '#' + `${kpiGrp}`]
                    "
                  >
                    <button
                      v-if="checkForButtonLabel(s.serviceID)"
                      class="chi-button -xs -ml--2"
                      @click="viewCreateKPIHandler(s.serviceID)"
                    >
                      View/Edit KPI
                    </button>
                    <button
                      v-else
                      class="chi-button -primary -xs -ml--2"
                      @click="viewCreateKPIHandler(s.serviceID)"
                    >
                      Add Custom KPIs
                    </button>
                  </div>
                  <div
                    v-else-if="
                      hasStandardKpi(s.serviceID) ||
                      hasStandardEditKpi(s.serviceID) ||
                      (hasCustomEditKpi(s.serviceID) &&
                        !viewKPIButtonVisible[`${s.serviceID}` + '#' + `${kpiGrp}`])
                    "
                  >
                    <button
                      v-if="checkForButtonLabel(s.serviceID)"
                      class="chi-button -xs -ml--2"
                      @click="viewCreateKPIHandler(s.serviceID)"
                    >
                      View/Edit KPI
                    </button>
                    <button
                      v-else
                      class="chi-button -primary -xs -ml--2"
                      @click="viewCreateKPIHandler(s.serviceID)"
                    >
                      Add Custom KPIs
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!createKPI" class="-mt--5 -text--center">
      <button :disabled="isSaveEnable" class="chi-button -sm -primary -mr--2" @click="editKPISave">
        Save
      </button>
      <button class="chi-button -sm" :disabled="isSaveEnable">Cancel</button>
    </div>
  </div>
  <div v-if="openPopUp">
    <CommonTaskOrderKpisPopup
      :key="incrementer"
      :service-desc="servicesDesc"
      :show="!createKPI ? showEditTaskOrderKpis : showTaskOrderKpis"
      :popup-name="!createKPI ? 'editTaskOrderKPIPopup' : 'taskOrderKPIPopup'"
      :custom-k-p-i-list="customKPIList"
      :create-custom-k-p-i-list="createCustomKPIList"
      :service-id="serviceId"
      :kpi-grp="kpiGrp"
      :task-order-id="taskOrderId"
      @add-custom-kpis-list="addCustomKpisList"
      @save-task-order-options="saveEditTaskOrderKpis"
      @cancel-task-order-options="cancelTaskOrderKpis"
    />
  </div>
</template>

<style scoped>
.service-desc {
  margin-bottom: 10px;
}
</style>
