<script lang="ts" setup>
import { watch, ref, Ref, onMounted } from 'vue'
import ImportFileComponent from '../../components/ImportFile.vue'
import { Modal } from '@centurylinkfederal/eis-vue'
const emits = defineEmits(['importContent', 'closeModal', 'modal', 'applyNSC'])
interface IProps {
  title: string
  jurisSvcId: string
  editLocations: any
  mapOfNSC: any
}
const props = defineProps<IProps>()
const id = ref<number>()
const type = ref<string>('')
const jurisSvcId = ref<string>()
const editLocations = ref<any[]>([])
const values = props.mapOfNSC.get(props.jurisSvcId)
if (values) {
  editLocations.value = values
}

interface Location {
  networkSiteCode: ''
  location: ''
}
interface OriginService {
  locations: Location[]
  acronym: string
  serviceID: string
}
const originService: Ref<OriginService> = ref({
  locations: [],
  acronym: '',
  serviceID: ''
})
let svcCode = ''
let errorMsg = ''
const changingId = ref<number>()
const isOpen = ref(false)
const showLocationsModal = ref(false)
const fileName = ref<string>()
const fileContent = ref<string>()
const headers = ['NSC', 'Location']
const fields = ['networkSiteCode', 'location']
const actionButtons = [
  { text: 'Apply' },
  { text: 'Add More' },
  { text: 'Import' },
  {
    text: 'Close',
    dismissModal: true
  }
]
function closeModal() {
  emits('closeModal', true)
}
function onButtonClick(index: number) {
  if (index === 0) {
    save()
  } else if (index === 1) {
    add()
  } else if (index === 2) {
    showLocationsModal.value = true
  } else {
    emits('closeModal', true)
  }
}
onMounted(async () => {
  console.log('mounted')
})
function beforeOpen(event: any) {
  editLocations.value = []
  originService.value = event.params && event.params.selected
  svcCode = originService.value && originService.value.acronym
  if (type.value == undefined) {
    type.value = event.params && event.params.type
  }
  if (type.value == 'Create') {
    if (event.params && event.params.juriId == svcCode) {
      if (event.params.selected.checked.some((c: any) => !c)) {
        event.params.selected.checked = []
        if (svcCode === event.params && event.params.juriId) {
          originService.value.locations = [{ networkSiteCode: '', location: '' }]
          editLocations.value = []
        }
      }
    }

    if (
      originService.value &&
      originService.value.locations &&
      originService.value.locations.length
    ) {
      editLocations.value = originService.value.locations.map((a: any) => ({ ...a }))
    } else {
      add()
    }
  } else if (type.value == 'Edit') {
    editLocations.value = originService.value.locations.map((a: any) => ({ ...a }))
  }
  changingId.value = event.params && event.params.changingId
}

function add() {
  const loc = {
    taskOrderNscId: 0,
    networkSiteCode: '',
    location: '',
    taskOrderSvc: {}
  }
  editLocations.value = [...editLocations.value, loc]
}
function remove(index: number) {
  if (editLocations.value && editLocations.value.length > 0) {
    editLocations.value.splice(index, 1)
  } else {
    if (type.value == 'Create') {
      add()
    }
  }
}
function save() {
  let tempEditLocations: any = []
  for (let i = 0; i < editLocations.value.length; i++) {
    if (editLocations.value[i].networkSiteCode !== '' || editLocations.value[i].location !== '') {
      const loc = {
        taskOrderNscId: 0,
        networkSiteCode: editLocations.value[i].networkSiteCode,
        location: editLocations.value[i].location,
        taskOrderSvc: {}
      }
      tempEditLocations.push(loc)
    }
  }
  editLocations.value = [...tempEditLocations]
  originService.value.locations = [...tempEditLocations]
  originService.value.serviceID = props.jurisSvcId
  emits('applyNSC', originService, changingId)
  if (type.value == 'Create') {
    close()
  } else {
    close()
  }
}
function close() {
  emits('closeModal', true)
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
function importedFileInfo(details: any = {}) {
  const { isValid, contents } = details
  contents.forEach((content: any) => {
    content.id = uuidv4()
    content.errors = {}
  })
  editLocations.value = editLocations.value.filter((item) => item.networkSiteCode && item.location)
  editLocations.value = [...editLocations.value, ...(contents || [])]
  showLocationsModal.value = false
}
</script>

<template>
  <Modal
    name="edit-nsc-options"
    height="auto"
    @before-open="beforeOpen"
    @onButtonClick="onButtonClick"
    @on-dismiss-modal="closeModal"
    :buttons="actionButtons"
    :title="props.title"
    :jurisSvcId="props.jurisSvcId"
  >
    <div class="chi-card">
      <div class="chi-card__content">
        <div class="table">
          <div class="table-heading">
            <div class="row-item">
              <div class="table-cell">
                <div class="label-align"><strong>NSC</strong></div>
              </div>
              <div class="table-cell">
                <div class="label-align"><strong>Location</strong></div>
              </div>
              <div class="table-cell"></div>
            </div>
          </div>
          <div style="overflow-y: auto">
            <div
              class="row-item"
              v-for="(location, index) in editLocations"
              :key="`nsc_options_${index}`"
            >
              <div class="table-cell">
                <input class="chi-input" type="text" v-model="location.networkSiteCode" />
              </div>
              <div class="table-cell">
                <input class="chi-input" type="text" v-model="location.location" />
              </div>
              <div class="table-cell">
                <div class="label-align">
                  <chi-button
                    variant="flat"
                    type="icon"
                    style="width: 50px; height: 20px"
                    @click="remove(index)"
                  >
                    <chi-icon icon="delete" />
                  </chi-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="chi-card__footer -noBorder -alignCenter">
        <button class="chi-button -sm -mr--2" @click="save">Apply</button>
        <button class="chi-button -sm -mr--2" @click="add">Add More</button>
        <button class="chi-button -sm -mr--2" @click="openDialog">Import</button>
        <button class="chi-button -sm" @click="close">Close</button>
      </div> -->
    </div>
    <!-- Import Modal -->
    <div v-if="showLocationsModal">
      <ImportFileComponent
        title="Locations"
        :showModal="showLocationsModal"
        :dataFields="fields"
        :headerDetails="headers"
        @importContent="importedFileInfo($event)"
        @closeModal="showLocationsModal = false"
      />
    </div>
  </Modal>
</template>

<style scoped>
.table {
  margin-bottom: 0px;
}
.table-row {
  display: flex;
}
.table-heading > .row-item {
  background-color: #38c6f4;
  height: 45px;
  color: white;
}
.row-item {
  display: flex;
  flex-wrap: nowrap;
  height: 45px;
}
.row-item:nth-child(even) {
  background: #dae5f4;
}
.row-item > .table-cell {
  padding: 0.25rem;
  border-right: 0.0625rem solid #d0d4d9;
  border-bottom: 0.0625rem solid #d0d4d9;
}
.row-item > .table-cell:first-child {
  border-left: 0.0625rem solid #d0d4d9;
  flex: 6;
}
.row-item > .table-cell:nth-child(2) {
  flex: 6;
}
.row-item > .table-cell:nth-child(3) {
  flex: 2;
}
.row-item > .table-cell .label-align {
  margin: auto !important;
  padding: 10px 0;
  width: 60%;
  text-align: center;
}
.chi-modal__content {
  overflow-y: auto;
  height: 350px;
}
</style>
