<script lang="ts" setup>
import { ref, computed, onMounted, watch, toRaw, watchEffect, nextTick } from 'vue'
import { storeToRefs } from 'pinia'
import { getStore } from './store'
import { useStore as useKpisStore } from './kpisStore'
import { useColumns as useKpisColumns } from './kpisColumns'
import { useColumns as useBillingColumns } from './billingColumns'
import { useStore as useServicesStore } from './servicesStore'
import { useViewStore } from '../../store/taskOrderAdminViewStore'
import { useColumns as useServicesColumns } from './servicesColumns'
import { useFormsStore } from '../../composables/forms'
import { useToAdminAPI } from '../../composables/to-admin/api'
import { affixFormat, arrayDiff } from '../../utils/helpers'
import { triggerForm, getFormChanges, makeFormQuirksMap } from '../../utils/form'
import {
  Row,
  Input,
  randomId,
  ButtonType,
  Signal,
  toBeOptional,
  fromMMDDYYYY
} from '@centurylinkfederal/eis-vue'
import { Pair, Rec } from '../../types/common'
import { toMap } from '../../utils/helpers'

export interface Props {
  pageMode: 'create' | 'edit'
}
const props = withDefaults(defineProps<Props>(), {
  pageMode: 'create'
})
const pageMode = ref(props.pageMode)
const pageStore = getStore(pageMode.value)()
const { selectedTaskOrderObj, resetSeq: pageResetSeq } = storeToRefs(pageStore)
const store = useKpisStore()
const servicesStore = useServicesStore()
const {
  form: profilesForm,
  formChanges: profilesFormChanges,
  formState,
  quirks,
  kpiStandardMap,
  kpisStandardInServiceMap,
  kpisInServiceMap
} = storeToRefs(store)
const { picks, resetSeq: servicesResetSeq } = storeToRefs(servicesStore)

const { alertMap, modalMap, signal, summary, activePanel, form: pageForm } = storeToRefs(pageStore)
const { loading } = storeToRefs(useViewStore())
const kpisColumnsStore = useKpisColumns()
const { columns, groups } = storeToRefs(kpisColumnsStore)
const billingColumnsStore = useBillingColumns()
const { columns: billingColumns } = storeToRefs(billingColumnsStore)
const serviceColumnsStore = useServicesColumns()
const { serviceMap, serviceOptions } = storeToRefs(serviceColumnsStore)
const { useForms, clearForms } = useFormsStore()
const api = useToAdminAPI()
const section = 'kpis'
const rows = ref<Row[]>([])
const emptyDataId = ref('emptyData-' + randomId())
const scrollOpts = { wait: 100, left: 0, useOffsetHight: true }
const expandSeq = ref<Pair>([null, 0])
const kpiCustomMap = ref(new Map())
const kpiCounterMap = ref(new Map())
const kpisCustomInServiceMap = ref(new Map())
const activeTab = ref('Performance')
const danger = ref(false)
const renderSeq = ref(0)
const tabs = computed(() => groups.value)
const tabGroupsByTitle = computed(() =>
  Object.fromEntries(tabs.value.map(({ title, group }: any) => [title, group]))
)
const tabGroupsByCode = computed(() =>
  Object.fromEntries(tabs.value.map(({ title, group }: any) => [group, title]))
)
const kpiSelectedMap = computed(() => {
  const map = new Map()
  for (const what in profilesForm.value) {
    for (const key in profilesForm.value[what]) {
      const input = profilesForm.value[what][key] ?? {}
      map.set([what, key].join(':'), input.value)
    }
  }
  return map
})
const customCounterByTitle = computed(() => {
  const counter: any = {}
  Array.from(kpiSelectedMap.value.entries())
    .filter(([, value]) => value === 'custom')
    .map(([key]) => key.split(':'))
    .forEach(([title]) => (counter[title] = (counter[title] ?? 0) + 1))
  return counter
})
const cells = ref<{
  [key: string]: any
}>({})

watch(
  () => customCounterByTitle.value,
  (newTitleCount) => {
    const numbers: number[] = Object.values(newTitleCount)
    const kpisCount = numbers.reduce((acc: number, cur: number) => acc + cur, 0)
    summary.value = { ...summary.value, kpisCount }
  }
)
watch(() => signal.value, onSignal)
watch(
  () => picks.value,
  (newSet) => {
    const servicesCount = newSet.size
    summary.value = { ...summary.value, servicesCount }
    renderCells()
  },
  { deep: true }
)
watch(
  () => servicesResetSeq.value,
  async () => {
    setTimeout(async () => await resetKpis())
  }
)

async function resetKpis(opts: any | undefined = undefined): Promise<void> {
  try {
    const { what, key, profile: _profile } = opts ?? {}
    const resetKey = what && key ? [what, key].join(':') : undefined
    const kpisRefsRes =
      kpiStandardMap.value.size > 0
        ? Array.from(kpiStandardMap.value.values())
        : ((await api.getRefKpis({ useCache: true })) ?? [])
    if (kpiStandardMap.value.size < 1 && kpisRefsRes.length > 0) {
      kpiStandardMap.value = toMap(kpisRefsRes, 'kpiId')
    }
    if (!resetKey) {
      kpisStandardInServiceMap.value.clear()
      kpisInServiceMap.value.clear()
      kpiCounterMap.value.clear()
    }
    const titleKeySet = new Set()
    const standardCounterMap = new Map()
    for (const { kpiId, svcId, kpiGrpType } of kpisRefsRes || []) {
      const kpiGroup = kpiGrpType?.split(' - ')[0]
      const kpiTitle = tabGroupsByCode.value[kpiGroup]
      const titleKey = `${kpiTitle}:${svcId}`
      titleKeySet.add(titleKey)
      const kpiSet = kpisStandardInServiceMap.value.get(titleKey) ?? new Set()
      kpiSet.add(kpiId)
      kpisStandardInServiceMap.value.set(titleKey, kpiSet)
      if (pageMode.value === 'create') {
        kpiCounterMap.value.set(`${titleKey}:standard`, kpiSet.size)
      } else {
        standardCounterMap.set(titleKey, kpiSet.size)
      }
    }
    for (const [kpiId, kpiData] of kpiStandardMap.value) {
      const kpiGroup = kpiData.kpiGrpType?.split(' - ')[0]
      const kpiTitle = tabGroupsByCode.value[kpiGroup]
      const titleKey = `${kpiTitle}:${kpiData.svcId}`
      if (resetKey && resetKey !== titleKey) {
        continue
      }
      if (!kpisStandardInServiceMap.value.has(titleKey)) {
        kpisStandardInServiceMap.value.set(titleKey, new Set())
      }
      kpisStandardInServiceMap.value.get(titleKey).add(Number(kpiId))
    }
    if (resetKey) {
      profilesForm.value[`${resetKey}:standard`] = {}
      profilesForm.value[`${resetKey}:custom`] = {}
    } else {
      profilesForm.value = {}
    }
    const taskOrderId =
      selectedTaskOrderObj.value?.originalTaskOrderId ?? selectedTaskOrderObj.value?.taskOrderId
    if (pageMode.value === 'edit' && taskOrderId) {
      const kpisRes = (await api.getAllKPIs(taskOrderId)) ?? []
      kpiCustomMap.value = new Map()
      const profilesRec: Rec = {}
      const rowsRec: Rec = {}
      const counterResetMap = new Map()
      for (const row of kpisRes) {
        const { kpiId, svcId, kpiGrpType } = row
        const kpiGroup = kpiGrpType?.split(' - ')[0]
        const kpiTitle = tabGroupsByCode.value[kpiGroup]
        const titleKey = `${kpiTitle}:${svcId}`
        if (resetKey && resetKey !== titleKey) {
          continue
        }
        {
          const kpiSet = kpisInServiceMap.value.get(titleKey) ?? new Set()
          kpiSet.add(kpiId)
          kpisInServiceMap.value.set(titleKey, kpiSet)
          kpiCustomMap.value.set(String(kpiId), row)
        }
        if (!counterResetMap.get(titleKey)) {
          kpiCounterMap.value.set(`${titleKey}:standard`, 0)
          kpiCounterMap.value.set(`${titleKey}:custom`, 0)
          counterResetMap.set(titleKey, true)
        }
        const isCustom = String(row.stdCustomIndicator).toLowerCase().includes('custom')
        if (isCustom) {
          const customCounter = kpiCounterMap.value.get(`${titleKey}:custom`) ?? 0
          kpiCounterMap.value.set(`${titleKey}:custom`, customCounter + 1)
        } else {
          const standardCounter = kpiCounterMap.value.get(`${titleKey}:standard`) ?? 0
          kpiCounterMap.value.set(`${titleKey}:standard`, standardCounter + 1)
          kpiStandardMap.value.set(kpiId, row)
        }
        {
          const profileKey = `${kpiTitle}:${svcId}`

          if (isCustom) {
            profilesRec[profileKey] = 'custom'
          } else if (!profilesRec[profileKey]) {
            profilesRec[profileKey] = profilesRec[profileKey] ?? 'standard'
          }

          const formKey = `${kpiTitle}:${svcId}:${profilesRec[profileKey]}`
          if (!rowsRec[formKey]) {
            rowsRec[formKey] = []
          }
          rowsRec[formKey].push(row)
        }
      }
      for (const [titleKey, standardCount] of standardCounterMap) {
        if (resetKey && resetKey !== titleKey) {
          continue
        }
        const kpiStandardCount = kpiCounterMap.value.get(`${titleKey}:standard`)
        const kpiCustomCount = kpiCounterMap.value.get(`${titleKey}:custom`)
        if (typeof kpiCustomCount === 'undefined' && typeof kpiStandardCount === 'undefined') {
          kpiCounterMap.value.set(`${titleKey}:standard`, standardCount)
        }
      }
      let profilesPatch = {}
      let titleInputs: Rec = {}
      for (const profileKey in profilesRec) {
        const [profileTitle, serviceCode] = profileKey.split(':')
        const titleKey = [profileTitle, serviceCode].join(':')
        if (resetKey && resetKey !== titleKey) {
          continue
        }
        const value = profilesRec[profileKey]
        const state = 'unchanged'
        const profileInputs: Rec = titleInputs[profileTitle] ?? {}
        profileInputs[serviceCode] =
          profileInputs[serviceCode] ??
          new Input({ field: String(serviceCode), value, state, tests: [toBeOptional] })
        profilesPatch = { ...profilesPatch, [profileTitle]: profileInputs }
        titleInputs = { ...titleInputs, [profileTitle]: profileInputs }
      }
      profilesForm.value = { ...profilesForm.value, ...profilesPatch }

      await nextTick()
      renderCells()
    }
    for (const titleKey of titleKeySet) {
      if (!resetKey || resetKey !== titleKey) {
        continue
      }
      const profile = 'custom'
      const formKey = `${titleKey}:${profile}`
      const modalForm = useForms(formKey)
      modalForm.rows.value = []
      modalForm.form.value = {}
      const [what, key] = formKey.split(':')
      loadKpisForm({ what, key, profile })
      modalForm.isFormLoaded.value = true
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
  formState.value = 'unchanged'
}
function loadKpisForm({ what, key, profile }: any): void {
  if (!what || !key) {
    return
  }
  const kpisForm = useForms([what, key, profile].join(':'))
  kpisForm.rows.value = []
  kpisForm.form.value = {}
  const columnsRef = what === 'Billing' ? billingColumns : columns
  let kpiSet = new Set()
  kpiSet = kpisInServiceMap.value.get([what, key].join(':')) ?? new Set()
  const semanticProfile = kpiSet.size > 0 ? 'custom' : 'standard'
  if (semanticProfile === 'custom') {
    kpiSet = kpisInServiceMap.value.get([what, key].join(':')) ?? new Set()
  } else {
    kpiSet = kpisStandardInServiceMap.value.get([what, key].join(':')) ?? new Set()
  }
  for (const kpiId of kpiSet) {
    let kpiRow: any
    if (semanticProfile === 'custom') {
      kpiRow = kpiCustomMap.value.get(String(kpiId))
    } else {
      kpiRow = kpiStandardMap.value.get(String(kpiId))
    }
    if (kpiRow) {
      kpisForm.rows.value.push(kpiRow)
      const inputs: any = {}
      for (const cell of columnsRef.value) {
        const { label, field, input } = cell
        const value = kpiRow[field] ?? input?.value
        const copy = value
        let disabled = input?.disabled
        if (field === '_deleted') {
          if (pageMode.value === 'edit') {
            if (String(kpiId).includes('_new-')) {
              disabled = false
            } else {
              disabled = true
            }
          }
          if (pageMode.value === 'create') {
            disabled = false
          }
        }
        if (field === 'kpiucUnitCode' && value && input?.options?.length) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          let inputOptions: any = Array.isArray(input?.options) ? [...input?.options] : []
          const kpiucUnitCodeTable = Object.fromEntries(inputOptions)
          if (!kpiucUnitCodeTable[value]) {
            kpiucUnitCodeTable[value] = `KPI Not Found: ${kpiRow.kpiucUnitCode}`
            input.options = Array.from(Object.entries(kpiucUnitCodeTable))
          }
        }
        inputs[field] = new Input({ ...input, label, field, value, copy, disabled })
        inputs[field].copy = copy
      }
      kpisForm.form.value[Number(kpiId)] = inputs
    }
  }
  formState.value = 'unchanged'
}
function emptyQuirk(): any {
  return {
    value: new Map(),
    copy: new Map(),
    draft: new Map()
  }
}
function onChangeClick(profile: any, key: string | number, what: string): void {
  let title, pickerBox, dataCards, dataTable, modalStyle, backdropStyle
  const buttonMap: Map<string | number, ButtonType> = new Map([
    ['Cancel', { text: 'Cancel', uppercase: true, dismissModal: true }],
    ['Undo', { text: 'Undo', uppercase: true, dismissModal: false, class: '-d--none' }],
    ['Reset', { text: 'Reset', uppercase: true, dismissModal: false, class: '-d--none' }],
    [
      'Apply',
      {
        text: 'Apply',
        uppercase: true,
        dismissModal: false,
        color: 'primary'
      }
    ]
  ])

  const actionMap = new Map([
    ['New', { text: 'Add new record', icon: 'circle-plus', dismissModal: false }]
  ])
  const quirk = quirks.value.get([what, key, profile].join(':')) ?? emptyQuirk()
  {
    const defaultProfile = standardOrNone(what)
    const profileInputs = profilesForm.value[what] ?? {}
    profileInputs[key] =
      profileInputs[key] ??
      new Input({ field: String(key), value: defaultProfile, tests: [toBeOptional] })
    profileInputs[key].set(profile)
    profileInputs[key].validate()
    profilesForm.value = { ...profilesForm.value, [what]: profileInputs }
  }
  if (['Performance', 'Outage', 'Provisioning', 'Billing'].includes(what)) {
    title = what
    backdropStyle = { maxWidth: '1200px' }
    modalStyle = { ...backdropStyle }
    const valueMap = quirk.value
    const kpisForm = useForms([what, key, profile].join(':'))
    const columnsRef = what === 'Billing' ? billingColumns : columns
    const expandable = what === 'Billing' ? false : true
    if (!kpisForm.isFormLoaded.value) {
      loadKpisForm({ what, key, profile })
      kpisForm.isFormLoaded.value = true
    }
    if (kpisForm.rows?.value?.length > 100) {
      window.alert('Too many KPIs')
      return
    }
    dataTable = {
      id: [what, key, profile].join('-'),
      columns: columnsRef.value.filter((f: any) => !f.hidden && !f.input?.hidden),
      rows: kpisForm.rows,
      form: kpisForm.form,
      valueMap,
      expandSeq: expandSeq,
      settings: {
        key: 'kpiId',
        striped: true,
        expandable,
        tableBodyStyle: {
          height: '400px',
          'overflow-y': 'auto'
        },
        hideFooter: true
      }
    }
  } else {
    return
  }
  quirks.value.set([what, key, profile].join(':'), quirk)
  const buttons: ButtonType[] = Array.from(buttonMap.values())
  const actions: ButtonType[] = Array.from(actionMap.values())
  if (profile === 'standard') {
    return
  }
  modalMap.value.set(['modal', what, key, profile].join('-'), {
    key,
    what,
    section,
    profile,
    title,
    closed: false,
    center: true,
    pickerBox,
    dataCards,
    dataTable,
    actions,
    buttons,
    modalStyle,
    backdropStyle
  })
}
async function onSignal([signalKey, payload]: Signal): Promise<void> {
  if (!payload) {
    return
  }
  const { index, value, opts, modal } = toRaw(payload)
  if (!modal) {
    return
  }
  const { key, what, section, profile } = modal
  if (section !== 'kpis') {
    return
  }
  const modalObj = modalMap.value.get(['modal', what, key, profile].join('-')) || {}
  switch (signalKey) {
    case 'onModalDismiss': {
      const modalObj = modalMap.value.get(['modal', what, key, profile].join('-')) || {}
      modalObj.closed = true
      modalMap.value.set(['modal', what, key, profile].join('-'), modalObj)
      break
    }
    case 'onModalPick': {
      break
    }
    case 'onModalButtonClick': {
      const quirk = quirks.value.get([what, key, profile].join(':')) ?? emptyQuirk()
      const [cancelIndex, undoIndex, resetIndex, applyIndex] = [0, 1, 2, 3]
      switch (index) {
        case cancelIndex:
        case undoIndex: {
          await resetKpis({ what, key, profile })
          onModalCancel({ index, what, key, profile })
          updateProfileCounter({ what, key, profile })
          renderCells()
          break
        }
        case resetIndex: {
          await resetKpis({ what, key, profile })
          break
        }
        case applyIndex: {
          const modalForm = useForms([what, key, profile].join(':'))
          const formKey = modalObj.dataTable.settings.key
          const rowMap = toMap(modalForm.rows.value, formKey)
          {
            const modalObj = modalMap.value.get(['modal', what, key, profile].join('-'))
            const modalColumns = modalObj?.dataTable?.columns
            if (modalColumns) {
              const { state, errorKey } = triggerForm(modalForm.form.value, modalColumns)
              const modalChanges = getFormChanges(modalForm.form.value, modalColumns)
              const modalQuirksMap = makeFormQuirksMap(modalChanges)
              const modalQuirks = Object.fromEntries(modalQuirksMap.entries())
              modalObj.dataTable.quirks = modalQuirks
              if (state === 'danger') {
                if (errorKey) {
                  modalObj.dataTable.expandSeq = [parseInt(errorKey), randomId()]
                }
                break
              }
            }
          }
          for (const rowKey in modalForm.form.value) {
            const inputs = modalForm.form.value[rowKey] ?? {}
            for (const field in inputs) {
              const input = inputs[field]
              if (!input) {
                continue
              }
              input.copy = input.value
              input.state = 'unchanged'
            }
            modalForm.form.value[rowKey] = inputs
            if (inputs._deleted?.value === true) {
              delete modalForm.form.value[rowKey]
              rowMap.delete(rowKey)
              quirk.draft?.delete(rowKey)
            }
          }
          modalForm.rows.value = Array.from(rowMap.values())
          updateProfileCounter({ what, key, profile })
          renderCells()
          quirk.value = new Map(quirk.draft)
          quirk.copy = new Map(quirk.value)
          onSignal(['onModalDismiss', payload])
          formState.value = 'changed'
          break
        }
      }
      quirks.value.set([what, key, profile].join(':'), quirk)
      break
    }
    case 'onModalActionClick': {
      const [newRecordIndex] = [0]
      switch (index) {
        case newRecordIndex: {
          const modalForm = useForms([what, key, profile].join(':'))
          const formKey = modalObj.dataTable.settings.key
          const formKeyValue = '_new-' + randomId()
          const newRecord: any = {
            [formKey]: formKeyValue,
            stdCustomIndicator: 'Custom KPI',
            kpiType: 'NON-REF'
          }
          let inputs: any = {}
          const columnsRef = what === 'Billing' ? billingColumns : columns
          for (const cell of columnsRef.value) {
            const { label, field, input } = cell
            const value = newRecord[field] ?? input?.value
            let disabled = input?.disabled
            if (field === '_deleted') {
              disabled = false
            }
            inputs[field] = new Input({ label, field, ...input, disabled, value })
          }
          modalForm.rows.value.push(newRecord)
          modalForm.form.value = { ...modalForm.form.value, [formKeyValue]: inputs }
          expandSeq.value = [formKeyValue, expandSeq.value[1] + 1]
          setTimeout(() => {
            const dataRows =
              document
                .getElementById('data-table-' + [what, key, profile].join('-'))
                ?.querySelector('.data-body')
                ?.querySelectorAll('.first-block') ?? []
            if (dataRows.length > 0) {
              const lastRow = dataRows[dataRows.length - 1]
              lastRow.querySelector('select')?.focus()
            }
          }, 100)
          break
        }
      }
      break
    }
    case 'onModalInput': {
      const modalForm = useForms([what, key, profile].join(':'))
      if (!opts) {
        return
      }
      const inputs = modalForm.form.value[opts.key]
      const input = inputs?.[opts.field]
      if (!input) {
        return
      }
      input.set(value)
      input.validate()
      const startDate = fromMMDDYYYY(inputs.effectiveStartDate.value)
      const endDate = fromMMDDYYYY(inputs.effectiveEndDate.value)
      if (startDate > endDate) {
        inputs.effectiveStartDate.state = 'danger'
        danger.value = true
        inputs.effectiveEndDate.error = {
          message: `Begin Date should be before End Date.`
        }
      } else {
        inputs.effectiveEndDate.set(inputs.effectiveEndDate.value)
        inputs.effectiveEndDate.validate()
        danger.value = false
      }

      if (opts.field === 'waive' && value) {
        const today = new Date().toISOString().split('T')[0]
        inputs.effectiveEndDate.value = today
        if (!inputs.effectiveStartDate.value) {
          inputs.effectiveStartDate.value = today
        }
      } else {
        if (opts.field === 'waive' && value === false) {
          inputs.effectiveEndDate.value = null
        }
      }
      if (input.state === 'success') {
        if (!['clin', 'icbNumber', 'effectiveStartDate', 'effectiveEndDate'].includes(opts.field)) {
          inputs.stdCustomIndicator?.set('Custom KPI')
        }
        inputs.kpiType?.set('NON-REF')
      }
      {
        let quirk = quirks.value.get([what, key, profile].join(':')) ?? emptyQuirk()
        quirk.draft.set(opts.key, value)
        quirks.value.set([what, key, profile].join(':'), quirk)
      }
      {
        const modalObj = modalMap.value.get(['modal', what, key, profile].join('-'))
        const modalColumns = modalObj?.dataTable?.columns
        if (modalColumns) {
          const modalChanges = getFormChanges(modalForm.form.value, modalColumns)
          const modalQuirksMap = makeFormQuirksMap(modalChanges)
          const modalQuirks = Object.fromEntries(modalQuirksMap.entries())
          modalObj.dataTable.quirks = modalQuirks
        }
      }
      break
    }
  }
}
function onModalCancel({ index, what, key, profile }: any): void {
  const quirk = quirks.value.get([what, key, profile].join(':')) ?? emptyQuirk()
  const [cancelIndex] = [0]
  const modalObj = modalMap.value.get(['modal', what, key, profile].join('-'))
  const modalForm = useForms([what, key, profile].join(':'))
  const formKey = modalObj?.dataTable.settings.key ?? undefined
  const rowMap = toMap(modalForm.rows.value, formKey)
  for (const rowKey in modalForm.form.value) {
    const inputs = modalForm.form.value[rowKey] ?? {}
    for (const field in inputs) {
      const input = inputs[field]
      if (!input?.state) {
        continue
      }
      input.value = input.copy
      input.state = 'unchanged'
    }
    modalForm.form.value[rowKey] = inputs
    if (index === cancelIndex) {
      if (inputs._deleted?.value === true) {
        delete modalForm.form.value[rowKey]
        rowMap ? rowMap.delete(rowKey) : undefined
        quirk.draft?.delete(rowKey)
      }
    }
  }
  modalForm.rows.value = Array.from(rowMap.values())
}
function updateProfileCounter({ what, key, profile }: any): void {
  const modalObj = modalMap.value.get(['modal', what, key, profile].join('-'))
  const modalForm = useForms([what, key, profile].join(':'))
  const formKey = modalObj?.dataTable.settings.key ?? undefined
  const rowMap = toMap(modalForm.rows.value, formKey)
  modalForm.rows.value = Array.from(rowMap.values())
  {
    const customKeysSet = new Set()
    const standardKeysSet = new Set()
    for (const rowKey in modalForm.form.value) {
      const inputs = modalForm.form.value[rowKey] ?? {}
      const isCustom = String(inputs.stdCustomIndicator?.value).toLowerCase().includes('custom')
      const rowKeyStringNumber = String(rowKey).replace('_new', '')
      const rowKeyInt = parseInt(rowKeyStringNumber)
      if (isCustom) {
        customKeysSet.add(rowKeyInt)
      } else {
        standardKeysSet.add(rowKeyInt)
      }
    }
    const customCounter = customKeysSet.size
    const standardCounter = standardKeysSet.size
    kpiCounterMap.value.set([what, key, 'custom'].join(':'), customCounter)
    kpiCounterMap.value.set([what, key, 'standard'].join(':'), standardCounter)
  }
  let profileValue
  {
    profileValue =
      kpiCounterMap.value.get([what, key, 'custom'].join(':')) > 0 ? 'custom' : 'standard'
    kpiSelectedMap.value.set([what, key].join(':'), profileValue)
  }
  {
    const profileInputs = profilesForm.value[what] ?? {}
    profileInputs[key] =
      profileInputs[key] ??
      new Input({ field: String(key), value: profileValue, tests: [toBeOptional] })
    profileInputs[key].set(profileValue)
    profileInputs[key].validate()
    profilesForm.value = { ...profilesForm.value, [what]: profileInputs }
  }
}
function standardOrNone(title: string): string {
  return title === 'Submission' ? 'none' : 'standard'
}
function StandardOrNone(title: string): string {
  return title === 'Submission' ? 'None' : 'Standard'
}
function renderCells(): void {
  const cellsDraft: any = {}
  for (const tab of tabs.value) {
    for (const serviceKey of picks.value) {
      const cell = {
        field: 'profile',
        input: {
          mode: 'button',
          class: '-w-sm--30 -w-md--25  -w-lg--20',
          options: [
            [
              standardOrNone(tab.title),
              [
                StandardOrNone(tab.title),
                affixFormat(
                  kpiCounterMap.value.get(
                    [tab.title, serviceKey, standardOrNone(tab.title)].join(':')
                  ),
                  '(',
                  ')'
                )
              ]
                .filter((f) => f)
                .join(' ')
            ],
            [
              'custom',
              [
                'Custom',
                affixFormat(
                  kpiCounterMap.value.get([tab.title, serviceKey, 'custom'].join(':')),
                  '(',
                  ')'
                )
              ]
                .filter((f) => f)
                .join(' ')
            ]
          ],
          value:
            kpiSelectedMap.value.get([tab.title, serviceKey].join(':')) ?? standardOrNone(tab.title)
        }
      }
      const cellsKey = [tab.title, serviceKey].join(':')
      cellsDraft[cellsKey] = cell
    }
  }
  cells.value = cellsDraft
  renderSeq.value++
}
onMounted(() => {})
</script>

<template>
  <ul class="chi-tabs -border -user-select--none" role="tablist" aria-label="chi-tabs-horizontal">
    <li v-for="tab in tabs" :key="tab.title" :class="{ '-active': activeTab === tab.title }">
      <a :href="undefined" role="tab" aria-selected="true" @click="activeTab = tab.title"
        >{{ tab.title }}
        <div v-if="customCounterByTitle[tab.title]" class="chi-badge -secondary -sm -ml--1">
          <span>{{ customCounterByTitle[tab.title] }}</span>
        </div>
      </a>
    </li>
  </ul>
  <section
    v-for="tab in tabs"
    :key="tab.title"
    class="chi-table -fixed--header -striped -mt--2"
    :class="{ '-d--none': tab.title !== activeTab }"
    :style="{ height: '360px' }"
  >
    <div>
      <table>
        <thead>
          <tr>
            <th class="-w--10">
              <div class="-user-select--none">Service</div>
            </th>
            <th class="-w--20">
              <div></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="serviceKey in picks" :key="serviceKey">
            <td>{{ serviceKey + ' - ' + serviceMap.get(serviceKey)?.serviceDesc }}</td>
            <td>
              <SingleCell
                :key="renderSeq"
                :cell="cells[[tab.title, serviceKey].join(':')]"
                @onChange="(newValue: any) => onChangeClick(newValue, serviceKey, tab.title)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<style scoped>
.-cursor--default {
  cursor: default;
}

.-user-select--none {
  user-select: none;
}
</style>
