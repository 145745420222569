import { computed } from 'vue'
import {
  toBeOptional,
  toNotBeEmpty,
  useApi,
  parseErr,
  toBeANumber,
  toMatchWith,
  InputType,
  toBeAnEmail,
  Validation,
  StateType,
  ErrorType
} from '@centurylinkfederal/eis-vue'

import { useStore } from './store'
import { storeToRefs } from 'pinia'
import { Action, dispatch } from './actions'

export function validatePhone(input: InputType) {
  let state = 'success'
  let error = undefined
  if (input.value) {
    const [code, phone] = input.value.split('-')
    if (code === '+1') {
      if (phone.length !== 10) {
        state = 'danger'
        error = 'Invalid phone number.'
      }
    } else {
      const remaingAllowedLength = 20 - (code.length + 1)
      if (phone.length > remaingAllowedLength) {
        state = 'danger'
        error = 'Invalid phone number.'
      }
    }
  }
  return [state, error ? { message: `${input.label} is invalid.` } : error]
}
export function toBeAZipCode(input: InputType): Validation {
  let state: StateType = undefined
  let error: ErrorType = undefined
  const dangerState = 'danger'
  const dangerError = {
    message: input?.dangerMessage || [input?.label, `is invalid.`].filter((f) => f).join(' ')
  }
  const value = input.value ? String(input.value).trim() : input.value
  state = 'success'
  if (value) {
    const zipCodePattern = /^\d{5}(?:-\d{4})?\s*$/
    if (!zipCodePattern.test(value)) {
      ;[state, error] = [dangerState, dangerError]
    }
  }
  return [state, error]
}
export function useColumnsStore(): any {
  const formId = -1
  const store = useStore()
  const {
    selectedAgencyTos,
    productGroups,
    agencies,
    states,
    loggedInUserInfo,
    selectedAgencyId,
    userDetail,
    isEditMode,
    isUserActive,
    toPermissionList,
    form,
    validationSuccess,
    originalTaskorder
  } = storeToRefs(store)
  const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)
  const userDetailsCols = computed(() => [
    {
      field: 'userName',
      label: 'User Name',
      labelInfoText: 'This name used to login to EIS Portal',
      labelInfoClass: '-italicised',
      input: {
        type: 'text',
        maxLength: 50,
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'firstName',
      label: 'First Name',
      input: {
        type: 'text',
        maxLength: 50,
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'lastName',
      label: 'Last Name',
      input: {
        type: 'text',
        maxLength: 50,
        required: true,
        tests: [toNotBeEmpty]
      }
    }
  ])

  const userPasswordInfoCols = computed(() => [
    {
      field: 'password',
      label: 'Password',
      input: {
        type: 'password',
        maxLength: 64,
        required: true,
        showOnValidationFail: true,
        enableDebounce: isEditMode.value,
        delayTime: 500,
        isPrevPassword: isEditMode.value && !validationSuccess.value,
        tests: [
          toNotBeEmpty,
          (input: any) => toMatchWith(input, form.value[formId]?.['confirmPassword'])
        ]
      }
    },
    {
      field: 'confirmPassword',
      label: 'Confirm Password',
      input: {
        type: 'password',
        maxLength: 64,
        required: true,
        tests: [
          toNotBeEmpty,
          (input: any) =>
            toMatchWith(
              input,
              form.value[formId]?.['password'],
              'Password does not match.  Please confirm.'
            )
        ]
      }
    },
    {
      field: 'passwordValidations',
      label: 'Password must contain',
      classCol: '-w--6',
      input: {
        type: 'password',
        required: true
      }
    }
  ])

  const userAddressLineInfo = computed(() => [
    {
      field: 'address1',
      label: 'Address 1',
      classCol: '-w-xl-2h--3 -w-md--6 -w-sm--12',
      input: {
        type: 'text',
        maxLength: 50,
        tests: [toBeOptional]
      }
    },
    {
      field: 'address2',
      label: 'Address 2',
      classCol: '-w-xl-2h--3 -w-md--6 -w-sm--12',
      input: {
        type: 'text',
        maxLength: 50,
        tests: [toBeOptional]
      }
    }
  ])

  const userCityStateDetails = computed(() => [
    {
      field: 'city',
      label: 'City',
      input: {
        type: 'text',
        maxLength: 50,
        tests: [toBeOptional]
      }
    },
    {
      field: 'stateCode',
      label: 'State',
      input: {
        type: 'select',
        options: states.value,
        value: null,
        disabled: isEditMode.value && loggedInUserInfo.value?.roleName === 'Lumen Admin',
        tests: []
      }
    },
    {
      field: 'zip',
      label: 'ZIP code',
      input: {
        type: 'text',
        maxLength: 10,
        tests: [toBeOptional, toBeAZipCode]
      }
    }
  ])

  const userContactDetails = computed(() => [
    {
      field: 'mobilePhone',
      label: 'Mobile Phone Number',
      input: {
        type: 'phone',
        maxLength: 14,
        inputMask: true,
        placeholder: '(212) 897-1964',
        tests: []
      }
    },
    {
      field: 'telephone',
      label: 'Work Phone Number',
      input: {
        type: 'phone',
        inputMask: true,
        maxLength: 14,
        placeholder: '(212) 897-1964',
        tests: []
      }
    },
    {
      field: 'workPhoneExtension',
      label: 'Work Phone Extension',
      input: {
        type: 'text',
        maxLength: 5,
        tests: [toBeOptional, toBeANumber]
      }
    }
  ])

  const userRemainderDetailsCols = computed(() => [
    {
      field: 'email',
      label: 'Email',
      classCol: '-w-xl-2h--3 -w-lg--6 -w-md--6 -w--6',

      input: {
        type: 'text',
        maxLength: 50,
        mode: 'email',
        required: true,
        tests: [toNotBeEmpty, toBeAnEmail]
      }
    },
    {
      field: 'preferredContactMethod',
      label: 'Media Preference (Optional)',
      input: {
        type: 'text',
        maxLength: 50,
        tests: [toBeOptional]
      }
    }
  ])

  const noteColumn = computed(() => [
    {
      field: 'notes',
      label: 'Notes (Optional)',
      classCol: '-w-xl-2h--3 -w-lg--9 -w-md--6 -w--6',
      input: {
        type: 'textarea',
        maxLength: 250,
        tests: [toBeOptional]
      }
    }
  ])

  const taskOrderPrevColumns = computed(() => [
    {
      field: 'taskOrderPrivileges',
      label: 'Task Order Privileges',
      classCol: '-w-xl-2h--3 -w-lg--6 -w-md--6 -w--6',
      input: {
        type: 'select',
        options: agencies.value,
        tests: [toBeOptional]
      }
    },
    {
      classCol: '-w-xl-2h--3 -w-lg--6 -w-md--6 -w--6 -d--flex -align-items--center -mt--2',
      type: 'slot'
    }
  ])

  const fullPermissionOptions = computed(() => [
    { label: 'None', value: 'NONE' },
    { label: 'Submit', value: 'SUBMIT' },
    { label: 'Save', value: 'SAVE' },
    { label: 'View', value: 'VIEW' }
  ])
  const viewOrNonePermission = computed(() => [
    { label: 'None', value: 'NONE' },
    { label: 'View', value: 'VIEW' }
  ])
  const viewOrNonePermissionInventory = computed(() => [
    { label: 'None', value: 'NONE' },
    { label: 'View All', value: 'VIEW' },
    { label: 'View IR Only', value: 'VIEW-IR' }
  ])
  const viewOrWritePermission = computed(() => [
    { label: 'None', value: 'NONE' },
    { label: 'View Only', value: 'VIEW' },
    { label: 'View/Write', value: 'VIEW/WRITE' }
  ])
  const roleInformationTypes = computed(() => [
    { label: 'OCO', value: 'CORA' },
    { label: 'COR', value: 'COR' },
    { label: 'Agency User', value: 'AGENCY_USER' },
    { label: 'Lumen User', value: 'CENTURYLINK_USER' },
    { label: 'Lumen Admin', value: 'L3ADMIN' }
  ])
  const troublePermissionOptions = computed(() => [
    { label: 'Create', value: false, name: 'troubleCreatePrivilegeValue' },
    { label: 'Update', value: false, name: 'troubleUpdatePrivilegeValue' },
    { label: 'Escalate', value: false, name: 'troubleEscalatePrivilegeValue' },
    { label: 'Close', value: false, name: 'troubleClosePrivilegeValue' }
  ])

  async function getAgencyTOs(agencyId: any) {
    if (!agencyId) {
      selectedAgencyTos.value = []
      return
    }
    await getTaskOrderByUserAndAgencyId(loggedInUserInfo.value.userId, selectedAgencyId.value)
  }
  async function getTaskOrderByUserAndAgencyId(userId: any, agencyId: any): Promise<void> {
    let taskOrders = []
    try {
      userId = isEditMode.value ? userDetail.value.eisUserId : userId
      const response: any = await api.get(`/users/${userId}/taskorders/${agencyId}`)
      taskOrders = response
        .map((result: any) => {
          const [, agencyIdentifier] =
            agencies.value.find(([value]) => result.agencyToPermissions.agencyId === value) || []
          const data = {
            ...result.agencyToPermissions,
            accountTaskOrderId: result.accountTaskOrderId,
            agencyIdentifier,
            ...(!isEditMode.value && {
              billingPermission: 'NONE',
              dashboardPermission: 'NONE',
              inventoryPermission: 'NONE',
              troublePermission: 'NONE',
              networkPermission: 'NONE',
              troubleCreatePrivilegeValue: false,
              troubleUpdatePrivilegeValue: false,
              troubleEscalatePrivilegeValue: false,
              troubleClosePrivilegeValue: false
            }),
            showEnhancedWO: isEditMode.value
              ? result.agencyToPermissions?.enhancedWorkOrder === 'Y' &&
                result.agencyToPermissions?.eowaPrivilegeViewEdit
              : false
          }
          return data
        })
        .sort((first: any, second: any) =>
          first.agencyIdentifier.localeCompare(second.agencyIdentifier)
        )
    } catch (err: any) {
      selectedAgencyTos.value = []
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
    originalTaskorder.value.set(agencyId, [...JSON.parse(JSON.stringify(taskOrders))])
    selectedAgencyTos.value = [...taskOrders]
    const { permissionMap, updatedToDetails } = dispatch(Action.UPDATE_TO_PREMISSION, {
      currentTo: selectedAgencyTos.value,
      selectedAgencyId: selectedAgencyId.value,
      toPermissionList: toPermissionList.value
    })
    selectedAgencyTos.value = updatedToDetails
    toPermissionList.value = permissionMap
  }
  async function getUserById(userId: number): Promise<void> {
    try {
      const response = await api.get<any>(`/users/${userId}`)
      const contact = response.contact
      isEditMode.value = true
      isUserActive.value = response.status === 'ACTIVE'
      delete response.contact
      userDetail.value = {
        ...response,
        ...contact,
        mobilePhone: contact.mobilePhone ?? '',
        telephone: contact.telephone ?? '',
        preferredContactMethod: response.mediaPreference,
        stateCode: contact.stateCode
      }
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
  }

  return {
    userDetailsCols,
    userPasswordInfoCols,
    userRemainderDetailsCols,
    taskOrderPrevColumns,
    getAgencyTOs,
    fullPermissionOptions,
    viewOrNonePermission,
    viewOrWritePermission,
    roleInformationTypes,
    troublePermissionOptions,
    productGroups,
    getUserById,
    userAddressLineInfo,
    userCityStateDetails,
    userContactDetails,
    noteColumn,
    viewOrNonePermissionInventory
  }
}
