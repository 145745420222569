import { ref, shallowRef, Ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { parseErr, SortTuple, useApi } from '@centurylinkfederal/eis-vue'

export const useUserSearchStore = defineStore('UserSearchStore', () => {
  const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)

  const currentResponse = shallowRef<any>({})

  const refreshSeq = ref(0)
  const clearSeq = ref(0)
  const clearBulkSeq = ref(0)
  const previousPage = ref(0)
  const currentPage = ref(1)
  const nextPage = ref(1)
  const rowsPerPage = ref(10)
  const loading = ref<boolean | string>(false)
  const sortBy: Ref<SortTuple | undefined> = ref<SortTuple | undefined>(undefined)
  const selectedRows = ref<any>()
  const searchInputValue = ref<string>('')
  const performSearch = ref<any>()
  const searchResult = ref<any>([])
  const searchUserStatus = ref<string>('ALL')
  const totalRecords = ref(0)
  const searchMessage = ref<string>('Search for or select at least one filter to get results')

  watch(
    () => performSearch.value,
    async () => await searchUsers()
  )

  watch(
    () => searchUserStatus.value,
    async () => await searchUsers()
  )

  watch(
    () => rowsPerPage.value,
    async () => await searchUsers()
  )

  watch(
    () => nextPage.value,
    async () => await searchUsers()
  )

  async function searchUsers() {
    try {
      const payload = {
        searchText: searchInputValue.value,
        pageNumber: currentPage.value,
        pageSize: rowsPerPage.value,
        userStatus: searchUserStatus.value
      }
      const result: any = await api.post(`/users/search`, payload)

      const users = result?.users?.map((data: any) => ({
        ...data,
        status: data.status === 'DISABLED' ? 'INACTIVE' : data.status,
        ...data.contact
      }))
      searchResult.value = users
      previousPage.value = currentPage.value - 1
      totalRecords.value = result.totalRecords
      if (!searchResult.value?.length) {
        searchMessage.value = 'No matching results found.'
      }
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
  }

  return {
    refreshSeq,
    clearSeq,
    clearBulkSeq,
    currentResponse,
    currentPage,
    previousPage,
    rowsPerPage,
    sortBy,
    loading,
    selectedRows,
    searchInputValue,
    performSearch,
    searchResult,
    nextPage,
    searchUserStatus,
    totalRecords,
    searchMessage
  }
})
