<script lang="ts" setup>
import moment from 'moment'
import { ref, watch, Ref, unref } from 'vue'
import { useAlertsStore } from '../../../store/alert'
import {
  createFinancialFields,
  createFinancialHeaders,
  generateFinancial
} from '../../../utils/to-admin.utils'
import ImportFile from '../../ImportFile.vue'
import { DatePicker } from '@centurylinkfederal/eis-vue'

const emit = defineEmits(['financial'])
const edit = ref(false)
const newFinancialRecord = generateFinancial()
const financials: Ref<Array<any>> = ref<Array<any>>([newFinancialRecord])
const errorMsg = ref('')
const isExpanded: Ref<boolean> = ref<boolean>(true)

const fields = createFinancialFields
const headers = createFinancialHeaders
const alertStore = useAlertsStore()
const showFinancialModal: Ref<boolean> = ref<boolean>(false)
const fileName: Ref<string> = ref<string>('')
const fileContent: Ref<string> = ref<string>('')
const showImportModal: Ref<boolean> = ref<boolean>(false)

watch(financials.value, () => {
  emit('financial', financials.value)
})

function prorationTypeChange(financial: any) {
  financial.errors.prorationType = ''
  if (!financial.prorationType) financial.errors.prorationType = 'Proration Type is required'
}

function addFinancial() {
  const newFinancialRecord = generateFinancial()
  financials.value.push(newFinancialRecord)
}

function deleteFinancial(id: number) {
  financials.value = financials.value.filter((f) => f.id !== id)
}

function validateCeilingAmount(financial: any) {
  const errors = validateFinancials(financial)
  const performancePeriodFunding = errors['performancePeriodFunding']
  const taskOrderCeiling = errors['taskOrderCeiling']
  financial.errors = {
    ...financial.errors,
    taskOrderCeiling,
    performancePeriodFunding
  }
}

function validate_dates(financial: any) {
  let bDateError: String | Boolean = !!!financial.performanceBeginDate
  let eDateError: String | Boolean = !!!financial.performanceEndDate
  const bDate = financial.performanceBeginDate
  const eDate = financial.performanceEndDate
  let errorMsg = ''
  const isValidDate = (value: string) => moment(value, 'MM/DD/YYYY', true).isValid()
  if (bDate && !isValidDate(bDate)) {
    bDateError = 'Invalid Date'
  }
  if (eDate && !isValidDate(eDate)) {
    eDateError = 'Invalid Date'
  }
  if (
    bDate &&
    eDate &&
    isValidDate(bDate) &&
    isValidDate(eDate) &&
    moment(bDate, 'MM/DD/YYYY', true).isSameOrAfter(moment(eDate, 'MM/DD/YYYY', true))
  ) {
    errorMsg = 'End Date must be greater than Start Date'
    eDateError = errorMsg
  }
  return {
    performanceBeginDate: bDateError,
    performanceEndDate: eDateError,
    errorMsg
  }
}

function validateDates1(financial: any) {
  const dateErrors = validate_dates(financial)
  financial.errors = {
    ...financial.errors,
    ...dateErrors
  }
}

function validateMultipleFinancials(financials: any[] = []): any {
  if (financials) {
    return financials.map((f) => validateFinancials(f))
  }
  return []
}

function validateFinancials(financial: any): any {
  let errors: any = {}
  errors.taskOrderAgfRate = !!!financial.taskOrderAgfRate
  errors.taskOrderCeiling = !!!financial.taskOrderCeiling
  errors.performancePeriodFunding = !!!financial.performancePeriodFunding
  errors.prorationType = !!!financial.prorationType
  const ppf = financial.performancePeriodFunding
    ? parseFloat(financial.performancePeriodFunding.replaceAll(',', ''))
    : 0
  const toc = financial.taskOrderCeiling
    ? parseFloat(financial.taskOrderCeiling.replaceAll(',', ''))
    : 0
  let flag = false
  if (ppf > toc) {
    flag = true
    errors['performancePeriodFunding'] = 'Performance Funding cannot exceed TO Ceiling Amount'
  }

  const dateErrors = validate_dates(financial)
  errors = {
    ...errors,
    ...dateErrors
  }
  return errors
}

function editField(financial: any, field: any, value: any) {
  if (field === 'taskOrderAgfRate') {
    if (/^([0-9]*)(\.?([0-9]*))$/.test(value)) {
      financial[field] = value
    }
  } else {
    financial[field] = value
    validateDates1(financial)
  }
}

function alertDismissed() {
  errorMsg.value = ''
}

function openDialog() {
  showFinancialModal.value = true
}

function importedFileInfo(details: any = {}) {
  const { isValid, contents = [] } = details
  contents.forEach((content: any) => {
    content.performanceBeginDate =
      (content.performanceBeginDate && moment(content.performanceBeginDate).format('MM/DD/YYYY')) ||
      ''
    content.performanceEndDate =
      (content.performanceEndDate && moment(content.performanceEndDate).format('MM/DD/YYYY')) || ''
    content.id = crypto.randomUUID()
    content.errors = validateFinancials(content)
    content.taskOrderCeiling = content.taskOrderCeiling
      ? commaSeperatedVal(+content.taskOrderCeiling)
      : ''
    content.performancePeriodFunding = content.performancePeriodFunding
      ? commaSeperatedVal(+content.performancePeriodFunding)
      : ''
  })
  financials.value = contents
}

function commaSeperatedVal(value: number): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
function onCeilingAmtChange(financial: any, key: string, event: any) {
  let value = financial[key]
  if (!value) return
  value = value.replace(/[^\d]+/g, '')
  value = commaSeperatedVal(Number(value))
  financial[key] = value
}

function toggleAccordian() {
  isExpanded.value = !isExpanded.value
}

function checkFieldsFinancials() {
  console.log('financials')
  const resp = validateMultipleFinancials(financials.value)
  console.log('resp', resp)
  let checkField = false
  resp.forEach((fields: any) => {
    if (
      fields.performanceBeginDate ||
      fields.performanceEndDate ||
      fields.performancePeriodFunding ||
      fields.prorationType ||
      fields.taskOrderAgfRate ||
      fields.taskOrderCeiling
    ) {
      checkField = true
    }
  })
  if (checkField) {
    return false
  }
  return true
}
function toFinancialsForm() {
  return financials.value
}

defineExpose({
  checkFieldsFinancials,
  toFinancialsForm
})
</script>

<template>
  <div class="chi-accordion -md" id="example-size-md">
    <div class="chi-accordion__item" :class="isExpanded ? '-expanded' : ''">
      <button class="chi-accordion__trigger" @click="toggleAccordian()">
        <i class="chi-icon icon-chevron-down" aria-hidden="true"></i>
        <div class="chi-accordion__title">To Financials</div>
      </button>
      <div class="chi-accordion__content" style="overflow: visible">
        <div>
          <div v-for="(financial, index) in financials" :key="financial.id">
            <div class="chi-grid">
              <div class="chi-col">
                <div class="m-form__item">
                  <chi-label required>TO Ceiling Amount</chi-label>
                  <input
                    type="text"
                    :class="financial.errors.taskOrderCeiling ? 'chi-input -danger' : 'chi-input'"
                    v-model="financial.taskOrderCeiling"
                    @input="onCeilingAmtChange(financial, 'taskOrderCeiling', $event)"
                    @blur="validateCeilingAmount(financial)"
                  />
                </div>
              </div>
              <div class="chi-col">
                <div class="m-form__item">
                  <chi-label required>TO AGF Rate (%)</chi-label>
                  <input
                    type="text"
                    :class="financial.errors.taskOrderAgfRate ? 'chi-input -danger' : 'chi-input'"
                    :value="financial.taskOrderAgfRate"
                    @input="editField(financial, 'taskOrderAgfRate', $event)"
                  />
                </div>
              </div>
              <div class="chi-col">
                <div class="m-form__item">
                  <chi-label required>Performance Period Funding</chi-label>
                  <input
                    type="text"
                    :class="
                      financial.errors.performancePeriodFunding ? 'chi-input -danger' : 'chi-input'
                    "
                    v-model="financial.performancePeriodFunding"
                    @input="onCeilingAmtChange(financial, 'performancePeriodFunding', $event)"
                    @blur="validateCeilingAmount(financial)"
                  />
                  <span
                    class="-text--danger"
                    v-if="
                      financial.errors.performancePeriodFunding &&
                      financial.errors.performancePeriodFunding !== true
                    "
                  >
                    {{ financial.errors.performancePeriodFunding }}
                  </span>
                </div>
              </div>
              <div class="chi-col">
                <div class="m-form__item">
                  <chi-label required>Proration Type</chi-label>
                  <select
                    :class="financial.errors.prorationType ? 'chi-input -danger' : 'chi-input'"
                    v-model="financial.prorationType"
                    @change="prorationTypeChange(financial)"
                    @blur="validateCeilingAmount(financial)"
                  >
                    <option value="">- Select -</option>
                    <option value="Month-Length Proration">Month-Length Proration</option>
                    <option value="Normalized 30-Day Month Proration">
                      Normalized 30-Day Month Proration
                    </option>
                  </select>
                </div>
              </div>
              <div class="chi-col">
                <div class="m-form__item">
                  <DatePicker
                    label="Begin Date"
                    :id="`finStartDate_${index}`"
                    :required="true"
                    class="z--70"
                    :value="financial.performanceBeginDate"
                    :state="''"
                    :helperMessage="''"
                    @change="editField(financial, 'performanceBeginDate', $event)"
                  ></DatePicker>
                </div>
              </div>
              <div class="chi-col -w--6 -w-xl--2">
                <div class="m-form__item">
                  <DatePicker
                    label="Performance End Date"
                    :id="`finEndDate_${index}`"
                    class="z--70"
                    :required="true"
                    :state="
                      financial.errors.performanceEndDate &&
                      financial.errors.performanceEndDate != true
                        ? 'danger'
                        : ''
                    "
                    :helperMessage="
                      financial.errors.performanceEndDate &&
                      financial.errors.performanceEndDate != true
                        ? financial.errors.performanceEndDate
                        : ''
                    "
                    :value="financial.performanceEndDate"
                    @change="editField(financial, 'performanceEndDate', $event)"
                  >
                  </DatePicker>
                </div>
              </div>
              <div class="icon" style="margin: auto">
                <i
                  style="cursor: pointer"
                  class="chi-icon icon-delete -icon--primary"
                  @click="deleteFinancial(financial.id)"
                  aria-hidden="true"
                  v-if="index !== 0"
                  :id="'deleteToFinancial_' + index"
                ></i>
              </div>
            </div>
          </div>
          <div class="-mt--5 -text--center">
            <button class="chi-button -sm -mr--2 -primary" @click="addFinancial">
              Add Financial
            </button>
            <button class="chi-button -sm -mr--2 -outline" @click="showImportModal = true">
              Import
            </button>
          </div>
          <div v-if="showImportModal">
            <ImportFile
              :headerDetails="headers"
              :dataFields="fields"
              title="financial"
              @closeModal="showImportModal = false"
              @importContent="importedFileInfo($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.mx-datepicker {
  width: 100%;
}

.required {
  padding-right: 2px;
}

.required::after {
  content: '\00a0*';
  color: red;
}

.icon {
  width: 30px;
  display: inline-flex;
  align-self: center;
  padding-left: 10px;
}

.chi-accordion__content {
  overflow: visible;
}
</style>
