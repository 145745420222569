<script setup lang="ts">
import { computed } from 'vue'
import {
  Row,
  ReadOnlyCell,
  Column,
  SettingsType,
  Form,
  EditCell,
  GroupCell
} from '@centurylinkfederal/eis-vue'

export interface Props {
  id?: string
  columns?: Column[]
  settings?: SettingsType
  form?: Form
  row?: Row
  classColDefault?: string
}
const props = withDefaults(defineProps<Props>(), {
  id: '',
  columns: () => {
    return []
  },
  settings: () => {
    return {}
  },
  form: () => {
    return {}
  },
  row: () => {
    return {}
  },
  classColDefault: '-w-xl-2h--3 -w-lg--3 -w-md--4 -w--6'
})
const emit = defineEmits(['onInput'])
const computedColumns = computed(() =>
  props.columns.filter((cell: any) => !cell?.hidden && !cell.input?.hidden)
)
function onInput(newValue: any, payload: any): void {
  emit('onInput', newValue, payload)
}
</script>
<template>
  <div class="grid-wrapper">
    <div class="chi-grid -mx--2">
      <div
        v-for="(cell, index) in computedColumns"
        :key="index"
        :class="[
          cell.class,
          cell.classSpacing,
          {
            ['chi-col ' + (cell.classCol ?? classColDefault)]: !cell.divider && !cell.groupTitle,
            '-mb--3': !cell.classSpacing,
            'chi-col -w--12': cell.divider || cell.groupTitle
          }
        ]"
      >
        <div
          v-if="cell.divider"
          :class="['chi-divider', cell.class, { '-mb--2': !cell.dividerClass }]"
        />
        <div
          v-if="cell.groupTitle"
          :class="[cell.class, { '-text--h4 -mt--0 -mb--3': !cell.groupTitleClass }]"
        >
          {{ cell.groupTitle }}
        </div>
        <ReadOnlyCell
          v-else-if="!cell.input"
          :cell="cell"
          :row="row"
          :keyValue="String(row?.[settings.key] ?? '')"
          :onAction="() => null"
        />
        <EditCell
          v-else-if="cell.input && cell.input.type !== 'group'"
          :cell="cell"
          :row="row"
          :form="form"
          :settings="settings"
          :input="form?.[row?.[settings.key]]?.[String(cell.field)]"
          @onInput="
            (newValue: any) =>
              onInput(newValue, {
                key: row?.[settings.key],
                field: cell.field,
                opts: cell.input
              })
          "
        />
        <GroupCell
          v-else-if="cell.input && cell.input.type === 'group'"
          :cell="cell"
          :row="row"
          :form="form"
          :settings="settings"
          :input="form?.[row?.[settings.key]]?.[String(cell.field)]"
          @onInput="
            (newValue: any, payload: any) =>
              onInput(newValue, {
                key: row?.[settings.key],
                field: payload.field,
                opts: payload.opts,
                group: payload.group,
                defaultValue: payload.defaultValue
              })
          "
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
.chi-col.-w--0 {
  min-width: 0px;
}
</style>
