<script setup lang="ts">
import { ref, watch, defineProps, computed } from 'vue'
import { useStore } from './store'
import { storeToRefs } from 'pinia'
import { useColumnsStore } from './ColumnStore'

const store = useStore()
const columnsStore = useColumnsStore()
const { roleInformationTypes } = columnsStore
const { productGroups, isEditMode, userDetail, adminEditingAdimUser } = storeToRefs(store)

const selectedCheckboxes = ref<Record<any, any>>({})
const selectedRadio = ref<Record<any, any | null>>({})
const requiredOpts = ref<Record<any, any | null>>({})

let isDisableETFI = ref<boolean | undefined>()
const props = defineProps(['userRole'])

const productMap = computed(() => {
  const map = new Map<string, number | undefined>()

  productGroups.value.forEach((group: any) => {
    group.products.forEach((product: any) => {
      map.set(product.shortName, product.productId)

      if (product.childProducts) {
        product.childProducts.forEach((childProduct: any) => {
          map.set(childProduct.shortName, childProduct.productId)

          if (childProduct.childProducts) {
            childProduct.childProducts.forEach((subChildProduct: any) => {
              map.set(subChildProduct.shortName, subChildProduct.productId)
            })
          }
        })
      }
    })
  })

  return map
})

const roleConfigurations: any = computed(() => ({
  OCO: {
    uncheckedCheckboxes: [
      productMap.value.get('VMANAGE'),
      productMap.value.get('VMANAGE_RO'),
      productMap.value.get('MSSTLS'),
      productMap.value.get('MTIPSTLS'),
      productMap.value.get('MZTNA_ZSCA'),
      productMap.value.get('FEDRAMP_HI'),
      'ETFI'
    ].filter(Boolean),
    unsetRadio: {
      [productMap.value.get('VMANAGE') as number]: null,
      [productMap.value.get('MZTNA_ZSCA') as number]: null
    } as { [key: number]: string | null }
  },
  COR: {
    uncheckedCheckboxes: [
      productMap.value.get('VMANAGE'),
      productMap.value.get('VMANAGE_RO'),
      productMap.value.get('MSSTLS'),
      productMap.value.get('MTIPSTLS'),
      productMap.value.get('MZTNA_ZSCA'),
      productMap.value.get('FEDRAMP_HI'),
      'ETFI'
    ].filter(Boolean),
    unsetRadio: {
      [productMap.value.get('VMANAGE') as number]: null,
      [productMap.value.get('MZTNA_ZSCA') as number]: null
    } as { [key: number]: string | null }
  },
  'Agency User': {
    uncheckedCheckboxes: [
      productMap.value.get('VMANAGE'),
      productMap.value.get('VMANAGE_RO'),
      productMap.value.get('MSSTLS'),
      productMap.value.get('MTIPSTLS'),
      productMap.value.get('MZTNA_ZSCA'),
      productMap.value.get('FEDRAMP_HI'),
      'ETFI'
    ].filter(Boolean),
    unsetRadio: {
      [productMap.value.get('VMANAGE') as number]: null,
      [productMap.value.get('MZTNA_ZSCA') as number]: null
    } as { [key: number]: string | null },
    disableETFI: false
  },
  'Lumen Admin': {
    uncheckedCheckboxes: [],
    unsetRadio: {} as { [key: number]: string | null }
  },
  'Lumen User': {
    uncheckedCheckboxes: [
      productMap.value.get('VMANAGE'),
      productMap.value.get('VMANAGE_RO'),
      productMap.value.get('MSSTLS'),
      productMap.value.get('MTIPSTLS'),
      productMap.value.get('MZTNA_ZSCA'),
      productMap.value.get('FEDRAMP_HI'),
      'ETFI'
    ].filter(Boolean),
    unsetRadio: {
      [productMap.value.get('VMANAGE') as number]: null,
      [productMap.value.get('MZTNA_ZSCA') as number]: null
    } as { [key: number]: string | null },
    disableETFI: true
  },
  default: {
    uncheckedCheckboxes: [],
    unsetRadio: {
      [productMap.value.get('VMANAGE') as number]: null
    } as { [key: number]: string | null }
  }
}))

function handleChildSelection(child: any, hasNestedRadio: boolean) {
  if (child.shortName === 'TFS') {
    selectedCheckboxes.value['ETFI'] = false
  }

  if (!selectedCheckboxes.value[child.productId]) {
    // Uncheck child products and radios if parent is unchecked
    uncheckChildren(child)
    selectedRadio.value[child.productId] = null
  } else if (hasNestedRadio && !selectedRadio.value[child.productId]) {
    const firstRadio = findFirstRadioOption(child.productId)
    if (firstRadio) {
      selectedRadio.value[child.productId] = firstRadio
    }
  }
  if (child.productId === (productMap.value.get('MZTNA_ZSCA') as number)) {
    requiredOpts.value[child.productId] =
      props.userRole?.value === 'L3ADMIN' &&
      selectedCheckboxes.value[productMap.value.get('MZTNA_ZSCA') as number]
  }
}

function uncheckChildren(parentProduct: any) {
  if (parentProduct.childProducts) {
    parentProduct.childProducts.forEach((child: any) => {
      selectedCheckboxes.value[child.productId] = false
      selectedRadio.value[child.productId] = null // Uncheck any selected radio

      // If the child has further children, uncheck them too
      if (child.childProducts) {
        child.childProducts.forEach((subChild: any) => {
          selectedCheckboxes.value[subChild.productId] = false
          selectedRadio.value[subChild.productId] = null // Uncheck radio for sub-children
        })
      }
    })
  }
}

function findFirstRadioOption(childProductId: any): any | undefined {
  return productGroups.value
    .flatMap((group: any) => group.products)
    .flatMap((product: any) => product.childProducts || [])
    .filter((child: any) => child.productId === childProductId && child.childProducts?.length)
    .flatMap((child: any) => child.childProducts || [])
    .find((subChild: any) => subChild.name?.toLowerCase().includes('read only'))?.productId
}

function editSelectedUserLabel(): string {
  return roleInformationTypes.value.find((type: any) => type.value === userDetail.value.userType)
    ?.label
}

function getUserType(): string {
  if (!isEditMode.value) {
    return props.userRole?.label || 'OCO'
  }

  const selectedType = editSelectedUserLabel()
  return props.userRole?.label === selectedType ? selectedType : props.userRole?.label
}

function radioProductIds() {
  return {
    VMANAGE: {
      shortName: 'VMANAGE',
      value: productMap.value.get('VMANAGE')
    },
    READONLY: {
      shortName: 'VMANAGE_RO',
      value: productMap.value.get('VMANAGE_RO')
    },
    'READ&WRITE': {
      shortName: 'VMANAGE_RW',
      value: productMap.value.get('VMANAGE_RW')
    },
    MSSTOOL: {
      shortName: 'MSSTLS',
      value: productMap.value.get('MSSTLS')
    },
    MTIPSTOOL: {
      shortName: 'MTIPSTLS',
      value: productMap.value.get('MTIPSTLS')
    },
    'MZTNAW/ZSCALER': {
      shortName: 'MZTNA_ZSCA',
      value: productMap.value.get('MZTNA_ZSCA')
    },
    FEDRAMPHIGH: {
      shortName: 'FEDRAMP_HI',
      value: productMap.value.get('FEDRAMP_HI')
    },
    FEDRAMPMODERATE: {
      shortName: 'FEDRAMP_MD',
      value: productMap.value.get('FEDRAMP_HM')
    }
  }
}

function selectedPremissions(): void {
  const getPermissions: any = {}
  const selectedPermissions = userDetail.value.productPermissions
  const productPermissions = productGroups.value.flatMap((p) => p.products)
  const mapper = (productPermissions: Array<any>, permissonMap: any) => {
    for (let product of productPermissions) {
      if (product.hasChild) {
        permissonMap[product.shortName.toUpperCase()] = {
          parent: product.parentId,
          productId: product.productId,
          name: product.shortName
        }
        mapper(product.childProducts, permissonMap)
      } else {
        permissonMap[product.shortName.toUpperCase()] = {
          parentId: product.parentId,
          productId: product.productId,
          name: product.shortName
        }
      }
    }
  }
  mapper(productPermissions, getPermissions)
  mapUserSelectedPermissions(selectedPermissions, getPermissions)
}

function mapUserSelectedPermissions(selectedPermissions: any, existingPermissons: any): void {
  const radioIds: any = radioProductIds()
  for (let name of selectedPermissions) {
    name = name.toUpperCase()
    const radioConfigValue = radioIds[name]
    const userSelectedValue =
      existingPermissons[name] || existingPermissons[radioConfigValue?.shortName]
    if (userSelectedValue) {
      selectedCheckboxes.value[userSelectedValue.productId] = true
    }
    if (name === 'ETFI') {
      selectedCheckboxes.value['ETFI'] = true
    }
    const radioSelected = radioConfigValue && existingPermissons[radioConfigValue.shortName]
    if (radioSelected?.productId && radioIds[name]) {
      selectedRadio.value[radioSelected.parentId] = radioSelected.productId
    }
  }
}

function initializeCheckboxes(): void {
  const role = getUserType()
  const config = roleConfigurations.value[role] || roleConfigurations.value.default
  isDisableETFI.value = config?.disableETFI ?? false
  requiredOpts.value[productMap.value.get('MZTNA_ZSCA') as number] =
    props.userRole?.value === 'L3ADMIN'

  if (
    isEditMode.value &&
    (editSelectedUserLabel() === props.userRole?.label || !props.userRole?.label)
  ) {
    selectedCheckboxes.value = {}
    selectedPremissions()
    return
  }
  selectedCheckboxes.value = {}

  if (!productGroups.value.length) {
    return
  }

  productGroups.value.forEach((group: any) => {
    group.products.forEach((product: any) => {
      selectedCheckboxes.value[product.productId] = true

      if (product.shortName === 'TFS') {
        if (config.disableETFI) {
          selectedCheckboxes.value['ETFI'] = false
        } else if (config.uncheckedCheckboxes.includes('ETFI')) {
          selectedCheckboxes.value['ETFI'] = false
        } else {
          selectedCheckboxes.value['ETFI'] = true
        }
      }

      if (product.hasChild) {
        product.childProducts.forEach((child: any) => {
          selectedCheckboxes.value[child.productId] = !config.uncheckedCheckboxes.includes(
            child.productId
          )

          if (config.unsetRadio[child.productId] !== undefined) {
            selectedRadio.value[child.productId] = config.unsetRadio[child.productId]
          }
          handleChildSelection(child, child.hasChild)
          if (child.hasChild) {
            child.childProducts.forEach((subChild: any) => {
              selectedCheckboxes.value[subChild.productId] = !config.uncheckedCheckboxes.includes(
                subChild.productId
              )

              if (config.unsetRadio[child.productId] === subChild.productId) {
                selectedRadio.value[child.productId] = null
              }
            })
          }
        })
      }
    })
  })
}
const filteredProductGroups = computed(() => {
  const filteredProducts = productGroups.value.flatMap((group: any) =>
    group.products.flatMap((product: any) => {
      const childProductShortNames =
        product.childProducts?.flatMap((child: any) => {
          const subChildProductShortNames =
            child.childProducts?.flatMap((subChild: any) => {
              if (selectedRadio.value[child.productId] === subChild.productId) {
                return [subChild.shortName]
              }
              return []
            }) || []
          return selectedCheckboxes.value[child.productId]
            ? [child.shortName, ...subChildProductShortNames]
            : []
        }) || []
      return selectedCheckboxes.value[product.productId]
        ? [product.shortName, ...childProductShortNames]
        : []
    })
  )
  if (selectedCheckboxes.value['ETFI']) {
    filteredProducts.push('ETFI')
  }
  return filteredProducts
})

watch(
  () => props.userRole,
  (newRole: any) => {
    initializeCheckboxes()
  },
  { immediate: true }
)
watch(
  () => filteredProductGroups.value,
  (newValue: any) => {
    store.filteredProductGroups = filteredProductGroups.value
  },
  { immediate: true }
)
watch(
  () => productGroups.value,
  (newValue: any) => {
    if (newValue && newValue.length) {
      initializeCheckboxes()
    }
  },
  { immediate: true }
)
</script>
<template>
  <div class="product-permissions">
    <h2 class="section-title">Product Permissions</h2>

    <div v-for="group in productGroups" :key="group.productGroupId">
      <h3 class="group-title">{{ group.productGroupName }}</h3>
      <div class="chi-card__content chi-card -d--block -mt--4">
        <div class="products-grid">
          <div v-for="product in group.products" :key="product.productId" class="product-item">
            <label class="chi-checkbox -d--flex -align--middle">
              <input
                type="checkbox"
                class="chi-checkbox__input"
                :disabled="adminEditingAdimUser"
                v-model="selectedCheckboxes[product.productId]"
                @change="handleChildSelection(product, product.hasChild)"
              />
              <span class="chi-checkbox__label">{{ product.name }}</span>
            </label>

            <template v-if="product.shortName === 'TFS'">
              <div style="display: flex; gap: 20px; margin-top: 20px; margin-left: 25px">
                <label for="product_ETFI" style="display: block; white-space: nowrap">
                  <input
                    type="checkbox"
                    class="chi-checkbox__input"
                    id="product_ETFI"
                    v-model="selectedCheckboxes['ETFI']"
                    :disabled="
                      !selectedCheckboxes[product.productId] ||
                      isDisableETFI ||
                      adminEditingAdimUser
                    "
                  />
                  Toll Free Self-Service Tool (ETFI)
                  <br />
                  <i style="font-size: 10px; margin-left: 15px"
                    >(This option grants both View and Write Access)</i
                  >
                  <br />
                </label>
              </div>
            </template>

            <template v-if="product.hasChild">
              <div class="child-products-grid">
                <div
                  v-for="child in product.childProducts"
                  :key="child.productId"
                  class="child-product-item"
                >
                  <label class="chi-checkbox -d--flex -align--middle">
                    <input
                      type="checkbox"
                      class="chi-checkbox__input"
                      v-model="selectedCheckboxes[child.productId]"
                      :disabled="!selectedCheckboxes[product.productId] || adminEditingAdimUser"
                      @change="handleChildSelection(child, child.hasChild)"
                    />
                    <span class="chi-checkbox__label">{{ child.name }}</span>
                    <abbr
                      v-if="requiredOpts[child.productId]"
                      class="chi-label__required"
                      aria-label="Required field"
                      >*</abbr
                    >
                  </label>
                  <template v-if="child.hasChild">
                    <div class="sub-child-products-grid">
                      <div
                        v-for="subChild in child.childProducts"
                        :key="subChild.productId"
                        class="sub-child-product-item"
                      >
                        <label class="chi-radio -d--flex -align--middle">
                          <input
                            type="radio"
                            class="chi-radio__input"
                            :name="'subChildRadioGroup-' + child.productId"
                            v-model="selectedRadio[child.productId]"
                            :value="subChild.productId"
                            :disabled="!selectedCheckboxes[child.productId] || adminEditingAdimUser"
                          />
                          <span class="chi-radio__label">{{ subChild.name }}</span>
                        </label>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.product-permissions {
  padding: 20px;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  color: black;
  margin-bottom: 2px;
  text-transform: uppercase;
}

.group-title {
  font-size: 20px;
  font-weight: bold;
  color: black;
  margin-bottom: -25px;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 25px;
}

.product-item,
.child-product-item,
.sub-child-product-item {
  box-sizing: border-box;
  padding: 5px 0;
  color-scheme: light;
}

.child-products-grid {
  margin-left: 20px;
  padding-top: 5px;
}

.sub-child-products-grid {
  margin-left: 20px;
  padding-top: 5px;
}

.chi-checkbox {
  display: flex;
  align-items: first baseline;
}

.chi-checkbox input[type='checkbox'] {
  margin-right: 10px;
}

.chi-checkbox__label {
  font-size: 14px;
  color: #000;
}

.chi-card {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  color-scheme: light;
}

.chi-radio__label {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .products-grid {
    grid-template-columns: 1fr;
  }
}
</style>
