import { computed } from 'vue'
import { defineStore } from 'pinia'
import { format, tzDate } from '@formkit/tempo'

export const useKpiColumns = defineStore('kpiActivityLogColumnsStore', () => {
  const kpiColumns = computed(() => [
    {
      field: 'expand',
      isExpandable: true,
      locked: true
    },
    {
      label: 'Date & Time',
      field: 'createdOn',
      isSortable: true,
      formatFn: (e: string) =>
        format(tzDate(e, 'Europe/Lisbon'), { date: 'medium', time: 'medium' }),
      class: '-size--md-2'
    },
    {
      label: 'Section Name',
      field: 'sectionName',
      isSortable: true,
      class: '-d-lg--show -text--truncate'
    },
    {
      label: 'Field Name',
      field: 'fieldName',
      isSortable: true,
      class: '-text--truncate'
    },
    {
      label: 'Action Type',
      field: 'actionType',
      isSortable: true,
      formatFn: (e: string) => String(e).replace('PROPERTY_VALUE_', '').toUpperCase(),
      class: '-d-xl--show -size--md'
    },
    {
      label: 'Modified By',
      field: 'createdBy',
      isSortable: true,
      class: '-d-xl--show -size--md'
    },
    {
      label: 'Previous Value',
      field: 'previousValue',
      class: '-d-expand--show -size--lg --text-wrap'
    },
    {
      label: 'Latest Value',
      field: 'latestValue',
      class: '-d-expand--show -size--lg --text-wrap'
    }
  ])

  return {
    kpiColumns
  }
})
