import { computed } from 'vue'
import { toBeOptional } from '@centurylinkfederal/eis-vue'
import { defineStore } from 'pinia'

export const useColumns = defineStore('ndcsColumnsStore', () => {
  const columns = computed(() => [
    {
      field: 'networkSiteCode',
      label: 'NSC',
      class: '-size--md-2',
      input: {
        type: 'text',
        placeholder: 'NSC',
        required: false,
        tests: [toBeOptional]
      }
    },
    {
      field: 'location',
      label: 'Location',
      class: '-size--md-2',
      input: {
        type: 'text',
        placeholder: 'Location',
        required: false,
        tests: [toBeOptional]
      }
    },
    {
      field: '_deleted',
      label: '',
      class: '-flex--grow0 -px--0 -mx--0',
      input: {
        type: 'toggle',
        mode: 'button',
        label: '',
        icon: 'delete',
        iconSize: 'sm',
        class: '-sm -danger -flat  -px--2',
        tooltip: 'Delete'
      }
    }
  ])

  return {
    columns
  }
})
