<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue'
import { useApiActions } from '../../composables/ApiActions'
import { useRoute, useRouter } from 'vue-router'
import jwtDecode from 'jwt-decode'
import {
  toMatchWith,
  toNotBeEmpty,
  Input,
  PasswordCell,
  doScrollToTop,
  dangerAlert
} from '@centurylinkfederal/eis-vue'
import { storeToRefs } from 'pinia'
import { useStore } from './store'
import Alerts from './Alerts.vue'

const actions = useApiActions()
const validationRules = [
  { id: 'passwordLength', message: '16 min- 32 max characters' },
  { id: 'upperAlphabet', message: '1 uppercase' },
  { id: 'lowerAlphabet', message: '1 lowercase' },
  { id: 'number', message: '1 numeric' },
  { id: 'splCharacter', message: '1 special character' }
] as const
const passwordOption = {
  field: 'newPassword',
  label: 'New Password',
  colClass: '-rules-container',
  input: {
    type: 'password',
    label: 'New Password',
    maxLength: 64,
    required: true,
    displayRules: true,
    delayTime: 500,
    validationRules: validationRules,
    asyncTest: (password: string) =>
      actions.validateLastTenPassword({
        password: password,
        userName: route.query?.userName as string
      }),
    tests: [toNotBeEmpty, () => toMatchWith(passwordInput, confirmPasswordInput)]
  }
}

const confirmPasswordOption = {
  field: 'confirmPassword',
  label: 'Confirm New Password',
  input: {
    type: 'password',
    label: 'Confirm New Password',
    maxLength: 64,
    required: true,
    tests: [
      toNotBeEmpty,
      () =>
        toMatchWith(confirmPasswordInput, passwordInput, 'Password does not match. Please confirm.')
    ]
  }
}
const store = useStore()
const route = useRoute()
const router = useRouter()
const isLoading = ref(false)
const showFeedback = ref(false)
const isTokenValid = ref(false)
const prevPasswordUsed = ref(false)
const { alertMap } = storeToRefs(store)
const passwordInput: Input = reactive(new Input(passwordOption.input))
const confirmPasswordInput: Input = reactive(new Input(confirmPasswordOption.input))

onMounted(async () => {
  alertMap.value = new Map()
  isTokenValid.value = await actions.validateEmailToken(
    route.query?.userName as string,
    route.query?.token as string
  )
  if (!isTokenValid.value) {
    alertMap.value.set('alert', {
      ...dangerAlert,
      title: 'Link has expired. Please request a new password reset link.'
    })
  } else {
    startTokenValidationCheck()
  }
})
/**
 * Starts a timeout to check for token expiration.
 * If the token is still valid, it sets a timeout to show an alert and mark the token as invalid when it expires.
 * If the token is already expired, it immediately shows the alert and marks the token as invalid.
 */
function startTokenValidationCheck() {
  const token: string = route.query?.token as string
  const data = jwtDecode<{ exp: number }>(token)
  const nowSecs = Date.now() / 1000
  const tokenValidUntilSecs = data.exp
  const timeUntilExpiration = (tokenValidUntilSecs - nowSecs) * 1000

  if (timeUntilExpiration > 0) {
    setTimeout(() => {
      alertMap.value.set('alert', {
        ...dangerAlert,
        title: 'Link has expired. Please request a new password reset link.'
      })
      isTokenValid.value = false
    }, timeUntilExpiration)
  } else {
    alertMap.value.set('alert', {
      ...dangerAlert,
      title: 'Link has expired. Please request a new password reset link.'
    })
    isTokenValid.value = false
  }
}

function onChange(newValue: any, input: Input): void {
  input.set(newValue)
  input.validate()
}

async function onConfirm(): Promise<void> {
  alertMap.value = new Map()
  passwordInput.validate()
  confirmPasswordInput.validate()
  if (
    passwordInput.state === 'danger' ||
    confirmPasswordInput.state === 'danger' ||
    prevPasswordUsed.value
  ) {
    doScrollToTop()
    alertMap.value.set('alert', {
      ...dangerAlert,
      title: prevPasswordUsed.value
        ? 'Please use a different password than the last 10 passwords.'
        : `Please complete all Required Fields indicated with * before attempting to submit changes.`
    })
    return
  }

  isLoading.value = true
  const isInValid = await actions.resetPassword({
    userName: route.query?.userName as string,
    password: passwordInput.value
  })
  isLoading.value = false
  if (isInValid) {
    alertMap.value.set('alert', {
      ...dangerAlert,
      title: 'Password reset failed. Please try again.'
    })
    isLoading.value = false
    return
  }
  showFeedback.value = true
  setTimeout(() => {
    router.push('/login')
  }, 5000)
}

function verifyPasswordHistory(isValid: boolean) {
  prevPasswordUsed.value = !isValid
}
</script>
<template>
  <div v-if="isTokenValid">
    <div
      class="chi-grid chi-col -o-sm--1 -w--9 -o-lg--3 -mt--4 -o--1"
      :class="!showFeedback ? '-w-lg--6' : '-w-lg--8'"
    >
      <Alerts />
      <div class="-text--h3 -text--boldest -m--0 -pr--2 -text--900 chi-col -w--12 -p--0 -mb--1">
        Reset Password
      </div>
      <div class="-p--4 -bg--grey-10" v-if="!showFeedback">
        <fieldset>
          <p class="-m--0 -mb--2">Your username: {{ route.query?.userName ?? '' }}</p>
          <p class="chi-label -mb--4">Please enter and confirm your new password</p>

          <div class="-mt--4">
            <PasswordCell
              :cell="passwordOption"
              :input="passwordInput"
              @onError="verifyPasswordHistory"
              @OnChange="(newValue: string) => onChange(newValue, passwordInput)"
            />
          </div>

          <div class="-mt--4">
            <PasswordCell
              :cell="confirmPasswordOption"
              :input="confirmPasswordInput"
              @OnChange="(newValue: string) => onChange(newValue, confirmPasswordInput)"
            />
          </div>
          <div class="-mt--4">
            <button
              class="chi-button -primary -w--100 -d--block"
              type="button"
              :disabled="isLoading"
              @click="onConfirm"
            >
              CONFIRM
              <svg
                class="chi-spinner -icon--primary -sm -position--absolute -ml--10"
                :class="{ '-d--none': !isLoading }"
                viewBox="0 0 66 66"
              >
                <circle class="path" cx="33" cy="33" r="30" fill="none" stroke-width="6"></circle>
              </svg>
            </button>
          </div>
        </fieldset>
      </div>
      <template v-else>
        <div class="chi-alert -success -close" role="alert">
          <i class="chi-alert__icon chi-icon icon-circle-check" aria-hidden="true"></i>
          <div class="chi-alert__content">
            <p class="chi-alert__title">Password Reset Successfull</p>
            <p class="chi-alert__text">
              Your password has been successfully changed. You will be re-directed to login page in
              5 seconds or click on button below to go back to the login page.
            </p>
            <div class="chi-alert__actions">
              <button class="chi-button -xs" @click="router.push('/login')">Login</button>
            </div>
          </div>
          <button class="chi-alert__close-button chi-button -icon -close" aria-label="Close">
            <div class="chi-button__content">
              <i class="chi-icon icon-x" aria-hidden="true"></i>
            </div>
          </button>
        </div>
      </template>
    </div>
  </div>
  <div v-else>
    <div class="chi-grid chi-col -o-sm--1 -w--9 -o-lg--3 -mt--4 -o--1 -w-lg--8">
      <Alerts />
    </div>
  </div>
</template>

<style>
.-rules-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  font-size: 12px;
}
</style>
