<script lang="ts" setup>
import { defineProps, Ref, ref, unref, watch, onMounted } from 'vue'
import EditNSCModal from './EditNSCModal.vue'
import ViewEditClins from './ViewEditClins.vue'
import ViewEditCountryJurisdiction from './ViewEditServiceCountryJuridiction.vue'
import ImportFile from '../ImportFile.vue'
import { Modal } from '@centurylinkfederal/eis-vue'

const props = defineProps(['taskOrderNumber', 'taskOrderId'])
const emit = defineEmits(['cancel'])

import { useToAdminAPI } from '../../composables/to-admin/api'
import { useTOAdminStore } from '../../store/to-admin'
import { useAlertsStore } from '../../store/alert'
import { storeToRefs } from 'pinia'

const api = useToAdminAPI()
const alertStore = useAlertsStore()

const toAdminStore = useTOAdminStore()
const { isTaskOrderSvcUpdated } = storeToRefs(toAdminStore)

const originalSelectedTaskOrderServices: Ref<any[]> = ref([])
const allServices: Ref<any[]> = ref([])
const edit: Ref<boolean> = ref<boolean>(false)
const selectedService: Ref<string> = ref('')
const show: Ref<boolean> = ref(false)
const selectedTaskOrderServices: Ref<any[]> = ref([])
const clins: Ref<any[]> = ref([])
const clinIdentifiers: Ref<any[]> = ref([])
const currentServiceId: Ref<string> = ref('')
const isImportType: Ref<boolean> = ref<boolean>(false)
const individualClinError: Ref<string> = ref<string>('')
const showClins: Ref<boolean> = ref<boolean>(false)
const showJurisdictions: Ref<boolean> = ref<boolean>(false)
const selectedJurisdictions: Ref<any[]> = ref([])
const errorMsg: Ref<string> = ref('')
const jurisdictonsLookupData: Ref<any[]> = ref([])
const jurisdictonsLookupCodes: Ref<any[]> = ref([])
const selectedToServicesDTO: Ref<any[]> = ref([])
const showNSCPopup: Ref<boolean> = ref<boolean>(false)
const invalidDataServiceIds: Ref<any[]> = ref([])
const invalidDataClinsIds: Ref<any[]> = ref([])
const isSaveEnabled: Ref<boolean> = ref<boolean>(false)
const isLoading: Ref<boolean> = ref<boolean>(false)
const MWS: Ref<string> = ref('MWS')
const showMSWpopUp: Ref<boolean> = ref<boolean>(false)
const vendorPo: Ref<number | null> = ref<number | null>(null)
const vendorPoSaved: Ref<number | null> = ref<number | null>(null)
const showToServiceModal: Ref<boolean> = ref<boolean>(false)
const fields = ['serviceID', 'identifier']

const headers = ['Service Id', 'All_Clins']

onMounted(async () => {
  const response: any = await api.getJurisdictions()
  const data = response.results.slice(0)
  jurisdictonsLookupData.value = data
  jurisdictonsLookupCodes.value = jurisdictonsLookupData.value.map(
    (value) => value.jurisdictionCode
  )
})

watch(
  () => selectedTaskOrderServices.value,
  () => (isSaveEnabled.value = shouldDisableSaveBtn()),
  { deep: true }
)

watch(
  () => [toAdminStore.selectedToServicesDTO, toAdminStore.services],
  () => {
    allServices.value = toAdminStore.services
    selectedToServicesDTO.value = toAdminStore.selectedToServicesDTO

    selectedTaskOrderServices.value = selectedToServicesDTO.value.map((tos) => {
      clinIdentifiers.value[tos.serviceCode] = tos.allClins
      if (tos.vendorPo) {
        vendorPo.value = tos.vendorPo
        vendorPoSaved.value = tos.vendorPo
      }
      originalSelectedTaskOrderServices.value.push(tos)
      return tos.serviceCode
    })
    originalSelectedTaskOrderServices.value = [...(originalSelectedTaskOrderServices.value || [])]
  },
  { deep: true, immediate: true }
)

function getSelectedClinCodesForAService(serviceId: string): string[] {
  let selectedServiceDto = getServiceDtoFromSelectedList(serviceId)
  if (selectedServiceDto) {
    if (selectedServiceDto.allClins == false) {
      return selectedServiceDto.clins
    }
  }
  return []
}

function getServiceDtoFromSelectedList(serviceId: string): any {
  if (!serviceId) {
    return {
      taskOrderNSCList: [],
      jurisdictions: []
    }
  }
  let service = selectedToServicesDTO.value.find(
    (selectToDto) => selectToDto.serviceCode === serviceId
  )
  if (!service) {
    service = {}
  }
  if (service.jurisdictions == undefined) {
    service.jurisdictions = []
  }
  if (service.taskOrderNSCList == undefined) {
    service.taskOrderNSCList = []
  }
  return unref(service)
}

function openClins(serviceId: any): void {
  errorMsg.value = ''
  currentServiceId.value = String(serviceId)
  const service: any = allServices.value.find((s) => serviceId === s.serviceID)
  isImportType.value = service?.isImport
  clins.value = service?.clins || []
  setTimeout(() => {
    showClins.value = true
  }, 10)
}

function closeClins(): void {
  showClins.value = false
}

function saveClins(event: any[]): void {
  errorMsg.value = ''
  const serviceObjRef = getServiceDtoFromSelectedList(currentServiceId.value)
  if (event.length === 0 || event.length === clins.value.length) {
    serviceObjRef.allClins = true
    serviceObjRef.clins = []
    changeServiceIdentifier(currentServiceId.value, true)
  } else {
    serviceObjRef.allClins = false
    serviceObjRef.clins = event.slice()
    changeServiceIdentifier(currentServiceId.value, false)
  }
  showClins.value = false
  isSaveEnabled.value = shouldDisableSaveBtn()
}

function changeServiceIdentifier(serviceId: string, isAllClinsSelected: boolean): void {
  errorMsg.value = ''
  clinIdentifiers.value = {
    ...clinIdentifiers.value,
    [serviceId]: isAllClinsSelected
  }
}

function changeServiceIdentifierFromUI(serviceId: string, isAllClinsSelected: boolean): void {
  if (isAllClinsSelected) {
    const serviceObjRef = getServiceDtoFromSelectedList(serviceId)
    serviceObjRef.allClins = true
    serviceObjRef.clins = []
    clinIdentifiers.value = {
      ...clinIdentifiers.value,
      [serviceId]: true
    }
  } else {
    openClins(serviceId)
  }
}

function checkServiceIdentifier(event: any): void {
  errorMsg.value = ''

  if (!event.target.checked) {
    selectedToServicesDTO.value = selectedToServicesDTO.value.filter(
      (dto) => dto.serviceCode != event.currentTarget.defaultValue
    )
    selectedTaskOrderServices.value = selectedTaskOrderServices.value.filter(
      (toService) => toService !== event.currentTarget.defaultValue
    )
    return
  }
  currentServiceId.value = event.currentTarget.defaultValue
  selectedTaskOrderServices.value.push(currentServiceId.value)
  selectedToServicesDTO.value.push({
    taskOrderId: null,
    toServiceId: null,
    serviceCode: event.currentTarget.defaultValue,
    clins: null,
    allClins: true,
    jurisdictions: [],
    taskOrderNSCList: []
  })
  changeServiceIdentifier(event.currentTarget.defaultValue, true)
}

function saveJurisdictionData(savedData: any): void {
  const serviceDto = getServiceDtoFromSelectedList(currentServiceId.value)
  serviceDto.jurisdictions = savedData
  closeJurisdictionOptions()
  isSaveEnabled.value = shouldDisableSaveBtn()
}
function showJurisdictionOptions(serviceId: any): void {
  errorMsg.value = ''
  currentServiceId.value = serviceId
  showJurisdictions.value = true
}

function closeJurisdictionOptions(): void {
  showJurisdictions.value = false
}

function cancel(): void {
  edit.value = false
  emit('cancel')
}

async function save(): Promise<void> {
  individualClinError.value = ''
  let selectedToServicesDTODuplicte = []
  invalidDataServiceIds.value = []
  invalidDataClinsIds.value = []

  for (let serviceDto of selectedToServicesDTO.value) {
    serviceDto.vendorPo =
      serviceDto.serviceCode === 'MWS' && vendorPoSaved.value ? +vendorPoSaved.value : null
    if (
      checkForCbsaBasedService(serviceDto.serviceCode) &&
      serviceDto.jurisdictions.length === 0 &&
      serviceDto.taskOrderNSCList.length === 0
    ) {
      invalidDataServiceIds.value.push(serviceDto.serviceCode)
    } else {
      serviceDto.taskOrderId = props.taskOrderId
      selectedToServicesDTODuplicte.push(serviceDto)
    }

    if (!serviceDto.allClins && !serviceDto.clins) {
      invalidDataClinsIds.value.push(serviceDto.serviceCode)
    }
  }
  if (invalidDataClinsIds.value.length) {
    individualClinError.value = 'Please select atleast one clin for each individual clin'
    return
  }
  if (!invalidDataServiceIds.value.length) {
    for (let entry of originalSelectedTaskOrderServices.value) {
      let taskOrderSvc = selectedToServicesDTO.value.find(
        (selectToDto) => selectToDto.serviceCode === entry.serviceCode
      )
      if (taskOrderSvc === undefined) {
        entry.serviceCode = 'delete'
        entry.taskOrderId = props.taskOrderId
        selectedToServicesDTO.value.push(entry)
      }
    }
    selectedToServicesDTO.value.forEach((dto: any) => {
      if (dto.jurisdictions) {
        dto.jurisdictions.forEach((jurisdiction: any) => {
          const jurisd = jurisdictonsLookupData.value.find(
            (j: any) => j.jurisdictionCode == jurisdiction.jurisdId
          )
          if (jurisd) {
            jurisdiction.country = jurisd.countryDesc
          }
        })
      }
    })
    isLoading.value = true
    await toAdminStore.saveTaskOrderSvcDetailsDTO(selectedToServicesDTO.value)
    isLoading.value = false
    if (isTaskOrderSvcUpdated.value) {
      cancel()
    } else {
      alertStore.alerts = []
      alertStore.addAlert('danger', `Error occurred while processing the request.`)
      window.scroll({ top: 0, behavior: 'smooth' })
    }
  }
}

function checkForCbsaBasedService(svcId: string): any {
  let validateNeeded = false
  let tempService: any = allServices.value.find((service: any) => service.serviceID === svcId)
  if (tempService.cbsaBased !== 'No') {
    validateNeeded = true
  }
  return validateNeeded
}

function openNSC(serviceID: any): void {
  currentServiceId.value = serviceID
  showNSCPopup.value = true
}

function closeNSC(): void {
  showNSCPopup.value = false
}

function saveNSC(event: any[]): void {
  const serviceDto = getServiceDtoFromSelectedList(currentServiceId.value)
  serviceDto.taskOrderNSCList = event
  closeNSC()
  isSaveEnabled.value = shouldDisableSaveBtn()
}

function closeMWSpopup(): void {
  showMSWpopUp.value = false
  if (!vendorPoSaved.value) {
    vendorPo.value = null
  }
}

function openMSWPopup(event: any): void {
  event.stopImmediatePropagation()
  vendorPo.value = vendorPoSaved.value
  showMSWpopUp.value = true
}

function applyVendorPoValue(): void {
  vendorPoSaved.value = vendorPo.value
  isSaveEnabled.value = true
  closeMWSpopup()
}

const actionButtons = [
  { text: 'Apply' },
  {
    text: 'Cancel',
    dismissModal: true
  }
]

function actionBtnClicked(index: number): void {
  if (index === 0) {
    applyVendorPoValue()
  }
  if (index === 1) {
    closeMWSpopup()
  }
}

function allowNumbersOnly(event: any): void {
  const value = String.fromCharCode(event.charCode)
  const allowedChar = /^\d+$/g
  if (!allowedChar.test(value)) {
    event.preventDefault()
  }
}

function openDialog(): void {
  showToServiceModal.value = true
}

function importedFileInfo(details: any = {}): void {
  const { contents } = details
  contents.forEach((data: any) => {
    const foundClins = allServices.value.find((s) => data.serviceID === s.serviceID)
    if (foundClins) foundClins.isImport = true
    let existingServiceDTO = selectedToServicesDTO.value.find(
      (selectedDTO) => selectedDTO.serviceCode == data.serviceID
    )
    const isAllClins = data.identifier.replace('\r', '') === 'Y'
    if (existingServiceDTO) {
      existingServiceDTO = {
        ...existingServiceDTO,
        allClins: isAllClins,
        clins: isAllClins ? existingServiceDTO : []
      }
    } else {
      selectedToServicesDTO.value.push({
        taskOrderId: null,
        toServiceId: null,
        serviceCode: data.serviceID,
        clins: null,
        allClins: isAllClins,
        jurisdictions: [],
        taskOrderNSCList: []
      })
    }
    if (!selectedTaskOrderServices.value.includes(data.serviceID)) {
      selectedTaskOrderServices.value.push(data.serviceID)
      clinIdentifiers.value = {
        ...clinIdentifiers.value,
        [data.serviceID]: isAllClins
      }
    } else if (selectedTaskOrderServices.value.includes(data.serviceID)) {
      clinIdentifiers.value = {
        ...clinIdentifiers.value,
        [data.serviceID]: isAllClins
      }
    }
  })
  showToServiceModal.value = false
}

function shouldDisableSaveBtn(): boolean {
  if (originalSelectedTaskOrderServices.value.length !== selectedToServicesDTO.value.length) {
    return true
  }
  for (let s of selectedToServicesDTO.value) {
    const org = originalSelectedTaskOrderServices.value.find((o) => o.serviceCode === s.serviceCode)
    if (!org) {
      return true
    }
    const fields = ['allClins', 'clins', 'serviceCode']
    if (fields.some((f) => s[f] != org[f])) {
      return true
    }
    if (s['jurisdictions'].sort().join(',') !== org['jurisdictions'].sort().join(',')) {
      return true
    }
    if (s['taskOrderNSCList'].sort().join(',') !== org['taskOrderNSCList'].sort().join(',')) {
      return true
    }
    const b = areJSONArraysEqual(s['taskOrderNSCList'].sort(), org['taskOrderNSCList'].sort())
    if (!b) {
      return true
    }
  }
  return false
}

function areJSONArraysEqual(a: any, b: any): boolean {
  for (let i = 0; i < a.length; i++) {
    let taskOrderNscId1 = a[i]['taskOrderNscId']
    for (let r = 0; r < b.length; r++) {
      let taskOrderNscId2 = b[r]['taskOrderNscId']
      if (taskOrderNscId1 == taskOrderNscId2) {
        if (
          a[i]['networkSiteCode'] != b[r]['networkSiteCode'] ||
          a[i]['location'] != b[r]['location']
        ) {
          return false
        }
      }
    }
  }
  return true
}
</script>

<template>
  <div class="edit-to-service">
    <div>
      <fieldset class="bottom-marigin-10" style="max-height: 500px; overflow: auto">
        <div :key="`${s}-${index}`" class="m-form__item -ml--1" v-for="(s, index) in allServices">
          <div class="chi-grid">
            <div class="chi-checkbox chi-col -sm--w3, max-size3">
              <input
                :id="`service_${s.serviceID}`"
                :value="s.serviceID"
                @click="checkServiceIdentifier($event)"
                class="chi-checkbox__input"
                type="checkbox"
                v-model="selectedTaskOrderServices"
              />

              <label :for="`service_${s.serviceID}`" class="chi-checkbox__label"
                ><span
                  class="red-color"
                  v-if="
                    invalidDataServiceIds.includes(s.serviceID) ||
                    invalidDataClinsIds.includes(s.serviceID)
                  "
                  >* </span
                >{{ s.serviceID }} - {{ s.serviceDesc }}</label
              >
            </div>
            <div
              @click="changeServiceIdentifierFromUI(s.serviceID, true)"
              class="chi-checkbox chi-col -sm--w2, max-size2"
              v-if="selectedTaskOrderServices.includes(s.serviceID)"
            >
              <div>
                <input
                  class="chi-radio__input"
                  type="radio"
                  v-model="clinIdentifiers[s.serviceID]"
                  value="true"
                  :id="`allClins_${s.serviceID}`"
                />
                <label class="chi-radio__label">All CLINs</label>
              </div>
            </div>
            <div
              @click="changeServiceIdentifierFromUI(s.serviceID, false)"
              class="chi-checkbox chi-col -w2, max-size"
              v-if="selectedTaskOrderServices.includes(s.serviceID)"
            >
              <div>
                <input
                  class="chi-radio__input"
                  type="radio"
                  v-model="clinIdentifiers[s.serviceID]"
                  value="false"
                  :id="`inClins_${s.serviceID}`"
                />
                <label class="chi-radio__label">Individual CLINs</label>
              </div>
            </div>
            <div
              class="chi-col -sm--w2, max-size"
              v-if="selectedTaskOrderServices.includes(s.serviceID)"
            >
              <button
                @click="openClins(s.serviceID)"
                class="chi-button ---primary -sm"
                style="width: max-content"
                v-if="!clinIdentifiers[s.serviceID]"
                :id="`viewEditClins_${s.serviceID}`"
              >
                View/Edit CLINs
              </button>
            </div>

            <div
              class="chi-col -w2 max-size"
              v-if="s.serviceID === MWS && selectedTaskOrderServices.includes(s.serviceID)"
            >
              <div>
                <button
                  @click="openMSWPopup($event)"
                  class="chi-button -sm --primary"
                  style="width: max-content"
                  :id="`viewEditClins_${s.serviceID}`"
                >
                  {{ !!vendorPo ? 'View/Edit Vendor PO' : 'Add Vendor PO' }}
                </button>
              </div>
            </div>
            <div
              v-if="s.cbsaBased !== 'No' && selectedTaskOrderServices.includes(s.serviceID)"
              class="chi-col -sm--w3"
            >
              <button
                @click="showJurisdictionOptions(s.serviceID)"
                class="chi-button -sm --primary"
                style="width: max-content; padding-inline: 1em"
                :disabled="
                  getServiceDtoFromSelectedList(s.serviceID).taskOrderNSCList.length > 0 &&
                  getServiceDtoFromSelectedList(s.serviceID).jurisdictions.length <= 0
                "
                :id="`countryJurisdiction_${s.serviceID}`"
              >
                {{
                  getServiceDtoFromSelectedList(s.serviceID).jurisdictions.length > 0
                    ? 'View/Edit'
                    : 'Add'
                }}
                Country/Jurisdiction IDs
              </button>
            </div>
            <div
              v-if="s.cbsaBased !== 'No' && selectedTaskOrderServices.includes(s.serviceID)"
              class="chi-col -sm--w2"
            >
              <button
                v-on:click="openNSC(s.serviceID)"
                class="chi-button -sm --primary"
                style="width: max-content"
                :disabled="
                  getServiceDtoFromSelectedList(s.serviceID).jurisdictions.length > 0 &&
                  getServiceDtoFromSelectedList(s.serviceID).taskOrderNSCList.length <= 0
                "
                :id="`nsc_${s.serviceID}`"
              >
                {{
                  getServiceDtoFromSelectedList(s.serviceID).taskOrderNSCList.length > 0
                    ? 'View/Edit'
                    : 'Add'
                }}
                NSCs
              </button>
            </div>
          </div>
          <div class="chi-divider" v-if="index !== allServices.length - 1"></div>
        </div>
      </fieldset>
      <div v-if="invalidDataServiceIds.length">
        <span class="red-color">* Please select Jurisdictions or NSC</span>
      </div>
      <div v-if="individualClinError">
        <span class="red-color">* {{ individualClinError }}</span>
      </div>
      <div class="-mt--5 -text--center">
        <div class="-mt--5 -text--center">
          <button
            class="chi-button -sm --primary -mr--2"
            v-on:click="save()"
            :disabled="!isSaveEnabled || isLoading"
            id="save-to-services"
          >
            {{ isLoading ? 'Saving' : 'Save' }}
            <div v-if="isLoading" class="-text--center center-loading">
              <svg class="chi-spinner -text--primary -sm--3" viewBox="0 0 66 66">
                <title>Loading</title>
                <circle class="path" cx="33" cy="33" r="30" fill="none" stroke-width="6"></circle>
              </svg>
            </div>
          </button>
          <button class="chi-button -sm --primary -mr--2" v-on:click="openDialog()">Import</button>
          <button class="chi-button -sm" v-on:click="cancel()">Cancel</button>
        </div>
      </div>
    </div>
    <!-- Vendor PO -->
    <div v-if="showMSWpopUp">
      <Modal
        :center="true"
        title="Vendor PO"
        :buttons="actionButtons"
        @onButtonClick="actionBtnClicked"
        @onDismissModal="closeMWSpopup"
      >
        <div>
          <input
            class="chi-input"
            maxlength="10"
            placeholder="PO Number"
            type="text"
            id="vendorPo"
            v-model="vendorPo"
            @keypress="allowNumbersOnly($event)"
          />
        </div>
      </Modal>
    </div>

    <div v-if="showToServiceModal">
      <ImportFile
        title="To Services"
        :dataFields="fields"
        :headerDetails="headers"
        @importContent="importedFileInfo($event)"
        @closeModal="showToServiceModal = false"
      />
    </div>

    <div v-if="showNSCPopup">
      <EditNSCModal
        @close="closeNSC()"
        @saveNSC="saveNSC($event)"
        :serviceId="currentServiceId"
        :nscDataInbound="getServiceDtoFromSelectedList(currentServiceId).taskOrderNSCList"
      />
    </div>

    <!-- Individual Clins Pop up -->
    <div v-if="showClins">
      <ViewEditClins
        :allClins="clins"
        :serviceCode="currentServiceId"
        :selectedClinCodesInput="getSelectedClinCodesForAService(currentServiceId)"
        @selectedClins="saveClins"
        @close="closeClins()"
      />
    </div>
    <!-- Country Juridiction -->
    <div v-if="showJurisdictions">
      <ViewEditCountryJurisdiction
        :serviceId="currentServiceId"
        :jurisdictonsLookupCodes="jurisdictonsLookupCodes"
        :jurisdictonsLookupData="jurisdictonsLookupData"
        :selectedJurisdictionsData="getServiceDtoFromSelectedList(currentServiceId).jurisdictions"
        @close="closeJurisdictionOptions()"
        @saveSelectedJuridictionCodes="saveJurisdictionData"
      >
      </ViewEditCountryJurisdiction>
    </div>
  </div>
</template>

<style scoped>
.table-cell-v1 {
  text-align: left !important;
  width: 10%;
}

.table-cell-v2 {
  text-align: left !important;
}

thead > tr > td.table-cell {
  background-color: rgb(146, 146, 146);
}

.modal-backdrop {
  z-index: -1;
}

.red-color {
  color: red;
}

.bottom-marigin-10 {
  margin-bottom: 10px;
}

.max-size {
  flex: 0 1 15%;
  max-width: 15%;
  padding: 0 6px;
}

.max-size2 {
  flex: 0 1 9%;
  max-width: 9%;
  padding: 0 6px;
}

.max-size3 {
  flex: 0 1 18%;
  max-width: 18%;
  padding: 0 6px;
}

.chi .chi-label,
.chi label {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-bottom: 0.25rem;
  display: inline;
}
.center-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dark-bg {
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
