<script lang="ts" setup>
import { ref, watch, computed, toRaw } from 'vue'
import { storeToRefs } from 'pinia'
import { addDay, parse, format, ParseOptions } from '@formkit/tempo'
import { orderBy } from 'lodash'
import { DataTable } from '@centurylinkfederal/eis-vue'
import { useToAdminAPI } from '../../composables/to-admin/api'
import { useColumns } from './activitylogColumns.ts'
import { useKpiColumns } from './kpiActivitylogColumns.ts'
import { getStore } from './store'
import { useStore as useModsStore } from './modsStore'
import TOActivityLogDownloadModal from '../to-admin/TOActivityLogDownloadModal.vue'
import DataTableExpanded from '../common/DataTableExpanded.vue'
import { useStore as useServicesStore } from './servicesStore'

export interface Props {
  pageMode: 'create' | 'edit'
  panel?: undefined | 'activitylog' | 'activitylogKpis' | ''
}
const props = withDefaults(defineProps<Props>(), {
  pageMode: 'create',
  panel: 'activitylog'
})
const pageMode = ref(props.pageMode)
const pageStore = getStore(pageMode.value)()
const modsStore = useModsStore()
const { rows: modsRows } = storeToRefs(modsStore)
const servicesStore = useServicesStore()
const { picks } = storeToRefs(servicesStore)
const { selectedTaskOrderObj, summary, updateSeq } = storeToRefs(pageStore)

const columnsStore = useColumns()
const kpiColumnsStore = useKpiColumns()
const { columns } = storeToRefs(columnsStore)
const { kpiColumns } = storeToRefs(kpiColumnsStore)
const api = useToAdminAPI()
const pagination = ref<any>({ currentPage: 1, totalResults: 0, rowsPerPage: 10, offset: 0 })
const paginationKPI = ref<any>({ currentPage: 1, totalResults: 0, rowsPerPage: 10, offset: 0 })
const sortParams = ref<any>([])
const showSelectedModDownload = ref<boolean>(false)
const showAllModDownload = ref<boolean>(false)
const showKPIModDownload = ref<boolean>(false)
const activityLogResponse = ref<{ data: any[]; totalRecords: number }>({
  data: [],
  totalRecords: 0
})
interface KPIActivityLogResponse {
  totalRecords: number
  kpiActivityLogList: any[]
}
const kpiActivityLogResponse = ref<{ data: KPIActivityLogResponse }>({
  data: { totalRecords: 0, kpiActivityLogList: [] }
})

const ActicityLogErrorCodes = {
  NO_DATA: 'acitivity_log.no_data',
  FETCHING_ERROR: 'activity_log.error_fetching',
  DOWNLOAD_ERROR: 'activity_log.error_downloading_psv'
}

const KPILogErrorCodes = {
  NO_DATA: 'kpi_log.no_data',
  FETCHING_ERROR: 'kpi_log.error_fetching',
  DOWNLOAD_ERROR: 'kpi_log.error_downloading_psv'
}

const errorMessages: Record<string, string> = {
  [ActicityLogErrorCodes.NO_DATA]: 'No Data',
  [ActicityLogErrorCodes.FETCHING_ERROR]: 'Error Fetching Data',
  [ActicityLogErrorCodes.DOWNLOAD_ERROR]: 'Error Downloading File',
  [KPILogErrorCodes.NO_DATA]: 'No Data',
  [KPILogErrorCodes.FETCHING_ERROR]: 'Error Fetching Data',
  [KPILogErrorCodes.DOWNLOAD_ERROR]: 'Error Downloading File'
}

const errors = ref<string[]>([])

watch(
  () => activityLogResponse.value?.totalRecords,
  (newValue) => {
    pagination.value.totalResults = newValue
    setTimeout(() => {
      summary.value = {
        ...summary.value,
        activitylogCount: newValue
      }
    }, 50)
  }
)
watch(
  () => kpiActivityLogResponse.value.data.totalRecords,
  (newValue) => {
    paginationKPI.value.totalResults = newValue
    summary.value = {
      ...summary.value,
      activitylogKpisCount: newValue
    }
  }
)
const serverParams = computed(() => {
  const params: any = {
    offset: pagination.value.offset,
    limit:
      pagination.value.rowsPerPage === '_all'
        ? pagination.value.totalResults
        : pagination.value.rowsPerPage
  }
  if (sortParams.value.length) {
    params.sortField = sortParams.value[0]
    params.sortDirection = sortParams.value[1]
  }
  return params
})

const serverParamsKPI = computed(() => {
  const params: any = {
    offset: paginationKPI.value.currentPage - 1,
    limit:
      paginationKPI.value.rowsPerPage === '_all'
        ? paginationKPI.value.totalResults
        : paginationKPI.value.rowsPerPage
  }
  params.sortBy = 'sectionName'
  params.sortDirection = 'desc'
  return params
})

const settings: any = {
  key: 'taskOrderEditLogId',
  rowsPerPageOptions: [10, 20, 40, 60, 80],
  rowsPerPageDefaultValue: 10,
  striped: true,
  expandable: true,
  tableBodyStyle: { height: '400px', overflowY: 'auto' }
}
const kpisSettings: any = {
  key: 'kpiActivityLogId',
  rowsPerPageOptions: [10, 20, 40, 60, 80],
  rowsPerPageDefaultValue: 10,
  striped: true,
  expandable: true,
  tableBodyStyle: { height: '400px', overflowY: 'auto' }
}

function onPageChange($event: any): void {
  pagination.value = {
    ...pagination.value,
    currentPage: $event,
    offset: pagination.value.rowsPerPage * ($event - 1)
  }
}
function onKPIPageChange($event: any): void {
  paginationKPI.value = {
    ...paginationKPI.value,
    currentPage: $event,
    offset: paginationKPI.value.rowsPerPage * ($event - 1)
  }
}

function onPageSizeChange($event: any): void {
  pagination.value = {
    ...pagination.value,
    rowsPerPage: $event,
    offset: 0,
    currentPage: 1
  }
}
function onKPIPageSizeChange($event: any): void {
  paginationKPI.value = {
    ...paginationKPI.value,
    rowsPerPage: $event,
    offset: 0,
    currentPage: 1
  }
}

function onSortChange($event: any): void {
  const [sortField, sortDirection] = $event
  activityLogResponse.value.data = orderBy(
    activityLogResponse.value.data,
    [sortField],
    [sortDirection]
  )
}

function onKPISortChange($event: any): void {
  const [sortField, sortDirection] = $event
  kpiActivityLogResponse.value.data.kpiActivityLogList = orderBy(
    kpiActivityLogResponse.value.data.kpiActivityLogList,
    [sortField],
    [sortDirection]
  )
}
function toNextDay(dateStr: string): string {
  const toDate = parse({
    date: String(dateStr),
    format: 'YYYY/MM/DD'
  } as ParseOptions)
  const nextDay = addDay(toDate)
  const nextDayStr = format(nextDay, 'YYYY/MM/DD')
  return nextDayStr
}
async function downloadSelectedModFile($event: any): Promise<void> {
  const taskOrderId = selectedTaskOrderObj.value?.taskOrderId
  const from = $event?.from
  const to = toNextDay($event?.to)
  const params = { taskOrderId, from, to }
  try {
    await api.downloadActivityLog(params)
  } catch {
    addAlert(ActicityLogErrorCodes.DOWNLOAD_ERROR)
  }
  showSelectedModDownload.value = false
}

async function downloadAllModFile($event: any): Promise<void> {
  const from = $event?.from
  const to = toNextDay($event?.to)
  const taskOrderIds = modsRows.value.map((e) => e.taskOrderId)
  const manyParams = taskOrderIds.map((taskOrderId) => Object({ taskOrderId, from, to }))

  try {
    await api.downloadManyActivityLogs(manyParams)
  } catch {
    addAlert(ActicityLogErrorCodes.DOWNLOAD_ERROR)
  }
  showAllModDownload.value = false
}

async function downloadKPIModFile($event: any): Promise<void> {
  const taskOrderId =
    selectedTaskOrderObj.value?.originalTaskOrderId ?? selectedTaskOrderObj.value?.taskOrderId
  const from = $event.from
  const to = toNextDay($event.to)
  const fromDate = format(parse(from, 'YYYY/MM/DD'), 'YYYY-MM-DD HH:mm:ss.000')
  const toDate = format(parse(to, 'YYYY/MM/DD'), 'YYYY-MM-DD HH:mm:ss.000')
  const params = {
    fromDate: fromDate,
    toDate: toDate,
    taskOrderId
  }
  try {
    await api.downloadKPIActivityLog(params)
  } catch {
    addAlert(KPILogErrorCodes.DOWNLOAD_ERROR)
  }
  showKPIModDownload.value = false
}

function removeAlert(code: string) {
  errors.value = errors.value.filter((e: any) => e !== code)
}

function addAlert(code: string) {
  errors.value.push(code)
  const t = setTimeout(() => {
    removeAlert(code)
  }, 5000)
}

async function fetchTaskOrderActivities({ taskOrderId, ...params }: any): Promise<void> {
  const taskOrderIdParam = taskOrderId ?? selectedTaskOrderObj.value?.taskOrderId
  if (!taskOrderIdParam || String(taskOrderIdParam) === 'undefined') {
    return
  }
  const response: any = await api.getTaskOrderActivities(taskOrderIdParam, params)
  activityLogResponse.value = {
    data: response?.activityLogList,
    totalRecords: response?.totalRecords
  }
  return response
}

async function getKPIActivityLog(data: any): Promise<void> {
  const taskOrderIdParam =
    selectedTaskOrderObj.value?.originalTaskOrderId ?? selectedTaskOrderObj.value?.taskOrderId
  if (!taskOrderIdParam || String(taskOrderIdParam) === 'undefined') {
    return
  }
  data.taskOrderId = taskOrderIdParam
  const response: any = await api.getKPIActivityLog(data)
  kpiActivityLogResponse.value = { data: response }
}
watch(
  () => activityLogResponse.value,
  (newResponse: any) => {
    pagination.value.totalResults = newResponse.totalRecords // Ensure this is updating correctly
  }
)
watch(
  () => kpiActivityLogResponse.value,
  (newResponse: any) => {
    paginationKPI.value.totalResults = newResponse.data.totalRecords // Ensure this is updating correctly
  }
)
const refreshData = async () => {
  if (props.panel !== 'activitylog') {
    return
  }
  await fetchTaskOrderActivities(serverParams.value)
}
const refreshKpisData = async () => {
  if (props.panel !== 'activitylogKpis') {
    return
  }
  await getKPIActivityLog(serverParamsKPI.value)
}
watch(
  () => [
    selectedTaskOrderObj.value?.taskOrderId,
    pagination.value.currentPage,
    pagination.value.rowsPerPage
  ],
  refreshData,
  {
    deep: true
  }
)
watch(
  () => [
    selectedTaskOrderObj.value?.taskOrderId,
    paginationKPI.value.currentPage,
    paginationKPI.value.rowsPerPage
  ],
  refreshKpisData,
  {
    deep: true
  }
)
watch(
  () => selectedTaskOrderObj.value?.taskOrderId,
  () => {
    pagination.value.currentPage = 1
    paginationKPI.value.currentPage = 1
  }
)
</script>

<template>
  <chi-alert
    v-for="error in errors"
    :key="error"
    color="danger"
    icon="circle-x"
    type="banner"
    class="-mb--2"
    closable
    @dismissAlert="() => removeAlert(error)"
  >
    {{ errorMessages[error] }}
  </chi-alert>
  <div v-if="panel === 'activitylogKpis'">
    <div class="-d--flex -mb--2 -justify-content--end" style="gap: 16px">
      <button class="chi-button -outline -xs" @click="showKPIModDownload = true">
        Download Activity Log
      </button>
    </div>
    <DataTable
      title="KPI Activity Log"
      @onPageChange="onKPIPageChange"
      @onPageSize="onKPIPageSizeChange"
      @onSortedBy="onKPISortChange"
      :columns="kpiColumns"
      :rows="kpiActivityLogResponse.data.kpiActivityLogList"
      :settings="kpisSettings"
      :pagination="paginationKPI"
    >
      <template #expanded="{ data }">
        <DataTableExpanded :data="data" />
      </template>
    </DataTable>
    <TOActivityLogDownloadModal
      title="Select Date Range"
      :key="'kpi_mods'"
      v-if="showKPIModDownload"
      @download="downloadKPIModFile"
      @close="() => (showKPIModDownload = false)"
    />
  </div>
  <div v-else>
    <TOActivityLogDownloadModal
      title="Select Date Range"
      :key="'selected_mod'"
      v-if="showSelectedModDownload"
      @download="downloadSelectedModFile"
      @close="() => (showSelectedModDownload = false)"
    />
    <TOActivityLogDownloadModal
      title="Select Date Range"
      :key="'all_mods'"
      v-if="showAllModDownload"
      @download="downloadAllModFile"
      @close="() => (showAllModDownload = false)"
    />
    <div class="-d--flex -mb--2 -mt--0 -justify-content--end">
      <button class="chi-button -outline -xs -mr--2" @click="showSelectedModDownload = true">
        Download Activity Log for Selected MOD
      </button>
      <button class="chi-button -outline -xs" @click="showAllModDownload = true">
        Download Activity Log for all MODs
      </button>
    </div>
    <DataTable
      title="Task Order Activity Log"
      @onPageChange="onPageChange"
      @onPageSize="onPageSizeChange"
      @onSortedBy="onSortChange"
      :columns="columns"
      :rows="activityLogResponse.data"
      :settings="settings"
      :pagination="pagination"
    >
      <template #expanded="{ data }">
        <DataTableExpanded :data="data" />
      </template>
    </DataTable>
  </div>
</template>
