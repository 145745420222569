import { computed } from 'vue'
import { toNotBeEmpty, toBeAnEmail } from '@centurylinkfederal/eis-vue'
import { defineStore } from 'pinia'

export const useColumns = defineStore('contactsColumnsStore', () => {
  const columns = computed(() => [
    {
      field: 'name',
      label: 'Contact Name',
      input: {
        type: 'text',
        required: true,
        maxLength: 200,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'billingEmail',
      label: 'E-mail',
      input: {
        type: 'text',
        required: true,
        maxLength: 200,
        tests: [toNotBeEmpty, toBeAnEmail]
      }
    },
    {
      field: '_deleted'
    }
  ])

  return {
    columns
  }
})
