import { computed } from 'vue'
import { toNotBeEmpty, toMatchWith } from '@centurylinkfederal/eis-vue'
import { Action, dispatch } from '../CreateUser/actions'
import { storeToRefs } from 'pinia'
import { useStore } from './store'

export function useColumnsStore(): any {
  const formId = -1
  const { userDetails, form } = storeToRefs(useStore())
  const currentPassword = computed(() => [
    {
      field: 'oldPassword',
      label: 'Current Password',
      input: {
        type: 'password',
        maxLength: 64,
        required: true,
        tests: [toNotBeEmpty]
      }
    }
  ])
  const newPassword = computed(() => [
    {
      field: 'newPassword',
      label: 'New Password',
      input: {
        type: 'password',
        maxLength: 64,
        required: true,
        displayRules: true,
        delayTime: 500,
        asyncTest: (password: string) =>
          dispatch(Action.PREVIOUS_PASSWORD, {
            value: password,
            userId: userDetails.value.eisUserId
          }),
        tests: [
          toNotBeEmpty,
          (input: any) => toMatchWith(input, form.value[formId]?.['confirmPassword'])
        ]
      }
    }
  ])

  const confirmPassword = computed(() => [
    {
      field: 'confirmPassword',
      label: 'Confirm New Password',
      input: {
        type: 'password',
        maxLength: 64,
        required: true,
        tests: [
          toNotBeEmpty,
          (input: any) => toMatchWith(input, form.value[formId]?.['newPassword'])
        ]
      }
    }
  ])

  return {
    currentPassword,
    newPassword,
    confirmPassword
  }
}
