import { defineStore } from 'pinia'
import { ref, computed, watchEffect, Ref } from 'vue'
import { useToAdminAPI } from '../composables/to-admin/api'
import moment from 'moment'

export const useTOAdminStore = defineStore('toadmin/base', () => {
  const agenciesResponse = ref<any[]>([])
  const standardIntervalResponse = ref<any[]>([])
  const taskOrdersResponse = ref<any[]>([])
  const jurisdictionCountries = ref<any[]>([])
  const getAllServicesSvcLookUp = ref<any[]>([])
  const agencyTOMap = ref<Record<string, any[]>>({})
  const agencyTaskOrderIdOfficials = ref<Record<string, any[] | null>>({})
  const agencyUpdateToOfficials = ref<Record<string, any[]>>({})
  const api = useToAdminAPI()
  const isTODetailsFormValid = ref<boolean>(false)
  const createTaskOrdersRes = ref<Record<string, any[]>>({})
  const isTaskOrderSvcUpdated = ref<boolean>(false)
  const getTaskOrderKPIStatus = ref<string | null>(null)

  // Edit State: Start
  const selectedTO = ref<any>()
  const toModIterations = ref<any[]>([])
  const toFinancials = ref<any>([])
  const refreshTrigger$ = ref<string>('')
  const refreshTriggerToDetails$ = ref<string>('')
  const selectedToServicesDTO: Ref<any[]> = ref([])
  const allServices: Ref<any[]> = ref([])
  const services: Ref<any[]> = ref([])
  const activityLogResponse = ref<{ data: any[]; totalRecords: number }>({
    data: [],
    totalRecords: 0
  })
  const kpiActivityLogResponse = ref<{ data: any[] }>({ data: [] })
  const deleteKpiFromModal = ref<any>()
  const billingUpdateListForCreate = ref<any[]>([])

  const exportTaskOrderNSCData: Ref<any[]> = ref([])
  const exportTaskOrderJuridictionData: Ref<any[]> = ref([])
  const kpiExportData: Ref<any[]> = ref([])
  const performanceStorage = ref<any[]>([])
  const outageStorage = ref<any[]>([])
  const provisioningStorage = ref<any[]>([])

  // Edit State: End

  // Dropdowns: Start
  const agencies = computed(() => {
    return [
      {
        label: '- Select -',
        value: ''
      },
      ...agenciesResponse.value.map((item) => ({ label: item.name, value: item.agencyId }))
    ]
  })

  const taskOrderDropdownOptions = computed(() => {
    return taskOrdersResponse.value.map((item) => ({
      ...item,
      title: item.taskOrderNumber,
      id: item.taskOrderNumber
    }))
  })

  const taskOrderNumbers = ref<any[]>([
    {
      label: 'Select',
      value: ''
    }
  ])

  const toProgramNames = ref<any[]>([
    {
      label: 'Select',
      value: ''
    }
  ])
  // Dropdowns: End

  watchEffect(async () => {
    if (refreshTrigger$.value) {
      await fetchModIterationsForTO(selectedTO.value.toNumber)
    }
    if (refreshTriggerToDetails$.value) {
      await fetchTaskOrderToEdit(
        selectedTO.value.agencyIdentifier,
        selectedTO.value.taskOrderDto.taskOrderId
      )
      refreshTriggerToDetails$.value = ''
    }
  })

  async function fetchAgencies() {
    const response = (await api.fetchAgencies()) as any
    agenciesResponse.value = response.results
  }

  async function fetchStandardInterval() {
    const response = (await api.fetchStandardInterval()) as any
    standardIntervalResponse.value = response.results
  }

  async function fetchTaskOrders() {
    const response = (await api.fetchTaskOrders()) as any
    taskOrdersResponse.value = response.results
  }

  async function fetchTaskOrdersForAgency(agencyId: string) {
    if (agencyId) {
      const _taskOrders = agencyTOMap.value[agencyId]
      const response = (await api.fetchTaskOrdersByAgencyId(agencyId)) as any
      agencyTOMap.value = {
        ...agencyTOMap.value,
        [agencyId]: response.results.map((item: any) => ({
          value: item.taskOrderId,
          label: item.taskOrderNumber,
          ...item
        }))
      }
    }
    return agencyTOMap.value[agencyId]
  }

  async function fetchModIterationsForTO(val: string) {
    const response = (await api.getTOIterations(val)) as any
    toModIterations.value = response.results.map((item: any) => ({
      ...item,
      createdOn: (item.createdOn && moment(item.createdOn).format('MM/DD/YYYY')) || '',
      iterationStartDate:
        (item.iterationStartDate && moment(item.iterationStartDate).format('MM/DD/YYYY')) || '',
      modApprovedDate:
        (item.modApprovedDate && moment(item.modApprovedDate).format('MM/DD/YYYY')) || '',
      iterationEndDate:
        (item.iterationEndDate && moment(item.iterationEndDate).format('MM/DD/YYYY')) || '',
      generatedId: item.iterationNumber
    }))
  }

  function notifyRefresh() {
    refreshTrigger$.value = crypto.randomUUID()
  }

  function notifyRefreshToDetails() {
    refreshTriggerToDetails$.value = crypto.randomUUID()
  }

  async function fetchTaskOrdersByIdForToOfficials(taskOrderId: string) {
    const taskOrders = agencyTaskOrderIdOfficials.value[taskOrderId]
    if (!taskOrders) {
      const response = (await api.fetchToOfficialsByTaskOrderId(taskOrderId)) as any
      agencyTaskOrderIdOfficials.value = {
        ...agencyTaskOrderIdOfficials.value,
        [taskOrderId]: response.results.map((item: any) => ({
          value: item.taskOrderOfficialId,
          label: item.taskOrderOfficialId,
          firstName: item.firstName,
          lastName: item.lastName,
          workEmail: item.workEmail,
          workOfficePhone: item.workOfficePhone,
          workMobilePhone: item.workMobilePhone,
          role: item.role,
          startDate: item.startDate,
          endDate: item.stopDate,
          taskOrderOfficialId: item.taskOrderOfficialId,
          taskOrder: item.taskOrder
        }))
      }
    }
    return agencyTaskOrderIdOfficials.value[taskOrderId]
  }

  async function updateTaskOrdersByIdForToOfficials(updateTaskOrders: any) {
    if (updateTaskOrders) {
      const resp = (await api.updateTaskOrdersByIdForToOfficialsAPI(updateTaskOrders)) as any
      agencyUpdateToOfficials.value = resp
    }
    return agencyUpdateToOfficials.value
  }

  async function fetchTaskOrderToEdit(agencyIdentifier: string, taskOrderNumber: string) {
    const taskOrderDetails = await api.fetchTODetailsByAgencyAndTONumber(
      btoa(agencyIdentifier),
      taskOrderNumber
    )
    selectedTO.value = taskOrderDetails
  }

  async function editMod(data: any) {
    await api.editMod(data)
    notifyRefresh()
  } // Add closing parenthesis here

  async function getJurisdictions() {
    const resp = (await api.getJurisdictions()) as any
    return resp
  }

  async function getAllServices() {
    const resp = (await api.getAllServices()) as any
    services.value = resp.results
    return resp
  }

  async function updateTaskOrderDetails(data: any) {
    await api.updateTaskOrderDetails(data)
    notifyRefreshToDetails()
  }

  async function fetchTaskOrderFinancials(taskOrderId: string) {
    const response: any = await api.fetchTaskOrderFinancial(taskOrderId)
    toFinancials.value = response.results[0]
  }

  async function updateTaskOrderFinancials(data: any[]) {
    await api.updateTaskOrderFinancials(data)
    await fetchTaskOrderFinancials(selectedTO.value.taskOrderDto.taskOrderId)
  }

  async function getTaskOrderSvcDetailsDTO(taskOrderId: string) {
    const response: any = await api.getTaskOrderSvcDetailsDTO(taskOrderId)
    selectedToServicesDTO.value = response.results
  }

  async function getServicesByTONumber(taskOrderId: string) {
    const response: any = await api.getServicesByTONumber(taskOrderId)
    allServices.value = response.results
  }

  async function createTaskOrders(taskOrders: any) {
    if (taskOrders) {
      const resp = (await api.createTaskOrders(taskOrders)) as any
      createTaskOrdersRes.value = resp
    }
    return createTaskOrdersRes.value
  }
  async function fetchTaskOrderActivities({ taskOrderId, ...params }: any): Promise<void> {
    const response: any = await api.getTaskOrderActivities(
      taskOrderId || selectedTO.value.taskOrderDto.taskOrderId,
      params
    )
    activityLogResponse.value = {
      data: response.activityLogList,
      totalRecords: response.totalRecords
    }
    return response
  }

  async function getKPIActivityLog(data: any): Promise<void> {
    data.taskOrderId = selectedTO.value.taskOrderDto.taskOrderId
    const response: any = await api.getKPIActivityLog(data)
    kpiActivityLogResponse.value = { data: response }
  }

  async function saveTaskOrderSvcDetailsDTO(taskOrderSvcRequest: any): Promise<void> {
    try {
      ;(await api.saveTaskOrderSvcDetailsDTO(taskOrderSvcRequest)) as any
      isTaskOrderSvcUpdated.value = true
    } catch (e) {
      isTaskOrderSvcUpdated.value = false
    }
  }
  async function getRefKpis() {
    try {
      const resp = (await api.getRefKpis()) as any
      if (resp) {
        getTaskOrderKPIStatus.value = 'Active'
        return resp
      }
    } catch (error) {
      getTaskOrderKPIStatus.value = 'Error'
      throw Error('Error fetching KPIs:' + error)
    }
  }
  async function createServiceKpis(data: any) {
    const response = await api.createServiceKpis(data)
    return response
  }

  async function getAllKPIs(taskOrderNumner: any) {
    const response: any = await api.getAllKPIs(taskOrderNumner)
    kpiExportData.value = response?.results
    return response
  }
  async function getExportUpdatedDates(taskOrderId: any) {
    const response = await api.getExportUpdatedDates(taskOrderId)
    return response
  }

  async function getTaskOrderNSCsByTaskOrderID(taskOrderId: any) {
    const response: any = await api.getTaskOrderNSCsByTaskOrderID(taskOrderId)
    exportTaskOrderNSCData.value = taskOrderNSCs(response.results)
  }
  async function getTaskOrderJurisdictionsByTaskOrderID(taskOrderId: any) {
    const response: any = await api.getTaskOrderJurisdictionsByTaskOrderID(taskOrderId)
    exportTaskOrderJuridictionData.value = taskOrderJuridiction(response.results)
  }

  function taskOrderNSCs(taskOrderNSCResponse: any): any {
    if (taskOrderNSCResponse.length) {
      let results: any[] = []
      Object.keys(taskOrderNSCResponse[0] || {}).forEach((taskOrderNSC) => {
        const names = taskOrderNSC.replace('{', '').replace('}', '').split('=')
        results.push({
          acronym: names[0].trim(),
          label: names[1].trim(),
          locations: taskOrderNSCResponse[0][taskOrderNSC]
        })
      })
      results = results.sort((r1, r2): any => (r1.acronym > r2.acronym ? 1 : -1))
      return results
    }
    return taskOrderNSCResponse
  }

  function taskOrderJuridiction(taskorderJuridictionRes: Array<any>): any {
    if (taskorderJuridictionRes.length > 0) {
      let results: Array<any> = []
      Object.keys(taskorderJuridictionRes[0]).forEach((tj) => {
        const names = tj.replace('{', '').replace('}', '').split('=')
        results.push({
          acronym: names[0].trim(),
          label: names[1].trim(),
          jurisdictions: taskorderJuridictionRes[0][tj].map((j: any) => ({
            ...j,
            checked: true,
            original: true
          }))
        })
      })
      results = results.sort((r1, r2): any => (r1.acronym > r2.acronym ? 1 : -1))
      return results
    }
    return taskorderJuridictionRes
  }

  return {
    agencies,
    agenciesResponse,
    taskOrderDropdownOptions,
    agencyTOMap,
    taskOrderNumbers,
    toProgramNames,
    refreshTrigger$,
    refreshTriggerToDetails$,
    toModIterations,
    jurisdictionCountries,
    getAllServicesSvcLookUp,
    createTaskOrdersRes,
    fetchTaskOrdersForAgency,
    fetchAgencies,
    fetchTaskOrders,
    fetchModIterationsForTO,
    notifyRefresh,
    notifyRefreshToDetails,
    agencyTaskOrderIdOfficials,
    agencyUpdateToOfficials,
    isTODetailsFormValid,
    fetchTaskOrdersByIdForToOfficials,
    updateTaskOrdersByIdForToOfficials,
    selectedTO,
    fetchTaskOrderToEdit,
    editMod,
    getJurisdictions,
    getAllServices,
    updateTaskOrderDetails,
    fetchTaskOrderFinancials,
    toFinancials,
    updateTaskOrderFinancials,
    getTaskOrderSvcDetailsDTO,
    getServicesByTONumber,
    selectedToServicesDTO,
    allServices,
    services,
    createTaskOrders,
    fetchTaskOrderActivities,
    activityLogResponse,
    kpiActivityLogResponse,
    saveTaskOrderSvcDetailsDTO,
    isTaskOrderSvcUpdated,
    getRefKpis,
    getTaskOrderKPIStatus,
    createServiceKpis,
    getAllKPIs,
    getKPIActivityLog,
    getExportUpdatedDates,
    getTaskOrderNSCsByTaskOrderID,
    exportTaskOrderNSCData,
    getTaskOrderJurisdictionsByTaskOrderID,
    exportTaskOrderJuridictionData,
    kpiExportData,
    deleteKpiFromModal,
    fetchStandardInterval,
    standardIntervalResponse,
    billingUpdateListForCreate,
    performanceStorage,
    outageStorage,
    provisioningStorage
  }
})
