<script lang="ts" setup>
import { ref, defineProps, watchEffect, watch } from 'vue'
import moment from 'moment'
import { storeToRefs } from 'pinia'
import { DatePicker } from '@centurylinkfederal/eis-vue'
import Accordion from '../common/Accordion.vue'
import { useTOAdminStore } from '../../store/to-admin'
import ImportFile from '../ImportFile.vue'
import { createFinancialFields, createFinancialHeaders } from '../../utils/to-admin.utils'

interface IProps {
  action: string
}

const props = defineProps<IProps>()

const toAdminStore = useTOAdminStore()
const { toFinancials } = storeToRefs(toAdminStore)
const financialsToEdit = ref<any[]>([])
const editPressed = ref<boolean>(false)
const showImportDialog = ref<boolean>(false)
const hasError = ref<boolean>(false)
const errorMessage = ref<string>('')

const fields: any[] = [
  { id: 'taskOrderCeiling', label: 'TO Ceiling Amount', required: true },
  { id: 'taskOrderAgfRate', label: 'TO AGF Rate (%)', required: true },
  { id: 'performancePeriodFunding', label: 'Performance Period Funding', required: true },
  {
    id: 'prorationType',
    label: 'Proration Type',
    component: 'select',
    options: [
      { value: '', label: '- Select -' },
      { value: 'Month-Length Proration', label: 'Month-Length Proration' },
      { value: 'Normalized 30-Day Month Proration', label: 'Normalized 30-Day Month Proration' }
    ],
    readOnlyFn: (financial: any) => props.action === 'edit' && !financial.new
  },
  {
    id: 'performanceBeginDate',
    label: 'Performance Begin Date',
    component: 'date',
    required: true
  },
  { id: 'performanceEndDate', label: 'Performance End Date', component: 'date', required: true }
]

const importFields = createFinancialFields
const importHeaders = createFinancialHeaders

function parseFinancial(financial: any) {
  if (financial.performanceBeginDate) {
    financial.performanceBeginDate = moment(financial.performanceBeginDate, 'YYYY-MM-DD').format(
      'MM/DD/YYYY'
    )
  }
  if (financial.performanceEndDate) {
    financial.performanceEndDate = moment(financial.performanceEndDate, 'YYYY-MM-DD').format(
      'MM/DD/YYYY'
    )
  }
  return { ...financial, errors: {} }
}

watchEffect(() => {
  const financials = JSON.parse(JSON.stringify(toFinancials.value))
  financialsToEdit.value = financials.map((financial: any) => parseFinancial(financial))
})

function resetFinancials() {
  const financials = JSON.parse(JSON.stringify(toFinancials.value))
  financialsToEdit.value = financials.map((financial: any) => parseFinancial(financial))
  editPressed.value = false
  hasError.value = false
}

function onEdit() {
  editPressed.value = true
}

async function onSave() {
  const hasErrors = financialsToEdit.value.some((financial: any) => {
    validateFinancial(financial)
    return hasFinancialErrors(financial)
  })
  const anyRequiredFieldErrors = financialsToEdit.value.some((financial) =>
    Object.values(financial.errors).some((err) => err === true)
  )
  const allItemsRemoved =
    financialsToEdit.value.filter((val: any) => val.deleted)?.length ===
    financialsToEdit.value?.length
  hasError.value = allItemsRemoved || hasErrors
  errorMessage.value = allItemsRemoved
    ? 'Atleast one financial record is required'
    : hasErrors
      ? anyRequiredFieldErrors
        ? 'Please fill in required fields'
        : 'Fix validation errors within financial items'
      : ''
  if (hasErrors || allItemsRemoved) {
    return
  }
  const dataToSave = financialsToEdit.value.map((financial) => ({
    ...financial,
    performanceBeginDate: moment(financial.performanceBeginDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
    performanceEndDate: moment(financial.performanceEndDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
  }))
  await toAdminStore.updateTaskOrderFinancials(dataToSave)
  editPressed.value = false
}

function addNew() {
  const financial = {
    id: `new-${crypto.randomUUID()}`,
    new: true,
    ...fields.reduce((result, item) => {
      result[item.id] = ''
      return result
    }, {}),
    errors: {},
    taskOrderAgfRate: 4.75
  }

  financialsToEdit.value.push(financial)
}

function validateFinancial(financial: any) {
  let errors: any = {}
  errors.taskOrderAgfRate = !!!financial.taskOrderAgfRate
  errors.taskOrderCeiling = !!!financial.taskOrderCeiling
  errors.performancePeriodFunding = !!!financial.performancePeriodFunding
  errors.prorationType = !!!financial.prorationType
  const ppf = financial.performancePeriodFunding
    ? parseFloat(financial.performancePeriodFunding.replaceAll(',', ''))
    : 0
  const toc = financial.taskOrderCeiling
    ? parseFloat(financial.taskOrderCeiling.replaceAll(',', ''))
    : 0
  const bDate = financial.performanceBeginDate
  const eDate = financial.performanceEndDate
  let flag = false
  if (ppf > toc) {
    flag = true
    errors['performancePeriodFunding'] = 'Performance Funding cannot exceed TO Ceiling Amount'
  }

  const dateErrors = validate_dates(financial)
  errors = {
    ...errors,
    ...dateErrors
  }
  financial.errors = errors
  return errors
}

function validate_dates(financial: any) {
  let bDateError: String | Boolean = !!!financial.performanceBeginDate
  let eDateError: String | Boolean = !!!financial.performanceEndDate
  const bDate = financial.performanceBeginDate
  const eDate = financial.performanceEndDate
  let errorMsg = ''
  const isValidDate = (value: string) => moment(value, 'MM/DD/YYYY', true).isValid()
  if (bDate && !isValidDate(bDate)) {
    bDateError = 'Invalid Date'
  }
  if (eDate && !isValidDate(eDate)) {
    eDateError = 'Invalid Date'
  }
  if (
    bDate &&
    eDate &&
    isValidDate(bDate) &&
    isValidDate(eDate) &&
    moment(bDate, 'MM/DD/YYYY', true).isSameOrAfter(moment(eDate, 'MM/DD/YYYY', true))
  ) {
    errorMsg = 'End Date must be greater than Start Date'
    eDateError = errorMsg
  }
  return {
    performanceBeginDate: bDateError,
    performanceEndDate: eDateError
  }
}

function hasFinancialErrors(financial: any) {
  return Object.values(financial.errors).some((error) => !!error)
}

function removeFinancial(item: any) {
  if (!item.new) item.deleted = true
  if (item.deleted) {
    return
  }
  financialsToEdit.value = financialsToEdit.value.filter(
    (record: any) => record.taskOrderFinancialId !== item.taskOrderFinancialId
  )
}

function importedFileInfo(details: any = {}) {
  const { isValid, contents = [] } = details
  const financials = contents.map((content: any) => {
    const financial = {
      ...parseFinancial(content),
      new: true,
      taskOrderCeiling: content.taskOrderCeiling
        ? commaSeperatedVal(+content.taskOrderCeiling)
        : '',
      performancePeriodFunding: content.performancePeriodFunding
        ? commaSeperatedVal(+content.performancePeriodFunding)
        : ''
    }
    return {
      ...financial,
      errors: validateFinancial(financial)
    }
  })
  financialsToEdit.value = financialsToEdit.value.concat(financials)
}

function commaSeperatedVal(value: number): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function onAmountChange(e: any, item: any, fieldName: string): void {
  if (fieldName === 'taskOrderAgfRate' || !e.target.value) return
  let value = e.target.value.replace(/[^\d]+/g, '')
  value = commaSeperatedVal(Number(value))
  item[fieldName] = value
}
</script>
<template>
  <Accordion title="TO Financials">
    <div>
      <ImportFile
        v-if="showImportDialog"
        :headerDetails="importHeaders"
        :dataFields="importFields"
        title="financial"
        @closeModal="showImportDialog = false"
        @importContent="importedFileInfo($event)"
      />

      <div class="chi-alert -danger -close -mb--4" role="alert" v-if="hasError">
        <i class="chi-alert__icon chi-icon icon-warning" aria-hidden="true"></i>
        <div class="chi-alert__content">
          <p class="chi-alert__text">{{ errorMessage }}</p>
        </div>
        <button
          class="chi-alert__close-button chi-button -icon -close"
          aria-label="Close"
          @click="hasError = false"
        >
          <div class="chi-button__content">
            <i class="chi-icon icon-x" aria-hidden="true"></i>
          </div>
        </button>
      </div>
      <div v-for="item in financialsToEdit" :key="item.taskOrderFinancialId">
        <div class="-d--flex -align-items--center">
          <div style="width: 30px" v-if="editPressed" class="-px--1"></div>
          <div class="-flex--fill">
            <div class="chi-grid" style="font-size: 0.875rem">
              <div
                style="flex: 1 1 auto"
                v-for="field in fields"
                :key="field.id"
                class="chi-col -w--2 -mb--2"
              >
                <div class="m-form__item">
                  <chi-label :required="field.required && editPressed">{{ field.label }}</chi-label>
                  <div v-if="props.action === 'edit' && (!editPressed || item.deleted)">
                    <span :style="{ 'text-decoration': item.deleted ? 'line-through' : '' }">{{
                      item[field.id]
                    }}</span>
                  </div>
                  <template v-else>
                    <select
                      class="chi-select"
                      :class="item.errors[field.id] ? '-dander' : ''"
                      v-if="field.component === 'select'"
                      v-model="item[field.id]"
                    >
                      <option v-for="opt in field.options" :key="opt.value" :value="opt.value">
                        {{ opt.label }}
                      </option>
                    </select>
                    <DatePicker
                      label=""
                      v-else-if="field.component === 'date'"
                      :value="item[field.id]"
                      :id="`date-${item.taskOrderFinancialId || item.id}-${field.id}`"
                      format="MM/DD/YYYY"
                      :required="field.required"
                      @change="($event) => (item[field.id] = $event)"
                      :helperMessage="''"
                      :state="item.errors[field.id] ? 'danger' : ''"
                    />
                    <input
                      v-else
                      class="chi-input"
                      :class="item.errors[field.id] ? '-danger' : ''"
                      type="text"
                      v-model="item[field.id]"
                      @input="onAmountChange($event, item, field.id)"
                    />
                  </template>
                  <div
                    class="chi-label -status -danger"
                    v-if="item.errors[field.id] && item.errors[field.id] !== true"
                  >
                    <!-- <i class="chi-icon icon-circle-warning" aria-hidden="true"></i> -->
                    {{ item.errors[field.id] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 30px" v-if="editPressed && !item.deleted" class="-px--1">
            <i class="chi-icon icon-delete -icon--primary" @click="removeFinancial(item)" />
          </div>
        </div>
      </div>
      <div
        style="display: flex; flex-direction: row; justify-content: center; gap: 16px"
        class="-mt--2"
        v-if="props.action === 'edit'"
      >
        <button class="chi-button" @click="onEdit" v-if="!editPressed">Edit</button>
        <template v-else>
          <button class="chi-button" @click="onSave">Save</button>
          <button class="chi-button" @click="addNew">Add New</button>
          <button class="chi-button" @click="resetFinancials">Cancel</button>
          <button class="chi-button" @click="() => (showImportDialog = true)">Import</button>
        </template>
      </div>
    </div>
  </Accordion>
</template>
