import { AlertType } from '@centurylinkfederal/eis-vue'

export const bubbleAlert: AlertType = { type: 'bubble', size: 'md', closable: true }
export const baseAlert: AlertType = { ...bubbleAlert, icon: 'flag' }
export const successAlert: AlertType = { ...bubbleAlert, color: 'success', icon: 'circle-check' }
export const dangerAlert: AlertType = { ...bubbleAlert, color: 'danger', icon: 'circle-x' }
export const warningAlert: AlertType = { ...bubbleAlert, color: 'warning', icon: 'warning' }
export const infoAlert: AlertType = { ...bubbleAlert, color: 'info', icon: 'circle-info' }
export const mutedAlert: AlertType = { ...bubbleAlert, color: 'muted', icon: 'circle-info' }
export const alertTable: any = {
  base: baseAlert,
  success: successAlert,
  danger: dangerAlert,
  warning: warningAlert,
  info: infoAlert,
  muted: mutedAlert
}
