import { ref } from 'vue'
import { parseErr, useApi } from '@centurylinkfederal/eis-vue'

export async function validateForExistingPassword({ value, userId }: any): Promise<boolean> {
  const previousPassMatch = ref<string>('')
  const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)

  async function checkIfPreviousPassword({ value, userId }: any): Promise<void> {
    try {
      await api.post(`/users/password`, {
        userId,
        userPassword: value
      })
      previousPassMatch.value = ''
    } catch (error: any) {
      if (error?.status === 400) {
        previousPassMatch.value = error.response.data
      }
    }
  }

  await checkIfPreviousPassword({ userId, value })

  return !!previousPassMatch.value
}

export function updateTOPermission({
  currentTo,
  selectedAgencyId,
  toPermissionList
}: {
  currentTo: Array<any>
  selectedAgencyId: number | string
  toPermissionList: Map<string | number, Array<any>>
}) {
  let updatedToDetails = currentTo
  let permissionMap = toPermissionList
  if (selectedAgencyId === 'ALL') {
    updatedToDetails = currentTo.map((to) => {
      const existingToList = toPermissionList.get(to.agencyId)
      if (existingToList?.length) {
        const toBeUpdatedTO = existingToList.find(
          (existingTo: any) => existingTo.taskOrderNumber === to.taskOrderNumber
        )

        if (toBeUpdatedTO) {
          to = { ...to, ...toBeUpdatedTO }
        }
      }
      return to
    })

    permissionMap = setPermissionDetails(updatedToDetails)
    permissionMap.set(selectedAgencyId, updatedToDetails)

    return {
      updatedToDetails,
      permissionMap
    }
  }

  const allTOExistInMap = toPermissionList.get('ALL')

  if (allTOExistInMap?.length) {
    permissionMap = setPermissionDetails(allTOExistInMap)
    permissionMap.set('ALL', allTOExistInMap)

    updatedToDetails = permissionMap.get(selectedAgencyId) as any[]
  }

  permissionMap.set(selectedAgencyId, updatedToDetails)

  return {
    updatedToDetails,
    permissionMap
  }
}

function setPermissionDetails(toPermission: Array<any>): Map<string | number, any[]> {
  return toPermission.reduce((all: Map<number | string, any[]>, toDetails) => {
    const detail = all.get(toDetails.agencyId) || []
    detail.push(toDetails)
    all.set(toDetails.agencyId, detail)

    return all
  }, new Map())
}

export async function getUserProfileDetails(): Promise<any> {
  try {
    const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)
    const userData = await api.get<any>('/users/user-profile')
    return {
      ...userData,
      mobilePhone: userData.mobilePhone
        ? `${userData.mobilePhone.includes('-') ? '' : '+1-'}${userData.mobilePhone}`
        : '',
      telephone: userData.telephone
        ? `${userData.telephone.includes('-') ? '' : '+1-'}${userData.telephone}`
        : '',
      stateCode: userData.stateCode
    }
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.error(parseErr(err))
  }
}

export async function getUserTOPermissions({
  pageNumber = 1,
  pageSize = 10
}: {
  pageNumber: number
  pageSize: number
}) {
  try {
    if (isNaN(pageNumber) || pageNumber <= 0) return {}
    const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)
    const response: any = await api.get(
      `/users/user-profile/agencies?pageNumber=${pageNumber}&pageSize=${pageSize}`
    )
    const userProfileAgencies: Array<any> = (response?.userProfileAgencies || [])?.map(
      (to: any) => ({
        ...to,
        agencyLongName: [to.agencyName, to.bureauName, to.subBureauName, to.agencyIdentifier]
          .filter((f) => f)
          .join('-')
      })
    )

    return { ...response, userProfileAgencies }
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.error(parseErr(err))
  }
}

export async function updateProfile(profileData: any) {
  const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)
  let isSuccess = false
  try {
    await api.put(`/users/user-profile`, profileData)
    isSuccess = true
  } catch (err: any) {
    isSuccess = false
    // eslint-disable-next-line no-console
    console.error(parseErr(err))
  }

  return isSuccess
}

export async function updateUserPassword(passwordDetails: any) {
  const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)
  let isSuccess = ''
  try {
    await api.put('/users/user-profile/password', passwordDetails)
  } catch (err: any) {
    isSuccess = 'Password update error'
    if (err?.status === 400) {
      isSuccess = err.response.data
    }
    // eslint-disable-next-line no-console
    console.error(parseErr(err))
  }
  return isSuccess
}
