<script lang="ts" setup>
import { ref, defineProps } from 'vue'

interface IProps {
  title: string
  defaultOpen?: boolean
}

const props = defineProps<IProps>()
const show = ref<boolean>(props.defaultOpen || false)

function toggleAccordion() {
  show.value = !show.value
}
</script>
<template>
  <div class="chi-accordion -md -mt--2">
    <div class="chi-accordion__item" :class="show ? '-expanded' : ''">
      <button class="chi-accordion__trigger" @click="toggleAccordion">
        <i class="chi-icon icon-chevron-down"></i>
        <div class="chi-accordion__title">{{ props.title }}</div>
      </button>
      <div class="chi-accordion__content" style="overflow: visible">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
