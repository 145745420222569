<script lang="ts" setup>
import { defineProps, defineEmits, ref, computed, watch } from 'vue'
import moment from 'moment'
import { Modal, DatePicker } from '@centurylinkfederal/eis-vue'

const props = defineProps<{
  title: string
}>()

const $emit = defineEmits<{
  (e: 'close'): void
  (e: 'download', payload: any): void
}>()

const startDate = ref<string>('')
const endDate = ref<string>()
const isEndDateValid = ref(true)

const actionButtons: any = computed(() => [
  { text: 'Cancel', dismissModal: true, variant: 'outline' },
  {
    text: 'Download',
    color: 'primary',
    disabled: !startDate.value || !endDate.value || !isEndDateValid.value
  }
])

watch([startDate, endDate], () => {
  const start = moment(startDate.value, 'MM/DD/YYYY')
  const end = moment(endDate.value, 'MM/DD/YYYY')
  isEndDateValid.value = !end.isBefore(start)
})

function actionButtonClicked($event: number): void {
  if ($event === 1) {
    $emit('download', {
      from: moment(startDate.value, 'MM/DD/YYYY').format('YYYY/MM/DD'),
      to: moment(endDate.value, 'MM/DD/YYYY').format('YYYY/MM/DD')
    })
  }
}

function closeModal(): void {
  $emit('close')
}

function onDateChange(field: string, $event: any): void {
  if (field === 'startDate') {
    startDate.value = $event
  }
  if (field === 'endDate') {
    endDate.value = $event
  }
}
</script>

<template>
  <Modal
    :center="true"
    :title="props.title"
    :buttons="actionButtons"
    @onButtonClick="actionButtonClicked"
    @onDismissModal="closeModal"
    class="custom-modal"
  >
    <div class="-d--flex -flex--column">
      <p>Select the "From Date" and "To Date" to proceed with the download.</p>
      <div class="-w--50 -mb--2">
        <DatePicker
          label="From Date"
          id="activity-modal-from-date"
          :value="startDate"
          @change="onDateChange('startDate', $event)"
          format="MM/DD/YYYY"
          class="custom-datepicker"
        />
      </div>
      <div class="-w--50">
        <DatePicker
          label="To Date"
          id="activity-modal-to-date"
          :value="endDate"
          @change="onDateChange('endDate', $event)"
          format="MM/DD/YYYY"
          class="custom-datepicker"
        />
      </div>
      <p v-if="!isEndDateValid" class="-text--danger">
        The "To Date" cannot be earlier than the "From Date".
      </p>
    </div>
  </Modal>
</template>

<style scoped>
.error {
  color: red;
}

/deep/ .chi-modal {
  overflow: visible !important;
}

.custom-datepicker .datepicker-dropdown {
  position: absolute !important;
  z-index: 1050 !important;
}

.custom-datepicker .datepicker-input {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
}
</style>
