import USState from '../constants/USState'

export const TOPermissionTypes = [
  'orderPermission',
  'billingPermission',
  'inventoryPermission',
  'troublePermission',
  'networkPermission'
]

export const TroublePrevlegies = [
  'troubleCreatePrivilegeValue',
  'troubleUpdatePrivilegeValue',
  'troubleEscalatePrivilegeValue',
  'troubleClosePrivilegeValue'
]

export function getTaskOrderDetails(
  taskOrderData: Array<any>,
  originalTos: Array<any>,
  isEditMode: boolean
) {
  return taskOrderData
    .filter((to) => isToModifiedOrALLNone(isEditMode, to, originalTos))
    .reduce((all, item) => {
      all[item.accountTaskOrderId] = {
        ...item,
        eowaPrivilegeViewOnly: item.orderPermission === 'SUBMIT' && !item.showEnhancedWO,
        eowaPrivilegeViewEdit:
          item.eowaPrivilegeViewEdit || (item.orderPermission === 'SUBMIT' && item.showEnhancedWO)
      }
      return all
    }, {})
}

function isToModifiedOrALLNone(isEditMode: boolean, to: any, _originalTos: Array<any>): boolean {
  if (!isEditMode) {
    return TOPermissionTypes.some((type) => to[type] !== 'NONE')
  }

  return to.modified
}

export function getUserDetails(
  formData: any,
  roleValue: any,
  activeUser: boolean,
  loggedInUserDetails: any,
  editMode: boolean,
  userDetails: any
) {
  const formValues: any = Object.keys(formData).reduce(
    (all, key) => ({
      ...all,
      [key]: formData[key].value
    }),
    {}
  )

  return {
    userInfo: {
      createdBy: loggedInUserDetails.userId,
      notes: formValues.notes,
      status: activeUser ? 'ACTIVE' : 'DISABLED',
      userName: formValues.userName,
      userPassword: formValues.password || '',
      apply2fa: 'N',
      userType: roleValue?.value,
      ...(editMode && { eisUserId: userDetails.eisUserId })
    },
    contact: {
      ...(editMode && { contactId: userDetails.contactId }),
      address1: formValues.address1,
      address2: formValues.address2,
      city: formValues.city,
      email: formValues.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      stateCode:
        formValues.stateCode && formValues.stateCode.length > 2
          ? USState.find((state) => state.name === formValues.stateCode)?.code
          : formValues.stateCode,
      telephone: formValues.telephone,
      zip: formValues.zip,
      mobilePhone: formValues.mobilePhone,
      workPhoneExtension: formValues.workPhoneExtension,
      preferredContactMethod: formValues.preferredContactMethod
    }
  }
}
