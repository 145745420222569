<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { ReadOnlyCell, EditCell, replaceAll, Bins, Row } from '@centurylinkfederal/eis-vue'

export interface Props {
  data?: Row
}
const props = withDefaults(defineProps<Props>(), {
  data: () => {
    return {}
  }
})

const selectedRows = ref<Bins>(props.data?.selectedRows || {})
watch(
  () => props.data?.selectedRows,
  (newValue) => {
    selectedRows.value = newValue
  },
  { deep: true }
)
const data = computed(() => {
  const patch = {
    lastLogEntry: replaceAll(String(props.data?.lastLogEntry), `\n`, `<br>`)
  }
  const data = { ...props.data, ...patch }
  return data as Row
})
const hiddenColumns = computed(() => {
  const cols = [...(props.data?.columns ?? [])]
    ?.filter((col: any) => col?.class?.indexOf('--show') > -1)
    .map((col: any) => {
      const classParts = col?.class.split(' ')
      const mutatedParts = classParts.map((e: string) => {
        if (e.indexOf('--show') === -1) {
          return e
        }
        return e.replace('--show', '--hide')
      })
      const input = col.input ? { ...col.input, size: 'md' } : undefined
      return { ...col, class: mutatedParts.join(' '), input }
    })
  return cols || []
})

const classColDefault = '-w-xl-2h--2 -w-lg--2 -w-md--4 -w--6'
</script>
<template>
  <div class="grid-wrapper">
    <div class="chi-grid -mx--2" style="padding-left: 0.25rem">
      <div
        :class="[
          cell.class,
          {
            ['-mb--3 chi-col ' + (cell.classCol || classColDefault)]:
              !cell.divider && !cell.groupTitle,
            'chi-col -w--12': cell.divider || cell.groupTitle
          }
        ]"
        v-for="(cell, index) in hiddenColumns"
        :key="index"
      >
        <div
          :class="['chi-divider', cell.class, { '-mb--2': !cell.dividerClass }]"
          v-if="cell.divider"
        />
        <div
          :class="[cell.class, { '-text--h4 -mt--0 -mb--3': !cell.groupTitleClass }]"
          v-if="cell.groupTitle"
          v-html="cell.groupTitle"
        />
        <ReadOnlyCell
          v-else-if="!cell.input"
          :cell="cell"
          :row="data"
          :keyValue="String(data?.[data.settings.key] ?? '')"
          :quirk="data.quirk"
          :onAction="data.onAction"
          :selected="selectedRows[String(cell?.bin)]?.includes(data?.[data.settings.key])"
          @onCheckbox="(isChecked: boolean) => data.toggleBin(cell.bin, data, isChecked)"
        />
        <EditCell
          v-else-if="cell.input"
          :cell="cell"
          :input="data.form?.[data?.[data.settings.key]]?.[String(cell.field)]"
          @onInput="
            (newValue: any) =>
              data.onInput(newValue, {
                key: data?.[data.settings.key],
                field: cell.field,
                opts: cell.input
              })
          "
        />
      </div>
    </div>
  </div>
</template>
