import { ref, computed, onMounted } from 'vue'
import { defineStore } from 'pinia'
import {
  toBeOptional,
  toNotBeEmpty,
  toBeAMonthDayYear,
  isInt,
  InputType,
  Validation,
  StateType,
  ErrorType
} from '@centurylinkfederal/eis-vue'
import { toMap } from '../../utils/helpers'
import { useToAdminAPI } from '../../composables/to-admin/api'
import { Entries, Pair } from '../../types/common'

const yesNoOptions = [
  ['Y', 'Yes'],
  ['N', 'No']
]
let adminApi: any
function toNotBeEmptySvcDeliveryDaysType(input: InputType): Validation {
  let state: StateType = undefined
  let error: ErrorType = undefined
  const value = input.value?.svcDeliveryDaysType
    ? String(input.value?.svcDeliveryDaysType).trim()
    : input.value?.svcDeliveryDaysType
  if (!value) {
    state = 'danger'
    error = {
      message: [`Service Delivery Type of Days may not be empty.`].filter((f) => f).join(' ')
    }
    return [state, error]
  }
  state = 'success'
  return [state, error]
}
function toBeAValidSvcDeliveryNumDays(input: InputType): Validation {
  let state: StateType = undefined
  let error: ErrorType = undefined
  let value = input.value?.svcDeliveryNumDays
    ? String(input.value?.svcDeliveryNumDays).trim()
    : input.value?.svcDeliveryNumDays
  if (!value) {
    state = 'danger'
    error = {
      message: [`Service Delivery # of Days is invalid.`].filter((f) => f).join(' ')
    }
    return [state, error]
  }
  if (!isInt(value)) {
    state = 'danger'
    error = {
      message: [`Service Delivery # of Days is invalid.`].filter((f) => f).join(' ')
    }
    return [state, error]
  }
  value = parseInt(value)
  if (value < 0) {
    state = 'danger'
    error = {
      message: [`Service Delivery # of Days must be greater than zero.`].filter((f) => f).join(' ')
    }
    return [state, error]
  }
  if (value > 90) {
    state = 'danger'
    error = {
      message: [`Service Delivery # of Days must be less than 90.`].filter((f) => f).join(' ')
    }
    return [state, error]
  }
  state = 'success'
  return [state, error]
}
export const useColumns = defineStore('detailsColumnsStore', () => {
  adminApi = useToAdminAPI()
  const agencyEntries = ref<Entries>([])
  const taskOrderEntries = ref<Entries>([])
  const taskOrderFirstOptionDefault = ['', '- Select -']
  const taskOrderFirstOption = ref(taskOrderFirstOptionDefault)
  const taskOrderMap = ref<Map<string, any>>(new Map())
  const svcDeliveryDefault = ref({ svcDeliveryNumDays: 3, svcDeliveryDaysType: 'calendar' })
  const agencyMap = computed(() => new Map(agencyEntries.value))
  const columnsEdit = computed(() =>
    columns.value.map((e: any) => {
      if (['modNumber'].includes(e.field)) {
        return {
          ...e,
          hidden: false
        }
      }
      if (['_flexableSpaceAfterProgramName'].includes(e.field)) {
        return {
          ...e,
          hidden: true
        }
      }
      return { ...e }
    })
  )
  const columns = computed(() => [
    {
      field: 'contractNumber',
      label: 'Contract Number',
      input: {
        type: 'text',
        value: 'GS00Q17NSD3006',
        readonly: true
      }
    },
    {
      field: 'agencyId',
      label: 'Agency',
      input: {
        type: 'select',
        options: [['', '- Select -'], ...agencyEntries.value],
        required: true,
        disabled: false,
        value: '',
        tests: [toNotBeEmpty]
      },
      classCol: 'w-xl-2h--3 -w-xl--3 -w-lg--3 -w-md--4 -w--12'
    },
    {
      field: 'taskOrderNumber',
      label: 'Task Order #',
      input: {
        type: 'select',
        options: [taskOrderFirstOption.value, ...taskOrderEntries.value],
        required: true,
        disabled: false,
        value: '',
        tests: [toNotBeEmpty]
      },
      classCol: 'w-xl-2h--2 -w-xl--2 -w-lg--2 -w-md--3 -w--12'
    },
    {
      field: 'toProgramName',
      label: 'Program Name',
      input: {
        type: 'text',
        required: true,
        maxLength: 250,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: '_flexableSpaceAfterProgramName',
      classCol: '-d--none -d-xl--inline-flex -w--2',
      hidden: false
    },
    {
      field: 'modNumber',
      label: 'Mod Number',
      hidden: true,
      input: {
        type: 'text',
        readonly: true
      }
    },
    {
      field: 'level3ban',
      label: 'Invoice Account #',
      input: {
        type: 'text',
        readonly: true
      }
    },
    {
      field: 'originalPramataId',
      label: 'CLM ID',
      input: {
        type: 'text',
        maxLength: 25,
        required: false,
        tests: [toBeOptional]
      }
    },
    {
      field: 'strStartDate',
      label: 'Start Date',
      input: {
        type: 'date',
        format: 'MM/DD/YYYY',
        required: true,
        tests: [toNotBeEmpty, toBeAMonthDayYear]
      }
    },
    {
      field: 'strEndDate',
      label: 'End Date',
      input: {
        type: 'date',
        format: 'MM/DD/YYYY',
        required: true,
        tests: [toNotBeEmpty, toBeAMonthDayYear]
      }
    },
    {
      field: 'fullyLoaded',
      label: 'Fully Loaded',
      input: {
        type: 'single',
        mode: 'button',
        options: yesNoOptions,
        value: 'N',
        tests: [toBeOptional]
      }
    },
    {
      field: 'invoiceOptions',
      label: 'Invoice Options',
      input: {
        type: 'multiple',
        mode: 'button',
        options: [
          ['ioCustom', 'Custom Tax, Fees & Surcharges'],
          ['ioDoi', 'DOI'],
          ['etems', 'ETEMS'],
          ['ioIppSummary', 'IPP Summary'],
          ['ioIppDetail', 'IPP Details']
        ],
        tests: [toBeOptional]
      },
      classCol: 'w-xl-2h--7 -w-xl--7 -w-lg--7 -w-md--12 -w--12 -text--wrap'
    },
    {
      field: 'billingNotifications',
      label: 'Billing Notifications',
      input: {
        type: 'single',
        mode: 'button',
        options: yesNoOptions,
        value: 'N',
        tests: [toBeOptional],
        warningMessage: `Selecting "No" will clear all existing SSCN Billing Notification Contacts and discontinue all SSCN Email Notifications.`
      }
    },
    {
      field: 'statusManaged',
      label: 'Managed/Unmanaged',
      input: {
        type: 'multiple',
        mode: 'button',
        options: [
          ['MANAGED', 'Managed'],
          ['UNMANAGED', 'Unmanaged']
        ],
        required: true,
        tests: [toNotBeEmpty]
      },
      classCol: 'w-xl-2h--2 -w-xl--2 -w-lg--4 -w-md--8  -text--wrap'
    },
    {
      field: 'enWorkAuto',
      label: 'Enh Work Order Automn',
      input: {
        type: 'single',
        mode: 'button',
        options: yesNoOptions,
        value: 'N',
        tests: [toBeOptional]
      }
    },
    {
      field: 'dashboardDisplay',
      label: 'Dashboard Display',
      input: {
        type: 'single',
        mode: 'button',
        options: yesNoOptions,
        value: 'N',
        tests: [toBeOptional]
      }
    },
    {
      field: 'enhancedEmailNotification',
      label: 'Enh Email Notifications',
      input: {
        type: 'single',
        mode: 'button',
        options: yesNoOptions,
        value: 'N',
        tests: [toBeOptional]
      }
    },
    {
      field: 'svcDelivery',
      label: 'Service Delivery Test and Acceptance Interval',
      required: false,
      input: {
        type: 'group',
        value: { svcDeliveryNumDays: 3, svcDeliveryDaysType: 'calendar' },
        required: false,
        maxLength: 2,
        tests: [toNotBeEmptySvcDeliveryDaysType, toBeAValidSvcDeliveryNumDays],
        formatFn: (newValue: any) => {
          if (typeof newValue?.svcDeliveryNumDays === 'string') {
            newValue.svcDeliveryNumDays = isInt(newValue.svcDeliveryNumDays)
              ? parseInt(newValue.svcDeliveryNumDays)
              : newValue.svcDeliveryNumDays
          }
          return newValue
        }
      },
      defaultValue: svcDeliveryDefault.value,
      group: [
        {
          field: 'svcDeliveryNumDays',
          input: {
            type: 'text',
            mode: 'text',
            placeholder: '# of days',
            value: 3,
            required: true,
            maxLength: 2,
            tests: [toNotBeEmpty]
          },
          classItem: '-w--25',
          settings: {
            noLabel: true
          }
        },
        {
          field: 'svcDeliveryDaysType',
          input: {
            type: 'single',
            mode: 'button',
            value: 'calendar',
            required: true,
            options: [
              ['calendar', 'Calendar'],
              ['business', 'Business']
            ],
            tests: [toNotBeEmpty]
          },
          classItem: '-ml--1',
          settings: {
            noLabel: true
          }
        }
      ],
      labelInfoClass: '-info -text--muted',
      labelInfoText: 'Contract Standard is 3 calendar days.',
      labelInfoIcon: 'circle-info',
      labelInfoIconClass: '-primary',
      classCol: '-w-xl-2h--4 -w-xl--4 -w-lg--6 -w-md--8 -w-sm--12',
      classSpacing: '-mb--0 -mb-sm-3'
    },
    {
      field: 'comments',
      label: 'Comments',
      input: {
        hidden: true,
        maxLength: 1000,
        type: 'textarea',
        tests: [toBeOptional]
      }
    }
  ])
  const columnsMap = computed(() => toMap(columns.value, 'field'))
  async function updateTaskOrderEntries(
    id: number | string | undefined,
    mode = 'create'
  ): Promise<void> {
    if (!id) {
      taskOrderMap.value = new Map()
    } else {
      const taskOrdersRes = await adminApi.fetchTaskOrdersByAgencyId(String(id))
      taskOrderMap.value = makeTaskOrderMap(taskOrdersRes, mode)
    }
    taskOrderEntries.value = Array.from(taskOrderMap.value.keys()).map((e) => [e, e])
    if (taskOrderEntries.value.length) {
      taskOrderFirstOption.value = taskOrderFirstOptionDefault
    }
  }
  onMounted(async () => {
    agencyEntries.value = makeAgencyOptions(await adminApi.fetchAgencies())
    const stdRes: any = await adminApi.fetchStandardInterval()
    const svcDeliveryDefaultValue = stdRes?.results?.[0]
    if (typeof svcDeliveryDefaultValue === 'object') {
      svcDeliveryDefault.value = svcDeliveryDefaultValue
    }
  })
  return {
    columns,
    columnsMap,
    columnsEdit,
    taskOrderEntries,
    updateTaskOrderEntries,
    taskOrderFirstOptionDefault,
    taskOrderFirstOption,
    taskOrderMap,
    agencyMap,
    agencyEntries,
    makeAgencyName,
    svcDeliveryDefault
  }
})

function makeAgencyOptions(response: any): Entries {
  if (!Array.isArray(response?.results)) {
    return []
  }
  const entries: Entries = response.results.map((e: any) => [e.agencyId, e.name]) ?? []
  entries.sort(sortEntryFn)
  return entries
}
function makeAgencyName(compoundName: string): string {
  compoundName = compoundName ?? ''
  const pairs = compoundName.split('-')
  pairs.pop()
  return pairs.join('-')
}
function makeTaskOrderMap(response: any, mode = 'create'): Map<string, any> {
  if (!Array.isArray(response?.results)) {
    return new Map()
  }
  const filterFn = mode === 'edit' ? (e: any) => e.taskOrderId > 0 : (e: any) => e.taskOrderId === 0
  const results = response.results.filter(filterFn)
  const entries: Entries = results.map((e: any) => [e.taskOrderNumber, e]) ?? []
  entries.sort(sortEntryFn)
  return new Map(entries)
}
function sortEntryFn(x: Pair, y: Pair): 0 | 1 | -1 {
  const a = String(x[1]).toUpperCase()
  const b = String(y[1]).toUpperCase()
  return a === b ? 0 : a > b ? 1 : -1
}
