import { RouteRecordRaw as Route } from 'vue-router'
import {
  ContactUs,
  Login,
  AccessDenied,
  userHasAccessToPath,
  ACCESS_DENIED_ROUTE_NAME,
  ResetPasswordOrUserName
} from '@centurylinkfederal/eis-vue'
import Home from '../views/Home.vue'
import Dbas from '../views/Dbas.vue'
import AgencyAccounttoAdmin from '../views/AgencyAccountToAdmin.vue'
import LookupAgency from '../components/LookupAgency.vue'
import LookupAccount from '../components/LookUpAccountHome.vue'
import CreateAgency from '../components/CreateAgency.vue'
import EditAccount from '../components/EditAccount.vue'
import CreateAccount from '../components/CreateAccount.vue'
import EditAgency from '../components/EditAgency.vue'
import CreateUser from '../views/CreateUser.vue'
import UserSearch from '../views/UserSearch.vue'
import UserProfile from '../views/UserProfile.vue'
import ChangePassword from '../views/ChangePassword.vue'
import ResetForm from '../components/PasswordReset/ResetForm.vue'
import UserHome from '../views/UserHome.vue'
import TOAdmin from '../views/TOAdmin.vue'
import CreateTO from '../views/to-admin/CreateTO.vue'
import EditTO from '../views/to-admin/EditTO.vue'
import ExportDetails from '../views/to-admin/ExportDetails.vue'
import CommonTaskOrderKpisPopup from '../components/to-admin/KPI/CommonTaskOrderKpisPopup.vue'
import TastOrderAdmin from '../views/TaskOrderAdmin.vue'
import CreateTaskOrder from '../components/createTaskOrder/Page.vue'
import ViewTaskOrder from '../components/viewTaskOrder/Page.vue'
import ExportDetailsSets from '../components/exportDetailsSets/Page.vue'
import News from '../components/news/NewsDashboard.vue'

const EditTaskOrder = { ...CreateTaskOrder }

export const routes: Route[] = [
  {
    path: '/',
    name: 'Home',
    component: async () => {
      return Home
    },
    children: [
      {
        path: '/home',
        name: 'UserHome',
        component: UserHome
      },
      {
        path: '/agencyAhcAccountAdminFlow',
        name: 'AgencyAccounttoAdmin',
        component: AgencyAccounttoAdmin,
        children: [
          {
            path: '/agencyAhcAccountAdminFlow/lookup-agency',
            name: 'LookupAgency',
            component: LookupAgency,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/agencyAhcAccountAdminFlow/lookup-agency', next)
          },
          {
            path: '/agencyAhcAccountAdminFlow/create-agency',
            name: 'Create Agency',
            component: CreateAgency,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/toadmin/create', next)
            //await userHasAccessToHook('/static#/toadmin/create', next)
          },
          {
            path: '/agencyAhcAccountAdminFlow/lookup-account',
            name: 'LookupAccount',
            component: LookupAccount,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/agencyAhcAccountAdminFlow/lookup-agency', next)
          },
          {
            path: '/agencyAhcAccountAdminFlow/create-account',
            name: 'Create Account',
            component: CreateAccount,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/toadmin/create', next)
            //await userHasAccessToHook('/static#/toadmin/create', next)
          },
          {
            path: '/agencyAhcAccountAdminFlow/edit-account/:id',
            name: 'Edit Account',
            component: EditAccount,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/agencyAhcAccountAdminFlow/lookup-agency', next)
          },
          {
            path: '/agencyAhcAccountAdminFlow/edit-agency/:id',
            name: 'Edit Agency',
            component: EditAgency,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/agencyAhcAccountAdminFlow/lookup-agency', next)
          }
        ]
      },
      {
        path: '/account/dbas/:accountId/:taskOrderId',
        name: 'ViewSendDBAS',
        component: Dbas,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        beforeEnter: async (to, from, next) =>
          await userHasAccessToHook('/admin-ui/agencyAhcAccountAdminFlow/lookup-agency', next)
      },
      {
        path: '/toadmincopy',
        name: 'TO Admin Copy',
        component: TOAdmin,
        redirect: { name: 'Create TO Copy' },
        children: [
          {
            path: 'create',
            name: 'Create TO Copy',
            component: CreateTO,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/toadmin/create', next)
          },
          {
            path: 'edit',
            name: 'Edit TO Copy',
            component: EditTO,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/toadmin/create', next)
          },
          {
            path: 'export',
            name: 'Export TO Copy',
            component: ExportDetails,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/toadmin/create', next)
          },
          {
            path: 'kpi',
            name: 'Custom KPI Table',
            component: CommonTaskOrderKpisPopup
          }
        ]
      },
      {
        path: '/toadmin',
        name: 'TO Admin',
        component: TOAdmin,
        redirect: { name: 'TastOrderAdmin' },
        children: [
          {
            path: 'create',
            name: 'Create TO',
            redirect: { name: 'CreateTaskOrder' }
          },
          {
            path: 'edit',
            name: 'Edit TO',
            component: EditTO
          },
          {
            path: 'export',
            name: 'Export TO',
            component: ExportDetails
          },
          {
            path: 'kpi',
            name: 'Custom KPI Table',
            component: CommonTaskOrderKpisPopup
          }
        ]
      },
      {
        path: '/taskOrderAdmin',
        name: 'TastOrderAdmin',
        component: TastOrderAdmin,
        redirect: { name: 'CreateTaskOrder' },
        children: [
          {
            path: 'create',
            name: 'CreateTaskOrder',
            component: CreateTaskOrder,
            props: { pageMode: 'create' },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/toadmin/create', next)
          },
          {
            path: 'edit',
            name: 'EditTaskOrder',
            component: EditTaskOrder,
            props: { pageMode: 'edit' },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/toadmin/create', next)
          },
          {
            path: 'view',
            name: 'ViewTaskOrder',
            component: ViewTaskOrder
          },
          {
            path: 'export',
            name: 'ExportDetailsSets',
            component: ExportDetailsSets,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/toadmin/create', next)
          },
          {
            path: 'export-details',
            name: 'ExportDetails',
            component: ExportDetails,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/toadmin/create', next)
          }
        ]
      },
      {
        path: '/user-admin',
        name: 'User Admin',
        children: [
          {
            path: 'create-user',
            component: CreateUser,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/user-admin/create-user', next)
          },
          {
            path: 'user-search',
            component: UserSearch,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/user-admin/user-search', next)
          },
          {
            path: 'edit-user/:id',
            component: CreateUser,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            beforeEnter: async (to, from, next) =>
              await userHasAccessToHook('/admin-ui/user-admin/user-search', next)
          },
          {
            path: 'user-profile',
            component: UserProfile
          },
          {
            path: 'change-password',
            component: ChangePassword
          }
        ]
      },
      {
        path: '/news',
        name: 'News',
        component: News
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/lgn',
    name: 'LegacyLoginPath',
    redirect: { name: 'Login' }
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetPasswordOrUserName
  },
  {
    path: '/password-reset',
    name: 'reset-form',
    component: ResetForm
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactUs
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: AccessDenied
  }
]
async function userHasAccessToHook(path: string, next: Function) {
  if (await userHasAccessToPath(path)) {
    next()
  } else {
    next({ name: ACCESS_DENIED_ROUTE_NAME })
  }
}

export default { routes }
