<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Alerts from './Alerts.vue'
import { useApi, userHasAccessToPath } from '@centurylinkfederal/eis-vue'
import { getUserPaths } from '../utils/user'
const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)
const router = useRouter()
const route = useRoute()
const selectedTab = ref('lookup-agency')
const permissions = ref<any[]>([])
const selectTab = (tabName: string) => {
  selectedTab.value = tabName
}
watch(
  () => route.path,
  (path) => {
    if (path === '/agencyAhcAccountAdminFlow/lookup-agency') {
      selectTab('lookup-agency')
    } else if (path === '/agencyAhcAccountAdminFlow/create-agency') {
      selectTab('create-agency')
    } else if (path === '/agencyAhcAccountAdminFlow/lookup-account') {
      selectTab('lookup-account')
    } else if (path === '/agencyAhcAccountAdminFlow/create-account') {
      selectTab('create-account')
    }
    // Add more conditions for other routes if needed
  }
)

async function getUserPermissions(): Promise<void> {
  try {
    const userPaths = getUserPaths()
    const viewPermission = userPaths.includes('/admin-ui/agencyAhcAccountAdminFlow/lookup-agency')
    const createPermission = userPaths.includes('/admin-ui/toadmin/create')
    //const createPermission = userPaths.includes('/static#/toadmin/create')
    const hasAccessTo: string[] = []

    if (viewPermission) {
      hasAccessTo.push('view')
    }
    if (createPermission) {
      hasAccessTo.push('create')
      hasAccessTo.push('edit')
    }

    permissions.value = hasAccessTo
    // eslint-disable-next-line no-empty
  } catch {}
}

onMounted(() => {
  setTimeout(() => {
    getUserPermissions()

    let path = route.path
    if (path === '/agencyAhcAccountAdminFlow/lookup-agency') {
      path = '/agencyAhcAccountAdminFlow/lookup-agency'
    }

    if (path === '/agencyAhcAccountAdminFlow/lookup-agency') {
      selectTab('lookup-agency')
    } else if (path === '/agencyAhcAccountAdminFlow/create-agency') {
      selectTab('create-agency')
    } else if (path === '/agencyAhcAccountAdminFlow/lookup-account') {
      selectTab('lookup-account')
    } else if (path === '/agencyAhcAccountAdminFlow/create-account') {
      selectTab('create-account')
    }
    router.push(path)
  }, 0)
})
</script>
<template>
  <div class="container">
    <Alerts />
    <section class="chi-card">
      <div class="chi-card__header chi-label -p--2">Agency/Account/TO Admin</div>
      <div class="chi-grid">
        <div class="chi-col -w--12">
          <ul
            class="chi-tabs -p--2 -mb--2 right-tabs"
            id="example-horizontal-base"
            role="tablist"
            aria-label="chi-tabs-horizontal"
          >
            <li
              role="tab"
              v-if="
                permissions?.includes('create') ||
                permissions?.includes('edit') ||
                permissions?.includes('view')
              "
            >
              <router-link
                tag="a"
                to="/agencyAhcAccountAdminFlow/lookup-agency"
                class="nav-link"
                :class="{
                  active: route.path.includes('lookup-agency') || route.path.includes('edit-agency')
                }"
                >Lookup Agency</router-link
              >
            </li>
            <li role="tab" v-if="permissions?.includes('create')">
              <router-link
                to="/agencyAhcAccountAdminFlow/create-agency"
                class="nav-link"
                :class="{ active: route.path.includes('create-agency') }"
                >Create Agency</router-link
              >
            </li>
            <li
              role="tab"
              v-if="
                permissions?.includes('create') ||
                permissions?.includes('edit') ||
                permissions?.includes('view')
              "
            >
              <router-link
                tag="a"
                to="/agencyAhcAccountAdminFlow/lookup-account"
                class="nav-link"
                :class="{
                  active:
                    route.path.includes('lookup-account') || route.path.includes('edit-account')
                }"
                >Lookup Account</router-link
              >
            </li>
            <li role="tab" v-if="permissions?.includes('create')">
              <router-link
                tag="a"
                to="/agencyAhcAccountAdminFlow/create-account"
                class="nav-link"
                :class="{ active: route.path.includes('create-account') }"
                >Create Account</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="-bg--white">
        <router-view></router-view>
      </div>
    </section>
    <div class="-m--1 -p--1"></div>
    <footer></footer>
  </div>
</template>
<style scoped>
.chi-card {
  border: 0.0625rem solid rgba(0, 0, 0, 0.12) !important;
}

.chi-card__header {
  background-color: #d0d4d9;
  font-size: 17px !important;
}

.right-tabs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.right-tabs li {
  margin-right: 10px;
}

.right-tabs a {
  color: #333;
  text-decoration: none;
}

.right-tabs a.active {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}
</style>
