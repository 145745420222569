import { ref, computed, onMounted } from 'vue'
import { defineStore } from 'pinia'
import { useToAdminAPI } from '../../composables/to-admin/api'
import { Entries, Pair } from '../../types/common'

let adminApi: any
export const useTaskOrder = defineStore('taskOrderStore', () => {
  adminApi = useToAdminAPI()
  const isMounted = ref(false)
  const agencyEntries = ref<Entries>([])
  const taskOrderEntries = ref<any>([])
  const taskOrderSearchEntries = ref<Entries>([])
  const taskOrderMap = ref(new Map())
  const taskOrderFirstOptionDefault = ['', '- Select -']
  const taskOrderFirstOption = ref(taskOrderFirstOptionDefault)
  const agencyMap = computed(() => new Map(agencyEntries.value))
  const agencyNameMap = ref(new Map())

  async function updateTaskOrderEntries(id: number | string | undefined): Promise<void> {
    const agencyMap = new Map(agencyEntries.value)
    const agencyCompoundName = agencyMap.get(id)
    const agencyIdentifier = makeAgencyName(agencyCompoundName)

    taskOrderEntries.value = Array.from(taskOrderMap.value.entries())
      .filter(([, e]) => e.agencyIdentifier === agencyIdentifier && e.taskOrderId !== 0)
      .map(([key]) => [key, key])
      .sort((a, b) => String(a[1]).localeCompare(String(b[1])))
  }
  async function onInit(force = false): Promise<void> {
    if (isMounted.value && !force) {
      return
    }
    const agenciesRes = await adminApi.fetchAgencies()
    agencyEntries.value = makeAgencyOptions(agenciesRes)
    agencyNameMap.value = new Map(
      (agenciesRes?.results ?? []).map((e: any) => [makeAgencyName(e.name), e])
    )
    const taskOrdersRes = await adminApi.fetchTaskOrders()
    taskOrderSearchEntries.value = makeTaskOrderSearchOptions(taskOrdersRes)
    taskOrderMap.value = new Map(
      (taskOrdersRes?.results ?? []).map((e: any) => [e.taskOrderNumber, e])
    )
    isMounted.value = true
  }
  onMounted(async () => {
    await onInit()
  })
  return {
    taskOrderEntries,
    taskOrderFirstOptionDefault,
    taskOrderFirstOption,
    agencyMap,
    agencyEntries,
    taskOrderMap,
    taskOrderSearchEntries,
    agencyNameMap,
    isMounted,
    updateTaskOrderEntries,
    makeAgencyName,
    onInit
  }
})

function makeAgencyOptions(response: any): Entries {
  if (!Array.isArray(response?.results)) {
    return []
  }
  const entries: Entries = response.results.map((e: any) => [e.agencyId, e.name]) ?? []
  entries.sort(sortEntryFn)
  return entries
}
function makeAgencyName(compoundName: string): string {
  compoundName = compoundName ?? ''
  const pairs = compoundName.split('-')
  pairs.pop()
  return pairs.join('-')
}
function makeTaskOrderSearchOptions(response: any): Entries {
  if (!Array.isArray(response?.results)) {
    return []
  }
  const results = response.results.filter((e: any) => e.taskOrderId !== 0)
  const entries: Entries = results.map((e: any) => [e.taskOrderNumber, e.taskOrderNumber]) ?? []
  entries.sort(sortEntryFn)
  return entries
}
function sortEntryFn(x: Pair, y: Pair): 0 | 1 | -1 {
  const a = String(x[1]).toUpperCase()
  const b = String(y[1]).toUpperCase()
  return a === b ? 0 : a > b ? 1 : -1
}
