<script setup lang="ts">
import { useStore } from './store'
import { ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { DropSearch } from '@centurylinkfederal/eis-vue'

const store = useStore()
const { agenciesFilter, clearAgencyFilter, agencies, searchTOByAgencies, selectedTOOptionType } =
  storeToRefs(store)
const selectionsToBeDisplayed = ref<Array<Array<any>>>([])
const selected = ref([])
const settings = {
  minWidth: '240px',
  minHeight: '240px',
  maxHeight: '240px',
  minSearchLength: 0,
  typeahead: true
}
const label = computed(() => {
  if (agenciesFilter.value?.length > 0) {
    return `- Selected Agencies (${agenciesFilter.value.length}) -`
  }
  return '- Select Agency -'
})
async function onToggle(isVisible: boolean, { selectedKeys }: any) {
  // filter agencies based on selected agencies
  selectionsToBeDisplayed.value = agencies.value.filter((agency: any) =>
    agenciesFilter.value.includes(agency[0])
  )

  if (!isVisible) {
    selectedTOOptionType.value =
      agenciesFilter.value?.length === selectedKeys?.length ? selectedTOOptionType.value : null

    agenciesFilter.value = selectedKeys
    searchTOByAgencies.value++
    return
  }
}
</script>
<template>
  <div class="-d--flex -align-items--end">
    <label for="searchTOs" class="chi-label">Task Order Privileges</label>
  </div>
  <DropSearch
    v-model="selected"
    :label="label"
    :options="agencies"
    :settings="settings"
    :selected="selectionsToBeDisplayed"
    :clearSeq="clearAgencyFilter"
    @onToggle="onToggle"
  />
</template>
