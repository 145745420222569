<script lang="ts" setup>
import { onMounted, ref, watch, computed, toRaw, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { getStore } from './store'
import { useColumns as useDetailsColumns } from './detailsColumns'
import { useStore as useModsStore } from './modsStore'
import { useStore as useContactsStore } from './contactsStore'
import { useColumns as useContactsColumns } from './contactsColumns'
import { useStore as useFinancialsStore } from './financialsStore'
import { useColumns as useFinancialsColumns } from './financialColumns'
import { useStore as useOfficialsStore } from './officialsStore'
import { useColumns as useOfficialsColumns } from './officialsColumns'
import { useStore as useServicesStore } from './servicesStore'
import { useColumns as useServicesColumns } from './servicesColumns'
import { useStore as useKpisStore } from './kpisStore'
import { useColumns as useKpisColumns } from './kpisColumns'
import { useFormsStore } from '../../composables/forms'
import { useToAdminAPI } from '../../composables/to-admin/api'
import { alertTable } from '../../composables/alerts'
import { triggerForm, getRowChanges } from '../../utils/form'
import { unite } from '../../utils/helpers'
import { Tooltip, parseErr, Row, randomId } from '@centurylinkfederal/eis-vue'
import { Pair, Entries } from '../../types/common'
import { fromMMDDYYYY } from '../../utils/date'
import { encodeAgencyIdentifier } from '../../utils/to-admin.utils'
import moment from 'moment'
export interface Props {
  pageMode: 'create' | 'edit'
  query?: any
}
const props = withDefaults(defineProps<Props>(), {
  pageMode: 'create',
  query: {}
})

const pageMode = ref(props.pageMode)
const pageStore = getStore(pageMode.value)()
const {
  alertMap,
  signal,
  form,
  formChanges,
  panelStates,
  selectedTaskOrderObj,
  taskOrderDetailsRes
} = storeToRefs(pageStore)
const detailsColumnsStore = useDetailsColumns()
const { agencyEntries, columns: detailsColumns } = storeToRefs(detailsColumnsStore)

const { makeAgencyName } = detailsColumnsStore
const modsStore = useModsStore()
const { form: modsForm, formChanges: modsFormChanges } = storeToRefs(modsStore)
const contactsStore = useContactsStore()
const { form: contactsForm, formChanges: contactsFormChanges } = storeToRefs(contactsStore)
const contactsColumnsStore = useContactsColumns()
const { columns: contactsColumns } = storeToRefs(contactsColumnsStore)
const financialsStore = useFinancialsStore()
const {
  form: financialsForm,
  formChanges: financialsFormChanges,
  deleted: financialDeleted
} = storeToRefs(financialsStore)
const financialsColumnsStore = useFinancialsColumns()
const { columns: financialsColumns } = storeToRefs(financialsColumnsStore)
const officialsStore = useOfficialsStore()
const {
  form: officialsForm,
  formChanges: officialsFormChanges,
  deleted: officialDeleted
} = storeToRefs(officialsStore)
const officialsColumnsStore = useOfficialsColumns()
const { columns: officialsColumns } = storeToRefs(officialsColumnsStore)
const servicesStore = useServicesStore()
const {
  form: servicesForm,
  formState: servicesFormState,
  picks,
  quirks
} = storeToRefs(servicesStore)
const servicesColumnsStore = useServicesColumns()
const { serviceMap, jurisdictionMap } = storeToRefs(servicesColumnsStore)
const kpisStore = useKpisStore()
const {
  form: kpisForm,
  formState: kpisFormState,
  quirks: kpisQuirks,
  kpiStandardMap,
  kpisStandardInServiceMap,
  kpisInServiceMap
} = storeToRefs(kpisStore)
const kpisColumnsStore = useKpisColumns()
const {
  titleMap: kpisTitleMap,
  codesOptions: kpisCodes,
  groups: kpisGroups
} = storeToRefs(kpisColumnsStore)
const { useForms } = useFormsStore()
const adminApi = useToAdminAPI()
const router = useRouter()
const submitting = ref(false)
const submitButton = computed(() => {
  const lableTable = {
    create: 'Create',
    edit: 'Update'
  }
  let button: any = {
    label: lableTable[pageMode.value] ?? 'Submit',
    icon: undefined,
    tooltip: undefined,
    state: undefined,
    class: undefined
  }

  let state = 'unchanged'
  for (const panelKey in panelStates.value) {
    const { state: panelState, error } = panelStates.value[panelKey]
    if (panelState && panelState !== 'unchanged') {
      state = panelState
    }
    button = {
      ...button,
      icon: state === 'danger' ? 'warning' : undefined,
      tooltip: error?.message,
      state,
      class: state === 'danger' ? '-danger -outline' : '-primary'
    }
    if (state === 'danger') {
      break
    }
  }
  if (pageMode.value === 'edit' && taskOrderDetailsRes.value?.taskOrderDto?.isCurrent !== 'Y') {
    button = {
      ...button,
      state: 'unchanged',
      label: 'In Read Only Mode'
    }
  }
  return button
})
const kpisCodesMap = computed(() =>
  Object.fromEntries(
    Object.keys(kpisCodes.value).map((key) => [key, new Map(kpisCodes.value[key])])
  )
)

function makePayload({ taskOrderId }: any = {}): any {
  taskOrderId = taskOrderId ? parseInt(taskOrderId) : undefined
  const detailsInputs: any = form.value.details ?? {}
  const detailsEntries = Array.from(Object.keys(detailsInputs))
    .map((inputKey) => {
      const { field, value } = detailsInputs[inputKey]
      return [field, toRaw(value)]
    })
    .filter(([field]) => field)
  const details = formatRow(Object.fromEntries(detailsEntries), {
    strStartDate: fromMMDDYYYY,
    strEndDate: fromMMDDYYYY
  })

  const agencyMap = new Map(agencyEntries.value)
  const agencyCompoundName = agencyMap.get(details.agencyId)
  const agencyIdentifier = makeAgencyName(agencyCompoundName)
  const newPicks: Set<string | number> = toRaw(picks.value)

  const payload = {
    taskOrderId: taskOrderId ?? 0,
    isCurrent: 'Y',
    ...details,
    contractorInvoiceLvlAcctNo: details.level3ban,
    agencyIdentifier,
    svcDeliveryNumDays: details.svcDelivery?.svcDeliveryNumDays,
    svcDeliveryDaysType: details.svcDelivery?.svcDeliveryDaysType,
    sendBillingEmail: details.billingNotifications,
    ioCustom: details.invoiceOptions?.includes('ioCustom') ? 'Y' : 'N',
    etems: details.invoiceOptions?.includes('etems') ? 'Y' : 'N',
    ioIppSummary: details.invoiceOptions?.includes('ioIppSummary') ? 'Y' : 'N',
    ioIppDetail: details.invoiceOptions?.includes('ioIppDetail') ? 'Y' : 'N',
    ioDoi: details.invoiceOptions?.includes('ioDoi') ? 'Y' : 'N',
    statusType: details.statusManaged?.join(','), //@todo Double check
    billingContacts:
      details.billingNotifications === 'N'
        ? []
        : formToRows(toRaw(contactsForm.value), 'taskOrderBillingContactId'),
    taskOrderFinancialDTOList: formToRows(toRaw(financialsForm.value), 'taskOrderFinancialId', {
      performanceBeginDate: fromMMDDYYYY,
      performanceEndDate: fromMMDDYYYY
    }),
    tooDTOList: formToRows(toRaw(officialsForm.value), 'taskOrderOfficialId', {
      startDate: fromMMDDYYYY,
      stopDate: fromMMDDYYYY
    }),
    taskOrderServices: JSON.stringify(makeServicesDtos(newPicks))
  }
  return payload
}
function makeEditServicesDtos(servicesSet: Set<string | number>): any[] {
  return Array.from(servicesSet).map((serviceKey) => makeEditServiceDto(serviceKey))
}
function makeEditServiceDto(serviceKey: string | number): any {
  const quirk = quirks.value.get(serviceKey) ?? {}

  const serviceDto = {
    ...quirk.serviceDto,
    vendorPo: serviceKey === 'MWS' ? servicesForm.value?.MWS?.vendorPo?.value : null
  }
  const nscMap: Map<string, any> = quirk.nscs || new Map()
  const jurisdictions = Array.from(quirk.jurisdictions ?? [])
  const clins = Array.from(quirk.clins ?? [])
  if (quirk.action === 'delete') {
    serviceKey = quirk.action
  }
  return {
    taskOrderId: selectedTaskOrderObj.value?.taskOrderId ?? 0,
    allClins: !quirk.clins?.size ? true : false,
    clins: clins.length ? clins : null,
    serviceCode: serviceKey,
    toServiceId: serviceDto.toServiceId ?? null,
    taskOrderNSCList: Array.from(nscMap.entries())
      .filter(([_nscId, nsc]): any => !nsc?._deleted)
      .map(([nscId, nsc]) => ({
        taskOrderNscId: parseInt(String(nscId).replace('_new', '')),
        networkSiteCode: nsc.networkSiteCode,
        location: nsc.location
      })),
    jurisdictions: jurisdictions.map((e) => {
      const j = jurisdictionMap.value.get(e)
      return {
        country: j.countryDesc,
        jurisdId: j.jurisdictionCode,
        taskOrderJurisdictionId: ''
      }
    }),
    vendorPo: serviceKey === 'MWS' && serviceDto.vendorPo ? serviceDto.vendorPo : null
  }
}
function makeServicesDtos(servicesSet: Set<string | number>): any[] {
  const servicesDto = Array.from(servicesSet).map((e) => {
    const dto = makeServiceDto(e)
    return dto
  })
  return servicesDto
}
function makeServiceDto(serviceKey: string | number): any {
  const quirk = quirks.value.get(serviceKey) ?? {}
  const service = {
    ...serviceMap.value.get(serviceKey),
    vendorPo: serviceKey === 'MWS' ? servicesForm.value?.MWS?.vendorPo?.value : null
  }
  const nscMap: Map<string, any> = quirk.nscs
  const jurisdictions = Array.from(quirk.jurisdictions ?? [])
  const clins = Array.from(quirk.clins ?? []).join(',')
  const dto = {
    individualClins: quirk.clins?.size ? 'Y' : 'N',
    allClins: !quirk.clins?.size ? 'Y' : 'N',
    clin: clins,
    serviceCode: serviceKey,
    taskOrderNSCList: Array.from(nscMap?.entries() ?? [])
      .filter(([_nscId, nsc]): any => !nsc?._deleted)
      .map(([nscId, nsc]): any =>
        Object({
          taskOrderNscId: parseInt(String(nscId).replace('_new', '')),
          networkSiteCode: nsc.networkSiteCode,
          location: nsc.location
        })
      ),
    jurisdictionCodeContry: jurisdictions.map((e) => {
      const j = jurisdictionMap.value.get(e)
      return [j.jurisdictionCode, j.countryDesc].join('~~~')
    }),
    vendorPo: serviceKey === 'MWS' && service.vendorPo ? service.vendorPo : null
  }
  return dto
}
function makeModsPayload(): any {
  const formChanges = toRaw(modsFormChanges.value)
  const posts: any[] = []
  const puts: any[] = []
  for (const rowKey in formChanges) {
    const inputs = modsForm.value[rowKey] ?? []
    if (inputs.length === 0) {
      continue
    }
    const dto: any = {}
    for (const field in inputs) {
      dto[field] = inputs[field].value
    }
    if (rowKey.includes('_new')) {
      posts.push({
        ...dto,
        taskOrderModIterationId: 0,
        taskOrderId: parseInt(props.query.taskOrderId)
      })
    } else {
      puts.push({
        ...dto,
        taskOrderModIterationId: dto.taskOrderModIterationId,
        taskOrderId: parseInt(props.query.taskOrderId)
      })
    }
  }
  return { posts, puts }
}
function makeKpiDto(row: any): any {
  const {
    group,
    serviceCode,
    kpiId,
    kpiucUnitCode,
    icbNumber,
    kpilqSvcLvlQCode,
    perfStdThreshold,
    kpiaoAqlQOprCode,
    kpimuMeasUnitCode,
    kpilqLocQCode,
    additionalInfo,
    effectiveStartDate,
    effectiveEndDate,
    taskOrderMetaData,
    stdCustomIndicator,
    taskOrderId,
    taskOrderNumber,
    clin,
    kpiType,
    kpiMode
  } = row
  const quirk = quirks.value.get(serviceCode) ?? {}
  const serviceDto = quirk.serviceDto ?? {}
  const tempKpiId = String(kpiId).includes('_new-')
    ? parseInt(String(kpiId).replace('_new-', ''))
    : null
  const isCustom = String(stdCustomIndicator).toLowerCase().includes('custom')
  const effectiveStartDateIso = effectiveStartDate
    ? new Date(effectiveStartDate).toISOString()
    : effectiveStartDate
  const effectiveEndDateIso = effectiveEndDate
    ? new Date(effectiveEndDate).toISOString()
    : effectiveEndDate
  const dto = {
    kpiId: tempKpiId || kpiMode === 'create' || kpiType === 'REF' ? null : kpiId,
    kpiType: 'NON-REF',
    kpiGrpType: kpisTitleMap.value.get(group)?.kpiGrpType,
    svcId: serviceCode,
    svcDesc: serviceMap.value.get(serviceCode)?.serviceDesc,
    icbNumber,
    kpiucUnitCode,
    kpiucUnitCodeDesc: kpisCodesMap.value.KPIUC?.get(kpiucUnitCode),
    kpiMeas: kpisCodesMap.value.KPIUC?.get(kpiucUnitCode),
    kpilqLocQCode: kpilqLocQCode,
    kpilqLocQDesc: kpisCodesMap.value.KPILQ?.get(kpilqLocQCode),
    kpilqSvcLvlQCode: kpilqSvcLvlQCode,
    kpilqSvcLvlQDesc: kpisCodesMap.value.KPISLQ?.get(kpilqSvcLvlQCode),
    kpimuMeasUnitCode,
    kpimuMeasUnitDesc: kpisCodesMap.value.KPIMU?.get(kpimuMeasUnitCode),
    perfStdThreshold,
    kpiaoAqlQOprCode,
    kpiaoAqlQOprDesc: kpisCodesMap.value.KPIAO?.get(kpiaoAqlQOprCode),
    stdCustomIndicator: isCustom ? 'Custom KPI' : 'Standard KPI',
    additionalInfo,
    effectiveStartDate: effectiveStartDateIso,
    effectiveEndDate: effectiveEndDateIso,
    taskOrderMetaData,
    svcClinPrefix: clin ? serviceCode : undefined,
    clin,
    taskOrderSvcId: serviceDto.toServiceId,
    customPrefStdThresHold: isCustom ? perfStdThreshold : undefined,
    customKpiaoAqlQOprCode: isCustom ? kpiaoAqlQOprCode : undefined,
    customKpimuMeasUnitCode: isCustom ? kpimuMeasUnitCode : undefined,
    customKpilqLocQCode: isCustom ? kpilqLocQCode : undefined,
    customEffectiveStartDate: isCustom ? effectiveStartDateIso : undefined,
    customEffectiveEndDate: isCustom ? effectiveEndDateIso : undefined,
    tempKpiId,
    taskOrderId,
    taskOrderNumber
  }
  return dto
}
function makeCustomEntries({ groupKey, serviceKey }: any): Entries {
  const kpisFormStoreEntries = Array.from(kpisQuirks.value.keys())
    .filter((quirkKey) => {
      const [what, key, _profile] = String(quirkKey).split(':')
      return what === groupKey && key === serviceKey
    })
    .map((quirkKey) => [quirkKey, useForms(quirkKey)])
  const kpisRowEntries: Entries = kpisFormStoreEntries.map(([quirkKey, e]) => {
    const rowEntries: Entries = Object.keys(e.form.value).map((rowKey) => {
      const row = Object.fromEntries(
        Object.keys(e.form.value[rowKey]).map((inputKey) => {
          const { field, value } = e.form.value[rowKey][inputKey]
          return [field, value]
        })
      )
      return [rowKey, row]
    })
    return [quirkKey, rowEntries]
  })
  return kpisRowEntries
}
function makeStandardEntries({ groupKey, serviceKey }: any): Entries {
  const kpiIdsSet: Set<string | number> =
    kpisStandardInServiceMap.value.get([groupKey, serviceKey].join(':')) ?? new Set()
  const kpiIds = Array.from(kpiIdsSet)
  const rowEntries: Entries = kpiIds.map((kpiId: number | string) => [
    kpiId,
    toRaw(kpiStandardMap.value.get(String(kpiId)))
  ])
  const quirkKey = [groupKey, serviceKey, 'standard'].join(':')
  const kpisRowEntries: Entries = rowEntries?.length ? [[quirkKey, rowEntries]] : []
  return kpisRowEntries
}
function makeKpis({ taskOrderNumber, taskOrderId, kpiMode }: any): any[] {
  let kpisRowMap: Map<string, any> = new Map()
  const groupTitles = kpisGroups.value.map((e) => e.title)
  for (const groupKey of groupTitles) {
    if (['Submission'].includes(groupKey)) {
      continue
    }
    const profileInputs = kpisForm.value[groupKey]
    for (const serviceKey of picks.value) {
      const kpiSet = kpisInServiceMap.value.get([groupKey, serviceKey].join(':')) ?? new Set()
      const semanticProfile =
        kpiSet.size > 0 ? 'custom' : (profileInputs?.[serviceKey]?.value ?? 'standard')
      const rowEntries: Entries =
        semanticProfile === 'standard'
          ? makeStandardEntries({ groupKey, serviceKey })
          : makeCustomEntries({ groupKey, serviceKey })
      for (const [rowKey, row] of rowEntries) {
        kpisRowMap.set(rowKey, row)
      }
    }
  }
  const kpisRowEntries = Array.from(kpisRowMap.entries())
  const kpisDtosUnflatten = kpisRowEntries.map(([quirkKey, rowEntries]) => {
    const [group, serviceCode, profile] = quirkKey.split(':')
    const rowMap = new Map(rowEntries)
    rowEntries = Array.from(rowMap.entries())
    const rowDtos = rowEntries.map(([kpiId, row]: Pair) => {
      const dto = makeKpiDto({
        ...row,
        kpiId,
        taskOrderId,
        taskOrderNumber,
        group,
        serviceCode,
        profile,
        kpiMode
      })
      return dto
    })
    return rowDtos
  })
  const kpisDtos = kpisDtosUnflatten.flat()
  return kpisDtos
}
async function sendKpis(kpisDtos: any[]): Promise<Pair> {
  if (!kpisDtos?.length) {
    return [null, null]
  }
  let kpisRes: any = undefined
  let kpisErr: any = undefined

  try {
    const kpisPayload = {
      thresholdUpdatedKpis: kpisDtos
    }
    kpisRes = await adminApi.createServiceKpis(kpisPayload)
  } catch (err) {
    kpisErr = parseErr(err)
  }
  if (kpisErr) {
    return [null, kpisErr]
  }
  return [kpisRes, null]
}
function onReset(): void {
  signal.value = ['reset', { resetId: randomId() }]
}
async function onSubmit(): Promise<void> {
  const skipChecks = false
  if (!skipChecks) {
    if (props.pageMode === 'create') {
      const { state } = triggerForm(form.value, detailsColumns.value, 'details')
      if (state === 'danger') {
        signal.value = ['scrollTo', { selector: '.page-container' }]
        return
      }
    }
    const billingNotifications = form.value?.details?.billingNotifications?.value
    if (billingNotifications === 'Y' && Object.keys(contactsForm.value).length === 0) {
      signal.value = [
        'setPanelState',
        {
          panelKey: 'billing-contacts',
          stateObj: {
            state: 'danger',
            error: {
              message: 'Must have least one Billing Contact.'
            }
          }
        }
      ]
      await nextTick()
      signal.value = ['scrollTo', { selector: '#epanel-billing-contacts' }]
      return
    }
    if (billingNotifications === 'Y' && Object.keys(contactsForm.value).length > 0) {
      const { state } = triggerForm(contactsForm.value, contactsColumns.value)
      if (state === 'danger') {
        signal.value = ['scrollTo', { selector: '#epanel-billing-contacts' }]
        return
      }
    }
    const financialsSize = Object.keys(financialsForm.value).filter(
      (key: string) => !financialsForm.value[key]?._deleted?.value
    ).length
    if (financialsSize === 0) {
      signal.value = [
        'setPanelState',
        {
          panelKey: 'financials',
          stateObj: {
            state: 'danger',
            error: {
              message: 'Must have least one Financial Record.'
            }
          }
        }
      ]
      await nextTick()
      signal.value = ['scrollTo', { selector: '#epanel-financials' }]
      return
    }
    if (financialsSize > 0) {
      const { state } = triggerForm(financialsForm.value, financialsColumns.value)
      if (state === 'danger') {
        signal.value = ['scrollTo', { selector: '#epanel-financials' }]
        return
      }
    }
    const officialsSize = Object.keys(officialsForm.value).filter(
      (key: string) => !officialsForm.value[key]?._deleted?.value
    ).length
    if (officialsSize === 0) {
      signal.value = [
        'setPanelState',
        {
          panelKey: 'officials',
          stateObj: {
            state: 'danger',
            error: {
              message: 'Must have least one Official Record.'
            }
          }
        }
      ]
      await nextTick()
      signal.value = ['scrollTo', { selector: '#epanel-officials' }]
      return
    }
    if (officialsSize > 0) {
      const { state } = triggerForm(officialsForm.value, officialsColumns.value)
      if (state === 'danger') {
        signal.value = ['scrollTo', { selector: '#epanel-officials' }]
        return
      }
    }
  }
  if (props.pageMode === 'create') {
    submitting.value = true
    await onCreate()
    submitting.value = false
  }
  if (props.pageMode === 'edit') {
    submitting.value = true
    await onUpdate()
    submitting.value = false
  }
}
async function onCreate(): Promise<void> {
  if (submitButton.value.state === 'danger') {
    return
  }
  const payload = makePayload({ ...props, ...selectedTaskOrderObj.value })

  let createRes: any = undefined
  let createErr: any = undefined
  try {
    createRes = await adminApi.createTaskOrders(payload)
  } catch (err) {
    createErr = parseErr(err)
  }
  if (!createRes?.valid) {
    // eslint-disable-next-line no-console
    console.error('createErr', createErr)
    alertMap.value.set('alert', {
      ...alertTable.danger,
      title: 'Create Error',
      message: createErr?.message
    })
    signal.value = ['scrollTo', { selector: '.page-container' }]
    return
  }
  const { taskOrderNumber, originalTaskOrderId, taskOrderId, agencyIdentifier } =
    createRes.results?.[0] ?? {}
  const kpisDtos = makeKpis({
    taskOrderNumber,
    taskOrderId,
    kpiMode: 'create'
  })

  const [_kpisRes, _kpisErr] = await sendKpis(kpisDtos)

  {
    const agencyIdentifierEncoded = encodeAgencyIdentifier(agencyIdentifier)
    let state = 'success'
    let message = `The Task Order got created successfully.`
    if (_kpisErr) {
      state = 'warning'
      message += ` However, there was a problem with Kpis. \n ` + _kpisErr?.message
    }
    const query = {
      taskOrderNumber,
      originalTaskOrderId,
      taskOrderId,
      agencyIdentifierEncoded,
      alert: JSON.stringify({
        message,
        state
      })
    }
    router.push({ name: 'EditTaskOrder', query })
  }
}
async function onUpdate(): Promise<void> {
  if (submitButton.value.state === 'danger') {
    return
  }
  {
    const financialFormChanges = toRaw(financialsFormChanges.value)
    let financialData = transformFinancialData(financialFormChanges, financialDeleted)

    const prepareFinancialPayload = (): any => {
      const payload = { ...props, ...selectedTaskOrderObj.value }
      const financialsPayload = financialData.map((financial: any) => ({
        taskOrderFinancialId: financial.taskOrderFinancialId || null,
        performanceBeginDate: financial.performanceBeginDate,
        performanceEndDate: financial.performanceEndDate,
        performancePeriodFunding: financial.performancePeriodFunding,
        prorationType: financial.prorationType,
        taskOrder: payload || {},
        taskOrderAgfRate: financial.taskOrderAgfRate,
        taskOrderCeiling: financial.taskOrderCeiling,
        deleted: financial.deleted,
        createdBy: financial.createdBy || 0,
        createdOn:
          financial.createdOn?.value || financial.createdOn || moment().format('YYYY-MM-DD'),
        updatedBy: financial.updatedBy || 0,
        updatedOn: moment().format('YYYY-MM-DD'),
        isNew: financial.taskOrderFinancialId === 0 || financial.isNew === true
      }))
      return financialsPayload
    }
    try {
      const putFinancialPayload = prepareFinancialPayload()
      if (putFinancialPayload.length > 0) {
        await adminApi.updateTaskOrderFinancials(putFinancialPayload)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating task order financials:', error)
    }
  }
  {
    const officialFormChanges = toRaw(officialsFormChanges.value)
    let officialData = transformOfficialData(officialFormChanges, officialDeleted)
    const prepareOfficialPayload = (): any => {
      const payload = { ...props, ...selectedTaskOrderObj.value }

      const officialsPayload = officialData.map((official: any) => ({
        taskOrderOfficialId: official.taskOrderOfficialId || null,
        createdBy: official.createdBy || 0,
        createdOn: official.createdOn?.value
          ? moment(official.createdOn.value).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          : moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        updatedBy: official.updatedBy || 0,
        updatedOn: official.updatedOn
          ? moment(official.updatedOn).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          : moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        firstName: official.firstName || '',
        lastName: official.lastName || '',
        role: official.role || '',
        startDate: official.startDate ? moment(official.startDate).format('YYYY-MM-DD') : '',
        stopDate: official.stopDate ? moment(official.stopDate).format('YYYY-MM-DD') : '',
        taskOrder: payload || {},
        isNew: official.isNew || false,
        workEmail: official.workEmail || '',
        workMobilePhone: official.workMobilePhone
          ? official.workMobilePhone.replace(/[^\d]/g, '')
          : '',
        workOfficePhone: official.workOfficePhone
          ? official.workOfficePhone.replace(/[^\d]/g, '')
          : '',
        deleted: official.deleted
      }))
      return officialsPayload
    }
    try {
      const putOfficialPayload = prepareOfficialPayload()
      if (putOfficialPayload.length > 0) {
        await adminApi.updateTaskOrdersByIdForToOfficialsAPI(putOfficialPayload)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating task order official:', error)
    }
  }

  let updateRes: any = undefined
  let updateErr: any = undefined
  const detailsChanges = getRowChanges(formChanges.value?.details)
  const contactsChangesCount = getChangesCount(contactsFormChanges.value)

  if (detailsChanges.length > 0 || contactsChangesCount > 0) {
    const payload = makePayload({ ...props, ...selectedTaskOrderObj.value })
    delete payload.taskOrderServices
    try {
      updateRes = await adminApi.updateTaskOrderDetails(payload)
    } catch (err) {
      updateErr = parseErr(err)
    }
    if (updateRes?.error) {
      updateErr = { message: updateRes.error }
      updateRes.valid = false
    }
    if (!updateRes?.valid) {
      // eslint-disable-next-line no-console
      console.error('updateErr', updateErr)
      alertMap.value.set('alert', {
        ...alertTable.danger,
        title: 'Details Update Error',
        message: updateErr?.message
      })
      signal.value = ['scrollTo', { selector: '.page-container', signalId: randomId() }]
      return
    }
  }
  if (servicesFormState.value !== 'unchanged') {
    const newPicks: Set<string | number> = toRaw(picks.value)
    const quirkKeysSet = new Set(Array.from(quirks.value.keys()))
    const oldPicks = pageMode.value === 'edit' ? quirkKeysSet : new Set()
    const picksSet = unite(oldPicks, newPicks)
    const taskOrderServices = JSON.stringify(makeEditServicesDtos(picksSet))
    try {
      updateRes = await adminApi.saveTaskOrderSvcDetailsDTO(taskOrderServices)
    } catch (err) {
      // eslint-disable-next-line no-console
      updateErr = parseErr(err)
      alertMap.value.set('alert', {
        ...alertTable.danger,
        title: 'Task Order Services Failed',
        message: updateErr?.message
      })
      signal.value = ['scrollTo', { selector: '.page-container', signalId: randomId() }]
      return
    }
  }
  const { agencyIdentifierEncoded } = props.query ?? {}
  const { taskOrderNumber, originalTaskOrderId, taskOrderId } = selectedTaskOrderObj.value
  let state = 'success'
  let message = `The Task Order was updated successfully.`

  if (kpisFormState.value !== 'unchanged') {
    const kpisDtos = makeKpis({
      taskOrderNumber,
      taskOrderId: originalTaskOrderId ?? taskOrderId,
      kpiMode: 'edit'
    })

    const [_kpisRes, _kpisErr] = await sendKpis(kpisDtos)

    if (_kpisErr) {
      state = 'warning'
      message += ` However, there was a problem with Kpis. \n ` + _kpisErr?.message
    }
  }

  let newModTaskOrderId: undefined | string | number
  {
    const putErrors = []
    const postErrors = []
    const { posts, puts } = makeModsPayload() ?? {}
    for await (const row of puts ?? []) {
      const putPayload = makePutModPayload(row, selectedTaskOrderObj.value)
      const [_putOk, putErr] = await putMod(putPayload)
      if (putErr) {
        putErrors.push(putErr)
      }
    }
    if (!putErrors.length) {
      for await (const row of posts ?? []) {
        const foundSameModNumber = await doesModExist(
          row.modNumber,
          selectedTaskOrderObj.value.taskOrderNumber
        )
        if (foundSameModNumber) {
          postErrors.push(`Duplicate MOD Number.`)
          continue
        }
        const postPayload = makePostModPayload(row, selectedTaskOrderObj.value)
        const [postOk, postErr] = await postMod(postPayload)
        if (postErr) {
          postErrors.push(postErr)
          continue
        }
        const firstRow = postOk?.results?.[0] ?? {}
        newModTaskOrderId = firstRow?.taskOrderId
      }
    }
    const modErrors = [...putErrors, ...postErrors]
    if (modErrors.length) {
      if (state === 'success') {
        if (modErrors.length === 1) {
          message += ` However, there was a problem with the MODS.`
        }
        if (modErrors.length > 1) {
          message += ` However, there were some problems with the MODS.`
        }
      }
      state = 'warning'
      message += `\n` + modErrors.map((e) => String(e)).join('\n')
      const lastChar = message.slice(-1)
      if (lastChar !== '.') {
        message += '.'
      }
    }
  }

  const query = {
    taskOrderNumber: taskOrderNumber,
    originalTaskOrderId,
    taskOrderId: newModTaskOrderId ?? taskOrderId,
    agencyIdentifierEncoded,
    alert: JSON.stringify({
      message,
      state
    })
  }
  if (!newModTaskOrderId) {
    signal.value = ['reset', { signalId: randomId() }]
  }
  router.push({ name: 'EditTaskOrder', query })
}
function formToRows(form: any, key: string | undefined = undefined, formats: any = {}): Row[] {
  const rows: Row[] = []
  for (const rowKey in form) {
    const inputs = form[rowKey]
    if (inputs?._deleted?.value) {
      continue
    }
    const row: Row = {}
    for (const field in inputs) {
      const { value } = inputs[field]
      row[field] = value
      if (field === key) {
        if (String(row[field]).indexOf('_new-') === 0) {
          row[field] = parseInt(row[field].replace('_new-', '-'))
        }
      }
      if (typeof formats[field] === 'function') {
        row[field] = formats[field](row[field])
      }
    }
    rows.push(row)
  }
  return rows
}
function formatRow(row: Row, formats: any = {}): Row {
  for (const field in row) {
    if (typeof formats[field] === 'function') {
      row[field] = formats[field](row[field])
    }
  }
  return row
}
function makePutModPayload(row: any, { taskOrderId }: any): any {
  const payload = {
    taskOrderId: Number(taskOrderId),
    pramataId: String(row.pramataId),
    taskOrderModIterationId: Number(row.taskOrderModIterationId),
    iterationNumber: row.iterationNumber,
    modNumber: String(row.modNumber),
    modDescription: row.modDescription,
    iterationStartDate: moment(row.iterationStartDate).format('YYYY-MM-DD'),
    iterationEndDate: row.iterationEndDate
      ? moment(row.iterationEndDate).format('YYYY-MM-DD')
      : null,
    modApprovedDate: moment(row.modApprovedDate).format('YYYY-MM-DD'),
    createdOn: moment(row.createdOn).format('YYYY-MM-DD'),
    createdBy: Number(row.createdBy),
    updatedOn: row.updatedOn ? moment(row.updatedOn).format('YYYY-MM-DD') : row.updatedOn,
    updatedBy: Number(row.updatedBy),
    selectedIterationNumber: row.selectedIterationNumber || null,
    status: row.status || 'ACTIVE'
  }
  return payload
}
function makePostModPayload(row: any, { taskOrderNumber }: any): any {
  const payload = {
    modNumber: row.modNumber,
    modApprovedDate: moment(row.modApprovedDate).format('MM/DD/YYYY'),
    pramataId: row.pramataId,
    description: row.modDescription,
    startDateOfIteration: moment(row.iterationStartDate).format('MM/DD/YYYY'),
    taskOrderNumber
  }
  return payload
}
async function putMod(putPayload: any): Promise<Pair> {
  try {
    const putResponse: any = await adminApi.editMod(putPayload)
    if (putResponse.error) {
      return [null, putResponse.error]
    }
    return [putResponse, null]
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error(error)
    return [null, error]
  }
}
async function postMod(postPayload: any): Promise<Pair> {
  try {
    const postResponse: any = await adminApi.addNewMod(postPayload)
    if (postResponse.error) {
      return [null, postResponse.error]
    }
    return [postResponse, null]
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error(error)
    return [null, error]
  }
}
async function doesModExist(modNumber: string, taskOrderNumber: any): Promise<boolean | undefined> {
  try {
    const response: any = await adminApi.checkForModExists(modNumber, taskOrderNumber)
    return response?.results?.[0]
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err)
  }
}
function transformFinancialData(data: any, deletedData: any): any[] {
  const updates: any[] = []

  for (const financial of deletedData.value) {
    const dto: any = {}
    if (String(financial.taskOrderFinancialId?.value).includes('_new-')) {
      continue
    }
    for (const field in financial) {
      const value = financial[field]?.value
      dto[field] = ['performanceBeginDate', 'performanceEndDate'].includes(field)
        ? value
          ? fromMMDDYYYY(value)
          : null
        : value
    }
    dto.taskOrderFinancialId = dto.taskOrderFinancialId || 0
    dto.deleted = true
    updates.push(dto)
  }
  for (const rowKey in data) {
    const inputs = financialsForm.value[rowKey] ?? {}
    if (!Object.keys(inputs).length) {
      continue
    }
    if (inputs._deleted?.value) {
      continue
    }
    const dto: any = {}
    const rowChanges = Object.fromEntries(data[rowKey])
    let changesCount = 0
    for (const field in inputs) {
      if (
        (rowChanges[field] && rowChanges[field].state !== 'unchanged') ||
        rowKey.includes('_new')
      ) {
        changesCount++
      }
      const value = inputs[field]?.value
      dto[field] = ['performanceBeginDate', 'performanceEndDate'].includes(field)
        ? value
          ? fromMMDDYYYY(value)
          : null
        : value
    }
    if (changesCount === 0) {
      continue
    }
    dto.taskOrderFinancialId = rowKey.includes('_new') ? 0 : parseInt(rowKey.replace('_new', ''))
    updates.push(dto)
  }

  return updates
}
function transformOfficialData(data: any, deletedData: any): any[] {
  const updates: any[] = []

  for (const official of deletedData.value) {
    const dto: any = {}
    if (String(official.taskOrderOfficialId?.value).includes('_new-')) {
      continue
    }
    for (const field in official) {
      const value = official[field]?.value
      dto[field] = ['startDate', 'stopDate'].includes(field)
        ? value
          ? fromMMDDYYYY(value)
          : null
        : value
    }
    dto.taskOrderOfficialId = dto.taskOrderOfficialId || 0
    dto.deleted = true
    dto.firstName = 'deleted'
    updates.push(dto)
  }
  for (const rowKey in data) {
    const inputs = officialsForm.value[rowKey] ?? {}
    if (!Object.keys(inputs).length) {
      continue
    }
    if (inputs._deleted?.value) {
      continue
    }
    const dto: any = {}
    const rowChanges = Object.fromEntries(data[rowKey])
    let changesCount = 0
    for (const field in inputs) {
      if (
        (rowChanges[field] && rowChanges[field].state !== 'unchanged') ||
        rowKey.includes('_new')
      ) {
        changesCount++
      }
      const value = inputs[field]?.value
      dto[field] = ['startDate', 'stopDate'].includes(field)
        ? value
          ? fromMMDDYYYY(value)
          : null
        : value
    }
    if (changesCount === 0) {
      continue
    }
    dto.taskOrderOfficialId = rowKey.includes('_new') ? 0 : parseInt(rowKey.replace('_new', ''))
    updates.push(dto)
  }
  return updates
}
function getChangesCount(data: any): number {
  let changesCount = 0
  const entries: Entries = Object.entries(data)
  for (const [rowKey, rowChanges] of entries) {
    for (const [_field, change] of rowChanges) {
      if (change?.state !== 'unchanged' || rowKey.includes('_new')) {
        changesCount++
      }
    }
  }
  return changesCount
}
</script>

<template>
  <button class="chi-button -mr--2" @click="onReset()">
    {{ pageMode === 'create' ? 'Discard' : 'Reset' }}
  </button>
  <Tooltip :content="submitButton.tooltip" placement="top">
    <button
      class="chi-button"
      :class="[
        submitButton.class,
        { '-disabled': submitButton.state === 'unchanged' || submitting }
      ]"
      aria-label="Submit Form"
      @click="onSubmit()"
    >
      <div class="chi-button__content">
        <i
          v-if="submitButton.icon"
          :class="['chi-icon icon-' + submitButton.icon, submitButton.class]"
          aria-hidden="true"
        ></i>
        <span>{{ submitButton.label }}</span>
        <svg
          v-if="submitting"
          class="chi-spinner -icon--primary"
          viewBox="0 0 66 66"
          aria-hidden="true"
        >
          <circle class="path" cx="33" cy="33" r="30" fill="none" stroke-width="6"></circle>
        </svg>
      </div>
    </button>
  </Tooltip>
</template>
