import { computed, ref } from 'vue'
import {
  toBeOptional,
  toNotBeEmpty,
  toBeANumber,
  InputType,
  toBeAValidOption,
  toBeAnEmail
} from '@centurylinkfederal/eis-vue'
import USState from '../CreateUser/constants/USState'

export function useColumnsStore(): any {
  const states = ref<Array<any>>(
    USState.reduce(
      (all: Array<any[]>, state) => {
        all.push([state.code, state.name])
        return all
      },
      [[null, '-Select-']]
    )
  )
  const userDetailsCols = computed(() => [
    {
      field: 'userName',
      label: 'User Name',
      labelInfoText: 'This name used to login to EIS Portal',
      labelInfoClass: '-italicised',
      input: {
        type: 'text',
        maxLength: 50,
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'firstName',
      label: 'First Name',
      input: {
        type: 'text',
        maxLength: 50,
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'lastName',
      label: 'Last Name',
      input: {
        type: 'text',
        maxLength: 50,
        required: true,
        tests: [toNotBeEmpty]
      }
    }
  ])

  const userAddressLineInfo = computed(() => [
    {
      field: 'address1',
      label: 'Address 1',
      classCol: '-w-xl-2h--3 -w-md--6 -w-sm--12',
      input: {
        type: 'text',
        maxLength: 50,
        tests: [toBeOptional]
      }
    },
    {
      field: 'address2',
      label: 'Address 2',
      classCol: '-w-xl-2h--3 -w-md--6 -w-sm--12',
      input: {
        type: 'text',
        maxLength: 50,
        tests: [toBeOptional]
      }
    }
  ])

  const userCityStateDetails = computed(() => [
    {
      field: 'city',
      label: 'City',
      input: {
        type: 'text',
        maxLength: 50,
        tests: [toBeOptional]
      }
    },
    {
      field: 'stateCode',
      label: 'State',
      input: {
        type: 'select',
        value: null,
        options: states.value,
        tests: [(input: InputType) => toBeAValidOption(input, USState, ['code', 'name'])]
      }
    },
    {
      field: 'zip',
      label: 'ZIP Code',
      input: {
        type: 'text',
        maxLength: 10,
        tests: [toBeOptional, toBeANumber]
      }
    }
  ])

  const userContactDetails = computed(() => [
    {
      field: 'mobilePhone',
      label: 'Mobile Phone Number',
      input: {
        type: 'phone',
        maxLength: 20,
        inputMask: true,
        placeholder: '(212) 897-1964',
        tests: []
      }
    },
    {
      field: 'telephone',
      label: 'Work Phone Number',
      input: {
        type: 'phone',
        maxLength: 20,
        inputMask: true,
        placeholder: '(212) 897-1964',
        tests: []
      }
    },
    {
      field: 'workPhoneExtension',
      label: 'Work Phone Extension',
      input: {
        type: 'text',
        maxLength: 5,
        tests: [toBeOptional, toBeANumber]
      }
    }
  ])

  const userRemainderDetailsCols = computed(() => [
    {
      field: 'email',
      label: 'Email',
      input: {
        type: 'text',
        maxLength: 50,
        mode: 'email',
        required: true,
        tests: [toNotBeEmpty, toBeAnEmail]
      }
    },
    {
      field: 'preferredContactMethod',
      label: 'Media Preference (Optional)',
      input: {
        type: 'text',
        maxLength: 50,
        tests: [toBeOptional]
      }
    }
  ])

  const ruleColumn = computed(() => [
    {
      field: 'acceptanceDate',
      label: 'Rules of Behavior and Acceptance:',
      input: {
        type: 'text',
        disabled: true,
        tests: [toBeOptional]
      }
    }
  ])

  const notesColumn = computed(() => [
    {
      field: 'notes',
      label: 'Notes (Optional)',
      classCol: '-w-xl-2h--3 -w-lg--9 -w-md--6 -w--6 -w-sm--12',
      input: {
        type: 'textarea',
        maxLength: 250,
        tests: [toBeOptional]
      }
    }
  ])

  return {
    userDetailsCols,
    userAddressLineInfo,
    userCityStateDetails,
    userContactDetails,
    userRemainderDetailsCols,
    notesColumn,
    ruleColumn
  }
}
