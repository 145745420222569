<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useToAdminAPI } from '../composables/to-admin/api'
import { useTOAdminStore } from '../store/to-admin'

const toAdminStore = useTOAdminStore()
const { selectedTO } = storeToRefs(toAdminStore)

onMounted(async () => {
  await toAdminStore.fetchAgencies()
})

const route = useRoute()

const menu = ref<any>([
  { name: 'Create Task Order', path: '/toadmincopy/create' },
  { name: 'Edit Task Order', path: '/toadmincopy/edit' },
  { name: 'Export Details Sets', path: '/toadmincopy/export' }
])

function activeClass(path: string) {
  return route.path === path ? '-active' : ''
}

watch(
  () => route.path,
  () => {
    selectedTO.value = null
  }
)

function reset() {}
</script>
<template>
  <div class="-m--2" id="task-order-admin-top">
    <div class="chi-card -widget">
      <div class="chi-card__header header">
        <div class="chi-card__title">
          <h3>Task Order Administration</h3>
        </div>
        <ul class="chi-tabs">
          <li v-for="item in menu" :class="activeClass(item.path)" :key="item.name">
            <router-link
              :to="item.path"
              :active-class="activeClass(item.path)"
              @click.native="reset"
            >
              {{ item.name }}
            </router-link>
          </li>
        </ul>
      </div>
      <!-- <div class="a-card__content" v-if="showFilter">
        <TOFilter />
      </div> -->
    </div>
    <router-view></router-view>
  </div>
</template>
<style scoped>
.header {
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem !important;
}
</style>
