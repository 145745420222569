<script setup lang="ts">
import { defineProps, computed, withDefaults } from 'vue'

const props = withDefaults(
  defineProps<{
    show?: boolean
    center?: boolean
    inverse?: boolean
    size?: string
  }>(),
  {
    show: true,
    center: true,
    inverse: false,
    size: 'md'
  }
)

const isShow = computed(() => props.show)

const backdropClasses = computed(() => ({
  '-center': props.center,
  '-inverse': props.inverse
}))
</script>

<template>
  <div v-if="isShow" class="chi-backdrop" :class="backdropClasses">
    <div class="chi-backdrop__wrapper">
      <svg :class="`chi-spinner -icon--primary -${props.size}`" viewBox="0 0 66 66">
        <title>Loading</title>
        <circle class="path" cx="33" cy="33" r="30" fill="none" stroke-width="6"></circle>
      </svg>
    </div>
  </div>
</template>
