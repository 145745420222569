<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useDbasStore } from './dbasStore'
const dbasStore = useDbasStore()
const { successAlerts, errorAlerts } = storeToRefs(dbasStore)
</script>
<template>
  <div
    v-for="(errorAlert, index) in errorAlerts"
    :key="index"
    class="chi-alert -danger"
    role="alert"
  >
    <i class="chi-alert__icon chi-icon icon-circle-x" aria-hidden="true"></i>
    <div class="chi-alert__content">
      <p class="chi-alert__text">{{ errorAlert }}</p>
    </div>
  </div>
  <div
    v-for="(successAlert, index) in successAlerts"
    :key="index"
    class="chi-alert -success"
    role="alert"
  >
    <i class="chi-alert__icon chi-icon icon-circle-check" aria-hidden="true"></i>
    <div class="chi-alert__content">
      <p class="chi-alert__text">{{ successAlert }}</p>
    </div>
  </div>
</template>
