<script lang="ts" setup>
import ViewKPI from './ViewKPI.vue'

export interface Props {
  selectedOnChange: any
  createKPI: boolean
  taskOrderNumber: any
  taskOrderId: any
  tabName: string
  billingEditedList: any
  createCustomKPIList: any
}
const props = withDefaults(defineProps<Props>(), {
  selectedOnChange: undefined,
  createKPI: undefined,
  taskOrderNumber: undefined,
  taskOrderId: undefined,
  tabName: undefined,
  billingEditedList: undefined,
  createCustomKPIList: undefined
})
const emit = defineEmits(['addCustomKpiOut'])
const kpiGrp = 'OUT - Service Outage'
function addCustomKPI(updatekpi: any) {
  emit('addCustomKpiOut', updatekpi, kpiGrp)
  console.log(props.createCustomKPIList)
}
</script>
<template>
  <ViewKPI
    :billingEditedList="billingEditedList"
    :selectedOnChange="selectedOnChange"
    tabName="outage"
    :taskOrderNumber="taskOrderNumber"
    :taskOrderId="taskOrderId"
    :kpiGrp="kpiGrp"
    :createKPI="createKPI"
    :createCustomKPIList="createCustomKPIList"
    :createKPIList="''"
    :perfStdThreshold="''"
    v-on:addCustomKpi="addCustomKPI"
  />
</template>
