<script lang="ts" setup>
import { defineProps, ref } from 'vue'

interface IProps {
  title: string
  dataFields: Array<any>
  headerDetails: Array<any>
  customValidation?: (
    headers: string[],
    importedData: any[]
  ) => { isValid: boolean; errorMsg: string }
}
const props = defineProps<IProps>()
const emits = defineEmits(['importContent', 'closeModal', 'error'])

const fileName = ref<string>('')
const errorMsg = ref<string>('')
const fileContent = ref<string>('')

// Action buttons for Modal
const actionButtons = [
  { text: 'Cancel', signal: 0 },
  { text: 'Import', signal: 1, class: '-primary' }
]

function handleButtonClick(signal: number) {
  if (signal === 1) {
    importCSV()
  } else if (signal === 0) {
    closeModal()
  }
}

function chooseFiles() {
  const uploadElement = document.getElementById('fileUploader')
  uploadElement?.click()
}

function handleFile(e: any) {
  const files = e.target?.files as FileList
  errorMsg.value = ''
  const file = files.item(0)
  if (file) {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = (e) => {
      fileName.value = file.name
      fileContent.value = e.target?.result as string
    }
  }
}

function importCSV() {
  errorMsg.value = ''
  if (!fileName.value) {
    errorMsg.value = `Please select a ${props.title} file to import`
    emits('error', errorMsg.value)
    return
  }
  if (!fileContent.value) return

  const headers = fileContent.value
    .split('\n')[0]
    .split(',')
    .map((header) => header.trim())

  if (props.customValidation) {
    const validationResult = props.customValidation(headers, [])
    if (!validationResult.isValid) {
      errorMsg.value = validationResult.errorMsg
      emits('error', validationResult.errorMsg)
      return
    }
  }

  const importedData = fileContent.value
    .split('\n')
    .slice(1)
    .filter((data: string) => !!data)
    .map((item: string) =>
      item.split(',').reduce((all: any, item: string, i: number) => {
        const key = props.dataFields[i]
        if (key) all[key] = item.trim()
        return all
      }, {})
    )

  emits('importContent', { isValid: true, contents: importedData })
  fileName.value = ''
  emits('closeModal')
}

function closeModal() {
  emits('closeModal')
}
</script>
<template>
  <Modal
    :center="true"
    :title="title"
    details=" "
    :buttons="actionButtons"
    @onDismissModal="closeModal"
    @onButtonClick="handleButtonClick"
  >
    <div class="-d--flex">
      <span for="import file" class="-text--bold -text -pl--1">Locate file for import</span>
      <input
        id="fileUploader"
        type="file"
        class="-primary -outline -mr--2"
        hidden
        @change="handleFile($event)"
        accept=".csv"
      />
      <button
        class="chi-button -sm -primary -outline -ml--3 -mr--2"
        @click="chooseFiles()"
        style="border-radius: 4px"
      >
        Browse...
      </button>
      <span>{{ fileName }}</span>
    </div>
    <div class="-text--danger">
      {{ errorMsg }}
    </div>
  </Modal>
</template>
