flushStores
<script lang="ts" setup>
import { onMounted, ref, watch, computed, toRaw, nextTick } from 'vue'
import { useRoute, useRouter, onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useScroll } from '@vueuse/core'
import { getStore } from './store'
import { useTaskOrder } from '../exportDetailsSets/taskOrderStore'
import { useColumns as useDetailsColumns } from './detailsColumns'
import { useStore as useModsStore } from './modsStore'
import { useStore as useContactsStore } from './contactsStore'
import { useStore as useFinancialsStore } from './financialsStore'
import { useStore as useKpisStore } from './kpisStore'
import { useStore as useOfficialsStore } from './officialsStore'
import { useStore as useServicesStore } from './servicesStore'
import { useColumns as useServicesColumns } from './servicesColumns'
import { useStore as useProfilesStore } from './kpisStore'
import TaskOrderMustBeSelected from './TaskOrderMustBeSelected.vue'
import Epanel from '../common/Epanel.vue'
import DataCard from '../common/DataCard.vue'
import EmptyData from '../common/EmptyData.vue'
import DataTableExpanded from '../common/DataTableExpanded.vue'
import TaskOrder from '../exportDetailsSets/TaskOrder.vue'
import Details from './Details.vue'
import Mods from './Mods.vue'
import Financials from './Financials.vue'
import Contacts from './Contacts.vue'
import Officials from './Officials.vue'
import Services from './Services.vue'
import Kpis from './Kpis.vue'
import Submit from './Submit.vue'
import { doScrollToElement } from '../../utils/ui'
import { encodeAgencyIdentifier } from '../../utils/to-admin.utils'
import {
  Alert,
  slice,
  Modal,
  DataTable,
  ifParse,
  Signal,
  randomId
} from '@centurylinkfederal/eis-vue'
import { alertTable } from '../../composables/alerts'
import PickerBox from '../common/PickerBox.vue'
import ActivityLog from './ActivityLog.vue'
export interface Props {
  pageMode: 'create' | 'edit'
}
const props = withDefaults(defineProps<Props>(), {
  pageMode: 'create'
})
const route = useRoute()
const router = useRouter()
const pageMode = ref(props.pageMode)
const store = getStore(pageMode.value)()
const {
  activePanel,
  disabledPanels,
  billingNotifications,
  alertMap,
  alertEntries,
  modalMap,
  modalEntries,
  signal,
  summary,
  form,
  formChanges,
  resetSeq,
  loadSeq,
  panelStates,
  selectedTaskOrderObj,
  selectedTaskOrder,
  taskOrderDetailsRes
} = storeToRefs(store)
const taskOrderStore = useTaskOrder()
const { isMounted: isTaskOrderMounted } = storeToRefs(taskOrderStore)
const { onInit: onTaskOrderInit } = taskOrderStore

const detailsColumnsStore = useDetailsColumns()
const { agencyMap, columns: detailsColumns } = storeToRefs(detailsColumnsStore)
const modsStore = useModsStore()
const { form: modsForm, formChanges: modsFormChanges, rows: modsRows } = storeToRefs(modsStore)
const contactsStore = useContactsStore()
const { form: contactsForm, formChanges: contactsFormChanges } = storeToRefs(contactsStore)
const financialsStore = useFinancialsStore()
const { form: financialsForm, formChanges: financialsFormChanges } = storeToRefs(financialsStore)
const officialsStore = useOfficialsStore()
const { form: officialsForm, formChanges: officialsFormChanges } = storeToRefs(officialsStore)
const servicesStore = useServicesStore()
const {
  form: servicesForm,
  formChanges: servicesFormChanges,
  formState,
  picks: servicesPicks,
  quirks: servicesQuirks
} = storeToRefs(servicesStore)
const servicesColumnsStore = useServicesColumns()
const { serviceMap } = storeToRefs(servicesColumnsStore)
const kpisStore = useKpisStore()
const profilesStore = useProfilesStore()
const { form: profilesForm, formChanges: profilesFormChanges } = storeToRefs(profilesStore)

const taskOrderNumber = ref<string | undefined>()
const epanelSettings = ref({ classFooter: '-pr--7', adHoc: true, noFooter: true })
const pageSeq = ref(0)
const documentRef = ref<Document | null>(null)
const { arrivedState: bottomArrivedState } = useScroll(documentRef, {
  throttle: 500,
  offset: { bottom: 150 }
})

const inEditModeWithNoTaskOrder = computed(
  () => pageMode.value === 'edit' && !selectedTaskOrder.value
)
const panels = computed(() => {
  const panelSet = new Set([
    'search',
    'details',
    'mods',
    'billing-contacts',
    'financials',
    'officials',
    'services',
    'kpis',
    'activitylog',
    'activitylogKpis'
  ])
  if (pageMode.value === 'create') {
    panelSet.delete('search')
    panelSet.delete('mods')
    panelSet.delete('activitylog')
    panelSet.delete('activitylogKpis')
  }
  return Array.from(panelSet)
})
const financialsSize = computed(
  () =>
    Object.keys(financialsForm.value).filter(
      (key: string) => !financialsForm.value[key]?._deleted?.value
    ).length
)
const officialsSize = computed(
  () =>
    Object.keys(officialsForm.value).filter(
      (key: string) => !officialsForm.value[key]?._deleted?.value
    ).length
)
watch(() => signal.value, onSignal, { deep: true })
const routeNameModes: any = {
  CreateTaskOrder: 'create',
  EditTaskOrder: 'edit'
}
watch(
  () => props.pageMode,
  (newMode) => {
    pageMode.value = newMode
  }
)
watch(
  () => taskOrderNumber.value,
  async (newValue) => {
    if (!newValue) {
      panelStates.value = {
        ...panelStates.value,
        search: { state: 'unchanged', error: null }
      }
    } else {
      panelStates.value = {
        ...panelStates.value,
        search: { state: 'primary', error: null },
        mods: { state: 'unchanged', error: null },
        activitylog: { state: 'unchanged', error: null },
        activitylogKpis: { state: 'unchanged', error: null }
      }
    }
  }
)
watch(
  () => billingNotifications.value,
  (newValue) => {
    if (newValue === 'Y') {
      disabledPanels.value.delete('billing-contacts')
    } else {
      disabledPanels.value.add('billing-contacts')
      contactsStore.flush()
    }
  }
)
watch(
  () => form?.value?.details?.billingNotifications?.value,
  (newValue) => {
    if (newValue === 'Y') {
      disabledPanels.value.delete('billing-contacts')
    } else {
      disabledPanels.value.add('billing-contacts')
      contactsStore.flush()
    }
  },
  { deep: true }
)
watch(
  () => summary.value,
  (newValue) => {
    if (newValue.servicesCount > 0) {
      disabledPanels.value.delete('kpis')
    } else {
      disabledPanels.value.add('kpis')
    }
  }
)
watch(
  () => formChanges.value,
  (newChanges) => {
    panelStates.value = { ...panelStates.value, details: makePanelState(newChanges, form.value) }
    onContactsFormHook()
  }
)
watch(
  () => modsFormChanges.value,
  (newChanges) => {
    panelStates.value = {
      ...panelStates.value,
      mods: makePanelState(newChanges, modsForm.value)
    }
  }
)
watch(
  () => contactsFormChanges.value,
  (newChanges) => {
    panelStates.value = {
      ...panelStates.value,
      ['billing-contacts']: makePanelState(newChanges, contactsForm.value)
    }
    onContactsFormHook()
  }
)
watch(
  () => financialsFormChanges.value,
  (newChanges) =>
    (panelStates.value = {
      ...panelStates.value,
      financials: makePanelState(newChanges, financialsForm.value)
    })
)
watch(
  () => officialsFormChanges.value,
  (newChanges) =>
    (panelStates.value = {
      ...panelStates.value,
      officials: makePanelState(newChanges, officialsForm.value)
    })
)
watch(
  () => servicesFormChanges.value,
  (newChanges) => {
    if (formState.value !== 'unchanged') {
      panelStates.value = {
        ...panelStates.value,
        services: makePanelState(newChanges, servicesForm.value)
      }
    }
    if (!['danger'].includes(panelStates.value.services?.state)) {
      const servicesState = makeServicesState()
      if (!['unchanged'].includes(servicesState?.state)) {
        panelStates.value = {
          ...panelStates.value,
          services: servicesState
        }
      }
    }
  }
)
watch(
  () => servicesQuirks.value,
  () => {
    const servicesState = makeServicesState()
    panelStates.value = {
      ...panelStates.value,
      services: servicesState
    }
    if (formState.value !== 'unchanged' && !['danger'].includes(servicesState?.state)) {
      panelStates.value = {
        ...panelStates.value,
        services: makePanelState(servicesFormChanges.value, servicesForm.value)
      }
    }
  },
  { deep: true }
)
watch(
  () => profilesFormChanges.value,
  (newChanges) => {
    panelStates.value = {
      ...panelStates.value,
      kpis: makePanelState(newChanges, profilesForm.value)
    }
  }
)
function nscsSize(nscMap: Map<string | number, any> | undefined) {
  const nscs = Array.from((nscMap ?? new Map()).values()).filter((e: any) => !e._deleted)
  return nscs.length
}
function makeServicesState(): any {
  let state = 'unchanged',
    error
  const picksArray = Array.from(servicesPicks.value)
  for (const serviceCode of picksArray) {
    const serviceObj = serviceMap.value.get(serviceCode)
    if (String(serviceObj?.cbsaBased).includes('Yes')) {
      const quirk = servicesQuirks.value.get(serviceCode)
      if (!quirk?.jurisdictions?.size && !nscsSize(quirk?.nscs)) {
        state = 'danger'
        error = {
          message: `Missing Country/Jurisdiction/NSC from ${serviceCode} - ${serviceObj?.serviceDesc}`
        }
        break
      }
    }
  }
  return {
    state,
    error
  }
}
async function onSignal([signalKey, payload]: Signal): Promise<void> {
  switch (signalKey) {
    case 'scrollTo': {
      doScrollToElement(
        payload?.selector ?? '.page-container',
        payload?.opts ?? { wait: 50, left: 0 }
      )
      break
    }
    case 'reset': {
      flushStores()
      resetSeq.value = parseInt(randomId())
      doScrollToElement('.page-container', { wait: 50, left: 0 })
      break
    }
    case 'setPanelState': {
      const { panelKey, stateObj } = payload ?? {}
      if (!panelKey || !stateObj) {
        break
      }
      panelStates.value = { ...panelStates.value, [panelKey]: stateObj }
      break
    }
    case 'selectTaskOrder': {
      onSelectTaskOrder(payload)
      break
    }
  }
}
function onContactsFormHook(): void {
  if (form.value?.details?.billingNotifications?.value === 'Y') {
    if (Object.keys(contactsForm.value).length === 0) {
      if (Object.keys(formChanges.value).length > 0) {
        panelStates.value = {
          ...panelStates.value,
          ['billing-contacts']: {
            state: 'warning',
            error: {
              message: `There are no Billing Notifications Contacts.`
            }
          }
        }
      }
    }
  } else {
    panelStates.value = {
      ...panelStates.value,
      ['billing-contacts']: {
        state: 'unchanged',
        error: undefined
      }
    }
  }
}
function makePanelState(changes: any, formValue: any): any {
  let formState = 'unchanged'
  let inputError
  for (const key in changes) {
    const inputs = formValue[key]
    if (inputs._deleted?.value) {
      continue
    }
    for (const [field, change] of changes[key]) {
      formState = change.state && change.state !== 'unchanged' ? change.state : formState
      if (formState === 'danger') {
        inputError = inputs?.[field]?.error
        break
      }
    }
    if (formState === 'danger') {
      break
    }
  }
  if (pageMode.value === 'create' && !['danger'].includes(formState)) {
    for (const key in formValue) {
      const inputs = formValue[key] ?? {}
      if (inputs._deleted?.value) {
        continue
      }
      for (const field in inputs) {
        const { required, value, state } = inputs[field]
        if (required && !value && !['unchanged'].includes(state)) {
          formState = 'draft'
          break
        }
      }
      if (['draft'].includes(formState)) {
        break
      }
    }
  }
  return {
    state: formState,
    error: inputError
  }
}
function getNextPanel(currentPanel: string | undefined, sign = 1, panic = 0): string | undefined {
  if (panic > 10) {
    return
  }
  const index = panels.value.indexOf(String(currentPanel))
  if (index === -1) {
    return
  }
  const nextIndex = index + sign
  if (nextIndex > panels.value.length - 1 || nextIndex < 0) {
    return
  }
  const nextPanel = panels.value[nextIndex]
  if (disabledPanels.value.has(nextPanel)) {
    return getNextPanel(nextPanel, sign, panic + 1)
  }
  return nextPanel
}
function onAction(action: string, panel: string) {
  switch (action) {
    case 'next':
    case 'previous': {
      const sign = action === 'next' ? 1 : -1
      const nextPanel = getNextPanel(panel, sign)
      if (!nextPanel) {
        break
      }
      activePanel.value = nextPanel
      doScrollToElement('#epanel-' + nextPanel, { wait: 50, left: 0, offsetTop: -40 })
      break
    }
    case 'active': {
      if (disabledPanels.value.has(panel)) {
        return
      }
      activePanel.value = panel
      doScrollToElement('#epanel-' + panel, { wait: 50, left: 0, offsetTop: -40 })
      break
    }
  }
  signal.value = [action, { panel: activePanel.value }]
}

function onModalButtonClick(index: number, modal: any) {
  signal.value = ['onModalButtonClick', { index, modal }]
}
function onModalActionClick(index: number, modal: any) {
  signal.value = ['onModalActionClick', { index, modal }]
}

function onModalPick(value: any, modal: any) {
  signal.value = ['onModalPick', { value, modal }]
}

function onModalInput(value: any, opts: any, modal: any) {
  signal.value = ['onModalInput', { value, opts, modal }]
}
function onModalDismiss(modal: any) {
  signal.value = ['onModalDismiss', { modal }]
}
function doAlert(newAlert: any): void {
  const { title, message, state } = ifParse(newAlert)
  if (!title && !message && !state) {
    return
  }
  const alertBubble = alertTable[state] ?? alertTable.muted
  alertMap.value.set('alert', {
    ...alertBubble,
    title,
    message
  })
}
function flushStores(): void {
  modsStore.flush()
  contactsStore.flush()
  financialsStore.flush()
  officialsStore.flush()
  servicesStore.flush()
  kpisStore.flush()
}
function onSelectTaskOrder({ taskOrderObj }: any): void {
  if (taskOrderObj?.taskOrderNumber) {
    const agencyIdentifierEncoded = taskOrderObj.agencyIdentifierEncoded
      ? taskOrderObj.agencyIdentifierEncoded
      : encodeAgencyIdentifier(taskOrderObj.agencyIdentifier)
    const query = {
      ...slice(taskOrderObj, ['originalTaskOrderId', 'taskOrderId', 'taskOrderNumber']),
      agencyIdentifierEncoded
    }
    const routeTo = {
      name: router.currentRoute.value.name,
      query
    }
    router.push(routeTo)
  } else {
    const routeTo = {
      name: router.currentRoute.value.name
    }
    router.push(routeTo)
  }
}
async function routeHook(_to: any, _from: any, next: Function): Promise<void> {
  if (
    Object.keys(routeNameModes).includes(String(_to?.name)) ||
    Object.keys(routeNameModes).includes(String(_from?.name))
  ) {
    pageMode.value = routeNameModes[String(_to?.name)]
    if (_to.query.taskOrderNumber) {
      taskOrderNumber.value = String(_to.query?.taskOrderNumber)
      selectedTaskOrderObj.value = { ..._to.query }
    } else {
      selectedTaskOrderObj.value = null
      taskOrderNumber.value = ''
    }
    if (_to.query.alert) {
      doAlert(ifParse(_to.query.alert))
      signal.value = ['scrollTo', { wait: 200, selector: '.page-container' }]
    } else {
      alertMap.value.clear()
    }
    flushStores()
    resetSeq.value = parseInt(randomId())
    if (isTaskOrderMounted.value) {
      await onTaskOrderInit(true)
    }
    onAction('active', String(panels.value[0]))
    disabledPanels.value.add('kpis')
    disabledPanels.value.add('billing-contacts')
    pageSeq.value = parseInt(randomId())
  }
  next()
}
async function cacheHook(_to: any, _from: any, next: Function): Promise<void> {
  if (
    !Object.keys(routeNameModes).includes(String(_to?.name)) &&
    Object.keys(routeNameModes).includes(String(_from?.name))
  ) {
    sessionStorage.removeItem('jurisdictionCountries')
    sessionStorage.removeItem('svcLookups')
    sessionStorage.removeItem('RefKpis')
  }
  next()
}
onBeforeRouteUpdate(routeHook)
onBeforeRouteLeave(cacheHook)
onMounted(async () => {
  documentRef.value = window.document
  await routeHook(route, route, () => {})
})
</script>

<template>
  <div class="alerts" :class="{ '-mt--2': alertEntries?.length }">
    <Alert
      v-for="[alertKey, alert] in alertEntries"
      :key="alertKey"
      v-bind="alert"
      @onDismissAlert="alertMap.delete(alertKey)"
    />
  </div>
  <div class="modals">
    <Modal
      v-for="[modalKey, modal] in modalEntries"
      :key="modalKey"
      v-bind="modal"
      @onDismissModal="onModalDismiss(slice(modal, ['key', 'what', 'section', 'profile']))"
      @onButtonClick="
        (index) => onModalButtonClick(index, slice(modal, ['key', 'what', 'section', 'profile']))
      "
      @onActionClick="
        (index) => onModalActionClick(index, slice(modal, ['key', 'what', 'section', 'profile']))
      "
    >
      <PickerBox
        v-if="modal.pickerBox"
        v-bind="modal.pickerBox"
        @onChange="
          (value) => onModalPick(value, slice(modal, ['key', 'what', 'section', 'profile']))
        "
      />
      <DataCard v-for="(dataCard, index) in modal.dataCards ?? []" :key="index" v-bind="dataCard" />
      <DataTable
        v-if="modal.dataTable"
        v-bind="modal.dataTable"
        @onInput="
          (value, opts) =>
            onModalInput(value, opts, slice(modal, ['key', 'what', 'section', 'profile']))
        "
      >
        <template #expanded="{ data }">
          <DataTableExpanded :data="data" />
        </template>
        <template #empty>
          <div class="chi-data-table__row-empty" style="min-height: 260px">
            <EmptyData message="No records found" icon="file-error-outline" />
          </div>
        </template>
      </DataTable>
    </Modal>
  </div>
  <Epanel
    v-if="panels.includes('search')"
    id="epanel-search"
    :number="panels.indexOf('search') + 1 + '.'"
    title="Task Order"
    :state="panelStates.search?.state"
    :active="activePanel === 'search'"
    :settings="{
      ...epanelSettings,
      noPrevious: true,
      noNext: inEditModeWithNoTaskOrder
    }"
    @onAction="(action) => onAction(action, 'search')"
  >
    <template #done>
      <div
        v-if="['unchanged', 'primary', 'success', 'muted'].includes(panelStates.search?.state)"
        :class="['chi-breadcrumb', '-text--' + panelStates.search?.state]"
      >
        {{ selectedTaskOrder }}
      </div>
      <div class="-text--danger" else-if="panelStates.search?.state === 'danger'">
        {{ panelStates.search?.error?.message }}
      </div>
    </template>
    <TaskOrder :taskOrderNumber="taskOrderNumber" @onSelect="onSelectTaskOrder" />
  </Epanel>
  <Epanel
    id="epanel-details"
    :key="selectedTaskOrderObj?.taskOrderId"
    :number="panels.indexOf('details') + 1 + '.'"
    title="Details"
    :state="panelStates.details?.state"
    :active="activePanel === 'details'"
    :disabled="inEditModeWithNoTaskOrder"
    :settings="{ ...epanelSettings, noPrevious: true }"
    @onAction="(action) => onAction(action, 'details')"
  >
    <template #disabled>
      <TaskOrderMustBeSelected v-if="inEditModeWithNoTaskOrder" />
    </template>
    <template #done>
      <div
        v-if="['unchanged', 'primary', 'success', 'muted'].includes(panelStates.details?.state)"
        :class="['-text--' + panelStates.details?.state]"
      >
        <div v-if="!inEditModeWithNoTaskOrder">
          <div class="chi-breadcrumb">
            <ol>
              <li
                v-for="(li, index) in [
                  form.details?.taskOrderNumber?.value,
                  form.details?.toProgramName?.value,
                  form.details?.modNumber?.value
                    ? 'Mod # ' + form.details?.modNumber?.value
                    : undefined
                ].filter((f) => f)"
                :key="index"
                class="chi-breadcrumb__item -text--normal"
                :class="['-text--' + panelStates.details?.state]"
              >
                <span class="-text--truncate">{{ li }}</span>
              </li>
            </ol>
          </div>
          <div v-if="form.details?.agencyId?.value">
            {{ agencyMap.get(form.details?.agencyId?.value) }}
          </div>
          <div
            v-if="pageMode === 'edit' && taskOrderDetailsRes?.taskOrderDto?.isCurrent === 'Y'"
            class="chi-badge -accent-2 -mt--1"
          >
            <span>Active</span>
          </div>
          <div
            v-if="pageMode === 'edit' && taskOrderDetailsRes?.taskOrderDto?.isCurrent !== 'Y'"
            class="chi-badge -muted -mt--1"
          >
            <span>Inactive</span>
          </div>
          <div
            v-if="
              pageMode === 'edit' && !taskOrderDetailsRes?.taskOrderDto?.taskOrderModIterationId
            "
            class="chi-badge -mt--1 -ml--1"
          >
            <span>Original</span>
          </div>
          <div v-if="pageMode === 'create'" class="chi-badge -mt--1">
            <span>Draft</span>
          </div>
        </div>
      </div>
      <div class="-text--danger" else-if="panelStates.details?.state === 'danger'">
        {{ panelStates.details?.error?.message }}
      </div>
    </template>
    <Details :pageMode="pageMode" :query="route.query" />
  </Epanel>
  <Epanel
    v-if="panels.includes('mods')"
    id="epanel-mods"
    :number="panels.indexOf('mods') + 1 + '.'"
    title="Modifications"
    :state="panelStates.mods?.state"
    :active="activePanel === 'mods'"
    :disabled="inEditModeWithNoTaskOrder"
    :settings="{ ...epanelSettings }"
    @onAction="(action) => onAction(action, 'mods')"
  >
    <template #disabled>
      <TaskOrderMustBeSelected v-if="inEditModeWithNoTaskOrder" />
    </template>
    <template #done>
      <div
        v-if="
          ['unchanged', 'primary', 'success', 'muted'].includes(panelStates.mods?.state) &&
          !inEditModeWithNoTaskOrder
        "
        :class="['chi-breadcrumb', '-text--' + panelStates.mods?.state]"
      >
        <div v-if="modsRows?.length">
          <span v-if="modsRows.length < 2"> There are <b>no</b> iteration records. </span>
          <span v-if="modsRows.length === 2"> There is <b>one</b> iteration record. </span>
          <span v-if="modsRows.length > 2">
            There are
            <b>{{ modsRows.length - 1 }}</b>
            iteration records.
          </span>
        </div>
      </div>
      <div class="-text--danger" else-if="panelStates.mods?.state === 'danger'">
        {{ panelStates.mods?.error?.message }}
      </div>
    </template>
    <Mods :pageMode="pageMode" />
  </Epanel>
  <Epanel
    id="epanel-billing-contacts"
    :number="panels.indexOf('billing-contacts') + 1 + '.'"
    title="Billing Notifications Contacts"
    :state="panelStates['billing-contacts']?.state"
    :active="activePanel === 'billing-contacts'"
    :disabled="disabledPanels.has('billing-contacts') || inEditModeWithNoTaskOrder"
    :settings="{ ...epanelSettings }"
    @onAction="(action) => onAction(action, 'billing-contacts')"
  >
    <template #disabled>
      <TaskOrderMustBeSelected v-if="inEditModeWithNoTaskOrder" />
      <div
        v-else-if="
          form?.details?.billingNotifications?.value === 'N' || !Object.keys(contactsForm).length
        "
        class="-text--md -text--muted"
      >
        Enable <b>Billing Notifications</b> to activate this panel.
      </div>
    </template>
    <template #done>
      <div
        v-if="
          ['unchanged', 'primary', 'success', 'muted'].includes(
            panelStates['billing-contacts']?.state
          )
        "
        :class="['chi-breadcrumb', '-text--' + panelStates['billing-contacts']?.state]"
      >
        <div v-if="contactsForm">
          <div v-if="pageMode === 'create'">
            {{
              Object.values(contactsForm)
                .map((inputs: any) =>
                  [
                    inputs?.name?.value ? `${inputs?.name?.value}` : undefined,
                    inputs?.billingEmail?.value ? `<${inputs?.billingEmail?.value}>` : undefined
                  ]
                    .filter((f) => f)
                    .join(' ')
                )
                .filter((f) => f)
                .join(', ')
            }}
          </div>
          <div v-else>
            <span v-if="Object.keys(contactsForm).length === 1">
              There is <b>one</b> Billing Notifications Contacts record.
            </span>
            <span v-if="Object.keys(contactsForm).length > 1">
              There are
              <b>{{ Object.keys(contactsForm).length }}</b>
              Billing Notifications Contacts records.
            </span>
          </div>
        </div>
      </div>
      <div
        else-if="panelStates['billing-contacts']?.state === 'danger'"
        :class="['-text--' + panelStates['billing-contacts']?.state]"
      >
        {{ panelStates['billing-contacts']?.error?.message }}
      </div>
    </template>
    <Contacts :pageMode="pageMode" />
  </Epanel>
  <Epanel
    id="epanel-financials"
    :number="panels.indexOf('financials') + 1 + '.'"
    title="Financials"
    :state="panelStates.financials?.state"
    :active="activePanel === 'financials'"
    :disabled="inEditModeWithNoTaskOrder"
    :settings="{ ...epanelSettings }"
    @onAction="(action) => onAction(action, 'financials')"
  >
    <template #disabled>
      <TaskOrderMustBeSelected v-if="inEditModeWithNoTaskOrder" />
    </template>
    <template #done>
      <div
        :class="['chi-breadcrumb', '-text--' + panelStates.financials?.state]"
        v-if="['unchanged', 'primary', 'success', 'muted'].includes(panelStates.financials?.state)"
      >
        <div v-if="financialsForm">
          <span v-if="financialsSize === 1"> There is <b>one</b> financial record. </span>
          <span v-if="financialsSize > 1">
            There are
            <b>{{ financialsSize }}</b>
            financial records.
          </span>
        </div>
      </div>
      <div class="-text--danger" else-if="panelStates.financials?.state === 'danger'">
        {{ panelStates.financials?.error?.message }}
      </div>
    </template>
    <Financials :pageMode="pageMode" />
  </Epanel>
  <Epanel
    id="epanel-officials"
    :number="panels.indexOf('officials') + 1 + '.'"
    title="Officials"
    :state="panelStates.officials?.state"
    :active="activePanel === 'officials'"
    :disabled="inEditModeWithNoTaskOrder"
    :settings="{ ...epanelSettings }"
    @onAction="(action) => onAction(action, 'officials')"
  >
    <template #disabled>
      <TaskOrderMustBeSelected v-if="inEditModeWithNoTaskOrder" />
    </template>
    <template #done>
      <div
        :class="['chi-breadcrumb', '-text--' + panelStates.officials?.state]"
        v-if="['unchanged', 'primary', 'success', 'muted'].includes(panelStates.officials?.state)"
      >
        <div v-if="pageMode === 'create'">
          <div v-if="officialsForm">
            {{
              Object.values(officialsForm)
                .map((inputs: any) =>
                  [
                    inputs?.firstName?.value ? `${inputs?.firstName?.value}` : undefined,
                    inputs?.lastName?.value ? `${inputs?.lastName?.value}` : undefined,
                    inputs?.workEmail?.value ? `<${inputs?.workEmail?.value}>` : undefined,
                    inputs?.role?.value ? ` - ${inputs?.role?.value}` : undefined
                  ]
                    .filter((f) => f)
                    .join(' ')
                )
                .filter((f) => f)
                .join(', ')
            }}
          </div>
        </div>
        <div v-else>
          <div v-if="officialsForm">
            <span v-if="officialsSize === 1"> There is <b>one</b> officials record. </span>
            <span v-if="officialsSize > 1">
              There are
              <b>{{ officialsSize }}</b>
              officials records.
            </span>
          </div>
        </div>
      </div>
      <div class="-text--danger" else-if="panelStates.officials?.state === 'danger'">
        {{ panelStates.officials?.error?.message }}
      </div>
    </template>
    <Officials :pageMode="pageMode" />
  </Epanel>
  <Epanel
    id="epanel-services"
    :number="panels.indexOf('services') + 1 + '.'"
    title="Services and CLINs Awarded"
    :state="panelStates.services?.state"
    :active="activePanel === 'services'"
    :disabled="inEditModeWithNoTaskOrder"
    :settings="{ ...epanelSettings, noNext: disabledPanels.has('kpis') ? true : false }"
    @onAction="(action) => onAction(action, 'services')"
  >
    <template #disabled>
      <TaskOrderMustBeSelected v-if="inEditModeWithNoTaskOrder" />
    </template>
    <template #done>
      <div
        :class="['chi-breadcrumb', '-text--' + panelStates.services?.state]"
        v-if="['unchanged', 'primary', 'success', 'muted'].includes(panelStates.services?.state)"
      >
        <div v-if="pageMode === 'create'">
          <div v-if="servicesForm">
            {{ servicesForm.summary?.picks?.value.join(', ') }}
          </div>
        </div>
        <div v-else>
          <div v-if="servicesForm">
            <span v-if="servicesForm.summary?.picks?.value?.length === 1">
              There is <b>one</b> services and clins record.
            </span>
            <span v-if="servicesForm.summary?.picks?.value?.length > 1">
              There are
              <b>{{ servicesForm.summary?.picks?.value.length }}</b>
              services and clins records.
            </span>
          </div>
        </div>
      </div>
      <div class="-text--danger" else-if="panelStates.services?.state === 'danger'">
        {{ panelStates.services?.error?.message }}
      </div>
    </template>
    <Services :pageMode="pageMode" />
  </Epanel>
  <Epanel
    id="epanel-kpis"
    :number="panels.indexOf('kpis') + 1 + '.'"
    title="KPIs"
    :active="activePanel === 'kpis'"
    :disabled="disabledPanels.has('kpis') || inEditModeWithNoTaskOrder"
    :state="panelStates.kpis?.state"
    :settings="{
      ...epanelSettings,
      noNext: disabledPanels.has('activitylog') || pageMode === 'create' ? true : false
    }"
    @onAction="(action) => onAction(action, 'kpis')"
  >
    <template #disabled>
      <TaskOrderMustBeSelected v-if="inEditModeWithNoTaskOrder" />

      <div class="-text--md -text--muted" v-else>
        Add Services or Awarded CLINs to activate this panel.
      </div>
    </template>
    <div class="-text--danger" else-if="panelStates.kpis?.state === 'danger'">
      {{ panelStates.kpis?.error?.message }}
    </div>
    <template #done>
      <div
        :class="['chi-breadcrumb', '-text--' + panelStates.kpis?.state]"
        v-if="['unchanged', 'primary', 'success', 'muted'].includes(panelStates.kpis?.state)"
      >
        <div v-if="summary.kpisCount === 1">There is <b>one</b> custom KPI profile.</div>
        <div v-if="summary.kpisCount > 1">
          There are <b>{{ summary.kpisCount }}</b> custom KPI profiles.
        </div>
      </div>
    </template>
    <Kpis :pageMode="pageMode" />
  </Epanel>
  <Epanel
    v-if="panels.includes('activitylog')"
    id="epanel-activitylog"
    :number="panels.indexOf('activitylog') + 1 + '.'"
    title="Activity Log"
    changeButtonTitle="Expand"
    :state="panelStates.activitylog?.state"
    :active="activePanel === 'activitylog'"
    :disabled="inEditModeWithNoTaskOrder"
    :settings="{ ...epanelSettings, noNext: false }"
    @onAction="(action) => onAction(action, 'activitylog')"
  >
    <div class="-text--danger" else-if="panelStates.activitylog?.state === 'danger'">
      {{ panelStates.activitylog?.error?.message }}
    </div>
    <template #disabled>
      <TaskOrderMustBeSelected v-if="inEditModeWithNoTaskOrder" />
    </template>
    <template #done>
      <div :class="['chi-breadcrumb', '-text--normal']">
        <div v-if="summary.activitylogCount === 1">There is <b>one</b> entry in log.</div>
        <div v-if="summary.activitylogCount > 1">
          There are <b>{{ summary.activitylogCount }}</b> entries in log.
        </div>
      </div>
    </template>
    <ActivityLog :pageMode="pageMode" panel="activitylog" />
  </Epanel>
  <Epanel
    v-if="panels.includes('activitylogKpis')"
    id="epanel-activitylogKpis"
    :number="panels.indexOf('activitylogKpis') + 1 + '.'"
    title="Activity Log for KPIs"
    changeButtonTitle="Expand"
    :state="panelStates.activitylogKpis?.state"
    :active="activePanel === 'activitylogKpis'"
    :disabled="inEditModeWithNoTaskOrder"
    :settings="{ ...epanelSettings, noNext: true }"
    @onAction="(action) => onAction(action, 'activitylogKpis')"
  >
    <div class="-text--danger" else-if="panelStates.activitylogKpis?.state === 'danger'">
      {{ panelStates.activitylogKpis?.error?.message }}
    </div>
    <template #disabled>
      <TaskOrderMustBeSelected v-if="inEditModeWithNoTaskOrder" />
    </template>
    <template #done>
      <div :class="['chi-breadcrumb', '-text--normal']">
        <div v-if="summary.activitylogKpisCount === 1">There is <b>one</b> entry in log.</div>
        <div v-if="summary.activitylogKpisCount > 1">
          There are <b>{{ summary.activitylogKpisCount }}</b> entries in log.
        </div>
      </div>
    </template>
    <ActivityLog :pageMode="pageMode" panel="activitylogKpis" />
  </Epanel>
  <footer
    class="-d--flex -justify-content--end -pb--2 -pt--2 -pl--2 -z--20"
    :class="{ '-position--fixed-bottom -bg--white-translucent -pr--4': !bottomArrivedState.bottom }"
  >
    <div class="chi-button-group -flex--row chi-divider -vertical">
      <button
        class="chi-button -flat -primary"
        data-chi-epanel-action="previous"
        :disabled="!getNextPanel(activePanel, -1)"
        @click="onAction('previous', String(activePanel))"
      >
        <div class="chi-button__content">
          <i class="chi-icon icon-chevron-left -xs" aria-hidden="true"></i>
          <span>Previous</span>
        </div>
      </button>
      <button
        class="chi-button -flat -primary"
        data-chi-epanel-action="next"
        :disabled="!getNextPanel(activePanel)"
        @click="onAction('next', String(activePanel))"
      >
        <div class="chi-button__content">
          <span>Next</span>
          <i class="chi-icon icon-chevron-right -xs" aria-hidden="true"></i>
        </div>
      </button>
    </div>
    <div class="-d--flex -pl--4">
      <Submit :pageMode="pageMode" :query="route.query" />
    </div>
  </footer>
</template>
<style>
.-cursor--default {
  cursor: default;
}
.-cursor--progress {
  cursor: progress;
}
.-user-select--none {
  user-select: none;
}
</style>
<style scoped>
a:hover.-cursor--default {
  cursor: default;
}
a:hover.-text--no-decoration {
  text-decoration: none;
}
.-bg--white-translucent {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>
