import { ref, computed } from 'vue'
import { Form, StateType } from '@centurylinkfederal/eis-vue'

export function useForm(): any {
  const isFormEnabled = ref(false)
  const isFormLoaded = ref(false)
  const form = ref<Form>({})
  const formState = ref<StateType>()
  const formChanges = computed(() => {
    const changesTable: any = {}
    for (const key in form.value) {
      const row = form.value[key]
      if (typeof row !== 'object' || Array.isArray(row)) {
        continue
      }
      for (const field in row) {
        const input = row[field]
        if (!input) {
          continue
        }
        const { value, copy, state } = input
        if (!state) {
          continue
        }
        if (!changesTable[key]) {
          changesTable[key] = []
        }
        changesTable[key].push([field, { value, copy, state }])
      }
    }
    return changesTable
  })
  return {
    form,
    formState,
    formChanges,
    isFormEnabled,
    isFormLoaded
  }
}
