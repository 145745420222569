<!-- eslint-disable no-console -->
<script lang="ts" setup>
import { defineProps, withDefaults, defineEmits } from 'vue'
import ViewKPI from './ViewKPI.vue'
import { storeToRefs } from 'pinia'
import { useTOAdminStore } from '../../../store/to-admin'

const toAdminStore = useTOAdminStore()
const { getTaskOrderKPIStatus } = storeToRefs(toAdminStore)

export interface Props {
  selectedOnChange: any
  createKPI: boolean
  taskOrderNumber: any
  taskOrderId: any
  tabName: string
  billingEditedList: any
  createCustomKPIList: any
}
const props = withDefaults(defineProps<Props>(), {
  selectedOnChange: undefined,
  createKPI: undefined,
  taskOrderNumber: undefined,
  taskOrderId: undefined,
  tabName: undefined,
  billingEditedList: undefined,
  createCustomKPIList: undefined
})
const emit = defineEmits(['addCustomKpiPerf'])
const kpiGrp = 'PERF - Product Specific Performance'
function addCustomKPI(updatekpi: any) {
  emit('addCustomKpiPerf', updatekpi, kpiGrp)
}
const TaskOrderKPIStatus = getTaskOrderKPIStatus.value
</script>

<template>
  <div>
    <!-- <div v-if="TaskOrderKPIStatus && TaskOrderKPIStatus === 'Active'">
      <div class="chi-backdrop -center -inverse">
        <div class="chi-backdrop__wrapper">
          <svg class="chi-spinner -icon--primary" viewBox="0 0 66 66">
            <title>Loading</title>
            <circle class="path" cx="33" cy="33" r="30" fill="none" stroke-width="6"></circle>
          </svg>
        </div>
      </div>
    </div> -->
    <ViewKPI
      :billing-edited-list="billingEditedList"
      :selected-on-change="selectedOnChange"
      tab-name="performance"
      :task-order-number="taskOrderNumber"
      :task-order-id="taskOrderId"
      :kpi-grp="kpiGrp"
      :create-k-p-i="createKPI"
      :create-custom-k-p-i-list="createCustomKPIList"
      @add-custom-kpi="addCustomKPI"
      :createKPIList="''"
      :perfStdThreshold="''"
    />
  </div>
</template>
