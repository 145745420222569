<script setup lang="ts">
import DBASForm from './DBASForm.vue'
import DBASFooterAndModal from './DBASFooterAndModal.vue'
import DBASPageAlerts from './DbasPageAlerts.vue'
import { storeToRefs } from 'pinia'
import { useDbasStore } from './dbasStore'
import { onUnmounted } from 'vue'

const dbasStore = useDbasStore()
const {
  dbasFormData,
  selectedTaskOrderNumber,
  selectedTaxExempt,
  requestXML,
  responseXML,
  isModalClosed,
  isSpinnerVisible,
  displayTOError,
  displayTaxExemptError,
  accountId,
  errorAlerts,
  successAlerts,
  dbasAssociationIdOfSelectedTaskOrder
} = storeToRefs(dbasStore)

onUnmounted(() => {
  dbasFormData.value = {}
  selectedTaskOrderNumber.value = ''
  selectedTaxExempt.value = ''
  requestXML.value = ''
  responseXML.value = ''
  isModalClosed.value = true
  isSpinnerVisible.value = false
  successAlerts.value = []
  errorAlerts.value = []
  dbasAssociationIdOfSelectedTaskOrder.value = ''
  displayTOError.value = false
  displayTaxExemptError.value = false
  accountId.value = ''
})
</script>
<template>
  <div class="chi-main__header">
    <div class="chi-main__header-start">
      <div class="chi-link__content">
        <i class="chi-icon icon-chevron-left -xs"></i>

        <span class="-text--md"
          ><router-link to="/agencyAhcAccountAdminFlow/lookup-account">
            Lookup Account
          </router-link></span
        >
      </div>
      <div class="chi-main__title">
        <div class="chi-main__title-heading">View / Send DBAS</div>
      </div>
    </div>
  </div>
  <div class="chi-main__content">
    <DBASPageAlerts />
    <DBASForm />
    <DBASFooterAndModal />
  </div>
</template>
