<script setup lang="ts">
import { ref } from 'vue'

export interface Props {
  id?: string
  class?: string
  message?: string
  text?: string
  icon?: string
  iconSize?: string
  minHeight?: string
}
const props = withDefaults(defineProps<Props>(), {
  text: undefined,
  icon: undefined,
  iconSize: undefined,
  class: '-text--normal -sm'
})
const text = ref(props.text || props.message)
</script>
<template>
  <div class="-user-select--none" :class="class">
    <div class="chi-button__content">
      <i
        class="chi-icon"
        :class="[{ ['icon-' + icon]: icon, ['-' + iconSize]: iconSize }]"
        aria-hidden="true"
      ></i>
      <span>{{ text }}</span>
    </div>
  </div>
</template>
<style scoped>
.-user-select--none {
  user-select: none;
}
</style>
