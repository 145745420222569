<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute, onBeforeRouteUpdate, RouteRecordNameGeneric } from 'vue-router'
import { storeToRefs } from 'pinia'
import Header from '../components/common/Header.vue'
import { useViewStore } from '../store/taskOrderAdminViewStore'

const route = useRoute()
const viewStore = useViewStore()
const { loading } = storeToRefs(viewStore)
const { tabs, tabsMap } = storeToRefs(viewStore)
const breadcrumbs = ref()
const activeTab = computed(() => String(route.query.activeTab ?? ''))

function routeHook(routeName: undefined | string | RouteRecordNameGeneric) {
  if (!routeName) {
    return
  }
  breadcrumbs.value = tabsMap.value.get(String(routeName))?.breadcrumbs
}
onBeforeRouteUpdate((_to: any, _from: any, next: Function) => {
  routeHook(_to?.name)
  next()
})

onMounted(() => {
  routeHook(route?.name)
})
</script>

<template>
  <div class="page-container" :class="{ '-cursor--progress': loading }">
    <Header
      title="Task Order Admin"
      :breadcrumbs="breadcrumbs"
      :breadcrumbsMode="'fixed_only'"
      :tabs="tabs"
      :activeTab="activeTab"
      size="lg"
      tabsClass="-solid -border -md -mt--3"
    >
    </Header>
    <router-view></router-view>
  </div>
</template>
<style>
.-cursor--default {
  cursor: default;
}
.-cursor--progress {
  cursor: progress;
}
</style>
<style scoped>
a:hover.-cursor--default {
  cursor: default;
}
a:hover.-text--no-decoration {
  text-decoration: none;
}
</style>
