<!-- eslint-disable no-console -->
<script lang="ts" setup>
import { onMounted, ref, onBeforeMount } from 'vue'
import Performance from './Performance.vue'
import Outage from './Outage.vue'
import Provisioning from './Provisioning.vue'
import Billing from './Billing.vue'
import Submission from './Submission.vue'
import { useTOAdminStore } from '../../../store/to-admin'

const toAdminStore = useTOAdminStore()

export interface Props {
  selectedServices: any
  createKPI: boolean
  taskOrderNumber: any
  taskOrderId: any
  createKPIList: any
  billingUpdatedList: any
}
const props = withDefaults(defineProps<Props>(), {
  selectedServices: '',
  createKPI: false,
  taskOrderNumber: '',
  taskOrderId: '',
  createKPIList: '',
  billingUpdatedList: ''
})
const billingEditedList = ref([])
const emit = defineEmits(['addCustomKpiMain'])
const kpiGrp = ref(null)
const selectedTab = ref('performance')

onBeforeMount(async () => {
  // for the edit task order
  if (props.taskOrderId) {
    await toAdminStore.getAllKPIs(props.taskOrderId)
  }
})
onMounted(async () => {
  ;(window as any).chi.tab(document.getElementById('KpiLits'))
})
function addCustomKPI(updatekpi: any, kpiGrp: string) {
  emit('addCustomKpiMain', updatekpi, kpiGrp)
}
function selectTab(tabName: any) {
  selectedTab.value = tabName
}
</script>
<template>
  <ul class="chi-tabs -border" id="KpiLits" role="tablist" aria-label="chi-tabs-horizontal">
    <li :class="{ '-active': selectedTab === 'performance' }" @click="selectTab('performance')">
      <a href="#perfomance" role="tab" aria-selected="false" aria-controls="perfomance"
        >Performance</a
      >
    </li>
    <li :class="{ '-active': selectedTab === 'outage' }" @click="selectTab('outage')">
      <a href="#outage" aria-selected="true" tabindex="-1" aria-controls="outage">Outage</a>
    </li>
    <li :class="{ '-active': selectedTab === 'provisioning' }" @click="selectTab('provisioning')">
      <a href="#provisioning" aria-selected="false" tabindex="-1" aria-controls="provisioning"
        >Provisioning</a
      >
    </li>
    <li :class="{ '-active': selectedTab === 'billing' }" @click="selectTab('billing')">
      <a href="#billing" aria-selected="false" tabindex="-1" aria-controls="billing">Billing</a>
    </li>
    <li :class="{ '-active': selectedTab === 'submission' }" @click="selectTab('submission')">
      <a href="#submission" aria-selected="false" tabindex="-1" aria-controls="submission"
        >Submission</a
      >
    </li>
  </ul>

  <div
    class="chi-tabs-panel -active"
    id="perfomance"
    :class="{ '-active': selectedTab === 'performance' }"
    role="tabpanel"
    v-if="selectedTab === 'performance'"
  >
    <Performance
      :selectedOnChange="selectedServices"
      :createKPI="createKPI"
      :createCustomKPIList="createKPIList"
      :taskOrderNumber="taskOrderNumber"
      :taskOrderId="taskOrderId"
      :billingEditedList="billingEditedList"
      v-on:addCustomKpiPerf="addCustomKPI"
      :tab-name="'KPIs'"
    >
    </Performance>
  </div>
  <div
    class="chi-tabs-panel"
    id="outage"
    :class="{ '-active': selectedTab === 'outage' }"
    role="tabpanel"
    v-else-if="selectedTab === 'outage'"
  >
    <Outage
      :selectedOnChange="selectedServices"
      :createKPI="createKPI"
      :createCustomKPIList="createKPIList"
      :taskOrderNumber="taskOrderNumber"
      :taskOrderId="taskOrderId"
      :billingEditedList="billingEditedList"
      v-on:addCustomKpiOut="addCustomKPI"
      :tab-name="'KPIS'"
    ></Outage>
  </div>
  <div
    class="chi-tabs-panel"
    id="provisioning"
    role="tabpanel"
    :class="{ '-active': selectedTab === 'provisioning' }"
    v-else-if="selectedTab === 'provisioning'"
  >
    <Provisioning
      :selectedOnChange="selectedServices"
      :createKPI="createKPI"
      :createCustomKPIList="createKPIList"
      :taskOrderNumber="taskOrderNumber"
      :taskOrderId="taskOrderId"
      :billingEditedList="billingEditedList"
      v-on:addCustomKpiProv="addCustomKPI"
    />
  </div>
  <div
    class="chi-tabs-panel"
    id="billing"
    role="tabpanel"
    :class="{ '-active': selectedTab === 'billing' }"
    v-else-if="selectedTab === 'billing'"
  >
    <Billing
      :selectedOnChange="selectedServices"
      :createKPI="createKPI"
      :createCustomKPIList="createKPIList"
      :taskOrderNumber="taskOrderNumber"
      :taskOrderId="taskOrderId"
      :billingUpdatedList="billingUpdatedList"
      :billingEditedList="billingEditedList"
      v-on:addCustomKpiProv="addCustomKPI"
      :tab-name="'KPIS'"
    ></Billing>
  </div>
  <div
    class="chi-tabs-panel"
    id="submission"
    role="tabpanel"
    :class="{ '-active': selectedTab === 'submission' }"
    v-else-if="selectedTab === 'submission'"
  >
    <Submission
      :selectedOnChange="selectedServices"
      :createKPI="createKPI"
      :createCustomKPIList="createKPIList"
      :taskOrderNumber="taskOrderNumber"
      :taskOrderId="taskOrderId"
      :billingEditedList="billingEditedList"
      v-on:addCustomKpiProv="addCustomKPI"
    />
  </div>
</template>
