<script lang="ts" setup>
import { onMounted, Ref, ref } from 'vue'
import EditTOServices from './EditTOServices.vue'
import ViewTOServices from './ViewTOServices.vue'

const props = defineProps(['taskOrderNumber', 'taskOrderId', 'selectedService', 'loading'])

import { useToAdminAPI } from '../../composables/to-admin/api'
import { useTOAdminStore } from '../../store/to-admin'

const api = useToAdminAPI()
const toAdminStore = useTOAdminStore()

const edit = ref(false)
const preSavedClinsArr: Ref<Array<any>> = ref([])
const selectedService = ref()

onMounted(async () => {
  const services: any = await api.getServicesByTONumber(props.taskOrderId)
  services.value = services.results
})

function updateAllServices(services: any, allServices: any): void {
  preSavedClinsArr.value = []
  for (let i = 0; i < services.length; i++) {
    for (let j = 0; j < allServices.length; j++) {
      if (services[i].serviceID === allServices[j].serviceID) {
        allServices[j].display = true
        if (services[i].identifier === 'Individual CLINs') {
          allServices[j].displayViewEditBtn = true
          allServices[j].clinType = 'Individual CLINs'
          allServices[j].identifier = 'Individual CLINs'
        } else {
          allServices[j].clinType = 'All CLINs'
          allServices[j].identifier = 'All CLINs'
        }
        break
      }
    }
    if (services[i].identifier === 'Individual CLINs') {
      for (let m = 0; m < services[i].clins.length; m++) {
        preSavedClinsArr.value.push(services[i].clins[m])
      }
    }
  }
}

function onEdit(): void {
  updateAllServices(toAdminStore.services, toAdminStore.allServices)
  edit.value = true
  selectedService.value = { ...selectedService.value }
}

async function newCancel(): Promise<void> {
  edit.value = false
  try {
    await toAdminStore.getTaskOrderSvcDetailsDTO(props.taskOrderId).catch((err: any) => err)
    await toAdminStore.getServicesByTONumber(props.taskOrderId).catch((err: any) => err)
  } catch (e) {
    console.log(e)
  }
}
function newSave(): void {
  edit.value = false
}
</script>
<template>
  <div>
    <ViewTOServices
      v-if="!edit"
      :taskOrderNumber="taskOrderNumber"
      :taskOrderId="taskOrderId"
      :loading="props.loading"
    />
    <EditTOServices
      v-else
      :taskOrderNumber="taskOrderNumber"
      :taskOrderId="taskOrderId"
      @cancel="newCancel"
      @save="newSave"
    >
    </EditTOServices>

    <div class="-mt--5 -text--center" v-if="!edit">
      <button
        @click="onEdit()"
        class="chi-button -sm -primary"
        id="edit-to-services"
        :disabled="props.loading"
      >
        Edit
      </button>
    </div>
  </div>
</template>

<style scoped>
.table-cell-v1 {
  text-align: left !important;
  width: 10%;
}

.table-cell-v2 {
  text-align: left !important;
}

thead > tr > td.table-cell {
  background-color: rgb(146, 146, 146);
}

.modal-backdrop {
  z-index: -1;
}
</style>
()
