import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'
import { AlertType, Form, StateType } from '@centurylinkfederal/eis-vue'

export const useStore = defineStore('useUserProfileStore', () => {
  const resetSeq = ref(0)
  const alertMap = ref<Map<string, AlertType>>(new Map())
  const alertEntries = ref<[string, AlertType][]>([])
  const loading = ref(false)
  const form = ref<Form>({})
  const formState = ref<StateType>()

  const currentPage = ref(1)
  const rowsPerPage = ref(10)

  const userProfileDetails = ref<any>({})
  const passwordExpiresOn = ref<number | undefined>(undefined)
  const userTOPermissionDetails = ref<any>({})

  const formChanges = computed(() => {
    const changesTable: any = {}
    for (const key in form.value) {
      const row = form.value[key]
      if (typeof row !== 'object' || Array.isArray(row)) {
        continue
      }
      for (const field in row) {
        const input = row[field]
        if (!input) {
          continue
        }
        const { value, copy, state } = input
        if (!state) {
          continue
        }
        if (!changesTable[key]) {
          changesTable[key] = []
        }
        changesTable[key].push([field, { value, copy, state }])
      }
    }
    return changesTable
  })

  watch(
    () => alertMap,
    (newMap) => {
      alertEntries.value = Array.from(newMap.value.entries())
    },
    { deep: true }
  )

  return {
    resetSeq,
    alertMap,
    alertEntries,
    loading,
    form,
    formState,
    formChanges,
    currentPage,
    rowsPerPage,
    passwordExpiresOn,
    userTOPermissionDetails,
    userProfileDetails
  }
})
