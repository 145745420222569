import { useApi, parseErr, replaceAll } from '@centurylinkfederal/eis-vue'
import { jsonParse, jsonStringify } from '../../utils/helpers'

export const useToAdminAPI = () => {
  const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)
  const kpiApi = useApi(import.meta.env.VITE_EIS_API_BASE_KPIS)
  const kpiActivityApi = useApi(import.meta.env.VITE_EIS_API_BASE_SLA)
  async function fetchAgencies(): Promise<any> {
    try {
      const response = await api.get('/filter/agencies')
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
  }
  async function fetchStandardInterval(): Promise<any> {
    try {
      const response = await api.get('/service-acceptance-interval')
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
  }
  async function fetchTaskOrders(): Promise<any> {
    try {
      const response = await api.get('/taskorders')
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
  }

  async function fetchTaskOrdersByAgencyId(id: string): Promise<any> {
    try {
      const response = await api.get(`/filter/agencies/${id}/accounttaskorders`)
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
  }

  async function addNewMod(data: any) {
    return await api.post('/modIteration', data)
  }

  async function createTaskOrders(taskOrders: any): Promise<any> {
    return await api.post('/taskorder', taskOrders)
  }

  async function checkForModExists(mod: string, taskOrderNumber: string) {
    return await api.get(`/taskorders/${taskOrderNumber}/modIterations/${mod}/isExist`)
  }

  async function getTOIterations(toNumber: string): Promise<any> {
    try {
      return await api.get(`/taskOrderNumbers/${toNumber}/modIterations`)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
  }

  async function isModIterationExist(mod: string, taskOrderNumber: string) {
    return await api.get(`/taskOrderAdminPage/editTaskTab/newmod/isexist/${mod}/${taskOrderNumber}`)
  }

  async function editMod(data: any) {
    return await api.put('/modIteration', data)
  }

  async function getJurisdictions(opts: any = {}): Promise<any> {
    try {
      if (opts.useCache) {
        const jurisdictionCountries = sessionStorage.getItem('jurisdictionCountries')
        const jurisdictionCountriesJson = jsonParse(jurisdictionCountries)
        if (jurisdictionCountriesJson) {
          return jurisdictionCountriesJson
        }
      }
      const response = await api.get('/jurisdictionCountries')
      if (opts.useCache) {
        const jurisdictionCountries = jsonStringify(response)
        if (jurisdictionCountries) {
          sessionStorage.setItem('jurisdictionCountries', jurisdictionCountries)
        }
      }
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
  }

  async function fetchToOfficialsByTaskOrderId(id: string) {
    const response = await api.get(`taskorders/${id}/taskorderofficials`)
    return response
  }

  async function updateTaskOrdersByIdForToOfficialsAPI(data: any) {
    const response = await api.put('/taskorderofficials', data)
    return response
  }

  async function fetchTODetailsByAgencyAndTONumber(
    agencyIdentifier: string,
    toNumber: string
  ): Promise<any> {
    try {
      const response = (await api.get(
        `agencies/${agencyIdentifier}/taskorders/${toNumber}/taskorderdetail`
      )) as any
      if (response.results && response.results[0]) {
        return response.results[0]
      }
      throw new Error('No Task Order details')
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
  }
  async function fetchTaskOrderDetails(
    agencyIdentifierEncoded: string,
    taskOrderId: number | string
  ): Promise<any> {
    try {
      const response = (await api.get(
        `agencies/${agencyIdentifierEncoded}/taskorders/${taskOrderId}/taskorderdetail`
      )) as any
      if (response.results && response.results[0]) {
        return response.results[0]
      }
      throw new Error('No Task Order details')
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
  }

  async function getAllServices(opts: any = {}): Promise<any> {
    try {
      if (opts.useCache) {
        const svcLookups = sessionStorage.getItem('svcLookups')
        const svcLookupsJson = jsonParse(svcLookups)
        if (svcLookupsJson) {
          return svcLookupsJson
        }
      }
      const response = await api.get('/svcLookups')
      if (opts.useCache) {
        const svcLookups = jsonStringify(response)
        if (svcLookups) {
          sessionStorage.setItem('svcLookups', svcLookups)
        }
      }
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
  }

  async function updateTaskOrderDetails(data: any): Promise<any> {
    return await api.put('/taskorderdetails', data)
  }

  async function fetchTaskOrderFinancial(taskOrderId: string) {
    return await api.get(`/taskorders/${taskOrderId}/taskorderfinancial`)
  }

  async function updateTaskOrderFinancials(data: any[]) {
    return await api.put('/taskorderfinancials', data)
  }

  function getTaskOrderSvcDetailsDTO(taskOrderId: string) {
    return api.get(`/taskorders/${taskOrderId}/taskorderservicesandclins`)
  }

  function getServicesByTONumber(taskOrderId: string) {
    return api.get(`/taskorders/${taskOrderId}/serviceIdAndName`)
  }
  async function getRefKpis(opts: any = {}): Promise<any> {
    try {
      if (opts.useCache) {
        const RefKpis = sessionStorage.getItem('RefKpis')
        const RefKpisJson = jsonParse(RefKpis)
        if (RefKpisJson) {
          return RefKpisJson
        }
      }
      const response = await kpiApi.get('/api/eis/kpi/RefKpis')
      if (opts.useCache) {
        const RefKpis = jsonStringify(response)
        if (RefKpis) {
          sessionStorage.setItem('RefKpis', RefKpis)
        }
      }
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
  }
  async function createServiceKpis(data: any): Promise<any[]> {
    return await kpiApi.post('/api/eis/kpis', data)
  }
  async function getAllKPIs(taskOrderNumner: any): Promise<any[]> {
    try {
      return await kpiApi.get(`/api/eis/kpi/getAllKpisByTaskOrderId/${taskOrderNumner}`)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
      return []
    }
  }

  async function getKPIActivityLog(data: any) {
    const resposne = await kpiActivityApi.post('/eis/kpiactivities', data)
    return resposne
  }

  async function getTaskOrderActivities(taskOrderId: string, params: any) {
    const query = new URLSearchParams(params || {}).toString()
    return await api.get(`/taskorders/${taskOrderId}/taskorderactivities?${query}`)
  }

  function saveTaskOrderSvcDetailsDTO(tasksvcDto: any): Promise<any> {
    return api.put(`/taskorderservices`, tasksvcDto)
  }

  async function downloadActivityLog(params: Record<string, any>): Promise<void> {
    const query = new URLSearchParams(params).toString()
    const response = await api.get<Blob>(`/activitylog/download?${query}`)
    const taskOrderIdSuffix = params.taskOrderId
    const dateSuffix = replaceAll(params.to, '/', '_')
    const fileName = `activity_log-${taskOrderIdSuffix}-${dateSuffix}.csv`
    const blob = new Blob([response], { type: 'text/csv' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  async function downloadManyActivityLogs(manyParams: Record<string, any>[]): Promise<void> {
    const responses = []
    let index = -1
    for await (const params of manyParams) {
      index++
      const query = new URLSearchParams(params).toString()
      const response = await api.get<Blob>(`/activitylog/download?${query}`)
      if (!response) {
        continue
      }
      if (index === 0) {
        responses.push(response)
      } else {
        const lines = String(response).split('\n')
        lines.shift()
        const response2 = lines.join('\n')
        responses.push(response2)
      }
    }
    const taskOrderIdSuffix = manyParams[0]?.taskOrderId
    const dateSuffix = replaceAll(String(manyParams[0]?.to), '/', '_')
    const fileName = `activity_logs-${taskOrderIdSuffix}-${dateSuffix}.csv`
    const blob = new Blob(responses, { type: 'text/csv' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  async function downloadKPIActivityLog(params: any): Promise<void> {
    const query = new URLSearchParams(params).toString()
    const response: any = await kpiActivityApi.get(`/eis/kpiactivities/download?${query}`)
    const taskOrderIdSuffix = params.taskOrderId
    const dateSuffix = replaceAll(params.toDate, '/', '_').substring(0, 9)
    const fileName = `kpi_activity_log-${taskOrderIdSuffix}-${dateSuffix}.csv`
    const blob = new Blob([response])
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  async function getCodes(codes: any): Promise<void> {
    try {
      return await kpiApi.post('/api/eis/kpi/codes', codes)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
  }
  async function getKpiUcCodes(kpiGroupType: any) {
    return await kpiApi.get(`/api/eis/kpi/kpiUcCodes?kpiGroupType=${kpiGroupType}`)
  }
  async function getExportUpdatedDates(taskOrderId: any) {
    return await api.get(`/updated-on/${taskOrderId}`, taskOrderId)
  }
  async function getTaskOrderNSCsByTaskOrderID(taskOrderId: any) {
    return await api.get(`/taskorders/${taskOrderId}/taskorderNSCs`)
  }
  async function getTaskOrderJurisdictionsByTaskOrderID(taskOrderId: any) {
    return await api.get(`/taskorders/${taskOrderId}/taskorderjurisdictions`)
  }

  return {
    api,
    kpiApi,
    kpiActivityApi,
    fetchAgencies,
    fetchStandardInterval,
    fetchTaskOrdersByAgencyId,
    addNewMod,
    checkForModExists,
    getTOIterations,
    isModIterationExist,
    fetchTaskOrders,
    editMod,
    fetchToOfficialsByTaskOrderId,
    updateTaskOrdersByIdForToOfficialsAPI,
    fetchTODetailsByAgencyAndTONumber,
    fetchTaskOrderDetails,
    getJurisdictions,
    getAllServices,
    updateTaskOrderDetails,
    fetchTaskOrderFinancial,
    updateTaskOrderFinancials,
    getTaskOrderSvcDetailsDTO,
    getServicesByTONumber,
    createTaskOrders,
    getTaskOrderActivities,
    saveTaskOrderSvcDetailsDTO,
    downloadActivityLog,
    downloadManyActivityLogs,
    getRefKpis,
    createServiceKpis,
    getAllKPIs,
    getKPIActivityLog,
    downloadKPIActivityLog,
    getCodes,
    getKpiUcCodes,
    getExportUpdatedDates,
    getTaskOrderNSCsByTaskOrderID,
    getTaskOrderJurisdictionsByTaskOrderID
  }
}
