<script lang="ts" setup>
import { ref, defineProps, toValue, computed } from 'vue'
import { useToAdminAPI } from '../../composables/to-admin/api'
import moment from 'moment'
import { DatePicker } from '@centurylinkfederal/eis-vue'

interface IProps {
  taskOrderNumber: string
}

const props = defineProps<IProps>()
const emit = defineEmits(['afterSave'])

const modIterationForm = ref<any>({
  description: '',
  modApprovedDate: '',
  modNumber: '',
  pramataId: '',
  startDateOfIteration: ''
})
const errors = ref<any>({
  modApprovedDate: false,
  modNumber: false,
  pramataId: false,
  startDateOfIteration: false
})
const showSuccess = ref<boolean>(false)
const api = useToAdminAPI()
const currentDate = ref<string>(moment().format('MM/DD/YYYY'))
const errorMessage = ref<string>('')
const hasErrorOnSave = ref<boolean>(false)

const showError = computed(() => {
  const { modApprovedDate, modNumber, pramataId, startDateOfIteration } = errors.value
  return modApprovedDate || modNumber || pramataId || startDateOfIteration || hasErrorOnSave.value
})

async function saveNewMod(): Promise<void> {
  hasErrorOnSave.value = false
  if (validate()) {
    const modExists: any = await api.checkForModExists(
      modIterationForm.value.modNumber,
      props.taskOrderNumber
    )
    if (modExists && modExists['results'][0]) {
      hasErrorOnSave.value = true
      errorMessage.value = 'Duplicate'
      return
    }
    const response = (await api.addNewMod({
      ...modIterationForm.value,
      taskOrderNumber: props.taskOrderNumber
    })) as any
    if (response.valid) {
      showSuccess.value = true
      await clearMod()
      emit('afterSave', props.taskOrderNumber)
    } else {
      hasErrorOnSave.value = true
      errorMessage.value = response.error
    }
  }
}

async function clearMod(): Promise<void> {
  modIterationForm.value = {
    description: '',
    modApprovedDate: '',
    modNumber: '',
    pramataId: '',
    startDateOfIteration: ''
  }

  resetErrors()
}

function resetErrors(): void {
  errors.value = {
    modApprovedDate: false,
    modNumber: false,
    pramataId: false,
    startDateOfIteration: false,
    invalidModNumber: null
  }
  hasErrorOnSave.value = false
  errorMessage.value = ''
}

function validate(): boolean {
  resetErrors()
  const { modApprovedDate, modNumber, pramataId, startDateOfIteration } = modIterationForm.value
  errors.value.modApprovedDate = !modApprovedDate
  errors.value.modNumber = !modNumber
  errors.value.pramataId = !pramataId
  errors.value.startDateOfIteration = !startDateOfIteration
  errors.value.invalidModNumber =
    modNumber && !/^[a-zA-Z0-9]*$/.test(modNumber) && 'Should be alpha numeric'
  if (
    errors.value.modApprovedDate ||
    errors.value.modNumber ||
    errors.value.pramataId ||
    errors.value.startDateOfIteration ||
    !!errors.value.invalidModNumber
  ) {
    return false
  }
  return true
}

function onDateChange(field: string, value: any): void {
  modIterationForm.value[field] = value
}

function hideSuccessAlert(): void {
  showSuccess.value = false
}
</script>
<template>
  <div class="chi-card -widget -mt--3">
    <div class="chi-card__header">
      <div class="chi-card__title">Add a New Mod</div>
    </div>
    <div class="chi-card__content">
      <div v-if="showSuccess" class="-mb--2">
        <chi-alert
          color="success"
          icon="circle-check"
          size="sm"
          closable
          @dismissAlert="hideSuccessAlert"
        >
          Successfully saved new MOD as shown in TO Iteration section below
        </chi-alert>
      </div>
      <div class="-mb--2" v-if="showError">
        <div class="chi-alert -danger -close" role="alert">
          <i class="chi-alert__icon chi-icon icon-circle-x" aria-hidden="true"></i>
          <div class="chi-alert__content">
            <p class="chi-alert__text">
              {{ errorMessage || 'Please fill in all required fields' }}
            </p>
          </div>
          <button
            class="chi-alert__close-button chi-button -icon -close"
            aria-label="Close"
            @click="resetErrors"
          >
            <div class="chi-button__content">
              <i class="chi-icon icon-x" aria-hidden="true"></i>
            </div>
          </button>
        </div>
      </div>
      <div class="chi-grid">
        <div class="chi-col">
          <div class="chi-form__item">
            <chi-label required>MOD Number</chi-label>
            <input
              class="chi-input"
              v-model="modIterationForm.modNumber"
              :class="errors.modNumber || errors.invalidModNumber ? '-danger' : ''"
            />
            <p class="error" v-if="errors.invalidModNumber">{{ errors.invalidModNumber }}</p>
          </div>
        </div>
        <div class="chi-col">
          <div class="chi-form__item">
            <DatePicker
              label="MOD Approved Date"
              id="modStartDate"
              :required="true"
              class="z--70"
              :helperMessage="showError ? 'Please enter date' : ''"
              :value="modIterationForm.modApprovedDate"
              :state="errors.modApprovedDate ? 'danger' : ''"
              @change="onDateChange('modApprovedDate', $event)"
            >
            </DatePicker>
          </div>
        </div>
        <div class="chi-col">
          <div class="chi-form__item">
            <DatePicker
              label="Start Date of Iteration"
              id="startDateIteration"
              :required="true"
              class="z--70"
              :state="errors.startDateOfIteration ? 'danger' : ''"
              :max="currentDate"
              :helperMessage="showError ? 'Please enter date' : ''"
              :value="modIterationForm.startDateOfIteration"
              @change="onDateChange('startDateOfIteration', $event)"
            >
            </DatePicker>
          </div>
        </div>
        <div class="chi-col">
          <div class="chi-form__item">
            <chi-label required>Pramata ID</chi-label>
            <input
              class="chi-input"
              v-model="modIterationForm.pramataId"
              :class="errors.pramataId ? '-danger' : ''"
            />
          </div>
        </div>
        <div class="chi-col">
          <div class="chi-form__item">
            <chi-label>Description</chi-label>
            <textarea
              class="chi-input"
              v-model="modIterationForm.description"
              :class="errors.description ? '-danger' : ''"
              maxlength="280"
            />
          </div>
        </div>
      </div>
      <div class="-d--flex -justify-content--center" style="gap: 16px">
        <button class="chi-button -sm -primary" @click="saveNewMod()">Save</button>
        <button class="chi-button -sm" @click="clearMod()">Cancel</button>
      </div>
    </div>
  </div>
</template>

<style>
.chi-card__title {
  font-size: 1.25rem;
  font-weight: 600 !important;
}
.error {
  color: red;
}
</style>
