<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useScroll } from '@vueuse/core'
import { Tab, Size } from '../../types/common'

type BreadcrumbsMode = undefined | 'default' | 'fixed_only'
type BreadcrumbsOffset = undefined | number
export interface Props {
  title?: string
  tabs?: Tab[]
  tabsClass?: string
  activeTab?: string
  size?: Size
  breadcrumbs?: Tab[]
  breadcrumbsMode?: BreadcrumbsMode
  breadcrumbsOffset?: BreadcrumbsOffset
}
const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  tabs: () => {
    return []
  },
  tabsClass: '-solid -border -md -mt--3',
  activeTab: '',
  size: 'md',
  breadcrumbs: () => [],
  breadcrumbsMode: undefined,
  breadcrumbsOffset: undefined
})
const route = useRoute()
const documentRef = ref<Document | null>(null)
const { arrivedState } = useScroll(documentRef, {
  throttle: 500,
  offset: { top: props.breadcrumbsOffset ?? 180 }
})
const activeTab = ref(props.activeTab)

const tabs = computed(() => props.tabs.filter((f: Tab) => !f.hidden))

watch(
  () => props.activeTab,
  (newValue) => (activeTab.value = newValue)
)
watch(
  () => route.name,
  () => (activeTab.value = '')
)

onMounted(() => {
  documentRef.value = window.document
})
</script>
<template>
  <slot name="breadcrumb">
    <div v-if="breadcrumbs.length === 0" class="-mb--1" />
    <nav
      v-else
      class="chi-breadcrumb"
      :class="{
        '-position--fixed-top -bg--white-translucent  -z--20 -py--1 -px--4':
          breadcrumbsMode === 'fixed_only' && !arrivedState.top,
        '-d--none': breadcrumbsMode === 'fixed_only' && arrivedState.top
      }"
      aria-label="Breadcrumb"
    >
      <ol>
        <li v-for="(item, index) in breadcrumbs" :key="index" class="chi-breadcrumb__item">
          <a
            :class="[
              '-text--md',
              {
                '-active': item.active,
                '-text--no-decoration -cursor--default': !item.to && !item.href
              }
            ]"
            >{{ item.label }}</a
          >
        </li>
      </ol>
    </nav>
  </slot>
  <h3 class="page-heading -cursor--default -text--bolder -mt--0">{{ title }}</h3>
  <ul v-if="tabs?.length" :class="['chi-tabs', tabsClass]">
    <li
      v-for="tab in tabs"
      :key="tab.name"
      :class="{
        '-active': tab.name === route.name,
        '-active -active--alt': tab.name === activeTab
      }"
      role="tab"
    >
      <router-link v-slot="{ href, navigate }" :to="tab.to"
        ><a :href="href" @click="navigate">{{ tab.label }}</a></router-link
      >
    </li>
  </ul>
</template>

<style scoped>
.-cursor--default {
  cursor: default;
}
a:hover.-cursor--default {
  cursor: default;
}
a:hover.-text--no-decoration {
  text-decoration: none;
}
.chi .chi-tabs.-solid > li.-active.-active--alt > a::before {
  background-color: #0075c9;
}
.-bg--white-translucent {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>
