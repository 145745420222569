<script setup lang="ts">
import { useStore } from './store'
import { storeToRefs } from 'pinia'
import { getTaskOrderDetails, getUserDetails } from './utils/helper.ts'
import {
  dangerAlert,
  doScrollToTop,
  parseErr,
  useApi,
  successAlert
} from '@centurylinkfederal/eis-vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const store = useStore()
const {
  resetSeq,
  roleInfo,
  loading,
  alertMap,
  form,
  filteredProductGroups,
  isUserActive,
  loggedInUserInfo,
  isEditMode,
  userDetail,
  isFormSubmitted,
  validationSuccess,
  toPermissionList,
  updatingMySelf,
  originalTaskorder,
  selectedAgencyTos
} = storeToRefs(store)
const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)
const createUserId = -1

function resetForm(): void {
  if (isEditMode.value) {
    router.push('/user-admin/user-search')
  } else {
    resetSeq.value++
  }
}

async function handleOnCreate(): Promise<void> {
  isFormSubmitted.value = true
  alertMap.value = new Map()
  const isFormInvalid = validateForm()
  const productPermissions = filteredProductGroups.value
  const isZscalerProductsSelectionNeeded: boolean = checkZscalerProductsSelectionNeeded()
  if (isFormInvalid || isZscalerProductsSelectionNeeded) {
    if (isFormInvalid) {
      alertMap.value.set('alert', {
        ...dangerAlert,
        title: `Please complete all Required Fields indicated with * before attempting to submit changes.`
      })
      return doScrollToTop()
    }
    if (isZscalerProductsSelectionNeeded) {
      alertMap.value.set('alert', {
        ...dangerAlert,
        title: `Please select a Zscaler product option before attempting to submit changes.`
      })
      return doScrollToTop()
    }
  }

  let err: any
  loading.value = true
  const taskOrderPermissions = getAllTaskOrderDetails()
  try {
    const request = {
      ...getUserDetails(
        form.value[createUserId],
        roleInfo.value,
        isUserActive.value,
        loggedInUserInfo.value,
        isEditMode.value,
        userDetail.value
      ),
      taskOrderPermissions,
      productPermissions
    }

    const userName = request.userInfo?.userName
    if (!isEditMode.value) {
      await api.post(`/users`, request)
      resetSeq.value++
    } else {
      await api.put(`/users`, request)
    }
    validationSuccess.value = true
    alertMap.value.set('success', {
      ...successAlert,
      title: `User ${userName} ${isEditMode.value ? 'updated' : 'created'} successfully`,
      ...err
    })
    isFormSubmitted.value = false
  } catch (error: any) {
    err = parseErr(error)
  }

  loading.value = false
  doScrollToTop()
  if (err) {
    alertMap.value.set('alert', {
      ...dangerAlert,
      title: `${isEditMode.value ? 'Update' : 'Create'} user error`,
      ...err
    })
  }
}

function validateForm(): boolean {
  let hasError = false
  const inputs = form.value[createUserId]
  for (let key of Object.keys(inputs)) {
    const input = inputs[key]
    input?.tests?.forEach((test) => {
      const result = test(input)
      if (result?.[0] === 'danger') {
        input.error = result?.[1]
        input.state = 'danger'
        hasError = true
      }
    })
  }

  form.value = { ...form.value, [createUserId]: inputs }

  return hasError || !roleInfo.value || !validationSuccess.value
}

function checkZscalerProductsSelectionNeeded(): boolean {
  const isZscalerProductSelectionNeeded: boolean =
    roleInfo.value?.value === 'L3ADMIN' &&
    filteredProductGroups.value.includes('MZTNA_ZSCA') &&
    !filteredProductGroups.value.some((product) => ['FEDRAMP_HI', 'FEDRAMP_MD'].includes(product))
  return isZscalerProductSelectionNeeded
}

function getAllTaskOrderDetails() {
  if (!toPermissionList.value.size) return {}

  const originalTos = Array.from(originalTaskorder.value, ([, value]) => value).flatMap((to) => to)
  return getTaskOrderDetails(selectedAgencyTos.value, originalTos, isEditMode.value)
}
</script>
<template>
  <div class="chi-card -mt--3">
    <div class="chi-card__footer -justify-content--end">
      <button class="chi-button -outline" @click="resetForm">Cancel</button>
      <button
        class="chi-button -primary"
        :disabled="loading || updatingMySelf"
        @click="handleOnCreate"
      >
        <div class="chi-button__content">
          <span>{{ isEditMode ? 'Update' : 'Create' }}</span>
          <svg
            class="chi-spinner -icon--primary -sm -position--absolute -ml--10"
            :class="{ '-d--none': !loading }"
            viewBox="0 0 66 66"
          >
            <circle class="path" cx="33" cy="33" r="30" fill="none" stroke-width="6"></circle>
          </svg>
        </div>
      </button>
    </div>
  </div>
</template>
