<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useStore } from './CreateUser/store'
import { Action, dispatch } from './CreateUser/actions'
import { toBeAPassword, toNotBeEmpty } from '@centurylinkfederal/eis-vue'

interface IProps {
  password?: string
}

const createUserId = -1
const store = useStore()
const { form, isEditMode, validationSuccess, userDetail } = storeToRefs(store)
const props = defineProps<IProps>()
const previousPasswordColor = ref('grey')

const rules = computed(() => {
  const [_, errorsObj] = toBeAPassword({ value: props.password })
  return (errorsObj?.errors || []).map((err: any) => err.id)
})

const passwordRulesConfig = [
  { id: 'passwordLength', message: '16 characters minimum' },
  { id: 'upperAlphabet', message: '1 uppercase' },
  { id: 'lowerAlphabet', message: '1 lowercase' },
  { id: 'splCharacter', message: '1 special character' },
  { id: 'number', message: '1 number' }
]

function ruleColor(id: string) {
  if (!props.password || rules.value.includes(id)) {
    return 'grey'
  }
  return 'success'
}

function ruleIcon(id: string) {
  if (!props.password || rules.value.includes(id)) {
    return 'circle-check-outline'
  }
  return 'circle-check'
}
watch(
  () => props.password,
  async (newValue, oldValue) => {
    previousPasswordColor.value = 'grey'
    validationSuccess.value = passwordRulesConfig.every(
      (config) => ruleColor(config.id) === 'success'
    )
    if (!isEditMode.value) return
    updateValidation(newValue)
    if (newValue && validationSuccess.value && oldValue !== newValue) {
      const isPrevPassword = await dispatch(Action.PREVIOUS_PASSWORD, {
        value: newValue,
        userId: userDetail.value.eisUserId
      })
      validationSuccess.value = !isPrevPassword
      previousPasswordColor.value = isPrevPassword ? 'grey' : 'success'
    }
  }
)

function updateValidation(newValue: string | undefined) {
  const inputs = form.value[createUserId]
  const confirm = inputs['confirmPassword']
  const currentTest = confirm.tests || []
  if (!newValue) {
    validationSuccess.value = true
    confirm.error = undefined
  }
  if (newValue) {
    confirm.tests = currentTest?.length > 1 ? [...currentTest] : [toNotBeEmpty, ...currentTest]
  }
}
</script>
<template>
  <div class="chi-form__item">
    <label class="chi-label">Password must contain</label>
    <div class="chi-grid">
      <div
        class="chi-col -w-md--4 -w-sm--6 -w-xs--12 -w--12 -d--flex -align-items--center -mb--1 item-gap"
        v-for="item in passwordRulesConfig"
      >
        <chi-icon :icon="ruleIcon(item.id)" size="sm" :color="ruleColor(item.id)"></chi-icon>
        <span :class="ruleColor(item.id)">{{ item.message }}</span>
      </div>
      <div
        v-if="isEditMode"
        class="chi-col -w-md--4 -w-sm--6 -w-xs--12 -w--12 -d--flex -align-items--center -mb--1 item-gap"
      >
        <chi-icon
          :icon="previousPasswordColor === 'grey' ? 'circle-check-outline' : 'circle-check'"
          size="sm"
          :color="previousPasswordColor"
        ></chi-icon>
        <span :class="previousPasswordColor">do not reuse your last 10 passwords</span>
      </div>
    </div>
  </div>
</template>
<style lang="css" scoped>
.item-gap {
  gap: 8px;
}

.grey {
  color: #65686c;
}

.success {
  color: #009040;
}
</style>
