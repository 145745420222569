<script setup lang="ts">
import { ref, onMounted } from 'vue'
import NewsContent from './NewsContent.vue'

const props = defineProps<{
  create: boolean
  data?: any
}>()

const emit = defineEmits(['newsCreated', 'drawerClosed'])
const isShow = ref(false)
const newContentCounter = ref<number>(0)
function openDrawer() {
  isShow.value = true
}
function closeDrawer() {
  isShow.value = false
  newContentCounter.value++
  emit('drawerClosed')
}
function emitNews() {
  emit('newsCreated')
}
onMounted(() => {
  isShow.value = true
})
</script>
<template>
  <!-- Drawer -->
  <div
    class="chi-backdrop"
    :class="{
      '-closed': !isShow
    }"
  >
    <div class="chi-backdrop__wrapper">
      <div
        class="chi-drawer -right -w--35"
        :class="{
          '-active': isShow
        }"
      >
        <div class="chi-drawer__header">
          <span class="chi-drawer__title" v-if="create"> Create News </span>
          <span class="chi-drawer__title" v-else> Edit / Update News </span>
          <button
            class="chi-button -icon -close"
            aria-label="Close"
            @click="closeDrawer()"
            data-tooltip="asdsad"
          >
            <div class="chi-button__content">
              <i class="chi-icon icon-x" aria-hidden="true"></i>
            </div>
          </button>
        </div>
        <div class="chi-drawer__content">
          <div class="-p--2 -text">
            <NewsContent
              :create="create"
              :data="props.data"
              @close="closeDrawer()"
              @newsCreated="(closeDrawer(), emitNews())"
              :key="newContentCounter"
            ></NewsContent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
