import { ref, watch, computed } from 'vue'
import { defineStore } from 'pinia'
import { AlertType, ModalType, Signal } from '@centurylinkfederal/eis-vue'
import { useForm } from '../../composables/form'

export const useStore = defineStore('exportDetailsSetsStore', () => {
  const loading = ref(false)
  const activePanel = ref('')
  const disabledPanels = ref(new Set())
  const resetSeq = ref(0)
  const alertMap = ref<Map<string, AlertType>>(new Map())
  const alertEntries = ref<[string, AlertType][]>([])
  const modalMap = ref<Map<string, ModalType>>(new Map())
  const modalEntries = ref<[string, ModalType][]>([])
  const signal = ref<Signal>([])
  const billingNotifications = ref()
  const summary = ref({
    servicesCount: 0,
    kpisCount: 0
  })
  const { form, formState, formChanges } = useForm()
  const panelStates = ref<any>({})
  const selectedTaskOrderObj = ref<any>(null)
  const selectedTaskOrder = computed(() => selectedTaskOrderObj.value?.taskOrderNumber)
  const setsToExport = ref<string[]>([])
  watch(
    () => alertMap,
    (newMap) => {
      alertEntries.value = Array.from(newMap.value.entries())
    },
    { deep: true }
  )
  watch(
    () => modalMap,
    (newMap) => {
      modalEntries.value = Array.from(newMap.value.entries())
    },
    { deep: true }
  )
  return {
    loading,
    activePanel,
    disabledPanels,
    resetSeq,
    alertMap,
    alertEntries,
    modalMap,
    modalEntries,
    signal,
    form,
    formState,
    formChanges,
    billingNotifications,
    summary,
    panelStates,
    selectedTaskOrderObj,
    selectedTaskOrder,
    setsToExport
  }
})
