<script lang="ts" setup>
import { useTOAdminStore } from '../../../../src/store/to-admin'
const toAdminStore = useTOAdminStore()
import { watch, ref, onMounted, Ref } from 'vue'
import ServiceLocations from '../../../../src/views/to-admin/ServiceLocations.vue'
//import GenericServiceLocations from '@/modules/taskOrderAdmin/components/GenericServiceLocations.vue';
import ServiceCountryJurisdiction from '../../to-admin/ServiceCountryJurisdiction.vue'
import Loader from '../../to-admin/TaskOrderLoader.vue'
import { Modal } from '@centurylinkfederal/eis-vue'
import ImportFileComponent from '../../ImportFile.vue'

const showToggle = ref(true)
const expand = ref(true)
const loading = ref(false)
const hideContent = ref(false)
const defaultid = ref<string>('')
const defaultIconId = ref<string>('')
const officials: any = ref([])
const showImportDailog = ref(false)

const valid = ref<string[]>()
//Constant Values
const ALL_CLINS = 'all'
const INDIVIDUAL_CLIN = 'individual'
const MWS = 'MWS'
//Class Variables for Data.
const allServices = ref<any[]>([])
const selectedServiceIds = ref<any>([])
const clinIdentifers = ref<any>({})
const selectedServices = ref<any[]>([])
//Variables for view/data Manipulation.
const individualClinsPopupIsOpen = ref(false)
let presentServiceId = ''
const allIndividualClinsForAService = ref<any[]>([])
const selectedIndividualClinsForAService = ref<any[]>([])
const selectAllClinsCheckBoxIsChecked = ref(false)
const previousStateClins = ref<any[]>([])
const currentIdx = ref<any>()
const taskOrderNSCList = ref<any[]>([])
const taskOrderJurisdiction = ref<any[]>([])
//country-jurisdiction Variables
const jurisSvcId = ref<string>()
const isChecked = ref<Array<boolean>>([true, false, true])
const isCheckedBox = ref(false)
const checkedService = ref<any>()
const showCountryJurisdiction = ref(false)
const showNscs = ref(false)
const showMSWpopUp = ref(false)
const vendorPo = ref<number>()
const headers = ref<any[]>([])
const fields = ref<any[]>([])
const headersForClins = ref<any[]>(['Clins'])
const fieldsForClins = ref<any[]>(['clin'])
const juriId = ref<string>('')
const mapOfCountry = new Map()
const mapOfNSC = new Map()
const showToServiceModal = ref(false)
let fieldsForServie = ['serviceID', 'identifier']
const editLocations = ref<any[]>([])
const editCountryJurisdtion = ref<any[]>([])

let headersForService = ['Service Id', 'All_Clins']
const showModal = ref(false)
const showImportIndividual = ref(false)
const title = ref<string>('')
const selectedCountryValue = ref<string>()
const emits = defineEmits(['importContent', 'closeModal', 'selectedServicesList'])
const actionButtons = [
  { text: 'Apply' },
  { text: 'Import' },
  {
    text: 'Cancel',
    dismissModal: true
  }
]

function closeModalPopUp() {
  emits('closeModal', true)
  closeIndividualClinsPopup(true)
}

function onButtonClick(index: number) {
  if (index === 0) {
    applyIndiviudalClinsForAService()
  } else if (index === 1) {
    title.value = 'CLINS'
    showImportDailog.value = true
  } else {
    closeIndividualClinsPopup(true)
    //emits('closeModal', true)
  }
}
const actionButtonsVendor = [
  { text: 'Apply' },
  {
    text: 'Cancel',
    dismissModal: true
  }
]
function onButtonClickVendor(index: number) {
  if (index === 0) {
    applyVendorPoValue()
  } else {
    closeMWSpopup()
    emits('closeModal', true)
  }
}

expand.value = true
function created() {
  updateView()

  // EventBus.$on('onTaskOrderNumberChange', this.onTOChange);
}
function updateView() {
  if (showToggle) {
    expand.value = false
  }
  if (expand) {
    expand.value = true
  }
}
function toggleAccordian() {
  expand.value = !expand.value
}

function onTOChange() {
  //this.updateView();

  if (
    defaultid.value !== 'TOAssociatedToSelectedAgency' &&
    defaultid.value !== 'addNewMOD' &&
    defaultid.value != 'toIteration'
  ) {
    // EventBus.$emit('sectionExpanded', { id: this.defaultid, expanded: false });
    expand.value = false
  }
}

onMounted(async () => {
  const response = (await toAdminStore.getAllServices()) as any
  allServices.value = response.results.slice(0)
  allServices.value.forEach((service: any) => {
    service.checked = []
    service.checkedForJury = []
    service.enableMWS = service.serviceID === MWS
  })
  response.results.forEach((service: any) => {
    let clinidentifer = ref<any>({})
    clinidentifer.value[service.serviceID] = ALL_CLINS
    clinIdentifers.value = {
      ...clinIdentifers.value,
      ...clinidentifer
    }
  })
})
function changedIdentifer(id: string, selectedClinType: string, clinsEditButton = false): void {
  presentServiceId = id
  allIndividualClinsForAService.value = getClinsForAServiceId(id)
  if (selectedClinType === ALL_CLINS) {
    selectedIndividualClinsForAService.value = getClinsForAServiceId(id)
    updateClinsForAService()
  } else if (selectedClinType === MWS) {
    showMSWpopUp.value = true
  } else {
    if (clinIdentifers.value[id] !== selectedClinType || clinsEditButton) {
      individualClinsPopupIsOpen.value = true
      selectedIndividualClinsForAService.value = clinsEditButton
        ? getClinsForAServiceId(id, true)
        : []
      previousStateClins.value = selectedIndividualClinsForAService.value.slice(0)
      selectAllClinsCheckBoxIsChecked.value = false
    }
  }
  jurisSvcId.value = id
}
function nscChangedIdentifer(id: string): void {
  presentServiceId = id
  //allIndividualClinsForAService = getClinsForAServiceId(id);
}
function getClinsForAServiceId(id: string, selectedOnly = false): string[] {
  let clins: string[] = []
  selectedServices.value.forEach((service) => {
    if (service.serviceID === id) {
      clins = selectedOnly ? service.selectedClins : service.clins
    }
  })
  return clins.slice(0)
}

// Based on checked services in UI , we update the list of selected Services and Emit to the Parent Component.
watch(selectedServiceIds, (updatedSelectedServiceIds) => {
  let selectedServiceCompleteData = ref<any[]>([])
  updatedSelectedServiceIds.forEach((serviceId: any) => {
    const existingService = getServiceFromSelectedServicesById(serviceId)
    if (existingService.length == 0) {
      allServices.value.forEach((service) => {
        if (service.serviceID === serviceId) {
          //changes for clear up nsc from service object when it is de selected
          let tempService: any = { ...service }
          tempService.selectedClins = service.clins
          clinIdentifers.value[serviceId] = ALL_CLINS
          tempService.taskOrderNSCList = []
          tempService.locations = []
          tempService.taskOrderJurisdiction = taskOrderJurisdiction
          selectedServiceCompleteData.value.push(tempService)

          /* service.selectedClins = service.clins;
                        clinIdentifers[serviceId] = ALL_CLINS;
                        service.taskOrderNSCList = taskOrderNSCList;
                        service.taskOrderJurisdiction = taskOrderJurisdiction;
                        selectedServiceCompleteData.push(service);*/
        }
      })
    } else {
      //selectedServiceCompleteData.push(existingService[0]);
      selectedServiceCompleteData.value.push({ ...existingService[0] })
    }
  })
  // selectedServices = selectedServiceCompleteData;
  //changes for clear up nsc from service object when it is de selected
  selectedServices.value = [...selectedServiceCompleteData.value]

  emitSelectedServices()
})
function getServiceFromSelectedServicesById(id: string): any[] {
  return selectedServices.value.filter((service: any) => service.serviceID === id)
}
function isNSCsSelectedForServiceId(id: string): boolean {
  const selectedServiceObject = getServiceFromSelectedServicesById(id)
  return (
    selectedServiceObject.length > 0 &&
    selectedServiceObject[0].taskOrderNSCList &&
    selectedServiceObject[0].taskOrderNSCList.length > 0
  )
}
function isJurisdictionsSelectedForServiceId(id: string): boolean {
  const selectedServiceObject = getServiceFromSelectedServicesById(id)
  return (
    selectedServiceObject.length > 0 &&
    selectedServiceObject[0].jurisdictionCodeContry &&
    selectedServiceObject[0].jurisdictionCodeContry.length > 0
  )
}
function emitSelectedServices(): void {
  selectedServices.value = selectedServices.value.sort((a, b): any =>
    a.serviceID > b.serviceID ? 1 : -1
  )
  emits('selectedServicesList', selectedServices)
}
function closeIndividualClinsPopup(cancelFromUI = false): void {
  if (cancelFromUI == true) {
    selectedIndividualClinsForAService.value = previousStateClins.value.slice(0)
    updateClinsForAService()
  }
  previousStateClins.value = []
  selectAllClinsCheckBoxIsChecked.value = false
  individualClinsPopupIsOpen.value = false
  presentServiceId = ''
  selectedIndividualClinsForAService.value = []
  allIndividualClinsForAService.value = []
}

function closeMWSpopup() {
  showMSWpopUp.value = false
  showImportDailog.value = false
  previousStateClins.value = []
  selectAllClinsCheckBoxIsChecked.value = false
  individualClinsPopupIsOpen.value = false
  presentServiceId = ''
  selectedIndividualClinsForAService.value = []
  allIndividualClinsForAService.value = []
}
function applyIndiviudalClinsForAService(): void {
  updateClinsForAService()
  closeIndividualClinsPopup()
  // $store.dispatch("taskOrderAdmin/resetTimer");
}
function updateClinsForAService() {
  if (
    selectedIndividualClinsForAService.value.length ===
      allIndividualClinsForAService.value.length ||
    selectedIndividualClinsForAService.value.length == 0
  ) {
    clinIdentifers.value[presentServiceId] = ALL_CLINS
  } else {
    clinIdentifers.value[presentServiceId] = INDIVIDUAL_CLIN
  }
  selectedServices.value.forEach((service) => {
    if (service.serviceID === presentServiceId) {
      service.selectedClins = selectedIndividualClinsForAService.value.slice(0)
    }
  })
  emitSelectedServices()
}
function toggleSelectAllInIndividualClinsInPopup(event: any): void {
  selectedIndividualClinsForAService.value = event.target.checked
    ? allIndividualClinsForAService.value.slice(0)
    : []
}

function toggleInIndividualClinsInPopup(event: any): void {
  if (!event.target.checked) {
    selectAllClinsCheckBoxIsChecked.value = false
  }
}
function editNSCpopup(service: any, index: number) {
  service.acronym = service.serviceID
  // //$modal.show("edit-nsc-options", {
  //   selected: service,
  //   changingId: index,
  //   type: "Create",
  //   juriId: service.acronym,
  // });
  juriId.value = service.serviceID
  currentIdx.value = index
  if (isNSCsSelectedForServiceId(service.acronym)) {
    console.log('edited locations')
  } else {
    editLocations.value = []
  }

  showNscs.value = true
}
function updateNSC(data: any): void {
  let serviceId = data.value.serviceID
  let service = getServiceFromSelectedServicesById(serviceId)
  taskOrderNSCList.value = data.value.locations
  selectedServices.value = selectedServices.value.sort((a, b): any =>
    a.serviceID > b.serviceID ? 1 : -1
  )
  selectedServices.value.forEach((service) => {
    if (service.serviceID === data.value.serviceID) {
      //service.taskOrderNSCList = taskOrderNSCList;
      service.taskOrderNSCList = [...taskOrderNSCList.value] //fix select/deselect service to get latest nsdlist. FFP-18207
    }
  })
  editLocations.value = data.value.locations
  mapOfNSC.set(serviceId, editLocations.value)
  emits('selectedServicesList', selectedServices)
}
//FFP-17182
function serviceCountryChangedIdentifer(sid: string): void {
  presentServiceId = sid
  jurisSvcId.value = sid
}
//FFP-17182
function editCountryJurisdtionPopup(service: any) {
  service.acronym = service.serviceID
  jurisSvcId.value = service.acronym
  checkedService.value = service
  showCountryJurisdiction.value = true
  //$bvModal.show("service-jurisdiction");
}
//FFP-17182
function updateCountryJurisdiction(selectedJurisdictions: any) {
  //when apply clicked on CJ popup
  //selectedCountryJurisditions = [...selectedJurisdictions];
  //let index = 0;
  //let updatedService: any = {};
  selectedServices.value.forEach((service) => {
    if (service.serviceID === jurisSvcId.value) {
      // updatedService = {...service};
      //updatedService.jurisdictionCodeContry = [...selectedJurisdictions];
      service.jurisdictionCodeContry = [...selectedJurisdictions.value]
    }
  })
  editCountryJurisdtion.value = selectedJurisdictions.value
  selectedCountryValue.value = jurisSvcId.value
  mapOfCountry.set(selectedCountryValue.value, editCountryJurisdtion.value)
  //selectedServices = selectedServices.map(selectedService => selectedService.serviceID !== updatedService.serviceID ? selectedService : updatedService);
  emitSelectedServices()
}
function checkCheckboxStatus(event: any, data: any) {
  isChecked.value = event.target.checked
  data.checked.push(isChecked)
  data.checkedForJury.push(isChecked)
  isCheckedBox.value = event.target.checked
  data.checkBoxChecked = event.target.checked
}

function allowNumbersOnly(event: any) {
  const value = String.fromCharCode(event.charCode)
  const allowedChar = /^\d+$/g
  if (!allowedChar.test(value)) {
    event.preventDefault()
  }
}

function applyVendorPoValue() {
  const idx = selectedServices.value.findIndex((service) => service.serviceID === MWS)
  if (idx > -1) {
    selectedServices.value[idx]['vendorPo'] = vendorPo ? Number(vendorPo) : null
    emitSelectedServices()
  }
  closeMWSpopup()
}

function openClinDialog() {
  title.value = 'CLINS'
  fields.value = fieldsForClins.value
  headers.value = headersForClins.value
  showModal.value = true
  //$forceUpdate();
}

function openServiceDialog() {
  title.value = 'To Services'
  fields.value = fieldsForServie
  headers.value = headersForService
  showModal.value = true
  showImportDailog.value = true
  //$forceUpdate();
}

function importedFileInfo(details: any = {}) {
  const { contents } = details
  if (title.value === 'CLINS') {
    const importedData =
      allIndividualClinsForAService.value.filter((clin) =>
        contents.some((data: any) => clin.includes(data.clin))
      ) || []
    selectedIndividualClinsForAService.value = [...importedData]
  } else {
    importForService(contents)
  }
  showModal.value = false
  //$forceUpdate();
}

function importForService(contents = []) {
  selectedServiceIds.value = contents.map((data: any) => data.serviceID)
  const selectedServiceCompleteData = ref<any[]>([])
  contents.forEach(({ serviceID, identifier }) => {
    const existingService = getServiceFromSelectedServicesById(serviceID)
    if (existingService.length == 0) {
      allServices.value.forEach((service) => {
        if (service.serviceID === serviceID) {
          let tempService = { ...service }
          tempService.selectedClins = identifier == 'N' ? [] : service.clins
          clinIdentifers.value[serviceID] = identifier == 'N' ? INDIVIDUAL_CLIN : ALL_CLINS
          tempService.taskOrderNSCList = []
          tempService.locations = []
          tempService.taskOrderJurisdiction = []
          selectedServiceCompleteData.value.push(tempService)
        }
      })
    } else {
      clinIdentifers.value[serviceID] = identifier == 'N' ? INDIVIDUAL_CLIN : ALL_CLINS
      selectedServiceCompleteData.value.push({
        ...existingService[0],
        selectedClins: identifier == 'N' ? [] : existingService[0].clins
      })
    }
  })
  selectedServices.value = [...selectedServiceCompleteData.value]

  emitSelectedServices()
}
function getTitle() {
  return 'Add NSCs to ' + juriId
}
</script>

<template>
  <div class="chi-accordion -md" id="example-size-md">
    <div class="chi-accordion__item" :class="expand ? '-expanded' : ''">
      <button class="chi-accordion__trigger" @click="toggleAccordian()">
        <i class="chi-icon icon-chevron-down" aria-hidden="true"></i>
        <div class="chi-accordion__title">TO Services and CLINs Awarded</div>
      </button>

      <div class="chi-accordion__content" style="overflow: visible"></div>

      <div style="position: relative">
        <button
          v-if="allServices && allServices.length > 0"
          class="chi-button -sm"
          style="position: absolute; right: 1em; top: -4em"
          @click="openServiceDialog"
        >
          Import
        </button>
        <div
          class="bottom-marigin-10"
          style="max-height: 500px; overflow-y: scroll; overflow-x: auto"
        >
          <Loader :spin="!allServices || allServices.length === 0"></Loader>
          <div
            class="m-form__item -pl--3"
            v-for="(s, index) in allServices"
            :key="`${s}-${index}`"
            v-if="expand"
          >
            <div class="chi-grid">
              <div class="chi-checkbox chi-col -sm--w3 max-size3">
                <!--<span v-if="valid && selectedServiceIds.includes(s.serviceID)" class="required"/>-->

                <input
                  type="checkbox"
                  class="chi-checkbox__input"
                  @click="checkCheckboxStatus($event, s)"
                  :value="s.serviceID"
                  v-model="selectedServiceIds"
                  :id="`service_${s.serviceID}`"
                />

                <label class="chi-checkbox__label" :for="`service_${s.serviceID}`">
                  <span
                    v-if="valid !== undefined && valid.includes(s.serviceID)"
                    class="required"
                  />{{ s.serviceID }} -
                  {{ s.serviceDesc }}
                </label>
              </div>
              <div
                class="chi-checkbox chi-col -sm--w2 max-size2"
                v-if="selectedServiceIds.includes(s.serviceID)"
              >
                <div @click="changedIdentifer(s.serviceID, ALL_CLINS)">
                  <div class="-d--flex -align-items--baseline">
                    <input
                      value="all"
                      type="radio"
                      class="chi-radio__input"
                      v-model="clinIdentifers[s.serviceID]"
                    />
                    <label class="chi-radio__label">All CLINs</label>
                  </div>
                </div>
              </div>
              <div>
                <div
                  class="chi-checkbox chi-col -w2 max-size"
                  v-if="selectedServiceIds.includes(s.serviceID)"
                  @click="changedIdentifer(s.serviceID, INDIVIDUAL_CLIN)"
                >
                  <div class="-d--flex -align-items--baseline">
                    <input
                      value="individual"
                      type="radio"
                      class="chi-radio__input"
                      v-model="clinIdentifers[s.serviceID]"
                    />
                    <label class="chi-radio__label" style="white-space: pre"
                      >Individual CLINs</label
                    >
                  </div>
                </div>
              </div>
              <div class="chi-col -sm--w2 max-size">
                <button
                  class="chi-button -sm --primary"
                  v-if="
                    selectedServiceIds.includes(s.serviceID) &&
                    clinIdentifers[s.serviceID] === INDIVIDUAL_CLIN
                  "
                  @click="changedIdentifer(s.serviceID, INDIVIDUAL_CLIN, true)"
                >
                  View/Edit CLINs
                </button>
              </div>

              <div
                class="chi-col -w2 max-size"
                v-if="selectedServiceIds.includes(s.serviceID) && s.enableMWS"
                @click="changedIdentifer(s.serviceID, MWS)"
              >
                <div>
                  <button
                    @click="changedIdentifer(s.serviceID, MWS)"
                    class="chi-button -sm --primary"
                    style="width: max-content"
                    :id="`createClin_${s.serviceID}`"
                  >
                    {{ !!vendorPo ? 'View/Edit Vendor PO' : 'Add Vendor PO' }}
                  </button>
                </div>
              </div>

              <div class="chi-col -sm--w3" v-if="selectedServiceIds.includes(s.serviceID)">
                <div v-if="s.cbsaBased !== 'No'">
                  <button
                    class="chi-button -sm --primary"
                    style="width: max-content; padding-inline: 1em"
                    :disabled="isNSCsSelectedForServiceId(s.serviceID)"
                    @click="editCountryJurisdtionPopup(s)"
                  >
                    {{ isJurisdictionsSelectedForServiceId(s.serviceID) ? 'View/Edit' : 'Add' }}
                    Country/Jurisdiction IDs
                  </button>
                </div>
              </div>

              <div
                class="chi-col -sm--w2"
                v-if="selectedServiceIds.includes(s.serviceID)"
                @click="nscChangedIdentifer(s.serviceID)"
              >
                <div v-if="s.cbsaBased !== 'No'">
                  <button
                    class="chi-button -sm --primary"
                    style="width: max-content"
                    :disabled="isJurisdictionsSelectedForServiceId(s.serviceID)"
                    @click="editNSCpopup(s, index)"
                  >
                    {{ isNSCsSelectedForServiceId(s.serviceID) ? 'View/Edit' : 'Add' }} NSCs
                  </button>
                </div>
              </div>
            </div>
            <div v-if="showNscs">
              <ServiceLocations
                @applyNSC="updateNSC"
                v-show="index == currentIdx"
                @closeModal="showNscs = false"
                :title="'Add NSCs to ' + juriId"
                :jurisSvcId="juriId"
                :editLocations="editLocations"
                :mapOfNSC="mapOfNSC"
              />
            </div>

            <div class="chi-divider" v-if="index !== allServices.length - 1"></div>
          </div>
          <div v-if="showCountryJurisdiction">
            <ServiceCountryJurisdiction
              :selectedServices="selectedServices"
              :currentServiceId="jurisSvcId"
              :currentSelectedService="checkedService"
              :showModal="checkedService"
              @closeModal="showCountryJurisdiction = false"
              :title="'Select TO Country/Jurisdiction Awarded by Service -' + jurisSvcId"
              @applyCountryJurisdiction="updateCountryJurisdiction"
              :editCountryJurisdtion="editCountryJurisdtion"
              :selectedCountryValue="selectedCountryValue"
              :mapOfCountry="mapOfCountry"
            />
          </div>
        </div>

        <Modal
          v-if="individualClinsPopupIsOpen"
          v-model="individualClinsPopupIsOpen"
          :title="'Select TO CLINs Awarded by Service - ' + presentServiceId"
          @onButtonClick="onButtonClick"
          :buttons="actionButtons"
          @on-dismiss-modal="closeModalPopUp"
        >
          <div class="content-modal">
            <table class="chi-table -bordered -striped">
              <thead class="header">
                <tr style="background-color: #38c6f4">
                  <td class="table-cell-v1">
                    <input
                      class="chi-checkbox__input"
                      type="checkbox"
                      id="all"
                      v-model="selectAllClinsCheckBoxIsChecked"
                      @click="toggleSelectAllInIndividualClinsInPopup($event)"
                    />
                    <label class="chi-checkbox__label" for="all">
                      <div class="-sr--only">Label</div>
                    </label>
                  </td>
                  <td class="table-cell-v2">CLIN</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(clinItem, index) in allIndividualClinsForAService"
                  :key="`clin-${index}`"
                >
                  <td class="table-cell-v1">
                    <input
                      :value="clinItem"
                      v-model="selectedIndividualClinsForAService"
                      :id="`clin-${index}`"
                      @click="toggleInIndividualClinsInPopup($event)"
                      class="chi-checkbox__input"
                      type="checkbox"
                    />
                    <label class="chi-checkbox__label" :for="`clin-${index}`">
                      <div class="-sr--only">
                        {{ 'Label-' + 'clin-' + clinItem.substr(0, 7) }}
                      </div>
                    </label>
                  </td>
                  <td class="table-cell-v2">{{ clinItem }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal>

        <!-- Vendor Po Modal !-->
        <Modal
          v-if="showMSWpopUp"
          v-model="showMSWpopUp"
          height="auto"
          title="Vendor PO"
          :scrollable="true"
          @onButtonClick="onButtonClickVendor"
          :buttons="actionButtonsVendor"
          @on-dismiss-modal="closeModalPopUp"
        >
          <div>
            <input
              class="chi-input input_bg"
              maxlength="10"
              placeholder="PO Number"
              type="text"
              id="vendorPo"
              v-model="vendorPo"
              @keypress="allowNumbersOnly($event)"
            />
          </div>
        </Modal>

        <!-- Import Modal -->
        <div v-if="showImportDailog">
          <ImportFileComponent
            :title="title"
            :showModal="showImportDailog"
            :dataFields="fields"
            :headerDetails="headers"
            @importContent="importedFileInfo($event)"
            @closeModal="showImportDailog = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.content-modal {
  height: 250px;
}
.m-form__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.fieldset-conatiner {
  max-height: 480px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.chi-grid {
  margin-right: 0px;
}
.chi-divider {
  border: 0;
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.12);
  margin: 0.5rem 0;
}
.table-cell-v2 {
  text-align: left !important;
}
.required::after {
  content: '*\00a0';
  color: red;
}
.chi.chi-modal__content {
  overflow-y: auto;
  height: 350px;
}

.max-size {
  flex: 0 1 15%;
  max-width: 15%;
  padding: 0 6px;
}

.max-size2 {
  flex: 0 1 9%;
  max-width: 9%;
  padding: 0 6px;
}

.max-size3 {
  flex: 0 1 18%;
  max-width: 18%;
  padding: 0 6px;
}
</style>
