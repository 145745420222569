import { computed } from 'vue'
import {
  InputType,
  Validation,
  ErrorType,
  StateType,
  toNotBeEmpty,
  toBeANumber,
  toBeAMonthDayYear
} from '@centurylinkfederal/eis-vue'
import { defineStore } from 'pinia'

export const useColumns = defineStore('financialColumnsStore', () => {
  const columns = computed(() => [
    {
      field: 'taskOrderCeiling',
      label: 'Ceiling Amount',
      input: {
        type: 'text',
        maxLength: 50,
        required: true,
        tests: [toNotBeEmpty, toBeANumber, toNotBeNegative]
      }
    },
    {
      field: 'taskOrderAgfRate',
      label: 'AGF Rate (%)',

      input: {
        type: 'text',
        required: true,
        value: '4.75',
        tests: [toNotBeEmpty, toBeANumber]
      }
    },
    {
      field: 'performancePeriodFunding',
      label: 'Performance Period Funding',

      input: {
        type: 'text',
        maxLength: 50,
        required: true,
        tests: [toNotBeEmpty, toBeANumber, toNotBeNegative]
      }
    },
    {
      field: 'prorationType',
      label: 'Proration Type',
      input: {
        type: 'select',
        options: [
          ['', '- Select -'],
          'Month-Length Proration',
          'Normalized 30-Day Month Proration'
        ],
        required: true,
        disabled: false,
        value: '',
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'performanceBeginDate',
      label: 'Begin Date',
      input: {
        type: 'date',
        format: 'MM/DD/YYYY',
        required: true,
        tests: [toNotBeEmpty, toBeAMonthDayYear]
      }
    },
    {
      field: 'performanceEndDate',
      label: 'End Date',
      input: {
        type: 'date',
        format: 'MM/DD/YYYY',
        required: true,
        tests: [toNotBeEmpty, toBeAMonthDayYear]
      }
    },
    {
      field: 'createdOn'
    },
    {
      field: 'createdBy'
    },
    {
      field: 'updatedBy'
    },
    {
      field: '_deleted',
      input: {
        hidden: true,
        value: false
      }
    }
  ])

  return {
    columns
  }
})

export function toNotBeNegative(input: InputType): Validation {
  let state: StateType = undefined
  let error: ErrorType = undefined
  const dangerState = 'danger'
  const dangerError = {
    message:
      input?.dangerMessage ||
      [input?.label, `must not be a negative number.`].filter((f) => f).join(' ')
  }
  const value = input.value ? String(input.value).trim() : input.value
  state = 'success'
  if (value < 0) {
    ;[state, error] = [dangerState, dangerError]
  }
  return [state, error]
}
