<script setup lang="ts">
import { randomId } from '@centurylinkfederal/eis-vue'
import { computed } from 'vue'

export interface Props {
  id?: string
  number?: string
  title?: string
  active?: boolean
  disabled?: boolean
  state?: string
  changeButtonTitle?: string
  changeButtonIcon?: string
  settings?: any
}
const props = withDefaults(defineProps<Props>(), {
  id: undefined,
  number: undefined,
  title: undefined,
  active: undefined,
  disabled: undefined,
  state: undefined,
  changeButtonTitle: 'Expand',
  changeButtonIcon: undefined,
  settings: () => {
    return {
      adHoc: false,
      noPrevious: false,
      noNext: false,
      noFooter: false
    }
  }
})
const emit = defineEmits(['onAction'])
const done = computed(() =>
  ['unchanged', 'primary', 'success', 'danger', 'warning', 'muted'].includes(String(props.state))
    ? true
    : false
)
const cid = computed(() => props.id ?? 'epanel-' + randomId)
function onAction(action: string, payload: any = undefined): void {
  emit('onAction', action, payload)
}
</script>
<template>
  <div
    :id="cid"
    :class="[
      'chi-epanel -w--auto',
      { '-state-icon': true, '-active': active, '-disabled': disabled, '-done': done }
    ]"
  >
    <div class="chi-epanel__header">
      <i
        :class="[
          'chi-icon -sm--2 -state',
          '-icon--' + state,
          { 'icon-circle-check': ['success', 'primary', 'muted'].includes(state) },
          { 'icon-circle-warning': state === 'warning' || state === 'danger' },
          { 'icon-circle': state === 'done' }
        ]"
      ></i>
      <div class="chi-epanel__number">{{ number }}</div>
      <div class="chi-epanel__title">{{ title }}</div>
      <div class="chi-epanel__content">
        <div class="chi-epanel__collapse">
          <div class="-done--only"><slot name="done" /></div>
          <div class="-disabled--only"><slot name="disabled" /></div>
        </div>
      </div>
      <div v-if="!active" class="chi-epanel__action" :class="{ ' -done--only': !settings.adHoc }">
        <slot name="end">
          <button
            class="chi-button -primary -flat"
            :class="{ '-disabled': disabled }"
            data-chi-epanel-action="active"
            @click="onAction('active')"
          >
            <span>{{ changeButtonTitle }}</span>
          </button>
        </slot>
      </div>
    </div>
    <div class="chi-epanel__collapse">
      <div class="-active--only">
        <div class="chi-epanel__body">
          <div class="chi-epanel__content">
            <slot />
          </div>
          <div
            v-if="!settings.noFooter"
            class="chi-epanel__footer -justify-content--end"
            :class="[settings.classFooter]"
          >
            <slot name="next">
              <div class="chi-button-group">
                <button
                  v-if="!settings.noPrevious"
                  class="chi-button -flat -primary"
                  data-chi-epanel-action="previous"
                  @click="onAction('previous')"
                >
                  <div class="chi-button__content">
                    <i class="chi-icon icon-chevron-left -xs" aria-hidden="true"></i>
                    <span>Previous</span>
                  </div>
                </button>
                <button
                  v-if="!settings.noNext"
                  class="chi-button -flat -primary"
                  data-chi-epanel-action="next"
                  @click="onAction('next')"
                >
                  <div class="chi-button__content">
                    <span>Next</span>
                    <i class="chi-icon icon-chevron-right -xs" aria-hidden="true"></i>
                  </div>
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.-cursor--default {
  cursor: default;
}
a:hover.-cursor--default {
  cursor: default;
}
a:hover.-text--no-decoration {
  text-decoration: none;
}
.-disabled--only,
.-done--only,
.-active--only {
  display: none;
}
.-disabled .-disabled--only,
.-done .-done--only,
.-active .-active--only {
  display: block;
}
</style>
