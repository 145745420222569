<script lang="ts" setup>
import { defineProps, ref, withDefaults, onMounted } from 'vue'
import { useTOAdminStore } from '../../../store/to-admin'

const toAdminStore = useTOAdminStore()
const iscustom = ref(false)
const isLoading = ref(false)
const refKPIs = ref<any[]>([])

onMounted(() => {
  isLoading.value = true
  toAdminStore
    .getRefKpis()
    .then((response: any) => {
      refKPIs.value = response
      isLoading.value = false
    })
    .catch(() => {
      isLoading.value = false
    })
})

const updateRadioButtonSelect = (inValue: boolean) => {
  iscustom.value = inValue
}
</script>
<template>
  <div style="margin-top: 20px">
    <div class="chi-grid">
      <div class="chi-col -w--3 -mb--2 -text--md">Submission KPIs</div>
      <div v-if="isLoading" class="chi-col">
        <svg class="chi-spinner -icon--primary -xs" viewBox="0 0 66 66">
          <title>Loading</title>
          <circle class="path" cx="33" cy="33" r="30" fill="none" stroke-width="6"></circle>
        </svg>
      </div>
      <div v-else class="chi-col -w--1">
        <div class="chi-form__item -inline">
          <div class="chi-radio">
            <input
              id="radio_none"
              class="chi-radio__input"
              type="radio"
              name="kpiOption"
              value="none"
              :checked="!iscustom"
              @change="updateRadioButtonSelect(false)"
            />
            <label class="chi-radio__label -mr--2" for="radio_none">None</label>

            <input
              id="radio_custom"
              class="chi-radio__input"
              type="radio"
              name="kpiOption"
              value="custom"
              :checked="iscustom"
              @change="updateRadioButtonSelect(true)"
            />
            <label class="chi-radio__label -mr--2" for="radio_custom">Custom</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
