<template>
  <div class="-text--center" v-if="spin">
    <svg class="a-spinner" viewBox="0 0 66 66">
      <title>Loading</title>
      <circle class="path" cx="33" cy="33" r="30" fill="none" stroke-width="6"></circle>
    </svg>
  </div>
</template>

<script lang="ts" setup>
import { watch, ref, onMounted, Ref } from 'vue'

const spin = ref(false)

onMounted(() => {
  //nextElementSibling["style"].visibility = spin.value ? "hidden" : "visible";
})
// @Watch("spin")
//   onChange() {
//     this.$el.nextElementSibling["style"].visibility = this.spin ? "hidden" : "visible";
//   }
</script>

<style></style>
