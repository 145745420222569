import { computed } from 'vue'
import { defineStore } from 'pinia'

export const useColumns = defineStore('viewDetailsColumnsStore', () => {
  const columns = computed(() => [
    {
      field: 'contractorInvoiceLvlAcctNo',
      label: 'Contract Number'
    },
    {
      field: 'agencyIdentifier',
      label: 'Agency'
    },
    {
      field: 'taskOrderNumber',
      label: 'Task Order Number'
    },
    {
      field: 'toProgramName',
      label: 'Program Name'
    },
    {
      field: 'originalPramataId',
      label: 'CLM ID'
    },
    {
      field: 'startDate',
      label: 'Start Date',
      formatFn: mdyFormat
    },
    {
      field: 'stopDate',
      label: 'End Date',
      formatFn: mdyFormat
    },
    {
      field: 'fullyLoaded',
      label: 'Fully Loaded',
      formatFn: (value: any) => (value === 'Y' ? 'Yes' : 'No')
    },
    {
      field: 'invoiceOptions',
      label: 'Invoice Options',
      formatFn: (_value: any, row: any) =>
        [
          row.ioCustom === 'Y' ? 'Custom Tax, Fees & Surcharges' : null,
          row.ioDoi === 'Y' ? 'DOI' : null,
          row.etems === 'Y' ? 'ETEMS' : null,
          row.ioIppSummary === 'Y' ? 'IPP Summary' : null,
          row.ioIppDetail === 'Y' ? 'IPP Details' : null
        ]
          .filter((f) => f)
          .join('; '),
      classCol: 'w-xl-2h--4 -w-xl--6 -w-lg--8 -w-md--12 -w--12 -text--wrap'
    },
    {
      field: 'billingNotifications',
      label: 'Billing Notifications',
      formatFn: (_value: any, row: any) => (row.billingContacts?.length ? 'Yes' : 'No')
    },
    {
      field: 'statusManaged',
      label: 'Managed/Unmanaged',
      formatFn: (_value: any, row: any) => String(row.statusType).replace(',', ', '),
      classCol: 'w-xl-2h--3 -w-xl--3 -w-lg--4 -w-md--8  -text--wrap'
    },
    {
      field: 'enWorkAuto',
      label: 'Enh Work Order Automn',
      formatFn: (value: any) => (value === 'Y' ? 'Yes' : 'No')
    },
    {
      field: 'dashboardDisplay',
      label: 'Dashboard Display',
      formatFn: (value: any) => (value === 'Y' ? 'Yes' : 'No')
    },
    {
      field: 'enhancedEmailNotification',
      label: 'Enh Email Notifications',
      formatFn: (value: any) => (value === 'Y' ? 'Yes' : 'No')
    },
    {
      field: 'svcDeliveryTest',
      label: 'Svc Delivery Test & Accpt',
      formatFn: (_value: any, row: any) =>
        [row.svcDeliveryNumDays, row.svcDeliveryDaysType, 'days'].join(' ')
    },
    {
      field: 'comments',
      label: 'Comments',
      classCol: '-w-md--12 -w-lg--12 -w-xl--8 -word--wrap'
    }
  ])

  return {
    columns
  }
})

function mdyFormat(isoDate: string): string {
  if (!isoDate) {
    return ''
  }
  const parts = isoDate.split('-')
  return [parts[1], parts[2], parts[0]].join('/')
}
