/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewsEventDTO } from '../models/NewsEventDTO'
import type { PaginatedResultsNewsEventDTO } from '../models/PaginatedResultsNewsEventDTO'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class NewsControllerService {
  /**
   * Update a news item.
   * This API is used to update news items for administration purposes.
   * @param authorization
   * @param requestBody
   * @returns NewsEventDTO OK
   * @throws ApiError
   */
  public static updateNewsEvents(
    authorization: string,
    requestBody: NewsEventDTO
  ): CancelablePromise<NewsEventDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1/toadmin/news',
      headers: {
        Authorization: authorization
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`
      }
    })
  }
  /**
   * Create a news item.
   * This API is used to create news items for administration purposes.
   * @param authorization
   * @param requestBody
   * @returns NewsEventDTO OK
   * @throws ApiError
   */
  public static createNewsEvents(
    authorization: string,
    requestBody: NewsEventDTO
  ): CancelablePromise<NewsEventDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/toadmin/news',
      headers: {
        Authorization: authorization
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`
      }
    })
  }
  /**
   * Get news items
   * This API is used to retrieve list of news items for administration purposes.
   * @param page
   * @param pageSize
   * @returns PaginatedResultsNewsEventDTO OK
   * @throws ApiError
   */
  public static getNewsEvents(
    page: number,
    pageSize: number
  ): CancelablePromise<PaginatedResultsNewsEventDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/toadmin/news/{page}/{pageSize}',
      path: {
        page: page,
        pageSize: pageSize
      },
      errors: {
        400: `Bad Request`
      }
    })
  }
  /**
   * Get active news items
   * This API is used to retrieve list of active news items for the EIS portal.
   * @param page
   * @param pageSize
   * @returns PaginatedResultsNewsEventDTO OK
   * @throws ApiError
   */
  public static getActiveNewsEvents(
    page: number,
    pageSize: number
  ): CancelablePromise<PaginatedResultsNewsEventDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/toadmin/active-news/{page}/{pageSize}',
      path: {
        page: page,
        pageSize: pageSize
      },
      errors: {
        400: `Bad Request`
      }
    })
  }
  /**
   * Delete a news item.
   * This API is used to hard delete a news item for administration purposes.
   * @param id
   * @returns any OK
   * @throws ApiError
   */
  public static deleteNewsEvents(id: number): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/toadmin/news/{id}',
      path: {
        id: id
      },
      errors: {
        400: `Bad Request`
      }
    })
  }
}
