import { ref, computed, onMounted } from 'vue'
import { toBeOptional, toNotBeEmpty } from '@centurylinkfederal/eis-vue'
import { defineStore } from 'pinia'
import { useToAdminAPI } from '../../composables/to-admin/api'

export const useColumns = defineStore('billingColumnsStore', () => {
  const api = useToAdminAPI()
  const codesOptions = ref<any>({})
  const selectOption = ref(['', '- Select -'])
  const columns = computed(() => [
    {
      field: 'kpiucUnitCode',
      label: 'KPI',
      class: '-size--md-2 -align-self--start -text--wrap',
      required: true,
      input: {
        type: 'select',
        options: [['', '- Select KPI -'], ...(codesOptions.value?.KPIUC ?? [])],
        value: '',
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'perfStdThreshold',
      label: 'PERF Std',
      class: '-size--sm-2 -text--wrap',
      required: true,
      input: {
        type: 'text',
        placeholder: 'Threshold',
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'kpiaoAqlQOprCode',
      label: 'AQL OP',
      class: '-size--sm-2 -text--wrap',
      required: true,
      input: {
        type: 'select',
        options: [selectOption.value, ...(codesOptions.value?.KPIAO ?? [])],
        value: '',
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'kpimuMeasUnitCode',
      label: 'Meas Unit',
      class: '-size--sm-2 -text--wrap',
      required: true,
      input: {
        type: 'select',
        options: [selectOption.value, ...(codesOptions.value?.KPIMU ?? [])],
        value: '',
        required: true,
        tests: [toNotBeEmpty]
      }
    },
    {
      field: 'effectiveStartDate',
      label: 'Effective Start Date',
      class: '-size--md-1 -align-self--start -text--wrap',
      input: {
        type: 'date',
        required: false,
        tests: [toBeOptional]
      }
    },
    {
      field: 'effectiveEndDate',
      label: 'Effective End Date',
      class: '-size--md-1 -align-self--start -text--wrap',
      input: {
        type: 'date',
        required: false,
        tests: [toBeOptional]
      }
    },
    {
      field: 'waive',
      label: '',
      class: '-flex--grow0 -align-self--start -mt--0-5 -px--0 -mx--0',
      input: {
        type: 'toggle',
        mode: 'button',
        label: 'Waive',
        disabled: true,
        class: '-sm -px--2'
      }
    },
    {
      field: '_deleted',
      label: '',
      class: '-flex--grow0 -align-self--start -mt--0-5 -px--0 -mx--0',
      input: {
        type: 'toggle',
        mode: 'button',
        label: '',
        icon: 'delete',
        iconSize: 'sm',
        class: '-sm -danger -flat  -px--2',
        tooltip: 'Delete'
      }
    },
    {
      field: 'additionalInfo',
      hidden: true
    },
    {
      field: 'taskOrderMetaData',
      hidden: true
    },
    {
      field: 'stdCustomIndicator',
      hidden: true,
      input: {}
    },
    {
      field: 'kpiType',
      hidden: true,
      input: {}
    }
  ])

  onMounted(async () => {
    const codesRes = await api.getCodes({
      transactionCode: ['KPIUC', 'KPIAO', 'KPIMU', 'KPILQ', 'KPISLQ']
    })
    if (typeof codesRes === 'object') {
      const codesEntries = Object.keys(codesRes).map((codeKey) => [
        codeKey,
        Object.entries(codesRes[codeKey])
      ])
      codesOptions.value = Object.fromEntries(codesEntries)
    }
  })
  return {
    columns
  }
})
