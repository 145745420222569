<script lang="ts" setup>
import { ref, computed, onMounted, watch, withDefaults, defineProps, toRaw } from 'vue'
import { storeToRefs } from 'pinia'
import { useStore } from './contactsStore'
import { useColumns } from './contactsColumns'
import DataCard from '../common/DataCard.vue'
import ActionButton from '../common/ActionButton.vue'
import ImportModal from '../common/ImportModal.vue'
import { Row, Input, randomId, Signal } from '@centurylinkfederal/eis-vue'
import { getStore } from './store'
import { doScrollToElement } from '../../utils/ui'
import { triggerForm } from '../../utils/form'

const store = useStore()
const { resetSeq, form } = storeToRefs(store)
const { flush } = store
export interface Props {
  pageMode: 'create' | 'edit'
}
const props = withDefaults(defineProps<Props>(), {
  pageMode: 'create'
})
const pageMode = ref(props.pageMode)
const pageStore = getStore(pageMode.value)()
const {
  resetSeq: pageResetSeq,
  taskOrderDetailsRes,
  loadSeq: pageLoadSeq,
  signal
} = storeToRefs(pageStore)
const columnsStore = useColumns()
const { columns } = storeToRefs(columnsStore)
const isImportModalVisible = ref(false)
const settings = {
  key: 'taskOrderBillingContactId',
  footerLabelClass: '-d--none -d-xl--inline'
}
const rows = ref<Row[]>([])
const emptyDataId = ref('emptyData-' + randomId())
const scrollOpts = { wait: 100, left: 0, useOffsetHight: true }

watch(() => signal.value, onSignal, { deep: true })

async function onSignal([signalKey, payload]: Signal): Promise<void> {
  switch (signalKey) {
    case 'previous':
    case 'next':
    case 'active': {
      if (payload?.panel === 'billing-contacts') {
        if (rows.value.length === 0) {
          onInsert()
        }
      }
      break
    }
  }
}
function resetForm(
  keyValue: string | number | undefined = undefined,
  item: undefined | Record<string, any> = undefined
): void {
  if (pageMode.value === 'edit') {
    if (!keyValue) {
      rows.value = taskOrderDetailsRes.value?.taskOrderDto?.billingContacts
        ? // eslint-disable-next-line no-unsafe-optional-chaining
          [...taskOrderDetailsRes.value?.taskOrderDto?.billingContacts]
        : []
    }
  }
  for (const row of rows.value) {
    if (keyValue && row[settings.key] !== keyValue) {
      continue
    }
    const rowKey = row[settings.key]
    let inputs = form.value[rowKey] ?? {}
    for (const field in row) {
      const value = row[field]
      inputs[field] = new Input({ field, value })
    }
    for (const cell of columns.value) {
      const { label, field, input } = cell as any
      const value = item?.[field] ?? row[field] ?? inputs?.[field]?.value ?? input?.value
      inputs[field] = new Input({ ...input, label, field, value })
    }
    form.value = { ...form.value, [rowKey]: inputs }
  }
}
async function onInput(newValue: any, cell: any): Promise<void> {
  if (!cell) {
    return
  }
  const { key, field } = cell
  const inputs = form.value[key]
  const input = inputs?.[field]
  if (!input) {
    return
  }
  input.set(newValue)
  input.validate()

  // eslint-disable-next-line no-empty
  switch (field) {
  }
}
function onInsert(_index = 0): void {
  const keyValue = '_new-' + randomId()
  const newRow = {
    [settings.key]: keyValue
  }
  rows.value.push(newRow)
  resetForm(keyValue)
  doScrollToElement('#dataCard-' + keyValue, scrollOpts)
  setTimeout(() => {
    document
      .getElementById('dataCard-' + keyValue)
      ?.querySelector('input')
      ?.focus()
  }, 100)
}
function onDelete(index: number): void {
  const row = rows.value[index]
  delete form.value[row?.[settings.key]]
  rows.value.splice(index, 1)
  const currentRow = rows.value[index - 1]
  const keyValue = currentRow?.[settings.key]
  const selector = keyValue ? '#dataCard-' + keyValue : '#' + emptyDataId.value
  doScrollToElement(selector, scrollOpts)
}
function onReset(index: number | undefined = undefined): void {
  if (typeof index === 'undefined') {
    return
  }
  const currentRow = rows.value[index]
  const keyValue = currentRow?.[settings.key]
  if (!keyValue) {
    return
  }
  resetForm(keyValue)
}
onMounted(() => {})

function handleContactsImport({ contents }: { contents: any[] }): void {
  contents.forEach((contact) => {
    const keyValue = '_new-' + randomId()
    rows.value.push({ [settings.key]: keyValue })
    resetForm(keyValue, contact)
  })
  closeModal()
}
function toggleImportModal(): void {
  isImportModalVisible.value = !isImportModalVisible.value
}

function validateContactsImport(headers: any, _data: any) {
  const requiredHeaders = ['Contact Name', 'E-mail']
  if (!requiredHeaders.every((h) => headers.includes(h))) {
    return { isValid: false, errorMsg: 'Invalid headers for Contact Import' }
  }
  return { isValid: true, errorMsg: '' }
}

function closeModal() {
  isImportModalVisible.value = false
}

watch(
  () =>
    [
      taskOrderDetailsRes.value?.taskOrderDto?.taskOrderId,
      pageResetSeq.value,
      pageLoadSeq.value
    ].join(':'),
  async (_newSeq) => {
    flush()
    rows.value = []
    resetForm()
    resetSeq.value++
  }
)
</script>

<template>
  <div v-if="rows.length > 0" :key="resetSeq" class="chi-grid">
    <div
      v-for="(row, index) in rows"
      :key="index"
      class="chi-col -mb--2 -w-xl--6 -w-lg--6 -w-md--6 -w--12"
    >
      <DataCard
        :id="'dataCard-' + row?.[settings.key]"
        :key="row?.[settings.key]"
        class=""
        classColDefault="-w-xl--6 -w-lg--6 -w-md--12 -w--12"
        :columns="columns"
        :settings="settings"
        :inputs="form?.[row?.[settings.key]]"
        :row="row"
        :count="{ current: index + 1, total: rows.length }"
        @onInput="onInput"
        @onInsert="onInsert(index)"
        @onDelete="onDelete(index)"
        @onReset="onReset(index)"
      />
    </div>
  </div>
  <div class="-d--flex -justify-content--center -m--1" :class="{ '-my--6': rows.length === 0 }">
    <div class="chi-divider -vertical">
      <ActionButton icon="circle-plus" text="Add new record" @onClick="onInsert" />
    </div>
    <div>
      <ActionButton icon="file-export" text="Import" @onClick="toggleImportModal" />
    </div>
  </div>

  <ImportModal
    v-if="isImportModalVisible"
    title="Contact Import"
    :dataFields="['name', 'billingEmail']"
    :headerDetails="['Contact Name', 'E-mail']"
    :customValidation="validateContactsImport"
    @importContent="
      (e) => {
        handleContactsImport(e)
        triggerForm(form, columns, undefined, true)
      }
    "
    @closeModal="closeModal"
  />
</template>

<style scoped></style>
