<script lang="ts" setup>
import { watch, ref, onMounted, Ref } from 'vue'
import ImportFileComponent from '../../components/ImportFile.vue'
import { storeToRefs } from 'pinia'
import { useTOAdminStore } from '../../../src/store/to-admin'
import { Modal } from '@centurylinkfederal/eis-vue'
const emits = defineEmits(['importContent', 'closeModal', 'applyCountryJurisdiction'])
interface IProps {
  title: string
  editCountryJurisdtion: any
  currentServiceId: any
  selectedCountryValue: any
  currentSelectedService: any
  selectedServices: any
  mapOfCountry: any
}
const props = defineProps<IProps>()
const toAdminStore = useTOAdminStore()

const selectedServices = ref<any[]>([]) //services passing from service component
selectedServices.value = props.selectedServices
const currentServiceId = ref<string>('')
currentServiceId.value = props.currentServiceId
const currentSelectedService = ref<any>()
currentSelectedService.value = props.currentSelectedService
const workingServiceId = ref<string>('')
const selectedToServices = ref<any[]>([])
const jurisdictonsLookupData = ref<any[]>([]) //object of cjs
const jurisdictonsLookupCodes = ref<any[]>([]) //ids only
const selectedJurisdictionsForAService = ref<any[]>([]) //all CJs for the service being worked on at the moment

const originalJurisForAService = ref<any[]>([]) //original CJs for the service being worked on at the moment
const serviceAndItsJurisdictionsData = ref<any[]>([]) //all selected service + cjs when service added new row added

const isAllJurisdictionsSlectedForAService = ref(false) //value for check all button
const isChecked = ref(false)
const jurisdictionData = ref<any>([])

const showImportDialog = ref(false)
const fields = ref<string[]>(['acronym', 'jurisdId'])
const headers = ref<string[]>(['Service Id', 'Jurisdiction Id', 'applyCountryJurisdiction'])

const country = props.mapOfCountry.get(props.currentServiceId)
console.log(country)
if (country) {
  country.forEach((value: any) => {
    selectedJurisdictionsForAService.value.push(value.split('~~~', 1)[0])
  })
}

const actionButtons = [
  { text: 'Apply' },
  { text: 'Import' },
  {
    text: 'Close',
    dismissModal: true
  }
]
function onButtonClick(index: number) {
  if (index === 0) {
    saveJuridictionData()
  } else if (index === 1) {
    showImportDialog.value = true
  } else {
    emits('closeModal', true)
  }
}

function closeModal() {
  emits('closeModal', true)
}

onMounted(async () => {
  const resp = (await toAdminStore.getJurisdictions()) as any
  const data = resp.results.slice(0)
  jurisdictonsLookupData.value = data
  jurisdictonsLookupData.value.forEach((jurisdictonsLookupObj) => {
    jurisdictonsLookupCodes.value.push(jurisdictonsLookupObj.jurisdictionCode)
  })
})

function importedFileInfo(file: any) {
  const { contents } = file
  const importedCountries = contents.map((c: any) =>
    c.jurisdId.replace(/(?:\\[rn]|[\r\n]+)+/g, '').toLowerCase()
  )
  const selectedJurisdictions = jurisdictonsLookupData.value.filter((j: any) =>
    importedCountries.includes(String(j.jurisdictionCode))
  )
  selectedJurisdictions.forEach((j: any) => {
    if (!selectedJurisdictionsForAService.value.includes(j.jurisdictionCode)) {
      selectedJurisdictionsForAService.value.push(j.jurisdictionCode)
    }
  })
  //$forceUpdate();
}

function loadData() {
  resetFormFileds()
  if (selectedServices.value.length === 1) {
    workingServiceId.value = selectedServices.value[0].serviceID
  }
  getSelectedJurisdictionCode()
  originalJurisForAService.value = [...selectedJurisdictionsForAService.value]

  isAllJurisdictionsSlectedForAService.value =
    selectedJurisdictionsForAService.value.length === jurisdictonsLookupCodes.value.length
  originalJurisForAService.value = [...selectedJurisdictionsForAService.value]
}

//$bvModal.show('service-jurisdiction');

function getSelectedJurisdictionCode() {
  let currentService: any = selectedServices.value.filter(
    (svc) => svc.serviceID === workingServiceId
  )[0].jurisdictionCodeContry
  selectedJurisdictionsForAService.value = []

  if (currentService) {
    currentService.filter((j: any) => {
      //  let id = j.split('~~~',1)[0] *1;
      selectedJurisdictionsForAService.value.push(j.split('~~~', 1)[0])
      //  selectedJurisdictionsForAService.push(id);
    })
  } else if (currentService === 'undefined') {
    selectedJurisdictionsForAService.value = []
  }
}
watch(selectedToServices, (selectedServices) => {
  let tempServiceAndItsJurisdictionData = ref<any[]>([])
  selectedServices.forEach((service) => {
    const isAlreadySelected = service.jurisdictionCodeContry

    if (isAlreadySelected.length === 0) {
      tempServiceAndItsJurisdictionData.value.push({
        serviceId: service.serviceID,
        selectedJurisdictions: []
      })
    } else {
      tempServiceAndItsJurisdictionData.value.push({
        serviceId: service.serviceID,
        selectedJurisdictions: isAlreadySelected
        //selectedJurisdictions : isAlreadySelected[0].selectedJurisdictions
      })
    }
  })
  serviceAndItsJurisdictionsData.value = tempServiceAndItsJurisdictionData.value
})

function openPopupForService(serviceId: string): void {
  selectedJurisdictionsForAService.value =
    getSelectedJurisdictionsForAServiceById(serviceId)[0].selectedJurisdictions
  isAllJurisdictionsSlectedForAService.value =
    selectedJurisdictionsForAService.value.length === jurisdictonsLookupCodes.value.length
}

function closePopup(): void {
  workingServiceId.value = ''
  selectedJurisdictionsForAService.value = [...originalJurisForAService.value]
  //$bvModal.hide('service-jurisdiction');
}
function toggleSelectAll(e: any) {
  const flag = e.target.checked
  isAllJurisdictionsSlectedForAService.value = flag
  if (flag) {
    selectedJurisdictionsForAService.value.push(jurisdictonsLookupCodes)
  } else {
    selectedJurisdictionsForAService.value = []
  }
}
function getSelectedJurisdictionsForAServiceById(serviceId: string) {
  return serviceAndItsJurisdictionsData.value
    .filter((serviceAndJurisdiction) => serviceAndJurisdiction.serviceId === serviceId)
    .slice(0)
}
function getSelectedJurisdictionById(juisId: any) {
  return jurisdictonsLookupData.value.filter(
    (jurisdictonsLookup) => jurisdictonsLookup.jurisdictionCode === Number(juisId)
  )
}
function saveJuridictionData(): void {
  // $bvModal.hide('service-jurisdiction');
  emitSavedJuridictionData()
  // $store.dispatch('taskOrderAdmin/resetTimer');
}
function emitSavedJuridictionData() {
  if (isChecked) {
    jurisdictionData.value = populateSelectedCJs(selectedJurisdictionsForAService.value)
    emits('applyCountryJurisdiction', jurisdictionData)
    emits('closeModal', true)
  } else {
    selectedJurisdictionsForAService.value = []
    originalJurisForAService.value = []
  }
}
function populateSelectedCJs(selecCJsIds: any[]) {
  let selectedCjs: any[] = []

  if (selecCJsIds) {
    selecCJsIds.forEach((selectedCode) => {
      let jObj = getSelectedJurisdictionById(selectedCode)
      if (jObj) {
        let selectedJ = selectedCode + '~~~' + jObj[0].countryDesc
        selectedCjs.push(selectedJ)
      }
    })
    return selectedCjs
  }
}
function resetFormFileds() {
  workingServiceId.value = currentServiceId.value
  if (currentSelectedService.value.checkedForJury.some((c: any) => !c)) {
    currentSelectedService.value.checkedForJury = []
    selectedJurisdictionsForAService.value = []
    if (
      typeof selectedServices != 'undefined' &&
      selectedServices &&
      selectedServices.value.length
    ) {
      if (currentSelectedService.value.acronym == currentServiceId) {
        currentSelectedService.value.jurisdictionCodeContry = []
      }
    }
  }
}
</script>

<template>
  <div>
    <Modal
      :center="true"
      :title="props.title"
      id="service-jurisdiction"
      scrollable
      :lazy="false"
      :no-fade="true"
      @show="loadData"
      @onButtonClick="onButtonClick"
      :buttons="actionButtons"
      @on-dismiss-modal="closeModal"
    >
      <div class="content-modal">
        <table class="chi-table -bordered -striped">
          <thead class="header">
            <tr>
              <td class="table-cell">
                <input
                  class="chi-checkbox__input"
                  type="checkbox"
                  id="all_jurisdictions"
                  :value="false"
                  v-on:change="toggleSelectAll($event)"
                  v-model="isAllJurisdictionsSlectedForAService"
                />
                <label class="chi-checkbox__label" for="all_jurisdictions">
                  <div class="-sr--only">Label</div>
                </label>
              </td>
              <td class="table-cell">Jurisdiction ID</td>
              <td class="table-cell">Country</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in jurisdictonsLookupData" :key="index">
              <td class="table-cell">
                <input
                  class="chi-checkbox__input"
                  type="checkbox"
                  :value="item.jurisdictionCode"
                  :id="`jurisdiction_option_${item.jurisdictionCode}`"
                  v-model="selectedJurisdictionsForAService"
                />
                <label
                  class="chi-checkbox__label"
                  :for="`jurisdiction_option_${item.jurisdictionCode}`"
                >
                  <div class="-sr--only">Label</div>
                </label>
              </td>
              <td class="table-cell">{{ item.jurisdictionCode }}</td>
              <td class="table-cell">{{ item.countryDesc }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="chi-card__footer -noBorder -alignCenter">
          <button class="chi-button -sm -mr--2" v-on:click="saveJuridictionData()">Apply</button>
          <button @click="openImportDialog" class="chi-button -sm -mr--4">Import</button>
          <button class="chi-button -sm" v-on:click="closePopup()">Close</button>
        </div> -->
    </Modal>
    <div v-if="showImportDialog">
      <ImportFileComponent
        title="Country/Jurisdiction"
        :showModal="false"
        :dataFields="fields"
        @closeModal="showImportDialog = false"
        :headerDetails="headers"
        @importContent="importedFileInfo($event)"
      />
    </div>
  </div>
</template>

<style scoped lang="css">
.content-modal {
  height: 250px;
}
.country-jurisdiction-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.country-jurisdiction-item {
  flex: 1 0 auto;
  min-width: 50%;
}
.country-jurisdiction-important {
  color: red;
}
.country-jurisdiction-heading {
  font-weight: bold;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.padding-8 {
  padding: 8px;
}
.padding-left-8 {
  padding-left: 8px;
}
.table-cell {
  padding: 0.25rem;
  text-align: left !important;
}
thead > tr > td.table-cell {
  background-color: #38c6f4;
}
.chi.chi-modal__content.-py--2 {
  overflow-y: auto;
  height: 350px;
}
</style>
