export function toEntries(arr: any[], key: string): [string, any][] {
  const entries: [string, any][] = arr.map((e: any) => [String(e[key]), e])
  return entries
}
export function toMap(arr: any[], key: string) {
  const entries = toEntries(arr, key)
  return new Map(entries)
}
export function affixFormat(
  value: string | number | undefined | null,
  prefix: string | number | undefined | null = undefined,
  sufix: string | number | undefined | null = undefined
): string | number | undefined | null {
  if (value) {
    return [prefix, value, sufix].map((f) => f).join('')
  }
}
export function arrayDiff(a: any[], b: any[]) {
  const c = a.filter((x) => !b.includes(x))
  const d = b.filter((x) => !a.includes(x))
  const e = Array.from(new Set([...c, ...d]))
  return e
}
export function unite(a: Set<any>, b: Set<any>): Set<any> {
  return new Set([...Array.from(a), ...Array.from(b)])
}
export function jsonParse(str: any): any {
  try {
    return JSON.parse(str)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err)
  }
}
export function jsonStringify(obj: any): string | undefined {
  try {
    return JSON.stringify(obj)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err)
  }
}
