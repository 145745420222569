<script setup lang="ts">
import { useRouter } from 'vue-router'
import SearchInput from './SearchInput.vue'
import SearchResultDataTable from './SearchResultDataTable.vue'
import { storeToRefs } from 'pinia'
import { useStore } from '../CreateUser/store'
import { computed } from 'vue'
const router = useRouter()

const searchStore = useStore()
const { loggedInUserInfo } = storeToRefs(searchStore)

function navigateToCreateUser(): void {
  router.push('/user-admin/create-user')
}
</script>

<template>
  <div>
    <div class="-d--flex -justify-content--between -align-items--center -mt--4">
      <div class="-text--h3 -text--boldest -text--navy -m--0 -pr--2 -text--900">User Search</div>
      <div>
        <button
          v-if="loggedInUserInfo?.roleName?.toUpperCase() === 'SUPER ADMIN'"
          class="chi-button -primary"
          @click="navigateToCreateUser"
        >
          CREATE USER
        </button>
      </div>
    </div>
    <div class="chi-card -no-border -mt--3">
      <div class="chi-card__content">
        <SearchInput />
        <div class="-mt--4">
          <SearchResultDataTable />
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
