import { ref, watch, computed } from 'vue'
import { defineStore } from 'pinia'
import { AlertType, ModalType, Signal } from '@centurylinkfederal/eis-vue'
import { useForm } from '../../composables/form'

const storeFn = () => {
  const loading = ref(false)
  const activePanel = ref<string | undefined>()
  const disabledPanels = ref(new Set())
  const resetSeq = ref(0)
  const loadSeq = ref(0)
  const updateSeq = ref(0)
  const alertMap = ref<Map<string, AlertType>>(new Map())
  const alertEntries = ref<[string, AlertType][]>([])
  const modalMap = ref<Map<string, ModalType>>(new Map())
  const modalEntries = ref<[string, ModalType][]>([])
  const signal = ref<Signal>([])
  const billingNotifications = ref()
  const selectedTaskOrderObj = ref<any>(null)
  const selectedMod = ref<string | undefined>()
  const summary = ref({
    servicesCount: 0,
    kpisCount: 0,
    activitylogCount: 0,
    activitylogKpisCount: 0
  })
  const { form, formState, formChanges } = useForm()
  const panelStates = ref<any>({})
  const taskOrderDetailsRes = ref<any>()
  const selectedTaskOrder = computed(() => selectedTaskOrderObj.value?.taskOrderNumber)

  watch(
    () => alertMap,
    (newMap) => {
      alertEntries.value = Array.from(newMap.value.entries())
    },
    { deep: true }
  )
  watch(
    () => modalMap,
    (newMap) => {
      modalEntries.value = Array.from(newMap.value.entries())
    },
    { deep: true }
  )
  return {
    loading,
    activePanel,
    disabledPanels,
    resetSeq,
    loadSeq,
    alertMap,
    alertEntries,
    modalMap,
    modalEntries,
    signal,
    form,
    formState,
    formChanges,
    billingNotifications,
    summary,
    panelStates,
    selectedTaskOrderObj,
    selectedTaskOrder,
    selectedMod,
    taskOrderDetailsRes,
    updateSeq
  }
}

export const useCreateStore = defineStore('createTaskOrderStore', storeFn)
export const useEditStore = defineStore('editTaskOrderStore', storeFn)
export const getStore = (mode = 'create') => {
  if (mode === 'edit') {
    return useEditStore
  }
  return useCreateStore
}
