<script lang="ts" setup>
import { computed } from 'vue'
import { useAlertsStore } from '../store/alert'
const alertsStore = useAlertsStore()

function getIcon(type: string) {
  if (type === 'success') {
    return 'icon-circle-check'
  }
  return 'icon-circle-x'
}
</script>
<template>
  <div class="alerts-wrapper">
    <div
      :class="`chi-alert -${alert.type}`"
      role="alert"
      v-for="alert in alertsStore.alerts"
      :key="alert.id"
    >
      <i :class="`chi-alert__icon chi-icon ${getIcon(alert.type)}`" aria-hidden="true"></i>
      <div class="chi-alert__content">
        <p class="chi-alert__text">{{ alert.message }}</p>
      </div>
      <button class="chi-alert__close-button chi-button -icon -close" aria-label="Close">
        <div class="chi-button__content">
          <i
            class="chi-icon icon-x"
            aria-hidden="true"
            @click="alertsStore.removeAlert(alert.id)"
          ></i>
        </div>
      </button>
    </div>
  </div>
</template>
<style scoped>
.alerts-wrapper {
  margin-bottom: 16px;
}
</style>
