<script setup lang="ts">
import { useApi } from '@centurylinkfederal/eis-vue'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useLookupSelectionStore, LookupSelection } from '../store/lookup'

const lookupSelectionStore = useLookupSelectionStore()

const router = useRouter()
const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)
const agencyData = ref<any[]>([])
const tableData = ref<any[]>([])
const selectedAgency = ref('')
const selectedMask = ref('')

onMounted(() => {
  const existingSelection: LookupSelection = lookupSelectionStore.lookupSelection
  if (existingSelection?.maskedName !== '-1') {
    selectedMask.value = existingSelection?.maskedName
    onSelectChange('', existingSelection?.maskedName)
  }

  if (existingSelection?.agencyName !== '-1') {
    selectedAgency.value = existingSelection?.agencyName
    onSelectChange('', existingSelection?.agencyName)
  }
  getAgencyData()
})

async function getAgencyData(): Promise<void> {
  try {
    const res: any = await api.get(`/agencies`)
    agencyData.value = res
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

async function onSelectChange(name: string, value: any): Promise<void> {
  try {
    const response: any = await api.get(`/agencyDetails/${value}`)
    tableData.value = response
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

const onSelectAgency = (name: string, $event: any) => {
  if ($event.target.value != '') {
    selectedMask.value = ''
    onSelectChange(name, $event.target.value)
    lookupSelectionStore.updateLookupSelection($event.target.value, 'agencyName')
    lookupSelectionStore.clearSelection('maskedName')
  } else {
    tableData.value = []
  }
}

const onSelectMask = (name: string, $event: any) => {
  if ($event.target.value != '') {
    selectedAgency.value = ''
    onSelectChange(name, $event.target.value)
    lookupSelectionStore.updateLookupSelection($event.target.value, 'maskedName')
    lookupSelectionStore.clearSelection('agencyName')
  } else {
    tableData.value = []
  }
}

const navigateToEditPage = (obj: any) => {
  router.push(`/agencyAhcAccountAdminFlow/edit-agency/${obj.agencyId}`)
}
</script>
<template>
  <div class="container -pt--2 -pd--4">
    <div class="chi-grid -pl--2">
      <div class="chi-col -w--6">
        <label>By Agency Name</label>
        <select
          class="chi-select chi-col"
          v-model="selectedAgency"
          @change="onSelectAgency('agencyName', $event)"
        >
          <option value="">--Select One--</option>
          <option value="0">All Agencies</option>
          <option v-for="item in agencyData" :value="item.agencyId" :key="item.agencyId">
            {{ item.agencyDisplayName }}
          </option>
        </select>
      </div>
      <div class="chi-col -w--6 -pl--4 -pr--4">
        <label>By Masked Name</label>
        <select
          class="chi-select chi-col"
          v-model="selectedMask"
          @change="onSelectMask('maskedName', $event)"
        >
          <option selected value="">--Select One--</option>
          <option value="0">All Agencies</option>
          <option v-for="item in agencyData" :value="item.agencyId" :key="item.agencyId">
            {{ item.agencyMaskedName }}
          </option>
        </select>
      </div>
    </div>
    <div class="chi-grid -p--2">
      <div class="chi-col -w--12" v-if="tableData && tableData.length > 0">
        <table class="chi-table -striped -p--1">
          <thead>
            <tr>
              <th>Agency Name</th>
              <th>Masked Name</th>
              <th>Bureau Name</th>
              <th>Agency Identifier</th>
              <th>BizOrgId</th>
              <th>Created Date</th>
              <th>View/Edit</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="obj in tableData" :key="obj.id" :value="obj">
              <td>{{ obj.agencyName }}</td>
              <td>{{ obj.maskedName }}</td>
              <td>{{ obj.bureauName }}</td>
              <td>{{ obj.agencyIdentifier }}</td>

              <td>{{ obj.bizOrgId }}</td>
              <td>{{ obj.createdOn }}</td>
              <td class="-text--primary">
                <a @click="navigateToEditPage(obj)">{{
                  obj.permissions?.includes('edit') ? 'View/Edit' : 'View'
                }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>
td {
  border: 0.0625rem solid #dadee2 !important;
}

th {
  border: 0.0625rem solid #dadee2 !important;
}

span:hover {
  text-decoration: underline !important;
  cursor: pointer;
}
</style>
