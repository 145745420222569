<script setup lang="ts">
import cookies from 'js-cookie'
import Cells from './Cells.vue'
import { useStore } from './store'
import { storeToRefs } from 'pinia'
import { useColumnsStore, validatePhone } from './ColumnStore.ts'
import AgencyTaskOrder from './AgencyTaskOrder.vue'
import RoleInformation from './RoleInformation.vue'
import ProductPermissions from './ProductPermissions.vue'
import { RouterLink, useRoute, useRouter } from 'vue-router'
import { ref, watch, Ref, onMounted, onUnmounted } from 'vue'
import {
  Row,
  StateType,
  setInput,
  InputType,
  toMatchWith,
  toMatchAPattern
} from '@centurylinkfederal/eis-vue'
import SearchTOAgencies from './SearchTOAgencies.vue'
import TaskOrderFilters from './TaskOrderFilters.vue'

const userExist = ref<boolean>(false)
const isApiInProgress = ref<boolean>(false)
const store = useStore()
const {
  resetSeq,
  form,
  formState,
  formChanges,
  isUserActive,
  userDetail,
  isEditMode,
  alertMap,
  toPermissionList,
  selectedAgencyTos,
  validationSuccess,
  adminEditingAdimUser,
  updatingMySelf,
  originalTaskorder,
  agenciesFilter,
  searchTosInputText,
  selectedTOOptionType,
  searchTOByAgencies
} = storeToRefs(store)
const columnsStore = useColumnsStore()
const {
  userDetailsCols,
  userPasswordInfoCols,
  userRemainderDetailsCols,
  taskOrderPrevColumns,
  getUserById,
  userAddressLineInfo,
  userCityStateDetails,
  userContactDetails,
  noteColumn
} = columnsStore
const quirksMap: Ref<Map<string, any>> = ref(new Map())
const formChangesOnceMap = ref<Map<string, Function>>(new Map())
const classColDefault = ref('-w-xl-2h--3 -w-xl--3 -w-lg--4 -w-md--6 -w-sm--12')
const agencyId = ref<any>(null)
const selectedRole = ref<string | null>(null)
const isUserNameValidated = ref<boolean>(false)
const userNameValue = ref<any>('')
const showSearchLink = ref(true)
const settings = {
  key: 'disputeId'
}
const row = ref<Row>({
  disputeId: -1
})
const route = useRoute()
const router = useRouter()

watch(
  () => adminEditingAdimUser.value,
  (newValue) => {
    if (newValue) {
      populateFormValue()
    }
  }
)

onMounted(async () => {
  showSearchLink.value = !!window.history.state.back
  if (route.params.id) {
    await getUserById(route.params.id)
    populateFormValue()
  } else {
    resetSeq.value++
  }
})

watch(
  () => formChanges.value,
  async (newValue) => {
    if (typeof newValue !== 'object') {
      return
    }
    let inDanger = false
    let inSuccess = false
    let inNoChange = false
    for (const key in newValue) {
      const rowEntries = newValue[key]
      const hasNoChange = rowEntries.some((entry: any) => entry?.[1]?.state === 'unchanged')
      const hasSuccess = rowEntries.some((entry: any) => entry?.[1]?.state === 'success')
      const hasDanger = rowEntries.some((entry: any) => entry?.[1]?.state === 'danger')
      let state = undefined
      state = hasNoChange ? 'unchanged' : state
      state = hasSuccess ? 'success' : state
      state = hasDanger ? 'danger' : state
      const patch = {
        state
      }
      const quirk = quirksMap.value.get(key)
      quirksMap.value.set(key, { ...quirk, ...patch })
      inNoChange = inNoChange || hasNoChange
      inSuccess = inSuccess || hasSuccess
      inDanger = inDanger || hasDanger
    }
    let state: StateType = undefined
    state = inNoChange ? 'unchanged' : state
    state = inSuccess ? 'success' : state
    state = inDanger ? 'danger' : state

    let testFailed = false
    const rowKeys = Object.keys(form.value)
    for (const rowKey of rowKeys) {
      const row = form.value[rowKey]
      for (const field in row) {
        const input = row[field]
        let testState, testErr
        const isExistingUser = await doesUserExist(field, input, testState, testErr)
        if (isExistingUser) {
          testFailed = true
          state = 'danger'
          break
        }
        if (!input?.tests?.length) {
          continue
        }
        ;[testState, testErr] = input.test()
        if (testState === 'danger' || testErr) {
          testFailed = true
          break
        }
      }
    }

    formState.value = testFailed ? 'danger' : state
    const patch = {
      state
    }
    const quirk = quirksMap.value.get('_all')
    quirksMap.value.set('_all', { ...quirk, ...patch })
    formChangesOnceMap.value.forEach((fn, key) => {
      try {
        if (typeof fn === 'function') {
          fn()
        }
      } catch (err) {
        console.error(err)
      }
      formChangesOnceMap.value.delete(key)
    })
  }
)

watch(
  () => resetSeq.value,
  () => {
    resetForm()
  }
)
function resetForm(): void {
  form.value = {}
  const rowKey = row.value.disputeId
  const inputs = form.value[rowKey] || {}
  const columns = [
    ...userDetailsCols.value,
    ...userPasswordInfoCols.value,
    ...userAddressLineInfo.value,
    ...userCityStateDetails.value,
    ...userContactDetails.value,
    ...userRemainderDetailsCols.value,
    ...taskOrderPrevColumns.value,
    ...noteColumn.value
  ]

  for (const column of columns) {
    const { field, input } = column
    if (!field) {
      continue
    }

    inputs[field] = setInput(input.value, column)
  }

  inputs.telephone.set('')
  inputs.mobilePhone.set('')

  form.value = { ...form.value, [rowKey]: inputs }
  userNameValue.value = null
  agencyId.value = null
  selectedRole.value = null
  agenciesFilter.value = []
  searchTosInputText.value = ''
  selectedTOOptionType.value = null
}

function onInput(newValue: any, cell: any, field?: string): void {
  const input = form.value[cell?.key]?.[cell?.field]
  if (!input) {
    return
  }
  if (field === 'taskOrderPrivileges') {
    agencyId.value = newValue
  }
  if (cell.field === 'mobilePhone' || cell.field === 'telephone') {
    input.tests = []
    if (newValue && !newValue.includes('+1')) {
      input.tests = [validatePhone]
    } else {
      input.tests = [
        (input: InputType) => toMatchAPattern(input, /^[^0|1]/, 'phone'),
        validatePhone
      ]
    }
  }

  input.set(newValue)
  input.validate()
}
function handleRoleSelected(role: string) {
  selectedRole.value = role
}

async function toBeUniqueUser(input: InputType) {
  let state: any = undefined
  let error: any = undefined
  input.state = 'success'
  input.error = undefined
  await checkUserNameExist(input)
  if (userExist.value) {
    state = 'danger'
    error = { message: `Username ${input.value} already exists` }
    input.state = 'danger'
    input.error = error
    return [state, error]
  }
  state = 'success'
  return [state, error]
}
async function checkUserNameExist(input: InputType): Promise<void> {
  try {
    if (!isApiInProgress.value) {
      isApiInProgress.value = true
      const result: any = await fetch(`${import.meta.env.VITE_EIS_AUTH_API}/users/${input.value}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.get('eis.portal.token')}`
        }
      })
      const response: any = await result.json()
      userExist.value = !!response.eisUserId
      isApiInProgress.value = false
    }
  } catch (error: any) {
    isApiInProgress.value = false
  }
}

async function doesUserExist(
  field: string,
  input: InputType,
  testState: any,
  testErr: any
): Promise<boolean> {
  isUserNameValidated.value = field === 'userName' && input.state === 'danger'
  if (
    field === 'userName' &&
    input.value?.length &&
    !isUserNameValidated.value &&
    userNameValue.value !== input.value &&
    !isEditMode.value
  ) {
    userNameValue.value = field === 'userName' ? input.value : userNameValue.value
    ;[testState, testErr] = await toBeUniqueUser(input)
    if (testState === 'danger' || testErr) {
      return true
    }
  }
  return false
}

function populateFormValue() {
  const rowKey = row.value.disputeId
  const inputs: any = {}
  const columns = [
    ...userDetailsCols.value,
    ...userPasswordInfoCols.value.map((pass: any) => ({
      ...pass,
      input: { ...pass.input, tests: [], required: false }
    })),
    ...userAddressLineInfo.value,
    ...userCityStateDetails.value,
    ...userContactDetails.value,
    ...userRemainderDetailsCols.value,
    ...taskOrderPrevColumns.value,
    ...noteColumn.value
  ]

  for (const column of columns) {
    const { field } = column
    if (!field) {
      continue
    }
    const value = userDetail.value[field]
    inputs[field] = setInput(value, column)
    if (field === 'userName') {
      inputs[field].disabled = true
    }
    if (adminEditingAdimUser.value && !['password', 'confirmPassword'].includes(field)) {
      inputs[field].disabled = true
    }
  }
  if (updatingMySelf.value) {
    inputs.password.disabled = true
    inputs.confirmPassword.disabled = true
  }
  inputs.password.tests = [
    (input: any) => toMatchWith(input, form.value[rowKey]?.['confirmPassword'])
  ]
  inputs.password.required = false
  inputs.confirmPassword.tests = [
    (input: any) =>
      toMatchWith(
        input,
        form.value[rowKey]?.['password'],
        'Password does not match.  Please confirm.'
      )
  ]
  inputs.confirmPassword.required = false

  form.value = { ...form.value, [rowKey]: inputs }
  selectedTOOptionType.value = 'ASSIGNED'
  searchTOByAgencies.value++
}

onUnmounted(() => {
  isEditMode.value = false
  alertMap.value = new Map()
  toPermissionList.value = new Map()
  originalTaskorder.value = new Map()
  form.value = {}
  selectedAgencyTos.value = []
  validationSuccess.value = true
  agenciesFilter.value = []
  searchTosInputText.value = ''
  selectedTOOptionType.value = null
})
</script>
<template>
  <div>
    <div class="chi-card -no-border -mt--3">
      <div class="chi-card__header">
        <div class="chi-card__title">
          <div class="-d--flex -flex--column">
            <a class="chi-link" v-if="showSearchLink">
              <div class="chi-link__content">
                <router-link class="chi-link -no-hover-underline" to="/user-admin/user-search">
                  <i class="chi-icon icon-chevron-left -xs -mr--1"></i>
                  <span class="-text--md -text--normal">User Search</span>
                </router-link>
              </div>
            </a>
            <div class="-d--flex -align-items--baseline -mb--4">
              <div class="-text--h3 -text--boldest -text--navy -m--0 -pr--2 -text--900">
                {{ isEditMode ? 'View / Update User' : 'Create User' }}
              </div>
              <div class="chi-form__item">
                <label class="chi-switch" for="active_user">
                  <input
                    type="checkbox"
                    class="chi-switch__input"
                    id="active_user"
                    :disabled="updatingMySelf"
                    v-model="isUserActive"
                  />
                  <span class="chi-switch__content">
                    <span class="chi-switch__thumb"></span>
                  </span>
                  <span class="chi-switch__label">Active</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chi-card__content">
        <Cells
          :classColDefault="classColDefault"
          :columns="userDetailsCols"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        />

        <Cells
          :classColDefault="classColDefault"
          :columns="userPasswordInfoCols"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        />
        <Cells
          :classColDefault="classColDefault"
          :columns="userAddressLineInfo"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        />
        <Cells
          :classColDefault="classColDefault"
          :columns="userCityStateDetails"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        />
        <Cells
          :classColDefault="classColDefault"
          :columns="userContactDetails"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        />

        <Cells
          :classColDefault="classColDefault"
          :columns="userRemainderDetailsCols"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        />
        <Cells
          :classColDefault="classColDefault"
          :columns="noteColumn"
          :settings="settings"
          :form="form"
          :row="row"
          @onInput="onInput"
        />
      </div>
    </div>
    <div class="chi-divider -mt--2"></div>
    <div class="chi-card -no-border -mt--3">
      <div class="chi-card__content">
        <div class="chi-grid -align-items--end">
          <div class="-mb--3 chi-col -w-xl-2h--3 -w-lg--6 -w-md--12 -w-sm--12 -w--12">
            <SearchTOAgencies />
          </div>
          <div class="-mb--3 chi-col -w-xl-2h--3 -w-lg--6 -w-md--12 -w-sm--12 -w--12">
            <TaskOrderFilters />
          </div>
        </div>

        <AgencyTaskOrder class="-p--4" :agencyId="agencyId" />
      </div>
    </div>
    <div class="chi-divider -m--2"></div>
    <div class="chi-card -no-border -mt--3">
      <div class="chi-card__content">
        <RoleInformation @role-selected="handleRoleSelected" />
      </div>
    </div>
  </div>
  <ProductPermissions :userRole="selectedRole" />
</template>

<style>
.chi-input:disabled {
  background: #fafafa !important;
}
</style>
