<script setup lang="ts">
import { onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { ref, computed, watch } from 'vue'
import { useUserSearchStore } from './store.ts'
import type { Column, SortTuple, DataTable, Row, Tooltip } from '@centurylinkfederal/eis-vue'

const userSearchStore = useUserSearchStore()
const {
  currentResponse,
  currentPage,
  previousPage,
  rowsPerPage,
  sortBy,
  searchResult,
  nextPage,
  totalRecords,
  searchMessage
} = storeToRefs(userSearchStore)

const router = useRouter()

const columns: Column[] = [
  {
    field: 'userName',
    label: 'User Name',
    isSortable: false,
    class: '-text--wrap -size--md-2'
  },
  {
    field: 'status',
    label: 'Status',
    isSortable: false,
    class: '-text--wrap -size--md-2',
    isSlot: true
  },
  {
    field: 'firstName',
    label: 'First Name',
    isSortable: false,
    class: '-text--wrap -size--md-2'
  },
  {
    field: 'lastName',
    label: 'Last Name',
    format: 'state',
    isSortable: false,
    class: '-text--wrap -size--md-2'
  },

  {
    field: 'actions',
    label: 'Actions',
    isActions: true,
    class: '-text--wrap -size--md-2',
    isSlot: true
  }
]
const settings = {
  striped: true,
  expandable: true,
  key: 'eisUserId',
  rowsPerPageOptions: [10, 20, 40, 60, 80, 100],
  rowsPerPageDefaultValue: 10
}
const quirks = ref<any>([])
const quirksMap = ref<Map<string, any>>(new Map())
const pagination = computed(() => {
  const result = {
    totalResults: totalRecords.value,
    currentPage: currentPage.value,
    rowsPerPage: rowsPerPage.value
  }

  return result
})

onMounted(() => {
  currentResponse.value = searchResult.value ?? []
})

watch(
  () => searchResult.value,
  () => {
    currentResponse.value = searchResult.value
  }
)

watch(
  () => currentResponse.value,
  (newRows) => {
    newRows?.forEach((row: Row) => {
      const key = row[settings.key]
      const quirk = quirksMap.value.get(key) || {}
      const badge = row.status === 'ACTIVE' ? '--accent-3' : '--accent-4'
      const patch = {
        badge
      }
      quirksMap.value.set(key, { ...quirk, ...patch })
    })
    quirks.value = Object.fromEntries(quirksMap.value)
  }
)

function onAction(newValue: any, rowData: any): void {
  router.push(`/user-admin/edit-user/${rowData.eisUserId}`)
}

function onSortedBy(newValue: SortTuple): void {
  sortBy.value = newValue
  searchResult.value.sort((resultA: any, resultB: any) => {
    const field: string = sortBy.value?.[0] as string
    const sortOrder = sortBy.value?.[1]
    if (sortOrder === 'asc') {
      return resultA[field].localeCompare(resultB[field])
    }
    return resultB[field].localeCompare(resultA[field])
  })
  onPageSize(rowsPerPage.value)
}

function onPageChange(newValue: number) {
  previousPage.value = newValue < currentPage.value ? newValue : currentPage.value
  nextPage.value = newValue
  currentPage.value = newValue
}
function onPageSize(newValue: number) {
  currentPage.value = 1
  rowsPerPage.value = newValue
}

watch(
  () => sortBy.value,
  (newValue: any) => {
    sortBy.value = newValue
  }
)
</script>

<template>
  <DataTable
    :columns="columns"
    :settings="settings"
    :quirks="quirks"
    :rows="currentResponse || []"
    :pagination="pagination"
    @onPageChange="onPageChange"
    @onPageSize="onPageSize"
    @onAction="onAction"
    @onSortedBy="onSortedBy"
    :sortedBy="sortBy"
  >
    <template #dataCell="{ cell, row }">
      <span v-if="cell.field === 'status'">
        <div
          class="chi-badge -sm"
          :class="{
            '-success': row[cell.field] === 'ACTIVE',
            '-muted': row[cell.field] === 'INACTIVE'
          }"
        >
          <span class="-text--capitalize">{{ String(row[cell.field]).toLowerCase() }}</span>
        </div>
      </span>
      <span v-if="cell.field === 'actions'">
        <Tooltip content="View / Update">
          <router-link :to="`/user-admin/edit-user/${row.eisUserId}`">
            <i class="chi-icon icon-edit -icon--info" aria-hidden="true"></i>
          </router-link>
        </Tooltip>
      </span>
    </template>
    <template #empty>
      <div class="chi-data-table__row-empty" style="min-height: 300px">
        <div>
          <i
            class="chi-icon icon-search -sm--2 -vertical-align--bottom -icon--grey"
            aria-hidden="true"
          ></i>
          <span class="-ml--1">{{ searchMessage }}</span>
        </div>
      </div>
    </template>
  </DataTable>
</template>

<style scoped>
h3 {
  color: #083176;
}
</style>
