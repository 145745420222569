import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useForm } from './form'
import { Row } from '@centurylinkfederal/eis-vue'

export const useFormsStore = defineStore('formsStore', () => {
  const formMap = new Map()
  const useForms = (formId: string | number): any => {
    let formObj = formMap.get(formId)
    if (!formObj) {
      const { form, formState, formChanges, isFormEnabled, isFormLoaded } = useForm()
      const resetSeq = ref(0)
      const rows = ref<Row[]>([])
      formObj = {
        form,
        formState,
        formChanges,
        isFormEnabled,
        isFormLoaded,
        resetSeq,
        rows
      }
      formMap.set(formId, formObj)
    }
    return formObj
  }
  const clearForms = (): void => {
    formMap.clear()
  }
  return { useForms, clearForms }
})
