<script setup lang="ts">
import { useStore } from './store'
import { storeToRefs } from 'pinia'
import { dangerAlert, doScrollToTop, successAlert } from '@centurylinkfederal/eis-vue'
import { useRouter } from 'vue-router'
import { Action, dispatch } from '../CreateUser/actions'

const router = useRouter()
const store = useStore()
const { loading, alertMap, form, resetSeq, isNewPasswordValid, lastTenPasswordValid } =
  storeToRefs(store)
const changePassFormId = -1

function cancelProfileUpdate(): void {
  router.push('/user-admin/user-profile')
}

async function onProfileUpdate(): Promise<void> {
  alertMap.value = new Map()
  if (validateForm()) {
    doScrollToTop()
    alertMap.value.set('alert', {
      ...dangerAlert,
      title:
        !lastTenPasswordValid.value && isNewPasswordValid.value
          ? `Do not reuse your last 10 passwords`
          : `Please complete all Required Fields indicated with * before attempting to submit changes.`
    })
    return
  }

  const formData = form.value[changePassFormId]
  const formValues: any = Object.keys(formData).reduce(
    (all, key) => ({
      ...all,
      [key]: formData[key].value
    }),
    {}
  )

  const errorMessage = await dispatch(Action.UPDATE_USER_PASSWORD, formValues)
  alertMap.value.set(!errorMessage ? 'success' : 'alert', {
    ...(!errorMessage ? successAlert : dangerAlert),
    title: !errorMessage ? 'Password updated successfully' : errorMessage
  })

  if (!errorMessage) {
    resetSeq.value++
  }

  doScrollToTop()
}

function validateForm(): boolean {
  let hasError = false
  const inputs = form.value[changePassFormId]
  for (let key of Object.keys(inputs)) {
    const input = inputs[key]
    input?.tests?.forEach((test) => {
      const result = test(input)
      if (result?.[0] === 'danger') {
        input.error = result?.[1]
        input.state = 'danger'
        hasError = true
      }
    })
  }

  form.value = { ...form.value, [changePassFormId]: inputs }

  return hasError || !isNewPasswordValid.value || !lastTenPasswordValid.value
}
</script>
<template>
  <div class="chi-card -mt--3 -bg--white">
    <div class="chi-card__footer -justify-content--end">
      <button class="chi-button -outline" @click="cancelProfileUpdate">CANCEL</button>
      <button class="chi-button -primary" :disabled="loading" @click="onProfileUpdate">
        <div class="chi-button__content">
          <span>CHANGE</span>
          <svg
            class="chi-spinner -icon--primary -sm -position--absolute -ml--10"
            :class="{ '-d--none': !loading }"
            viewBox="0 0 66 66"
          >
            <circle class="path" cx="33" cy="33" r="30" fill="none" stroke-width="6"></circle>
          </svg>
        </div>
      </button>
    </div>
  </div>
</template>
