import { defineStore } from 'pinia'
import { ref, computed, watch, onMounted } from 'vue'
import USState from './constants/USState'
import { TOPermissionTypes, TroublePrevlegies } from './utils/helper'
import { AlertType, Form, StateType, parseErr, useApi } from '@centurylinkfederal/eis-vue'

export const useStore = defineStore('useBillingCreateDisputeStore', () => {
  const resetSeq = ref(0)
  const alertMap = ref<Map<string, AlertType>>(new Map())
  const alertEntries = ref<[string, AlertType][]>([])
  const loading = ref(false)
  const form = ref<Form>({})
  const formState = ref<StateType>()
  const roleInfo = ref<any>(null)
  const selectedAgencyTos = ref<Array<any>>([])
  const productGroups = ref<Array<any>>([])
  const initialProductGroups = ref<Array<any>>([])
  const filteredProductGroups = ref<Array<any>>([])
  const isUserActive = ref<any>(true)
  const authApi = useApi(import.meta.env.VITE_EIS_AUTH_API)
  const api = useApi(import.meta.env.VITE_EIS_API_BASE_AGENCIES)
  const agencies = ref<Array<Array<any>>>([])
  const states = ref<Array<Array<any>>>([])
  const agecyTaskOrderData = ref<Array<any>>([])
  const loggedInUserInfo = ref<any>(null)
  const taskOrderListByAgencyId = ref<any[]>([])
  const selectedAgencyId = ref<any>(null)
  const userDetail = ref<any>(null)
  const isEditMode = ref<boolean>(false)
  const toPermissionList = ref<Map<number | string, any>>(new Map())
  const isFormSubmitted = ref<boolean>(false)
  const validationSuccess = ref(true)
  const originalTaskorder = ref<Map<string | number, any>>(new Map())
  const clearAgencyFilter = ref(0)
  const searchTOByAgencies = ref(0)
  const searchTosInputText = ref<any>('')
  const agenciesFilter = ref<Array<any>>([])
  const selectedAgencies = ref<Array<any>>([])
  const selectedToOptions = ref<Array<any>>([])
  const displayedAgencyTOs = ref<Array<any>>([])
  const selectedTOOptionType = ref<'ASSIGNED' | 'ALL' | null>(null)

  const formChanges = computed(() => {
    const changesTable: any = {}
    for (const key in form.value) {
      const row = form.value[key]
      if (typeof row !== 'object' || Array.isArray(row)) {
        continue
      }
      for (const field in row) {
        const input = row[field]
        if (!input) {
          continue
        }
        const { value, copy, state } = input
        if (!state) {
          continue
        }
        if (!changesTable[key]) {
          changesTable[key] = []
        }
        changesTable[key].push([field, { value, copy, state }])
      }
    }
    return changesTable
  })

  watch(
    () => alertMap,
    (newMap) => {
      alertEntries.value = Array.from(newMap.value.entries())
    },
    { deep: true }
  )
  async function loadOptions(): Promise<void> {
    try {
      const agenciesResp: Array<any> = await authApi.get(`/user-profile/permissions/agencies`)
      const taskOrderData: Array<any> = await authApi.get(
        `/user-profile/permissions/taskorders-data`
      )
      agecyTaskOrderData.value = agenciesResp
        .map((agency: any) => {
          const agencyTaskOrder = taskOrderData.filter(
            (to) => to.agencyIdentifier.split('-')?.[0] === agency.agencyName
          )
          if (agencyTaskOrder.length) {
            agency.taskOrderData = agencyTaskOrder
          }
          agency.fullName = `${agency.agencyName}${agency.bureauName ? '-' + agency.bureauName : ''}${agency.subBureauName ? '-' + agency.subBureauName : ''}${agency.agencyIdentifier ? '-' + agency.agencyIdentifier : ''}`

          return agency
        })
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
      const agenciesData: Array<Array<any>> = agecyTaskOrderData.value.reduce((all, agency) => {
        const agencyVal = [agency.agencyId, agency.fullName]
        all.push(agencyVal)
        return all
      }, [])
      agencies.value = agenciesData
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }

    states.value = USState.reduce(
      (all: Array<any[]>, state) => {
        all.push([state.code, state.name])
        return all
      },
      [[null, '-Select-']]
    )
  }

  async function loadProductGroups(): Promise<void> {
    try {
      let productGroupsResp = await api.get<any>('/products/groups')
      const general = productGroupsResp.find(
        (group: any) => group.productGroupName?.toUpperCase() === 'GENERAL'
      )
      productGroupsResp = productGroupsResp.filter(
        (group: any) => group.productGroupName?.toUpperCase() !== 'GENERAL'
      )
      if (general) {
        productGroupsResp.push(general)
      }
      productGroups.value = productGroupsResp

      initialProductGroups.value = productGroupsResp
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
  }
  function loadUserInfo(): void {
    try {
      const userInfo: string = sessionStorage.getItem('eis.portal.userinfo') || ''
      loggedInUserInfo.value = JSON.parse(userInfo)
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(parseErr(err))
    }
  }

  onMounted(async () => {
    await loadOptions()
    await loadProductGroups()
    loadUserInfo()
  })

  watch(
    () => resetSeq.value,
    async () => {
      roleInfo.value = null
      selectedAgencyTos.value = []
      selectedAgencyId.value = null
      toPermissionList.value = new Map()
    }
  )

  const adminEditingAdimUser = computed(() => {
    return (
      isEditMode.value &&
      ((loggedInUserInfo.value?.roleName?.toUpperCase() === 'SUPER ADMIN' &&
        userDetail.value?.userType?.toUpperCase() === 'SUPERADMIN') ||
        (loggedInUserInfo?.value?.roleName?.toUpperCase() === 'LUMEN ADMIN' &&
          userDetail.value?.userType?.toUpperCase() === 'L3ADMIN'))
    )
  })

  const lumenAdminEditingLumenAdmin = computed(() => {
    return (
      isEditMode.value &&
      loggedInUserInfo?.value?.roleName?.toUpperCase() === 'LUMEN ADMIN' &&
      userDetail.value?.userType?.toUpperCase() === 'L3ADMIN'
    )
  })

  const updatingMySelf = computed(() => {
    return (
      isEditMode.value &&
      ['LUMEN ADMIN', 'SUPER ADMIN'].includes(loggedInUserInfo?.value?.roleName?.toUpperCase()) &&
      userDetail.value?.eisUserId === loggedInUserInfo?.value?.userId
    )
  })

  watch(
    () => clearAgencyFilter.value,
    () => {
      agenciesFilter.value = []
    }
  )

  watch(
    () => searchTOByAgencies.value,
    async () => await getTaskOrderByUserAndAgencyId()
  )

  const selectedTOAgencies = computed(() => {
    return agenciesFilter.value.length === agencies.value.length ||
      selectedToOptions.value.length === agencies.value.length ||
      selectedTOOptionType.value === 'ASSIGNED'
      ? ['ALL']
      : agenciesFilter.value
  })

  async function getTaskOrderByUserAndAgencyId(): Promise<void> {
    let taskOrders = []
    selectedAgencyTos.value = []
    for (const agencyId of selectedTOAgencies.value) {
      try {
        let existingTOs = toPermissionList.value.get(agencyId)
        if (existingTOs) {
          if (selectedTOOptionType.value === 'ASSIGNED') {
            existingTOs = existingTOs.filter((to: any) =>
              TOPermissionTypes.some((t) => to[t] !== 'NONE')
            )
            agenciesFilter.value = Array.from(new Set(existingTOs.map((to: any) => to.agencyId)))
          }
          selectedAgencyTos.value = [...selectedAgencyTos.value, ...existingTOs].sort((a, b) =>
            a.agencyIdentifier?.localeCompare(b?.agencyIdentifier)
          )
          displayedAgencyTOs.value = [...selectedAgencyTos.value]
          continue
        }
        const userId = isEditMode.value ? userDetail.value.eisUserId : loggedInUserInfo.value.userId
        const response: any = await api.get(`/users/${userId}/taskorders/${agencyId}`)
        taskOrders = getTaskOrderDetails(response)
      } catch (err: any) {
        selectedAgencyTos.value = []
        // eslint-disable-next-line no-console
        console.error(parseErr(err))
      }

      if (agencyId === 'ALL' || selectedTOOptionType.value === 'ASSIGNED') {
        assignToValues(taskOrders, agencyId)
        break
      }
      originalTaskorder.value.set(agencyId, [...JSON.parse(JSON.stringify(taskOrders ?? []))])
      toPermissionList.value.set(agencyId, taskOrders)
      selectedAgencyTos.value = [...selectedAgencyTos.value, ...taskOrders].sort((a, b) =>
        a.agencyIdentifier?.localeCompare(b?.agencyIdentifier)
      )
      displayedAgencyTOs.value = [...selectedAgencyTos.value]
    }
  }

  function assignToValues(taskOrders: Array<any>, agencyId: any): void {
    assignTosForAllSelected(taskOrders)
    originalTaskorder.value.set(agencyId, [...JSON.parse(JSON.stringify(taskOrders ?? []))])
    toPermissionList.value.set(agencyId, selectedAgencyTos.value)
    if (selectedTOOptionType.value === 'ASSIGNED') {
      updateORAddAssignedTOs(taskOrders)
    }
    displayedAgencyTOs.value = [...selectedAgencyTos.value]
  }

  function assignTosForAllSelected(taskOrders: Array<any>): void {
    originalTaskorder.value.clear()
    taskOrders.forEach((to) => {
      const existsInMap: Array<any> = originalTaskorder.value.get(to.agencyId)
      const tos = existsInMap?.length ? [...existsInMap, to] : [to]
      originalTaskorder.value.set(to.agencyId, JSON.parse(JSON.stringify(tos ?? [])))
    })

    for (const [agencyId, tos] of originalTaskorder.value.entries()) {
      const existingTo = toPermissionList.value.get(agencyId)
      if (!existingTo?.length) {
        toPermissionList.value.set(agencyId, tos)
      }
      selectedAgencyTos.value = [
        ...selectedAgencyTos.value,
        ...(existingTo?.length ? existingTo : tos)
      ].sort((a, b) => a.agencyIdentifier?.localeCompare(b?.agencyIdentifier))
    }
  }

  function getTaskOrderDetails(taskOrder: Array<any> = []): Array<any> {
    return taskOrder.map((result: any) => {
      const [, agencyIdentifier] =
        agencies.value.find(([value]) => result.agencyToPermissions.agencyId === value) || []
      const data = {
        ...result.agencyToPermissions,
        accountTaskOrderId: result.accountTaskOrderId,
        agencyIdentifier,
        ...(!isEditMode.value && {
          billingPermission: 'NONE',
          orderPermission: 'NONE',
          inventoryPermission: 'NONE',
          troublePermission: 'NONE',
          networkPermission: 'NONE',
          troubleCreatePrivilegeValue: false,
          troubleUpdatePrivilegeValue: false,
          troubleEscalatePrivilegeValue: false,
          troubleClosePrivilegeValue: false
        }),
        showEnhancedWO: isEditMode.value
          ? result.agencyToPermissions?.enhancedWorkOrder === 'Y' &&
            result.agencyToPermissions?.eowaPrivilegeViewEdit
          : false,
        modified: false
      }
      return data
    })
  }

  /**
   *
   * @param taskOrders :Array<any>
   * @description : helper method that will combine TOs
   * when there's already selected TOs from dropsearch and
   *  when clicked on assigned only button
   *
   */
  function updateORAddAssignedTOs(taskOrders: Array<any>): void {
    taskOrders = taskOrders.filter((to) => TOPermissionTypes.some((t) => to[t] !== 'NONE'))
    const selectedTos = Array.from(toPermissionList.value, ([agencyId, value]) =>
      agencyId !== 'ALL' ? value : null
    )
      .filter(Boolean)
      .flatMap((to) => to)
      .filter((to) => TOPermissionTypes.some((t) => to[t] !== 'NONE'))

    const combinedTos: Array<any> = []
    for (const to of [...(selectedTos || []), ...(taskOrders || [])]) {
      const seenTo = combinedTos.find((c) => c.accountTaskOrderId === to.accountTaskOrderId)
      if (!seenTo) {
        combinedTos.push(to)
      }
    }

    selectedAgencyTos.value = combinedTos.sort((a, b) =>
      a.agencyIdentifier?.localeCompare(b?.agencyIdentifier)
    )

    agenciesFilter.value = Array.from(
      new Set(selectedAgencyTos.value.map((to: any) => to.agencyId))
    )
  }

  /**
   * @description reset TO to default values when unchecked/removed
   */
  watch(
    () => agenciesFilter.value,
    (newAgencies: Array<number>, oldAgencies: Array<number>) => {
      if (newAgencies.length < oldAgencies.length) {
        oldAgencies
          .filter((agencyId) => !newAgencies.includes(agencyId))
          .forEach((id) => {
            const existingTo = toPermissionList.value.get(id)
            if (existingTo) {
              existingTo.forEach((to: any) => {
                TOPermissionTypes.forEach((type) => (to[type] = 'NONE'))
                TroublePrevlegies.forEach((prev) => (to[prev] = false))
                to.modified = false
              })
              toPermissionList.value.set(id, existingTo)
            }
          })
      }
    }
  )
  return {
    resetSeq,
    alertMap,
    alertEntries,
    loading,
    form,
    formState,
    formChanges,
    roleInfo,
    selectedAgencyTos,
    productGroups,
    filteredProductGroups,
    isUserActive,
    agencies,
    states,
    agecyTaskOrderData,
    loggedInUserInfo,
    taskOrderListByAgencyId,
    selectedAgencyId,
    initialProductGroups,
    userDetail,
    isEditMode,
    toPermissionList,
    isFormSubmitted,
    validationSuccess,
    adminEditingAdimUser,
    lumenAdminEditingLumenAdmin,
    updatingMySelf,
    originalTaskorder,
    agenciesFilter,
    clearAgencyFilter,
    selectedAgencies,
    searchTOByAgencies,
    selectedToOptions,
    selectedTOOptionType,
    searchTosInputText,
    displayedAgencyTOs
  }
})
