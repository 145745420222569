<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import {
  usePasswordExpirationInfoApis,
  PasswordExpirationDetails
} from '../composables/password-expiration-banner-apis'

const router = useRouter()
const passwordExpirationDetails = ref<PasswordExpirationDetails>({
  passwordExpired: false,
  passwordExpiringWithin15days: false,
  numberOfDaysUntilExpiration: 0
})

const showPasswordExpirationBanner = computed(() => {
  const passwordExpired = passwordExpirationDetails.value.passwordExpired
  const passwordExpiringWithin15Days = passwordExpirationDetails.value.passwordExpiringWithin15days
  return !passwordExpired && passwordExpiringWithin15Days
})

const closeClicked = ref(false)

async function getPasswordExpirationDetails(): Promise<void> {
  try {
    passwordExpirationDetails.value =
      await usePasswordExpirationInfoApis().getPasswordExpirationDetails()
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

onMounted(async () => {
  await getPasswordExpirationDetails()
})

function navigateToChangePassword(): void {
  router.push('/user-admin/change-password')
}
</script>
<template>
  <div v-if="showPasswordExpirationBanner && !closeClicked">
    <div class="chi-alert -warning" role="alert">
      <i class="chi-alert__icon chi-icon icon-warning" aria-hidden="true"></i>
      <div class="chi-alert__content">
        <p class="chi-alert__text -text--bold -mb--1 -text--warning">Password Expiring Soon</p>
        <p class="-mb--1">
          Your password is going to expire within
          {{ passwordExpirationDetails.numberOfDaysUntilExpiration }} days. Please change your
          password before it expires.
        </p>
        <div class="chi-grid -pl--2">
          <button class="chi-button -xs -mr--2" @click="closeClicked = true">Close</button>
          <button class="chi-button -primary -xs" @click="navigateToChangePassword">
            Change Password
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
