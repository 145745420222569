import axios from 'axios'
import { eisConfig } from '@centurylinkfederal/eis-vue'
import { OpenAPI } from './openapi/clients/admin-ui-service-api'
import { useApi } from '@centurylinkfederal/eis-vue'

export const configureEis = () => {
  eisConfig.apiBase = import.meta.env.VITE_EIS_API_BASE
  eisConfig.portalBase = import.meta.env.VITE_EISPORTAL_PATH
  eisConfig.authUrl = import.meta.env.VITE_EIS_AUTH_API || `${eisConfig.apiBase}/auth`
  eisConfig.navUrl = import.meta.env.VITE_EIS_ACCOUNTS_API || `${eisConfig.apiBase}/node/accounts`
  OpenAPI.BASE = eisConfig.apiBase.split('/api')[0] ?? eisConfig.apiBase
  OpenAPI.HEADERS = {
    Accept: '*'
  }

  eisConfig.components.login.onAuthenticated = async () => {
    try {
      const userLandingPageUri: string = await getUserLandingPage()
      if (userLandingPageUri?.startsWith('/spog')) {
        const host = window.location.host
        const protocol = window.location.protocol
        const spogUrl = `${protocol}//${host}${userLandingPageUri}`
        window.location.href = spogUrl
      } else {
        window.location.replace('/admin-ui/home')
      }
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err)
      window.location.replace(import.meta.env.VITE_EISPORTAL_PATH)
    }
  }

  eisConfig.components.login.loginPage = async () => {
    const isLoginPageEnabled = import.meta.env.VITE_ENABLE_EISPORTAL_LOGIN_PAGE
    if (isLoginPageEnabled === 'true') {
      try {
        localStorage.clear()
        sessionStorage.clear()
        const url = `/srvc/logout?_=${new Date().getTime()}`
        await axios.get(url)
      } finally {
        window.location.replace('/')
      }
    } else {
      window.location.replace(`${eisConfig.portalBase || '/'}login`)
    }
  }
  if (!import.meta.env.PROD) {
    logConfig()
  }
}

export const logConfig = (level: 'debug' | 'log' = 'debug') => {
  // eslint-disable-next-line no-console
  const log = console[level]
  const { components: _c, ...config } = eisConfig
  log(`eis config: ${JSON.stringify(config)}`)
}

interface UserLandingPageInfo {
  landingPageUri: string
}
async function getUserLandingPage(): Promise<string> {
  try {
    const api = useApi(import.meta.env.VITE_EIS_API_BASE)
    const userLandingPageInfo: UserLandingPageInfo = await api.get('/user-landing-page')
    return userLandingPageInfo.landingPageUri
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
  // in case of errors, return the default landing page
  return '/admin-ui/home'
}
