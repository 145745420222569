<!-- eslint-disable no-console -->
<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { NewsControllerService } from '../../openapi/clients/admin-ui-service-api/services/NewsControllerService'
import { OpenAPI } from '../../openapi/clients/admin-ui-service-api'
import { PaginatedResultsNewsEventDTO } from '../../openapi/clients/admin-ui-service-api/models/PaginatedResultsNewsEventDTO'
import Cookies from 'js-cookie'
const page = ref(1)
const pageSize = ref(10)
const newsList = ref<PaginatedResultsNewsEventDTO[]>([])
const getAuthToken = (): string => {
  OpenAPI.TOKEN = Cookies.get('eis.portal.token')
  return OpenAPI.TOKEN as string
}
const fetchActiveNews = async () => {
  getAuthToken()
  try {
    const response = await NewsControllerService.getActiveNewsEvents(page.value, pageSize.value)
    newsList.value = response.results ?? []
  } catch (error) {
    console.error('Error fetching active news:', error)
  }
}

onMounted(async () => {
  await fetchActiveNews()
})
</script>

<template>
  <div v-if="newsList.length > 0" class="news-container">
    <h3 class="-text--h3 -text--boldest -text--navy">News & Events</h3>
    <div class="news-list">
      <div v-for="news in newsList" :key="news.newsEventId" class="news-item -py--2">
        <h4 class="-text--h4 -text--navy -m--0">
          <i class="chi-icon icon-file-outline -xs" aria-hidden="true"></i> {{ news.newsTitle }}
        </h4>
        <p class="-m--0 -ml--2">{{ news.newsBody }}</p>
      </div>
    </div>
  </div>
</template>
