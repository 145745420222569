<script setup lang="ts">
import { useStore } from './store'
import { storeToRefs } from 'pinia'
import { dangerAlert, doScrollToTop, successAlert } from '@centurylinkfederal/eis-vue'
import { useRouter } from 'vue-router'
import USState from '../CreateUser/constants/USState'
import { Action, dispatch } from '../CreateUser/actions'

const router = useRouter()
const store = useStore()
const { loading, alertMap, form, userProfileDetails } = storeToRefs(store)
const profileFormId = -1

function cancelProfileUpdate(): void {
  router.push('/')
}

async function onProfileUpdate(): Promise<void> {
  alertMap.value = new Map()
  if (validateForm()) {
    doScrollToTop()
    alertMap.value.set('alert', {
      ...dangerAlert,
      title: `Please complete all Required Fields indicated with * before attempting to submit changes.`
    })
    return
  }
  const formData = form.value[profileFormId]
  const formValues: any = Object.keys(formData).reduce(
    (all, key) => ({
      ...all,
      [key]: formData[key].value
    }),
    {}
  )

  const request = {
    ...formValues,
    stateCode:
      formValues.stateCode && formValues.stateCode.length > 2
        ? USState.find((state) => state.name === formValues.stateCode)?.code
        : formValues.stateCode,
    contactId: userProfileDetails.value.contactId,
    eisUserId: userProfileDetails.value.eisUserId
  }
  const isSuccess = await dispatch(Action.UPDATE_USER_PROFILE, request)
  alertMap.value.set(isSuccess ? 'success' : 'alert', {
    ...(isSuccess ? successAlert : dangerAlert),
    title: isSuccess ? 'User Profile updated successfully' : 'Profile update error'
  })

  doScrollToTop()
}

function validateForm(): boolean {
  let hasError = false
  const inputs = form.value[profileFormId]
  for (let key of Object.keys(inputs)) {
    const input = inputs[key]
    input?.tests?.forEach((test) => {
      const result = test(input)
      if (result?.[0] === 'danger') {
        input.error = result?.[1]
        input.state = 'danger'
        hasError = true
      }
    })
  }

  form.value = { ...form.value, [profileFormId]: inputs }

  return hasError
}
</script>
<template>
  <div class="chi-card -mt--3">
    <div class="chi-card__footer -justify-content--end">
      <button class="chi-button -outline" @click="cancelProfileUpdate">CANCEL</button>
      <button class="chi-button -primary" :disabled="loading" @click="onProfileUpdate">
        <div class="chi-button__content">
          <span>UPDATE</span>
          <svg
            class="chi-spinner -icon--primary -sm -position--absolute -ml--10"
            :class="{ '-d--none': !loading }"
            viewBox="0 0 66 66"
          >
            <circle class="path" cx="33" cy="33" r="30" fill="none" stroke-width="6"></circle>
          </svg>
        </div>
      </button>
    </div>
  </div>
</template>
