<!-- eslint-disable no-console -->
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import NewsDrawer from '../news/NewsDrawer.vue'
import Table from '../common/Table.vue'
import Pagination from '../common/Pagination.vue'
import { useNewsDashboard } from '../../store/news/newsDashboardStore'
import { OpenAPI } from '../../openapi/clients/admin-ui-service-api'
import Cookies from 'js-cookie'
import { Alert, Modal, doScrollToTop } from '@centurylinkfederal/eis-vue'
import PageLoader from '../common/PageLoader.vue'

interface IColumn {
  label: string
  field: string
  class?: string
  width?: string
}

const newsDashboardStore = useNewsDashboard()
const { deleteNews, updateNews, showAlert } = newsDashboardStore
const { news, totalNews, alert, createNewsFailure, updateNewsFailure } =
  storeToRefs(newsDashboardStore)
const tableCounter = ref(0)
const loader = ref<boolean>(false)
const selectedNewsPage = ref<number>(1)
const selectedNewsLimit = ref<number>(10)
const selectedNewsPageCount = ref<number>(0)
const columns: IColumn[] = [
  { field: 'id', label: 'ID', width: '4%' },
  { field: 'title', label: 'Title', width: '15%' },
  { field: 'description', label: 'Description', width: '25%' },
  { field: 'createdBy', label: 'Created On | Created By', width: '12%' },
  { field: 'modifiedBy', label: 'Modified On | Modified By', width: '12%' },
  { field: 'actions', label: 'Actions', width: '10%' }
]
const isModalOpen = ref<boolean>(false)
const isDrawerClicked = ref<boolean>(false)
const isCreateOpen = ref<boolean>(false)
const clickedRowData = ref<any>()
const alertBox = computed(() => alert.value)
const newsId = ref<number>(0)
const action = ref<string>('')
const modalButtons = [
  {
    text: 'No',
    type: 'no',
    class: '-md',
    onClick: () => {
      onDismissModal()
    }
  },
  {
    text: 'Yes',
    type: 'yes',
    class: '-md',
    color: 'primary',
    onClick: async () => {
      await onYes()
    }
  }
] as any[]

const getAuthToken = (): string => {
  OpenAPI.TOKEN = Cookies.get('eis.portal.token')
  return OpenAPI.TOKEN as string
}
function convertDate(date: string) {
  const dateObj = new Date(date)
  const datePart = dateObj.toISOString().split('T')[0]
  const hours = dateObj.getHours()
  const minutes = dateObj.getMinutes()
  const seconds = String(dateObj.getSeconds()).padStart(2, '0')
  const ampm = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = hours % 12 || 12
  const timePart = `${formattedHours}:${String(minutes).padStart(2, '0')}:${seconds} ${ampm}`

  const formattedDate = `${datePart} ${timePart}`
  return formattedDate
}
const formatNewsData = () => {
  return news.value.map((row, index) => ({
    index: index + 1,
    id: row?.newsEventId,
    title: row?.newsTitle,
    description: row?.newsBody,
    createdOn: convertDate(row?.createdOn ?? ''),
    createdBy: row?.createdByUserName,
    modifiedOn: convertDate(row?.updatedOn ?? ''),
    modifiedBy: row?.updatedByUserName,
    isActive: row?.isActive,
    actions: '' // Unique identifier
  }))
}
const fetchNews = async () => {
  loader.value = true
  getAuthToken()
  await newsDashboardStore.fetchNews(selectedNewsPage.value, selectedNewsLimit.value).then(() => {
    formatNewsData()
    selectedNewsPageCount.value = Math.ceil(totalNews.value / selectedNewsLimit.value)
    tableCounter.value += 1
    doScrollToTop()
    loader.value = false
  })
}

const changePage = async (newValue: number) => {
  selectedNewsPage.value = newValue
  await fetchNews()
}

const changePageSize = async (newValue: number) => {
  selectedNewsLimit.value = newValue
  selectedNewsPage.value = 1
  await fetchNews()
}

onMounted(async () => {
  await fetchNews()
})

const confirmPublishToggle = (id: number, isActive: string) => {
  alert.value.show = false
  newsId.value = id
  action.value = isActive === 'Y' ? 'unpublish' : 'publish'
  isModalOpen.value = true
}

const onPublishToggle = async (newsEventId: number) => {
  loader.value = true
  const newsEvent = news.value.find((news) => news.newsEventId === newsEventId)
  if (!newsEvent) {
    loader.value = false
    return
  }

  const isActive = newsEvent.isActive === 'Y' ? 'N' : 'Y'
  action.value = isActive === 'Y' ? 'publish' : 'unpublish'

  const authToken = getAuthToken()
  const updatedNewsBody = {
    ...newsEvent,
    isActive
  }
  await updateNews(authToken, updatedNewsBody)

  if (!updateNewsFailure.value) {
    showAlert(
      `News ${isActive === 'Y' ? 'Published' : 'Unpublished'} Successfully`,
      `News / Event with an ID: ${newsEventId} is ${isActive === 'Y' ? 'published' : 'unpublished'}`,
      'success'
    )
  } else {
    showAlert(
      'Unable to update the news',
      'Internal server error. Please try again after sometime.',
      'danger'
    )
  }
  loader.value = false
}

const confirmDelete = (id: number) => {
  action.value = 'delete'
  alert.value.show = false
  isModalOpen.value = true
  newsId.value = id
}

const onDelete = async (id: number) => {
  loader.value = true
  isModalOpen.value = false
  deleteNews(id)
    .then(() => {
      showAlert('News Deleted Successfully', `News / Event is deleted with an ID: ${id}`, 'success')
    })
    .catch((error) => {
      showAlert(
        'Unable to delete news',
        (error as any).message || 'Internal server error. Please try again after sometime.',
        'danger'
      )
    })
    .finally(() => {
      loader.value = false
    })
}

const onYes = async () => {
  isModalOpen.value = false
  if (action.value === 'delete') await onDelete(newsId.value)
  if (action.value === 'publish' || action.value === 'unpublish') {
    await onPublishToggle(newsId.value)
  }
  fetchNews()
}

const actionTitles: { [key: string]: string } = {
  delete: 'Delete News',
  publish: 'Publish News',
  unpublish: 'Unpublish News'
}

const getTitle = (action: string): string => {
  return actionTitles[action]
}

const onDismissModal = () => {
  if (action.value !== 'delete') tableCounter.value += 1
  isModalOpen.value = false
}
function fetchNewsCreated() {
  isDrawerClicked.value = false
  if (isCreateOpen.value) selectedNewsPage.value = 1
  fetchNews()
  tableCounter.value += 1
  if (isCreateOpen.value) {
    if (!createNewsFailure.value) {
      setTimeout(async () => {
        showAlert(
          'News Created Successfully',
          `News / Event is created with an ID: ${newsDashboardStore.newsID}`,
          'success'
        )
      }, 1000)
    } else {
      setTimeout(async () => {
        showAlert(
          'Unable to create news',
          'Internal server error. Please try again after sometime.',
          'danger'
        )
      }, 1000)
    }
  } else {
    if (!updateNewsFailure.value) {
      setTimeout(async () => {
        showAlert(
          'News Updated Successfully',
          `News / Event is updated with an ID: ${newsDashboardStore.newsID}`,
          'success'
        )
      }, 1000)
    } else {
      setTimeout(async () => {
        showAlert(
          'Unable to update news',
          'Internal server error. Please try again after sometime.',
          'danger'
        )
      }, 1000)
    }
  }
}
function showNewsDrawer(content?: any, create?: string) {
  isDrawerClicked.value = true
  isCreateOpen.value = create === 'create'
  clickedRowData.value = content
}

function closeDrawer() {
  isDrawerClicked.value = false
}
function limit(content: string) {
  return content == 'desc' ? 100 : 75
}
function limitChar(description: string, content: string) {
  return description.length >= limit(content)
    ? description.substring(0, limit(content)) + '...'
    : description
}

function setPopoverPosition(index: number) {
  const newsDataLength = Math.floor(formatNewsData().length / 2)
  if (index === 1) return 'bottom'
  if (newsDataLength >= index) return 'bottom'
  return 'top'
}
</script>

<template>
  <div class="news-dashboard -mx--2">
    <PageLoader :show="loader" :inverse="true" />
    <div class="chi-main -pt--3">
      <div class="chi-main__header -m--0 -mb--1">
        <div class="chi-main__header-start">
          <div class="chi-main__title">
            <div class="chi-main__title-heading">News Dashboard</div>
          </div>
        </div>
        <button
          class="chi-drawer__trigger chi-button -primary"
          @click="showNewsDrawer('', 'create')"
        >
          Create News
        </button>
      </div>
      <div class="chi-main__content -m--0">
        <Alert
          v-if="alertBox.show"
          class="-mb--1"
          :title="alertBox.title"
          :message="alertBox.message"
          :color="alertBox.color"
          :closable="alertBox.closable"
          @on-dismiss-alert="alert.show = false"
        />
        <Modal
          v-if="isModalOpen"
          :buttons="modalButtons"
          :title="getTitle(action)"
          :center="true"
          type="alert"
          class="-text--left"
          @on-dismiss-modal="onDismissModal"
        >
          <div class="chi-modal__content -p--0">
            <p class="chi-label -mb--2 -mt--0">
              Are you sure want to {{ action }} this news with ID: {{ newsId }}?
            </p>
          </div>
        </Modal>
        <!-- Page content goes here -->
        <Table
          class="-portal -bg--white -pt--2"
          :key="tableCounter"
          :columns="columns"
          :rows="formatNewsData()"
          :scrollable="false"
          :fixed-header="false"
        >
          <template #title="{ content }">
            <div class="wordBreak title">
              <eis-popover
                v-if="content?.title.length > limit('title')"
                :content="content?.title"
                :arrow="true"
                :placement="setPopoverPosition(content.index)"
              >
                <template #trigger>
                  <p class="-m--0">{{ limitChar(content?.title, 'title') }}</p>
                </template>
              </eis-popover>
              <p v-else class="-m--0">{{ content?.title }}</p>
            </div>
          </template>
          <template #description="{ content }">
            <div class="wordBreak description">
              <eis-popover
                v-if="content?.description.length > limit('desc')"
                :content="content?.description"
                :arrow="true"
                :placement="setPopoverPosition(content.index)"
              >
                <template #trigger>
                  <p class="-m--0">{{ limitChar(content?.description, 'desc') }}</p>
                </template>
              </eis-popover>
              <p v-else class="-m--0">{{ content?.description }}</p>
            </div>
          </template>
          <template #createdBy="{ content }">
            {{ content?.createdOn }}
            <br />
            {{ content?.createdBy }}
          </template>
          <template #modifiedBy="{ content }">
            {{ content?.modifiedOn }}
            <br />
            {{ content?.modifiedBy }}
          </template>
          <template #actions="{ content }">
            <div class="chi-table__actions">
              <eis-tooltip
                :content="content.isActive == 'Y' ? 'Publish' : 'Unpublish'"
                :arrow="false"
              >
                <button class="chi-button -flat -p--0">
                  <div class="chi-form__item">
                    <label :for="'toggle-' + content.id" class="chi-switch -sm">
                      <input
                        :id="'toggle-' + content.id"
                        type="checkbox"
                        class="chi-switch__input"
                        :checked="content.isActive == 'Y'"
                        @change="confirmPublishToggle(content.id, content.isActive)"
                      />
                      <span class="chi-switch__content">
                        <span class="chi-switch__thumb"></span>
                      </span>
                    </label>
                  </div>
                </button>
              </eis-tooltip>
              <eis-tooltip content="Edit" :arrow="false">
                <button
                  class="chi-button -flat -p--0 -mx--1"
                  @click="showNewsDrawer(content, 'edit')"
                >
                  <i class="chi-icon icon-edit -sm--3 -m--0" aria-hidden="true"></i>
                </button>
              </eis-tooltip>
              <eis-tooltip content="Delete" :arrow="false">
                <button class="chi-button -flat -p--0" @click="confirmDelete(content.id)">
                  <i class="chi-icon icon-delete -sm--3 -m--0" aria-hidden="true"></i>
                </button>
              </eis-tooltip>
            </div>
          </template>
        </Table>
        <NewsDrawer
          v-if="isDrawerClicked"
          :create="isCreateOpen"
          :data="clickedRowData"
          @news-created="fetchNewsCreated()"
          @drawer-closed="closeDrawer()"
        />
        <Pagination
          :key="tableCounter"
          :page-jumper="false"
          :compact="true"
          :is-page-size="false"
          size="xs"
          class="-bg--grey-15 -px--2 -py--1"
          :total-results="totalNews"
          :page-size="selectedNewsLimit"
          :pages="selectedNewsPageCount"
          :current-page="selectedNewsPage"
          :results="news"
          @current-page="changePage"
          @page-size="changePageSize"
        />
      </div>
    </div>
  </div>
</template>
<style>
.news-dashboard .wordBreak {
  word-break: break-word;
}
.news-dashboard .wordBreak.description div.eis-dropdown {
  max-height: fit-content;
  max-width: 550px;
}
</style>
