<script setup lang="ts">
import { ref } from 'vue'
import { ButtonType } from '@centurylinkfederal/eis-vue'

export interface Props extends ButtonType {
  class?: string
  message?: string
  icon?: string
  iconSize?: string
  iconColor?: string
  disabled?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  class: '-flat -sm',
  message: undefined,
  icon: undefined,
  iconSize: undefined,
  iconColor: undefined,
  disabled: undefined
})
const text = ref(props.text ?? props.message)
const className = ref(props.class)
const emit = defineEmits(['onClick'])

function onClick(): void {
  emit('onClick')
}
</script>
<template>
  <button class="chi-button" :class="className" :disabled="disabled" @click="onClick">
    <div class="chi-button__content">
      <i
        v-if="icon"
        class="chi-icon"
        :class="['icon-' + icon, { ['-' + iconSize]: iconSize }, { ['-' + iconColor]: iconColor }]"
        aria-hidden="true"
      ></i>
      <span v-if="text">{{ text }}</span>
    </div>
  </button>
</template>
<style scoped></style>
